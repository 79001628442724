import { Component, ChangeDetectionStrategy, Input, TemplateRef, ContentChild, TrackByFunction } from '@angular/core';

export interface CommonTreeDatum<T> {
    children?: Array<CommonTreeDatum<T>>;
    id?: number | string;
    label: string;
    value: T;
}

@Component({
    selector: 'msc-common-tree',
    templateUrl: './tree.component.html',
    styleUrls: ['./tree.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class CommonTreeComponent<T> {
    @Input() data: Array<CommonTreeDatum<T>> = []; // data items for tree
    @Input() template?: TemplateRef<{ datum: CommonTreeDatum<T> }>; // template for item via input
    @ContentChild(TemplateRef) leafTemplate: TemplateRef<{ datum: CommonTreeDatum<T> }>; // template for leaf

    public onTrackByIndex: TrackByFunction<CommonTreeDatum<T>> = (i: number) => i;
}
