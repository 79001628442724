import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Course, Section } from '@stuplay';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslationService } from '../../../../utils/services/translation.service';
import { EmitterService } from '../../../../utils/services/emitter.service';
import { ExternalAppsService } from '../../../../utils/services/external-apps.service';
import { StorageService } from '../../../../utils/services/storage.service';

@Component({
    selector: 'app-section-presentation',
    templateUrl: './section-presentation.component.html',
    styleUrls: ['./section-presentation.component.less']
})
export class SectionPresentationComponent implements OnInit {
    @Input() course: Course | any;
    @Input() section: Section;
    @Output() reloadErrors = new EventEmitter();
    public company: any;
    private me: any;
    public deleteAction: boolean = false;
    public editAction: boolean = false;
    public error: any;
    public position: string = 'right';
    public currentLang: string;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private translationService: TranslationService,
        private emitterService: EmitterService,
        private externalAppsService: ExternalAppsService,
        private storageService: StorageService,
    ) { }

    ngOnInit() {
        document.addEventListener('click', this.offClickHandler.bind(this));
        this.me = this.storageService.get('me');
        this.company = this.storageService.get('company');
    }

    offClickHandler(event: any): void {
        if (!event.target.closest('.settings-delete')) {
            this.deleteAction = false;
        }
        if (!event.target.closest('.settings-edit')) {
            this.editAction = false;
        }
    }

    updateSection(): void {
        this.section.update().subscribe(() => {
            this.emitterService.refreshCourse.emit(true);
        },
            (error) => {
                this.error = error
            }
        );
    }

    deleteSection(): void {
        const pos = this.course.sections.indexOf(this.section);
        this.section.delete().subscribe(() => {
            this.course.sections.splice(pos, 1);
            this.reloadErrors.emit(this.course);
            this.router.navigate(['../../'], { relativeTo: this.route });
            this.emitterService.refreshCourse.emit(true);
        },
            (error) => {
                this.error = error
            }
        );
    }

    changeDeleteAction(event: any): void {
        this.position = (event.clientX + 260 > window.innerWidth) ? 'left' : 'right';
        this.deleteAction = !this.deleteAction
    }

    onUpdatedMedia(media: any): void {
        this.section.media = media;
        this.updateSection();
    }

    openAsideHelp(type) {
        this.emitterService.activeHelp.emit({ type: type, feature: 'exercice' });
    }
}
