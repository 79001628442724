import { Component, ViewChild } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, Event as NavigationEvent } from '@angular/router';
import { NgProgressComponent } from 'ngx-progressbar';

@Component({
    selector: 'app',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.less']
})
export class AppComponent {
    @ViewChild(NgProgressComponent) progressBar: NgProgressComponent;

    constructor(
        private router: Router,
    ) {
        this.router.events.forEach((event: NavigationEvent) => {
            if (event instanceof NavigationStart) {
                this.progressBar.start();
            }
            if (event instanceof NavigationEnd) {
                this.progressBar.complete();
            }
        });
    }

    ngAfterViewInit() {
        const preloaderElementRef = document.getElementById('preloader');
        if (preloaderElementRef !== null) {
            preloaderElementRef.style['display'] = 'none';
        }
    }
}
