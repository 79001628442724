import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Account } from '../../../../account/account';
import { getParams } from "../../../../helpers";

export class EventTrainer {
    eventId: number;
    account: Account;
    name: string;

    constructor(eventTrainer: any, private http: HttpClient) {
        this.extends(eventTrainer);
    }

    extends(eventTrainer: any): EventTrainer {
        Object.assign(this, eventTrainer);
        return this;
    }

    delete(): Observable<void> {
        const params = {
            name: this.name,
        };
        if (this.account) {
            params['account_id'] = this.account.id;
        }

        return this.http.delete(`${environment.envVar.API_URL}/events/${this.eventId}/trainers`, {params: getParams(params)}).pipe(map((res) => null));
    }
}