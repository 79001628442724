<span class="message" [innerHTML]="content.message | url"></span>
<div *ngIf="content.media">
  	<media-holder [media]="content.media" [mini]="true" [height]="80" [width]="200"></media-holder>
</div>

<!--<div class="post-link">
	<span *ngIf="content.link">
		<div *ngIf='!content?.link?.video'>
			<a class="post-url" target="_blank" [href]="content.link.pageUrl">
				<h3>{{ content.link.title }}</h3>
				<p>{{ content.link.description }}</p>
				<img [src]="content.link.image">
			</a>
		</div>

		<div *ngIf='content?.link?.video'>
			<a class="post-url" target="_blank" [href]="content.link.pageUrl">
				<h4>{{ content.link.title }}</h4>
				<p>{{ content.link.description }}</p>
				<span [innerHtml]="safeUrl"></span>
			</a>
		</div>
	</span>
</div>-->
