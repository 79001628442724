<aside class="sidebar sidebar--modal sidebar-subscription" [ngClass]="active ? 'active' : ''" (appSidebar)="closeSidebar()" *ngIf="activePanel === 'select-camp'">
    <div class="sidebar__container">
        <div class="sidebar__head sidebar__head--center">
            <div class="sidebar__description">
                <h1 class="sidebar__title">
                    {{ 'studio.course-preview.publish' | translate }}
                </h1>
            </div>

            <a class="sidebar__close" (click)="closeSidebar()">
                <i class="icon icon--close"></i>
            </a>
        </div>

        <div class="sidebar__body">
            <div class="sidebar__form">
                <ul class="sidebar__progress">
                    <li class="active"></li>
                    <li></li>
                    <li></li>
                    <li></li>
                </ul>

                <div class="sidebar__search">
                    <div class="search">
                        <label class="search__label">
                            <i class="search__icon icon icon--search"></i>
                        </label>

                        <input autocomplete="off" class="search__input" type="search" [placeholder]="'words.search' | translate" [(ngModel)]="query" [debounce]="500" (onDebounce)="search()">
                    </div>
                </div>

                <div class="templab-alert-box" *ngIf="alert">
                    <div class="templab-alert">
                        <div class="templab-alert__icon">
                            <i class="templab-icon templab-icon-alert-triangle"></i>
                        </div>

                        <div class="templab-alert__text">
                            {{ 'alert.publishable' | translate }}
                        </div>
                    </div>
                </div>

                <div class="sidebar__fieldset" *ngFor="let company of companies; let index=index">
                    <div class="sidebar__toggle" [ngClass]="{'active': displays[index]}" (click)="displayed(index)">
                        <div class="templab-title-company">
                            {{ company.name }}

                            <i class="templab-icon templab-icon-slash-1 templab-color--orange" *ngIf="!company.hasProvidersEnabled"></i>

                            <!-- <div class="tooltip-v2 tooltip-v2--top tooltip-v2--large" [attr.data-tooltip]="'alert.flex-option' | translate" *ngIf="company.hasExternalContents">
                                <i class="templab-icon templab-icon-slash-1 templab-color--orange"></i>
                            </div> -->
                        </div>

                        <i class="icon icon--arrow-bottom"></i>
                    </div>

                    <div class="sidebar__camps">
                        <div class="" *ngIf="company.camps.length === 0" style="display: flex; justify-content: center; width: 100%;">
                            {{ 'words.camps-not-found' | translate }}
                        </div>

                        <div class="templab-card-camp" *ngFor="let camp of company.camps" [ngClass]="{'active' : isChosen(camp.id) && company.hasProvidersEnabled, 'disable': !company.hasProvidersEnabled}" (click)="chooseCamp(company, camp)">
                            <div class="templab-card-camp__content">
                                <p class="templab-card-camp__title">
                                    {{ camp.name | truncate: '40' }}
                                </p>

                                <div class="templab-card-camp__stats">
                                    <div>
                                        <i class="templab-icon templab-icon-people"></i>

                                        <span>{{ camp.usersCount }}</span>
                                    </div>

                                    <div>
                                        <i class="templab-icon templab-icon-calendar"></i>
                                        <span>{{ camp.instancesCount }}</span>
                                    </div>
                                </div>

                                <div class="templab-card-camp__checkbox" *ngIf="company.hasProvidersEnabled">
                                    <input type="checkbox" [checked]="isChosen(camp.id)">
                                </div>

                                <div class="templab-card-camp__icon" *ngIf="!company.hasProvidersEnabled">
                                    <i class="templab-icon templab-icon-slash-1"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="" *ngIf="companies.length === 0 && !loading" style="display: flex; justify-content: center; align-items: center; height: 200px; font-weight: 600;">
                    {{ 'words.camps-not-found' | translate }}
                </div>

                <div class="load-more animate" *ngIf="loading">
                    <div class="dot"></div>
                </div>
            </div>
        </div>

        <div class="sidebar__footer">
            <button class="btn btn--primary" (click)="options()">
                {{ 'studio.publish.button.publish' | translate }}
            </button>
        </div>
    </div>
</aside>

<aside class="sidebar sidebar--modal sidebar-subscription" *ngIf="activePanel === 'options'" [ngClass]="active ? 'active' : ''" (appSidebar)="closeSidebar()">
    <div class="sidebar__container">
        <div class="sidebar__head sidebar__head--center">
            <div class="sidebar__description">
                <h1 class="sidebar__title">
                    <i class="icon icon--arrow-left" (click)="back()"></i>

                    <span *ngIf="currentTab === 'invitation'">
                        {{ 'words.subscribe-method' | translate }}
                    </span>

                    <span *ngIf="currentTab === 'settings'">
                        {{ 'words.settings-publication' | translate }}
                    </span>

                    <span *ngIf="currentTab === 'summary'">
                        {{ 'words.summary-publication' | translate }}
                    </span>
                </h1>
            </div>

            <a class="sidebar__close" (click)="closeSidebar()">
                <i class="icon icon--close"></i>
            </a>
        </div>

        <div class="sidebar__body">
            <div class="sidebar__form">
                <ul class="sidebar__progress">
                    <li class="success" (click)="changeTab('camps', 1)"></li>
                    <li [ngClass]="{'active': currentTab === 'invitation', 'success': currentTab === 'settings' || currentTab === 'summary' || currentTab === 'published'}" (click)="changeTab('invitation', 1)"></li>
                    <li [ngClass]="{'active': currentTab === 'settings', 'success': currentTab === 'summary' || currentTab === 'published'}" (click)="changeTab('settings', 2)"></li>
                    <li [ngClass]="{'active': currentTab === 'summary', 'success': currentTab === 'published'}" (click)="changeTab('summary', 3)"></li>
                </ul>

                <h2 class="sidebar__subtitle" *ngIf="currentTab === 'invitation'">
                    {{ 'journey.publication.settings' | translate }}
                </h2>

                <h2 class="sidebar__subtitle sidebar__subtitle--highlight" *ngIf="currentTab === 'summary'">
                    {{ 'words.publication' | translate }}
                </h2>

                <div class="sidebar__content" *ngIf="currentTab === 'invitation'">
                    <div class="box-publication" [ngClass]="{'active': settings.is_catalogue}" (click)="selectCatalogue()">
                        <div class="box-publication__header">
                            <i class="new-icon icon-catalogue"></i>
                            {{ 'instance.options.catalogue' | translate }}
                        </div>

                        <div class="box-publication__body">
                            <p><i class="icon icon--check"></i>{{ 'instance.catalogue.subscribe' | translate }}</p>
                            <p><i class="icon icon--check"></i>{{ 'instance.admin.subscribe' | translate }}</p>
                        </div>
                    </div>

                    <div class="box-publication" [ngClass]="{'active': settings.is_catalogue === false}" (click)="selectCatalogue(false)">
                        <div class="box-publication__header">
                            <i class="new-icon icon-email-outline"></i>
                            {{ 'instance.options.invitation' | translate }}
                        </div>

                        <div class="box-publication__body">
                            <p><i class="icon icon--close"></i>{{ 'instance.catalogue.subscribe' | translate }}</p>
                            <p><i class="icon icon--check"></i>{{ 'instance.admin.subscribe' | translate }}</p>
                        </div>
                    </div>
                </div>

                <div class="params" *ngIf="currentTab === 'settings'">
                    <div class="params__item params__item--success">
                        <div class="sidebar__toggle active">
                            <p>
                                <i class="new-icon icon-options-outline"></i>
                                {{ 'studio.publish.options.access-title' | translate }}
                            </p>
                        </div>

                        <div class="params__form">
                            <div class="params__fieldset">
                                <div class="switch-v2">
                                    <div class="switch__content">
                                        <input type="checkbox" name="params-3" class="switch-checkbox" id="params-3" [(ngModel)]="settings.notify_manager">

                                        <label class="switch-label" for="params-3">
                                            <span class="switch-inner"></span>
                                            <span class="switch-switch">
                                                <i class="icon icon--close"></i>
                                            </span>
                                        </label>
                                    </div>

                                    <div class="switch__label">
                                        {{ 'studio.publish.options.notify-manager' | translate }}
                                        <span>{{ 'studio.publish.options.enable-notify-manager' | translate }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="" *ngIf="currentTab === 'summary'" style="margin: 25px 60px 0;">
                    <div class="box-module">
                        <div class="box-module__icon">
                            <i class="icon icon--journey"></i>
                        </div>

                        <div class="box-module__text">
                            <p>
                                {{ journey.title }}
                            </p>
                        </div>
                    </div>
                </div>

                <div class="sidebar-summary" *ngIf="currentTab === 'summary'" style="margin: 0 100px;">
                    <h2 class="sidebar-summary__title">
                        {{ 'words.custom-settings' | translate }}
                    </h2>

                    <h3 class="sidebar-summary__subtitle">
                        <i class="new-icon icon-email-outline"></i>
                        {{ 'words.subscribe-method' | translate }}
                    </h3>

                    <p>
                        <i class="icon icon--check"></i>
                        {{ (settings.is_catalogue ? 'instance.options.catalogue' : 'instance.options.invitation') | translate }}
                    </p>

                    <h3 class="sidebar-summary__subtitle">
                        <i class="new-icon icon-options-outline"></i>
                        {{ 'studio.publish.options.access-title' | translate }}
                    </h3>

                    <p>
                        <i class="icon icon--check" *ngIf="settings.notify_manager"></i>
                        <i class="icon icon--close" *ngIf="!settings.notify_manager"></i>

                        {{ 'studio.publish.options.notify-manager' | translate }}
                        <span>{{ 'studio.publish.options.enable-notify-manager' | translate }}</span>
                    </p>
                </div>
            </div>
        </div>

        <div class="sidebar__footer">
            <button class="btn btn--primary" (click)="next()">
                {{ 'studio.publish.button.publish' | translate }}
            </button>
        </div>
    </div>
</aside>

<style>
    .sidebar__progress {
        cursor: pointer;
    }
</style>
