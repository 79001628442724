import { Component, Output, EventEmitter, Input } from '@angular/core';

@Component({
    selector: 'media-library-sidebar',
    templateUrl: './media-library-sidebar.component.html',
    styleUrls: ['./media-library-sidebar.component.less']
})
export class MediaLibrarySidebarComponent {
    @Input() tab: string = 'import';
    @Output() filterMedia: EventEmitter<string> = new EventEmitter;
    @Output() sendTab: EventEmitter<string> = new EventEmitter;

    constructor() {}

    setTab(type: string): void {
        this.tab = type;
        this.sendTab.emit(type);
    }

    isSelected(type: string): boolean {
        return this.tab === type;
    }

    filterMedias(type): void {
        this.filterMedia.emit(type)
    }

    goToToolbox() {
        window.open('https://view.genial.ly/5c18f56a363ee256b5899904', '_blank');
    }
}
