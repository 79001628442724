import {
  TrueFalseQuestionSession,
  OpenWriteQuestionSession,
  MultipleQuestionSession,
  LinkerQuestionSession,
  CategoryQuestionSession,
  FillInQuestionSession,
  OrderedQuestionSession,
  ProcedureQuestionSession
} from './question-session';
import { HttpClient } from '@angular/common/http';

export function questionSessionFactory(type: string, questionSession: any, http: HttpClient) {
    switch (type) {
      case 'true-false' : {
        return new TrueFalseQuestionSession(questionSession, http);
      }
      case 'open-write' : {
        return new OpenWriteQuestionSession(questionSession, http);
      }
      case 'multiple' : {
        return new MultipleQuestionSession(questionSession, http);
      }
      case 'linker' : {
        return new LinkerQuestionSession(questionSession, http);
      }
      case 'category' : {
        return new CategoryQuestionSession(questionSession, http);
      }
      case 'fill-in' : {
        return new FillInQuestionSession(questionSession, http);
      }
      case 'ordered' : {
        return new OrderedQuestionSession(questionSession, http);
      }
      case 'procedure' : {
        return new ProcedureQuestionSession(questionSession, http);
      }

    }
}
