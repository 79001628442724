import { Directive, OnInit, OnDestroy, HostListener, Output, EventEmitter } from '@angular/core';

@Directive({
    selector: '[appSidebar]'
})
export class SidebarDirective implements OnInit, OnDestroy {
    @Output('appSidebar') close: EventEmitter<any> = new EventEmitter;
    private body: any;

    constructor() {
        this.body = document.querySelector('body');
    }

    ngOnInit() {
        this.body.className += ' active';
    }

    ngOnDestroy() {
        this.body.className = this.body.className.replace(' active', '');
    }

    @HostListener('document:click', ['$event'])
    clickout(event) {
        if (event.target.className === 'overlay active') {
            this.close.emit(event);
        }
    }
}
