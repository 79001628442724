import { Component, OnInit, Input } from '@angular/core';
import { Media } from '@stuplay';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../../../environments/environment';

@Component({
    selector: 'media-doc',
    templateUrl: './doc.component.html'
})

export class MediaDocComponent implements OnInit {
    @Input() media: Media;
    public url;

    constructor(private sanitizer: DomSanitizer) {}

    ngOnInit() {
        this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.media.originalUrl.indexOf('.txt') > -1
            ? `${environment.envVar.STORAGE_URL}/${this.media.originalUrl}` 
            : (this.media.pictureUrl || `${environment.envVar.STORAGE_URL}/${this.media.url}` )
        );
    }
}
