import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Exercise } from '@stuplay'

@Component({
    selector: 'app-edit-question-settings',
    templateUrl: './edit-question-settings.component.html'
})
export class EditQuestionSettingsComponent implements OnInit, OnChanges {
    @Input() question: any;
    @Input() exercise: Exercise;
    @Output() closeSettings = new EventEmitter();
    @Output() modalOpen: EventEmitter<boolean> = new EventEmitter();
    public type: string = '';
    public displayHint: boolean = false;
    public displayFeedBacks: boolean = false;
    public displayMinLength: boolean = false;

    ngOnInit() {
        if (this.question) {
            this.type = this.question.constructor.name;
            this.displayHint = this.question.hint || this.question.hintMedia;
            this.displayFeedBacks = (this.question.feedbackDefault || this.question.feedbackDefaultMedia || this.question.feedbackWrongAnswer || this.question.feedbackWrongAnswerMedia);
            this.displayMinLength = this.question.param.minLength;
        }
    }

    emit(): void {
        this.modalOpen.emit(true)
    }

    ngOnChanges() {
        if (this.question) {
            this.type = this.question.constructor.name;
        }
    }

    close(): void {
        this.closeSettings.emit();
    }

    updateSettings(): void {
        this.question.update().subscribe();
    }

    updateHintMediaSettings(media: any): void {
        this.question.hintMedia = media;
        this.updateSettings();
    }

    displayedHint(): void {
        if (!this.displayHint) {
            this.question.hint = null;
            this.question.hintMedia = null;
            this.updateSettings();
        }
    }

    displayedMinLength(): void {
        if (!this.displayMinLength) {
            this.question.param.minLength = null;
            this.updateSettings();
        }
    }
}
