import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Course } from '@stuplay';
import { FullstoryService } from '../../utils/services/fullstory.service';
import { StorageService } from '../../utils/services/storage.service';

@Component({
    selector: 'app-course',
    templateUrl: './course.component.html'
})
export class CourseComponent implements OnInit {
    me: any;
    course: Course;
    playerMode: boolean;
    size: string = 'desktop';

    constructor(
        private route: ActivatedRoute,
        private fullstoryService: FullstoryService,
        private storageService: StorageService,
    ) { }

    ngOnInit() {
        this.course = this.route.snapshot.data['course'];
        this.me = this.storageService.get('me');
        // this.fullstoryService.init(this.me);
    }

    onUpdateSize(size: string): void {
        this.size = size;
    }
}
