import { QuestionSession } from './question-session';
import { HttpClient } from '@angular/common/http';
import { Observable, Subscription, interval, of } from 'rxjs';
import { questionSessionFactory } from './question-session';
import { map, switchMap, timeInterval } from 'rxjs/operators';
import { ProjectAnswer } from "./project-answer/project-answer";
import { Media } from '../../media';
import { CourseSubscriptionSessionProvider } from "../../course-subscription-session";
import { cleanObject } from "../../helpers";
import { SelfAssessmentAnswer } from "./self-assessment-answer/self-assessment-answer";
import { environment } from 'src/environments/environment';

export class SectionContentSession {
    id: number;
    sectionContentId: number;
    sectionContentContext: string;
    questionsLength?: number;
    score: number;
    progress: number = 0;
    duration = 0;
    current?: string = '';
    doneAt: Date;
    projectAnswer: ProjectAnswer;
    selfassessmentAnswer: SelfAssessmentAnswer;
    questionSessions?: QuestionSession[] = [];
    timerSubscription: Subscription;
    courseSubscriptionSessionId: number;

    constructor(module: any, private http: HttpClient) {
        this.extends(module);
    }

    extends(module: any): SectionContentSession {
        Object.assign(this, module);
        if (this.questionSessions.length > 0) {
            this.questionSessions = this.questionSessions.map((questionSession) => new QuestionSession(questionSession, this.http));
        }
        this.projectAnswer = new ProjectAnswer(this.projectAnswer || {}, this.http);
        this.selfassessmentAnswer = new SelfAssessmentAnswer(this.selfassessmentAnswer || {}, this.http);
        return this;
    }

    getProgress(): number {
        if (this.questionsLength) {
            return this.questionSessions.filter(({doneAt}) => doneAt).length / this.questionsLength;
        } else {
            return this.doneAt ? 1 : 0;
        }
    }

    getScore(): number {
        if (this.questionSessions && this.questionSessions.length > 0) {
            return this.questionSessions.reduce((prev, questionSession) => prev + questionSession.getScore(), 0) / this.questionSessions.length;
        } else {
            return 1;
        }
    }

    getWrongQuestionsNumber(): number {
        if (this.questionSessions) {
            return this.questionSessions.reduce((prev, questionSession) => questionSession.isCorrect ? prev : prev + 1, 0);
        }
    }

    getRightQuestionNumber(): number {
        if (this.questionSessions) {
            return this.questionSessions.reduce((prev, questionSession) => questionSession.isCorrect ? prev + 1 : prev, 0);
        }
    }

    startTimer(companyId: number) {
        this.timerSubscription = interval(5000)
            .pipe(timeInterval())
            .subscribe(() => {
                this.duration += 5;
                this.update(false, companyId);
            });
    }

    stopTimer() {
        this.timerSubscription.unsubscribe();
    }

    getDuration(): number { // TODO SectionContentContext === 'exercise'
        return this.duration + (this.questionSessions ? this.questionSessions.reduce((prevDuration, {durationGlobal}) => prevDuration + durationGlobal, 0) : 0);
    }

    getExerciseDuration(): number { // TODO SectionContentContext === 'exercise'
        return this.questionSessions ? this.questionSessions.reduce((prevDuration, {durationGlobal}) => prevDuration + durationGlobal, 0) : 0;
    }

    getOrCreateQuestionSession(questionId: number): Observable<QuestionSession> {
        const questionSession = this.questionSessions.find((questionSession) => questionId === questionSession.questionId);
        return questionSession ? of(questionSession) : this.addQuestionSession(questionId);
    }

    addQuestionSession(questionId: number): Observable<QuestionSession> {
        return this.http.post(`${environment.envVar.API_URL}/session/question-sessions`, {
            questionId,
            sectionContentSessionId: this.id
        }).pipe(map((res: any) => {
                const questionSession = questionSessionFactory(res.type, res, this.http);
                this.questionSessions.push(questionSession);
                return questionSession;
            }));
    }

    update(isDone: boolean = false, companyId: number): Observable<any> {
        const courseSubscriptionSessionProvider: CourseSubscriptionSessionProvider = new CourseSubscriptionSessionProvider(this.http);
        return this.http.put(`${environment.envVar.API_URL}/session/section-content-sessions/${this.id}?isDone=${isDone}&company_id=${companyId}`, cleanObject(this))
            .pipe(map(res => this.extends(res)),
                switchMap(() => {
                    return courseSubscriptionSessionProvider.getCourseSubscriptionSession(this.courseSubscriptionSessionId);
                })
            );
    }

    get(): Observable<SectionContentSession> {
        return this.http.get(`${environment.envVar.API_URL}/session/section-content-sessions/${this.id}`)
            .pipe(map((data) => this.extends(data)));
    }

    createOrUpdateProjectAnswer(projectPayload: {content?: string, media?: Media, companyId: number}): Observable<ProjectAnswer> {
        if (this.projectAnswer && this.projectAnswer.id) {
            return this.projectAnswer.extends(projectPayload).update();
        } else {
            return this.http.post(`${environment.envVar.API_URL}/session/section-content-sessions/${this.id}/project-answer`, projectPayload)
                .pipe(map(res => {
                    this.projectAnswer = new ProjectAnswer(res, this.http);
                    return this.projectAnswer;
                }));
        }
    }

    createProjectAnswer(mediaArray: number[], companyId: number): Observable<ProjectAnswer> {
        return this.http.post(`${environment.envVar.API_URL}/session/section-content-sessions/${this.id}/project-answer`, {media: mediaArray, company_id: companyId})
            .pipe(map(res => {
                return this.projectAnswer = new ProjectAnswer(res, this.http);
            }));
    }

    createOrUpdateSelfassessmentAnswer(selfAssessmentId, selfassessmentPayload: {sectionContentSessionId?: number, content?: string, media?: number[]}): Observable<SelfAssessmentAnswer> {
        if (this.selfassessmentAnswer && this.selfassessmentAnswer.id) {
            return this.selfassessmentAnswer.extends(selfassessmentPayload).update();
        } else {
            return this.http.post(`${environment.envVar.API_URL}/selfassessments/${selfAssessmentId}/answers`, selfassessmentPayload)
                .pipe(map(res => {
                    this.selfassessmentAnswer = new SelfAssessmentAnswer(res, this.http);
                    return this.selfassessmentAnswer;
                }));
        }
    }

    updateSelfAssessmentRatio(selfAssessmentId, selfAssessmentAnswerId, selfassessmentPayload: {ratio: number, companyId: number}): Observable<SelfAssessmentAnswer> {
        return this.http.put(`${environment.envVar.API_URL}/selfassessments/${selfAssessmentId}/answers/${selfAssessmentAnswerId}/correction`, selfassessmentPayload)
            .pipe(map(res => {
                this.selfassessmentAnswer = new SelfAssessmentAnswer(res, this.http);
                return this.selfassessmentAnswer;
            }));
    }

    createSelfassessmentAnswer(mediaArray: number[]): Observable<SelfAssessmentAnswer> {
        return this.http.post(`${environment.envVar.API_URL}/selfassessments/${this.id}/answers`, {media: mediaArray})
            .pipe(map(res => {
                return this.selfassessmentAnswer = new SelfAssessmentAnswer(res, this.http);
            }));
    }
}
