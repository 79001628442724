<div class="add-library">
    <div class="add-media">
        <div class="row">
            <div class="columns small-12">
                <div class="header">
                    <h2 class="header__title">
                        <span class="header__supertitle">{{'studio.library.manage-medias' | translate}}</span>

                        <div class="instruction">
                            {{'studio.library.manage-medias-' + importType| translate}}
                            <a (click)="openAsideHelp('iframe')" *ngIf="importType === 'iframe' || importType === 'url'">
                                <img src="https://static.myskillcamp.com/images/guidance/question-mark-circle.svg"/>
                            </a>
                        </div>
                    </h2>
                </div>

                <div class="add-media__container embed-media">
                    <div class="interior embed">
                        <div class="icon-embed"></div>
                        <p>{{ (importType === 'iframe' ? 'studio.library.add-iframe.instructions' : 'studio.library.add-embed.instructions') | translate }}</p>

                        <div class="embed-code">
                            <label class="input-field" *ngIf="importType === 'iframe'">
                                <input placeholder="<iframe></iframe>" type="text" [(ngModel)]="embed" (ngModelChange)="preview('embed')">
                            </label>

                            <label class="input-field" *ngIf="importType === 'url'">
                                <input placeholder="https://" [(ngModel)]="url" type="text" (ngModelChange)="preview('url')">
                            </label>
                        </div>

                        <div class="embed" *ngIf="trustedUrl">
                            <iframe [src]="trustedUrl" width="50%" height="50%" allowfullscreen></iframe>
                        </div>

                        <div class="actions">
                            <button class="btn btn_color_grey" *ngIf="trustedUrl" (click)="trustedUrl = ''">{{ 'studio.common.button.cancel' | translate }}</button>
                            <button class="btn pull-right btn-action" (click)="createEmbed()">{{ 'studio.media.embed.create' | translate}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
