import { Question } from '../question';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

export class MultipleQuestion extends Question {
    content: MultipleQuestionContent = new MultipleQuestionContent();
    param: MultipleQuestionParameters = new MultipleQuestionParameters();

    constructor(question: any, protected http: HttpClient) {
        super(question, http);
        this.extends(question);
    }

    extends(question: any): MultipleQuestion {
        Object.assign(this, question);

        if (!this.param) {
            this.param = new MultipleQuestionParameters();
        }
        if (question.param) {
            if (this.param instanceof MultipleQuestionParameters) {
                this.param.extends(question.param);
            } else {
                this.param = new MultipleQuestionParameters(question.param);
            }
        }

        if (!this.content) {
            this.content = new MultipleQuestionContent();
            this.content.addChoice(false);
            this.content.addChoice(true);
            this.content.addChoice(true);
            this.content.addChoice(false);
        }
        if (question.content) {
            if (this.content instanceof MultipleQuestionContent) {
                this.content.extends(question.content);
            } else {
                this.content = new MultipleQuestionContent(question.content);
            }
        }

        return this;
    }

    getCorrection(answer): Observable<any> {
        return this.postAnswer(answer);
    }
}

export class MultipleQuestionContent {
    choices: MultipleQuestionChoice[] = [];

    constructor(data: any = {}) {
        this.extends(data);
    }

    extends(data: any = {}): MultipleQuestionContent {
        Object.assign(this, data);

        if (data.choices) {
            this.choices = [];
            data.choices.map((choice) => this.choices.push(new MultipleQuestionChoice(choice)));
        }
        return this;
    }

    addChoice(checked: boolean): MultipleQuestionChoice {
        const choice = new MultipleQuestionChoice();
        choice.choice = '';
        choice.state = checked;
        this.choices.push(choice);

        return choice;
    }

    removeChoice(index: number) {
        this.choices.splice(index, 1);
    }
}

export class MultipleQuestionChoice {
    choice: string;
    state: boolean = false;
    mark: number;

    constructor(data: any = {}) {
        this.extends(data);
    }

    extends(data: any = {}): MultipleQuestionChoice {
        Object.assign(this, data);

        return this;
    }
}

export class MultipleQuestionParameters {
    listStyle: string;
    markMode: string;
    mode: string = 'progressive';

    constructor(data: any = {}) {
        this.extends(data);
    }

    extends(data: any = {}): MultipleQuestionParameters {
        Object.assign(this, data);

        return this;
    }
}
