import { Course } from '../course/course';
import { HttpClient } from "@angular/common/http";
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { cleanObject } from "../helpers";
import { environment } from 'src/environments/environment';

export class Folder {
	id: number;
	slug: string;
    courses: Course[];
    folders: Folder[];
    parents: any;
    title: string = '';
    updatedAt: string;
    pagination: any;
    folder: any;
    templatesCount: number;
    foldersCount: number;
    parentId?: number;
    permissions?: any[];
    new?: boolean;
    subfolders?: Folder[];
    editable?: boolean;
    type?: string;

	constructor(folder: any, private http: HttpClient) {
        this.extends(folder);
    }

    extends(folder:any): Folder {
    	Object.assign(this, folder);
        if (this.courses && this.courses.length > 0) {
            this.courses = this.courses.map((course) => new Course(course, this.http))
        }
        if (this.folders && this.folders.length > 0) {
            this.folders = this.folders.map((folder) => new Folder(folder, this.http))
        }
        if (this.subfolders && this.subfolders.length > 0) {
            this.subfolders = this.subfolders.map((folder) => new Folder(folder, this.http))
        }
    	return this;
    }

    delete(): Observable<void> {
    	return this.http.delete(`${environment.envVar.API_URL}/courses/folder/${this.id}`)
    	.pipe(map((res) => null));
    }

    update(): Observable<any> {
        return this.http.put(`${environment.envVar.API_URL}/courses/folder/${this.id}`, cleanObject(this))
        .pipe(map((folder) => this.extends(folder)));
    }

    addFolder(folder:any): Observable<Folder> {
        return this.http.post(`${environment.envVar.API_URL}/courses/folder`, folder)
        .pipe(map((res: any) => {
            this.folders.push(new Folder(res.folder, this.http));
            return this;
        }));
    }
}
