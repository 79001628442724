import { Injectable, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class WorkerService {
    private workersCollection: any[] = [];

    run(name: string, func: Function, listener?: boolean): void {
        const url = this.createUrl(func);
        const worker = new Worker(url);
        const item = { name, worker, emitter: new EventEmitter() };
        this.workersCollection.push(item);
        if (listener) {
            this.attachListener(item);
        }
    }

    stop(name: string): void {
        const worker = this.getWorker(name);
        if (worker) {
            worker.terminate();
            this.deleteWorker(name);
        }
    }

    post(name: string, data: any): void {
        const worker = this.getWorker(name);
        if (worker) {
            worker.postMessage(data);
        }
    }

    listen(name: string): Observable<any> {
        const item = this.workersCollection.find((worker) => {
            return worker.name === name;
        });

        return item ? item.emitter : null;
    }

    createUrl(func: Function): string {
        const template = `self.addEventListener('message', ${func});`;
        const blob = new Blob([template], { type: 'application/json' });

        return URL.createObjectURL(blob);
    }

    getWorker(name: string): Worker {
        const item = this.workersCollection.find((worker) => {
            return worker.name === name;
        });

        return item ? item.worker : null;
    }

    deleteWorker(name: string): void {
        const index = this.workersCollection.findIndex((worker) => {
            return worker.name === name;
        });

        if (index > -1) {
            this.workersCollection.splice(index, 1);
        }
    }

    attachListener(item: any): void {
        item.worker.addEventListener('message', (event) => {
            item.emitter.emit(event.data);
        });
    }
}
