<aside class="sidebar sidebar--modal" [ngClass]="active ? 'active' : ''" (appSidebar)="closeSidebarEmit()">
    <div class="sidebar__container">
        <div class="sidebar__head">
            <div class="sidebar__description">
                <h1 class="sidebar__title">{{ 'studio.template.choose' | translate }}</h1>
                <p class="sidebar__subtitle">{{ 'studio.template.select' | translate }}</p>
            </div>

            <a class="sidebar__close" (click)="closeSidebarEmit()">
                <i class="icon icon--close"></i>
            </a>
        </div>

        <div class="search">
            <label class="search__label">
                <i class="search__icon icon icon--search"></i>
            </label>
            <input class="search__input" type="search" placeholder="{{ 'words.search' | translate }}" #search (keyup)="searchTemplate(search.value)">
        </div>

        <ul class="box">
            <li class="box__item" *ngFor="let template of templates" (click)="chooseTemplate(template)" [ngClass]="{'active': isChoosed(template)}">
                <div class="modul modul--thumb">
                    <div class="modul__img modul__img--thumb">
                        <img src="https://static.myskillcamp.com/images/marketplace/categories/1024/office-productivity.jpg" alt="img">
                    </div>

                    <div class="modul__content box">
                        <h1 class="box__title">{{ template.title }}</h1>
                        <p class="box__description">
                        <span class="box__number">
                            {{ template.instancesCount }}
                        </span>
                            {{ ((template.instancesCount > 1) ? 'studio.instance.plural' : 'studio.instance.single') | translate }}
                        </p>
                    </div>
                </div>
            </li>
        </ul>
    </div>

    <footer class="sidebar__footer">
        <button (click)="createInstance()" class="button button--small button--color" [disabled]="!choosenTemplate">
            {{ 'studio.instance.create' | translate }}
            <i class="icon icon--arrow-right"></i>
        </button>
    </footer>
</aside>
