<div class="modal-header">
    <span></span>
    <button type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <div class="modal-content" style="border: none;">

        <h3 [innerHtml]="'words.session.unpublish.warn' | translate"></h3>
        <p>
            <i class="templab-icon-bulb"></i>
            <b>{{ '-hint' | translate }}:</b> {{ 'words.session.unpublish.hint' | translate }}
        </p>
    </div>

    <div class="modal-actions" style="display: flex; justify-content: flex-end; margin-top: 10px;">
        <button class="button button--border button--primary" (click)="close()">
            Ok
        </button>
    </div>
</div>
