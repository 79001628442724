import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CourseCampSession } from './course-camp-session';
import { environment } from 'src/environments/environment';

@Injectable()
export class CourseCampSessionProvider {
    defaultLang: string;
    courseCampSession?: CourseCampSession;

    constructor(public http: HttpClient) {}

    setCourseCampSession(courseCampSession: any | CourseCampSession): CourseCampSession {
        if (courseCampSession instanceof CourseCampSession) {
            this.courseCampSession = courseCampSession;
        } else {
            this.courseCampSession = new CourseCampSession(courseCampSession, this.http);
        }
        return this.courseCampSession;
    }

    getOrCreateCourseCampSession(courseCampId: number, preview: string): Observable<CourseCampSession> {
        return this.http.post(`${environment.envVar.API_URL}/session/course-camp-sessions?preview=${preview}`, {courseCampId})
            .pipe(map(res => this.setCourseCampSession(res)));
    }

    getCourseCampSessions(courseId: number): Observable<CourseCampSession[]> {
        return this.http.get(`${environment.envVar.API_URL}/session/course-camp-sessions/?course_id=${courseId}`)
            .pipe(map((courseCampSessions: any) => {
                return courseCampSessions.map((courseCampSession) => this.setCourseCampSession(courseCampSession))
            }));
    }

    getOrCreateCourseCampSessionForUpdate(courseCampSessionId: number, preview: string): Observable<CourseCampSession> {
        return this.http.get(`${environment.envVar.API_URL}/session/course-camp-sessions/${courseCampSessionId}?preview=${preview}`)
            .pipe(map(res => this.setCourseCampSession(res)));
    }
}
