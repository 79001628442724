import { Component, Input, OnInit, EventEmitter, ViewChild, ElementRef, HostListener } from '@angular/core';
import { Course, SectionProvider, UserProvider, SkillProvider, DataQualityProvider } from '@stuplay';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageService } from '../../../utils/services/storage.service';
import { ToastService } from 'src/app/utils/components/toast/toast.service';
import { RedirectService } from 'src/app/utils/services/redirect.service';
import { ExternalAppsService } from 'src/app/utils/services/external-apps.service';
import { LanguageProvider } from 'src/app/providers/language';
import { UploadInput, UploadOutput } from 'ngx-uploader';
import { environment } from 'src/environments/environment';
import { debounceTime, Subject, tap } from 'rxjs';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

export type CourseEditMode = 'draft' | 'live';

@Component({
    selector: 'app-course-presentation',
    templateUrl: './course-presentation.component.html',
    styleUrls: ['./course-presentation.component.scss']
})
export class CoursePresentationComponent implements OnInit {
    @Input() course: Course;
    @ViewChild('modalDraft') modalDraft: ElementRef;

    public uploadInput: EventEmitter<UploadInput> = new EventEmitter<UploadInput>();
    private me: any;
    private locks: boolean = false;
    public company: any;
    private skillsLevel: number[][] = [];
    public companies: any;
    public languages: any;
    public companiesAuthor: any;
    public selectedLanguage: any;
    public selectedCompany: any;
    public filteredLanguages: any[] = [];
    public skills: any = [];
    public displayDropdown: any = {
        skills: false,
        languages: false,
        type: false,
        company: false,
    };
    public error: any;
    public addSectionError: any;
    public displayAside: boolean = false;
    public loading: boolean = false;
    public coverFile;
    public uploading: boolean = false;
    public dragging: boolean = false;
    public isInfoMessageDisplayed: boolean = true;
    public isCourseCreator: boolean = false;
    public isLive: boolean = false;
    duration;
    courseUpdate = new Subject<any>();

    constructor(
        private sectionProvider: SectionProvider,
        private route: ActivatedRoute,
        private router: Router,
        private userProvider: UserProvider,
        private externalAppsService: ExternalAppsService,
        private storageService: StorageService,
        private skillProvider: SkillProvider,
        private languageProvider: LanguageProvider,
        private toastService: ToastService,
        private redirectService: RedirectService,
        private dataQualityProvider: DataQualityProvider,
        private modalService: NgbModal
    ) { }

    ngOnInit() {
        this.company = this.storageService.get('company');
        this.me = this.storageService.get('me');
        this.course = this.route.snapshot.data.course;
        this.duration = this.course.duration / 3600;
        this.isLive = this.course.editMode === 'live';
        this.isCourseCreator = this.course.accountId === this.me.id;
        this.selectedLanguage = this.course ? this.course.language : this.me.language;
        this.skillsLevel[0] = [];
        this.skillsLevel[1] = [];
        this.getSkills();
        this.onQueryCompaniesAuthor();

        this.languageProvider.get('content').subscribe((resp) => {
            this.languages = resp;
            this.filteredLanguages = this.languages;
        });

        this.courseUpdate.pipe(debounceTime(2000)).subscribe(() => {
            if (this.duration < 0) {
                this.duration = 0;
            }
            this.course.duration = Math.round(this.duration * 3600);
            this.updateCourse();
        });
    }

    ngAfterViewInit() {
        if (this.isCourseCreator && this.course.isAttachedToACollection && this.isLive) {
            const options: NgbModalOptions = {
                size: 'l',
                centered: true,
                backdrop: 'static',
                keyboard: false,
            };
            this.openModal(this.modalDraft, options);
        }
    }

    openModal(content: any, options: NgbModalOptions = { size: 'xl', centered: true, backdropClass: 'backdrop-custom' }) {
        this.modalService.open(content, options);
    }

    getSkills(): void {
        this.loading = true;
        const params = {
            context: 'course_template',
            context_id: this.course.schema === 'template' ? this.course.id : this.course.templateId,
            company_id: this.company.slug
        };

        this.skillProvider.getCompanySkills(params).subscribe((data) => {
            this.skills = data.skills;
            this.loading = false;
        });
    }

    setCompanyOwnership(data) {
        this.companiesAuthor = data;

        if (this.course && this.course.company) {
            this.selectedCompany = this.course.company;
            return;
        }

        const company = data.find(c => c.id === this.company.id);
        this.updateCompanyOwnership(company);
    }

    onQueryCompaniesAuthor() {
        if (!this.isCourseCreator) {
            return;
        };

        this.dataQualityProvider
            .getCompanies({ role: 'author' })
            .pipe(
                tap(resp => this.setCompanyOwnership(resp.companies))
            )
            .subscribe()
    }

    onDurationUpdate(duration: any): void {
        this.course.duration = duration;
        this.updateCourse();
    }

    onWeeklyWorkingDurationUpdate(duration: any): void {
        this.course.weeklyWorkingDuration = duration;
        this.updateCourse();
    }

    onUpdateMedia(media: any): void {
        this.course.media = media;
        this.updateCourse();
    }

    updateCourse(): void {
        this.course.update(this.company.id)
            .subscribe(
                () => { },
                (error) => {
                    this.error = error
                }
            );
    }

    updateCompanyOwnership(company): void {
        this.selectedCompany = company;
        const params = {
            company_id: company.id,
            contents: [{
                context: 'course',
                context_id: this.course.id
            }]
        }
        
        this.dataQualityProvider.editCompanyContent(params).subscribe();
    }

    onDismissInfoMessage() {
        this.isInfoMessageDisplayed = false;
    }

    onStatusLive(): void {
        const options: NgbModalOptions = {
            size: 'l',
            centered: true,
            backdrop: 'static',
            keyboard: false,
        };
        this.openModal(this.modalDraft, options);
        this.setCourseEditMode('live');
    }

    goToPreview(): void {
        this.redirectService.navigate('player', ['course', this.course.id], { preview: true });
    }

    goToCatalogue(): void {
        this.modalService.dismissAll();
        this.router.navigate(['company', this.storageService.get('company').slug, 'home']);
    }

    onContinueEditiong(): void {
        this.setCourseEditMode('draft');
        this.modalService.dismissAll();
}

    setCourseEditMode(mode: CourseEditMode) {
        const params = {
            companyId: this.company.id,
            status: mode
        };
        this.dataQualityProvider.editCourseMode(this.course.id, params).subscribe((resp: Course) => {
            this.isLive = resp.editMode === 'live';
        });
    }

    deleteSection(id: number): void {
        if (this.course) {
            let selectedSection = this.course.sections.find(section => section.id === id);
            const selectedSectionIndex = this.course.sections.findIndex(section => section.id === id);
            selectedSection.delete().subscribe(() => {
                this.course.sections.splice(selectedSectionIndex, 1);
                selectedSection = null;
            });
        }
        this.course.update(this.company.id).subscribe((course) => this.course = course);
    }

    addSection(): void {
        if (!this.locks) {
            this.locks = true;
            this.course.addSection().subscribe(() => {
                this.locks = false;
            }, (error) => {
                this.addSectionError = error;
                this.locks = false;
            });
        }
    }

    displayed(key: string): void {
        Object.keys(this.displayDropdown).forEach((objKey) => {
            if (objKey === key) {
                this.displayDropdown[key] = !this.displayDropdown[key];
            } else {
                this.displayDropdown[objKey] = false;
            }
        })
    }

    selectLanguage(language: any): void {
        this.selectedLanguage = language;
        this.course.languageId = language.id;
        this.updateCourse();
    }

    filterLanguages(event: string) {
        if (typeof event === 'string') {
            this.filteredLanguages = this.languages.filter(lang => lang.name ? lang.name.toLowerCase().startsWith(event.toLowerCase()) : false);
        }
    }

    openAside(): void {
        this.displayAside = true;
    }

    closeAside(): void {
        this.displayAside = false;
    }

    isLevel(index: number, skill: any, levelExpected: number, thematic?: boolean): boolean {
        if (this.skillsLevel[thematic ? 0 : 1][index]) {
            return this.skillsLevel[thematic ? 0 : 1][index] > levelExpected;
        }
        return skill.level > levelExpected;
    }

    displayLevel(index: number, level?: number, thematic?: boolean): void {
        this.skillsLevel[thematic ? 0 : 1][index] = level;
    }

    updateLevel(skill: any, level: number): void {
        if (this.course.schema === 'template') {
            skill.level = level;
            const params = {
                context: 'course_template',
                context_id: this.course.id,
                level
            };

            this.skillProvider.updateLevel(skill.id, params).subscribe();
        }
    }

    attachSkill(skill: any, level: number): void {
        const params = {
            context: 'course_template',
            context_id: this.course.id,
            company_id: this.company.slug,
            level
        };

        this.skillProvider.attach(skill.id, params).subscribe(() => {
            skill.level = level;
            this.skills.push(skill);
        });
    }

    detachSkill(skill: any): void {
        const params = {
            context: 'course_template',
            context_id: this.course.id
        };

        this.skillProvider.detach(skill.id, params).subscribe(() => {
            const index = this.skills.findIndex((data) => {
                return data.id === skill.id;
            });

            if (index !== -1) {
                this.skills.splice(index, 1);
            }
        });
    }

    eventDispatcher(event: any): void {
        switch (event.type) {
            case 'attach':
                this.attachSkill(event.skill, event.level);
                break;
            case 'detach':
                this.detachSkill(event.skill);
                break;
            case 'level':
                this.updateLevel(event.skill, event.level);
                break;
        }
    }

    filterSkills(thematic?: boolean): any {
        return this.skills.filter((skill) => {
            if (thematic && skill.thematic) {
                return skill;
            }

            if (!thematic && !skill.thematic) {
                return skill;
            }
        });
    }

    onUploadOutput(output: UploadOutput): void {
        this.uploading = true;
        if (output.type === 'allAddedToQueue') {
            // update current data in files array for uploading file
            if (this.coverFile && this.coverFile.size > 2000000000) {
                this.toastService.push('file-too-big', 'error');
            } else {
                const event: UploadInput = {
                    type: 'uploadAll',
                    url: `${environment.envVar.API_URL}/media`,
                    method: 'POST',
                    data: {},
                    headers: {
                        'Authorization': 'Bearer ' + sessionStorage.getItem(environment.tokenSessionName)
                    }
                };
                this.uploadInput.emit(event);
            }
        } else if (output.type === 'addedToQueue') {
            this.coverFile = output.file;
        } else if (output.type === 'uploading') {
            // update current data in files array for uploading file
            this.coverFile = output.file;
            this.dragging = false;
        } else if (output.type === 'removed') {
            // remove file from array when removed
            this.coverFile = null
        } else if (output.type === 'dragOver') { // drag over event
            this.dragging = true;
        } else if (output.type === 'dragOut') { // drag out event
            this.dragging = false;
        } else if (output.type === 'done') {
            this.uploading = false;
        } else if (output.type === 'start') {
            const interval = setInterval(() => {
                if (output.file.progress.status === 2) {
                    output.file.progress.data.percentage = 125;
                    setTimeout(() => {
                        this.uploading = false;
                        this.onUpdateMedia(output.file.response);
                        this.coverFile = null;
                    }, 1000);
                    clearInterval(interval);
                }
            }, 1000);
        }
    }

    @HostListener('window:popstate', ['$event'])
    onPopState(event) {
        this.modalService.dismissAll();
    }
}
