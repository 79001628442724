import { Media } from '../../../../../media';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { factoryQuestion } from './question.factory';
import { cleanObject } from "../../../../../helpers";
import { environment } from 'src/environments/environment';

export class Question {
    id: number;
    title: string;
    description: string;
    type: string;
    position: number;
    isValid: boolean;
    hint: string;
    hintMedia: Media;
    feedbackDefault: string;
    feedbackDefaultMedia: Media;
    feedbackWrongAnswer: string;
    feedbackWrongAnswerMedia: Media;
    scoreMax: number;
    tryMax: number;
    timer: number;
    media: Media;
    ratio?: number;

    constructor(question: any, protected http: HttpClient) {
        this.extends(question);
    }

    extends(question: any): Question {
        Object.assign(this, question);

        return this;
    }

    postAnswer(answer: any): Observable<any> {
        return this.http.post('', answer);
    }

    update(questionType:string): Observable<any> {
        this.beforeSave();
        let baseObs: Observable<any>;
        if (this.id) {
            baseObs = this.http.put(`${environment.envVar.API_URL}/studio/question/${this.id}`, cleanObject(this))
        } else {
            baseObs = this.http.post(`${environment.envVar.API_URL}/studio/question`, cleanObject(this));
        }

        return baseObs.pipe(map((question) =>  factoryQuestion(questionType, question, this.http)),
            map((response) =>  {return response}));
    }

    delete(): Observable<void> {
        return this.http.delete(`${environment.envVar.API_URL}/studio/question/${this.id}`).pipe(map((response: any) => null));
    }

    protected beforeSave(): void {}
}
