import { Component, ElementRef, HostListener, Input } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { RedirectService } from '../../services/redirect.service';
import { TranslationService } from '../../services/translation.service';

@Component({
    selector: 'msc-account',
    templateUrl: './account.component.html',
    styleUrls: ['./account.component.less']
})
export class AccountComponent {
    @Input() currentUser: any;
    isOpen: boolean = false;

    constructor(
        private authService: AuthService,
        private translate: TranslationService,
        private eRef: ElementRef,
        private redirectService: RedirectService,
    ) { }

    logout(): void {
        this.authService.logout();
    }

    toggleDropdown(): void {
        this.isOpen = !this.isOpen;
    }

    navigateProfile(): void {
        this.redirectService.navigate('app', ['account', 'profile']);
    }

    @HostListener('document:click', ['$event'])
    clickout(event: any): void {
        if (!this.eRef.nativeElement.contains(event.target)) {
            this.isOpen = false;
        }
    }
}
