import { HttpParams } from "@angular/common/http";

export function getParams(params: any): HttpParams {
    let httpParams = new HttpParams();
    for (const p in params) {
        httpParams = httpParams.append(p, params[p]);
    }

    return httpParams;
}

export function cleanObject(object: any = {}): any {
    const newObject = {};
    for (const o in object) {
        if (o !== 'http' && o !== 'stuplayConfig' && typeof object[o] !== 'function') {
            if (object[o] instanceof Array) {
                if (object[o].length > 0) {
                    const subObject = {};
                    for (let i = 0; i < object[o].length; i++) {
                        subObject[i] = (typeof object[o][i] === 'string' || typeof object[o][i] === 'number') ? object[o][i] : cleanObject(object[o][i]);
                    }
                    newObject[o] = subObject;
                }
            } else if (typeof object[o] === 'object' && object[o] !== null) {
                newObject[o] = cleanObject(object[o]);
            } else {
                newObject[o] = object[o];
            }
        }
    }

    return newObject;
}
