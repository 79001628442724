import { Injectable, EventEmitter } from '@angular/core';

@Injectable()
export class RefreshAuthService {
    private refreshEmitter: EventEmitter<any> = new EventEmitter;
    private logoutEmitter: EventEmitter<any> = new EventEmitter;

    listenRefresh(): EventEmitter<any> {
        return this.refreshEmitter;
    }

    listenLogout(): EventEmitter<any> {
        return this.logoutEmitter;
    }

    refresh(): void {
        this.refreshEmitter.emit(true);
    }

    logout(): void {
        this.logoutEmitter.emit(true);
    }
}
