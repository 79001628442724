<aside class="sidebar sidebar--modal sidebar-subscription" [ngClass]="active ? 'active' : ''" (appSidebar)="closeSidebarEmit()">
    <div class="sidebar__container">
        <div class="sidebar__head sidebar__head--center">
            <div class="sidebar__description">
                <h1 class="sidebar__title">
                    {{ (type === 'summary' ? 'words.summary-publication' : 'studio.instance.' + type) | translate }}
                </h1>
            </div>

            <a class="sidebar__close" (click)="closeSidebarEmit()">
                <i class="icon icon--close"></i>
            </a>
        </div>

        <div class="sidebar__body">
            <div class="sidebar__form" *ngIf="type !== 'summary'">
                <div class="sidebar__fieldset">
                    <label>{{ 'studio.instance.name' | translate }}</label>
                    <input autocomplete="off" type="text" class="input" [placeholder]="'studio.instance.name' | translate" [(ngModel)]="data.internal_name">
                </div>

                <div class="load-more animate" *ngIf="loadEvents">
                    <div class="dot"></div>
                </div>

                <div class="sidebar__fieldset sidebar__fieldset--bg" *ngIf="eventsList && eventsList.length > 0">
                    <div class="sidebar__toggle" [ngClass]="{'active': displays.events}" (click)="displayed('events')">
                        <p>
                            {{ 'studio.publish.events-overview' | translate }}
                        </p>

                        <i class="icon icon--arrow-bottom"></i>
                    </div>

                    <div class="sidebar__events">
                        <div class="sidebar__params sidebar__params--border" *ngFor="let event of eventsList; let index=index">
                            <div class="sidebar__input">
                                <label>{{ event.title }}</label>
                            </div>

                            <div class="sidebar__form-group">
                                <div class="sidebar__input" style="position: relative;">
                                    <label>{{ 'studio.event.event-session.start-at' | translate }}</label>

                                    <div class="input">
                                        <input type="text"
                                            [placeholder]="'studio.event.event-session.start-at' | translate"
                                            [ngModel]="eventDate(index, 'startAt')"
                                            (ngModelChange)="updateEventDate(index, 'startAt', $event)"
                                            [disabled]="event.readOnly" (click)="activeDatepicker(index, 'startAt')">
                                        <i *ngIf="!event.readOnly" class="new-icon icon-arrow-ios-downward-outline"></i>
                                    </div>

                                    <msc-datepicker *ngIf="!event.readOnly && datePickerActive[index]['startAt']"
                                        [class]="'small'" [date]="eventDate(index, 'startAt')" [reset]="false"
                                        (onSelectDate)="updateEventDate(index, 'startAt', $event)"
                                        (close)="activeDatepicker(index, 'startAt', true)"></msc-datepicker>
                                </div>

                                <div class="sidebar__input" [ngClass]="{'error': formErrors['startAt']}">
                                    <label>{{ 'studio.instance.hour-start' | translate }}</label>
                                    <input type="text" class="input"
                                        pattern="^([0-1][0-9]|2[0-3]):([0-5][0-9])$"
                                        [placeholder]="'studio.instance.hour-start' | translate"
                                        [disabled]="event.readOnly" [ngModel]="eventHour(index, 'startAt')"
                                        (ngModelChange)="updateEventHour(index, 'startAt', $event)">
                                    <div *ngIf="formErrors['startAt']" class="error-message">{{ 'studio.session.invalid-time' | translate: {value: 'start time'} }}</div>
                                </div>
                            </div>

                            <div class="sidebar__form-group">
                                <div class="sidebar__input" style="position: relative;">
                                    <label>{{ 'studio.event.event-session.end-at' | translate }}</label>

                                    <div class="input">
                                        <input type="text"
                                            [placeholder]="'studio.event.event-session.end-at' | translate"
                                            [disabled]="event.readOnly" [ngModel]="eventDate(index, 'endAt')"
                                            (ngModelChange)="updateEventDate(index, 'endAt', $event)"
                                            (click)="activeDatepicker(index, 'endAt')">
                                        <i *ngIf="!event.readOnly" class="new-icon icon-arrow-ios-downward-outline"></i>
                                    </div>

                                    <msc-datepicker *ngIf="!event.readOnly && datePickerActive[index]['endAt']"
                                        [class]="'small'" [date]="eventDate(index, 'endAt')" [reset]="false"
                                        (onSelectDate)="updateEventDate(index, 'endAt', $event)"
                                        (close)="activeDatepicker(index, 'endAt', true)"></msc-datepicker>
                                </div>

                                <div class="sidebar__input" [ngClass]="{'error': formErrors.endAt}">
                                    <label>{{ 'studio.instance.hour-end' | translate }}</label>
                                    <input type="text" class="input"
                                        pattern="^([0-1][0-9]|2[0-3]):([0-5][0-9])$"
                                        [placeholder]="'studio.instance.hour-end' | translate"
                                        [disabled]="event.readOnly" [ngModel]="eventHour(index, 'endAt')"
                                        (ngModelChange)="updateEventHour(index, 'endAt', $event)">
                                    <div *ngIf="formErrors.endAt" class="error-message">{{ 'studio.session.invalid-time' | translate: {value: 'end time'} }}</div>
                                </div>
                            </div>

                            <div class="sidebar__input" *ngIf="event.type === 'place'">
                                <label>{{ 'studio.instance.address' | translate }}</label>
                                <input autocomplete="off" type="text" class="input" [disabled]="event.readOnly"
                                    [placeholder]="'studio.instance.address' | translate" [(ngModel)]="event.address">
                            </div>

                            <div class="sidebar__input" *ngIf="event.type === 'visio'">
                                <label>{{ 'studio.event.event-session.type.visio.description' | translate }}</label>
                                <input autocomplete="off" type="text" class="input" [disabled]="event.readOnly"
                                    [placeholder]="'studio.event.event-session.type.visio.description' | translate"
                                    [(ngModel)]="event.link">
                            </div>

                            <div class="sidebar__input">
                                <label>{{ 'studio.event.event-session.additional-informations' | translate }}</label>
                                <textarea autocomplete="off" class="textarea" [(ngModel)]="event.info"
                                    [disabled]="event.readOnly"></textarea>
                            </div>

                            <div class="sidebar__input">
                                <label class="input">
                                    <input type="checkbox" class="input-checkbox" [(ngModel)]="event.isAttendanceMandatory" [disabled]="event.readOnly" [mscTooltip]="'words.presence-mandatory.tooltip' | translate">
                                    {{ 'words.presence-mandatory' | translate }}
                                </label>
                            </div>

                            <div class="sidebar__input" *ngIf="event.type === 'virtual-class'">
                                <label>{{ 'studio.instance.virtual-class.create-room' | translate }}</label>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="sidebar__fieldset sidebar__fieldset--bg">
                    <div class="sidebar__toggle" [ngClass]="{'active': displays.settings}" (click)="displayed('settings')">
                        <p>
                            <i class="new-icon icon-settings-outline"></i>
                            {{ 'words.advanced-settings' | translate }}
                        </p>

                        <i class="icon icon--arrow-bottom"></i>
                    </div>

                    <div class="sidebar__events">
                        <label class="input select__label">{{ 'studio.course.language' | translate }}</label>
                        <div class="dropdown dropdown-select dropdown-overflow" [ngClass]="{'active': displays.language}" (click)="displayed('language')">
                            <div class="dropdown__label">
                                {{ selectedLanguage?.name }}
                                <i class="new-icon icon-arrow-ios-downward-outline"></i>
                            </div>

                            <div class="dropdown__list">
                                <div class="dropdown-select__filter">
                                    <input class="" type="text" [placeholder]="'placeholder.search' | translate" [(ngModel)]="filterInput" [debounce]="500" (onDebounce)="filterLanguages($event)" (click)="$event.stopPropagation()" />
                                </div>

                                <div class="dropdown__items">
                                    <div class="dropdown__item" *ngFor="let language of filteredLanguages" (click)="selectLanguage(language)">
                                        {{ language.name }}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <label>{{ 'studio.instance.reference' | translate }}</label>
                        <input type="text" class="input" [placeholder]="'studio.instance.reference' | translate" [(ngModel)]="data.reference">

                        <label>{{ 'studio.journey.users-limit' | translate }}</label>
                        <input type="text" class="input" [placeholder]="'studio.journey.users-limit.title' | translate" [(ngModel)]="data.users_limit">

                        <label>{{ 'studio.instance.discussion-visibility' | translate }}</label>
                        <div class="dropdown dropdown-select dropdown_active" [ngClass]="{'active': displays.visibility}" (click)="displayed('visibility')">
                            <div class="dropdown__label">
                                {{ 'studio.instance.discussion.' + data.discussion_mode | translate }}
                                <i class="new-icon icon-arrow-ios-downward-outline"></i>
                            </div>

                            <div class="dropdown__list">
                                <div class="dropdown__item" *ngIf="data.discussion_mode !== 'private'" (click)="setData('discussion_mode', 'private')">
                                    {{ 'studio.instance.discussion.private' | translate }}
                                </div>

                                <div class="dropdown__item" *ngIf="data.discussion_mode !== 'shared'" (click)="setData('discussion_mode', 'shared')">
                                    {{ 'studio.instance.discussion.shared' | translate }}
                                </div>
                            </div>
                        </div>

                        <div class="dropdown dropdown-users" [ngClass]="{'dropdown_active': displays.trainers}" [activeClass]="'dropdown_active'" [(autoClose)]="displays.trainers">
                            <a class="link link--plus" (click)="displayed('trainers')">
                                {{ 'words.add-trainers' | translate }}
                                <i class="icon icon--add"></i>
                            </a>

                            <div class="dropdown__content" style="max-height: 250px; overflow: auto;">
                                <div class="dropdown__search">
                                    <div class="search">
                                        <label class="search__label">
                                            <i class="search__icon icon icon--search"></i>
                                        </label>

                                        <input class="search__input" type="search" [placeholder]="'words.search' | translate" [(ngModel)]="query" [debounce]="500" (onDebounce)="getTrainers()">
                                    </div>
                                </div>

                                <ul class="dropdown__users">
                                    <li *ngFor="let trainer of trainers" (click)="addTrainer(trainer)">
                                        <label [attr.for]="'trainer-' + trainer.id">
                                            <input type="checkbox" class="input-checkbox" [name]="'trainer-' + trainer.id" [checked]="isTrainerSelected(trainer.id)">

                                            <img [src]="trainer.picture + '?size=256'" alt="" class="avatar">
                                            <span>{{ trainer.name }}</span>
                                        </label>
                                    </li>
                                </ul>

                                <button class="show-more show-more--no-margin" *ngIf="pagination.currentPage < pagination.lastPage" (click)="loadMoreTrainers($event)" [disabled]="loadingTrainers">
                                    <span>{{ 'words.load-more' | translate }}</span>
                                </button>
                            </div>
                        </div>

                        <div class="tag tag--former" *ngFor="let trainer of trainersSelected">
                            <img class="avatar avatar--medium" [src]="trainer.picture + '?size=256'">
                            <p class="tag__name">{{ trainer.name }}</p>
                            <i class="icon icon--close" (click)="addTrainer(trainer)"></i>
                        </div>
                    </div>
                </div>
            </div>

            <div class="sidebar__content sidebar__content--thin" *ngIf="type === 'summary'">
                <h2 class="sidebar__subtitle sidebar__subtitle--highlight">
                    {{ 'words.create-instance' | translate }}
                </h2>

                <p class="sidebar__text sidebar__text--center">
                    {{ 'words.create-session.description' | translate }}
                </p>

                <div class="sidebar__action">
                    <button class="btn btn--primary" (click)="selectCamps()">
                        <i class="icon icon--publish"></i>
                        {{ 'studio.instance.create-publish' | translate }}
                    </button>

                    <p>{{ 'words.or' | translate | uppercase }}</p>
                </div>

                <div class="sidebar__publishing-option">
                    <div class="box-module" (click)="new()">
                        <div class="box-module__icon">
                            <i class="icon icon--calendar"></i>
                        </div>

                        <div class="box-module__text">
                            <p>{{ 'words.create-another-instance' | translate }}</p>
                        </div>
                    </div>

                    <div class="box-module" (click)="customize()">
                        <div class="box-module__icon">
                            <i class="new-icon icon-edit-outline"></i>
                        </div>

                        <div class="box-module__text">
                            <p>{{ 'words.customize-instance' | translate }}</p>
                        </div>
                    </div>

                    <div class="box-module" (click)="closeSidebarEmit()">
                        <div class="box-module__icon">
                            <i class="icon icon--check"></i>
                        </div>

                        <div class="box-module__text">
                            <p>{{ 'words.finish' | translate }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="sidebar__footer" *ngIf="type !== 'summary'">
            <button *ngIf="type === 'create'" class="btn btn--primary" (click)="createInstance()" [disabled]="formErrors.startAt || formErrors.endAt">
                <div class="lds-ring" *ngIf="loading">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>

                <span>{{ 'studio.instance.save' | translate }}</span>
            </button>

            <button *ngIf="type === 'edit'" class="btn btn--primary" (click)="updateInstance()" [disabled]="formErrors.startAt || formErrors.endAt" style="margin: 0 0.7rem 0 0;">
                <span>{{ 'studio.instance.modify' | translate }}</span>
            </button>

            <button *ngIf="type === 'edit'" class="btn btn--primary"
                (click)="selectCamps()" [disabled]="formErrors.startAt || formErrors.endAt">
                <span>{{ 'studio.instance.create-publish' | translate }}</span>
            </button>
        </div>
    </div>
</aside>
