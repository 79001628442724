import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable()
export class FullstoryService {
    private user: any;
    private script: any;
    private load: boolean = false;

    init(user: any): void {
        if (!this.load && environment.production) {
            this.user = user;
            this.load = true;
            this.script = "FS.identify('" + this.user.id + "', {displayName: '" + this.user.lastname + ' ' + this.user.firstname + "', email: '" + this.user.email + "'});";
            const content = document.createElement('script');
            content.innerHTML = this.script;
            document.head.appendChild(content);
        }
    }
}
