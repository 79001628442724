import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Journey, JourneyProvider } from '@stuplay';
import { HttpHelper, DataHelper } from '../../../../../../utils/helpers';
import { TranslationService } from 'src/app/utils/services/translation.service';
import { StorageService } from 'src/app/utils/services/storage.service';

export const DEFAULT_PROVIDER_LIST_KEYS: ReadonlyArray<string> = [
    'appcues',
    'bookboon',
    'cefora',
    'cegos',
    'coursera',
    'edflex-blue',
    'email-apside',
    'freshchat',
    'goodhabitz',
    'gotowebinar',
    'mymooc',
    'openclassroom',
    'trainingexpress',
    'udemy',
    'youtube',
];

@Component({
    selector: 'journey-library-content',
    templateUrl: './library-content.component.html'
})
export class JourneyLibraryContentComponent implements OnInit {
    @Input() journey: Journey;
    @Input() content: any;
    @Output() onClose: EventEmitter<any> = new EventEmitter();
    @Output() onSave: EventEmitter<any> = new EventEmitter()
    @Output() onEdit: EventEmitter<any> = new EventEmitter();
    public company: any;
    public selectedContents: any[] = [];
    public selectedTypes: any = [];
    public selectedProviders: any[] = [];
    public selectedLanguageIds: number[] = [];
    public selectedSkills: any[] = [];
    public availableContentTypes: any;
    public availableCourseTypes: any;
    public availableExternalResourceTypes: any;
    public availableExternalContentTypes: any;
    public availableProviders: any;
    public availableLanguages: any;
    public availableSkills: any;
    public contents: any[];
    public pagination: any;
    public query: string = '';
    public mySelection: boolean = false;
    public filtersActive: boolean = false;
    public replaceMode: boolean = false;
    public orderDate: string = '-date';
    public loaders = {
        contents: false,
        loadMoreContents: false,
    };
    public displays: any = {
        contents: false,
        providers: false,
        languages: false,
        skills: false,
        sort: false
    };
    public filters: any = {
        contents: [],
        courses: [],
        externalResources: [],
        externalContents: [],
        providers: [],
        languages: [],
        skills: [],
        order: '-date'
    };

    constructor(
        private journeyProvider: JourneyProvider,
        private storageService: StorageService,
        private translateService: TranslationService
    ) { }

    ngOnInit(): void {
        this.company = this.storageService.get('company');
        this.loaders.contents = true;

        const params = {
            companyId: this.company?.id
        }

        if (this.content) {
            this.replaceMode = true;
        }
        
        this.searchContents();

        this.journeyProvider.getMeta(this.journey.id, params).subscribe((data) => {
            this.availableContentTypes = this.sortProcess(data.meta.contentTypes, 'filter.content-type.');
            this.availableCourseTypes = data.meta.courseTypes;
            this.availableExternalContentTypes = data.meta.externalContentTypes;
            this.availableExternalResourceTypes = data.meta.externalResourceTypes;
            this.availableProviders = this.sortProviders(data.meta.providers);
            this.availableLanguages = this.getAvailableLanguages(data.meta.languages);
            this.availableSkills = data.meta.contentSkills;
        });

        const element: any = document.getElementById('main-content');
        const scroll: any = {
            block: 'start',
            inline: 'nearest',
            behavior: 'smooth'
        };
        element.scrollIntoView(scroll);
    }

    resetDisplays(): void {
        this.displays = {
            contents: false,
            providers: false,
            languages: false,
            skills: false,
            sort: false
        };
    }

    getAvailableLanguages(metaLanguages: any): any {
        return metaLanguages.map((lang) => {
            if (lang.id === 'undefined') {
                return {
                    id: lang.id,
                    name: this.translateService.instant('words.multilingual'),
                    count: lang.count
                }
            }
            return lang;
        });
    }

    sortProviders(providers: any): any {
        const sortedArray = [];
        for (const provider of providers) {
            const data = {
                id: provider.id,
                translate: provider.id === 'company' ? this.company?.name : provider.name
            };
            sortedArray.push(data);
        }

        return sortedArray;
    }

    sortProcess(collection: any, translateKey: string): any {
        const sortedArray = [];
        for (const key in collection) {
            if (collection.hasOwnProperty(key)) {
                const data = { key, value: collection[key], translate: this.translateService.instant(translateKey + DataHelper.changeCase(key)) };
                sortedArray.push(data);
            }
        }

        return sortedArray.sort((a, b) => {
            return a.translate.localeCompare(b.translate);
        });
    }

    loadMoreContent(): void {
        const params = HttpHelper.cleanParams({
            companyId: this.company?.id,
            page: this.pagination.currentPage + 1,
            q: this.query,
            course_types: this.filters && this.filters.courses && this.filters.courses.length > 0 ? this.filters.courses.map((item) => DataHelper.changeCase(item)).join() : null,
            external_content_types: this.filters && this.filters.externalContents && this.filters.externalContents.length > 0 ? this.filters.externalContents.map((item) => DataHelper.changeCase(item)).join() : null,
            external_resource_types: this.filters && this.filters.externalResources && this.filters.externalResources.length > 0 ? this.filters.externalResources.map((item) => DataHelper.changeCase(item)).join() : null,
            content_types: this.filters && this.filters.contents && this.filters.contents.length > 0 ? this.filters.contents.map((item) => DataHelper.changeCase(item)).join() : null,
            providers: this.filters.providers.length > 0 ? this.filters.providers.join(',') : null,
            langs: this.filters.languages.length > 0 ? this.filters.languages.join(',') : null,
            order: this.filters ? this.filters.order : null,
            'skills[]': this.filters.skills.length > 0 ? this.filters.skills : null,
            'skill_level[]': this.filters.skills.length > 0 ? [0, 1, 2, 3] : null
        });

        this.loaders.loadMoreContents = true;
        this.journeyProvider.getTemplatesAndProviderContents(this.journey.id, params).subscribe((data) => {
            this.contents = this.contents.concat(data.contents);
            this.pagination = data.pagination;
            this.loaders.loadMoreContents = false;
        })
    }

    selectContent(content: any): void {
        if (!this.replaceMode) {
            if (this.isContentSelected(content)) {
                const index = this.selectedContents.findIndex(c => c.data.id === content.data.id);
                this.selectedContents.splice(index, 1);
            } else {
                this.selectedContents.push(content);
            }

            if (this.mySelection) {
                this.contents = Object.assign([], this.selectedContents);
            }

            if (this.mySelection && this.selectedContents.length === 0) {
                this.mySelection = false;
                this.searchContents();
            }
        } else {
            this.selectedContents = [content];
        }
    }

    showMySelection(): void {
        if (this.mySelection) {
            this.contents = Object.assign([], this.selectedContents);
        } else {
            this.searchContents();
        }
    }

    isContentSelected(content: any): boolean {
        const index = this.selectedContents.findIndex(c => c.data.id === content.data.id);

        return index > -1;
    }

    searchContents() {
        const params = HttpHelper.cleanParams({
            companyId: this.company?.id,
            q: this.query,
            course_types: this.filters && this.filters.courses && this.filters.courses.length > 0 ? this.filters.courses.map((item) => DataHelper.changeCase(item)).join() : null,
            external_content_types: this.filters && this.filters.externalContents && this.filters.externalContents.length > 0 ? this.filters.externalContents.map((item) => DataHelper.changeCase(item)).join() : null,
            external_resource_types: this.filters && this.filters.externalResources && this.filters.externalResources.length > 0 ? this.filters.externalResources.map((item) => DataHelper.changeCase(item)).join() : null,
            content_types: this.filters && this.filters.contents && this.filters.contents.length > 0 ? this.filters.contents.map((item) => DataHelper.changeCase(item)).join() : null,
            providers: this.filters.providers.length > 0 ? this.filters.providers.join(',') : null,
            langs: this.filters.languages.length > 0 ? this.filters.languages.join(',') : null,
            order: this.filters ? this.filters.order : null,
            skills: this.filters.skills.length > 0 ? this.filters.skills.join(',') : null,
            skill_level: this.filters.skills.length > 0 ? '0,1,2,3' : null
        });

        this.loaders.contents = true;
        this.journeyProvider.getTemplatesAndProviderContents(this.journey.id, params).subscribe((data) => {
            this.contents = data.contents;
            this.pagination = data.pagination;
            this.loaders.contents = false;
        });
    }

    displayed(type: string): void {
        if (!this.displays[type]) {
            this.resetDisplays();
        }
        this.displays[type] = !this.displays[type];

        if (this.displays[type]) {
            this.selectedTypes = HttpHelper.cleanParams({
                content: this.filters && this.filters.contents ? this.filters.contents : null,
                course: this.filters && this.filters.courses ? this.filters.courses : null,
                externalContent: this.filters && this.filters.externalContents ? this.filters.externalContents : null,
                externalResource: this.filters && this.filters.externalResources ? this.filters.externalResources : null,
            });
            this.selectedProviders = this.filters && this.filters.providers ? this.filters.providers : [];
            this.selectedLanguageIds = this.filters && this.filters.languages ? this.filters.languages : [];
            this.selectedSkills = this.filters && this.filters.skills ? this.filters.skills : [];
        } else {
            switch (type) {
                case 'providers':
                    this.selectedProviders = [];
                    break;
                case 'languages':
                    this.selectedLanguageIds = [];
                    break;
                case 'skills':
                    this.selectedSkills = [];
                    break;
                default:
                    this.selectedTypes = [];
            }
        }
    }

    save(): void {
        this.onSave.emit(this.selectedContents);
    }

    edit(): void {
        this.onEdit.emit(this.selectedContents);
    }

    isIntermediate(type: string): boolean {
        if (this.selectedTypes[type]) {
            const length = this.selectedTypes[type].length;
            if (type === 'course' && length < Object.keys(this.availableCourseTypes).length) {
                return true;
            } else if (type === 'externalContent' && length < Object.keys(this.availableExternalContentTypes).length) {
                return true;
            } else if (type === 'externalResource' && length < Object.keys(this.availableExternalResourceTypes).length) {
                return true;
            }
        }
        return false;
    }

    isSelectedTypes(key: string, type: string): boolean {
        if (!this.selectedTypes[key]) {
            this.selectedTypes[key] = [];
        }
        return this.selectedTypes[key].indexOf(type) !== -1;
    }

    isSelectedProvider(providerId: string | number): boolean {
        return this.selectedProviders.indexOf(providerId) !== -1;
    }

    isSelectedLanguage(languageId: number): boolean {
        return this.selectedLanguageIds.indexOf(languageId) !== -1;
    }

    isSelectedSkill(skill: number): boolean {
        return this.selectedSkills.indexOf(skill) !== -1;
    }

    selectType(key: string, type: string): void {
        const index = this.selectedTypes[key].indexOf(type);

        if (index === -1) {
            this.selectedTypes[key].push(type);
        } else {
            this.selectedTypes[key].splice(index, 1);
        }

        if (key === 'content' && type === 'course') {
            if (index === -1) {
                for (const courseType in this.availableCourseTypes) {
                    if (this.availableCourseTypes.hasOwnProperty(courseType)) {
                        if (!this.selectedTypes.course) {
                            this.selectedTypes.course = [];
                        }
                        this.selectedTypes.course.push(courseType);
                    }
                }
            } else {
                this.selectedTypes.course = [];
            }
        }

        if (key === 'content' && type === 'externalContent') {
            if (index === -1) {
                for (const externalContentType in this.availableExternalContentTypes) {
                    if (this.availableExternalContentTypes.hasOwnProperty(externalContentType)) {
                        if (!this.selectedTypes.externalContent) {
                            this.selectedTypes.externalContent = [];
                        }
                        this.selectedTypes.externalContent.push(externalContentType);
                    }
                }
            } else {
                this.selectedTypes.externalContent = [];
            }
        }

        if (key === 'content' && type === 'externalResource') {
            if (index === -1) {
                for (const externalResourceType in this.availableExternalResourceTypes) {
                    if (this.availableExternalResourceTypes.hasOwnProperty(externalResourceType)) {
                        if (!this.selectedTypes.externalResource) {
                            this.selectedTypes.externalResource = [];
                        }
                        this.selectedTypes.externalResource.push(externalResourceType);
                    }
                }
            } else {
                this.selectedTypes.externalResource = [];
            }
        }

        if (key !== 'content' && this.selectedTypes[key].length === 0) {
            this.selectType('content', key);
        }
    }

    selectProvider(providerId: string | number): void {
        const index = this.selectedProviders.indexOf(providerId);

        if (index > -1) {
            this.selectedProviders.splice(index, 1);
        } else {
            this.selectedProviders.push(providerId)
        }
    }

    selectLanguage(languageId: number): void {
        const index = this.selectedLanguageIds.indexOf(languageId);

        if (index > -1) {
            this.selectedLanguageIds.splice(index, 1);
        } else {
            this.selectedLanguageIds.push(languageId);
        }
    }

    selectSkill(skill: number): void {
        const index = this.selectedSkills.indexOf(skill);

        if (index > -1) {
            this.selectedSkills.splice(index, 1);
        } else {
            this.selectedSkills.push(skill)
        }
    }

    stopPropagation(event: any): void {
        event.stopPropagation();
    }

    getColor(content: any): string {
        switch (content.type) {
            case 'external_content':
                if (content.data.provider) {
                    switch (content.data.provider.key) {
                        case 'bookboon':
                            return 'templab-tag--orange';
                        default:
                            return 'templab-tag--purple';
                    }
                } else {
                    switch (content.type) {
                        case 'course':
                            return 'templab-tag--purple';
                        case 'video':
                            return 'templab-tag--blue';
                        case 'article':
                            return 'templab-tag--orange';
                        case 'other':
                            return 'templab-tag--red';
                    }
                }
                break;
            case 'external_video':
                return 'templab-tag--blue';
            default:
                return 'templab-tag--purple';
        }
    }

    getType(content: any): string {
        switch (content.type) {
            case 'external_content':
                if (content.data.provider) {
                    switch (content.data.provider.key) {
                        case 'openclassroom':
                        case 'cefora':
                        case 'udemy':
                        case 'coursera':
                            return 'words.template';
                        case 'bookboon':
                            return 'words.ebook';
                        default:
                            return content.data.provider.name;
                    }
                } else {
                    return 'filter.content-type.external_resource';
                }
            case 'external_video':
                return 'words.video';
            default:
                return 'words.template';
        }
    }

    getLogo(content: any): string {
        switch (true) {
            // If there is a customCampMedia , you need to display this media
            case !!content && content.hasOwnProperty('customCampMedia') && !!content.customCampMedia:
                return `url(${content?.customCampMedia?.pictureUrl}?size=256)`;
            // If there is NO customCampMedia , you need to check whether there is a provider.media
            case !!content && content.hasOwnProperty('provider') && !!content.provider && content.provider.hasOwnProperty('media') && !!content?.provider?.media:
                // If company.id === provider.responsibleId && provider.responsibleType === 'company')  => display the company_logo and not the provider.media logo
                return this.company?.id === content.provider.responsibleId && content.provider.responsibleType === 'company' ?
                    `url(${this.company?.experience?.displayPicture}?size=256)` : `url(${content.provider.media.pictureUrl}?size=256)`;
            // If there is no  provider.media  check if provider is Coursera, Goodhabitz, Bookboon etc...
            case !!content && content.hasOwnProperty('provider') && DEFAULT_PROVIDER_LIST_KEYS.includes(content?.provider?.key):
                return `url(https://static.myskillcamp.com/images/integrations/${content.provider.key}.svg)`;
            // If there is no  provider.media + NOT IN know providers , you need to display the company media image
            default:
                // return `url(${this.company?.experience?.displayPicture}?size=256)`;
                return '';
        }
    }

    saveFilters(type: string): void {
        this.displays[type] = false;

        this.filtersActive = true;
        this.filters = DataHelper.merge(this.filters, { contents: this.selectedTypes.content });
        this.filters = DataHelper.merge(this.filters, { courses: this.selectedTypes.course });
        this.filters = DataHelper.merge(this.filters, { externalContents: this.selectedTypes.externalContent });
        this.filters = DataHelper.merge(this.filters, { externalResources: this.selectedTypes.externalResource });
        this.filters = DataHelper.merge(this.filters, { providers: this.selectedProviders });
        this.filters = DataHelper.merge(this.filters, { languages: this.selectedLanguageIds });
        this.filters = DataHelper.merge(this.filters, { skills: this.selectedSkills });

        this.searchContents();
    }

    clearFilters(): void {
        this.filtersActive = false;
        this.filters = {
            contents: [],
            courses: [],
            externalResources: [],
            externalContents: [],
            providers: [],
            languages: [],
            skills: [],
            orderDate: '-date'
        };
        this.orderDate = '-date';

        this.searchContents();
    }

    selectOrderDate(order: string): void {
        this.orderDate = order;

        this.filtersActive = true;
        this.filters = DataHelper.merge(this.filters, { order: this.orderDate });

        this.searchContents();
    }

    getOrderDate(): string {
        switch (this.orderDate) {
            case '-date':
                return 'words.addition-date';
            case 'title':
                return 'A-Z';
            case '-title':
                return 'Z-A';
        }
    }
}
