import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, ViewChild, ElementRef, ComponentRef } from '@angular/core';
import { Router } from '@angular/router';
//
import { CommonSelectDatum } from 'src/app/utils/components/select/select.component';
import { StorageService } from 'src/app/utils/services/storage.service';
import { TranslationService } from 'src/app/utils/services/translation.service';
import { UuidPipe } from 'src/app/utils/pipes/uuid.pipe';
import { RedirectService } from 'src/app/utils/services/redirect.service';

export interface CompanyOwnershipData {
    id: number;
    isSelected: boolean;
    icon: string;
    title: string;
    type: string;
    context: string;
    description: string;
    skills: string;
    selectOptions: Array<CommonSelectDatum<unknown>>;
}
export interface CompanyOwnershipMetaData {
    languages: Array<CommonSelectDatum<unknown>>;
    types: Array<CommonSelectDatum<unknown>>;
}

@Component({
    selector: 'msc-table-company-ownership-component',
    templateUrl: './table-company-ownership.component.html',
    styleUrls: ['./table-company-ownership.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [UuidPipe],
})

export class TableCompanyOwnershipComponent {
    @Input() rows: Array<CompanyOwnershipData> = [];
    @Input() meta: CompanyOwnershipMetaData;
    @Input() availableCompanies: Array<CommonSelectDatum<unknown>> = [];
    @Input() loading: boolean = true;
    @Output() assignEvent: EventEmitter<any> = new EventEmitter<any>();
    @Output() dismissEvent: EventEmitter<any> = new EventEmitter<any>();
    @Output() updateEvent: EventEmitter<any> = new EventEmitter<any>();
    @ViewChild('searchInput') searchInput;

    public columns: Array<unknown>;
    public id: string = this.uuidPipe.transform();
    public filters: any = {
        isVisible: false,
        isActive: false
    };

    constructor(
        private router: Router,
        private redirectService: RedirectService,
        private storageService: StorageService,
        private translateService: TranslationService,
        private uuidPipe: UuidPipe,
    ) {}

    ngOnInit(): void {
        this.setContent();
        this.setPristineFilters();
    }

    get thCheckboxIsPartial(): boolean { return !this.rows.every((row, _i, rows) => rows[0].isSelected === row.isSelected); }
    get thCheckboxValue(): boolean { return this.rows.every((row) => row.isSelected); }
    get remainingCoursesCounter(): number { return this.rows.filter(r => r.selectOptions.every(opt => !opt.isSelected)).length; }
    get selectedCoursesCounter(): number { return this.rows.filter(r => r.isSelected).length; }
    
    /**
     * Get the column list
     * Note: Order of columns is important
     */
    getColumns() {
        return [
            {
                key: 'isSelected',
                width: '5%',
            },
            {
                key: 'icon',
                width: '5%',
            },
            {
                key: 'title',
                label: this.translateService.instant('words.title'),
                width: '25%',
            },
            {
                key: 'type',
                label: this.translateService.instant('words.type'),
                width: '15%',
            },
            {
                key: 'skills',
                label: this.translateService.instant('words.skills'),
                width: '20%',
            },
            {
                key: 'ownership',
                label: 'Ownership',
                width: '25%',
            },
            {
                key: 'goto',
                width: '5%',
            }
        ];
    }

    /**
     * Set the table component content
     */
    setContent(): void {
        this.columns = this.getColumns();
    }

    /**
     * 
     */
    setPristineFilters(): void {
        this.meta.languages = this.meta.languages.map(d => {
            d.isSelected = false;
            return d;
        });
        this.meta.types = this.meta.types.map(d => {
            d.isSelected = false;
            return d;
        });
        // this.searchInput.input.nativeElement.value = '';
    }

    /**
     * 
     */
    onToggleFilters(prop, force?: boolean | undefined) {
        this.filters[prop] = force ?? !this.filters[prop];
    }

    /**
     * Event handler for modify
     */
    onModify(value: boolean): void {
        this.rows.forEach((row) => row.isSelected = value);
    }

    /**
     * Event handler for change
     */
    onChange(value: boolean, datum: any): void {
        datum.isSelected = value;
    }

    /**
     * Event handler for sort
     */
    onSort(key: string, type: string): void {
        this.updateEvent.emit({sort: key, order: type});
    }

    /**
     * Event handler for filter
     */
    onFilter() {
        const languageIds = this.meta.languages.filter(d => d.isSelected).map(d => d.id);
        const types = this.meta.types.filter(d => d.isSelected).map(d => d.value);
        this.updateEvent.emit({languageIds, types});
        this.onToggleFilters('isVisible', false);
        this.onToggleFilters('isActive');
    }

    /**
     * 
     */
    onFiltersReset() {
        this.searchInput.input.nativeElement.value = '';
        this.setPristineFilters();
        this.onToggleFilters('isActive');
        this.updateEvent.emit();
    }

    /**
     * 
     */
    onSearch(key) {
        this.updateEvent.emit({q: key.value});
    }

    /**
     * 
     */
    onSelectOptionFilter(option: any, key: string) {
        this.meta[key] = this.meta[key].map(opt => {
            if (opt.id === option.id) {
                opt.isSelected = !opt.isSelected;
            }
            return opt;
        });
    }

    /**
     * 
     */
    onSelectCompanySingle(row: any, option: any) {
        row.selectOptions = row.selectOptions.map((opt) => {
            opt.isSelected = opt.id === option.id;
            return opt;
        });
    }

    /**
     * 
     */
    onSelectCompanyMultiple(option: any) {
        const selectedCourses = this.rows.filter(r => r.isSelected);

        selectedCourses.forEach((course) => {
            course.selectOptions = course.selectOptions.map(opt => {
                opt.isSelected = opt.id === option.id;
                return opt;
            });
        });

        this.onModify(false);
    }

    /**
     * 
     */
    onClickCourseDetail(datum: CompanyOwnershipData) {
        if (datum.type.toLocaleLowerCase() === 'course') {
            this.redirectService.navigate('studio', ['company', this.storageService.get('company').slug, 'course', datum.id], {}, '_blank');
            return;
        }

        this.redirectService.navigate('studio', ['company', this.storageService.get('company').slug, 'journey', 'preview', datum.id], {}, '_blank');
    }

    /**
     * 
     */
    onModalDismiss() {
        this.dismissEvent.emit();
    }

    /**
     * 
     */
    onAssign() {
        const contents: any[] = this.rows
            .map(r => ({
                companyId: r.selectOptions.find(opt => opt.isSelected)?.id,
                companyName: r.selectOptions.find(opt => opt.isSelected)?.label,
                contentId: r.id,
                contentTitle: r.title,
                contentType: r.context,
            }))
            .filter(r => r.companyId);

        this.assignEvent.emit(contents);
    }
}
