import { HttpClient } from "@angular/common/http";
import { SurveyQuestion } from './survey-questions/survey-questions';

export class SurveyGroup {
    id: number;
    title: string;
    surveyQuestions: SurveyQuestion[] = [];

    constructor(surveyGroup: any, private http: HttpClient) {
        this.extends(surveyGroup);
    }

    extends(surveyGroup: any): SurveyGroup {
        Object.assign(this, surveyGroup);
        if (this.surveyQuestions && this.surveyQuestions.length > 0) {
            this.surveyQuestions = this.surveyQuestions.map((surveyQuestion) =>
                new SurveyQuestion(surveyQuestion, this.http));
        }
        return this;
    }
}
