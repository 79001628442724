<div class="embeded" *ngIf="url">
	<iframe [src]="url" width="560" height="460" frameborder="0" title="" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
</div>

<style>
	.embeded {
		padding-bottom: 56.25%;
		position: relative;
	}

	.embeded iframe {
		border: none;
		position: absolute;
		width: 100%;
		top: 0;
		left: 0;
	}
</style>