import { Component, HostListener, Input } from '@angular/core';

@Component({
    selector: 'app-tooltip',
    templateUrl: './tooltip.component.html',
})
export class TooltipComponent {
    @Input() position: string = 'left';
    @Input() text: string = '';
    tooltipAction: boolean = false;

    @HostListener('click', ['$event.target'])
    onClick(event) {
        this.tooltipAction = !this.tooltipAction;
    }
}
