import { Component, Input } from '@angular/core';
import { Section, SectionContent, ExternalLinkProvider } from '@stuplay'
import { DomSanitizer } from '@angular/platform-browser';
import { StorageService } from '../../../../../utils/services/storage.service';
import { DataHelper } from '../../../../../utils/helpers';
import { NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-external-link',
    templateUrl: './external-link.component.html',
    styleUrls: ['./external-link.component.less']
})
export class ExternalLinkComponent {
    @Input() externalLink: any;
    @Input() section: Section;
    @Input() sectionContent: SectionContent;
    public company: any;
    public url: any;
    public display: boolean = false;

    constructor(
        private sanitizer: DomSanitizer,
        private externalLinkProvider: ExternalLinkProvider,
        private storageService: StorageService,
        private modalService: NgbModal,
    ) { }

    ngOnChanges() {
        this.company = this.storageService.get('company');
        this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.externalLink.url);
    }

    run(content: any): void {
        if (this.externalLink.params.mode === 'embed') {
            const options: NgbModalOptions = { size: 'lg', backdropClass: 'backdrop-custom' };
            this.modalService.open(content, options);
            this.buildLTIForm(this.externalLink, 'embed-iframe')
        } else {
            this.buildLTIForm(this.externalLink, '_blank');
        }
    }

    openModal(content: any) {
        const options: NgbModalOptions = { size: 'lg', backdropClass: 'backdrop-custom' };
        this.modalService.open(content, options);
    }

    buildLTIForm(integration: any, mode: string): void {
        const params = {
            company_id: this.company.id
        };

        this.externalLinkProvider.startExternalLink(integration.id, params).subscribe((data) => {
            const form = document.createElement('form');
            form.target = mode;
            form.method = 'POST';
            form.action = integration.url;
            form.enctype = 'application/x-www-form-urlencoded';
            for (const key in data.fields) {
                if (data.fields.hasOwnProperty(key)) {
                    const input = document.createElement('input');
                    input.type = 'hidden';
                    input.name = DataHelper.changeCase(key);
                    input.setAttribute('value', data.fields[key]);
                    form.appendChild(input);
                }
            }
            if (mode === '_blank') {
                document.body.appendChild(form);
            } else {
                document.querySelector('#embed-code').appendChild(form);
            }
            form.submit();
        });
    }
}
