<div class="media-list-library__list" *ngIf="medias">
    <div class="wrapper" *ngFor="let splittedMedia of splittedMedias, let index = index" style="margin: 1rem 0 0 0;">
        <div class="added-date">
            <strong>{{ 'month.' + (dates[index] | date: 'MMMM') | translate }} {{ dates[index] | date: 'yyyy' }}</strong>
        </div>

        <div class="folder folder_display_block">
            <div *ngFor="let item of (splittedMedia.medias | search:query | order:orderBy)">
                <library-item [item]="item" (updateMedia)="onUpdatedMedia($event)" (showItemPreview)="showPreview($event)" [toUpload]="toUpload"></library-item>
            </div>
        </div>
    </div>

    <div *ngIf="pagination.currentPage < pagination.lastPage" class="">
        <button (click)="showMore()" class="show-more">
            <div class="lds-ring" *ngIf="processing">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>

            <span>{{"words.load-more" | translate}}</span>
        </button>
    </div>
</div>
