import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { getParams } from '../helpers';
import { environment } from '../../../environments/environment';


@Injectable()
export class NewKnowledgeProvider {

    constructor(private http: HttpClient) { }

    get(slug: string, params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/company/${slug}/skills`, { params: getParams(params) });
    }

    getSkill(id: number | string, params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/skills/${id}`, { params: getParams(params) });
    }
}
