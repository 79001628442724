import { Component, OnInit } from '@angular/core';
import { ToastService } from './toast.service';

export interface Toast {
    message: string;
    type: string;
    duration: number;
    link: any;
    redirect: string;
}

@Component({
    selector: 'msc-toast',
    templateUrl: './toast.component.html',
})
export class ToastComponent implements OnInit {
    public toasts: Toast[];

    constructor(private toastService: ToastService) {
        this.toasts = [];
    }

    ngOnInit(): void {
        this.toastService.listen().subscribe((toast) => {
            this.manageToast(toast);
        });
    }

    manageToast(toast: Toast): void {
        this.toasts.push(toast);

        const timeOut = setTimeout(() => {
            this.dismiss(toast);
            clearTimeout(timeOut);
        }, toast.duration);
    }

    dismiss(toast: Toast): void {
        const index = this.toasts.findIndex((data) => {
            return data === toast;
        });

        this.toasts.splice(index, 1)
    }
}
