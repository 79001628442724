<div class="templab-library__body">
    <div class="slide-external-link__header__controls">
        <div class="slide-external-link__back">
            <a></a>
        </div>

        <div class="slide__ariane">
            <span class="slide__ariane__step" (click)="onArianeClick('form')" [ngClass]="{'slide__ariane__step--current': currentTab === 'form', 'slide__ariane__step--success': !isDisabled('form') && currentTab !== 'form'}">
                <div class="tooltip tooltip--bottom"></div>
            </span>

            <span class="slide__ariane__step" (click)="onArianeClick('infos')" [ngClass]="{'slide__ariane__step--disabled': isDisabled('infos'), 'slide__ariane__step--current': currentTab === 'infos', 'slide__ariane__step--success': !isDisabled('infos') && currentTab !== 'infos'}">
                <div class="tooltip tooltip--bottom"></div>
            </span>

            <span class="slide__ariane__step" (click)="onArianeClick('skills')" [ngClass]="{'slide__ariane__step--disabled': isDisabled('skills'), 'slide__ariane__step--current': currentTab === 'skills', 'slide__ariane__step--success': !isDisabled('skills') && currentTab !== 'skills'}">
                <div class="tooltip tooltip--bottom"></div>
            </span>
        </div>
    </div>

    <div class="templab-ptmc-external-link__body" *ngIf="currentTab === 'form'">
        <div class="templab-ptmc-external-link__form">
            <div class="templab-ptmc-external-link__fieldset">
                <div class="templab-ptmc-external-link__input">
                    <div class="templab-ptmc-external-link__label">URL</div>
                    <form class="form-search white">
                        <form class="templab-form-search">
                            <div class="templab-form-search__input">
                                <i class="templab-form-search__icon icon templab-icon-search"></i>

                                <input autocomplete="off" name="url" id="url" type="text"
                                       [(ngModel)]="extensionLink.url"
                                       [debounce]="800"
                                       (onDebounce)="onChangeURL()"
                                       [placeholder]="'placeholder.extension-form-search' | translate">
                            </div>
                        </form>

                        <p *ngIf="wrongURL" class="error-message color--red">
                            {{ 'extension.form.wrongURL' | translate }}
                        </p>
                    </form>
                </div>
            </div>

            <div class="templab-ptmc-external-link__fieldset">
                <div class="templab-ptmc-external-link__input">
                    <div class="input form-select">
                        <label for="type" class="templab-ptmc-external-link__label">
                            {{ 'extension.form.type-label' | translate }}
                        </label>

                        <div class="select">
                            <select name="select" id="type" [(ngModel)]="extensionLink.type">
                                <option value="other" selected>{{ 'filter.content-type.other' | translate }}</option>
                                <option value="course">{{ 'filter.content-type.course' | translate }}</option>
                                <option value="video">{{ 'filter.content-type.video' | translate }}</option>
                                <option value="article">{{ 'filter.content-type.article' | translate }}</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="templab-ptmc-external-link__input">
                    <div class="input input-duration">
                        <label for="duration" class="templab-ptmc-external-link__label">
                            {{ 'extension.form.time-label' | translate }}
                        </label>

                        <div class="templab-ptmc-external-link__fieldwrapper">
                            <div class="templab-ptmc-external-link__fieldwrapper__element">
                                <label class="templab-text-input">
                                    <input autocomplete="off" type="text" id="duration" name="input" [value]="extensionLink.duration" [(ngModel)]="extensionLink.duration">
                                </label>
                            </div>

                            <div class="templab-ptmc-external-link__fieldwrapper__element">
                                <div class="select">
                                    <select name="duration-unit" id="duration-unit" [(ngModel)]="extensionLink.durationUnit">
                                        <option value="minutes" selected>{{ 'words.minutes' | translate }}</option>
                                        <option value="hours">{{ 'words.hours' | translate}}</option>
                                        <option value="days">{{ 'words.days' | translate}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="templab-ptmc-external-link__body" *ngIf="currentTab === 'infos'">
        <div class="templab-ptmc-external-link__form">
            <div class="templab-ptmc-external-link__fieldset">
                <div class="templab-ptmc-external-link__input">
                    <div class="input">
                        <label for="title" class="templab-text-input">
                            <div class="templab-text-input__label">
                                {{ 'extension.infos.title-label' | translate }}
                            </div>

                            <input autocomplete="off" type="text" id="title" name="input" class="templab-input-text" [(ngModel)]="extensionLink.title" [placeholder]="'placeholder.extension-infos-title' | translate">
                        </label>
                    </div>
                </div>

                <div class="templab-ptmc-external-link__input">
                    <label for="description" class="templab-ptmc-external-link__label">
                        {{ 'extension.infos.description-label' | translate }}
                    </label>

                    <div class="templab-textarea__content">
                        <textarea autocomplete="off" type="text" id="description" name="input" class="textarea"
                                  [(ngModel)]="extensionLink.description"
                                  [placeholder]="'placeholder.extension-infos-description' | translate">
                        </textarea>
                    </div>
                </div>

                <div class="templab-ptmc-external-link__input">
                    <div class="input">
                        <label class="templab-ptmc-external-link__label">
                            {{ 'words.language' | translate }}
                        </label>

                        <div class="select">
                            <select name="select" [(ngModel)]="extensionLink.lang">
                                <option value="fr" selected>{{ 'words.french' | translate }}</option>
                                <option value="en">{{ 'words.english' | translate }}</option>
                                <option value="nl">{{ 'words.dutch' | translate }}</option>
                                <option value="it">{{ 'words.italian' | translate }}</option>
                                <option value="de">{{ 'words.german' | translate }}</option>
                                <option value="es">{{ 'words.spanish' | translate }}</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="templab-ptmc-external-link__input">
                    <div class="input-upload">
                        <div class="input-upload__ctn" [ngClass]="{'content__picture--empty': !extensionLink.image}">
                            <media-manager [height]="320"
                                           [onlyPictures]="true"
                                           [media]="extensionLink.image"
                                           [cover]="'https://static.myskillcamp.com/images/images_random/empty-state-7.jpg'"
                                           (updateMedia)="updatePicture($event)">
                            </media-manager>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="templab-ptmc-external-link__selected-skills" *ngIf="currentTab === 'skills'">
        <div class="templab-ptmc-external-link__label">{{ 'words.skills' | translate}}</div>

        <div class="templab-skill templab-skill--shadow-none templab-skill--level templab-skill--hoverable" *ngFor="let skill of extensionLink.skills; let index = index">
            <div class="templab-skill__content">
                <div class="templab-skill__level">
                    <span [ngClass]="{'active' : skill.level > 0 }" (click)="setLevel(1, index)"></span>
                    <span [ngClass]="{'active' : skill.level > 1 }" (click)="setLevel(2, index)"></span>
                    <span [ngClass]="{'active' : skill.level > 2 }" (click)="setLevel(3, index)"></span>
                </div>

                <p class="templab-skill__text" (click)="setLevel(0, index)">{{ skill.name }}</p>

                <div class="templab-skill__hover-actions">
                    <div class="templab-skill__level">
                        <span [ngClass]="{'active' : skill.level > 0 }" (click)="setLevel(1, index)"></span>
                        <span [ngClass]="{'active' : skill.level > 1 }" (click)="setLevel(2, index)"></span>
                        <span [ngClass]="{'active' : skill.level > 2 }" (click)="setLevel(3, index)"></span>
                    </div>

                    <p (click)="setLevel(0, index)">{{ 'words.for-all' | translate }}</p>
                    <a class="templab-skill__close" (click)="removeSkill(index)">
                        <i class="templab-icon templab-icon-close"></i>
                    </a>
                </div>
            </div>
        </div>
    </div>

    <div class="templab-ptmc-external-link__available-skills" *ngIf="currentTab === 'skills'">
        <div class="templab-ptmc-external-link__input">
            <div class="templab-form-search__label">{{ 'mentoring.add-skill' | translate }}</div>

            <form class="templab-form-search">
                <div class="templab-form-search__input">
                    <i class="templab-form-search__icon icon templab-icon-search"></i>
                    <input autocomplete="off" name="skillSearchName" type="text" required=""
                           [placeholder]="'placeholder.search' | translate" [(ngModel)]="skillSearchName"
                           [debounce]="500" (onDebounce)="searchSkill()">
                </div>
            </form>
        </div>

        <ul class="templab-ptmc-external-link__available-skills__list skill__list">
            <li *ngFor="let skill of availableSkills; let index = index">
                <div class="templab-ptmc-external-link__form__skills">
                    <div
                        class="templab-skill templab-skill--shadow-none templab-skill--level templab-skill--hoverable">
                        <div class="templab-skill__content">
                            <p class="templab-skill__text">{{ skill.name }}</p>
                        </div>

                        <div class="templab-skill__hover-actions">
                            <div class="templab-skill__level">
                                <span (click)="addSkill(index, 1)"></span>
                                <span (click)="addSkill(index, 2)"></span>
                                <span (click)="addSkill(index, 3)"></span>
                            </div>

                            <p (click)="addSkill(index, 0)">{{ 'words.for-all' | translate }}</p>
                        </div>
                    </div>
                </div>
            </li>
        </ul>

        <button class="show-more" *ngIf="skillPagination?.currentPage < skillPagination?.lastPage" (click)="loadMoreSkills()">
            <div class="lds-ring" *ngIf="loading">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>

            <span>{{ 'words.load-more' | translate }}</span>
        </button>
    </div>
</div>

<footer class="templab-library__footer">
    <div class="btn-empty" *ngIf="currentTab === 'form'"></div>

    <button class="templab-btn templab-btn--secondary" (click)="previous()" [disabled]="loadings.newResource" *ngIf="currentTab !== 'form'">
        {{ 'words.previous' | translate }}
    </button>

    <button class="templab-btn templab-btn--secondary" (click)="next()" [disabled]="loadings.newResource" *ngIf="currentTab !== 'skills'">
        {{ 'words.next' | translate }}
    </button>

    <button class="templab-btn templab-btn--secondary" (click)="saveContent()" [disabled]="loadings.newResource" *ngIf="currentTab === 'skills'">
        <i class="templab-icon templab-icon-checkmark"></i>
        {{ 'words.validate' | translate }}
    </button>
</footer>
