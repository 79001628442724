import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { getParams } from '../helpers';
import { environment } from '../../../environments/environment';

@Injectable()
export class NewJourneyProvider {

    constructor(public http: HttpClient) { }

    getCamps(id: number | string, params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/create/trajects/${id}/camps`, { params: getParams(params) });
    }

    getRecovered(id: number | string, params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/create/trajects/${id}/camps/recovered`, { params: getParams(params) });
    }
}
