import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CourseSubscriptionSession } from "./course-subscription-session";
import { getParams } from "../helpers";
import { environment } from 'src/environments/environment';

@Injectable()
export class CourseSubscriptionSessionProvider {

    constructor(public http: HttpClient) {}

    getOrCreateCourseSubscriptionSession(courseId: number, preview?: boolean, directPlay?: boolean): Observable<CourseSubscriptionSession> {
        const params = {
            course_id: courseId,
            direct_play: directPlay ? 1 : 0
        };
        const prev = (preview) ? '?preview=' + preview : '';

        return this.http.post(`${environment.envVar.API_URL}/course-subscription-sessions${prev}`, params)
            .pipe(map(data => new CourseSubscriptionSession(data, this.http)));
    }

    getCourseSubscriptionSession(id: number): Observable<CourseSubscriptionSession> {
        return this.http.get(`${environment.envVar.API_URL}/course-subscription-sessions/${id}`)
            .pipe(map(data => new CourseSubscriptionSession(data, this.http)));
    }

    putCourseSubscriptionSession(id: number, body: any, params: any): Observable<any> {
        return this.http.put(`${environment.envVar.API_URL}/course-subscription-sessions/${id}`, body, {params});
    }

    resetCourseSubscriptionSession(id: number): Observable<any> {
        return this.http.delete(`${environment.envVar.API_URL}/course-subscription-sessions/${id}/reset`);
    }

    unsubscribe(id: number, params: any): Observable<any> {
        return this.http.delete(`${environment.envVar.API_URL}/subscriptions/courses/${id}`, {params: getParams(params)});
    }

    resetContentSectionSession(id: number): Observable<any> {
        return this.http.post(`${environment.envVar.API_URL}/section-content-sessions/${id}/reset`, {});
    }
}
