<div class="event-presentation event">
    <div class="row">
        <div class="columns event-presentation__infos">
            <h2 class="heading">
                <span class="instruction">
                    {{ 'studio.course.section-content.'+sectionContent.context+'-title' | translate }}

                    <img (click)="openAsideHelp('event')" src="https://static.myskillcamp.com/images/guidance/question-mark-circle.svg"/>
                </span>

                <live-editor [(inputData)]="sectionContent.title" (onUpdate)="updateSectionContent()" [placeholder]="('studio.course.section-content.'+sectionContent.context+'-hint' | translate)" ></live-editor>
            </h2>
            <p class="event-presentation__description">
                <live-editor [(inputData)]="sectionContent.description" (onUpdate)="updateSectionContent()" [placeholder]="('studio.event.info' | translate)"></live-editor>
            </p>
        </div>
    </div>

    <div class="row">
        <div class="columns event-presentation__img">
            <media-manager [height]="320" [media]="event.media" [cover]="'https://static.myskillcamp.com/images/studio/img_default_event.jpg'" (updateMedia)="onUpdateMedia($event)"></media-manager>
        </div>
    </div>

    <div class="row">
        <div class="columns medium-6">
            <div class="row">
                <div class="columns medium-12 micro-data" *ngIf="event.type === 'place'">
                    <span class="micro-data__title">
                        <img src="https://static.myskillcamp.com/images/icon/icon-studio/icon_pin.svg" alt="">
                        {{ 'studio.event.event-session.type.place.description' | translate}}
                    </span>

                    <span class="micro-data__info">
                        <live-editor style="display: inline; height: auto;"
                            [(inputData)]="eventSession.address"
                            (onUpdate)="setUpdateEvent()"
                            [placeholder]="('studio.event.place.placeholder' | translate)" >
                        </live-editor>
                    </span>
                </div>

                <div class="columns medium-12 micro-data" *ngIf="event.type === 'visio'">
                    <span class="micro-data__title">
                        {{ 'studio.event.event-session.type.visio.description' | translate}}
                    </span>

                    <span class="micro-data__info">
                        <live-editor style="display: inline; height: auto;"
                            [(inputData)]="event.link"
                            (onUpdate)="setUpdateEvent()"
                            [placeholder]="('studio.event.link.placeholder' | translate)"
                            [disabled]="(course?.schema === 'template' && event.integration === 'gotowebinar') || course?.schema === 'instance' && event.integration === 'gotowebinar' && eventSession.eventSessionIntegrations.length > 0">
                        </live-editor>
                    </span>
                </div>

                <div class="columns medium-12 micro-data" *ngIf="(event.type === 'virtual-class' && course?.schema === 'instance')">
                    <span class="micro-data__title">
                        {{ 'studio.event.event-session.type.virtual-class.description' | translate}}
                    </span>

                    <button class="btn" (click)="createVirtualClass()" *ngIf="!event.url">{{ 'studio.event.event-session.type.virtual-class.button' | translate }}</button>

                    <span class="micro-data__info" *ngIf="event.url">
                        {{ 'studio.event.event-session.type.virtual-class.already-created' | translate }}
                    </span>

                    <a class="btn" [href]="event.url" target="_blank" *ngIf="event.url">{{ 'studio.event.event-session.type.virtual-class.join' | translate }}</a>
                </div>

                <div class="columns medium-12 micro-data">
                    <span class="micro-data__title">
                        <img src="https://static.myskillcamp.com/images/icon/icon-studio/icon_langue.svg" alt="">
                        {{ 'studio.event.event-session.additional-informations' | translate}}
                    </span>

                    <span class="micro-data__info">
                        <live-editor style="display: inline; height: auto;"
                            [line]="'multi'"
                            [rows]="8"
                            [(inputData)]="event.info"
                            (onUpdate)="setUpdateEvent()"
                            [placeholder]="('studio.event.event-session.additional-informations.placeholder' | translate)">
                        </live-editor>
                    </span>

                    <ng-container *ngFor="let i of range">
                        <span class="micro-data__info">
                            <media-button-upload
                                [media]="event.eventSessions[0].eventSessionMedia[i]?.media"
                                (updateMedia)="onUpdateEventMedia($event)"
                                (deleteMedia)="onDeleteEventMedia(event.eventSessions[0].eventSessionMedia[i])">
                            </media-button-upload>
                        </span>
                    </ng-container>
                </div>

                <div class="columns medium-12 micro-data">
                    <span class="micro-data__title" style="margin: 0 0 1rem 0;">
                        <img src="https://static.myskillcamp.com/images/icon/icon-studio/icon_manager.svg" alt="">
                        {{ 'event.speakers' | translate }}
                    </span>

                    <div class="trainer" *ngFor="let trainer of event?.eventTrainers">
                        <img *ngIf="trainer.account" [src]="trainer.account.picture+'?size=32'">
                        <img *ngIf="!trainer.account" [src]="'https://ui-avatars.com/api/?background=cb96ff&color=a35ff0&font-size=0.6&length=1&name='+trainer.name+'?size=64'">
                        <span class="trainer__name">{{ trainer.name }}</span>
                        <img (click)="deleteTrainer(trainer)" class="trainer__remove" src="https://static.myskillcamp.com/images/icon/icon-studio/icon_cross.svg">
                    </div>

                    <span class="micro-data__info">
						<button class="add-user" (click)="inputTrainer()">+</button>
						<label style="width: auto !important" class="liveEdit" [ngClass]="{'liveEdit_empty': !queryTrainer, 'liveEdit_focused': focused }" [attr.data-content]="('studio.event.event-session.trainer.placeholder' | translate)">
                            <textarea (focus)="onFocus(true)" (blur)="onFocus(false)" rows='1' [(ngModel)]="queryTrainer" [debounce]="500" (onDebounce)="searchName()" (keydown.enter)="false"></textarea>
                            <span class="liveEdit__trigger"><span class="sr-only">Edit</span></span>
                        </label>
                    </span>

                    <ul class="accounts_list">
                        <li class="account" *ngFor="let trainer of availableTrainers" style="display: flex; justify-content: space-between; align-items: center; width: 250px;">
                            <img [src]="trainer.picture + '?size=128'" style="width: 32px; border-radius: 100%;">

                            <span style="width: 200px; max-width: 200px; display: flex; justify-content: center;">
                                {{ trainer.name | truncate: '23' }}
                            </span>

                            <button class="btn" (click)="addTrainer(trainer.name, trainer.id)" style="min-width: 32px;">
                                +
                            </button>
                        </li>
                    </ul>

                    <label class="input">
                        <input type="checkbox" class="input-checkbox" [(ngModel)]="event.isAttendanceMandatory" [mscTooltip]="'words.presence-mandatory.tooltip' | translate" (ngModelChange)="setUpdateEvent()">
                        {{ 'words.presence-mandatory' | translate }}
                    </label>
                </div>
            </div>
        </div>

        <div class="columns medium-6">
            <div class="event__dates date_number_many">
                <div class="date">
                    <div class="date__title">
                        {{ 'studio.event.event-session.start-at' | translate}}
                    </div>

                    <div class="date__content">
                        <span class="date__day">{{ startDate | date:'d' }}</span>
                        <span class="date__month">{{ ('month.' + (startDate | date:'MMMM')) | translate }}</span>
                    </div>
                </div>

                <div class="date">
                    <div class="date__title">
                        {{ 'studio.event.event-session.end-at' | translate}}
                    </div>

                    <div class="date__content">
                        <span class="date__day">{{ endDate | date:'d' }}</span>
                        <span class="date__month">{{ ('month.' + (endDate | date:'MMMM')) | translate }}</span>
                    </div>
                </div>

                <msc-daterangepicker [(ngModel)]="date" [options]="options" (ngModelChange)="updateDate()"></msc-daterangepicker>

                <div class="hours">
                    <div class="hour">
                        <input type="text" [(ngModel)]= "startHour" (blur)="updateHour()" >
                    </div>

                    <p>-</p>

                    <div class="hour">
                        <input type="text" required="required" [(ngModel)]= "endHour" (blur)="updateHour()" >
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
