import { HttpClient } from '@angular/common/http';

export class EventSessionIntegration {
    id: number;
    eventSessionId: number;
    providerName: string;
    resourceId: string;

    constructor(eventSessionIntegration: any, private http: HttpClient) {
        this.extends(eventSessionIntegration);
    }

    extends(eventSessionIntegration: any): EventSessionIntegration {
        Object.assign(this, eventSessionIntegration);
        return this;
    }
}