import { Injectable } from '@angular/core';
import { ApplicationProvider } from '@stuplay';
import { StorageService } from './storage.service';
import { ScriptInjectorService } from './script-injector.service';
import { find, addClass, removeClass } from '../functions/html.function';
import { environment } from '../../../environments/environment';
import * as FullStory from '@fullstory/browser';
import { TranslationService } from './translation.service';
import * as Sentry from '@sentry/angular';

declare const zE: any;
interface Me {
    id: string;
    uuid: string;
    name: string;
    email: string;
    firstname: string;
    lastname: string;
    language: {
        code: string;
        name: string;
    };
    timezone: string;
    department: string;
    learningLanguages: Array<{ name: string }>;
    zendeskJwt: string;
}

@Injectable()
export class ExternalAppsService {
    private booted: any;
    private appIds: any;
    private me: Me;

    constructor(
        private applicationProvider: ApplicationProvider,
        private storageService: StorageService,
        private scriptInjectorService: ScriptInjectorService,
        private translateService: TranslationService,
    ) {
        this.booted = {
            freshchat: false,
            beamer: false,
            fullstory: false,
            sentry: false,
            zendesk: true,
        };

        this.appIds = {
            fullstory: '4HEW5',
            beamer: 'UYnzpRBS27242'
        };
    }
    
    isBooted(key: string): boolean {
        return this.booted[key];
    }

    boot(me: any, company: any, access: any, applications?: any): void {
        this.me = me;
        if (environment.name.short === 'prod') {
            this.bootChat(me, applications);
            this.bootAppcues(me, company, applications);
            this.bootFullstory(me);
            this.bootBeamer(me, company, access);
        }

        if (environment.name.short === 'dev') {
            this.bootAppcues(me, company, applications); // MSC-3140
        }
    }

    zendesk(action: string): void {
        if (this.booted.zendesk) {
            switch (action) {
                case 'open':
                    const token = this.me?.zendeskJwt;

                    zE('messenger', 'loginUser', function (callback) {
                        callback(token);
                    });
                    zE('messenger:set', 'cookies', true);
                    setTimeout(()=>{
                        zE('messenger', 'open');
                    },1000)

                    break;
                case 'close':
                    zE('messenger:set', 'cookies', false);
                    zE('messenger', 'close');
                    break;
            }
        }
    }

    /**
     * Boot sentry system
     */
    bootSentry(me): void {
        if ((environment as any).local)
            return;

        if (!this.booted.sentry) {
            this.booted.sentry = true;

            Sentry.setUser(
                {
                    id: me.id,
                    username: me.name,
                    email: me.email
                }
            );
        }
    }

    bootFullstory(me): void {
        if ((window as any).Cookiebot?.consent?.statistics && !this.booted.fullstory) {
            try {
                this.booted.fullstory = true;

                FullStory.init({
                    orgId: this.appIds.fullstory,
                    devMode: false
                });

                const timeOut = setTimeout(() => {
                    FullStory.identify(me.id, {
                        displayName: me.name,
                        email: me.email
                    })
                    clearTimeout(timeOut);
                }, 3000);
            } catch (e) {
                this.booted.fullstory = true;
            }
        }
    }

    private bootChat(me: any, applications?: any): void {
        if (applications && applications.freshchat && applications.freshchat.settings.create) {
            this.bootFreshchat(me, applications.freshchat);
        } else {
            this.bootZendesk();
        }
    }

    bootZendesk(): void {
        this.booted.zendesk = true;
    }

    bootFreshchat(me: any, freshchat: any): void {
        if (!this.booted.freshchat) {
            this.booted.freshchat = true;
            const params = {
                email: me.email,
                restoreId: freshchat.userSettings && freshchat.userSettings.restoreId ? freshchat.userSettings.restoreId : null
            };
            this.scriptInjectorService.freshchat(freshchat.settings.create.connectionString, params);

            const timeOut = setTimeout(() => {
                (<any>window).fcWidget.user.setProperties({
                    firstName: me.firstname,
                    lastName: me.lastname,
                    email: me.email
                });

                (<any>window).fcWidget.on('user:created', (data) => {
                    if (!data.success) {
                        (<any>window).fcWidget.user.create({
                            firstName: me.firstname,
                            lastName: me.lastname,
                            email: me.email
                        }).then((res) => {
                            const parameters = {
                                restoreId: res.data.restoreId
                            };
                            this.applicationProvider.updateSettings(this.storageService.get('company').id, freshchat.applicationId, parameters).subscribe();
                        });
                    }
                });

                addClass('#fc_frame', 'm-visibility-hidden');

                (<any>window).fcWidget.on('widget:opened', () => {
                    removeClass('#fc_frame', 'm-visibility-hidden');
                });

                (<any>window).fcWidget.on('widget:closed', () => {
                    addClass('#fc_frame', 'm-visibility-hidden');
                });

                clearTimeout(timeOut);
            }, 2700);
        }
    }

    freshchat(action: string): void {
        if (this.booted.freshchat) {
            switch (action) {
                case 'open':
                    (<any>window).fcWidget.open();
                    break;
                case 'shutdown':
                    this.booted.freshchat = false;
                    find('#custom-app').innerHTML = '';
                    (<any>window).fcWidget.destroy();
                    break;
            }
        }
    }

    /**
     * Boot appcues
     * @param {any} me - Current user
     * @param {Company} company - Current company
     * @param {any} applications - Applications system
     */
    bootAppcues(me: any, company: any, applications: any): void {
        if (!this.booted.appcues && applications && applications.appcues && applications.appcues.isEnabled) {
            this.booted.appcues = true;
            this.scriptInjectorService.appcues();
            const timeOut = setTimeout(() => {
                if ((window as any).Appcues) {
                    (window as any).Appcues.identify(me.id, {
                        name: me.name,
                        email: me.email,
                        created_at: new Date().getTime(),
                        language: me.language.code,
                        companySlug: company.slug,
                        isEnabled: true
                    });
                }
                clearTimeout(timeOut);
            }, 3000);
        }
    }

    /**
     * Appues management
     * @return {any} Appcues system
     */
    appcues(): any {
        return (window as any).Appcues;
    }

    /**
     * Boot Beamer
     * @param {any} me - Current user
     * @param {Company} company - Current company
     * @param {any} access - Current access
     */
    bootBeamer(me: any, company: any, access: any): void {
        if (!this.booted.beamer) {
            this.booted.beamer = true;

            const filters = ['learner'];

            if (access.indexOf('company_author') !== -1 || access.indexOf('company_manager') !== -1 || access.indexOf('company_administrator') !== -1) {
                filters.push('super-user');
            }

            if (access.indexOf('company_owner') !== -1) {
                filters.push('super-admin');
            }

            const timeOut = setTimeout(() => {
                this.scriptInjectorService.beamer(this.appIds.beamer, me, company?.id, filters.join(','));
                clearTimeout(timeOut);
            }, 3000);
        }
    }

    /**
     * Beamer management
     * @return {any} beamer system
     */
    beamer(): any {
        return (window as any).Beamer;
    }
}