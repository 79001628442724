import { Component, Input } from '@angular/core';
import { Project } from '@stuplay'

@Component({
    selector: 'app-project',
    templateUrl: './project.component.html'
})
export class ProjectComponent {
    @Input() project: Project;

    constructor() {}
}
