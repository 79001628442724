import { Component, Input, Output, EventEmitter, OnInit, HostListener } from '@angular/core';
import { SectionContentProvider, ExternalLinkProvider, Journey } from '@stuplay';
import { EmitterService } from '../../../../utils/services/emitter.service';
import { DomSanitizer } from '@angular/platform-browser';
import { StorageService } from '../../../../utils/services/storage.service';
import * as moment from 'moment';
import { DataHelper } from '../../../../utils/helpers';
import { NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'journey-content',
    templateUrl: './journey-content.component.html'
})
export class JourneyContentComponent implements OnInit {
    @Input() contentType: string;
    @Input() content: any;
    @Input() conditions: any;
    @Input() first: boolean = false;
    @Input() last: boolean = false;
    @Input() index: number;
    @Input() movingContent: boolean = false;
    @Input() published: boolean;
    @Input() journey: Journey;
    @Output() editContent: EventEmitter<any> = new EventEmitter;
    @Output() deleteContent: EventEmitter<any> = new EventEmitter;
    @Output() moveContent: EventEmitter<any> = new EventEmitter;
    public company: any;
    public url: any;
    public display: boolean = false;
    public score: number = 50;
    public openDropdown: boolean = false;
    public openDropdownQuestion: boolean[] = [];

    constructor(
        private emitterService: EmitterService,
        private sectionContentProvider: SectionContentProvider,
        private sanitizer: DomSanitizer,
        private storageService: StorageService,
        private externalLinkProvider: ExternalLinkProvider,
        private modalService: NgbModal,
    ) { }

    ngOnInit() {
        this.company = this.storageService.get('company');

        this.emitterService.dropdownActive.subscribe((data) => {
            if (data.index !== this.index) {
                this.openDropdown = false;
            }
        });
    }

    changeOpenDropdown(): void {
        this.openDropdown = !this.openDropdown;
        if (this.openDropdown) {
            this.emitterService.dropdownActive.emit({ index: this.index });
        }
    }

    changeOpenDropdownQuestion(index: number): void {
        const dropdown = !this.openDropdownQuestion[index];
        this.openDropdownQuestion = [];
        this.openDropdownQuestion[index] = dropdown;
    }

    edit(): void {
        this.editContent.emit({ value: this.content });
    }

    editQuestion(index: number): void {
        this.content.questionIndex = index;
        this.editContent.emit({ value: this.content, questionIndex: index });
        this.openDropdownQuestion = [];
    }

    openModal(content: any): void {
        const options: NgbModalOptions = { centered: true, size: 'lg', backdropClass: 'backdrop-custom' };
        this.modalService.open(content, options);
        // this.modalComponent.open()
    }

    delete(): void {
        this.deleteContent.emit();
        this.modalService.dismissAll();
    }

    deleteQuestion(index: number): void {
        const question = this.content.exercise.questions[index];
        this.sectionContentProvider.deleteQuestion(question.id, { traject_id: this.journey.id }).subscribe(() => {
            this.content.exercise.questions.splice(index, 1);
            this.openDropdownQuestion = [];
        });
    }

    sameDay(): boolean {
        const start = moment(this.content.event.eventSessions[0].startAt).format('DD-MM-YYYY');
        const end = moment(this.content.event.eventSessions[0].endAt).format('DD-MM-YYYY');
        return moment(start).isSame(end);
    }

    convertHour(date: string): any {
        return moment(date).format('HH:mm');
    }

    closeAside(): void {
        this.display = false;
    }

    runLTI(content: any): void {
        if (this.content.externalLink.params.mode === 'embed') {
            this.openModal(content);
            this.buildLTIForm(this.content.externalLink, 'embed-iframe')
        } else {
            this.buildLTIForm(this.content.externalLink, '_blank');
        }
    }

    buildLTIForm(integration: any, mode: string): void {
        const params = {
            company_id: this.company.id
        };
        this.externalLinkProvider.startExternalLink(integration.id, params).subscribe((data) => {
            const form = document.createElement('form');
            form.target = mode;
            form.method = 'POST';
            form.action = integration.url;
            for (const key in data.fields) {
                if (data.fields.hasOwnProperty(key)) {
                    const input = document.createElement('input');
                    input.type = 'hidden';
                    input.name = DataHelper.changeCase(key);
                    input.setAttribute('value', data.fields[key]);
                    form.appendChild(input);
                }
            }
            if (mode === '_blank') {
                document.body.appendChild(form);
            } else {
                document.querySelector('#embed-code').appendChild(form);
            }
            form.submit();
        });
    }

    getLogo(content: any): string {
        if (!content.provider.logo) {
            return 'https://static.myskillcamp.com/images/integrations/' + content.provider.key + '.svg';
        } else {
            return content.provider.logo + '?size=128';
        }
    }

    @HostListener('document:click', ['$event'])
    clickout(event: any): void {
        if (event.target.className !== 'icon icon--more') {
            this.openDropdown = false;
        }
    }
}
