import { HttpClient } from "@angular/common/http";
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Comment } from './comment/comment';
import { Account } from '../../account/account';
import { cleanObject } from "../../helpers";
import { environment } from "src/environments/environment";

export class Post {
    id: number;
    timelineId: number;
    account: Account;
    comments: Comment[];
    content;
    created_at: any;

    constructor(post: any, private http: HttpClient) {
        this.extends(post);
    }

    extends(post: any) {
        Object.assign(this, post);
        this.comments = post.comments.map((comment) => new Comment(comment, this.http)).reverse();
        return this;
    }

    update(): Observable<any> {
        return this.http.put(`${environment.envVar.API_URL}/timeline/${this.id}`, cleanObject(this));
    }

    addComment(postId: number, params: any): Observable<any> {
        return this.http.post(`${environment.envVar.API_URL}/timeline/${postId}/content`, params)
            .pipe(map(res => {
                const comment = new Comment(res, this.http);
                this.comments.push(comment);
                return this;
            }));
    }

    deleteComment(id: number): Observable<any> {
        const selectedCommentIndex = this.comments.findIndex(comment => comment.id === id);
        return this.http.delete(`${environment.envVar.API_URL}/timeline/comment/${id}`)
            .pipe(map(() => {
                this.comments.splice(selectedCommentIndex, 1);
                return this;
            }));
    }
}
