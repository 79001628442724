import { Component, Input, Output, EventEmitter, ElementRef, ViewChild, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { trigger, style, transition, animate } from '@angular/animations';
//
import { Subscription } from 'rxjs';
//
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
//
import { Course, Question, CourseProvider } from '@stuplay';
//
import { AuthService } from '../../services/auth.service';
import { ErrorCatcherService } from '../../services/error-catcher.service';
import { StorageService } from '../../services/storage.service';
import { EmitterService } from '../../services/emitter.service';
import { ExternalAppsService } from '../../services/external-apps.service';
import { ToastService } from '../toast/toast.service';
import { RedirectService } from '../../services/redirect.service';

@Component({
    selector: 'app-topbar',
    templateUrl: './topbar.component.html',
    styleUrls: ['./topbar.component.less'],
    animations: [
        trigger('itemAnim', [
            transition(':enter', [
                style({ transform: 'scale(.8)', opacity: 0 }),
                animate(200, style({ transform: 'scale(1)', opacity: 1 }))
            ]),
            transition(':leave', [
                animate(200, style({ transform: 'scale(.8)', opacity: 0 }))
            ])
        ])
    ]
})

export class TopBarComponent {
    @ViewChild('errorsContainer', { static: false }) el: ElementRef;
    @Input() currentUser: any;
    @Input() size: string;
    @Input() course: Course;
    @Output() onPlayerModeChange: EventEmitter<boolean> = new EventEmitter();
    @Output() updatedSize: EventEmitter<string> = new EventEmitter();

    public company: any;
    public countContents: number = 0;
    public data: any;
    public errorOpen: boolean = false;
    public errors: any[] = [];
    public errorsLength: number;
    public errorSubscription: Subscription;
    public loading: boolean = false;
    public me: any;
    public permissions: any;

    constructor(
        private authService: AuthService,
        private router: Router,
        private errorCatcher: ErrorCatcherService,
        private courseProvider: CourseProvider,
        private emitterService: EmitterService,
        private externalAppsService: ExternalAppsService,
        private storageService: StorageService,
        private toastService: ToastService,
        private redirectService: RedirectService,
        private modalService: NgbModal,
    ) { }

    ngOnInit(): void {
        this.me = this.storageService.get('me');
        this.company = this.storageService.get('company');
        this.permissions = this.storageService.get('permissions');

        this.errorSubscription = this.errorCatcher.sendErrorsObservable.subscribe((errors) => {
            if (this.errorsLength < errors.length && this.el) {
                this.el.nativeElement.classList.add('new-error');
                const timeOut = setTimeout(() => {
                    this.el.nativeElement.classList.remove('new-error');
                    clearTimeout(timeOut);
                }, 600);
            }
            this.errors = errors;
            this.errorsLength = errors.length;
        });

        this.data = {
            resetEvents: false
        };

        this.countContents = this.course.sections.length > 0 ? this.course.sections.map((section) => {
            return section.sectionContents ? section.sectionContents.length : 0;
        }).reduce((accumulator, currentValue) => {
            return accumulator + currentValue;
        }) : 0;

        if (this.course && this.course.realignedAt) {
            const realign = new Date(this.course.realignedAt);
            const now = new Date();
            const diff = now.getTime() - realign.getTime();

            if (diff < 1000 * 60 * 5 && this.countContents > 30) {
                this.toastService.push('instance.current.align', 'info');
            }
        }
    }

    ngOnDestroy(): void {
        this.errorSubscription.unsubscribe();
    }

    /**
     *
     */
    openModal(content: any, options?: NgbModalOptions) {
        this.modalService.open(content, options);
    }

    /**
     *
     */
    getFragment(error: any) {
        if (error.elementType === 'project' || error.elementType === 'media' || error.elementType === 'cheatsheet' ||
            error.elementType === 'exchange' || error.elementType === 'scorm' || error.elementType === 'event' || error.elementType === 'certificate') {
            return 'sc-' + error.element.id;
        } else {
            return 'q-' + error.element.id;
        }
    }

    /**
     *
     */
    getTranslation(element: any) {
        if (element instanceof Question) {
            return 'studio.sidebar.section-content.exercise.question-' + element.type;
        } else {
            return 'studio.sidebar.section-content.context-' + element.context;
        }
    }

    /**
     *
     */
    getErrorClass(error: any) {
        return error.element instanceof Question ? 'error-question' : error.element.context;
    }

    /**
     *
     */
    toggleDropdown() {
        this.errorOpen = !this.errorOpen;
    }

    /**
     *
     */
    resetInstance(): void {
        const params = this.data;
        this.courseProvider.resetInstance(this.course, params).subscribe(() => {
            this.modalService.dismissAll();
            location.reload();
        });

        this.loading = true;
    }

    /**
     *
     */
    openModalMedia(content: any) {
        this.openModal(content, { size: 'xl', centered: true, backdropClass: 'backdrop-custom' });
    }

    /**
     *
     */
    openModalReset(content: any): void {
        const realign = new Date(this.course.realignedAt);
        const now = new Date();
        const diff = now.getTime() - realign.getTime();

        if (diff < 1000 * 60 * 5 && this.countContents > 30) {
            this.toastService.push('instance.current.progress', 'warning');
        } else {
            this.openModal(content, { size: 'xl', centered: true, backdropClass: 'backdrop-custom' });
        }
    }

    /**
     *
     */
    openAsideHelp(type: string): any {
        this.emitterService.activeHelp.emit({ type: 'all', feature: 'exercice' });
    }

    /**
     *
     */
    preview(): void {
        this.redirectService.navigate('player', ['course', this.course.id], { company_id: this.company.id, preview: true });
    }

    /**
     *
     */
    goBack(): void {
        if (this.course.schema === 'template') {
            if (this.storageService.get('currentLocation')) {
                this.router.navigateByUrl(this.storageService.get('currentLocation'));
            } else {
                this.router.navigate(['company', this.storageService.get('company').slug, 'home']);
            }
        } else {
            this.router.navigate(['company', this.storageService.get('company').slug, 'instance']);
        }
    }

    /**
     *
     */
    @HostListener('document:click', ['$event'])
    clickout(event: any) {
        if (this.el && !this.el.nativeElement.contains(event.target)) {
            this.errorOpen = false;
        }
    }
}
