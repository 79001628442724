<div class="overlay" [ngClass]="{'active': active}"></div>

<aside class="m-sidebar sidebar sidebar-v2 sidebar--modal sidebar--skill" [ngClass]="{'active': active, 'm-sidebar--full': full}" (appSidebar)="closeSidebar()">
    <div class="sidebar__container">
        <div class="sidebar__head">
            <div class="sidebar__description">
                <h1 class="sidebar__title">
                    {{ 'words.skills' | translate }}
                </h1>
            </div>

            <a class="sidebar__close" (click)="closeSidebar()">
                <i class="icon icon--close"></i>
            </a>
        </div>

        <div class="sidebar__body">
            <div class="skill__List">
                <div class="" *ngIf="filterSkills(true).length > 0">
                    {{ 'words.thematics' | translate }}
                </div>

                <div class="skill skill--rate" *ngFor="let skill of filterSkills(true); let index=index" (mouseout)="displayLevel(index, null, true)">
                    <div class="skill__content" (click)="updateLevel(skill, 0)">
                        <div class="skill__icon">
                            <i class="new-icon icon-grid-outline"></i>
                        </div>

                        <div class="skill__text">
                            {{ skill.name }}
                        </div>

                        <div class="skill__rating">
                            <span [ngClass]="{'active' : isLevel(index, skill, 0, true)}" (mouseover)="displayLevel(index, 1, true)" (click)="updateLevel(skill, 1); $event.stopPropagation()"></span>
                            <span [ngClass]="{'active' : isLevel(index, skill, 1, true)}" (mouseover)="displayLevel(index, 2, true)" (click)="updateLevel(skill, 2); $event.stopPropagation()"></span>
                            <span [ngClass]="{'active' : isLevel(index, skill, 2, true)}" (mouseover)="displayLevel(index, 3, true)" (click)="updateLevel(skill, 3); $event.stopPropagation()"></span>
                        </div>

                        <div class="skill__close" (click)="detachSkill(skill)">
                            <i class="new-icon icon-close-outline"></i>
                        </div>
                    </div>
                </div>

                <div class="" *ngIf="filterSkills().length > 0">
                    {{ 'words.other-skills' | translate }}
                </div>

                <div class="skill skill--rate" *ngFor="let skill of filterSkills(); let index=index" (mouseout)="displayLevel(index)">
                    <div class="skill__content" (click)="updateLevel(skill, 0)">
                        <div class="skill__text">{{ skill.name }}</div>

                        <div class="skill__rating">
                            <span [ngClass]="{'active' : isLevel(index, skill, 0)}" (mouseover)="displayLevel(index, 1)" (click)="updateLevel(skill, 1); $event.stopPropagation()"></span>
                            <span [ngClass]="{'active' : isLevel(index, skill, 1)}" (mouseover)="displayLevel(index, 2)" (click)="updateLevel(skill, 2); $event.stopPropagation()"></span>
                            <span [ngClass]="{'active' : isLevel(index, skill, 2)}" (mouseover)="displayLevel(index, 3)" (click)="updateLevel(skill, 3); $event.stopPropagation()"></span>
                        </div>

                        <div class="skill__close" (click)="detachSkill(skill)">
                            <i class="new-icon icon-close-outline"></i>
                        </div>
                    </div>
                </div>
            </div>

            <div class="form">
                <h2 class="form__title">{{ 'words.add-skill' | translate }}</h2>

                <div class="search">
                    <label class="search__label">
                        <i class="search__icon icon icon--search"></i>
                    </label>

                    <input class="search__input" type="search" [placeholder]="'placeholder.search' | translate" [(ngModel)]="query" [debounce]="800" (onDebounce)="search(true)">

                    <i class="icon icon--close" (click)="removeSearch()"></i>
                </div>

                <div class="skill__add" (click)="createSkill()" *ngIf="canCreate()">
                    <span>{{ 'words.create' | translate }}</span>

                    <div class="skill skill--level skill--level-0 skill--create">
                        <div class="skill__content">
                            <div class="skill__text">
                                {{ query }}
                            </div>

                            <div class="skill__action skill__action-rating">
                                <div class="skill__rating" (mouseout)="setLevel(0)">
                                    <span [ngClass]="{'active': level > 0}" (mouseover)="setLevel(1)"></span>
                                    <span [ngClass]="{'active': level > 1}" (mouseover)="setLevel(2)"></span>
                                    <span [ngClass]="{'active': level > 2}" (mouseover)="setLevel(3)"></span>
                                </div>

                                <div class="skill__rating-desc">
                                    <span>{{ 'words.for-everyone' | translate }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="skill__disabled" *ngFor="let skill of suggestions">
                    <div class="skill disabled">
                        <div class="skill__content">
                            <i class="new-icon icon-slash-outline"></i>
                            {{ skill.name }}
                        </div>
                    </div>

                    <div class="skill__arrow">
                        <i class="new-icon icon-arrow-ios-forward-outline"></i>
                    </div>

                    <div class="skill skill--level skill--level-0">
                        <div class="skill__content">
                            <div class="skill__text">
                                {{ skill.suggestion.name }}
                            </div>

                            <div class="skill__action skill__action-rating" (click)="attachSkill(skill.suggestion)">
                                <div class="skill__rating" (mouseout)="setLevel(0)">
                                    <span [ngClass]="{'active': level > 0}" (mouseover)="setLevel(1)"></span>
                                    <span [ngClass]="{'active': level > 1}" (mouseover)="setLevel(2)"></span>
                                    <span [ngClass]="{'active': level > 2}" (mouseover)="setLevel(3)"></span>
                                </div>

                                <div class="skill__rating-desc">
                                    <span>{{ 'words.for-everyone' | translate }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="skills" *ngIf="!loading">
                    <div class="skills__list">
                        <div class="skill skill--level skill--level-0" *ngFor="let skill of skillCollection">
                            <div class="skill__content">
                                <div class="skill__text">
                                    <div class="skill__icon" *ngIf="skill.thematic">
                                        <i class="new-icon icon-grid-outline"></i>
                                    </div>

                                    {{ skill.name }}
                                </div>

                                <div class="skill__action skill__action-rating" (click)="attachSkill(skill)">
                                    <div class="skill__rating" (mouseout)="setLevel(0)">
                                        <span [ngClass]="{'active': level > 0}" (mouseover)="setLevel(1)"></span>
                                        <span [ngClass]="{'active': level > 1}" (mouseover)="setLevel(2)"></span>
                                        <span [ngClass]="{'active': level > 2}" (mouseover)="setLevel(3)"></span>
                                    </div>

                                    <div class="skill__rating-desc">
                                        <span>{{ 'words.for-everyone' | translate }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <msc-loader [loading]="loading"></msc-loader>
            </div>
        </div>
    </div>
</aside>
