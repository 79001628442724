import { Component, Input, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ScormModule, ScormProvider, SectionContent } from '@stuplay';

const UPLOAD_STATES = {
    FAILED: 'failed',
    PENDING: 'pending',
    DONE: 'done'
}
@Component({
    selector: 'app-scorm',
    templateUrl: './scorm.component.html'
})
export class ScormComponent implements OnInit, AfterViewInit {
    @ViewChild('iframeScorm', { static: false }) iframeScorm;
    @Input() cloud: boolean = false;
    @Input() scorm: ScormModule;
    @Input() sectionContent: SectionContent;

    public url;
    public cloudStatus: string;
    public progress: number = 0;
    public processing: boolean = false;
    public message: string;

    constructor(
        private sanitizer: DomSanitizer,
        private scormProvider: ScormProvider,
    ) { }

    ngOnInit() {
        if (this.cloud) {
            this.getStatusScormCloud();
        } else {
            this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.scorm.url);
        }
    }

    ngAfterViewInit() {
        if (!this.cloud) {
            window.addEventListener('message', (message) => {
                if (['ScormInitialize'].indexOf(message.data.function) !== -1) {
                    var res = {
                        'LMSInitialize': true,
                        'LMSGetLastError': 0,
                        'LMSGetErrorString': '',
                        'LMSGetDiagnostic': '',
                        'LMSFinish': '',
                        'cmi.core.student_id': '',
                        'cmi.core.student_name': '',
                        'cmi.core.lesson_location': '',
                        'cmi.core.credit': 'credit',
                        'cmi.core.lesson_status': 'incomplete',
                        'cmi.core.entry': '',
                        'cmi.core.score.raw': 0,
                        'cmi.core.score.max': 100,
                        'cmi.core.score.min': 0,
                        'cmi.core.total_time': 0,
                        'cmi.core.lesson_mode': 'browse',
                        'cmi.core.suspend_data': '',
                        'cmi.launch_data': ''
                    };
                    const iframeWin = this.iframeScorm.nativeElement.contentWindow;

                    iframeWin.postMessage({
                        isCallback: true,
                        uuid: message.data.uuid,
                        data: res
                    }, '*');
                }
            }, false);
        }
    }

    getStatusScormCloud(): void {
        if (this.scorm.upload?.status === UPLOAD_STATES.PENDING) {
            const interval = setInterval(() => {
                this.scormProvider.getStatus(this.scorm.id).subscribe((scorm) => {
                    this.progress = scorm.progress;
                    this.message = scorm.message;
                    if (scorm.status === UPLOAD_STATES.FAILED) {
                        this.cloudStatus = UPLOAD_STATES.FAILED;
                        clearInterval(interval);
                    } else if (scorm.status === UPLOAD_STATES.DONE) {
                        this.cloudStatus = UPLOAD_STATES.DONE;
                        clearInterval(interval);
                    } else {
                        this.cloudStatus = UPLOAD_STATES.PENDING;
                    }
                });
            }, 2000);
        } else if (!this.scorm.upload || this.scorm.upload?.status === UPLOAD_STATES.DONE) {
            this.cloudStatus = UPLOAD_STATES.DONE;
        }
    }

    launchScormCloud(): void {
        this.processing = true;
        this.scormProvider.launchScorm(this.scorm.id, this.sectionContent.id, true).subscribe((data) => {
            this.processing = false;
            window.open(data.url, '_blank');
        });
    }
}
