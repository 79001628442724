export class DataHelper {

    public static getUniqueId(prefix?: string): string {
        const random = Math.random().toString(36).substr(2, 16);
        return prefix ? prefix + random : random;
    }

    public static append(object: any, objectToAppend: any): any {
        for (const key in objectToAppend) {
            if (objectToAppend.hasOwnProperty(key)) {
                object[key] = objectToAppend[key];
            }
        }
        return object;
    }

    public static split(content: string, last: string): string {
        return content.substring(0, content.indexOf(last));
    }

    public static splitBetween(content: string, first: string, last: string): string {
        return content.substring(content.indexOf(first), content.indexOf(last));
    }

    public static assign(assigner: any, base: any = {}): any {
        return Object.assign(base, assigner);
    }

    public static isEmpty(content: any): boolean {
        return Object.keys(content).length === 0;
    }

    public static move(collection: any, currentIndex: number, newIndex: number): any {
        collection.splice(newIndex, 0, collection.splice(currentIndex, 1)[0]);
        return collection;
    }

    public static changeCase(value: string, type: string = 'snake'): string {
        switch (type) {
            case 'camel':
                let splitedValue = value.split('_');
                splitedValue = splitedValue.map((splited, index) => {
                    if (index > 0) {
                        return splited.charAt(0).toUpperCase() + splited.slice(1);
                    }
                    return splited;
                });
                value = splitedValue.join('');
                break;
            case 'snake':
                value = value.split(/(?=[A-Z])/).join('_').toLowerCase();
                break;
        }

        return value;
    }

    public static filterCheckbox(object: any, splitter?: string): any {
        const collection = [];

        for (const key in object) {
            if (object[key] && object.hasOwnProperty(key)) {
                collection.push(key);
            }
        }

        if (splitter && collection.length > 0) {
            return collection.join(splitter);
        }
        return collection.length > 0 ? collection : null;
    }

    public static merge(object: any, objectToMerge: any): any {
        for (const key in objectToMerge) {
            if (objectToMerge.hasOwnProperty(key)) {
                object[key] = objectToMerge[key];
            }
        }
        return object;
    }

    public static isJSON(value: any): boolean {
        try {
            JSON.parse(value);
            return true;
        } catch (e) {
            return false;
        }
    }
}
