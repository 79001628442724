import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ExternalAppsService } from "../../../utils/services/external-apps.service";
import { StorageService } from "../../../utils/services/storage.service";

@Component({
    selector: 'msc-appcues',
    templateUrl: './appcues.component.html'
})
export class AppcuesComponent implements OnInit {
    // @Output() back: EventEmitter<any> = new EventEmitter();
    // @Input() currentJob: string = 'learn';
    // private appcues: Appcues = new Appcues();
    // private company: any;
    // public me: any;
    // public appcuesGuides: any[] = [];

    constructor(private router: Router, private externalAppsService: ExternalAppsService, private storageService: StorageService) {}

    ngOnInit() {
        //     this.me = this.storageService.get('me');
        //     this.company = this.storageService.get('company');
        //
        //     const timeOut = setTimeout(() => {
        //         this.appcuesGuides = this.appcues.get(this.currentJob);
        //         clearTimeout(timeOut);
        //     }, 1000);
        // }
        //
        // dismissAppcues(): void {
        //     this.back.emit(true);
        // }
        //
        // startGuide(guide: any): void {
        //     let url = '';
        //
        //     if (guide.redirect === 'history') {
        //         url = `account/history`;
        //     } else if (guide.redirect === 'profile') {
        //         url = `account/profile`;
        //     } else if (guide.redirect === 'language') {
        //         url = `account/settings`;
        //     } else {
        //         url = `${this.currentJob}/${this.company.slug}/${guide.redirect}`;
        //     }
        //     this.router.navigateByUrl(url);
        //
        //     const timeOut = setTimeout(() => {
        //         this.externalAppsService.appcues().show(guide[this.me.language.code].appcuesId);
        //         clearTimeout(timeOut);
        //     }, 2000);
    }
}
