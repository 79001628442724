import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
//
import { UuidPipe } from 'src/app/utils/pipes/uuid.pipe';
//
export type CommonThSortType = 'asc' | 'desc';
export interface CommonThSortDatum {
    id: string;
    name: string;
    type: CommonThSortType;
}
//
const COMMON_TH_SORT_TYPE_LIST: ReadonlyArray<CommonThSortType> = ['asc', 'desc'];

@Component({
    selector: 'msc-common-th-sort',
    templateUrl: './th-sort.component.html',
    styleUrls: ['./th-sort.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [UuidPipe],
})

export class CommonThSortComponent {
    @Input() label: string;
    @Input() name?: string; // used for table incapsulation of this component
    @Output() sortEvent: EventEmitter<CommonThSortType> = new EventEmitter<CommonThSortType>();

    public data: Array<CommonThSortDatum> = [];
    public id: string = this.uuidPipe.transform();

    constructor(
        private readonly uuidPipe: UuidPipe,
    ) { }

    ngOnInit(): void {
        this.data = this.getData();
    }

    /**
     * Get the th data
     */
    getData(): Array<CommonThSortDatum> {
        return COMMON_TH_SORT_TYPE_LIST.map((type) => ({
            id: `${this.id}-${type}`,
            name: this.name || this.id,
            type,
        }));
    }

    /**
     * Event handler for sort
     */
    onSort(type: CommonThSortType): void {
        this.sortEvent.emit(type);
    }
}
