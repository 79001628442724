import { Upload } from '../../../../media';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { cleanObject } from "../../../../helpers";
import { environment } from 'src/environments/environment';

export class Video {
    upload?: Upload;
    url: string;
    content?: any;
    thumbNail?: string;
    id: number;

    constructor(video: any, protected http: HttpClient) {
        this.extends(video);
    }

    extends(project: any): Video {
        Object.assign(this, project);
        return this;
    }

    update(): Observable<Video> {
        return this.http.put(`${environment.envVar.API_URL}/studio/video/${this.id}`, cleanObject(this)).pipe(map(res => this.extends(res)));
    }
}
