import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CourseProvider } from '@stuplay';
import { StorageService } from '../../../../utils/services/storage.service';
import { RedirectService } from '../../../../utils/services/redirect.service';

@Component({
    selector: 'learners-ondemand',
    templateUrl: './learners-ondemand.component.html'
})
export class LearnersOnDemandComponent implements OnInit {
    @Input() template: any;
    @Output() onClose: EventEmitter<any> = new EventEmitter;
    public accountsList: any[] = [];
    public me: any;
    public active: boolean = false;
    public loading: boolean = false;
    public pagination: any = null;

    constructor(
        private courseProvider: CourseProvider,
        private storageService: StorageService,
        private redirectService: RedirectService,
    ) { }

    ngOnInit() {
        this.me = this.storageService.get('me');
        this.activeEffect();
        this.getLearnersOnDemand()
    }

    activeEffect(): void {
        const timeOut = setTimeout(() => {
            this.active = true;
            clearTimeout(timeOut);
        }, 100);
    }

    closeSidebarEmit(): void {
        this.active = false;
        const timeOut = setTimeout(() => {
            this.onClose.emit(true);
            clearTimeout(timeOut);
        }, 300);
    }

    search(search: string = ''): void {
        this.courseProvider.learnersOnDemand(this.template.id, search).subscribe((data) => {
            this.accountsList = data.accounts;
            this.pagination = data.pagination;
        });
    }

    getLearnersOnDemand(): void {
        this.courseProvider.learnersOnDemand(this.template.id, '').subscribe((data) => {
            this.accountsList = data.accounts;
            this.pagination = data.pagination;
        });
    }

    checkIfDisplayButton(): number {
        const accountIds = [];
        this.accountsList.forEach((account) => {
            if (this.me.id !== account.id) {
                accountIds.push(account.id)
            }
        });
        return accountIds.length;
    }

    goToDiscussion(accountId?: number): void {
        if (accountId) {
            this.redirectService.navigate('app', ['conversation', 'create'], { accounts_ids: accountId });
        } else {
            const accountIds = [];
            this.accountsList.forEach((account) => {
                if (this.me.id !== account.id) {
                    accountIds.push(account.id)
                }
            });
            this.redirectService.navigate('app', ['conversation', 'create'], { accounts_ids: accountIds.toString() });
        }
    }

    checkIfUser(account: any): boolean {
        return this.me.id === account.id
    }
}
