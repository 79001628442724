import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Folder, FolderProvider, CourseProvider } from '@stuplay';
import { ToastService } from '../../../utils/components/toast/toast.service';
import { SubfolderService } from '../../../utils/components/subfolder/subfolder.service';

@Component({
    selector: '<moveto>',
    templateUrl: './moveto.component.html'
})
export class MoveToComponent implements OnInit {
    @Input() object: any;
    @Input() folders: Folder[];
    @Output() close: EventEmitter<any> = new EventEmitter;
    @Output() move: EventEmitter<any> = new EventEmitter;
    public active: boolean = false;
    public folderSelected: Folder;
    public query: string;

    constructor(
        private folderProvider: FolderProvider,
        private courseProvider: CourseProvider,
        private toastService: ToastService,
        private subfolderService: SubfolderService,
    ) { }

    ngOnInit() {
        this.activeEffect();

        this.subfolderService.listen().subscribe((folder) => {
            this.folderSelected = folder;
        });
    }

    activeEffect(): void {
        const timeOut = setTimeout(() => {
            this.active = true;
            clearTimeout(timeOut);
        }, 100);
    }

    closeSidebar(): void {
        this.active = false;
        const timeOut = setTimeout(() => {
            this.close.emit(true);
            clearTimeout(timeOut);
        }, 300);
    }

    search(): void {
        this.folderProvider.getFoldersTree({ q: this.query }).subscribe((data) => {
            this.folders = data;
        });
    }

    resetSearch(): void {
        this.query = '';
        this.search();
    }

    moveTo(): void {
        const link = ['folder', this.folderSelected.slug];
        if (this.object.type === 'template') {
            this.courseProvider.moveTo(this.object.item.id, this.folderSelected.id).subscribe((data) => {
                this.process(link, 'template');
            });
        } else {
            this.folderProvider.moveTo(this.object.item.id, this.folderSelected.id).subscribe((data) => {
                this.process(link, 'folder');
            });
        }
    }

    dropFolder(): void {
        const link = ['home'];
        if (this.object.type === 'template') {
            this.courseProvider.moveTo(this.object.item.id, 0).subscribe((data) => {
                this.process(link, 'template');
            });
        } else {
            this.folderProvider.moveTo(this.object.item.id, 0).subscribe((data) => {
                this.process(link, 'folder');
            });
        }
    }

    process(link: any, type: string): void {
        this.move.emit(true);
        this.toastService.push(type + '-moved', 'success', 5000, link);
        this.closeSidebar();
    }
}
