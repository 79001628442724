<div class="media-manager" [ngStyle]="{'width': width+'px', 'height': height+'px'}" [ngClass]="{'media-manager_empty': !media, 'media-manager_mini': mini, 'margin': margin}">
    <div class="m-placeholder" *ngIf="!media" (click)="modalOpen(modalMedia)">
        <img style="width: 100%; height: 100%; object-fit: cover" *ngIf="cover" [src]="cover">

        <div *ngIf="!cover" class="placeholder" style="text-align: left;">
            <div class="placeholder__title">{{ 'studio.media.library' | translate }}</div>
            <div class="placeholder__text">{{ 'studio.media.add_media' | translate }}</div>
        </div>
    </div>

    <ng-container *ngIf="media">
        <media-holder (mediaStatus)="setError($event)" [media]="media" [mini]="mini" [width]="width" [height]="height" [size]="size"></media-holder>
    </ng-container>

    <div *ngIf="error">
        {{ 'studio.media.error' | translate }}
    </div>

    <div class="media-manager__actions" [ngClass]="{'m-delete': media && leftTrash}" *ngIf="!disable">
        <button class="btn add-media" *ngIf="!media" (click)="modalOpen(modalMedia)">
            <span class="sr-only"></span>
        </button>

        <button class="btn btn_color_red delete" *ngIf="media" (click)="delete()">
            <span class="sr-only">Delete</span>
        </button>
    </div>
</div>

<ng-template #modalMedia let-modal class="media-library">
    <library-modal (closeModal)="modalClose()" (updateMedia)="onUpdatedMedia($event)" [toUpload]="true" [onlyPictures]="onlyPictures"></library-modal>
</ng-template>

<style>
    .margin {
        margin: 0 0 3rem 0;
    }
</style>
