<div class="templab-library__body" *ngIf="activityActive !== 'exercise'">
    <a class="button--link grid middle" (click)="selectActivity('')" *ngIf="activityActive !== '' && !content">
        <i class="icon icon icon--arrow-left"></i> {{ 'studio.publish.button.back' | translate }}
    </a>

    <ul class="templab-library__activity" *ngIf="activityActive === ''">
        <li>
            <div class="templab-card-activity" (click)="selectActivity('exercise')">
                <div class="templab-card-activity__content">
                    <div class="templab-card-activity__icon">
                        <img src="https://static.myskillcamp.com/images/illustration/section-content/exercise.svg">
                    </div>

                    <h4 class="templab-card-activity__title">
                        {{ 'studio.modal-activity.exercise' | translate }}
                    </h4>

                    <p class="templab-card-activity__paragraph">
                        {{ 'studio.modal-activity.exercise-description' | translate }}
                    </p>
                </div>
            </div>
        </li>

        <li>
            <div class="templab-card-activity" (click)="selectActivity('exchange')">
                <div class="templab-card-activity__content">
                    <div class="templab-card-activity__icon">
                        <img src="https://static.myskillcamp.com/images/illustration/section-content/discussion.svg">
                    </div>

                    <h4 class="templab-card-activity__title">
                        {{ 'studio.modal-activity.discussion' | translate }}
                    </h4>

                    <p class="templab-card-activity__paragraph">
                        {{ 'studio.modal-activity.discussion-description' | translate }}
                    </p>
                </div>
            </div>
        </li>

        <li>
            <div class="templab-card-activity" (click)="selectActivity('cheatsheet')">
                <div class="templab-card-activity__content">
                    <div class="templab-card-activity__icon">
                        <img src="https://static.myskillcamp.com/images/illustration/section-content/cheatsheet.svg">
                    </div>

                    <h4 class="templab-card-activity__title">
                        {{ 'studio.modal-activity.cheatsheet' | translate }}
                    </h4>

                    <p class="templab-card-activity__paragraph">
                        {{ 'studio.modal-activity.cheatsheet-description' | translate }}
                    </p>
                </div>
            </div>
        </li>

        <li>
            <div class="templab-card-activity" (click)="openModal(modalMedia)">
                <div class="templab-card-activity__content">
                    <div class="templab-card-activity__icon">
                        <img src="https://static.myskillcamp.com/images/illustration/section-content/media.svg">
                    </div>

                    <h4 class="templab-card-activity__title">
                        {{ 'studio.modal-activity.media' | translate }}
                    </h4>

                    <p class="templab-card-activity__paragraph">
                        {{ 'studio.modal-activity.media-description' | translate }}
                    </p>
                </div>
            </div>
        </li>

        <li>
            <div class="templab-card-activity" (click)="openModal(modalScormCloud, { size: 'xl', centered: true })">
                <div class="templab-card-activity__content">
                    <div class="templab-card-activity__icon">
                        <img src="https://static.myskillcamp.com/images/illustration/section-content/scorm.svg">
                    </div>

                    <h4 class="templab-card-activity__title">
                        {{ 'studio.modal-activity.scorm' | translate }}
                    </h4>

                    <p class="templab-card-activity__paragraph">
                        {{ 'studio.modal-activity.scorm-description' | translate }}
                    </p>
                </div>
            </div>
        </li>

        <li>
            <div class="templab-card-activity" (click)="openAside()">
                <div class="templab-card-activity__content">
                    <div class="templab-card-activity__icon">
                        <img src="https://static.myskillcamp.com/images/illustration/section-content/scorm.svg">
                    </div>

                    <h4 class="templab-card-activity__title">
                        {{ 'studio.modal-activity.lti' | translate }}
                    </h4>

                    <p class="templab-card-activity__paragraph">
                        {{ 'studio.modal-activity.lti-description' | translate }}
                    </p>
                </div>
            </div>
        </li>
    </ul>

    <div class="" *ngIf="activityActive && activityActive !== 'certificate' && activityActive !== 'exercise'" style="margin-top: 1rem;">
        <h2 class="heading">
            <live-editor (onUpdate)="updateSectionContent()" [(inputData)]="generatedSectionContent.title" [placeholder]="('studio.course.section-content.'+ activityActive +'-hint' | translate)"></live-editor>
        </h2>

        <p class="">
            <msc-froala [(content)]="generatedSectionContent.description" (onUpdate)="updateSectionContent()"></msc-froala>
        </p>
    </div>

    <div class="" *ngIf="activityActive === 'exchange'">
        <app-exchange [exchange]="generatedSectionContent.exchange" [sectionContent]="generatedSectionContent"></app-exchange>
    </div>

    <div class="" *ngIf="activityActive === 'cheatsheet'">
        <app-cheatsheet [cheat]="generatedSectionContent.cheatsheet" [sectionContent]="generatedSectionContent"></app-cheatsheet>
    </div>

    <div class="" *ngIf="activityActive === 'media'">
        <app-media [media]="generatedSectionContent.media" [sectionContent]="generatedSectionContent"></app-media>
    </div>

    <div class="" *ngIf="activityActive === 'scormcloud'">
        <app-scorm [cloud]="true" [scorm]="generatedSectionContent.scormcloud" [sectionContent]="generatedSectionContent"></app-scorm>
    </div>

    <div class="" *ngIf="activityActive === 'project'">
        <app-project [project]="generatedSectionContent.project"></app-project>
    </div>

    <div class="" *ngIf="activityActive === 'external_link'">
        <app-external-link [externalLink]="generatedSectionContent.externalLink" [sectionContent]="generatedSectionContent"></app-external-link>
    </div>

    <div class="" *ngIf="activityActive === 'certificate'">
        <app-certificate [certificate]="generatedSectionContent.certificate" [sectionContent]="generatedSectionContent" [disabledGlobal]="true"></app-certificate>
    </div>

    <msc-aside-lti *ngIf="displayAside" (onSave)="addLti($event)" (onClose)="closeAside()"></msc-aside-lti>

    <ng-template #modalMedia let-modal class="media-library" id="media-library">
        <library-modal (closeModal)="modal.close()" (updateMedia)="selectActivity('media', $event.id)" [toUpload]="true"></library-modal>
    </ng-template>

    <ng-template #modalScormCloud let-modal>
        <scorm-modal [cloud]="true" (closeModal)="modal.close()" (updateScorm)="selectActivity('scormcloud', $event)"></scorm-modal>
    </ng-template>
</div>

<footer class="templab-library__footer" *ngIf="activityActive && activityActive !== 'exercise'">
    <div class="sidebar__btn">
        <div class="sidebar__btn-right grid">
            <a class="templab-btn templab-button--border" (click)="selectActivity('')">
                {{ 'studio.common.button.cancel' | translate }}
            </a>

            <a class="templab-btn templab-btn--secondary" (click)="addActivity()" *ngIf="!content">
                {{ 'studio.journey.add-activity' | translate }}
            </a>

            <a class="templab-btn templab-btn--secondary" (click)="editActivity()" *ngIf="content">
                {{ 'words.modify' | translate }}
            </a>
        </div>
    </div>
</footer>

<journey-library-question *ngIf="activityActive === 'exercise'" [journey]="journey" [sectionContent]="generatedSectionContent" [questionIndex]="questionIndex" (back)="selectActivity('')" (add)="addActivity()">
</journey-library-question>
