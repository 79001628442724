<header class="header header__studio">
    <div class="header__logo" (click)="redirectTo()">
        <div class="logo" *ngIf="!company.experience" [style.backgroundImage]="'url(https://static.myskillcamp.com/lxp/images/logo.svg)'"></div>
        <div class="logo" *ngIf="company.experience" [style.backgroundImage]="'url(' + company.experience.displayPicture + '?size=512)'"></div>
    </div>

    <div class="header__switch">
        <div class="job__switch" (click)="displayed('roles')">
            <a>
                <i class="new-icon icon-manage"></i> {{ 'words.create' | translate }}
                <i class="new-icon icon-arrow-ios-downward-outline"></i>
            </a>

            <div class="dropdown" [ngClass]="{'active': displayDropdowns.roles }">
                <ul class="job__list">
                    <li class="job__item" *ngIf="isRole('company_learner')" (click)="redirect('learn')">
                        <a class="job__title">
                            <i class="new-icon icon-learn"></i> {{ 'words.learn' | translate }}
                        </a>
                    </li>

                    <li class="job__item" *ngIf="isRole('company_author')">
                        <a class="job__title active">
                            <i class="new-icon icon-create"></i> {{ 'words.create' | translate }}
                        </a>
                    </li>

                    <li class="job__item" *ngIf="isRole('company_manager')" (click)="redirect('manage')">
                        <a class="job__title">
                            <i class="new-icon icon-manage"></i> {{ 'words.coach' | translate }}
                        </a>
                    </li>

                    <li class="job__item">
                        <a class="job__title" *ngIf="isRole('company_administrator')" (click)="redirect('organize')">
                            <i class="new-icon icon-organize"></i> {{ 'words.organise' | translate }}
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <nav class="header__nav nav">
        <a class="nav__link" (click)="navigateTo('home')" [ngClass]="{'active': isActive('home')}">
            <i class="icon icon--modul"></i> {{ 'studio.template.title' | translate }}
            <span class="nav__number">{{ counts?.templates }}</span>
        </a>

        <a class="nav__link" (click)="navigateTo('instance')" [ngClass]="{'active': isActive('instance')}">
            <i class="icon icon--calendar"></i> {{ 'studio.instance.title' | translate }}
            <span class="nav__number">{{ counts?.instances }}</span>
        </a>

        <a class="nav__link" (click)="navigateTo('journey')" [ngClass]="{'active': isActive('journey')}">
            <i class="icon icon--journey"></i> {{ 'studio.journey.title' | translate }}
            <span class="nav__number">{{ counts?.trajects }}</span>
        </a>

        <a class="nav__link" (click)="navigateTo('library')" [ngClass]="{'active': isActive('library')}">
            <i class="icon icon--media"></i> {{ 'studio.media.title' | translate }}
        </a>
    </nav>

    <div class="header__action">
        <div class="link link-help" (click)="displayed('help')">
            <i class="icon icon-question-mark-circle"></i>
        
            <span>{{ 'words.help' | translate }}</span>
        </div>
        <a class="link link-help" (click)="beamer()" style="color: #F88A56; margin: 0 1rem 0 0;">
			{{ 'words.beamer' | translate }}
		</a>
        <div class="dropdown-hidden dropdown-help" [ngClass]="{'active': displayDropdowns.help}" [(autoClose)]="displayDropdowns.help">
            <ul (click)="displayed('help')">
                <li (click)="support()" [attr.id]="'chat-help-hook'">
                    <i class="icon icon-question-mark-circle"></i>
        
                    <div class="dropdown-help__content">
                        <div class="dropdown-help__title">
                            {{ 'words.support.manage' | translate }}
                        </div>
        
                        <div class="dropdown-help__description">
                            {{ 'words.support.description.manage' | translate }}
                        </div>
                    </div>
                </li>
        
                <li (click)="documentation()">
                    <i class="icon icon-layers-1"></i>
        
                    <div class="dropdown-help__content">
                        <div class="dropdown-help__title">
                            {{ 'words.documentation' | translate }}
                        </div>
        
                        <div class="dropdown-help__description">
                            {{ 'words.documentation.description' | translate }}
                        </div>
                    </div>
                </li>
        
                <li (click)="displayed('appcues')"
                    *ngIf="booted('appcues') && access && access.permissions && access.permissions.companyAccessPlatform">
                    <i class="icon icon-book-open"></i>
        
                    <div class="dropdown-help__content">
                        <div class="dropdown-help__title">
                            {{ 'words.guides' | translate }}
                        </div>
        
                        <div class="dropdown-help__description">
                            {{ 'words.guides.description' | translate }}
                        </div>
                    </div>
                </li>
            </ul>
        </div>

        <a class="link link-logout" (click)="openModal(modalLogout)">
            <i class="new-icon icon-power-outline"></i>
        </a>
    </div>
</header>

<ng-template #modalLogout let-modal>
    <div class="modal-header">
        <span></span>
        <button type="button" class="close" aria-label="Close" (click)="modal.close()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div style="background-color: #FFFFFF; padding: 5rem; text-align: center;">
            {{ 'words.logout' | translate }}

            <div class="actions" style="padding-top: 2rem;">
                <button class="btn btn_color_red" (click)="logout()">
                    {{ 'words.yes' | translate }}
                </button>

                <button class="btn btn_color_grey" (click)="modal.close()">
                    {{ 'words.no' | translate }}
                </button>
            </div>
        </div>
    </div>
</ng-template>
