<div class="date-picker {{ class }}">
    <div class="grid between">
        <a class="date-picker__reset" (click)="resetDate()">
            {{ ((reset) ? 'words.reset' : '') | translate }}
        </a>
        <a class="date-picker__close" (click)="closeDatepicker()">
            <i class="icon icon--close"></i>
        </a>
    </div>

    <header class="date-picker__header">
        <nav class="date-picker__nav">
            <a class="date-picker__nav-icon" (click)="prevMonth();">
                <i class="icon icon--arrow-left"></i>
            </a>
            <span class="date-picker__nav-title">{{ 'month.'+ currentDate.format('MMMM') | translate }}</span>
            <a class="date-picker__nav-icon" (click)="nextMonth();">
                <i class="icon icon--arrow-right"></i>
            </a>
        </nav>
        <nav class="date-picker__nav">
            <a class="date-picker__nav-icon" (click)="prevYear()">
                <i class="icon icon--arrow-left"></i>
            </a>
            <span class="date-picker__nav-year">{{ currentDate.format('YYYY') }}</span>
            <a class="date-picker__nav-icon" (click)="nextYear()">
                <i class="icon icon--arrow-right"></i>
            </a>
        </nav>
    </header>

    <div class="date-picker__day">
        <span class="date-picker__day-name" *ngFor="let name of dayNames[currentLang]">
            {{ name }}
        </span>
    </div>

    <div class="date-picker__week" *ngFor="let week of weeks">
        <span class="date-picker__week-date" *ngFor="let day of week" [ngClass]="{ active: day.today, disabled: !isSelectedMonth(day.mDate), select: day.selected}" (click)="selectDate(day, isSelectedMonth(day.mDate))">
            {{ day.mDate.date() }}
        </span>
    </div>
</div>
