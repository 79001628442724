<div class="loader" *ngIf="loading">
    <svg width="60px" height="40px" viewBox="0 0 250 92">
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="loader" fill="#080D38">
                <circle class="dot-1" cx="40.5" cy="46" r="24"></circle>
                <circle class="dot-2" cx="124.84668" cy="46" r="24"></circle>
                <circle class="dot-3" cx="209.5" cy="46" r="24"></circle>
            </g>
        </g>
    </svg>
</div>
