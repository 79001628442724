import { Component, Input } from '@angular/core';
import { Media } from '@stuplay';
import { environment } from '../../../../environments/environment';

@Component({
    selector: 'media-image',
    templateUrl: './image.component.html'
})
export class MediaImageComponent {
    @Input() media: Media;
    @Input() size: number;
    stockageUrl: string = environment.envVar.STORAGE_URL;
}
