import { Pipe, PipeTransform } from '@angular/core';
import { Folder, Course } from '@stuplay';

@Pipe({
    name: 'filterFolders'
})
export class FilterFoldersPipe implements PipeTransform {
    arrayFolder: any[] = [];
    arrayCourse: any[] = [];

    transform(values: any[], args?: any): any {
        if (args === 'showFolders') {
            return values.filter((value) => value instanceof Folder)
        } else if (args === 'showCourses') {
            return values.filter((value) => value instanceof Course)
        } else if (args === 'IMG' || args === 'DOC' || args === 'PDF' || args === 'EMBED') {
            return values.filter((value) => value.type === args)
        } else {
            return values
        }
    }
}
