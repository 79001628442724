import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Media, MediaProvider } from '@stuplay';

@Component({
    selector: 'media-holder',
    templateUrl: './media.component.html',
    styleUrls: ['./media.component.less']
})
export class MediaComponent implements OnInit {
    @Input() media: Media;
    @Input() size: number;
    @Input() width: number;
    @Input() height: number;
    @Input() mini: boolean;
    @Output() mediaStatus: EventEmitter<boolean> = new EventEmitter();
    public status: number;
    public init: boolean = false;

    constructor(private mediaProvider: MediaProvider) { }

    ngOnInit() {
        if (this.media && this.media.type !== 'EMBED') {
            this.mediaProvider.getStatus(this.media.id).subscribe((finalStatus) => {
                if (finalStatus.status === 0) {
                    const interval = setInterval(() => {
                        this.mediaProvider.getStatus(this.media.id).subscribe((media) => {
                            if (media.status === -1) {
                                this.mediaStatus.emit(false);
                                this.status = -1;
                                clearInterval(interval);
                            } else if (media.status === 1) {
                                this.mediaProvider.getMedia(this.media.id).subscribe((media) => {
                                    this.status = 1;
                                    this.media = media;
                                    this.mediaStatus.emit(true);
                                });
                                clearInterval(interval);
                            } else {
                                this.status = 0;
                            }
                        });
                    }, 2000);
                } else {
                    this.status = finalStatus.status;
                }
            });
        }
        this.init = true;
    }
}
