import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Course } from '@stuplay';

@Injectable()
export class ErrorCatcherService {
    errors: any[] = [];
    sendErrors = new BehaviorSubject<any[]>(this.errors);
    sendErrorsObservable = this.sendErrors.asObservable();
    rules: any = require('./validation-rules.json');

    setErrors(element: any, exercise?: any): void {
        if (element) {
            const searchKey = element.constructor.name === 'SectionContent' ? element.context : element.constructor.name;

            for (const key in this.rules[searchKey]) {
                const data = this.rules[searchKey][key].split(':');
                const chain = this.chainProperties(element, key, data);
                if (!chain && (!this.errors.find((item) => item.element.id === element.id) || !this.errors.find(item => item.key === key))) {
                    exercise ? this.pushToErrors(element, key, searchKey, this.rules[searchKey][key], exercise.sectionId) : this.pushToErrors(element, key, searchKey, this.rules[searchKey][key], element.sectionId)
                } else if (chain && this.errors.find((item) => item.element.id === element.id)) {
                    this.removeElementFromErrors(element, key);
                }
            }
            this.sendErrors.next(this.errors);
        }
    }

    //Remove the element from errors array when error is corrected
    removeElementFromErrors(element: any, key: string): void {
        const elementToRemove: any = this.errors.find((item) => item.element.id === element.id && item.key === key);

        if (elementToRemove) {
            const elementToRemoveIndex: number = this.errors.findIndex((item) => item.element.id === elementToRemove.element.id && item.key === key)
            this.errors.splice(elementToRemoveIndex, 1);
        }
    }

    //Create errors array when course is init
    setErrorsOnInit(course: Course): void {
        this.errors = [];
        course.sections.forEach((section) => {
            if (section.sectionContents && section.sectionContents.length > 0) {
                section.sectionContents.forEach((sectionContent) => {
                    this.setErrors(sectionContent);
                    if (sectionContent.context === 'exercise') {
                        sectionContent.exercise.questions.forEach(question => {
                            this.setErrors(question, sectionContent);
                        })
                    }
                })
            }
        })
    }

    //Remove element from errors array when element is deleted by the user
    removeFromErrors(element: any): void {
        const elements: any[] = this.errors.filter((item) => item.element.id === element.id);
        elements.forEach(item => {
            const index = this.errors.findIndex((error) => error.element.id === item.element.id);

            if (index !== -1) {
                this.errors.splice(index, 1);
                this.sendErrors.next(this.errors);
            }
        })
    }

    pushToErrors(element: any, key: string, searchKey: string, data: any, sectionId?: number): void {
        const myElement = {
            element: element,
            elementType: searchKey,
            key: key,
            sectionId: sectionId,
            data: data
        }

        this.errors.push(myElement)
    }

    // Chains the properties to access object elements
    chainProperties(element: any, key: string, data: any): any {
        const properties = key.split('.');
        const elementWithProperties = element[properties[0]];

        if (properties.length === 1) {
            return eval('this.' + data[0] + 'ElementValidation(elementWithProperties, data)');
        } else {
            if (elementWithProperties instanceof Array) {
                let result = true;
                elementWithProperties.forEach(item => {
                    if (!this.chainProperties(item, properties.slice(1).join('.'), data)) {
                        result = false;
                    }
                });
                return result;
            } else {
                return this.chainProperties(elementWithProperties, properties.slice(1).join('.'), data);
            }
        }
    }

    //VALIDATING FUNCTIONS
    notNullElementValidation(element: any): boolean {
        return !element.includes(null) && !element.includes('');
    }

    requiredElementValidation(element: any): boolean {
        return !!element;
    }

    countElementValidation(element: any, data?: any[]): boolean {
        const number = data[data.length - 1];
        let result = true;

        if (element) {
            element.forEach(item => {
                if (!item) {
                    result = false
                }
            });

            return result && element.length >= number;
        }
    }
}
