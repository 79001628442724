<button type="button" [ngClass]="{ 'is-open': isOpen }" (click)="onOpen()">
    <ng-container *ngTemplateOutlet="templateButton || defaultTemplateButton; context: { data: dataSelect }"></ng-container>
</button>

<div [ngClass]="{ 'is-open': isOpen, 'is-up': isUp }">
    <ng-container *ngIf="hasSearch">
        <msc-common-search [data]="data" [key]="'label'" [placeholder]="'common.search' | translate" (searchEvent)="onSearch($event)"></msc-common-search>
    </ng-container>
    <msc-common-tree [data]="dataRender" [template]="templateOption || defaultTemplateOption"></msc-common-tree>
</div>

<ng-template #defaultTemplateButton let-data="data">
    <ng-container *ngIf="data?.length">
        <span>{{ data[0]?.label }}</span>
    </ng-container>
</ng-template>

<ng-template #defaultTemplateOption let-datum="datum">
    <button class="option" type="button" (click)="onSelect(datum)">
        <span>{{ datum?.label }}</span>
    </button>
</ng-template>
