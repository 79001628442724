import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Media } from '@stuplay';
import { NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'media-manager',
    templateUrl: './media-manager.component.html',
    styleUrls: ['./media-manager.component.less']
})
export class MediaManagerComponent {
    @Input() media: Media;
    @Input() size: number;
    @Input() cover: string;
    @Input() mini: boolean;
    @Input() width: number;
    @Input() height: number;
    @Input() margin: boolean;
    @Input() onlyPictures: boolean = false;
    @Input() leftTrash: boolean = false;
    @Input() disable: boolean = false;
    @Output() updateMedia = new EventEmitter();
    @Output() mediaModalOpen: EventEmitter<boolean> = new EventEmitter();
    public error: boolean = false;

    constructor(
        private modalService: NgbModal,
    ) { }


    delete(): void {
        if (this.media) {
            this.media = null;
            this.updateMedia.emit(null);
        }
    }

    modalOpen(content: any): void {
        const options: NgbModalOptions = { windowClass: 'force-fullscreen', backdropClass: 'backdrop-custom' };
        if (!this.media) {
            this.modalService.open(content, options);
            this.mediaModalOpen.emit();
        }
    }

    modalClose(): void {
        this.modalService.dismissAll();
        this.mediaModalOpen.emit();
    }

    onUpdatedMedia(media: any): void {
        if (this.media !== media) {
            this.media = media;
            this.modalService.dismissAll();
            this.updateMedia.emit(this.media);
        }
    }

    setError(error: any): void {
        this.error = !error;
    }
}
