<div id="sidebar">
    <div class="sidebar__tabs row">
        <div class="column small-6">
            <button class="btn" (click)="switchPanel('course')" [ngClass]="{'sidebar__tabs_active': activePanel == 'course'}">{{ (course.schema === 'template' ? 'studio.template.single' : 'studio.instance.single') | translate | titlecase }}
            </button>
        </div>

        <div class="column small-6">
            <div class="sidebar__block-title">
                <button class="btn" (click)="switchPanel('collaborators')" [ngClass]="{'sidebar__tabs_active': activePanel == 'collaborators'}"> {{ 'studio.collaboration' | translate }}

                    <a (click)="openAsideHelp('collaborator')">
                        <img src="https://static.myskillcamp.com/images/guidance/question-mark-circle.svg" />
                    </a>
                </button>
            </div>
        </div>
    </div>

    <div class="sidebar__collaboration" *ngIf="course && activePanel == 'collaborators'">
        <div class="collaboration-block" *ngIf="course.schema === 'template'">
            <div class="company__content" *ngIf="sharedCompanies.length > 0">
                <div class="switch switch_height_small" [ngClass]="{'switch_active': companyContent}" (click)="openModalCompanyContent(modalCompanyContent)">
                    <input type="checkbox">
                </div>

                <span>{{ 'words.company-content' | translate }}</span>
            </div>

            <input type="text" class="search" autocomplete="off" [placeholder]="'studio.collaboration.placeholder' | translate"
                [(ngModel)]="query" [debounce]="500" (onDebounce)="onSearchCollaborator()">

            <div class="collaboration">
                <h3 class="collaboration__title">{{ 'studio.sidebar.collaborators.added-collaborators' | translate }}</h3>

                <ng-container [ngSwitch]="collaboratorsStatus$ | async">
                    <div class="colloration__list">
                        <ng-container *ngFor="let collaborator of collaborators$ | async">
                            <div class="collaborator">
                                <img [src]="collaborator.picture + '?size=48'" height="32" width="32" alt=""> {{ collaborator.name | truncate: '20' }}
        
                                <ng-container *ngIf="!collaborator.notUpdated; else loadingTemplate">
                                    <button class="btn btn_color_red remove" (click)="deleteCollaborator(collaborator, false, modalCollaborators)">
                                        -
                                    </button>
                                </ng-container>
                                <ng-template #loadingTemplate>
                                    <!-- loader to be added -->
                                </ng-template>
                            </div>
                        </ng-container>
                    </div>

                    <ng-container *ngIf="collaboratorsHasLoadMore">
                        <button class="show-more" (click)="onLoadMoreCollaborators()">
                            <span>{{ 'words.load-more' | translate }}</span>
                        </button>
                    </ng-container>

                    <ng-container *ngSwitchCase="'loading'">
                        <msc-loader [loading]="true"></msc-loader>
                    </ng-container>
                </ng-container>
            </div>

            <div class="collaboration">
                <h3 class="collaboration__title"> {{ 'studio.sidebar.collaborator.available-collaborators' | translate }} </h3>

                <ng-container [ngSwitch]="collaboratorsAvailableStatus$ | async">
                    <div class="colloration__list">
                        <ng-container *ngFor="let collaborator of collaboratorsAvailable$ | async">
                            <div class="collaborator">
                                <img [src]="collaborator.picture + '?size=48'" height="32" width="32" alt=""> {{ collaborator.name | truncate: '20' }}
        
                                <button class="btn" (click)="addCollaborator(collaborator)">
                                    +
                                </button>
                            </div>
                        </ng-container>
                    </div>

                    <ng-container *ngIf="collaboratorsAvailableHasLoadMore">
                        <button class="show-more" (click)="onLoadMoreCollaboratorsAvailable()">            
                            <span>{{ 'words.load-more' | translate }}</span>
                        </button>
                    </ng-container>

                    <ng-container *ngSwitchCase="'loading'">
                        <msc-loader [loading]="true"></msc-loader>
                    </ng-container>
                </ng-container>
            </div>
        </div>

        <div class="collaboration_block" *ngIf="course.schema === 'instance'">
            <div class="collaboration" style="background-color: white; padding: 1rem; border-radius: 4px">
                {{ 'studio.sidebar.collaborator.instance' | translate }}
            </div>
        </div>
    </div>

    <div class="sidebar__course-content" *ngIf="course && activePanel == 'course'">
        <!--<button class="btn" [routerLink]="['correction']">Mes corrections</button>-->

        <!--Course Presentation-->
        <button class="course-title" [routerLink]="['../../course', course.id]" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{exact: true}">
            {{ (course.schema === 'template' ? 'studio.presentation.template' : 'studio.presentation.instance') | translate }}
        </button>

        <ul class="sections" #dropListContainer cdkDropList (cdkDropListDropped)="dropped($event)"> 
            <li (click)='defineIntermediate(section.id)' class="section" [class.section_open]="!sectionOpen[index]" *ngFor="let section of course.sections; let index=index"  (mouseenter)="setUpDragAndDrop($event, 'section')"
             cdkDrag (mouseenter)="setUpDragAndDrop($event, 'section')" [cdkDragDisabled]="!(isSectionDraggable && !sectionUpdating)">

                <button class="section__title" fragment="s-{{section.id}}" [routerLink]="['section', section.id]" [ngClass]="{'active': isSectionActive('s-' + section.id)}">
                    {{ section.title || ('studio.sidebar.section-title' | translate) }}
                    <span class="section__toggle" (click)="sectionOpen[index] = !sectionOpen[index]" *ngIf="!sectionUpdating"></span>

                    <div class="loader" *ngIf="sectionUpdating">
                        <div class="lds-ring" style="margin: 6% 0 0 88%">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                </button>

                <ul>
                    <li class="section__content" *ngFor="let sectionContent of section?.sectionContents; let index = index" (click)='defineIntermediate(section.id)' [class.section__media]="sectionContent.context === 'video' || sectionContent.context === 'media'" [class.section__scorm]="sectionContent.context === 'scorm' || sectionContent.context === 'scormcloud' || sectionContent.context === 'external_link'"
                    [class.section__project]="sectionContent.context === 'project' || sectionContent.context === 'selfassessment'" [ngClass]="'section__' + sectionContent.context">

                        <button class="section-content" [routerLink]="['section', section.id]" fragment="sc-{{sectionContent.id}}" [ngClass]="{'active': isSectionActive('sc-' + sectionContent.id)}">
                            <span class="context">{{ 'studio.sidebar.section-content.context-'+sectionContent?.context | translate}}</span>
                            <span class="title">
                                {{ sectionContent.title || (('studio.sidebar.section-content-title' | translate) + ' ' + ('studio.sidebar.section-content.' + sectionContent.context + '-title-for' | translate)) }}
                            </span>
                        </button>

                        <div class="arrows" [ngClass]="{'question' : sectionContent.context === 'exercise'}" *ngIf="!processing">
                            <i class="fa fa-chevron-up up" [ngClass]="{'disable': !checkPosition(section, sectionContent, 'down')}" (click)="changePosition(section, sectionContent, 'down'); stopPropagation($event)"></i>
                            <i class="fa fa-chevron-down down" [ngClass]="{'disable': !checkPosition(section, sectionContent, 'up')}" (click)="changePosition(section, sectionContent, 'up'); stopPropagation($event)"></i>
                        </div>

                        <div class="loader" *ngIf="processing">
                            <div class="lds-ring" style="margin: 6% 0 0 88%">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        </div>

                        <ul *ngIf="sectionContent.context == 'exercise'">
                            <!--<li class="module__item" *ngFor="let question of sectionContent.exercise?.questions">-->
                            <!--<button [routerLink]="['section', section.id]" fragment="q-{{question.id}}" class="icon-no-check">{{ question?.title || ('studio.journey-sidebar.section-content.exercise.question-title' | translate) }}</button>-->
                            <!--</li>-->
                            <li class="question" *ngFor="let question of sectionContent.exercise?.questions; let index = index">
                                <button [routerLink]="['section', section.id]" fragment="q-{{question.id}}" [ngClass]="{'active': isSectionActive('q-' + question.id)}">
                                    <div class="block-empty"></div>
                                    <div class="title">{{ question?.title || ('studio.sidebar.section-content.exercise.question-'+question?.type | translate) }}</div>
                                </button>
                            </li>
                        </ul>
                    </li>
                </ul>
            </li>

            <li class="add-section" *ngIf="!company?.permissions?.companyLockSession || (company?.permissions?.companyLockSession && course.state != 'ongoing')">
                <button (click)="addSection()">{{ 'studio.sidebar.add-section' | translate}}</button>
            </li>
        </ul>
        <!--<button class="publish-link btn" [promiseBtn]="promiseSetBySomeAction" (click)="modalPublish.open()">
            {{ 'studio.journey-sidebar.publish' | translate }}
        </button>-->

        <button class="publish-link button button--color" *ngIf="course.schema === 'template'" (click)="publishInstance(course)">
            {{ 'studio.sidebar.publish' | translate }}
        </button>
    </div>
</div>

<ng-template #modalCompanyContent let-modal>
    <div class="modal-header">
        <span></span>
        <button type="button" class="close" aria-label="Close" (click)="modal.close()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div style="background-color: #FFFFFF; padding: 5rem; text-align: center;">
            <p>{{ 'studio.company-content.description' | translate }} <strong>{{ selectedSharedCompany.name }}</strong></p>
            <p>{{ 'studio.company-content.description-2' | translate }}</p>

            <div class="" *ngIf="sharedCompanies && sharedCompanies.length > 1">
                <label class="input select__label">
                    {{ 'words.company' | translate }}

                    <div class="select">
                        <select class="select__field" [(ngModel)]="selectedCompany" (ngModelChange)="selectCompany()">
                            <option *ngFor="let company of sharedCompanies" [value]="company.id">
                                {{ company.name }}
                            </option>
                        </select>
                    </div>
                </label>
            </div>

            <div class="actions" style="padding-top: 2rem;">
                <button class="btn btn_color_red" (click)="validateCompanyContent()">
                    {{ 'studio.correction.button-submit' | translate }}
                </button>

                <button class="btn btn_color_grey" (click)="modal.close()">
                    {{ 'studio.common.button.cancel' | translate }}
                </button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #modalCollaborators let-modal>
    <div class="modal-header">
        <span></span>
        <button type="button" class="close" aria-label="Close" (click)="modal.close()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div style="background-color: #FFFFFF; padding: 5rem; text-align: center;">
            <p>{{ 'studio.modal-collaborators.delete-himself-as-collaborator' | translate }}</p>


            <div class="actions" style="padding-top: 2rem;">
                <button class="btn btn_color_red" (click)="deleteCollaborator(pendingCollaboratorToDelete, true, modalCollaborators)">
                    {{ 'studio.correction.button-submit' | translate }}
                </button>

                <button class="btn btn_color_grey" (click)="modal.close()">
                    {{ 'studio.common.button.cancel' | translate }}
                </button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #modalUnableToEdit let-modal>
    <div class="modal-header">
        <span></span>
        <button type="button" class="close" aria-label="Close" (click)="modal.close();goToPreview()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div style="background-color: #FFFFFF; padding: 5rem; text-align: center;">
            <h3>{{ 'words.session.locked' | translate }}</h3>
            <p>{{ 'words.session.locked.reason' | translate }}</p>


            <div class="actions" style="padding-top: 2rem;">

                <button class="btn btn_color_deep-blue" (click)="modal.close();goToPreview();">
                    Ok
                </button>
            </div>
        </div>
    </div>
</ng-template>
