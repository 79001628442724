import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'library-item',
    templateUrl: './library-item.component.html'
})
export class LibraryItemComponent implements OnInit {
    @Output() updateMedia = new EventEmitter();
    @Output() showItemPreview = new EventEmitter();
    @Input() item;
    @Input() toUpload: boolean;
    public stockageUrl: string = environment.envVar.STORAGE_URL;
    public style;

    constructor(private sanitizer: DomSanitizer) {
        this.style = {
            'background-image': 'url(https://picsum.photos/200/300)'
        }
    }

    ngOnInit() {
        this.getPicture();
    }

    onUpdatedMedia(event) {
        event.stopPropagation();
        this.updateMedia.emit(this.item);
    }

    showPreview() {
        this.showItemPreview.emit(this.item);
    }

    getType(type: string): string {
        switch (type) {
            case 'IMG': {
                return 'studio.media.images';
            }
            case 'VID': {
                return 'studio.re-use.video-context';
            }
            case 'DOC': {
                return 'studio.media.docs';
            }
            case 'EMBED': {
                return 'studio.media.embed';
            }
            case 'SND': {
                return 'studio.media.sounds';
            }
        }
    }

    getPicture(): void {
        let url;
        if (this.item.storage === 'ext') {
            url = 'url(' + this.item.url + '?size=512) center no-repeat'
        } else {
            if (this.item.type === 'IMG') {
                url = 'url(' + this.stockageUrl + this.item.url + '?size=512) center no-repeat'
            } else {
                if (this.item.video) {
                    url = 'url(' + this.item.video.thumbnailUrl + '?mh=48) center no-repeat'
                }
            }
        }

        this.style = {
            'background': url,
            'background-size': 'cover'
        }
    }
}
