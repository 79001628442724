<div class="modal-header">
    <span></span>
    <button type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <div>
        <div class="upload" *ngIf="!files[0]">
            <label class="upload__placeholder" style="cursor: pointer;">
                <div class="title">
                    {{ 'studio.media.upload.title' | translate }}
                </div>
                <p [innerHTML]="'studio.media.scorm.explainer-main' | translate"></p>
                <p [innerHTML]="'studio.media.scorm.explainer-secondary' | translate"></p>
                <a href="https://scorm.com/scorm-explained/business-of-scorm/scorm-versions/" target="_blank">{{'studio.media.scorm.explainer-link' | translate}}</a>
                <div class="content">
                    <input style="text-align: center; width: auto; margin-top: 1rem;" type="file" ngFileSelect (uploadOutput)="onUploadOutput($event, modalConfirmScorm)" [uploadInput]="uploadInput">
                </div>
            </label>
        </div>

        <div class="upload upload_processing" *ngIf="files[0]">
            <h2 class="heading">
                {{ files[0].name }}
            </h2>

            <div class="progress">
                <div class="progress__text">
                    {{ ((files[0]?.progress.data.percentage * 0.8 | number:'1.2-2') || 0)+'%' }}
                </div>
                <div class="progress-bar">
                    <div class="progress-bar__progress" [ngStyle]="{'width': (files[0]?.progress.data.percentage * 0.8 || 0)+'%'}"></div>
                </div>
            </div>

            <p *ngIf="files[0].progress.status !== 2">{{ 'studio.media.upload.waiting' | translate }}</p>

            {{ files[0]?.response?.message | translate }}
        </div>
    </div>
</div>

<ng-template #modalConfirmScorm let-modal>
    <div class="modal-header">
        <span></span>
        <button type="button" class="close" aria-label="Close" (click)="modal.close()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div style="background-color: #FFFFFF; padding: 1.2rem 0 4rem 0;">
            <div class="m-title" style="margin-bottom: 1.2rem;" [innerHTML]="'studio.media.scorm.warning.title' | translate: {version: ('words.scorm.version.' + scorm?.version | translate)}"></div>
            <div class="m-content-modal">
                <p [innerHTML]="'studio.media.scorm.warning' | translate: {version: ('words.scorm.version.' + scorm?.version | translate)}"></p>
                <a href="https://scorm.com/scorm-explained/business-of-scorm/scorm-versions/" target="_blank">[{{'studio.media.scorm.explainer-link' | translate}}]</a>
            </div>

            <div class="actions" style="padding: 2rem 0 0 0; text-align: center;">
                <button class="btn btn--secondary" (click)="modal.close(); close()">
                    {{ 'studio.common.button.cancel' | translate }}
                </button>
                <button class="btn btn--primary" (click)="modal.close(); confirmAddedScorm(scorm)">
                    {{ 'words.continue' | translate }}
                </button>
            </div>
        </div>
    </div>
</ng-template>
