export const environment = {
    name: {
        short: 'prod',
        long: 'prod'
    },
    analytics: {
        google: 'UA-112169318-2'
    },
    production: true,
    tokenName: 'token_msc',
    tokenSessionName: 'token_session',
    refreshTokenName: 'refresh_token',
    unsplashAccess: '06c6f6cddb8970690101c6c2dbc61b4459c029d26195cda8f28d9e6ac9fad7fd',
    unsplashSecret: '95ac1a4c81cfc50a8486f033cf7a05835ab03b8c21957eafc22de93deacd5611',
    domain: 'myskillcamp.com',
    envVar: {
        API_URL: "https://api.myskillcamp.com/api",
        AUTH_URL: "https://auth.myskillcamp.com",
        STORAGE_URL: "https://api.myskillcamp.com/api/stockage/",
        APP_URL: "https://app.myskillcamp.com/",
        STUDIO_URL: "https://studio.myskillcamp.com/",
        PLAYER_URL: "https://player.myskillcamp.com/",
        CLOUD_URL: "https://cloud.myskillcamp.com"
    }
};
