<div class="modal-header">
    <span></span>
    <button type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <div class="modal-content" style="border: none;">

        <h3>{{ 'words.session.edit.warning' | translate }}</h3>
        <p>
            <i class="templab-icon-bulb"></i>
            <b>{{ '-hint' | translate }}:</b> {{ 'words.session.edit.unpublish' | translate }}
        </p>
        <label class="input">
            <input type="checkbox" class="input-checkbox" [(ngModel)]="hide"> {{ 'words.dont-show' | translate }}
        </label>
    </div>

    <div class="modal-actions" style="display: flex; justify-content: flex-end; margin-top: 10px;">
        <button class="button button--border" (click)="close()" style="margin-right: 10px;">
            {{ 'words.cancel' | translate }}
        </button>

        <button class="button button--border button--primary" (click)="confirm()">
            {{ 'words.confirm' | translate }}
        </button>
    </div>
</div>
