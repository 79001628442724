<label class="liveEdit" [ngClass]="{'liveEdit_empty': isEmpty(), 'liveEdit_focused': focused }" [attr.data-content]="getData()">
    <textarea [attr.class]="getClass()" [disabled]="disabled" [rows]="rows" [(ngModel)]="inputData" (focus)="onFocus()" (blur)="onBlur()" (keydown.enter)="enterKeydown"></textarea>
    <span class="liveEdit__trigger" *ngIf="mode === 'normal'">
        <span class="sr-only">
            {{'words.edit' | translate}}
        </span>
    </span>

    <span *ngIf="mode !== 'normal'" style="padding: 0 0 0 2rem;">
        <img src="https://static.myskillcamp.com/images/icon/icon-studio/icon_edit_blue.svg">
    </span>
</label>

<style>
    .align-center {
        text-align: center !important;
    }
</style>
