import { Component, Input, Output, ViewChild, ElementRef, EventEmitter } from '@angular/core';
import { Media } from '@stuplay';
import { NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'media-button-upload',
    templateUrl: './media-button-upload.component.html',
})
export class MediaButtonUploadComponent {
    @ViewChild('downloadableMedia', { static: false }) aDownload: ElementRef;
    @Input() media: Media;
    @Output() updateMedia = new EventEmitter();
    @Output() deleteMedia = new EventEmitter();
    @Output() mediaModalOpen: EventEmitter<boolean> = new EventEmitter();

    constructor(
        private modalService: NgbModal,
    ) { }

    modalOpen(content: any, options: NgbModalOptions = { size: 'xl', centered: true, backdropClass: 'backdrop-custom' }): void {
        this.modalService.open(content, options);
        this.mediaModalOpen.emit();
    }

    modalClose(): void {
        this.modalService.dismissAll();
        this.mediaModalOpen.emit();
    }

    onUpdateMedia(media: any): void {
        if (this.media !== media && media.id) {
            this.media = media;
            this.updateMedia.emit(this.media);
        }
        this.modalService.dismissAll();
    }

    deletedMedia(media: any): void {
        this.media = media;
        this.deleteMedia.emit(this.media);
    }

    displayMedia(): void {
        const event = new MouseEvent('click', { bubbles: true });
        // this.renderer.invokeElementMethod(this.aDownload.nativeElement, 'dispatchEvent', [event]);
        (this.aDownload.nativeElement as any)['dispatchEvent'].apply(this.aDownload.nativeElement, [event]);
    }

    dynamicUrl(media: any): string {
        let url: any;
        if (media.type === 'IMG') {
            url = environment.envVar.STORAGE_URL + media.url + '?size=raw';
        } else {
            url = environment.envVar.STORAGE_URL + media.url;
        }

        return url;
    }
}
