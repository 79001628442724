<div class="templab-library__body">
    <a class="button--link grid middle" (click)="backAction()">
        <i class="icon icon icon--arrow-left"></i>
        {{ 'studio.publish.button.back' | translate }}
    </a>
</div>

<div class="templab-library__body templab-library__body--without-padding">
    <div class="templab-library__activity__title" *ngIf="!questionActive">
        <h3>{{ 'studio.course.section-content.exercise-hint' | translate }}</h3>

        <live-editor (onUpdate)="updateSectionContent()" [(inputData)]="sectionContent.title" [placeholder]="('studio.course.section-content.' + sectionContent.context + '-hint' | translate)"></live-editor>

        <li class="templab-library__activity__session" *ngFor="let question of exercise.questions">
            <div class="box__item box__item--list grid between middle" (click)="updateQuestion(question)">
                <div class="box__grid grid middle">
                    <a>
                        <div class="box__session-name">
                            <h1 class="box__title" [innerHTML]="question.title | safe:'html'"></h1>
                        </div>
                    </a>
                </div>

                <div class="box__list">
                    <p>{{ 'studio.modal-question.' + question.type + '-title' | translate }}</p>
                </div>
            </div>
        </li>
    </div>

    <ul class="templab-library__activity" *ngIf="!questionActive">
        <li>
            <div class="templab-card-activity" (click)="selectQuestion('fill-in')">
                <div class="templab-card-activity__content">
                    <div class="templab-card-activity__icon">
                        <img src="https://static.myskillcamp.com/images/studio/icons/player/illu_question_texteatrou.svg">
                    </div>

                    <h4 class="templab-card-activity__title">
                        {{ 'studio.modal-question.fill-in-title' | translate }}
                    </h4>

                    <p class="templab-card-activity__paragraph">
                        {{ 'studio.modal-question.fill-in-description' | translate }}
                    </p>
                </div>
            </div>
        </li>

        <li>
            <div class="templab-card-activity" (click)="selectQuestion('true-false')">
                <div class="templab-card-activity__content">
                    <div class="templab-card-activity__icon">
                        <img src="https://static.myskillcamp.com/images/studio/icons/player/illu_question_vraioufaux.svg">
                    </div>

                    <h4 class="templab-card-activity__title">
                        {{ 'studio.modal-question.true-false-title' | translate }}
                    </h4>

                    <p class="templab-card-activity__paragraph">
                        {{ 'studio.modal-question.true-false-description' | translate }}
                    </p>
                </div>
            </div>
        </li>

        <li>
            <div class="templab-card-activity" (click)="selectQuestion('multiple')">
                <div class="templab-card-activity__content">
                    <div class="templab-card-activity__icon">
                        <img src="https://static.myskillcamp.com/images/studio/icons/player/illu_question_choixmultiple.svg">
                    </div>

                    <h4 class="templab-card-activity__title">
                        {{ 'studio.modal-question.multiple-title' | translate }}
                    </h4>

                    <p class="templab-card-activity__paragraph">
                        {{ 'studio.modal-question.multiple-description' | translate }}
                    </p>
                </div>
            </div>
        </li>

        <li>
            <div class="templab-card-activity" (click)="selectQuestion('ordered')">
                <div class="templab-card-activity__content">
                    <div class="templab-card-activity__icon">
                        <img src="https://static.myskillcamp.com/images/studio/icons/player/illu_question_phraseordonnee.svg">
                    </div>

                    <h4 class="templab-card-activity__title">
                        {{ 'studio.modal-question.ordered-title' | translate }}
                    </h4>

                    <p class="templab-card-activity__paragraph">
                        {{ 'studio.modal-question.ordered-description' | translate }}
                    </p>
                </div>
            </div>
        </li>

        <li>
            <div class="templab-card-activity" (click)="selectQuestion('linker')">
                <div class="templab-card-activity__content">
                    <div class="templab-card-activity__icon">
                        <img src="https://static.myskillcamp.com/images/studio/icons/player/illu_question_association.svg">
                    </div>

                    <h4 class="templab-card-activity__title">
                        {{ 'studio.modal-question.linker-title' | translate }}
                    </h4>

                    <p class="templab-card-activity__paragraph">
                        {{ 'studio.modal-question.linker-description' | translate }}
                    </p>
                </div>
            </div>
        </li>

        <li>
            <div class="templab-card-activity" (click)="selectQuestion('category')">
                <div class="templab-card-activity__content">
                    <div class="templab-card-activity__icon">
                        <img src="https://static.myskillcamp.com/images/studio/icons/player/illu_question_flashcards.svg">
                    </div>

                    <h4 class="templab-card-activity__title">
                        {{ 'studio.modal-question.category-title' | translate }}
                    </h4>

                    <p class="templab-card-activity__paragraph">
                        {{ 'studio.modal-question.category-description' | translate }}
                    </p>
                </div>
            </div>
        </li>

        <li>
            <div class="templab-card-activity" (click)="selectQuestion('procedure')">
                <div class="templab-card-activity__content">
                    <div class="templab-card-activity__icon">
                        <img src="https://static.myskillcamp.com/images/studio/icons/player/illu-question-process.svg">
                    </div>

                    <h4 class="templab-card-activity__title">
                        {{ 'studio.modal-question.procedure-title' | translate }}
                    </h4>

                    <p class="templab-card-activity__paragraph">
                        {{ 'studio.modal-question.procedure-description' | translate }}
                    </p>
                </div>
            </div>
        </li>
    </ul>

    <div class="templab-library__body__questions" *ngIf="questionActive">
        <app-question [indexQuestion]="indexQuestion"
                      [exercise]="exercise"
                      [question]="question"
                      [sectionContent]="sectionContent"
                      [displayDeleteAction]="false"
                      [settingsClass]="'journey-settings'"
                      [questionClass]="'question_'+question.type"
                      (delete)="selectQuestion('')">
        </app-question>
    </div>
</div>

<footer class="templab-library__footer" *ngIf="questionActive">
    <div class="sidebar__btn">
        <div class="sidebar__btn-right grid">
            <a class="templab-btn templab-button--border" (click)="cancelQuestion()">
                {{ 'studio.common.button.cancel' | translate }}
            </a>

            <a class="templab-btn templab-btn--secondary" (click)="saveQuestion()">
                {{ 'words.save' | translate }}
            </a>
        </div>
    </div>
</footer>

<footer class="templab-library__footer" *ngIf="exercise.questions.length > 0 && !questionActive">
    <div class="sidebar__btn">
        <div class="sidebar__btn-right grid">
            <a class="templab-btn templab-btn--secondary" (click)="addActivity()">
                {{ 'studio.journey.add-activity' | translate }}
            </a>
        </div>
    </div>
</footer>
