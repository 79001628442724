import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { CourseProvider } from '@stuplay';
import { StorageService } from '../../../../utils/services/storage.service';
import { NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { RedirectService } from '../../../../utils/services/redirect.service';

@Component({
    selector: 'instance-sidebar-settings',
    templateUrl: './instance-sidebar-settings.component.html'
})
export class InstanceSidebarSettingsComponent implements OnInit {
    @Input() instances: any[];
    @Input() camps: any[];
    @Output() close: EventEmitter<any> = new EventEmitter;
    @Output() published: EventEmitter<any> = new EventEmitter;
    @Output() finished: EventEmitter<any> = new EventEmitter;
    @Output() backEmitter: EventEmitter<any> = new EventEmitter;
    public company: any;
    public settings: any = {
        is_catalogue: true,
        need_validation: false,
        is_locked: false,
        is_ordered: false,
        survey: false,
        notify_manager: false
    };
    public parameters: any = {
        course_camp_ids: null,
        notify: false
    };
    public active: boolean = true;
    public savedContentsSettings: any;
    public currentTab: string = 'invitation';
    public loading: boolean = false;
    public displays: any;
    public defaultParams: any = {
        notify_manager: false,
        savedContentsSettings: false
    };
    public step: number = 1;
    public hidePublishWarn: boolean;

    constructor(
        private courseProvider: CourseProvider,
        private storageService: StorageService,
        private redirectService: RedirectService,
        private modalService: NgbModal,
    ) { }

    ngOnInit() {
        this.company = this.storageService.get('company');
        this.hidePublishWarn = !this.company.permissions.companyLockSession || (sessionStorage.getItem('hidePublishWarn') ? true : false);
        if (this.instances.length === 1 && this.instances[0].eventsCount > 0) {
            this.settings.notify_manager = true;
            this.defaultParams.notify_manager = true;
        }
        this.defaultParams.savedContentsSettings = this.savedContentsSettings = this.storageService.getFlash('savedContentsSettings');

        this.displays = {
            access: false,
            advanced: false
        };
    }

    init(is_catalogue: boolean) {
        this.settings = {
            is_catalogue,
            need_validation: false,
            is_locked: false,
            is_ordered: false,
            survey: false,
            notify_manager: this.defaultParams.notify_manager
        };

        this.savedContentsSettings = this.defaultParams.savedContentsSettings;
    }

    displayed(key: string): void {
        this.displays[key] = !this.displays[key];
    }

    closeSidebarEmit(): void {
        this.active = false;
        const timeOut = setTimeout(() => {
            this.close.emit(true);
            clearTimeout(timeOut);
        }, 300);
    }

    openModal(content: any, options: NgbModalOptions = {size: 'xl', centered: true, backdropClass: 'backdrop-custom'} ) {
        this.modalService.open(content, options);
    }

    selectCatalogue(catalogue: boolean = true): void {
        this.init(catalogue);
    }

    publish(): void {
        this.loading = true;
        const instanceIds = [];
        this.instances.forEach((instance) => {
            instanceIds.push(instance.id);
        });
        const campIds = [];
        this.camps.forEach((camp) => {
            campIds.push(camp.id);
        });
        if (this.savedContentsSettings) {
            this.settings['notify_saved_contents'] = this.savedContentsSettings.notifySavedContents;
            this.settings['saved_contents'] = this.savedContentsSettings.savedContents;
        }

        this.courseProvider.publishInstance(instanceIds, campIds, this.settings).subscribe((data) => {
            this.storageService.set('instances', instanceIds);
            this.published.emit(true);
            if (this.camps.length === 1 && this.instances.length === 1) {
                this.currentTab = 'published';
            } else {
                this.finished.emit(true);
                this.closeSidebarEmit();
            }
            this.loading = false;
            this.parameters.course_camp_ids = data.courseCampIds;
        });
    }

    finish(): void {
        this.finished.emit(true);
    }

    navigate(type: string): void {
        const template = this.storageService.get('templateSelected');
        this.redirectService.navigate('app', ['organize', this.company.slug, 'camp', this.camps[0].id, 'contents', 'template', template.slug], { action: type, instance: this.instances[0].id }, '_blank');
    }

    invite(): void {
        this.loading = true;
        this.courseProvider.massiveSubscription(this.parameters).subscribe(() => {
            this.finished.emit(true);
            this.loading = false;
        });
    }

    back(): void {
        switch (this.currentTab) {
            case 'invitation':
                this.storageService.set('selectedCamps', this.camps);
                this.backEmitter.emit(this.instances);
                break;
            case 'settings':
                this.currentTab = 'invitation';
                break;
            case 'summary':
                this.currentTab = 'settings';
                break;
        }
    }

    next(content): void {
        switch (this.currentTab) {
            case 'invitation':
                this.currentTab = 'settings';
                this.step = 2;
                break;
            case 'settings':
                this.currentTab = 'summary';
                this.step = 3;
                break;
            case 'summary':
                this.hidePublishWarn ? this.publish() : this.openModal(content, {size: 'md', centered: true, backdropClass: 'backdrop-custom'});
                break;
        }
    }

    changeTab(tab: string, step: number): void {
        if (this.currentTab !== 'published' && this.step > step) {
            this.currentTab = tab;

            if (tab === 'camps') {
                this.storageService.set('selectedCamps', this.camps);
                this.backEmitter.emit(this.instances);
            }
        }
    }
}
