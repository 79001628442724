export class QuestionTypeMap {
    private static map = {
        1: 'fill-in',
        2: 'open-write',
        3: 'true-false',
        4: 'multiple',
        5: 'ordered',
        6: 'linker',
        7: 'mindmap',
        8: 'flip-card',
        9: 'category',
        10: 'flash-card',
        11: 'open-audio',
        12: 'procedure',
        13: 'ucq'
    };

    static getNameById(id: number): string {
        if (this.map.hasOwnProperty(id) === false) {
            return null;
        }

        return this.map[id];
    }

    static getIdByName(name: string): number {
        for (let id in this.map) {
            if (this.map[id] == name) {
                return Number(id);
            }
        }

        return null;
    }
}


