import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { FolderProvider, CourseProvider } from '@stuplay';
import { StorageService } from '../../../../utils/services/storage.service';
import { HeaderService } from '../../../../utils/services/header.service';
import { ToastService } from '../../../../utils/components/toast/toast.service';

@Component({
    selector: 'folder-display',
    templateUrl: './folder-display.component.html'
})
export class FolderDisplayComponent {
    @Input() type: string;
    @Input() contents: any[];
    @Input() sharedCourses: boolean = false;
    @Input() configurable: boolean = true;
    @Output() createInstance: EventEmitter<any> = new EventEmitter();
    @Output() newFolder: EventEmitter<any> = new EventEmitter();

    constructor(
        private router: Router,
        private folderProvider: FolderProvider,
        private courseProvider: CourseProvider,
        private storageService: StorageService,
        private headerService: HeaderService,
        private toastService: ToastService,
    ) { }

    sliceElement(event: any): void {
        this.contents.splice(event.index, 1);
        this.toastService.push(event.type + '-deleted');
    }

    addInstance(item: any): void {
        this.createInstance.emit(item);
    }

    addTemplate(): void {
        const params = {
            folder_id: this.storageService.get('folderId'),
            company_id: this.storageService.get('company').id
        };
        this.courseProvider.createCourse(params).subscribe(
            (course) => {
                this.headerService.plus('templates');
                this.router.navigate(['company', this.storageService.get('company').slug, 'course', course.hash]);
            });
    }

    addFolder(): void {
        const folderId = this.storageService.get('folderId');
        this.folderProvider.createFolder({ folder_id: folderId }).subscribe((folder) => {
            this.newFolder.emit(folder);
        });
    }

    navigateFolder(item: any): void {
        if (this.type === 'folder') {
            switch (item.type) {
                case 'company':
                    this.router.navigate(['company', this.storageService.get('company').slug, 'home', 'shared', item.slug]);
                    break;
                case 'collaboration':
                    this.router.navigate(['company', this.storageService.get('company').slug, 'home', 'collaborations']);
                    break;
                default:
                    this.router.navigate(['company', this.storageService.get('company').slug, 'home', item.slug]);
            }
        }
    }
}
