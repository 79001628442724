import { Place } from '../../../../place/place';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { cleanObject } from "../../../../helpers";
import { environment } from 'src/environments/environment';

export class Project {
    id: number;
    type: string;
    title: string;
    info: string;
    content: string;
    startAt: number;
    endAt: number;
    place: Place;
    createdAt: number;
    updatedAt: number;
    scoreMax: number;

    constructor(lessonContent: any, protected http: HttpClient) {
        this.extends(lessonContent);
    }

    extends(project: any): Project {
        Object.assign(this, project);
        return this;
    }

    update(): Observable<Project> {
        return this.http.put(`${environment.envVar.API_URL}/studio/project/${this.id}`, cleanObject(this)).pipe(map(res => this.extends(res)));
    }

    save(): Observable<Project> {
        let baseObs: Observable<any>;
        if (this.id) {
            baseObs = this.http.put(`${environment.envVar.API_URL}/studio/project/${this.id}`, cleanObject(this));
        } else {
            baseObs = this.http.post(`${environment.envVar.API_URL}/studio/project`, cleanObject(this));
        }

        return baseObs.pipe(map((object: object) => this.extends(object)));
    }
}
