import { NgModule, Injectable } from '@angular/core';
import { Routes, RouterModule, Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { map, Observable } from 'rxjs';

import { AuthGuard } from './utils/guards/auth.guard';
import { CompanyGuard } from './utils/guards/company.guard';

import { StorageService } from './utils/services/storage.service';
import { HeaderService } from './utils/services/header.service';

import { HttpHelper } from './utils/helpers';

import { Course, Section, CourseProvider, JourneyProvider, UserProvider, ApplicationProvider } from '@stuplay';

import { CompanyComponent } from './pages/company/company.component';

import { HomeComponent } from './pages/home/home.component';

import { CourseComponent } from './pages/course/course.component';
import { CoursePresentationComponent } from './pages/course/course-presentation/course-presentation.component';
import { SectionComponent } from './pages/course/section/section.component';
import { MediaLibraryComponent } from './pages/media-library/media-library.component';

/* Multi sessions */
import { InstanceComponent } from './pages/instance/instance.component';

/* Journey */
import { JourneyComponent } from './pages/journey/journey.component';
import { JourneyListComponent } from './pages/journey/journey-list/journey-list.component';
import { JourneyPreviewComponent } from './pages/journey/journey-preview/journey-preview.component';

/* New Providers */
import { CompanyProvider } from './providers';

@Injectable()
export class UserResolver implements Resolve<any> {
    constructor(private userProvider: UserProvider, private storageService: StorageService) { }

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        this.userProvider.getPermissions().subscribe((data)=>{
            this.storageService.set('permissions', data);
        })
        return this.userProvider.getCurrent();
    }
}

@Injectable()
export class CompanyResolver implements Resolve<any> {
    constructor(private companyProvider: CompanyProvider, private storageService: StorageService) { }

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        if (route.paramMap.get('companySlug')) {
            return this.companyProvider.getCompany(route.paramMap.get('companySlug'), { includes: 'roles,experience' }).pipe(map(data=>{
                this.storageService.set('company', data);
                return data;
            }))
        }
    }
}

@Injectable()
export class ApplicationResolver implements Resolve<any> {
    constructor(private applicationProvider: ApplicationProvider) { }

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        if (route.paramMap.get('companySlug')) {
            return this.applicationProvider.get(route.paramMap.get('companySlug'));
        }
    }
}

@Injectable()
export class CoursesResolver implements Resolve<Course[]> {
    constructor(private courseProvider: CourseProvider) { }

    resolve(route: ActivatedRouteSnapshot): Observable<Course[]> {
        return this.courseProvider.getCourses();
    }
}

@Injectable()
export class HomeCoursesResolver implements Resolve<any> {
    constructor(private courseProvider: CourseProvider, private storageService: StorageService) { }

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        const query = this.storageService.get('query');
        if (route.paramMap.get('slug')) {
            if (route.paramMap.get('slug') === 'collaborations') {
                const params = HttpHelper.cleanParams({
                    q: query
                });
                return this.courseProvider.getSharedCollaborations(params);
            } else {
                const params = HttpHelper.cleanParams({
                    includes: 'account,permissions',
                    q: query
                });
                return this.courseProvider.getHomeCourses(route.paramMap.get('slug'), params);
            }
        } else {
            const params = HttpHelper.cleanParams({
                includes: 'account,permissions',
                q: query
            });
            return this.courseProvider.getHomeCourses(null, params);
        }
    }
}

@Injectable()
export class HomeCompanyCoursesResolver implements Resolve<any> {
    constructor(private courseProvider: CourseProvider) { }

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        if (route.paramMap.get('companyId')) {
            return this.courseProvider.getSharedCourses(route.paramMap.get('companyId'), { 'includes': 'account,permissions' });
        }
    }
}

@Injectable()
export class CourseResolver implements Resolve<Course> {
    constructor(private courseProvider: CourseProvider) { }

    resolve(route: ActivatedRouteSnapshot): Observable<Course> {
        return this.courseProvider.getCourse(route.paramMap.get('hash'), { company_id: route.parent.data.company.id, 'includes': 'sections,sections.sectionContents' });
    }
}

@Injectable()
export class SectionResolver implements Resolve<Course> {
    constructor(private courseProvider: CourseProvider) { }

    resolve(route: ActivatedRouteSnapshot): Observable<Course> {
        return this.courseProvider.getCourse(route.parent.paramMap.get('hash'), { company_id: route.parent.parent.data.company.id, 'includes': 'sections,sections.sectionContents' });
    }
}

@Injectable()
export class InstanceResolver implements Resolve<any> {
    constructor(private courseProvider: CourseProvider) { }

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        if (route.paramMap.get('slug')) {
            return this.courseProvider.getSpecificTemplate(route.paramMap.get('slug'), { includes: 'camps', archived: route.queryParamMap.get('archived') || '0' });
        } else {
            return this.courseProvider.getTemplates(10, 3, '', 1);
        }
    }
}

@Injectable()
export class JourneyResolver implements Resolve<any> {

    constructor(private journeyProvider: JourneyProvider) { }

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        const params = {
            company_id: route.parent.parent.paramMap.get('companySlug')
        };

        if (route.paramMap.get('slug')) {
            return this.journeyProvider.getJourney(route.paramMap.get('slug'), params);
        } else {
            return this.journeyProvider.getJourneys(null, params);
        }
    }
}

export const routes: Routes = [
    {
        path: '',
        redirectTo: 'company',
        pathMatch: 'full'
    },
    {
        path: 'company',
        component: CompanyComponent,
        canActivate: [CompanyGuard]
    },
    {
        path: 'company/:companySlug',
        component: CompanyComponent,
        canActivate: [AuthGuard],
        resolve: {
            company: CompanyResolver,
            me: UserResolver,
            applications: ApplicationResolver
        },
        children: [
            {
                path: '',
                redirectTo: 'home',
                pathMatch: 'full'
            },
            {
                path: 'home',
                component: HomeComponent,
                resolve: {
                    course: HomeCoursesResolver
                }
            },
            {
                path: 'home/:slug',
                component: HomeComponent,
                resolve: {
                    course: HomeCoursesResolver
                }
            },
            {
                path: 'home/:slug/:companyId',
                component: HomeComponent,
                resolve: {
                    course: HomeCompanyCoursesResolver
                }
            },
            {
                path: 'instance',
                component: InstanceComponent,
                resolve: {
                    instances: InstanceResolver
                }
            },
            {
                path: 'instance/:slug',
                component: InstanceComponent,
                resolve: {
                    instances: InstanceResolver
                }
            },
            {
                path: 'journey',
                component: JourneyComponent,
                children: [
                    {
                        path: '',
                        component: JourneyListComponent,
                        resolve: {
                            journeys: JourneyResolver
                        }
                    },
                    {
                        path: 'preview/:slug',
                        component: JourneyPreviewComponent,
                        resolve: {
                            journey: JourneyResolver
                        }
                    }
                ]
            },
            {
                path: 'library',
                component: MediaLibraryComponent,
            },
            {
                path: 'course/:hash',
                component: CourseComponent,
                resolve: {
                    course: CourseResolver
                },
                children: [
                    {
                        path: '',
                        component: CoursePresentationComponent
                    },
                    {
                        path: 'section/:sectionId',
                        component: SectionComponent,
                        resolve: {
                            course: SectionResolver
                        }
                    }
                ]
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
    providers: [
        UserResolver,
        CompanyResolver,
        ApplicationResolver,
        CoursesResolver,
        CourseResolver,
        SectionResolver,
        HomeCoursesResolver,
        HomeCompanyCoursesResolver,
        InstanceResolver,
        JourneyResolver
    ]
})

export class AppRoutingModule { }
