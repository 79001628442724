import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Section, Course, ReUseListingProvider, SectionContent } from '@stuplay';
import { HttpClient } from '@angular/common/http';
import { EmitterService } from 'src/app/utils/services/emitter.service';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'modal-re-use-section-content',
    templateUrl: './re-use-section-content.component.html',
    styleUrls: ['./re-use-section-content.component.less']
})
export class ReUseSectionContentComponent implements OnInit {
    @Input() section: Section;
    @Input() course: Course;
    @Input() position: number = 0;
    @Input() open: boolean = false;
    @Output() closeModal = new EventEmitter<boolean>();
    public sections: any;
    public error: any;
    public modalAction: boolean = false;
    public id: number[] = [];
    public context: string[] = [];
    public filterByContext: boolean = false;
    public currentPage: number;
    public maxPages: number;
    public countSections: number;
    public itemsPerPage: number;
    public currentFilter: string = '';
    public displaySectionContent: number;
    public displaySectionContentState: boolean = false;
    public sectionContentsToDuplicate: number[] = [];
    public ready: boolean = false;

    constructor(
        private reUseListingProvider: ReUseListingProvider,
        private http: HttpClient,
        private emitterService: EmitterService
    ) { }

    ngOnInit() {
        this.resetFilter();
    }

    changeModalAction($event) {
        // Todo: Scroll if to close from the bottom
        this.modalAction = !this.modalAction
    }

    close() {
        this.closeModal.emit();
    }

    changeFilterByContextAction() {
        this.filterByContext = !this.filterByContext;
    }

    changeCurrentPage(page: number) {
        this.currentPage = page;
    }

    catchPage(event: any) {
        this.ready = false;
        let url = `${environment.envVar.API_URL}/create/sections?includes=section-contents`;
        url += (this.currentFilter) ? '&section-contents.context=' + this.currentFilter + '&page=' + event : '&page=' + event;

        return this.http.get(url).pipe(map((section) => {
            return new Section(section, this.http);
        }))
            .subscribe((sections) => {
                this.sections = sections;
                this.maxPages = this.sections.pagination.lastPage;
                this.countSections = this.sections.pagination.total;
                this.itemsPerPage = this.sections.pagination.perPage;
                this.ready = true;
            });
    }

    useFilterByContext(context: string) {
        this.currentFilter = context;
        this.ready = false;

        return this.http.get(`${environment.envVar.API_URL}/studio?includes=section-contents&section-contents.context=${context}`).pipe(map((section) => {
            return new Section(section, this.http);
        }))
            .subscribe((sections) => {
                this.sections = [];
                this.sections = sections;
                this.maxPages = this.sections.pagination.lastPage;
                this.countSections = this.sections.pagination.total;
                this.itemsPerPage = this.sections.pagination.perPage;
                this.ready = true;
            });
    }

    displaySectionContentAction(id: number): void {
        this.displaySectionContentState = !this.displaySectionContentState;
        this.displaySectionContent = id;
    }

    resetFilter() {
        this.ready = false;
        this.currentFilter = null;
        this.currentPage = 1;

        return this.http.get(`${environment.envVar.API_URL}/create/sections?includes=section-contents`).pipe(map((section) => {
            return new Section(section, this.http);
        }))
            .subscribe((sections) => {
                this.sections = sections;
                this.maxPages = this.sections.pagination.lastPage;
                this.countSections = this.sections.pagination.total;
                this.itemsPerPage = this.sections.pagination.perPage;
                this.ready = true;
            });
    }

    addSectionContentToDuplicate(event, sectionContent: SectionContent) {
        const isChecked = event.target.checked;
        if (isChecked === true) {
            this.sectionContentsToDuplicate.push(sectionContent.id);

        } else if (!isChecked) {
            const sectionContentsToDuplicateIndex = this.sectionContentsToDuplicate.findIndex(sectionContentIndex => sectionContentIndex === sectionContent.id);
            this.sectionContentsToDuplicate.splice(sectionContentsToDuplicateIndex, 1);
        }
    }

    isInArray(id: number): boolean {
        return this.sectionContentsToDuplicate.some((sectionContentId) => sectionContentId === id)
    }

    duplicateAction() {
        const params = {
            position: this.position,
            section_id: this.section.id,
            section_content_ids: this.sectionContentsToDuplicate
        };

        return this.http.post(`${environment.envVar.API_URL}/create/section-contents/duplicate`, params)
            .pipe(map((sectionContent: any) => {
                sectionContent.sectionContents.map((sectionContentToMap) => {
                    this.section.sectionContents.push(new SectionContent(sectionContentToMap, this.http));
                });
            })).subscribe(() => {
                this.section.update().subscribe();
                this.closeModal.emit();
                this.emitterService.refreshCourse.emit(true);
            });
    }
}
