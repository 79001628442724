import { HttpClient } from '@angular/common/http';

export class Lti {
    id: number;
    type: string;
    name: string;
    url: string;
    mediaId: number;
    mode: string;
    redirectUrl: string;
    key: string;
    secret: string;
    names: any;
    anonymize: boolean;


    constructor(lti: any, http: HttpClient) {
        this.extends(lti);
    }

    extends(lti: any): Lti {
        Object.assign(this, lti);
        return this;
    }
}