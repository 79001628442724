import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Media } from '@stuplay';
import { NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'timeline-add-content',
    templateUrl: './timeline-add-content.component.html'
})
export class TimelineAddContentComponent {
    @Output() addContent = new EventEmitter();
    @Output() updateMedia = new EventEmitter();
    @Input() placeholder: string;
    message: string = '';
    media: Media;

    constructor(
        private modalService: NgbModal,
    ) { }

    onAddContent(message: string): void {
        this.addContent.emit({
            message: message,
            media: this.media,
            link: null
        });
        this.media = null;
        this.message = '';
    }

    openModal(content: any, options: NgbModalOptions = { windowClass: 'force-fullscreen', backdropClass: 'backdrop-custom' }) {
        this.modalService.open(content, options);
    }

    onUpdatedMedia(media: Media): void {
        this.media = media;
    }

    deleteMediaPreview(): void {
        this.media = null;
    }
}
