import { Injectable, EventEmitter } from '@angular/core';
import { Folder } from '@stuplay';

@Injectable()
export class SubfolderService {
    private folder: EventEmitter<any> = new EventEmitter;
    private folderSelected: Folder;

    listen(): EventEmitter<any> {
        return this.folder;
    }

    select(folder: Folder): void {
        this.folderSelected = (this.folderSelected !== folder) ? folder : null;
        this.folder.emit(this.folderSelected);
    }

    selected(): Folder {
        return this.folderSelected;
    }
}
