import { Pipe, PipeTransform } from '@angular/core';

/**
 * Add an unit to a value
 */
@Pipe({
    name: 'fileSize'
})
export class FileSizePipe implements PipeTransform {
    private units: string[] = ['B', 'KB', 'MB', 'GB', 'TB'];

    transform(value: number): any {
        return this.process(value);
    }

    /**
     * Process logic
     * @param {number} size - Size
     * @return {string} Size + unit
     */
    private process(size: number): string {
        let i: number = 0;
        while (size > 1024 && i < 4) {
            size /= 1024;
            i++;
        }

        return Math.round(size) + ' ' + this.units[i];
    }
}
