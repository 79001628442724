import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Journey, CampProvider } from '@stuplay';
import { StorageService } from '../../../../utils/services/storage.service';
import { NewJourneyProvider } from '../../../../providers';
import { HttpHelper } from '../../../../utils/helpers';

@Component({
    selector: 'journey-sidebar-publish',
    templateUrl: './journey-sidebar-publish.component.html'
})
export class JourneySidebarPublishComponent implements OnInit {
    @Input() journey: Journey;
    @Output() close: EventEmitter<any> = new EventEmitter;
    @Output() publish: EventEmitter<any> = new EventEmitter;
    public currentCompany: any;
    public campsSelected: any[] = [];
    public activePanel: string = 'select-camp';
    public companies: any[] = [];
    public settings: any;
    public active: boolean = false;
    public currentTab: string = 'invitation';
    public query: string = '';
    public loading: boolean = false;
    public displays: boolean[] = [];
    public step: number = 1;
    public alert: boolean = false;

    constructor(
        private campProvider: CampProvider,
        private journeyProvider: NewJourneyProvider,
        private storageService: StorageService,
    ) { }

    ngOnInit() {
        this.currentCompany = this.storageService.get('company');

        this.activeEffect();
        this.search();

        if (this.journey.isUnpublished) {
            this.getRecovered();
        }

        this.settings = {
            camps: [],
            is_catalogue: true,
            notify_manager: false,
            company_id: this.currentCompany.id
        };
    }

    getRecovered(): void {
        const params = {
            company_id: this.currentCompany.id
        };

        this.journeyProvider.getRecovered(this.journey.id, params).subscribe((camps) => {
            for (const camp of camps) {
                this.campsSelected.push(camp);
            }
        });
    }

    activeEffect(): void {
        const timeOut = setTimeout(() => {
            this.active = true;
            clearTimeout(timeOut);
        }, 100);
    }

    displayed(index: number): void {
        this.displays[index] = !this.displays[index];
    }

    changeTab(tab: string, step: number): void {
        if (this.currentTab !== 'published' && this.step > step) {
            this.currentTab = tab;

            if (tab === 'camps') {
                this.activePanel = 'select-camp';
                this.currentTab = 'invitation';
            }
        }
    }

    closeSidebar(): void {
        this.active = false;
        const timeOut = setTimeout(() => {
            this.close.emit(true);
            clearTimeout(timeOut);
        }, 300);
    }

    search() {
        this.loading = true;
        this.displays = [];

        const params = HttpHelper.cleanParams({
            company_id: this.currentCompany.id,
            q: this.query
        });

        this.journeyProvider.getCamps(this.journey.id, params).subscribe((data) => {
            this.loading = false;
            this.companies = data.companies;

            for (let i = 0; i < this.companies.length; i++) {
                if (!this.companies[i].hasProvidersEnabled) {
                    this.alert = true
                }

                for (const camp of this.companies[i].camps) {
                    const index = this.campsSelected.findIndex((id) => {
                        return id === camp.id;
                    });

                    if (index !== -1) {
                        this.displays[i] = true;
                    }

                    if (camp.published) {
                        this.chooseCamp(this.companies[i], camp);
                    }
                }

                if (this.currentCompany.slug === this.companies[i].slug) {
                    const company = this.companies[i];
                    this.companies.splice(i, 1);
                    this.companies.unshift(company);
                    this.displays[0] = true;
                } else if (this.query) {
                    this.displays[i] = true;
                }
            }
        });
    }

    chooseCamp(company: any, camp: any): void {
        if (company.hasProvidersEnabled) {
            const index = this.campsSelected.indexOf(camp.id);

            if (index === -1) {
                this.campsSelected.push(camp.id);
            } else {
                this.campsSelected.splice(index, 1);
            }
        }
    }

    isChosen(campId: number): boolean {
        return this.campsSelected.indexOf(campId) > -1;
    }

    options(): void {
        if (this.campsSelected.length > 0) {
            this.activePanel = 'options';
        }
    }

    back(): void {
        switch (this.currentTab) {
            case 'invitation':
                this.activePanel = 'select-camp';
                break;
            case 'settings':
                this.currentTab = 'invitation';
                break;
            case 'summary':
                this.currentTab = 'settings';
                break;
        }
    }

    next(): void {
        switch (this.currentTab) {
            case 'invitation':
                this.currentTab = 'settings';
                this.step = 2;
                break;
            case 'settings':
                this.currentTab = 'summary';
                this.step = 3;
                break;
            case 'summary':
                this.publishJourney();
                break;
        }
    }

    selectCatalogue(catalogue: boolean = true): void {
        this.settings.is_catalogue = catalogue;
    }

    publishJourney(): void {
        this.settings.camps = this.campsSelected;
        const data = {
            settings: this.settings
        };

        this.active = false;
        const timeOut = setTimeout(() => {
            this.publish.emit(data);
            clearTimeout(timeOut);
        }, 300);
    }
}
