<msc-header></msc-header>

<div class="overlay" [ngClass]="{'active' : (step && step !== 'sidemenu') || aside?.onDemand || aside?.onDemandLearners}">
</div>

<ng-container *ngIf="hasInitialData">
    <msc-topbar [type]="'instance'" [breadcrumb]="(breadcrumb) ? templates[0].title : ''" (publish)="publishInstance()" (create)="newInstance()" (search)="filterSearch($event)"></msc-topbar>
</ng-container>

<div class="container">
    <instance-manager [step]="step" [template]="template" [instances]="selectedInstances" [camps]="campsSelected"
        (eventDispatcher)="eventDispatcher($event, modalDeleteInstance, modalRealign)"></instance-manager>

    <content-ondemand *ngIf="aside?.onDemand" [template]="selectedTemplate" (onClose)="closeAside('onDemand')"
        (onPublish)="publishOnDemand($event)"></content-ondemand>

    <learners-ondemand *ngIf="aside?.onDemandLearners" [template]="selectedTemplate"
        (onClose)="closeAside('onDemandLearners')"></learners-ondemand>

    <div class="grid between">
        <aside class="sidebar sidebar--filter" *ngIf="displayMode === 'list'">
            <form class="form" action="">
                <div class="form__item input">
                    <label class="input__label" for="filterDate">{{ 'words.filter-date' | translate }}
                        <div class="input__date">
                            <input class="input__field" type="text" id="filterDate" [(ngModel)]="search.start_at"
                                [ngModelOptions]="{standalone: true}" (click)="activeDatepicker()">
                            <msc-datepicker *ngIf="datePickerActive" [class]="'small'" [date]="search.start_at"
                                (onSelectDate)="selectedDate($event)" (close)="activeDatepicker(true)"></msc-datepicker>
                        </div>
                    </label>
                </div>

                <div class="form__item multi-select">
                    <label class="input select__label select__label--multi">{{ 'studio.course.language' | translate
                        }}</label>
                    <label class="multi-select__label select select__field" (click)="dropdownLanguages()"
                        [ngClass]="{'js-active': dropdownLanguagesActive}" [(autoClose)]="dropdownLanguagesActive"
                        [activeClass]="'js-active'">
                        {{ 'studio.course.language' | translate }}
                    </label>
                    <div class="multi-select__list">
                        <label class="multi-select__item input" *ngFor="let lang of metaLanguages">
                            <input type="checkbox" class="input-checkbox" [value]="lang.id" (click)="setLanguage(lang)">
                            {{ lang.name }}
                        </label>
                    </div>
                </div>

                <div class="form__item multi-select">
                    <label class="input select__label select__label--multi">{{ 'studio.instance.trainers' | translate
                        }}</label>
                    <label class="multi-select__label select select__field" (click)="dropdownTrainers()"
                        [ngClass]="{'js-active': dropdownTrainersActive}" [(autoClose)]="dropdownTrainersActive"
                        [activeClass]="'js-active'">
                        {{ 'studio.instance.trainers' | translate }}
                    </label>
                    <div class="multi-select__list">
                        <label class="multi-select__item input" *ngFor="let trainer of trainersList">
                            <input type="checkbox" class="input-checkbox" [value]="trainer.id"
                                (click)="addTrainers(trainer.id)"> {{ trainer.name }}
                        </label>

                        <button class="show-more show-more--no-margin"
                            *ngIf="paginationTrainers?.currentPage < paginationTrainers?.lastPage"
                            (click)="loadMoreTrainers($event)" [disabled]="loadingTrainers">
                            <span>{{ 'words.load-more' | translate }}</span>
                        </button>
                    </div>
                </div>

                <div class="form__item input">
                    <p class="input__label">{{ 'studio.instance.discussion-visibility' | translate }}</p>
                    <label for="private">
                        <input type="checkbox" id="private" class="input-checkbox" (click)="setDiscussion('private')">
                        {{ 'studio.instance.discussion.private' | translate }}
                    </label>

                    <label for="shared">
                        <input type="checkbox" id="shared" class="input-checkbox" (click)="setDiscussion('shared')"> {{
                        'studio.instance.discussion.shared' | translate }}
                    </label>
                </div>
            </form>
        </aside>

        <aside class="sidebar sidebar--filter" *ngIf="displayMode === 'calendar'">
            <form class="form" action="">
                <div class="form__item input">
                    <p class="input__label">{{ 'studio.course.language' | translate }}</p>
                    <label class="input" for="frCalendar">
                        <input class="input-checkbox" type="checkbox" id="frCalendar" value="fr"
                            (click)="addLanguageFilter('fr')"> {{ 'studio.event.event-session.language.fr' | translate
                        }}
                    </label>

                    <label class="input" for="enCalendar">
                        <input class="input-checkbox" type="checkbox" id="enCalendar" value="en"
                            (click)="addLanguageFilter('en')"> {{ 'studio.event.event-session.language.en' | translate
                        }}
                    </label>

                    <label class="input" for="nlCalendar">
                        <input class="input-checkbox" type="checkbox" id="nlCalendar" value="nl"
                            (click)="addLanguageFilter('nl')"> {{ 'studio.event.event-session.language.nl' | translate
                        }}
                    </label>

                    <label class="input" for="deCalendar">
                        <input class="input-checkbox" type="checkbox" id="deCalendar" value="de"
                            (click)="addLanguageFilter('de')"> {{ 'words.de' | translate }}
                    </label>

                    <label class="input" for="deCalendar">
                        <input class="input-checkbox" type="checkbox" id="esCalendar" value="es"
                            (click)="addLanguageFilter('es')"> {{ 'words.es' | translate }}
                    </label>
                </div>

                <div class="form__item" *ngIf="!breadcrumb">
                    <label class="input select__label">{{ 'studio.template.title' | translate }}
                        <div class="select">
                            <select class="select__field" [(ngModel)]="templateFilterChosen"
                                (ngModelChange)="doFilterTemplate()" [ngModelOptions]="{standalone: true}">
                                <option value="reset">{{ 'words.all' | translate }}</option>
                                <option [value]="template.id" *ngFor="let template of templatesFilter">{{ template.title
                                    }}</option>
                            </select>
                        </div>
                    </label>
                </div>
            </form>
        </aside>

        <div class="section section--sidebar session">
            <header class="section__header grid middle between" style="justify-content: flex-end;">
                <div *ngIf="company.permissions.companyLockSession" class="templab-library__switch"
                    style="margin: 0 1rem 0 0 !important">
                    <label class="switch__input">
                        <p style="margin-right: 0.5rem;">{{ 'words.show-archived' | translate }}</p>
                        <div class="switch switch_height_small" [ngClass]="{'switch_active': showArchived}">
                            <input type="checkbox" [(ngModel)]="showArchived" (ngModelChange)="refresh()">
                        </div>
                    </label>
                </div>
                <div class="section__sort">
                    <div class="sort sort--list">
                        <a class="sort__list" (click)="changeDisplayMode('calendar')"
                            [ngClass]="{'active' : displayMode === 'calendar'}">
                            <i class="icon icon--calendar"></i>
                        </a>

                        <a class="sort__list" (click)="changeDisplayMode('list')"
                            [ngClass]="{'active' : displayMode === 'list'}">
                            <i class="icon icon--list"></i>
                        </a>
                    </div>
                </div>
            </header>

            <div class="session__item" [hidden]="displayMode === 'calendar'"
                *ngFor="let template of templates; let index=index">
                <div class="session__head grid between middle">
                    <div class="session__head-checkbox">
                        <input type="checkbox" class="input-checkbox" (click)="selectTemplate(template)"
                            [checked]="isTemplateSelected(template.id)">
                    </div>

                    <h6 class="session__modul-title" (click)="navigateTo('instance', template.slug)">{{ template.title
                        !== '' ? template.title : ('words.template-without-title' | translate) }} - <span class="">{{
                            template.instancesCount }}</span>
                    </h6>
                    <a class="session__toggle" (click)="changeTemplateOpen(index)"
                        [ngClass]="{'active': templateOpen[index]}">
                        <i class="icon icon--less-circle"></i>
                    </a>
                </div>

                <div class="session__content" [ngClass]="{'active': templateOpen[index]}">
                    <div class="m-content-menu">
                        <div class="session__menu">
                            <div class="session__add m-black m-left tooltip-custom m-hidden"
                                [ngClass]="{'m-visible': displayMenu[index], 'm-left-normal': displayMenu[index]}"
                                (click)="createInstance(template)">
                                <i class="icon icon--modul"></i>

                                <div class="tooltip__info">
                                    {{ 'studio.instance.create' | translate }}
                                </div>
                            </div>

                            <div class="session__add m-adding" [ngClass]="{'m-reverse': displayMenu[index]}"
                                (click)="displayedMenu(index)">
                                <i class="icon icon--add"></i>
                            </div>

                            <div class="session__add m-orange m-right tooltip-custom m-hidden"
                                [ngClass]="{'m-visible': displayMenu[index] && !template?.onDemand, 'm-right-normal': displayMenu[index]}"
                                (click)="enableOnDemand(template, true)">
                                <i class="icon icon--publish"></i>

                                <div class="tooltip__info">
                                    {{ 'studio.instance.enable-od' | translate }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="m-content" *ngIf="template?.instances || template?.onDemand">
                        <div class="m-content-od" *ngIf="template?.onDemand">
                            <div class="m-od-title">
                                {{ 'studio.instance.content-od-title' | translate }}
                            </div>

                            <div class="m-od-camps tooltip-custom">
                                <span>{{ template.campsCount }}</span>
                                <i class="icon icon--camp"></i>
                                <div class="tooltip__info" *ngIf="template.campsCount > 0">
                                    {{ template.campsNames }}
                                </div>
                            </div>

                            <div class="m-od-camps m-od-learners" (click)="enableOnDemand(template, false, 'learners')">
                                <span>{{ template.savedContentsUsersCount > 0 ? template.savedContentsUsersCount : 0
                                    }}</span>
                                <i class="icon icon--users"></i>
                            </div>

                            <div class="m-od-settings">
                                <i class="m-icon new-icon icon-settings-outline" (click)="openContentOd(index)"></i>

                                <ul class="dropdown__sub dropdown__sub--right"
                                    [ngClass]="{'active': dropdownContentOd[index]}"
                                    [(autoClose)]="dropdownContentOd[index]">
                                    <li class="dropdown__item" (click)="enableOnDemand(template, false)">
                                        <a class="dropdown__link">{{ 'words.modify' | translate }}</a>
                                    </li>

                                    <li class="dropdown__item"
                                        (click)="openModalDisableContentOd(template, modalDisableContentOd)">
                                        <a class="dropdown__link">{{ 'words.disable' | translate }}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <ul class="box box--session">
                            <li class="box__session" *ngFor="let instance of template.instances">
                                <input type="checkbox" class="input-checkbox"
                                    (click)="selectInstance(instance, template)"
                                    [checked]="isInstanceSelected(instance.id)">
                                <div class="box__session-list grid between middle">
                                    <div class="box__grid grid middle col-four">
                                        <div class="box__calendar calendar calendar--thumb" *ngIf="instance.nextEvent">
                                            <span class="calendar__mounth-thumb" style="font-size: 0.8rem;">{{ 'month.'
                                                + getMonth(instance.nextEvent) | translate }}</span>
                                            <p class="calendar__days-thumb">{{ getDay(instance.nextEvent) }}</p>
                                        </div>

                                        <a (click)="selectInstance(instance, template)">
                                            <div class="box__session-name">
                                                <h1 class="box__title">{{ instance.settings?.name }}</h1>
                                                <p class="box__id">{{ instance.settings?.reference }}</p>
                                            </div>
                                        </a>
                                    </div>

                                    <div class="box__list grid middle tooltip-custom col-one">
                                        <span class="box__strong">
                                            {{ instance.campsCount }}
                                        </span>
                                        <i class="icon icon--camp"></i>
                                        <div class="tooltip__info" *ngIf="instance.campsCount > 0">
                                            {{ instance.campsNames }}
                                        </div>
                                    </div>

                                    <div class="box__list grid middle col-one">
                                        <p>
                                            <span class="box__strong" style="margin: 0;">{{ instance.usersCount
                                                }}</span>
                                            <span> / {{( (instance.settings?.usersLimit) ? instance.settings.usersLimit
                                                : '-' )}}</span>
                                        </p>
                                        <i class="icon icon--users"></i>
                                    </div>

                                    <div class="box__list col-one">
                                        <p>{{ instance.language?.name }}</p>
                                    </div>

                                    <div class="box__list grid grid--wrap col-two">
                                        <div class="tooltip-custom" *ngFor="let trainer of instance.trainers">
                                            <img class="avatar" [src]="trainer.picture+'?size=32'">
                                            <span class="tooltip__info">
                                                {{ trainer.name }}
                                            </span>
                                        </div>
                                    </div>

                                    <div class="box__list grid grid--wrap col-one"
                                        *ngIf="company.permissions.companyLockSession">
                                        <div class="tooltip-custom">
                                            <div class="tag">
                                                <ng-container *ngIf="instance.state == 'draft'">{{
                                                    'words.instance-draft' | translate | titlecase }}</ng-container>
                                                <ng-container *ngIf="instance.state == 'published'">{{
                                                    'words.instance-published' | translate | titlecase }}</ng-container>
                                                <ng-container *ngIf="instance.state == 'ongoing'">{{
                                                    'words.instance-ongoing' | translate | titlecase }}</ng-container>
                                                <ng-container *ngIf="instance.state == 'archived'">{{
                                                    'words.instance-archived' | translate | titlecase }}</ng-container>
                                            </div>
                                            <span class="tooltip__info">
                                                <ng-container *ngIf="instance.state == 'draft'">{{
                                                    'tooltip.instance-draft' | translate }}</ng-container>
                                                <ng-container *ngIf="instance.state == 'published'">{{
                                                    'tooltip.instance-published' | translate }}</ng-container>
                                                <ng-container *ngIf="instance.state == 'ongoing'">{{
                                                    'tooltip.instance-ongoing' | translate }}</ng-container>
                                                <ng-container *ngIf="instance.state == 'archived'">{{
                                                    'tooltip.instance-archived' | translate }}</ng-container>
                                            </span>
                                        </div>
                                    </div>

                                    <div class="dropdown col-one">
                                        <a class="box__btn-edit dropdown__click"
                                            (click)="changeDropdownInstance(instance.id)">
                                            <i class="icon icon--more"></i>
                                        </a>

                                        <ul class="dropdown__sub dropdown__sub--right"
                                            [ngClass]="{'active': dropdownOpen[instance.id]}"
                                            [(autoClose)]="dropdownOpen[instance.id]">
                                            <li class="dropdown__item">
                                                <a class="dropdown__link"
                                                    (click)="selected.instance = instance; selected.template = template; publishInstance(instance, template)">{{
                                                    'studio.sidebar.publish' | translate }}</a>
                                            </li>

                                            <li class="dropdown__item">
                                                <a class="dropdown__link" (click)="editInstance(instance)">{{
                                                    'words.edit-session' | translate }}</a>
                                            </li>

                                            <li class="dropdown__item"
                                                *ngIf="company.permissions.companyLockSession && instance.state == 'ongoing'">
                                                <a class="dropdown__link" (click)="preview(instance.templateId)">{{
                                                    'words.view' | translate }}</a>
                                            </li>

                                            <li class="dropdown__item"
                                                *ngIf="!company.permissions.companyLockSession || instance.state != 'ongoing'">
                                                <a class="dropdown__link"
                                                    (click)="navigateTo('course', instance.slug)">{{ 'words.customize' |
                                                    translate }}</a>
                                            </li>

                                            <li class="dropdown__item">
                                                <a class="dropdown__link" (click)="cloneInstance(instance)">{{
                                                    'studio.common.button.clone' | translate }}</a>
                                            </li>

                                            <ng-container *ngIf="company.permissions.companyLockSession">
                                                <li class="dropdown__item"
                                                    *ngIf="!['archived', 'ongoing', 'draft'].includes(instance.state)">
                                                    <a class="dropdown__link"
                                                        (click)="selected.instance = instance; selected.template = template; unpublishInstance(instance, template)">{{
                                                        'studio.instance.create-unpublish' | translate }}</a>
                                                </li>

                                                <li class="dropdown__item" *ngIf="instance.state != 'archived'">
                                                    <a class="dropdown__link"
                                                        (click)="openArchiveInstance(instance, modalArchiveInstance)">{{
                                                        'studio.common.button.archive' | translate }}</a>
                                                </li>

                                                <li class="dropdown__item" *ngIf="instance.state == 'archived'">
                                                    <a class="dropdown__link" (click)="restoreInstance(instance)">{{
                                                        'studio.common.button.restore' | translate }}</a>
                                                </li>
                                            </ng-container>

                                            <li class="dropdown__item"
                                            [ngClass]="{ 'generic-disabled': instance.hasOnboardings }">
                                                <a class="dropdown__link"
                                                    [mscTooltip]="instance.hasOnboardings ? ('words.has-onboardings.cannot-delete' | translate) : null"
                                                    (click)="!instance.hasOnboardings ? openDeleteInstance(instance, modalDeleteInstance) : null">{{
                                                    'studio.common.button.delete' | translate }}</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </li>

                            <li class="box__session box__session--more"
                                *ngIf="template.instancesCount > 3 && !breadcrumb">
                                <a class="session__more" (click)="navigateTo('instance', template.slug)">{{
                                    template.instancesCount - 3 }} {{ 'studio.instance.more' | translate }}...
                                    <i class="icon icon--arrow-right"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="section__calendar" *ngIf="displayMode === 'calendar'">
                <full-calendar #calendar [options]="calendarOptions"></full-calendar>
            </div>

            <button class="show-more"
                *ngIf="pagination?.currentPage < pagination?.lastPage && displayMode === 'list' && !breadcrumb"
                (click)="loadMore()">
                <div class="lds-ring" *ngIf="processing">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                <span>{{ 'studio.instance.load-more' | translate }}</span>
            </button>
        </div>
    </div>
</div>

<ng-template #modalDeleteInstance let-modal>
    <div class="modal-header">
        <span></span>
        <button type="button" class="close" aria-label="Close" (click)="modal.close()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="scrollable" style="background-color: #FFFFFF; padding: 1.2rem 0 4rem 0; text-align: center;">
            <div class="m-title"
                [innerHTML]="(currentInstance ? 'studio.delete-instance.confirmation' : 'studio.delete-instances.confirmation') | translate">
            </div>

            <div class="m-content-modal m-grey" *ngIf="currentInstance">
                <div class="box__grid grid middle" style="width: 50%;">
                    <a>
                        <div class="box__session-name">
                            <h1 class="box__title">{{ currentInstance.internalName }}</h1>
                        </div>
                    </a>
                </div>

                <div class="box__list grid middle tooltip-custom" style="width: 10%; justify-content: center;">
                    <span class="box__strong">{{ currentInstance.campsCount }}</span>
                    <i class="icon icon--camp"></i>
                    <div class="tooltip__info" *ngIf="currentInstance.campsCount > 0">
                        {{ currentInstance.campsNames }}
                    </div>
                </div>

                <div class="box__list grid middle" style="width: 10%;">
                    <p style="margin: 0;">
                        <span class="box__strong" style="margin: 0;">{{ currentInstance.usersCount }}</span>
                        <span> / {{ (currentInstance.settings?.usersLimit) ? currentInstance.settings.usersLimit : '-'
                            }}</span>
                    </p>
                    <i class="icon icon--users"></i>
                </div>
            </div>

            <div class="m-content-modal m-content-columns" *ngIf="!currentInstance">
                <div class="m-item m-grey" *ngFor="let instance of instancesSelectList">
                    <div class="box__grid grid middle" style="width: 50%;">
                        <a>
                            <div class="box__session-name">
                                <h1 class="box__title">{{ instance.internalName }}</h1>
                                <p class="box__id"></p>
                            </div>
                        </a>
                    </div>

                    <div class="box__list grid middle tooltip-custom" style="width: 10%; justify-content: center;">
                        <span class="box__strong">{{ instance.campsCount }}</span>
                        <i class="icon icon--camp"></i>
                        <div class="tooltip__info" *ngIf="instance.campsCount > 0">
                            {{ instance.campsNames }}
                        </div>
                    </div>

                    <div class="box__list grid middle" style="width: 10%;">
                        <p style="margin: 0;">
                            <span class="box__strong" style="margin: 0;">{{ instance.usersCount }}</span>
                            <span> / {{ (instance.settings?.usersLimit) ? instance.settings.usersLimit : '-' }}</span>
                        </p>
                        <i class="icon icon--users"></i>
                    </div>
                </div>
            </div>

            <div class="m-notify" *ngIf="containsUsers()">
                <label>
                    <input type="checkbox" [(ngModel)]="delete.notify"> {{ 'instance.notify-unsubscribe' | translate }}
                </label>
            </div>

            <div class="m-notify" *ngIf="containsUsers()">
                <label>
                    <input type="checkbox" [(ngModel)]="delete.savedContents"> {{ 'instance.delete.saved-contents' |
                    translate }}
                </label>
            </div>

            <div class="m-notify" *ngIf="containsUsers()">
                <label>
                    <input type="checkbox" [(ngModel)]="delete.notifySavedContents"> {{ 'instance.notify-saved-contents'
                    | translate }}
                </label>
            </div>

            <div class="actions" style="padding: 2rem 0 0 0;">
                <button class="btn btn_color_red" (click)="deleteInstance(currentInstance); modal.close()">
                    {{ 'studio.common.button.delete' | translate }}
                </button>

                <button class="btn btn_color_grey" (click)="modal.close()">
                    {{ 'studio.common.button.cancel' | translate }}
                </button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #modalArchiveInstance let-modal>
    <div class="modal-header">
        <span></span>
        <button type="button" class="close" aria-label="Close" (click)="modal.close()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="scrollable" style="background-color: #FFFFFF; padding: 1.2rem 0 4rem 0; text-align: center;">
            <div class="m-title"
                [innerHTML]="(currentInstance ? 'studio.archive-instance.title' : 'studio.archive-instances.title') | translate">
            </div>

            <div class="m-content-modal m-grey" *ngIf="currentInstance">
                <div class="box__grid grid middle" style="width: 50%;">
                    <a>
                        <div class="box__session-name">
                            <h1 class="box__title">{{ currentInstance.internalName }}</h1>
                        </div>
                    </a>
                </div>

                <div class="box__list grid middle tooltip-custom" style="width: 10%; justify-content: center;">
                    <span class="box__strong">{{ currentInstance.campsCount }}</span>
                    <i class="icon icon--camp"></i>
                    <div class="tooltip__info" *ngIf="currentInstance.campsCount > 0">
                        {{ currentInstance.campsNames }}
                    </div>
                </div>

                <div class="box__list grid middle" style="width: 10%;">
                    <p style="margin: 0;">
                        <span class="box__strong" style="margin: 0;">{{ currentInstance.usersCount }}</span>
                        <span> / {{ (currentInstance.settings?.usersLimit) ? currentInstance.settings.usersLimit : '-'
                            }}</span>
                    </p>
                    <i class="icon icon--users"></i>
                </div>
            </div>

            <div class="m-content-modal m-content-columns" *ngIf="!currentInstance">
                <div class="m-item m-grey" *ngFor="let instance of instancesSelectList">
                    <div class="box__grid grid middle" style="width: 50%;">
                        <a>
                            <div class="box__session-name">
                                <h1 class="box__title">{{ instance.internalName }}</h1>
                                <p class="box__id"></p>
                            </div>
                        </a>
                    </div>

                    <div class="box__list grid middle tooltip-custom" style="width: 10%; justify-content: center;">
                        <span class="box__strong">{{ instance.campsCount }}</span>
                        <i class="icon icon--camp"></i>
                        <div class="tooltip__info" *ngIf="instance.campsCount > 0">
                            {{ instance.campsNames }}
                        </div>
                    </div>

                    <div class="box__list grid middle" style="width: 10%;">
                        <p style="margin: 0;">
                            <span class="box__strong" style="margin: 0;">{{ instance.usersCount }}</span>
                            <span> / {{ (instance.settings?.usersLimit) ? instance.settings.usersLimit : '-' }}</span>
                        </p>
                        <i class="icon icon--users"></i>
                    </div>
                </div>
            </div>

            <div class="m-notify"
                [innerHTML]="(currentInstance ? 'studio.archive-instance.confirmation' : 'studio.archive-instances.confirmation') | translate">
            </div>

            <div class="actions" style="padding: 2rem 0 0 0;">
                <button class="btn btn_color_red" (click)="archiveInstance(currentInstance); modal.close()">
                    {{ 'studio.common.button.archive' | translate }}
                </button>

                <button class="btn btn_color_grey" (click)="modal.close()">
                    {{ 'studio.common.button.cancel' | translate }}
                </button>
            </div>
        </div>
    </div>
</ng-template>


<ng-template #modalUnpublish let-modal>
    <instance-unpublish-modal (closeModal)="modal.close()">
    </instance-unpublish-modal>
</ng-template>

<ng-template #modalUnpublishConfirm let-modal>
    <instance-unpublish-confirm-modal (closeModal)="modal.close()" (onConfirm)="unpublishFromAllCamps();modal.close()">
    </instance-unpublish-confirm-modal>
</ng-template>

<ng-template #modalRealign let-modal>
    <div class="modal-header">
        <span></span>
        <button type="button" class="close" aria-label="Close" (click)="modal.close()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="scrollable" style="background-color: #FFFFFF; padding: 5rem; text-align: center;">
            {{ 'studio.topbar.reset-instance.hint' | translate }}
            <br>
            <br> 1. {{ 'studio.topbar.reset-instance.one' | translate }}
            <br> 2. {{ 'studio.topbar.reset-instance.two' | translate }}
            <br> 3. {{ 'studio.topbar.reset-instance.three' | translate }}
            <br>
            <br> {{ 'studio.topbar.reset-instance.hint-progress' | translate }}
            <br>
            <br>

            <div class="reset-event">
                <label for="reset-event">
                    <input id="reset-event" type="checkbox" [checked]="dataRealign.resetEvents"
                        [(ngModel)]="dataRealign.resetEvents" (click)="$event.stopPropagation()"> {{
                    'studio.instance.reset-events' | translate }}
                </label>
            </div>

            <div>
                <strong>{{ 'studio.topbar.reset-instance.end-hint' | translate }}</strong>
            </div>

            <div style="color: tomato; margin: 1rem 0 0"
                *ngIf="instancesSelectList.length === 1 && instancesSelectList[0].realignedAt">
                <strong>
                    {{ 'studio.topbar.reset-instance.last-date' | translate }} :
                    {{ instancesSelectList[0].realignedAt | date: 'dd/MM/yyyy HH:mm' }}
                </strong>
            </div>

            <div class="actions" style="padding-top: 2rem;">
                <button class="btn btn_color_red" (click)="realignInstance()">
                    <span>{{ 'studio.topbar.reset-instance' | translate }}</span>
                </button>

                <button class="btn btn_color_grey" (click)="modal.close()">
                    {{ 'studio.common.button.cancel' | translate }}
                </button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #modalDisableContentOd let-modal>
    <div class="modal-header">
        <span></span>
        <button type="button" class="close" aria-label="Close" (click)="modal.close()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="scrollable" style="background-color: #FFFFFF; padding: 1.2rem 0 4rem 0; text-align: center;">
            <div class="">
                {{ 'studio.instance.content-on-demand' | translate }}
            </div>

            <div class="actions" style="padding: 2rem 0 0 0;">
                <button class="btn btn_color_red" (click)="disableContentOd(); modal.close()">
                    {{ 'words.disable' | translate }}
                </button>

                <button class="btn btn_color_grey" (click)="modal.close()">
                    {{ 'studio.common.button.cancel' | translate }}
                </button>
            </div>
        </div>
    </div>
</ng-template>
