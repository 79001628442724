import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
    name: 'key'
})
export class KeyPipe implements PipeTransform {

    transform(collection): any {
        const array = []
        for (const key in collection) {
            array.push({ key: key, value: collection[key] });
        }
        return array;
    }
}
