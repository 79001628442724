import { Question } from './questions/question';
import { Observable } from 'rxjs';
import { factoryQuestion } from './questions/question.factory';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { cleanObject } from '../../../../helpers';
import { environment } from 'src/environments/environment';

export class Exercise {
    id: number;
    position: number;
    exerciseId: number;
    selectedQuestions?: number; //TODO
    questions: Question[] = [];
    randomize: boolean;

    constructor(exercise: any, private http: HttpClient) {
        this.extends(exercise);
    }

    extends(exercise: any): Exercise {
        Object.assign(this, exercise);
        let exerciseQuestions = (exercise && exercise.exercise && exercise.exercise.questions ? exercise.exercise.questions : (exercise && exercise.questions ? exercise.questions : []));
        if (exerciseQuestions.length > 0) {
            this.questions = exerciseQuestions.map((question) => factoryQuestion(question.type, question, this.http));
        }

        return this;
    }

    addQuestion(data: any, params?): Observable<Exercise> {
        return this.http.post(`${environment.envVar.API_URL}/studio/question`, data, { params })
            .pipe(map((question: any) => {
                this.questions.push(factoryQuestion(question.type, question, this.http));
                return this;
            }));
    }

    getAllQuestion(): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/studio/question`);
    }

    getQuestion(id: number): Observable<Exercise> {
        return this.http.get(`${environment.envVar.API_URL}/studio/lesson/exercise/${id}`)
            .pipe(map(res => new Exercise(res, this.http)));
    }

    deleteQuestion(questionId: number, params?): Observable<void> {
        const selectedQuestionIndex = this.questions.findIndex(question => question.id === questionId);
        return this.http.delete(`${environment.envVar.API_URL}/studio/question/${questionId}`, { params })
            .pipe(map(() => {
                this.questions.splice(selectedQuestionIndex, 1);
                return null;
            }));
    }

    update(params?): Observable<Exercise> {
        return this.http.put(`${environment.envVar.API_URL}/studio/exercise/${this.id}`, cleanObject(this), { params })
            .pipe(map(res => this.extends(res)));
    }
}
