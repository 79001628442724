<table>
    <thead>
        <tr>
            <ng-container *ngFor="let th of thList">
                <th [style.width]="th?.width">
                    <ng-container *ngTemplateOutlet="th.template; context: { datum: th }"></ng-container>
                </th>
            </ng-container>
        </tr>
    </thead>

    <tbody>
        <cdk-virtual-scroll-viewport [itemSize]="70">
            <ng-container *cdkVirtualFor="let row of rows; trackBy: onTrackByIndex">
                <tr class="row">
                    <ng-container *ngFor="let td of tdList">
                        <td [style.width]="td?.width">
                            <ng-container *ngTemplateOutlet="td.template; context: { datum: row }"></ng-container>
                        </td>
                    </ng-container>
                </tr>
            </ng-container>
        </cdk-virtual-scroll-viewport>
    </tbody>
</table>
