<div class="add-question" [attr.id]="'aq-' + position + '-' + sectionContentId" [ngClass]="{'open-modal': modalAction}">
    <div class="add-question__trigger" (click)="changeModalAction()">
        <button class="icon">+</button>
        <button class="btn btn_color_white btn_width_1 text">{{ 'studio.modal-question.create-question' | translate }}</button>
        <button class="icon">+</button>
    </div>

    <div class="add-question__content">
        <div class="row">
            <div class="columns medium-3">
                <div class="item" (click)="addQuestion('fill-in')">
                    <img src="https://static.myskillcamp.com/images/studio/icons/player/illu_question_texteatrou.svg" />
                    <h5 class="item__title">{{ 'studio.modal-question.fill-in-title' | translate }}</h5>
                    <span class="item__subtitle">{{ 'studio.modal-question.fill-in-description' | translate }}</span>
                </div>
            </div>

            <div class="columns medium-3" (click)="addQuestion('open-write')">
                <div class="item">
                    <img src="https://static.myskillcamp.com/images/studio/icons/player/illu_question_questionouverte.svg" />
                    <h5 class="item__title">{{ 'studio.modal-question.open-write-title' | translate }}</h5>
                    <span class="item__subtitle">{{ 'studio.modal-question.open-write-description' | translate }}</span>
                </div>
            </div>

            <div class="columns medium-3">
                <div class="item" (click)="addQuestion('true-false')">
                    <img src="https://static.myskillcamp.com/images/studio/icons/player/illu_question_vraioufaux.svg" />
                    <h5 class="item__title">{{ 'studio.modal-question.true-false-title' | translate }}</h5>
                    <span class="item__subtitle">{{ 'studio.modal-question.true-false-description' | translate }}</span>
                </div>
            </div>

            <div class="columns medium-3">
                <div class="item" (click)="addQuestion('multiple')">
                    <img src="https://static.myskillcamp.com/images/studio/icons/player/illu_question_choixmultiple.svg" />
                    <h5 class="item__title">{{ 'studio.modal-question.multiple-title' | translate }}</h5>
                    <span class="item__subtitle">{{ 'studio.modal-question.multiple-description' | translate }}</span>
                </div>
            </div>

            <div class="columns medium-3">
                <div class="item" (click)="addQuestion('ordered')">
                    <img src="https://static.myskillcamp.com/images/studio/icons/player/illu_question_phraseordonnee.svg" />
                    <h5 class="item__title">{{ 'studio.modal-question.ordered-title' | translate }}</h5>
                    <span class="item__subtitle">{{ 'studio.modal-question.ordered-description' | translate }}</span>
                </div>
            </div>

            <div class="columns medium-3">
                <div class="item" (click)="addQuestion('linker')">
                    <img src="https://static.myskillcamp.com/images/studio/icons/player/illu_question_association.svg" />
                    <h5 class="item__title">{{ 'studio.modal-question.linker-title' | translate }}</h5>
                    <span class="item__subtitle">{{ 'studio.modal-question.linker-description' | translate }}</span>
                </div>
            </div>

            <div class="columns medium-3">
                <div class="item" (click)="addQuestion('category')">
                    <img src="https://static.myskillcamp.com/images/studio/icons/player/illu_question_flashcards.svg" />
                    <h5 class="item__title">{{ 'studio.modal-question.category-title' | translate }}</h5>
                    <span class="item__subtitle">{{ 'studio.modal-question.category-description' | translate }}</span>
                </div>
            </div>

            <div class="columns medium-3">
                <div class="item" (click)="addQuestion('procedure')">
                    <img src="https://static.myskillcamp.com/images/studio/icons/player/illu-question-process.svg" />
                    <h5 class="item__title">{{ 'studio.modal-question.procedure-title' | translate }}</h5>
                    <span class="item__subtitle">{{ 'studio.modal-question.procedure-description' | translate }}</span>
                </div>
            </div>
        </div>
    </div>
</div>
