<div class="add-library">
    <div class="add-media" *ngIf="uploadState === 'waiting'">
        <div class="wrapper">
            <div class="">
                <div class="add-media__container">
                    <div class="interior">
                        <div class="upload"></div>
                        <h6>{{'studio.library.import-file' | translate}}</h6>

                        <label class="upload-label" for="file"></label>
                        <input id="file" type="file" accept="image/*" *ngIf="onlyPictures" ngFileSelect [uploadInput]="uploadInput" (uploadOutput)="onUploadOutput($event)">
                        <input id="file" type="file" *ngIf="!onlyPictures" ngFileSelect [uploadInput]="uploadInput" (uploadOutput)="onUploadOutput($event)">
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="upload upload_processing" *ngIf="uploadState === 'uploading'">
        <h2 class="heading">
            {{ files[0].name }}
        </h2>

        <div class="progress">
            <div class="progress__text">{{ ((files[0]?.progress.data.percentage * 0.8 | number:'1.1-1') || 0) + '%' }}</div>
            <div class="progress-bar">
                <div class="progress-bar__progress" [ngStyle]="{'width': (files[0]?.progress.data.percentage * 0.8 || 0)+'%'}"></div>
            </div>
        </div>

        <p *ngIf="files[0].progress.status !== 2">
            {{ 'studio.media.upload.waiting' | translate}}
        </p>
    </div>

    <div class="add-media__preview" *ngIf="uploadState === 'uploaded'">
        {{files[0].name}}
    </div>
</div>
