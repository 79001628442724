import { Component, OnInit, Input } from '@angular/core';
import { UrlHelper } from '../../helpers';
import { UtilityProvider } from '@stuplay';

@Component({
    selector: 'msc-link-preview',
    templateUrl: './link-preview.component.html',
    styleUrls: ['./link-preview.component.scss']
})
export class LinkPreviewComponent implements OnInit {
    @Input() link: string;
    @Input() class: string = 'm-link--white';
    public preview: any;

    constructor(private utilityProvider: UtilityProvider) {}

    ngOnInit() {
        this.utilityProvider.getLinkPreview(this.link).subscribe((data) => {
            this.preview = data;
        });
    }

    navigate(): void {
        UrlHelper.navigate(this.link);
    }
}
