import { Component, Input } from '@angular/core';
import { Media } from '@stuplay';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'media-video',
    templateUrl: './video.component.html'
})
export class MediaVideoComponent {
    @Input() media: Media;
    public url;

    constructor(private sanitizer: DomSanitizer) { }

    ngOnChanges() {
        if (this.media.url) {
            const url = this.media.url.replace('www.vimeo.com/', 'player.vimeo.com/video/');
            this.url = this.sanitizer.bypassSecurityTrustResourceUrl('https:' + url);
        }
    }
}
