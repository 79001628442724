<ul class="grid grid--block box">
    <li *ngFor="let item of contents; let index = index" class="dropdown"
        [ngClass]="{'grid__five box__folder' : type === 'folder', 'grid__four box__modul' : type === 'template'}" (click)="navigateFolder(item)">
        <folder-miniature (slice)=sliceElement($event)
                          (createInstance)="addInstance($event)"
                          [contents]="contents"
                          [index]="index"
                          [item]="item"
                          [type]="type"
                          [sharedCourses]="sharedCourses"
                          [configurable]="configurable">
        </folder-miniature>
    </li>

    <li class="grid__four box__modul box__modul--add" *ngIf="type === 'template' && contents.length < 12 && !sharedCourses">
        <div class="box__group-folder">
            <a class="folder__link-add" (click)="addTemplate()">
                <span class="folder__icon-add">
                    <i class="icon icon--add"></i>
                </span>
                {{ 'studio.template.create' | translate}}
            </a>
        </div>
    </li>

    <li class="grid__five box__folder box__folder--add" *ngIf="type === 'folder' && contents.length < 5 && contents.length !== 0">
        <div class="box__group-folder">
            <a class="folder__link-add" (click)="addFolder()">
                <span class="folder__icon-add">
                    <i class="icon icon--add"></i>
                </span>
                {{ 'studio.folder.create-folder' | translate }}
            </a>
        </div>
    </li>
</ul>

<style>
    .box__modul {
        padding: 0;
    }
</style>
