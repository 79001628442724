<aside class="sidebar sidebar--modal sidebar-v2 sidebar--account" [ngClass]="active ? 'active' : ''" (appSidebar)="closeSidebarEmit()">
    <div class="sidebar__container">
        <div class="sidebar__head">
            <div class="sidebar__description">
                <h1 class="sidebar__title">{{ 'studio.instance.content-od' | translate }} - {{ template.title }}</h1>
                <p>{{ 'studio.instance.learners-od.description' | translate }}</p>
            </div>

            <a class="sidebar__close" (click)="closeSidebarEmit()">
                <i class="icon icon--close"></i>
            </a>
        </div>

        <div class="sidebar__body">
            <div class="account__action">
                <div class="search">
                    <label class="search__label">
                        <i class="search__icon icon icon--search"></i>
                    </label>
                    <input class="search__input" #searchLearners type="search" [placeholder]="'words.search' | translate" (keyup)="search(searchLearners.value)">
                    <i class="icon icon--close"></i>
                </div>

                <button class="btn btn--min" (click)="goToDiscussion()" style="padding-top: 0.5rem; padding-bottom: 0.5rem" *ngIf="checkIfDisplayButton()">
                    <i class="new-icon icon-message-circle-3"></i>
                    {{ 'studio.instance.learners-od.discussion' | translate }}
                </button>
            </div>

            <div class="acount">
                <div class="account__item" *ngFor="let account of accountsList">
                    <div class="account__infos">
                        <div class="avatar">
                            <img class="avatar" [src]="account.picture + '?size=64'">
                        </div>
                        <div class="account__name">
                            <div>{{ account.name }}</div>
                            <div class="account__status">{{ account.headline }}</div>
                        </div>
                    </div>
                    <div class="account__btn" (click)="goToDiscussion(account.id)" *ngIf="!checkIfUser(account)">
                        <i class="new-icon icon-message-circle-3"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</aside>
