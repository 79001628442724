import { Component, Input, Output, EventEmitter } from '@angular/core';
import { AuthProvider, Media } from '@stuplay';
import { UploadOutput, UploadInput, UploadFile } from 'ngx-uploader';
import { environment } from '../../../../environments/environment';
import { ToastService } from '../../components/toast/toast.service';
import { CookieService } from 'ngx-cookie-service';
import { take } from 'rxjs';
import { AuthService } from '../../services/auth.service';

@Component({
    selector: 'library-upload',
    templateUrl: './library-upload.component.html'
})
export class LibraryUploadComponent {
    @Input() media: Media;
    @Input() uploadInput: EventEmitter<UploadInput> = new EventEmitter<UploadInput>();
    @Input() toUpload: boolean;
    @Input() onlyPictures: boolean = false;
    @Output() updateMedia = new EventEmitter();
    @Output() redirect = new EventEmitter();
    public files: UploadFile[] = [];
    public dragOver: boolean;
    public uploadState: string = 'waiting';
    public uploadCountRefrestToken: number = 0;
    public lastRefresh: boolean = false;

    constructor(
        private toastService: ToastService,
        private cookieService: CookieService,
        private authProvider: AuthProvider,
        private authService: AuthService

    ) { }

    onUploadOutput(output: UploadOutput): void {
        console.log('here')
        this.uploadCountRefrestToken = 0;
        this.uploadState = 'uploading';

        if (output.type === 'allAddedToQueue') {
            const isFileSizeValid = this.files[0].size <= 2000000000;
            const isFileTypeValid = this.files[0].type;
            if (!isFileSizeValid) {
                this.toastService.push('file-too-big', 'error');
                this.redirect.emit('document');
            } else if (!isFileTypeValid) {
                this.toastService.push('file.not-supported', 'error');
                this.redirect.emit('document');
            } else {
                const event: UploadInput | any = {
                    type: 'uploadAll',
                    url: `${environment.envVar.API_URL}/media`,
                    method: 'POST',
                    data: {},
                    headers: {
                        'Authorization': 'Bearer ' + sessionStorage.getItem(environment.tokenSessionName)
                    },
                    concurrency: 1
                };
                this.uploadInput.emit(event);
            }
        } else if (output.type === 'addedToQueue') {
            this.files.push(output.file);
        } else if (output.type === 'uploading') {
            const index = this.files.findIndex(file => file.id === output.file.id);
            this.files[index] = output.file;
        } else if (output.type === 'removed') {
            this.files = this.files.filter((file: UploadFile) => file !== output.file);
        } else if (output.type === 'dragOver') {
            this.dragOver = true;
        } else if (output.type === 'dragOut') {
            this.dragOver = false;
        } else if (output.type === 'drop') {
            this.dragOver = false;
        } else if (output.type === 'done') {
        } else if (output.type === 'start') {
            const interval = setInterval(() => {
                if (output.file.progress.status === 2) {
                    this.lastRefresh = false;
                    output.file.progress.data.percentage = 125;
                    const timeOut = setTimeout(() => {
                        if (typeof output.file.response === 'string' && output.file.response.indexOf('is not supported') > -1) {
                            this.toastService.push('file.not-supported', 'error');
                        }

                        if (output.file.responseStatus === 415) {
                            this.toastService.push('file.not-supported', 'error');
                        }

                        if (this.toUpload) {
                            this.onUpdatedMedia(output.file.response)
                        } else {
                            switch (output.file.response.type) {
                                case ('VID'): {
                                    this.redirect.emit('video');
                                    break;
                                }
                                case ('IMG'): {
                                    this.redirect.emit('image');
                                    break;
                                }
                                case ('SND'): {
                                    this.redirect.emit('sound');
                                    break;
                                }
                                default:
                                    this.redirect.emit('document');
                            }
                        }
                        clearTimeout(timeOut);
                    }, 1000);
                    clearInterval(interval);
                }
            }, 1000);
        }
    }

    onUpdatedMedia(media: any): void {
        this.updateMedia.emit(media);
    }
}
