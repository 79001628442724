import { HttpClient } from '@angular/common/http';
import { Media } from '../../../media';

export class ProjectAnswerMedia {
    id: number;
    projectAnswerId: number;
    media: Media;

    constructor(projectAnswerMedia: any, protected http: HttpClient) {
        this.extends(projectAnswerMedia);
    }

    extends(projectAnswer: any): ProjectAnswerMedia {
        Object.assign(this, projectAnswer);
        return this;
    }
}