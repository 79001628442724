import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
    name: 'keyValue'
})
export class KeyValuePipe implements PipeTransform {

    transform(object: any): any {
        const array = [];
        for (let i = 0; i < Object.keys(object).length; i++) {
            array[i] = Object.values(object)[i];
        }
        return array;
    }
}
