<ul>
  <li id="test-button-desktop" class="responsive__size responsive_desktop" [ngClass]="{'responsive__size_active': isResponsiveSize('desktop')}" (click)="setResponsiveSize('desktop')">
    <i class="icon-screen-desktop"></i>
  </li>
  <li id="test-button-tablet" class="responsive__size responsive_tablet" [ngClass]="{'responsive__size_active': isResponsiveSize('tablet')}" (click)="setResponsiveSize('tablet')">
    <i class="icon-screen-tablet"></i>
  </li>
  <li id="test-button-mobile" class="responsive__size responsive_mobile" [ngClass]="{'responsive__size_active': isResponsiveSize('mobile')}" (click)="setResponsiveSize('mobile')">
    <i class="icon-screen-mobile"></i>
  </li>
</ul>
