import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Media } from '@stuplay';
import { UploadOutput, UploadInput, UploadFile, humanizeBytes } from 'ngx-uploader';
import { Observable } from 'rxjs';
import { environment } from '../../../../../../environments/environment';
import { StorageService } from 'src/app/utils/services/storage.service';
import { ToastService } from 'src/app/utils/components/toast/toast.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'scorm-modal',
    templateUrl: './scorm-modal.component.html'
})
export class ScormModalComponent implements OnInit {
    @Input() media: Media;
    @Output() updateScorm = new EventEmitter();
    @Output() closeModal = new EventEmitter<boolean>();
    @Input() uploadInput: EventEmitter<UploadInput>;
    @Input() cloud: boolean = false;

    public formData: FormData;
    public files: UploadFile[];
    public humanizeBytes: Function;
    public dragOver: boolean;
    public observableEmitter: Observable<EventEmitter<UploadInput>>;
    public url: string;
    public scorm: any;

    constructor(
        public http: HttpClient,
        private storageService: StorageService,
        private toastService: ToastService,
        private modalService: NgbModal,
    ) {
        this.files = []; // local uploading files array
        this.uploadInput = new EventEmitter<UploadInput>(); // input events, we use this to emit data to ngx-uploader
        this.humanizeBytes = humanizeBytes;
    }

    ngOnInit() {
        this.url = `${environment.envVar.API_URL}/create/scorms/upload`;
    }

    onUploadOutput(output: UploadOutput, content: any): void {
        if (output.type === 'allAddedToQueue') { // when all files added in queue
            // uncomment this if you want to auto upload files when added
            const event: UploadInput | any = {
                type: 'uploadAll',
                url: this.url,
                method: 'POST',
                data: {
                    company_id: this.storageService.get('company').slug
                },
                headers: {
                    'Authorization': 'Bearer ' + sessionStorage.getItem(environment.tokenSessionName)
                },
                concurrency: 1 // set sequential uploading of files with concurrency 1
            };
            this.uploadInput.emit(event);
        } else if (output.type === 'addedToQueue') {
            this.files.push(output.file); // add file to array when added
        } else if (output.type === 'uploading') {
            // update current data in files array for uploading file
            const index = this.files.findIndex(file => file.id === output.file.id);
            this.files[index] = output.file;
        } else if (output.type === 'removed') {
            // remove file from array when removed
            this.files = this.files.filter((file: UploadFile) => file !== output.file);
        } else if (output.type === 'dragOver') { // drag over event
            this.dragOver = true;
        } else if (output.type === 'dragOut') { // drag out event
            this.dragOver = false;
        } else if (output.type === 'drop') { // on drop event
            this.dragOver = false;
        } else if (output.type === 'done') {
            // this.onUpdatedMedia(output.file.response);
        } else if (output.type === 'removedAll') {
            this.files = [];
        } else if (output.type === 'start') {
            const interval = setInterval(() => {
                if (output.file.progress.status === 2) {
                    output.file.progress.data.percentage = 125;
                    setTimeout(() => {
                        (!this.cloud) ? this.onUpdatedMedia(output.file.response) : this.onUpdateScormCloud(output.file.response, content);
                    }, 1000);
                    clearInterval(interval);
                }
            }, 1000);
        }
    }

    onUpdatedMedia(scorm: any) {
        this.scorm = scorm;
        this.updateScorm.emit(scorm.module.context_id);
        this.close();
    }

    onUpdateScormCloud(scorm: any, content: any): void {
        if (scorm.message) {
            this.toastService.push('invalid-file', 'error');
            this.close();
        } else {
            // IF the SCORM version retrieved is AICC, v1.2 or any other version below
            if (scorm.isUploaded === false) {
                this.scorm = scorm;
                const options: NgbModalOptions = { size: 'lg', centered: true, backdropClass: 'backdrop-custom' };
                this.modalService.open(content, options);
            } else {
                this.updateScorm.emit(scorm.id);
                this.close();
            }
        }
    }

    confirmAddedScorm(scorm: any) {
        this.http.post(`${environment.envVar.API_URL}/create/scorms/upload/confirm`, {
            companyId: this.storageService.get('company').slug,
            version: scorm.version,
            filename: scorm.filename,
            extension: scorm.extension
        }).subscribe((data: any) => {
            this.updateScorm.emit(data.id);
            this.close();
        });
    }

    close() {
        this.closeModal.emit();
    }
}
