import { Question } from '../question';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

export class UniqueQuestion extends Question {
    content: UniqueQuestionContent = new UniqueQuestionContent();
    param: UniqueQuestionParameters = new UniqueQuestionParameters();

    constructor(question: any, protected http: HttpClient) {
        super(question, http);
        this.extends(question);
    }

    extends(question: any): UniqueQuestion {
        Object.assign(this, question);

        if (!this.param) {
            this.param = new UniqueQuestionParameters();
        }
        if (question.param) {
            if (this.param instanceof UniqueQuestionParameters) {
                this.param.extends(question.param);
            } else {
                this.param = new UniqueQuestionParameters(question.param);
            }
        }

        if (!this.content) {
            this.content = new UniqueQuestionContent();
            this.content.addChoice(true);
            this.content.addChoice(false);
            this.content.addChoice(false);
        }
        if (question.content) {
            if (this.content instanceof UniqueQuestionContent) {
                this.content.extends(question.content);
            } else {
                this.content = new UniqueQuestionContent(question.content);
            }
        }

        return this;
    }

    getCorrection(answer: any): Observable<any> {
        return this.postAnswer(answer);
    }
}

export class UniqueQuestionContent {
    choices: UniqueQuestionChoice[] = [];

    constructor(data: any = {}) {
        this.extends(data);
    }

    extends(data: any = {}): UniqueQuestionContent {
        Object.assign(this, data);

        if (data.choices) {
            this.choices = [];
            data.choices.map((choice) => this.choices.push(new UniqueQuestionChoice(choice)));
        }
        return this;
    }

    addChoice(checked: boolean): UniqueQuestionChoice {
        const choice = new UniqueQuestionChoice();
        choice.choice = '';
        choice.state = checked;
        this.choices.push(choice);

        return choice;
    }

    removeChoice(index: number) {
        this.choices.splice(index, 1);
    }
}

export class UniqueQuestionChoice {
    choice: string;
    state: boolean = false;
    mark: number;

    constructor(data: any = {}) {
        this.extends(data);
    }

    extends(data: any = {}): UniqueQuestionChoice {
        Object.assign(this, data);

        return this;
    }
}

export class UniqueQuestionParameters {
    listStyle: string;
    markMode: string;

    constructor(data: any = {}) {
        this.extends(data);
    }

    extends(data: any = {}): UniqueQuestionParameters {
        Object.assign(this, data);

        return this;
    }
}
