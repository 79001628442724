import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { getParams } from '../helpers';
import { Media } from '../media';
import { environment } from 'src/environments/environment';

@Injectable()
export class TimelineProvider {

    constructor(public http: HttpClient) {}

    getTimelineId(params: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/timeline`, {params: getParams(params)});
    }

    getTimeline(id: number, params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/common/timelines/${id}/items`, {params: getParams(params)});
    }

    send(params: any): Observable<any> {
        return this.http.post(`${environment.envVar.API_URL}/timeline`, params);
    }

    addPost(postMessage: string, timelineId: number, media?: Media, crawl?: any, companyId?: number): Observable<any> {
        const params = {
            content: {
                link: crawl,
                media_id: media ? media.id : null,
                message: postMessage,
            },
            timeline_id: timelineId,
            type: 'mixed',
            company_id: companyId
        };

        return this.http.post(`${environment.envVar.API_URL}/timeline`, params);
    }

    deletePost(timelineId: number, postId: number): Observable<any> {
        return this.http.delete(`${environment.envVar.API_URL}/timelines/${timelineId}/items/${postId}`);
    }

    addComment(postId: number, params: any): Observable<any> {
        return this.http.post(`${environment.envVar.API_URL}/timeline/${postId}/content`, params);
    }

    deleteComment(id: number): Observable<any> {
        return this.http.delete(`${environment.envVar.API_URL}/timeline/comment/${id}`);
    }

    crawlUrl(url: string): Observable<any> {
        const data = {data: window.btoa(encodeURIComponent(JSON.stringify({text: url})))};
        return this.http.post(`${environment.envVar.API_URL}/media/crawl`, data, {headers: new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'})});
    }
}
