<div *ngIf="step === 'sidemenu'" style="background-color: white;">
    <instance-sidebar-menu (close)="eventManager($event, 'closeSidemenu')"
                           (publish)="eventManager($event, 'publishInstance')"
                           (unpublish)="eventManager($event, 'unpublishInstance')"
                           (clone)="eventManager($event, 'cloneInstance')"
                           (realign)="eventManager($event, 'realignInstance')"
                           (delete)="eventManager($event, 'deleteInstance')">
    </instance-sidebar-menu>
</div>

<div *ngIf="step === 'selecttemplate'" style="background-color: white;">
    <instance-sidebar-template (create)="eventManager($event, 'createInstance')"
                               (close)="eventManager($event, 'close')">
    </instance-sidebar-template>
</div>

<div *ngIf="step === 'created'" style="background-color: white;">
    <instance-sidebar-create [template]="template"
                             [instance]="instances"
                             (add)="eventManager($event, 'addInstance')"
                             (update)="eventManager($event, 'updateInstance')"
                             (campsSelection)="eventManager($event, 'selectCamps')"
                             (close)="eventManager($event, 'close')">
    </instance-sidebar-create>
</div>

<div *ngIf="step === 'selectcamp'" style="background-color: white;">
    <instance-sidebar-camp [instances]="instances"
                           (settingsConfiguration)="eventManager($event, 'settings')"
                           (close)="eventManager($event, 'close')">
    </instance-sidebar-camp>
</div>

<div *ngIf="step === 'unpublish'" style="background-color: white;">
    <instance-sidebar-unpublish [instances]="instances"
                           (settingsConfiguration)="eventManager($event, 'settings')"
                           (close)="eventManager($event, 'close')">
    </instance-sidebar-unpublish>
</div>

<div *ngIf="step === 'settings'" style="background-color: white;">
    <instance-sidebar-settings [instances]="instances"
                               [camps]="camps"
                               (published)="eventManager($event, 'reload')"
                               (finished)="eventManager($event, 'finish')"
                               (close)="eventManager($event, 'close')"
                               (backEmitter)="eventManager($event, 'selectCamps')">
    </instance-sidebar-settings>
</div>
