import { Question } from '../question';
import { HttpClient } from '@angular/common/http';

export class OpenWriteQuestion extends Question {
  param: OpenWriteQuestionParameters; //(stringify);
  constructor(question: any, http: HttpClient) {
    super(question, http);
    this.extends(question);
  }

  extends(question: any): OpenWriteQuestion {
    Object.assign(this, question);
    // this.param = JSON.parse(question.param);
    return this;
  }
}

export interface OpenWriteQuestionParameters {
  minLength: number; //TO DO;
}
