<div class="overlay" [ngClass]="{'active': active}"></div>

<aside class="m-sidebar sidebar sidebar--modal sidebar-lti" [ngClass]="{'active': active}" (appSidebar)="closeSidebar()">
    <div class="sidebar__container">
        <div class="sidebar__head">
            <div class="sidebar__description">
                <h1 class="sidebar__title">
                    {{ (action === 'edit' ? 'studio.modal-activity.lti-update' : 'studio.modal-activity.lti-description') | translate }}
                </h1>
            </div>

            <a class="sidebar__close" (click)="closeSidebar()">
                <i class="icon icon--close"></i>
            </a>
        </div>

        <div class="m-content">
            <div>
                <h5 class="heading" *ngIf="action === 'edit' && sectionContent">
                    <live-editor (onUpdate)="updateSectionContent()" [(inputData)]="sectionContent.value.title" [placeholder]="('studio.course.section-content.external_link-hint' | translate)"></live-editor>
                </h5>

                <p class="" *ngIf="action === 'edit' && sectionContent">
                    <live-editor (onUpdate)="updateSectionContent()" [(inputData)]="sectionContent.value.description" [placeholder]="('words.description' | translate)"></live-editor>
                </p>

                <div class="input">
                    <label class="input__label">{{ 'studio.aside-lti.url' | translate }}</label>

                    <input autocomplete="off" name="input" class="input__text" type="text" [(ngModel)]="data.url" [debounce]="800" (onDebounce)="getTitle()">
                </div>

                <div class="input">
                    <label class="input__label">{{ 'studio.aside-lti.title' | translate }}</label>
                    <input autocomplete="off" name="input" class="input__text" type="text" [(ngModel)]="data.name">
                </div>

                <!--                <div class="input input-media">-->
                <!--                    <label class="slide__label">{{ 'studio.integrations.link-picture' | translate }}</label>-->
                <!--                    <media-manager (updateMedia)="onUpdatedMedia($event)"></media-manager>-->
                <!--                    <img *ngIf="lti?.picture && !uploadPicture" [src]="lti?.picture">-->
                <!--                </div>-->

                <div class="input">
                    <label class="input__label">{{ 'studio.aside-lti.redirect' | translate }}</label>

                    <input autocomplete="off" name="input" class="input__text" type="text" [(ngModel)]="data.redirectUrl">
                </div>

                <div class="input form-select">
                    <label class="input__label">{{ 'studio.aside-lti.mode' | translate }}</label>

                    <div class="select">
                        <select class="select__field" name="select" id="mode" [(ngModel)]="data.mode">
                            <option value="embed">{{ 'studio.aside-lti.mode-embed' | translate }}</option>
                            <option value="blank">{{ 'studio.aside-lti.mode-window' | translate }}</option>
                        </select>
                    </div>
                </div>

                <div class="input">
                    <label class="input__label">{{ 'studio.aside-lti.private-key' | translate }}</label>
                    <input autocomplete="off" name="input" class="input__text" type="text" [(ngModel)]="data.key">
                </div>

                <div class="input">
                    <label class="input__label">{{ 'studio.aside-lti.secret-key' | translate }}</label>
                    <input autocomplete="off" name="input" class="input__text" type="text" [(ngModel)]="data.secret">
                </div>

                <div class="input">
                    <label class="input">
                        <input type="checkbox" class="input-checkbox" [checked]="data.anonymize" (change)="updateAnonymise()">
                        <span class="choice__text">{{ 'studio.aside-lti.anonymise' | translate }}</span>
                    </label>
                </div>
            </div>
        </div>
    </div>

    <div class="m-footer">
        <a class="button button--small button--color" (click)="save(modalConfirmation)">
            {{ 'words.save' | translate }}
        </a>
    </div>
</aside>

<ng-template #modalConfirmation let-modal>
    <div class="modal-header">
        <span></span>
        <button type="button" class="close" aria-label="Close" (click)="modal.close()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div style="background-color: #FFFFFF; padding: 5rem; text-align: center;">
            {{ 'studio.lti.modal.confirm' | translate }}

            <div class="actions" style="padding-top: 2rem;">
                <button class="btn btn_color_red" (click)="confirm()">
                    {{ 'words.yes' | translate }}
                </button>

                <button class="btn btn_color_grey" (click)="modal.close()">
                    {{ 'words.no' | translate }}
                </button>
            </div>
        </div>
    </div>
</ng-template>
