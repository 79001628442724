<aside class="sidebar sidebar--modal" [ngClass]="active ? 'active' : ''" (appSidebar)="closeSidebar()">
    <div class="sidebar__container">
        <div class="sidebar__head">
            <div class="sidebar__description">
                <h1 class="sidebar__title">{{ 'studio.folder.select-folder' | translate }}</h1>
                <p class="sidebar__subtitle">
                    {{ 'studio.folder.move-to.' + object.type.toLowerCase() | translate }} <strong>{{ (object.item.title.length > 40) ? (object.item.title | slice:0:40) + '...' : object.item.title }}</strong> {{ 'studio.folder.move-to.other-folder' | translate }}
                </p>
            </div>

            <a class="sidebar__close" (click)="closeSidebar()">
                <i class="icon icon--close"></i>
            </a>
        </div>

        <div class="grid between middle">
            <div class="search">
                <label class="search__label">
                    <i class="search__icon icon icon--search"></i>
                </label>

                <input class="search__input" type="text" [placeholder]="'words.search' | translate" [(ngModel)]="query" [debounce]="500" (onDebounce)="search()">
                <i class="icon icon--close" (click)="resetSearch()"></i>
            </div>

            <a class="sidebar__btn-action" (click)="dropFolder()" *ngIf="(object.type === 'template' && object.item.folderId) || (object.type === 'folder' && object.item.parentId)">
                <i class="icon icon--folder"></i>
                {{ 'studio.folder.drop' | translate }}
            </a>
        </div>

        <msc-subfolder [folders]="folders" [object]="object"></msc-subfolder>

        <div *ngIf="!folders" style="text-align: center;">
            <div class="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>

        <div class="ps__rail-x" style="left: 0px; top: 0px;">
            <div class="ps__thumb-x" tabindex="0" style="left: 0px; width: 0px;"></div>
        </div>

        <div class="ps__rail-y" style="top: 0px; height: 562px; right: 0px;">
            <div class="ps__thumb-y" tabindex="0" style="top: 0px; height: 363px;"></div>
        </div>
    </div>

    <footer class="sidebar__footer">
        <div class="sidebar__btn">
            <div class="sidebar__btn-right grid">
                <a class="button button--small" (click)="closeSidebar()">
                    {{ 'studio.common.button.cancel' | translate }}
                </a>

                <a class="button button--small button--color" (click)="moveTo()">
                    {{ 'words.move' | translate }}
                </a>
            </div>
        </div>
    </footer>
</aside>

<style>
    .lds-ring div {
        border-color: #ADCADF transparent transparent transparent;
    }
</style>