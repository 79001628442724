<div class="modal-header">
    <span>{{ 'studio.modal.data-quality.title' | translate }}</span>
</div>

<div class="modal-body">
    <div class="section-info">
        <div class="info-left">
            <p [innerHTML]="'studio.modal.data-quality.missing-fields' | translate: {value: remainingCoursesCounter }"></p>
            <msc-common-search #searchInput [placeholder]="'common.search' | translate" (searchEvent)="onSearch($event)"></msc-common-search>
            <div class="filters-container">
                <button class="filters-button" [ngClass]="{'active': filters.isVisible || filters.isActive}" (click)="onToggleFilters('isVisible')">
                    <i class="new-icon icon-options-2-outline"></i>
                    <span>{{ 'words.filters' | translate }}</span>
                </button>

                <ng-container *ngIf="filters.isActive">
                    <button class="filters-button" [ngClass]="{'active': filters.isActive}" (click)="onFiltersReset()">
                        <i class="new-icon icon-refresh-outline"></i>
                        <span>{{ 'words.reset-filters' | translate }}</span>
                    </button>
                </ng-container>
                
                <div class="filters-options" [ngClass]="{'active': filters.isVisible}">
                    <div class="filters-item">
                        <label class="filter-item-label">{{ 'words.language' | translate }}</label>
                        <msc-common-select [data]="meta.languages" type="multi">
                            <ng-template #buttonTemplate let-data="data">
                                <span>{{ 'words.languages' | translate}}</span>
                            </ng-template>
                            <ng-template #optionTemplate let-datum="datum">
                                <label class="select-option">
                                    <span>{{ datum.label }}</span>
                                    <msc-common-checkbox type="tick" [value]="datum.isSelected" (changeEvent)="onSelectOptionFilter(datum, 'languages')"></msc-common-checkbox>
                                </label>
                            </ng-template>
                        </msc-common-select>
                    </div>

                    <div class="filters-separator"></div>

                    <div class="filters-item">
                        <label class="filter-item-label">{{ 'words.type' | translate }}</label>
                        <msc-common-select [data]="meta.types" type="multi">
                            <ng-template #buttonTemplate let-data="data">
                                <span>{{ 'words.types' | translate}}</span>
                            </ng-template>
                            <ng-template #optionTemplate let-datum="datum">
                                <label class="select-option">
                                    <span>{{ datum.label }}</span>
                                    <msc-common-checkbox type="tick" [value]="datum.isSelected" (changeEvent)="onSelectOptionFilter(datum, 'types')"></msc-common-checkbox>
                                </label>
                            </ng-template>
                        </msc-common-select>
                    </div>

                    <div class="filters-actions">
                        <button class="btn" (click)="onToggleFilters('isVisible', false)">{{ 'words.cancel' | translate}}</button>
                        <button class="btn" (click)="onFilter()">{{ 'words.apply' | translate }}</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="info-right">
            <ng-container *ngIf="selectedCoursesCounter">
                <span [innerHTML]="'studio.modal.data-quality.items-selected' | translate: {value: selectedCoursesCounter}"></span>
                <msc-common-select [data]="availableCompanies">
                    <ng-template #buttonTemplate let-data="data">
                        <button class="select-main">
                            <span>{{ 'studio.modal.data-quality.placeholder.assign-ownership' | translate}}</span>                
                        </button>
                    </ng-template>
                    <ng-template #optionTemplate let-datum="datum">
                        <button class="select-option" (click)="onSelectCompanyMultiple(datum)">
                            <span>{{ datum.label }}</span>
                        </button>
                    </ng-template>
                </msc-common-select>
            </ng-container>
        </div>
    </div>

    <div class="section-table">
        <ng-container *ngIf="!loading">
        <msc-common-table [columns]="columns" [rows]="rows">
            <ng-template #thTemplate let-datum="datum">
                <msc-common-th-checkbox [isPartial]="thCheckboxIsPartial" [value]="thCheckboxValue" (changeEvent)="onModify($event)"></msc-common-th-checkbox>
            </ng-template>
            <ng-template #thTemplate let-datum="datum">
                <span></span>
            </ng-template>
            <ng-template #thTemplate let-datum="datum">
                <msc-common-th-sort [label]="datum.label" [name]="id" (sortEvent)="onSort(datum.key, $event)"></msc-common-th-sort>
            </ng-template>
            <ng-template #thTemplate let-datum="datum">
                <msc-common-th-sort [label]="datum.label" [name]="id" (sortEvent)="onSort(datum.key, $event)"></msc-common-th-sort>
            </ng-template>
            <ng-template #thTemplate let-datum="datum">
                <span>{{ datum.label }}</span>
            </ng-template>
            <ng-template #thTemplate let-datum="datum">
                <span>{{ datum.label }}</span>
            </ng-template>
            <ng-template #thTemplate let-datum="datum">
                <span></span>
            </ng-template>
    
            <ng-template #tdTemplate let-datum="datum">
                <msc-common-checkbox type="tick" [value]="datum.isSelected" (changeEvent)="onChange($event, datum)"></msc-common-checkbox>
            </ng-template>
            <ng-template #tdTemplate let-datum="datum">
                <div class="course-icon">
                    <i class="icon icon--{{datum.icon}}"></i>
                </div>
            </ng-template>
            <ng-template #tdTemplate let-datum="datum">
                <span class="course-title">{{ datum.title }}</span>
                <!-- <span class="course-description">{{ datum.description }}</span> -->
            </ng-template>
            <ng-template #tdTemplate let-datum="datum">
                <span>{{ datum.type }}</span>
            </ng-template>
            <ng-template #tdTemplate let-datum="datum">
                <span [ngClass]="{'tooltip-v2 tooltip-v2--large': datum.skills?.length > 25}" [attr.data-tooltip]="datum.skills">{{ datum.skills | truncate: '25' }}</span>
            </ng-template>
            <ng-template #tdTemplate let-datum="datum">
                <msc-common-select [data]="datum.selectOptions" class="ownership-select">
                    <ng-template #buttonTemplate let-data="data">
                        <button class="select-main">
                            <ng-container *ngIf="data?.length; else emptyTemplate">
                                <span><i class="templab-icon templab-icon-checkmark-circle-3"></i> {{data[0].label}}</span>
                            </ng-container>
                            <ng-template #emptyTemplate>
                                <span><i class="templab-icon templab-icon-alert-circle-1"></i> {{ 'studio.modal.data-quality.placeholder.company-not-set' | translate }}</span>
                            </ng-template>                        
                        </button>
                    </ng-template>
                    <ng-template #optionTemplate let-optionDatum="datum">
                        <button class="select-option" (click)="onSelectCompanySingle(datum, optionDatum)">
                            <span>{{ optionDatum.label | translate}}</span>
                        </button>
                    </ng-template>
                </msc-common-select>
            </ng-template>
            <ng-template #tdTemplate let-datum="datum">
                <i class="templab-icon templab-icon-eye" (click)="onClickCourseDetail(datum)"></i>
            </ng-template>
        </msc-common-table>
        </ng-container>
        
        <msc-loader [loading]="loading"></msc-loader>
    </div>
</div>

<div class="modal-footer">
    <a (click)="onModalDismiss()">{{ 'studio.modal.data-quality.button.ask-again' | translate }}</a>
     <ng-container *ngIf="remainingCoursesCounter === rows.length; else assignOwnershipTemplate">
        <button class="btn" disabled>{{ 'studio.modal.data-quality.button.items-remaining' | translate: {value: remainingCoursesCounter} }}</button>
    </ng-container>
    <ng-template #assignOwnershipTemplate>
        <button class="btn" (click)="onAssign()">{{ 'studio.modal.data-quality.button.assign-ownership' | translate: {value: rows.length - remainingCoursesCounter} }}</button>
    </ng-template>
</div>
