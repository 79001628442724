<div class="add-library">
    <div class="add-media">
        <div class="row">
            <div class="columns small-12">
                <div class="add-media__container embed-media" style="cursor:initial;">
                    <div class="interior embed">
                        <div style="width: 100%;">
                            <div style="position: relative; padding-bottom: 56.25%; padding-top: 0; height: 0;">
                                <iframe frameborder="0"
                                        width="1200px"
                                        height="675px"
                                        style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"
                                        [src]="getGeniallyUrl()"
                                        type="text/html"
                                        allowscriptaccess="always"
                                        allowfullscreen="true"
                                        scrolling="yes"
                                        allownetworking="all">
                                </iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
