import { Component, Input, OnInit } from '@angular/core';
import { Event } from '@stuplay'
import { throwError } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
    selector: 'app-event-presentation',
    templateUrl: './event-presentation.component.html'
})
export class EventPresentationComponent implements OnInit {
    @Input() event: Event;
    private error: any;

    constructor() { }

    ngOnInit() { }

    addEventSession(): void {
        this.event.addEventSession().subscribe();
        this.updateEvent();
    }

    updateEvent(): void {
        this.event.update()
            .pipe(switchMap(() => throwError({ error: 'error' })))
            .subscribe();
    }
}
