<div class="modal-header">
    <span>{{'word.library' | translate}}</span>
    <button type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <div class="library-container">
        <div class="library-sidebar">
            <div class="library-sidebar-item">
                <h4 class="library-sidebar-title">{{ 'studio.re-use.media-context' | translate }}</h4>
                <div class="library-filters">
                    <div class="filter" [ngClass]="{'active': isSelected('video')}" (click)="setTab('video'); filterMedias('video')" *ngIf="!onlyPictures">
                        <img src="https://static.myskillcamp.com/images/icon/icon-camp/icon_library_video.svg"> {{ 'studio.re-use.video-context' | translate }}
                    </div>

                    <div class="filter"  [ngClass]="{'active': isSelected('image')}"  (click)="setTab('image'); filterMedias('image')">
                        <img src="https://static.myskillcamp.com/images/icon/icon-camp/icon_library_bibliotheque.svg"> {{ 'studio.media.images' | translate }}
                    </div>

                    <div class="filter" [ngClass]="{'active': isSelected('document')}"  (click)="setTab('document'); filterMedias('document')" *ngIf="!onlyPictures">
                        <img src="https://static.myskillcamp.com/images/icon/icon-camp/icon_library_fichiers.svg"> {{ 'studio.media.docs' | translate }}
                    </div>

                    <div class="filter" [ngClass]="{'active': isSelected('sound')}"  (click)="setTab('sound'); filterMedias('sound')" *ngIf="!onlyPictures">
                        <img src="https://static.myskillcamp.com/images/icon/icon-camp/icon_library_sound.svg"> {{'studio.media.sounds' | translate}}
                    </div>

                    <div class="filter" [ngClass]="{'active': isSelected('embed')}"  (click)="setTab('embed'); filterMedias('embed')" *ngIf="!onlyPictures">
                        <img src="https://static.myskillcamp.com/images/icon/icon-camp/icon_library_interactivecontent.svg"> {{ 'studio.media.embed' | translate }}
                    </div>
                </div>
            </div>

            <div class="library-sidebar-item">
                <h4 class="library-sidebar-title">{{ 'words.add' | translate }}</h4>
                <div class="library-filters">
                    <div class="filter" [ngClass]="{'active': isSelected('import')}" (click)="setTab('import')">
                        <img src="https://static.myskillcamp.com/images/icon/icon-camp/icon_library_upload.svg"> {{ 'studio.media.upload.title' | translate }}
                    </div>

                    <div class="filter" [ngClass]="{'active': isSelected('databank')}" (click)="setTab('databank')">
                        <img src="https://static.myskillcamp.com/images/icon/icon-camp/icon_library_photostock.svg"> {{ 'studio.library.image-bank' | translate }}
                    </div>

                    <div class="filter" [ngClass]="{'active': isSelected('integration')}" (click)="setTab('integration')" *ngIf="!onlyPictures">
                        <img src="https://static.myskillcamp.com/images/icon/icon-camp/icon_library_embed.svg"> {{ 'studio.media.embed' | translate }}
                    </div>

                    <div class="filter" [ngClass]="{'active': isSelected('iframe')}" (click)="setTab('iframe')" *ngIf="!onlyPictures">
                        <img src="https://static.myskillcamp.com/images/icon/icon-camp/icon_code.svg"> {{ 'studio.media.iframe' | translate }}
                    </div>
                </div>
            </div>

            <div class="library-sidebar-item">
                <h4 class="library-sidebar-title">{{ 'words.discover' | translate }}</h4>
                <div class="library-filters">
                    <div class="filter" [ngClass]="{'active': isSelected('toolbox')}" (click)="setTab('toolbox')">
                        <img src="https://static.myskillcamp.com/images/guidance/toolbox.svg"> {{ 'studio.media.toolbox' | translate }}
                    </div>
                </div>
            </div>
        </div>

        <div class="library-content">
            <div class="library-content-library">
                <div class="library-content-library-header" *ngIf="tab !== 'iframe' && tab !== 'integration'">
                    <h2 class="header__title">
                        <span class="header__supertitle">{{'studio.library.manage-medias' | translate}}</span>
                        {{'studio.library.manage-medias-' + tab| translate}}
                    </h2>
                </div>

                <div *ngIf="tab === 'video' || tab === 'image' || tab === 'document'  || tab === 'sound' || tab === 'embed'">
                    <library-folders [medias]="medias"
                                     [currentUser]="currentUser"
                                     [pagination]="pagination"
                                     [toUpload]="toUpload"
                                     (updateMedia)="onUpdatedMedia($event)"
                                     (showItemPreview)="showPreview($event)">
                    </library-folders>

                    <library-item-preview *ngIf="showItemPreview"
                                          [media]="itemToPreview"
                                          [currentUser]="currentUser"
                                          [toUpload]="toUpload"
                                          [availableArchive]="false"
                                          (close)="closePreview()"
                                          (updateMedia)="onUpdatedMedia($event)">
                    </library-item-preview>
                </div>
            </div>

            <div *ngIf="tab === 'import'" style="margin: 2rem 0 0;">
                <library-upload [toUpload]="toUpload" [onlyPictures]="onlyPictures" (updateMedia)="onUpdatedMedia($event)" (redirect)="redirect($event)"></library-upload>
            </div>

            <div *ngIf="tab === 'databank'" style="margin: 2rem 0 0;">
                <library-bank [toUpload]="toUpload" (updateMedia)="onUpdatedMedia($event)" (redirect)="redirect($event)"></library-bank>
            </div>

            <div *ngIf="tab === 'integration'" style="margin: 2rem 0 0;">
                <library-embed [toUpload]="toUpload" [importType]="'url'" (updateMedia)="onUpdatedMedia($event)" (redirect)="redirect($event)"></library-embed>
            </div>

            <div *ngIf="tab === 'iframe'" style="margin: 2rem 0 0;">
                <library-embed [toUpload]="toUpload" [importType]="'iframe'" (updateMedia)="onUpdatedMedia($event)" (redirect)="redirect($event)"></library-embed>
            </div>

            <div *ngIf="tab === 'toolbox'" style="margin: 2rem 0 0;">
                <library-toolbox></library-toolbox>
            </div>
        </div>
    </div>
</div>

<style media="screen">
    .library-container {
        display: flex;
        align-items: flex-start;
        padding: 20px;
    }

    .library-sidebar {
        width: 25%;
    }

    .library-sidebar-item {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
    }

    .library-content {
        flex-grow: 1;
    }

    .library-sidebar-title {
        font-family: GT-Walsheim-Medium, arial, sans-serif;
        font-size: 28px;
        margin-bottom: 1.375rem
    }

    .library-filters {
        display: flex;
        flex-direction: column;
    }

    .filter {
        padding: 0.875rem;
        transition: .2s ease;
        cursor: pointer;
        border-radius: 2px;
        font-family: GT-Walsheim-Medium,arial,sans-serif;
    }

    .filter img {
        height: 20px;
        width: 20px;
    }

    .filter:hover {
        background-color: #f4f7fb;
    }

    .library-content-library {

    }

    .library-content-library-header {

    }
</style>
