<div *ngIf="type === 'Project'">
    <div class="settings-block">
        <div class="settings-block__title">
            <label>{{ 'studio.sidebar-settings.type' | translate }}</label>
        </div>

        <div class="settings-block_details">
            <div class="row">
                <div class="columns small-12">
                    <select [(ngModel)]="object.type" (ngModelChange)="updateSettings()" (select)="object.type">
                        <option value="document">{{ 'studio.sidebar-settings.type.document' | translate }}</option>
                        <option value="cheatsheet">{{ 'studio.sidebar-settings.type.cheatsheet' | translate }}</option>
                    </select>
                </div>
            </div>
        </div>
    </div>

    <div class="settings-block">
        <div class="settings-block__title" [ngClass]="{'active': sectionContent.isScorable}">
            <label>{{ 'studio.sidebar-settings.selfassessment.is-scorable' | translate }}
                <span class="switch switch_height_small" [ngClass]="{'switch_active': sectionContent.isScorable}">
                    <input type="checkbox" [(ngModel)]="sectionContent.isScorable" (ngModelChange)="updateSettings()">
                </span>
            </label>
        </div>
    </div>
</div>

<div *ngIf="type === 'SelfAssessment'">
    <div class="settings-block">
        <div class="settings-block__title" [ngClass]="{'active': (object.type === 'text' || object.type === 'both')}">
            <label>{{ 'studio.sidebar-settings.fillin.mode.input' | translate }}
                <span class="switch switch_height_small" [ngClass]="{'switch_active': selfAssessmentSettings.text}">
                    <input type="checkbox" [(ngModel)]="selfAssessmentSettings.text"
                        (ngModelChange)="updateSelfAssessment()">
                </span>
            </label>
        </div>
    </div>

    <div class="settings-block">
        <div class="settings-block__title" [ngClass]="{'active': (object.type === 'media' || object.type === 'both')}">
            <label>{{ 'studio.media.upload.title' | translate }}
                <span class="switch switch_height_small" [ngClass]="{'switch_active': selfAssessmentSettings.media}">
                    <input type="checkbox" [(ngModel)]="selfAssessmentSettings.media"
                        (ngModelChange)="updateSelfAssessment()">
                </span>
            </label>
        </div>
    </div>

    <div class="settings-block">
        <div class="settings-block__title" [ngClass]="{'active': object.isScorable}">
            <label>{{ 'studio.sidebar-settings.selfassessment.is-scorable' | translate }}
                <span class="switch switch_height_small" [ngClass]="{'switch_active': object.isScorable}">
                    <input type="checkbox" [(ngModel)]="object.isScorable" (ngModelChange)="updateSelfAssessment()">
                </span>
            </label>
        </div>
    </div>

    <div class="settings-block">
        <div class="settings-block__title" [ngClass]="{'active': object.allowRevaluation}">
            <label>{{ 'studio.sidebar-settings.selfassessment.allow-revaluation' | translate }}
                <span class="switch switch_height_small" [ngClass]="{'switch_active': object.allowRevaluation}">
                    <input type="checkbox" [(ngModel)]="object.allowRevaluation"
                        (ngModelChange)="updateSelfAssessment()">
                </span>
            </label>
        </div>
    </div>
</div>
<div *ngIf="type === 'Exercise'">
    <div class="settings-block">
        <div class="settings-block__title" [ngClass]="{'active': !sectionContent.isScorable}">
            <label>{{ 'words.training-mode' | translate }}
                <span class="switch switch_height_small" [ngClass]="{'switch_active': !sectionContent.isScorable}">
                    <input type="checkbox" [(ngModel)]="sectionContent.isScorable" (ngModelChange)="updateSettings()">
                </span>
            </label>
        </div>
    </div>
</div>

<div *ngIf="type === 'Media'">
    <div class="settings-block">
        <div class="settings-block__title" [ngClass]="{'active': sectionContent.isDownloadable}">
            <label>{{ 'studio.sidebar-settings.media.is-downloadable' | translate }}
                <span class="switch switch_height_small" [ngClass]="{'switch_active': sectionContent.isDownloadable}">
                    <input type="checkbox" [(ngModel)]="sectionContent.isDownloadable"
                        (ngModelChange)="updateSettings()">
                </span>
            </label>
        </div>
    </div>
</div>

<div *ngIf="type === 'Event'">
    <div class="settings-block">
        <div class="settings-block__title">
            <label>{{ 'studio.sidebar-settings.event.type' | translate }}</label>
        </div>

        <div class="settings-block_details">
            <div class="row">
                <div class="columns small-12">
                    <select [(ngModel)]="object.type" (ngModelChange)="updateSettings()" (select)="object.type">
                        <option value="place">{{ 'studio.sidebar-settings.event.type.place' | translate }}</option>
                        <option value="visio">{{ 'studio.sidebar-settings.event.type.visio' | translate }}</option>
                        <option value="virtual-class" *ngIf="enable.virtualClass">{{
                            'studio.sidebar-settings.event.type.virtual-class' | translate }}</option>
                    </select>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="type === 'ScormModule'">
    <div class="settings-block">
        <div class="settings-block__title">
            <label>{{ 'studio.sidebar-settings.scorm.display.type' | translate }}</label>
        </div>

        <div class="settings-block_details">
            <div class="row">
                <div class="columns small-12">
                    <select [(ngModel)]="object.displayType" (ngModelChange)="updateCourse()"
                        (select)="object.displayType">
                        <option value="embedded">{{ 'studio.sidebar-settings.scorm.display.type.embed' | translate }}
                        </option>
                        <option value="new_tab">{{ 'studio.sidebar-settings.scorm.display.type.window' | translate }}
                        </option>
                    </select>
                </div>
            </div>
        </div>
    </div>
</div>