import { Injectable } from '@angular/core';
import { DataHelper } from '../helpers';
import { add, find } from '../functions/html.function';
import { environment } from 'src/environments/environment';

@Injectable()
export class ScriptInjectorService {

    constructor() {}

    freshchat(content: string, params: any): void {
        const html = DataHelper.split(content, '<script');
        const url = DataHelper.splitBetween(content, 'https', '"></script>');
        const script = content.replace(html, '')
            .replace(`<script src="${url}"></script>`, '')
            .replace('<script>', '')
            .replace('</script>', '')
            .replace('<#externalId>', params.email)
            .replace('<#restoreId>', params.restoreId)
            .trim();
        const timeOut = `setTimeout(() => {${script}}, 2500);`;

        find('#custom-app').innerHTML += html;
        add('#custom-app', 'script', { type: 'src', value: url });
        add('#custom-app', 'script', null, { type: 'text', value: timeOut });
    }

    /**
     * Inject appcues inside HTML
     */
    appcues(): void {
        const url = '//fast.appcues.com/52146.js';
        add('#custom-app', 'script', { type: 'src', value: url });
    }

    /**
     * Inject beamer inside HTML
     * https://www.getbeamer.com/docs/#parameters
     * @param {number} productId - Product identification number
     * @param {any} me - Current user
     * @param {string} filters - Current filters
     */
    beamer(productId: number, me: any, companyId, filters: string): void {
        const script = `var beamer_config = {
            product_id: '${productId}',
            language: 'en',
            user_email: '${me.id}@myskillcamp.com',
            user_id: '${me.id}',
            company_id: '${companyId}',
            filter: '${filters}',
            alert: false
        }`;
        add('#custom-app', 'script', null, { type: 'text', value: script });

        const url = 'https://app.getbeamer.com/js/beamer-embed.js';
        add('#custom-app', 'script', { type: 'src', value: url });
    }
}
