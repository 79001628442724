import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { Instance, CourseProvider } from '@stuplay';
import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from '../../../../utils/components/toast/toast.service';
import PerfectScrollbar from 'perfect-scrollbar';
import { StorageService } from '../../../../utils/services/storage.service';
import { LanguageProvider } from 'src/app/providers';
import { TranslationService } from 'src/app/utils/services/translation.service';
import { environment } from 'src/environments/environment';

const HOUR_MINUTE_REGEX = /^([0-1][0-9]|2[0-3]):([0-5][0-9])$/;

@Component({
    selector: 'instance-sidebar-create',
    templateUrl: './instance-sidebar-create.component.html',
    styleUrls: ['./instance-sidebar-create.component.less'],
    providers: [DatePipe],
})
export class InstanceSidebarCreateComponent implements OnInit {
    @Output() add: EventEmitter<any> = new EventEmitter;
    @Output() update: EventEmitter<any> = new EventEmitter;
    @Output() campsSelection: EventEmitter<any> = new EventEmitter;
    @Output() close: EventEmitter<any> = new EventEmitter;
    @Input() template: any = '';
    @Input() instance: any[] = [];

    public company: any;
    public data: any = { languageId: null, discussion_mode: 'private', trainers: [], company_id: null };
    public instancesList: Instance[] = [];
    public eventsList: any[];
    public pagination: any = {};
    public defaultEventsList: any[];
    public datesList: any[] = [];
    public hoursList: any[] = [];
    public active: boolean = false;
    public datePickerActive: boolean[][];
    public canActive: boolean = true;
    public loadEvents: boolean = true;
    public formErrors: any;
    public displays: any = {};
    public query: string;
    public trainers: any = [];
    public trainersSelected: any = [];
    public type: string = 'create';
    public loading: boolean = false;
    public languages: any;
    public filteredLanguages: any[] = [];
    private selectedLanguage: any;
    private loadingTrainers: boolean = false;

    constructor(
        private router: Router,
        private courseProvider: CourseProvider,
        private http: HttpClient,
        private toastService: ToastService,
        private translate: TranslationService,
        private storageService: StorageService,
        private languageProvider: LanguageProvider,
        private modalService: NgbModal,
        private datePipe: DatePipe,
    ) { }

    ngOnInit() {
        this.company = this.storageService.get('company');
        this.activeEffect();

        this.getTrainers();

        if (this.instance && this.instance.length > 0) {
            this.processLoadInstance();
        } else {
            this.reset();
        }

        this.initDisplays();

        const ps = new PerfectScrollbar('.sidebar__container');

        this.languageProvider.get('content').subscribe((resp) => {
            this.languages = resp;
            this.filteredLanguages = this.languages;
        });

        this.selectedLanguage = this.instance && this.instance.length && this.instance[0].language ? this.instance[0].language : this.template.language;
        this.data.languageId = this.instance && this.instance.length && this.instance[0].language ? this.instance[0].language.id : this.template.language.id;
        this.formErrors = {
            startAt: false,
            endAt: false
        }
    }

    openModal(content: any) {
        this.modalService.open(content);
    }

    displayed(key: string): void {
        this.displays[key] = !this.displays[key];
    }

    selectLanguage(language: any): void {
        this.selectedLanguage = language;
        this.data.languageId = language.id;
    }

    filterLanguages(event: string) {
        if (typeof event === 'string') {
            this.filteredLanguages = this.languages.filter((lang: any) => lang.name ? lang.name.toLowerCase().startsWith(event.toLowerCase()) : false);
        }
    }

    setData(key: string, value: string): void {
        this.data[key] = value;
    }

    reset(): void {
        const date = moment();
        this.data.languageId = this.instance && this.instance.length && this.instance[0].language ? this.instance[0].language.id : this.template.language.id;
        this.data.internal_name = this.translate.instant('words.instance') + ' ' + this.translate.instant('month.' + date.format('MMMM')).toLowerCase() + ' ' + date.format('YYYY');
        this.courseProvider.getTemplateInstances(this.template.id, 'events').subscribe((data) => {
            if (data.events) {
                this.eventsList = data.events;
                this.defaultEventsList = data.events;
                this.convertDates();
                this.initDatePickerArray();
            }
            this.loadEvents = false;
        });
    }

    initDisplays(): void {
        this.displays = {
            events: true,
            settings: false,
            language: false,
            visibility: false,
            trainers: false
        };
    }

    getTrainers(): void {
        const url = `${environment.envVar.API_URL}/search/trainers` + (this.query ? '?q=' + this.query : '');
        this.http.get(url).subscribe((data: any) => {
            this.trainers = data.accounts;
            this.pagination = data.pagination;
        });
    }

    loadMoreTrainers(ev): void {
        ev.stopPropagation();
        this.loadingTrainers = true;

        const pageParam = `page=${this.pagination.currentPage + 1}`;
        const qParam = this.query ? '&?q=' + this.query : '';

        this.http.get(`${environment.envVar.API_URL}/search/trainers?${pageParam}${qParam}`).subscribe((data: any) => {
            this.trainers.push(...data.accounts);
            this.pagination = data.pagination;

            this.loadingTrainers = false;
        });
    }

    activeEffect(): void {
        setTimeout(() => {
            this.active = true;
        }, 100);
    }

    processLoadInstance(): void {
        this.type = 'edit';
        this.transformInstance();
        this.courseProvider.getTemplateInstances(this.instance[0].templateId, 'events').subscribe((data) => {
            // this.instancesList = data.instances;
            if (data.events) {
                this.defaultEventsList = data.events;
            }
        });

        this.courseProvider.getInstance(this.instance[0].templateId, this.instance[0].id, 'template,events').subscribe((data) => {
            this.template = data.template;
            if (data.events) {
                this.eventsList = data.events;
                this.convertDates();
                this.initDatePickerArray();
            }
            this.loadEvents = false;
        });
    }

    transformInstance(): void {
        this.trainersSelected = [];
        this.data.id = this.instance[0].id;
        this.data.discussion_mode = this.instance[0].settings.discussionMode;
        this.data.languageId = this.instance && this.instance.length && this.instance[0].language ? this.instance[0].language.id : this.template.language.id;
        this.data.internal_name = this.instance[0].settings.name;
        this.data.reference = this.instance[0].settings.reference;
        if (this.instance[0].trainers) {
            for (const trainer of this.instance[0].trainers) {
                this.trainersSelected.push(trainer);
            }
        }
        this.data.users_limit = this.instance[0].settings.usersLimit;
    }

    convertDates(): void {
        this.datesList = [];
        this.hoursList = [];
        this.eventsList.forEach((event) => {
            const startAt = event.startAt.split(' ');
            const endAt = event.endAt.split(' ');

            this.datesList.push({ startAt: startAt[0], endAt: endAt[0] });
            this.hoursList.push(
                {
                    startAt: this.datePipe.transform(new Date(event.startAt), 'HH:mm'),
                    endAt: this.datePipe.transform(new Date(event.endAt), 'HH:mm'),
                }
            );
        });
    }

    transformDates(): void {
        this.eventsList.forEach((event, index) => {
            const startAt = new Date(this.datesList[index].startAt);
            const startHoursMinutes = this.hoursList[index].startAt.split(':');
            startAt.setHours(parseInt(startHoursMinutes[0]));
            startAt.setMinutes(parseInt(startHoursMinutes[1]));
            
            const endAt = new Date(this.datesList[index].endAt);
            const endHoursMinutes = this.hoursList[index].endAt.split(':');
            endAt.setHours(parseInt(endHoursMinutes[0]));
            endAt.setMinutes(parseInt(endHoursMinutes[1]));

            event.startAt = this.datePipe.transform(startAt, 'YYYY-MM-dd H:mm:ss');
            event.endAt = this.datePipe.transform(endAt, 'YYYY-MM-dd H:mm:ss');
            // event.startAt = this.datesList[index].startAt + ' ' + this.hoursList[index].startAt;
            // console.log(event.startAt);
            // const startAt = (this.datePipe.transform(new Date(event.startAt), 'YYYY-MM-dd H:mm') as string).split(' ');
            // let date = startAt[0].split('-').map((r) => parseInt(r));
            // let hour = startAt[1].split(':').map((r) => parseInt(r));

            // console.log(date, hour)
            // event.endAt = this.datesList[index].endAt + ' ' + this.hoursList[index].endAt;
            // const endAt = this.datePipe.transform(new Date(event.endAt), 'YYYY-MM-dd H:mm').split(' ');
            // date = endAt[0].split('-').map((r) => parseInt(r));
            // hour = endAt[1].split(':').map((r) => parseInt(r));
            // event.startAt = this.datePipe.transform(new Date(date[0], date[1] - 1, date[2], hour[0], hour[1]), 'YYYY-MM-dd H:mm:ss');
            // event.endAt = this.datePipe.transform(new Date(date[0], date[1] - 1, date[2], hour[0], hour[1]), 'YYYY-MM-dd H:mm:ss');
        });
    }

    eventDate(index: number, type: string): any {
        if (type === 'startAt') {
            if (moment(this.datesList[index][type]).isAfter(this.datesList[index]['endAt'])) {
                this.datesList[index]['endAt'] = this.datesList[index]['startAt'];
            }
        }

        return moment(this.datesList[index][type]).format('DD/MM/YYYY');
    }

    eventHour(index: number, type: string): any {
        return this.hoursList[index][type];
    }

    updateEventDate(index: number, type: string, date: any): void {
        if (typeof date === 'string') {
            const formattedDate = moment(date, 'DD/MM/YYYY', true);
            if (formattedDate.isValid()) {
                this.datesList[index][type] = formattedDate.format('YYYY-MM-DD');
            }
        } else {
            this.datesList[index][type] = date.format('YYYY-MM-DD');
        }
    }

    updateEventHour(index: number, type: string, hour: any): void {
        if (HOUR_MINUTE_REGEX.test(hour)) {
            this.hoursList[index][type] = hour;
            this.formErrors[type] = false;
            return;
        }

        this.formErrors[type] = true;
    }


    activeDatepicker(index: number, type: string, closeForce?: boolean): void {
        if (closeForce) {
            this.datePickerActive[index][type] = false;
            setTimeout(() => {
                this.canActive = true;
            }, 100);
        } else {
            if (this.canActive) {
                this.datePickerActive[index][type] = true;
                this.canActive = false;
            }
        }
    }

    createInstance(): void {
        if (this.data.internal_name === undefined || this.data.internal_name === '') {
            this.toastService.push('instance-name-required', 'warning');
        }

        if (!this.loading && this.data.internal_name !== undefined && this.data.internal_name !== '') {
            this.loading = true;
            this.transformDates();
            if (!this.data.users_limit) {
                this.data.users_limit = 0;
            }
            this.data.trainers = this.trainersSelected.map((trainer) => {
                return trainer.id;
            });

            this.data.company_id = this.company.id;
            this.courseProvider.createInstance(this.template.id, this.data, this.eventsList).subscribe((instance) => {
                this.instance = [];
                this.instance[0] = instance;
                this.transformInstance();
                this.loading = false;
                this.add.emit(instance);
                this.type = 'summary';

                if (instance.learncubeFailed) {
                    this.toastService.push('virtual-class.not-created', 'error');
                }
            }, () => {
                this.loading = false;
                this.toastService.push('error-occurred', 'error');
            });
        }
    }

    updateInstance(): void {
        this.transformDates();
        this.data.trainers = this.trainersSelected.map((trainer) => {
            return trainer.id;
        });
        this.data.company_id = this.company.id;
        this.courseProvider.updateInstance(this.template.id, this.data, this.eventsList).subscribe((instance) => {
            this.instance[0] = instance;
            this.update.emit(instance);
            this.refreshList(instance);
        }, (err) => {
            this.toastService.push('error-occurred', 'error');
        });
    }

    refreshList(instanceEmit: any): void {
        const instanceIndex = this.instancesList.findIndex((instance) => {
            return instance.id === instanceEmit.id;
        });

        if (instanceIndex !== -1) {
            this.instancesList[instanceIndex] = instanceEmit;
        }
    }

    addTrainer(trainer: any): void {
        const index = this.trainersSelected.findIndex((data) => {
            return data.id === trainer.id;
        });

        if (index === -1) {
            this.trainersSelected.push(trainer);
        } else {
            this.trainersSelected.splice(index, 1);
        }
    }

    isTrainerSelected(id: number): boolean {
        return this.trainersSelected.findIndex((trainer) => {
            return trainer.id === id;
        }) > -1;
    }

    selectCamps(): void {
        this.transformDates();
        this.data.trainers = this.trainersSelected.map((trainer) => {
            return trainer.id;
        });
        this.courseProvider.updateInstance(this.template.id, this.data, this.eventsList).subscribe((instance) => {
            this.storageService.set('templateSelected', this.template);
            this.instance[0] = instance;
            this.update.emit(instance);
            this.campsSelection.emit(this.instance);
        });
    }

    closeSidebarEmit(): void {
        this.active = false;
        setTimeout(() => {
            this.close.emit(true);
        }, 300);
    }

    initDatePickerArray(): void {
        this.datePickerActive = [];
        this.eventsList = this.eventsList.map((event, index) => {
            if (this.company.permissions.companyLockSession && this.instance && this.instance[0]) {
                const realign = new Date(event.startAt);
                const now = new Date();
                const diff = now.getTime() - realign.getTime();

                event.readOnly = this.instance[0].state === "ongoing" && diff > 59 * 1000;
            }
            this.datePickerActive[index] = [];
            this.datePickerActive[index]['startAt'] = false;
            this.datePickerActive[index]['endAt'] = false;
            return event;
        });
    }

    new(): void {
        this.type = 'create';
        this.instance = [];
        this.reset();
        this.initDisplays();
    }

    customize(): void {
        this.router.navigate(['company', this.storageService.get('company').slug, 'course', this.instance[0].slug]);
    }
}
