<div class="library-bank">
	<div>
		<div class="library-bank-header">
			<div class="left">
				<div *ngIf="showList" (click)="showList = false; query = ''; collectionId = ''; page = 1; loadAvailable = true;" class="back">
                    {{"studio.library.back-to-collections" | translate}}
                </div>
			</div>

			<div *ngIf="!showList" class="section__search search">
				<label class="search__label">
					<i class="search__icon icon icon--search"></i>
				</label>

				<input class="search__input" type="text" [placeholder]="'words.search' | translate" [(ngModel)]="query" (keyup.enter)="getPhotos()">
			</div>
		</div>

		<div *ngIf="!showList" class="row collection-list">
			<div class="" *ngIf="!collections" style="width: 100%; text-align: center;">
				<div class="lds-ring">
					<div></div>
					<div></div>
					<div></div>
					<div></div>
				</div>
			</div>

			<div class="columns small-4" *ngFor="let collection of collections">
				<div class="image-bank-miniature" (click)="getCollectionPhotos(collection.id); collectionId = collection.id">
					<div class="images">
						<div class="right">
							<div class="img-container" [ngStyle]="{'background' : 'url(' + collection.preview_photos[0].urls.small + '}', 'background-size': 'cover', 'background-position' : 'center'}">
							</div>
						</div>
						<div class="left">
							<div class="img-container" [ngStyle]="{'background' : 'url(' + collection.preview_photos[1].urls.small + '}', 'background-size': 'cover', 'background-position' : 'center'}">
							</div>
							<div class="img-container" [ngStyle]="{'background' : 'url(' + collection.preview_photos[2].urls.small + '}', 'background-size': 'cover', 'background-position' : 'center'}">
							</div>
						</div>
					</div>
					<div class="info">
						<div class="info__title">{{ collection.title }}</div>

						<div class="info__author">{{ collection.total_photos }} {{ 'studio.library.curated-by' | translate }} {{ collection.user.name }}</div>

						<div class="info__tags" *ngIf="collection.tags.length > 0">
							<div class="tag">{{ collection.tags[0].title }}</div>
							<div class="tag" *ngIf="collection.tags.length > 1">{{ collection.tags[1].title }}</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<image-bank-list *ngIf="showList" [images]="images" (uploadMedia)="uploadMedia($event)"></image-bank-list>

		<div class="" *ngIf="collections && loadAvailable">
			<button (click)="loadMore()" class="show-more">
				<div class="lds-ring" *ngIf="processing">
					<div></div>
					<div></div>
					<div></div>
					<div></div>
				</div>

				<span>{{"words.load-more" | translate}}</span>
			</button>
		</div>
	</div>
</div>


