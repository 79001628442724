import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Journey, ExternalLinkProvider } from '@stuplay';
import { NewKnowledgeProvider } from '../../../../../../providers';
import { EmitterService } from '../../../../../../utils/services/emitter.service';
import { StorageService } from '../../../../../../utils/services/storage.service';
import { HttpHelper } from '../../../../../../utils/helpers';

@Component({
    selector: 'journey-library-link',
    templateUrl: './library-link.component.html',
    styleUrls: ['./library-link.component.less']
})
export class JourneyLibraryLinkComponent implements OnInit {
    @Input() journey: Journey;
    @Input() content: any;
    @Output() onClose: EventEmitter<any> = new EventEmitter();
    @Output() onSave: EventEmitter<any> = new EventEmitter();
    @Output() onRefresh: EventEmitter<any> = new EventEmitter();
    public company: any;
    public wrongURL: boolean = false;
    public extensionLink: any = {};
    public disabled: boolean = false;
    public loading: boolean = false;
    public availableSkills: any[] = [];
    public skillPagination: any;
    public skillSearchName: string;
    public isEditing: boolean = false;
    public currentTab: string = 'form';
    public visitedPages: string[] = [];
    public loadings: any = {
        skills: false,
        newResource: false
    }

    constructor(
        private route: ActivatedRoute,
        private emitterService: EmitterService,
        private storageService: StorageService,
        private externalContentProvider: ExternalLinkProvider,
        private knowledgeProvider: NewKnowledgeProvider,
    ) { }

    ngOnInit(): void {
        this.company = this.storageService.get('company');

        this.extensionLink = {
            id: this.content ? this.content.id : null,
            title: this.content ? this.content.title : '',
            type: this.content ? this.content.type : 'other',
            duration: this.content ? this.content.duration : '',
            durationUnit: this.content ? this.content.durationUnit : 'minutes',
            description: this.content ? this.content.description : '',
            image: (this.content && this.content.additionalData) ? this.content.additionalData.image : '',
            url: this.content ? this.content.url : '',
            companyId: this.storageService.get('company').id,
            lang: this.content ? this.content.language : this.storageService.get('me').lang,
            skills: this.content ? this.content.skills : [],
            removedSkills: [],
            modifiedSkills: [],
            exists: !!this.content
        };

        if (this.content) {
            this.isEditing = true;

            if (this.extensionLink.durationUnit === 'minutes') {
                this.extensionLink.duration = '' + parseInt(this.extensionLink.duration, 10) / 60;
            } else if (this.extensionLink.durationUnit === 'hours') {
                this.extensionLink.duration = '' + parseInt(this.extensionLink.duration, 10) / 60 / 60;
            } else if (this.extensionLink.durationUnit === 'days') {
                this.extensionLink.duration = '' + parseInt(this.extensionLink.duration, 10) / 60 / 60 / 24;
            }
        }

        this.loadAvailableSkills();

        this.emitterService.loadingExternalResource.subscribe((data) => {
            this.loading = data.loading;
            this.wrongURL = data.error || false;
            this.disabled = data.loading || data.error;
        });

        const element: any = document.getElementById('main-content');
        const scroll: any = {
            block: 'start',
            inline: 'nearest',
            behavior: 'smooth'
        };
        element.scrollIntoView(scroll);
    }

    onChangeURL(): void {
        if (this.extensionLink.url.length > 0) {
            this.emitterService.loadingExternalResource.emit({ loading: true });

            const params = {
                url: this.extensionLink.url
            };

            this.externalContentProvider.getPreviewUrl(params).subscribe(data => {
                this.bindExtensionLink(data);

                this.emitterService.loadingExternalResource.emit({ loading: false });
            }, () => {
                this.extensionLink.exists = false;
                this.emitterService.loadingExternalResource.emit({ loading: false, error: true });
            });
        } else {
            this.emitterService.loadingExternalResource.emit({ loading: false });
        }
    }

    bindExtensionLink(extensionLink: any): void {
        this.extensionLink.id = extensionLink.id ? extensionLink.id : null;
        this.extensionLink.title = extensionLink.title;
        this.extensionLink.type = extensionLink.type || this.extensionLink.type;
        this.extensionLink.duration = extensionLink.exists ? (extensionLink.duration / 60).toFixed() : extensionLink.duration;
        this.extensionLink.description = extensionLink.description;
        this.extensionLink.image = extensionLink.image;
        this.extensionLink.lang = extensionLink.lang ? extensionLink.lang : 'fr';
        this.extensionLink.exists = extensionLink.exists;
        this.extensionLink.removedSkills = [];
        this.extensionLink.modifiedSkills = [];
        this.extensionLink.skills = extensionLink.skills ? extensionLink.skills : [];
    }

    updatePicture(media?: any): void {
        this.extensionLink.image = media ? (media.storage === 'ext' ? media.url : media.pictureUrl) : '';
    }

    loadAvailableSkills(pagination?: number): void {
        const params = HttpHelper.cleanParams({
            page: pagination
        });

        this.knowledgeProvider.get(this.storageService.get('company').id, params).subscribe(data => {
            this.availableSkills = this.availableSkills.concat(
                data.skills.filter((skill) => this.extensionLink.skills.findIndex((response) => response.id === skill.id) === -1).filter((response) => response.name)
            );
            this.skillPagination = data.pagination;
            this.loading = false;
        });
    }

    loadMoreSkills(): void {
        this.loading = true;
        this.loadAvailableSkills(this.skillPagination.currentPage + 1);
    }

    addSkill(index: number, level: number): void {
        this.availableSkills[index].level = level;
        this.extensionLink.skills.push(this.availableSkills[index]);

        const toRemoveIndex = this.extensionLink.removedSkills.findIndex(res => res.id === this.availableSkills[index].id);
        if (toRemoveIndex !== -1) {
            this.extensionLink.removedSkills.splice(toRemoveIndex, 1);
        }
        this.availableSkills.splice(index, 1);
    }

    removeSkill(index: number): void {
        this.availableSkills.push(this.extensionLink.skills[index]);
        this.extensionLink.removedSkills.push(this.extensionLink.skills[index]);

        const toRemoveIndex = this.extensionLink.modifiedSkills.findIndex(res => res.id === this.extensionLink.skills[index].id);
        if (toRemoveIndex !== -1) {
            this.extensionLink.modifiedSkills.splice(toRemoveIndex, 1);
        }
        this.extensionLink.skills.splice(index, 1);
    }

    setLevel(level: number, index: number): void {
        this.extensionLink.skills[index].level = level;
        this.extensionLink.modifiedSkills.push(this.extensionLink.skills[index]);
    }

    searchSkill(): void {
        const params = HttpHelper.cleanParams({
            q: this.skillSearchName
        });

        this.knowledgeProvider.get(this.company.slug, params).subscribe((data) => {
            this.availableSkills = data.skills;
            this.skillPagination = data.pagination;
        });
    }

    addNewResource(): any {
        const send = Object.assign({}, this.extensionLink);

        if (send.durationUnit === 'minutes') {
            send.duration *= 60;
        } else if (send.durationUnit === 'hours') {
            send.duration *= 60 * 60;
        } else if (send.durationUnit === 'days') {
            send.duration *= 60 * 60 * 24;
        }

        send['forceCreate'] = !this.isEditing;
        this.loadings.newRessource = true;
        this.externalContentProvider.addExternalResource(send).subscribe(data => {
            this.extensionLink.id = data.id;
            this.postSkills(data.id);
        });
    }

    postSkills(id: number): void {
        const params = {
            companyId: this.storageService.get('company').id,
            skills: []
        };

        this.extensionLink.skills.forEach(skill => {
            params.skills.push({
                skill_id: skill.id,
                level: skill.level
            });
        });

        this.externalContentProvider.addSkillToExternalResource(id, params).subscribe(() => {
            this.loadings.newRessource = false;
            if (this.isEditing) {
                this.onRefresh.emit();
            } else {
                this.onSave.emit(this.extensionLink);
            }
            this.onClose.emit();
        });
    }

    onArianeClick(step: string): void {
        const index = this.visitedPages.indexOf(step);

        if (index !== -1) {
            this.currentTab = step;
        }
    }

    isDisabled(name: string): boolean {
        return !(this.visitedPages.indexOf(name) > -1);
    }

    previous(): void {
        switch (this.currentTab) {
            case 'infos':
                this.currentTab = 'form';
                break;
            case 'skills':
                this.currentTab = 'infos';
                break;
        }
    }

    next(): void {
        switch (this.currentTab) {
            case 'form':
                this.visitedPages.push('form');
                this.currentTab = 'infos';
                break;
            case 'infos':
                this.visitedPages.push('infos');
                this.visitedPages.push('skills');
                this.currentTab = 'skills';
                break;
        }
    }

    saveContent(): void {
        this.addNewResource();
    }

    close(): void {
        this.onClose.emit();
    }
}
