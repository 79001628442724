import { Section } from './section';
import { HttpClient } from '@angular/common/http';
import { Media } from '../media';
import { Collaborator } from './collaborator/collaborator';
import { Account } from '../account/account';
import { Instance } from './instance';
import { Event } from './section/section-content/event';
import { getParams, cleanObject } from '../helpers';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

export class Course {
    accountId: number;
    category?: string;
    collaborators?: Collaborator[];
    company? : any;
    courseCampCount: number;
    courseCampId:any;
    courseFolderId: number;
    courseSubscriptionId?: number;
    createdAt: any;
    description: string;
    duration: number;
    editMode: string;
    folderId?: number;
    hash: string;
    hasSurvey?: boolean;
    id: number;
    isAttachedToACollection: boolean;
    isOrdered?: boolean;
    instancesCount: number;
    instances?: Instance[];
    isTrajectContent?: boolean;
    introMediaId: number;
    language: any;
    languageId: number;
    longDescription?: string;
    media?: Media;
    mode: string;
    permissions?: any[];
    purpose: string;
    realignedAt?: any;
    requirement?: string;
    resume: any;
    schema: string;
    settings?: any;
    shortDescription?: string;
    shared?: boolean;
    sections?: Section[];
    slug: string;
    subscription?: any;
    surveyId: boolean;
    tags?: any;
    target: string;
    templateId?: number;
    theme?: string | null;
    title: string;
    type?: string;
    updatedAt: string;
    weeklyWorkingDuration: number;

    constructor(course: any, private http: HttpClient) {
        this.extends(course);
    }

    extends(course: any): Course {
        Object.assign(this, course);
        if (this.sections && this.sections.length > 0) {
            this.sections = this.sections.map((section) => new Section(section, this.http));
        }
        if (this.collaborators && this.collaborators.length > 0) {
            this.collaborators = this.collaborators.map((collaborator) => new Collaborator(collaborator));
        }
        if (this.instances && this.instances.length > 0) {
            this.instances = this.instances.map((instance) => new Instance(instance));
        }
        return this;
    }

    addMedia(media: Media): Observable<Course> {
        return this.http.post(`${environment.envVar.API_URL}/media/embed`, cleanObject(this)).pipe(map(() => {
            this.media = media;
            return this;
        }));
    }

    deleteMedia(mediaId: number): Observable<any> {
        return this.http.delete(`${environment.envVar.API_URL}/media/${mediaId}`);
    }

    addSection(): Observable<Course> {
      return this.http.post(`${environment.envVar.API_URL}/studio/course/${this.id}/section?includes=section-contents`, {courseId: this.id})
          .pipe(map((res) => {
            this.sections.push(new Section(res, this.http));
            return this;
          }));
    }

    getCollaborators(params: any = {}): Observable<Collaborator[]> {
        return this.http.get(`${environment.envVar.API_URL}/create/courses/${this.id}/collaborators`, {
            observe: 'response',
            params: params,
        }).pipe(
            map((response: any) => response.body)
        );
    }

    getCollaboratorsAvailable(params: any = {}): Observable<Collaborator[]> {
        return this.http.get(`${environment.envVar.API_URL}/create/courses/${this.id}/collaborators/available`, {
            observe: 'response',
            params: params,
        }).pipe(
            map((response: any) => response.body)
        );
    }

    addCollaborator(id: number, companyId: number): Observable<Collaborator> {
        return this.http.post(`${environment.envVar.API_URL}/courses/${this.id}/collaborators`, {
            accountId: id,
            roleId: 1,
            isActive: true,
            companyId: companyId
        }).pipe(map((collaboratorObject) => new Collaborator(collaboratorObject)));
    }

    updateCollaborator(id: number, roleId: number = 1, isActive: boolean = false, companyId: number): Observable<Collaborator> {
        return this.http.put(`${environment.envVar.API_URL}/courses/${this.id}/collaborators/${id}`, {
            accountId: id,
            roleId: roleId,
            isActive: isActive,
            companyId: companyId
        }).pipe(map((collaboratorObject) => new Collaborator(collaboratorObject)));
    }

    deleteCollaborator(account: Account): Observable<void> {
        return this.http.delete(`${environment.envVar.API_URL}/courses/${this.id}/collaborators/${account.id}`).pipe(map(res => null));
    }

    update(companyId): Observable<Course> {
        return this.http.put(`${environment.envVar.API_URL}/create/courses/${this.id}?includes=sections.sectionContents,categories&company_id=${companyId}`, cleanObject(this)).pipe(map(res => this.extends(res)));
    }

    delete(params?: any): Observable<void> {
        return this.http.delete(`${environment.envVar.API_URL}/studio/course/${this.id}`, {params: getParams(params)}).pipe(map(res => null));
    }

    getEvents() {
        return this.http.get(`${environment.envVar.API_URL}/course/${this.id}/events?includes=event_sessions`)
            .pipe(map((data: any) => {
                return data.events.map((event) => {
                    return new Event(event, this.http);
                })
            }));
    }
}
