export class HttpHelper {

    public static cleanParams(params: any): any {
        const newParams = {};
        for (const param in params) {
            if (params.hasOwnProperty(param) && params[param] !== undefined && params[param] !== null && params[param] !== '') {
                newParams[param] = params[param];
            }
        }
        return newParams;
    }
}
