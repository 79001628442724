import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'journey-library',
    templateUrl: './journey-library.component.html'
})
export class JourneyLibraryComponent {
    @Input() company: any;
    @Input() journey: any;
    @Input() action: string;
    @Input() content: any;
    @Input() edit: boolean = false;
    @Input() event: any;
    @Input() sectionContent: any;
    @Input() loadingCreateEvent: boolean = false;
    @Output() actionChange: EventEmitter<any> = new EventEmitter();
    @Output() onSelectedEvent: EventEmitter<boolean> = new EventEmitter();
    @Output() onEventCreate: EventEmitter<any> = new EventEmitter();
    @Output() onClose: EventEmitter<any> = new EventEmitter();
    @Output() onAddCourse: EventEmitter<any> = new EventEmitter();
    @Output() onAddContent: EventEmitter<any> = new EventEmitter();
    @Output() onAddPtmc: EventEmitter<any> = new EventEmitter();
    @Output() onRefresh: EventEmitter<any> = new EventEmitter();
    @Output() onUpdate: EventEmitter<any> = new EventEmitter();

    createEvent(event: any): void {
        this.onEventCreate.emit(event);
    }

    updateEvent(event: any): void {
        this.onUpdate.emit({ type: 'event', data: event });
    }

    addPtmc(event: any): void {
        this.onAddPtmc.emit(event);
    }

    addCourse(event: any): void {
        this.onAddCourse.emit(event);
    }

    updateCourse(event: any): void {
        this.onUpdate.emit({ type: 'content', data: event });
    }

    addContent(event: any): void {
        this.onAddContent.emit(event);
    }

    select(action: string): void {
        this.action = action;
        this.actionChange.emit(this.action);
    }

    selectEvent(): void {
        if (this.action !== 'event') {
            this.onSelectedEvent.emit(true);
        }
    }

    refresh(): void {
        this.onRefresh.emit();
    }
}
