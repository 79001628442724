import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'msc-comment',
    templateUrl: './comment.component.html'
})
export class CommentComponent {
    @Input() comment: any;
    @Output() onDelete: EventEmitter<any> = new EventEmitter();

    onDeleteComment(): void {
        this.onDelete.emit(this.comment.id);
    }
}
