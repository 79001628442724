<div (click)="showPreview()">
	<div *ngIf="item.type === 'IMG' || item.type === 'EMBED'">
		<div class="folder__item  folder__item__folder media" [style.background]="none">
			<div class="img-container" style="height: 100%">
				<div *ngIf="item.storage !== 'ext'" style="height: 100%" [ngStyle]="style" alt="" class="info__image"></div>
				<div *ngIf="item.storage === 'ext'" style="height: 100%" [ngStyle]="style"></div>
			</div>

			<div class="info" [ngClass]="'picto-' + item.picto">
				<span class="info__name">{{ item.title }}</span>
				<span class="info__type font-text">{{ getType(item.type) | translate }}</span>
			</div>
		</div>
		<div class="actions">
			<button *ngIf="toUpload" class="add-media" (click)="onUpdatedMedia($event)">+</button>
		</div>
	</div>

	<div *ngIf="item.type === 'VID' || item.type === 'SND' || item.type === 'DOC' || item.type === 'PDF' || item.type === 'NONE'">
		<div class="folder-miniature columns" style="padding: 0.8rem;">
			<div class="info" [ngClass]="'picto-' + item.picto">
				<div class="info-block">
					<div class="info__name">{{ item.title }}</div>
					<div class="info__type font-text">{{ getType(item.type) | translate }}</div>
				</div>
			</div>
		</div>
	</div>
</div>
