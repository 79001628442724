import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { CanActivate } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { RedirectService } from '../services/redirect.service';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private auth: AuthService, private redirectService: RedirectService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.auth.loggedIn()) {
            return true;
        } else {
            this.redirectService.navigate('app', ['login']);

            return false;
        }
    }
}
