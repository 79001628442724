import { NgModule, ModuleWithProviders } from '@angular/core';

import { CompanyProvider } from './company';
import { NewJourneyProvider } from './journey';
import { NewKnowledgeProvider } from './knowledge';
import { LanguageProvider } from './language';
import { SessionProvider } from './session';

@NgModule({})
export class ProvidersModule {
    static forRoot(): ModuleWithProviders<any> {
        return {
            ngModule: ProvidersModule,
            providers: [
                CompanyProvider,
                NewJourneyProvider,
                NewKnowledgeProvider,
                LanguageProvider,
                SessionProvider
            ]
        };
    }
}
