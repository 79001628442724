import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { EmitterService } from '../../../services/emitter.service';
import { LoadFile, SubscriptionCollection } from '@classes';
import { TranslationService } from '../../../services/translation.service';
import { StorageService } from '../../../services/storage.service';
import { ExternalAppsService } from '../../../services/external-apps.service';

@Component({
    selector: 'msc-aside-help',
    templateUrl: './aside-help.component.html',
    styleUrls: ['./aside-help.component.less']
})
export class AsideHelpComponent implements OnInit, OnDestroy {
    public loadFile: LoadFile = new LoadFile();
    public subscriptionCollection: SubscriptionCollection = new SubscriptionCollection();
    public company: any;
    public guideType: string;
    public me: any;
    public guides: any;
    public active = {
        aside: false,
        dropdown: []
    };
    public currentLang: string;
    public displayed: boolean = false;
    public isAll: boolean = false;
    public currentJob: string = 'create';
    public feature: string;

    constructor(
        private emitterService: EmitterService,
        private translationService: TranslationService,
        private storageService: StorageService,
        private externalAppsService: ExternalAppsService,
        private router: Router,
    ) { }

    ngOnInit() {
        this.me = this.storageService.get('me');
        this.company = this.storageService.get('company');
        this.currentLang = this.translationService.getCurrentLang();
        this.active.dropdown[0] = true;

        this.subscriptionCollection.subscribe = this.emitterService.activeHelp.subscribe((data) => {
            this.guides = [];
            this.isAll = false;
            this.active.aside = true;
            this.guideType = data.type;
            this.feature = data.feature;

            if (data.type === 'all') {
                this.guides = this.loadFile.getAll();
                this.isAll = true;
            } else {
                this.guides = this.loadFile.get(data.type);
            }
        });
    }

    ngOnDestroy() {
        this.subscriptionCollection.unsubscribe();
    }

    closeSidebar(): void {
        this.active.aside = false;
    }

    openDropdown(index): void {
        this.active.dropdown[index] = !this.active.dropdown[index];
    }
}
