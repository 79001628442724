<div class="overlay" [ngClass]="{'active': active}"></div>

<aside class="m-sidebar sidebar sidebar--modal" [ngClass]="{'active': active}" (appSidebar)="closeSidebar()">
    <div class="sidebar__container">
        <div class="sidebar__head">
            <div class="sidebar__description">
                <h1 class="sidebar__title">
                    {{ 'studio.course.course-settings-edit-title' | translate }}
                    <span *ngIf="title"> - {{ title }}</span>
                </h1>
            </div>

            <a class="sidebar__close" (click)="closeSidebar()">
                <i class="icon icon--close"></i>
            </a>
        </div>

        <div class="m-content">
            <msc-froala *ngIf="active" [plugins]="plugins" [autofocus]="true" [(content)]="content"></msc-froala>
        </div>
    </div>

    <div class="m-footer">
        <a class="button button--small button--color" (click)="save()">
            {{ 'words.save' | translate }}
        </a>
    </div>
</aside>
