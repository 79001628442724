import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { getParams } from '../helpers';
import { environment } from 'src/environments/environment';

@Injectable()
export class SkillProvider {

    constructor(public http: HttpClient) { }

    getSkills(params: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/skills`, { params: getParams(params) });
    }

    getCompanySkills(params: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/skillables/skills`, { params: getParams(params) });
    }

    create(params: any): Observable<any> {
        return this.http.post(`${environment.envVar.API_URL}/skills`, params);
    }

    attach(id: number, params: any): Observable<any> {
        return this.http.post(`${environment.envVar.API_URL}/skills/${id}/attach`, params);
    }

    detach(id: number, params: any): Observable<any> {
        return this.http.delete(`${environment.envVar.API_URL}/skills/${id}/detach`, { params: getParams(params) });
    }

    updateLevel(id: number, params: any): Observable<any> {
        return this.http.put(`${environment.envVar.API_URL}/skills/${id}/level`, params);
    }
}
