import { Question } from '../question';
import { HttpClient } from '@angular/common/http';

export class CategoryQuestion extends Question {
    content: CategoryQuestionContent;

    constructor(question: any = {}, http: HttpClient) {
        super(question, http);
        this.extends(question);
    }


    extends(question: any): CategoryQuestion {
        Object.assign(this, question);


        if (!this.content) {
            this.content = new CategoryQuestionContent();
            this.content.addCategory(null);
            this.content.addCategory(null);
            this.content.addChoice();
            this.content.addChoice();
        }
        if (question.content) {
            if (this.content instanceof CategoryQuestionContent) {
                this.content.extends(question.content);
            } else {
                this.content = new CategoryQuestionContent(question.content);
            }
        }

        return this;
    }
}


export class CategoryQuestionContent {
    choices: CategoryQuestionChoice[] = []; // TO DO;
    categories: string[] = [];

    constructor(data: any = {}) {
        this.extends(data);
    }

    extends(data: any = {}): CategoryQuestionContent {
        Object.assign(this, data);
        return this;
    }

    addCategory(name: string) {
        this.categories.push(name);
    }

    removeCategory(index: number) {
        this.categories.splice(index, 1);
    }

    addChoice() {
        const choice = { type: 'text', value: null, category: this.categories[0] }
        this.choices.push(choice);
    }

    removeChoice(index: number) {
        this.choices.splice(index, 1);
    }
}

export interface CategoryQuestionChoice {
  type: string;
  value: string|number;
  category?: string;
}
