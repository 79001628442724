<div class="overlay" [ngClass]="{'active' : sidebarActive}"></div>

<msc-topbar [type]="'journey'" (create)="openSidebar('create')" (search)="search($event)"></msc-topbar>

<div *ngIf="sidebarActive === 'create'">
    <journey-sidebar-create (close)="closeSidebar()" (create)="addJourney($event)"></journey-sidebar-create>
</div>

<div *ngIf="sidebarActive === 'publish'">
    <journey-sidebar-publish [journey]="currentJourney" (close)="closeSidebar()" (publish)="publishJourney($event)">
    </journey-sidebar-publish>
</div>

<div *ngIf="sidebarActive === 'update'">
    <journey-sidebar-create [journey]="currentJourney" (close)="closeSidebar()" (create)="updateJourney($event)">
    </journey-sidebar-create>
</div>

<div class="container">
    <section class="section">
        <h1 class="section__title">
            {{ 'studio.journey.title' | translate }}
        </h1>

        <div class="tabview">
            <div class="tab active" [ngClass]="{'active': !onlyArchived}" (click)="onlyArchived = 0; search('')">
                <p>{{ 'words.all' | translate }}</p>
            </div>
            <div class="tab" [ngClass]="{'active': onlyArchived}" (click)="onlyArchived = 1; search('')">
                <p>{{ 'words.archived' | translate }}</p>
            </div>
        </div>

        <ul class="grid grid--block box" *ngIf="journeys.length > 0">
            <li class="grid__four box__modul dropdown" *ngFor="let journey of journeys; let index=index">
                <div class="card journey-card" (click)="navigateToJourney(journey.slug)">
                    <div class="card__header">
                        <div class="card__action card__action--single">
                            <span class="tooltip-custom" *ngIf="journey.state == 'published'">
                                <div class="templab-icon templab-icon-checkmark"
                                    style="height: 22px; width: 22px; background-color: #3EBA81; border-radius: 100%; color: #FFFFFF;">
                                </div>
                                <span class="tooltip__info">
                                    {{ 'words.instance-published' | translate }}
                                </span>
                            </span>
                            <div class="more__actions">
                                <a class="link__more" (click)="changeOpenDropdown(index); stopPropagation($event)">
                                    <i class="new-icon icon-more-horizontal"></i>
                                </a>

                                <div class="dropdown" [ngClass]="{'active' : openDropdown[index]}"
                                    [(autoClose)]="openDropdown[index]">
                                    <ul class="list__action">
                                        <li class="item__action"
                                            (click)="cloneJourney(journey); stopPropagation($event)">
                                            <a>
                                                <img src="https://static.myskillcamp.com/images/icon-duplicate.svg"
                                                    alt="icon-duplicate"> {{ 'studio.common.button.clone' | translate }}
                                            </a>
                                        </li>

                                        <li class="item__action" *ngIf="journey.state == 'archived'"
                                            (click)="restoreJourney(journey); stopPropagation($event)">
                                            <a>
                                                <img src="https://static.myskillcamp.com/images/icon-duplicate.svg"
                                                    alt="icon-duplicate"> {{ 'studio.common.button.restore' | translate
                                                }}
                                            </a>
                                        </li>

                                        <li class="item__action" *ngIf="journey.state != 'archived'"
                                            (click)="hideArchiveWarn ? archiveJourney(journey) : openJourneyModal(journey, modalArchiveJourney, true); stopPropagation($event)">
                                            <a>
                                                <img src="https://static.myskillcamp.com/images/icon-duplicate.svg"
                                                    alt="icon-duplicate"> {{ 'studio.common.button.archive' | translate
                                                }}
                                            </a>
                                        </li>

                                        <li class="item__action"
                                            (click)="openSidebar('update', journey); stopPropagation($event)">
                                            <a>
                                                <img src="https://static.myskillcamp.com/images/icon-duplicate.svg"
                                                    alt="icon-duplicate"> {{ 'words.edit' | translate }}
                                            </a>
                                        </li>

                                        <li class="item__action" *ngIf="journey.state != 'archived'"
                                            (click)="openSidebar('publish', journey); stopPropagation($event)">
                                            <a>
                                                <img src="https://static.myskillcamp.com/images/icon-duplicate.svg"
                                                    alt="icon-duplicate"> {{ 'studio.sidebar.publish' | translate }}
                                            </a>
                                        </li>

                                        <li class="item__action"
                                            (click)="!journey.hasOnboardings ? openJourneyModal(journey, modalDeleteJourney) : null; stopPropagation($event)"
                                            [ngClass]="{ 'generic-disabled': journey.hasOnboardings }">
                                            <a
                                            [mscTooltip]="journey.hasOnboardings ? ('words.has-onboardings.cannot-delete' | translate) : null">
                                                <img src="https://static.myskillcamp.com/images/icon-delete.svg"
                                                    alt="icon-delete"> {{ 'studio.common.button.delete' | translate }}
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="card__image">
                            <img *ngIf="!journey.media"
                                [src]="'https://static.myskillcamp.com/lxp/images/logo-empty-state-' + journey.id % 7 + '.jpg'"
                                alt="img">
                            <img *ngIf="journey.media" [src]="getPicture(journey.media)" alt="media">
                        </div>
                    </div>

                    <div class="card__content">
                        <a class="card__title">
                            {{(journey.title | truncate: '35')}}
                            <span class="card__title-tooltip" *ngIf="journey.title.length > 35">{{ journey.title
                                }}</span>
                        </a>

                        <!--                        <div class="card__alert" *ngIf="journey.isUnpublished">-->
                        <!--                            <div class="tooltip-v2 tooltip-v2&#45;&#45;large" [attr.data-tooltip]="'tooltip.learning-path.unpublished' | translate">-->
                        <!--                                <i class="templab-icon templab-icon-alert-triangle templab-icon-alert templab-color&#45;&#45;red"></i>-->
                        <!--                            </div>-->
                        <!--                        </div>-->
                    </div>

                    <div class="card__footer">
                        <p class="box__description grid tooltip-custom">
                            <span class="box__number">{{ (journey.templatesCount + journey.externalContentsCount )}}</span>
                            <i class="icon icon--modul"></i>
                            <span class="tooltip__info">
                                {{ 'words.courses' | translate }}
                            </span>
                        </p>

                        <div class="box__description grid tooltip-custom">
                            <span class="box__number">{{ journey.eventsCount }}</span>
                            <i class="new-icon icon-calendar-outline"></i>
                            <span class="tooltip__info">
                                {{ 'words.event' | translate | titlecase }}
                            </span>
                        </div>

                        <p class="box__description grid tooltip-custom">
                            <span class="box__number">{{ journey.contentsCount }}</span>
                            <i class="icon icon--media-library"></i>
                            <span class="tooltip__info">
                                {{ 'studio.modal-activity.media' | translate }}
                            </span>
                        </p>

                        <p class="box__description grid tooltip-custom">
                            <a class="link__edit">
                                <i class="new-icon icon-edit-outline"></i>
                            </a>
                            <span class="tooltip__info">
                                {{ 'words.edit' | translate }}
                            </span>
                        </p>
                    </div>
                </div>
            </li>
        </ul>

        <div class="empty-body" *ngIf="journeys.length === 0 && !loading && !journeyLoading">
            <img src="https://static.myskillcamp.com/images/studio/empty-journey.svg">


            <h1 *ngIf="onlyArchived" class="empty-title">
                {{ 'studio.archived-journey.empty-title' | translate }}
            </h1>

            <ng-container *ngIf="!onlyArchived">
                <a class="empty-link" (click)="createJourney()">
                    {{ 'studio.journey.empty-action' | translate }}
                    <i class="empty-icon icon icon--arrow-long-right"></i>
                </a>
                <h1 class="empty-title">
                    {{ 'studio.archived-journey.empty-title' | translate }}
                </h1>
            </ng-container>
        </div>

        <msc-loader [loading]="journeyLoading"></msc-loader>

        <button class="show-more" *ngIf="!journeyLoading && pagination.currentPage < pagination.lastPage"
            (click)="loadMore()">
            <div class="lds-ring" *ngIf="loading">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>

            <span>{{ 'studio.instance.load-more' | translate }}</span>
        </button>
    </section>
</div>

<ng-template #modalDeleteJourney let-modal>
    <div class="modal-header">
        <span></span>
        <button type="button" class="close" aria-label="Close" (click)="modal.close()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-content" style="border: none; text-align: center;">
            <h3>{{ 'studio.journey.delete-hint' | translate }}</h3>
        </div>

        <div class="actions" style="padding-top: 2rem; display: flex; justify-content: center;">
            <button class="btn btn_color_red" (click)="deleteJourney(currentJourney); modal.close()">
                {{ 'studio.common.button.delete' | translate }}
            </button>
            <button class="btn btn_color_grey" (click)="modal.close()">
                {{ 'studio.common.button.cancel' | translate }}
            </button>
        </div>
    </div>
</ng-template>

<ng-template #modalArchiveJourney let-modal>
    <div class="modal-header">
        <span></span>
        <button type="button" class="close" aria-label="Close" (click)="modal.close()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <div class="modal-content" style="border: none;">

            <h3>{{ 'studio.learning-path.confirm.archive.title' | translate }}</h3>
            <p>
                {{ 'studio.learning-path.confirm.archive.description' | translate }}
            </p>
            <label class="input">
                <input type="checkbox" class="input-checkbox" [(ngModel)]="hideArchiveWarn"> {{ 'words.dont-show' | translate }}
            </label>
        </div>
    
        <div class="modal-actions" style="display: flex; justify-content: flex-end; margin-top: 10px;">
            <button class="button button--border" (click)="modal.close()" style="margin-right: 10px;">
                {{ 'words.cancel' | translate }}
            </button>

            <button class="button button--border button--primary"
                (click)="archiveJourney(currentJourney);modal.close()">
                {{ 'words.confirm' | translate }}
            </button>
        </div>
    </div>
</ng-template>
