import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'search'
})
export class SearchPipe implements PipeTransform {

    transform(values: any[], filter?: any): any[] {
        if (!filter) {
            return values;
        }
        return values.filter((value) => value.title.toLowerCase().includes(filter.toLowerCase()))
    }
}
