<button class="btn" (click)="run(modalEmbed)">
    {{ 'lti.launch-lti' | translate }}
</button>

<ng-template #modalEmbed let-modal>
    <div class="modal-header">
        <span></span>
        <button type="button" class="close" aria-label="Close" (click)="modal.close()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div style="background-color: #FFFFFF; padding: 5rem; margin: 3rem 0 0; max-height:80%; overflow: auto;">
            <div class="embed">
                <iframe width="100%" height="100%" name="embed-iframe"></iframe>
                <div id="embed-code"></div>
            </div>
        </div>
    </div>
</ng-template>
