<div class="modal-header">
    <span></span>
    <button type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <div id="library" class="wrapper">
        <div class="column library-topbar">
            <h2 class="heading">
                <span>{{ "words.activity" | translate }}</span>
                <div>
                    {{ 'studio.re-use.title' | translate }}
                </div>
            </h2>

            <div class="topbar">
                <div class="topbar-items">
                    <div class="topbar-items__title" (click)="changeFilterByContextAction()">{{ 'studio.re-use.filter-by-contexts' | translate }}
                        <i class="fa icon-chevron-down"></i>
                        <div class="dropdown" [ngClass]="{'active': filterByContext === true}">
                            <div class="dropdown-list">
                                <div class="dropdown-list__choice" (click)="useFilterByContext('project')">{{ 'studio.re-use.project-context' | translate }}</div>
                                <div class="dropdown-list__choice" (click)="useFilterByContext('event')">{{ 'studio.re-use.event-context' | translate }}</div>
                                <div class="dropdown-list__choice" (click)="useFilterByContext('media')">{{ 'studio.re-use.media-context' | translate }}</div>
                                <div class="dropdown-list__choice" (click)="useFilterByContext('exercise')">{{ 'studio.re-use.exercise-context' | translate }}</div>
                                <div class="dropdown-list__choice" (click)="useFilterByContext('scorm')">{{ 'studio.re-use.scorm-context' | translate }}</div>
                                <div class="dropdown-list__choice" (click)="useFilterByContext('video')">{{ 'studio.re-use.video-context' | translate }}</div>
                                <div class="dropdown-list__choice" (click)="useFilterByContext('cheatsheet')">{{ 'studio.re-use.cheatsheet-context' | translate }}</div>
                                <div class="dropdown-list__choice" (click)="useFilterByContext('certificate')">{{ 'studio.re-use.certificate-context' | translate }}</div>
                                <div class="dropdown-list__choice" (click)="useFilterByContext('exchange')">{{ 'studio.re-use.exchange-context' | translate }}</div>
                            </div>
                        </div>
                    </div>
                    <button class="btn btn_width_1 btn_color_deep-blue" (click)="resetFilter()">{{ 'studio.re-use.reset-filter' | translate }}</button>
                    <button class="btn btn_width_1" (click)="duplicateAction()">{{ 'studio.re-use.duplication' | translate }}</button>
                </div>
            </div>
        </div>

        <div class="column library_content" *ngIf="ready">
            <div class="folder folder_display_list">
                <div class="folder__item folder__item__parcours re-use-activity" *ngFor="let section of sections?.sections">
                    <div *ngIf="section.sectionContents.length > 0">
                        <div class="info" (click)="displaySectionContentAction(section.id)">
                            <span class="info__name">{{ section?.title }}</span>
                            <span class="info__type font-text">{{ 'studio.re-use.section-context' | translate }}</span>
                        </div>
                        <div *ngIf="displaySectionContent === section.id && displaySectionContentState">
                            <div *ngFor="let sectionContent of section.sectionContents" class="section-content-checkbox">
                                <label for="{{sectionContent.id}}" class="choice" [class.choice_selected]="isInArray(sectionContent.id)">
                                <span class="info__name"><input id="{{sectionContent.id}}" type="checkbox" (change)="addSectionContentToDuplicate($event, sectionContent)" style="margin-right: .5rem;">
                                    {{ sectionContent?.title || 'Sans titre' }} - {{ sectionContent?.context }}</span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ngb-pagination class="pagination" [collectionSize]="countSections" (pageChange)="catchPage($event)" [(page)]="currentPage" size="sm" [ellipses]="false" [pageSize]="itemsPerPage"></ngb-pagination>
        </div>

        <div class="ready" style="text-align: center;" *ngIf="!ready">
            <div class="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    </div>
</div>
