<div class="row" >
	<div class="column" *ngFor="let array of imagesTab">
		<div class="image-bank-container" *ngFor="let image of array">
			<img class="image-bank-image" (click)="createMedia(image.urls.small)" [src]="image.urls.small">

			<div class="image-bank-details">Unsplash - {{image.user.name}}
				<div class="links"><a [href]="image.links.html" target="_blank">{{'word.check-online' | translate }}</a></div>
			</div>
		</div>
	</div>
</div>

