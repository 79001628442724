import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UtilityProvider, ExternalLinkProvider } from '@stuplay';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'msc-aside-lti',
    templateUrl: './aside-lti.component.html',
    styleUrls: ['./aside-lti.component.scss']
})
export class AsideLtiComponent implements OnInit {
    @Input() lti: any;
    @Input() action: string;
    @Input() sectionContent: any;
    @Output() onClose: EventEmitter<any> = new EventEmitter;
    @Output() onSave: EventEmitter<any> = new EventEmitter();
    public file: File;
    public active: boolean = false;
    public data: any;
    public picture: string = '';
    public media: any;
    public uploadPicture: boolean = false;

    constructor(
        private utilityProvider: UtilityProvider,
        private externalLinkProvider: ExternalLinkProvider,
        private modalService: NgbModal,
    ) { }

    ngOnInit() {
        this.activeEffect();

        this.data = {
            id: this.lti ? this.lti.id : null,
            type: 'lti',
            name: this.lti ? this.lti.name : '',
            url: this.lti ? this.lti.url : '',
            mediaId: this.lti ? this.lti.mediaId : '',
            mode: this.lti ? this.lti.params.mode : 'blank',
            redirectUrl: this.lti ? this.lti.params.redirectUrl : null,
            key: this.lti ? this.lti.params.key : null,
            secret: this.lti ? this.lti.params.secret : null,
            names: this.lti ? this.lti.params.names :
                {
                    fr: null,
                    en: null,
                    nl: null,
                    it: null
                },
            anonymize: this.lti ? this.lti.params.anonymize : false
        };
    }

    openModal(content: any) {
        const options: NgbModalOptions = { size: 'xl', centered: true, backdropClass: 'backdrop-custom' };
        this.modalService.open(content, options);
    }

    activeEffect(): void {
        const timeOut = setTimeout(() => {
            this.active = true;
            clearTimeout(timeOut);
        }, 100);
    }

    onUpdatedMedia(media: any) {
        media ? this.data.mediaId = media.id : this.data.mediaId = null;
        this.uploadPicture = true;
    }

    closeSidebar(): void {
        this.active = false;

        const timeOut = setTimeout(() => {
            this.onClose.emit();
            clearTimeout(timeOut);
        }, 300);
    }

    getTitle(): void {
        this.utilityProvider.getLinkPreview(this.data.url).subscribe((data) => {
            this.data.name = data ? data.title : null;
        });
    }

    updateAnonymise(): void {
        this.data.anonymize = !this.data.anonymize;
    }

    updateSectionContent(): void {
        this.externalLinkProvider.updateSectionContent(this.sectionContent.value.id, this.sectionContent.value).subscribe();
    }

    stopPropagation(event: any): void {
        event.stopPropagation();
    }

    save(content: any): void {
        this.action === 'edit' ? this.openModal(content) : this.confirm();
    }

    confirm(): void {
        this.onSave.emit(this.data);
    }
}
