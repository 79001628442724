import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Exercise, Journey, Question, QuestionTypeMap, SectionContent } from '@stuplay';
import { switchMap } from 'rxjs/operators';

@Component({
    selector: 'journey-library-question',
    templateUrl: './library-question.component.html'
})
export class JourneyLibraryQuestionComponent implements OnInit {
    @Input() sectionContent: SectionContent;
    @Input() questionIndex: number;
    @Input() journey: Journey;
    @Output() close: EventEmitter<any> = new EventEmitter;
    @Output() back: EventEmitter<any> = new EventEmitter;
    @Output() add: EventEmitter<any> = new EventEmitter;
    public exercise: Exercise;
    public question: Question;
    public questionActive: string;
    public active: boolean = true;
    public indexQuestion: number;
    public processing: boolean = false;

    ngOnInit() {
        this.exercise = this.sectionContent.exercise;

        if (this.questionIndex !== undefined) {
            this.indexQuestion = this.questionIndex;
            this.questionActive = this.exercise.questions[this.questionIndex].type;
            this.question = this.exercise.questions[this.questionIndex];
        }

        const element: any = document.getElementById('main-content');
        const scroll: any = {
            block: 'start',
            inline: 'nearest',
            behavior: 'smooth'
        };
        element.scrollIntoView(scroll);
    }

    selectQuestion(type: string): void {
        this.processing = true;

        if (type) {
            this.exercise
                .addQuestion({
                    type_id: QuestionTypeMap.getIdByName(type),
                    exerciseId: this.exercise.id,
                    position: this.exercise.questions.length
                }, {
                    traject_id: this.journey.id
                })
                .pipe(switchMap(() => this.exercise.update({
                    traject_id: this.journey.id
                })))
                .subscribe(() => {
                    this.questionActive = type;
                    this.question = this.exercise.questions[this.exercise.questions.length - 1];
                    this.processing = false;
                });
        } else {
            this.questionActive = '';
        }
    }

    updateSectionContent(): void {
        this.sectionContent.update({
            traject_id: this.journey.id
        }).subscribe();
    }

    updateQuestion(question: any): void {
        this.questionActive = question.type;
        this.question = question;
    }

    backAction(): void {
        if (this.questionActive) {
            this.questionActive = '';
        } else {
            this.back.emit(true);
        }
    }

    cancelQuestion(): void {
        this.exercise.deleteQuestion(this.question.id, {
            traject_id: this.journey.id
        }).subscribe();
        this.questionActive = '';
    }

    saveQuestion(): void {
        this.questionActive = '';
    }

    addActivity(): void {
        this.active = false;
        this.add.emit(true);
    }
}
