import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Subject, of, forkJoin } from 'rxjs';
import { map, repeatWhen, takeUntil, tap, concatMap, finalize } from 'rxjs/operators';
import { NgbModal, NgbModalRef, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { DataQualityProvider, Folder, FolderProvider, CourseProvider } from '@stuplay';
import { HeaderService } from '../../utils/services/header.service';
import { StorageService } from '../../utils/services/storage.service';
import { EmitterService } from '../../utils/services/emitter.service';
import { ExternalAppsService } from '../../utils/services/external-apps.service';
import { TranslationService } from '../../utils/services/translation.service';
import { ToastService } from '../../utils/components/toast/toast.service';

const _MS_PER_DAY = 1000 * 60 * 60 * 24;

const ContentTypeIconMapping = new Map<string, string>([
    ['course', 'modul'],
    ['traject', 'journey'],
]);

@Component({
    selector: 'msc-topbar',
    templateUrl: './topbar.component.html',
    styleUrls: ['./topbar.component.less']
})
export class TopbarComponent implements OnInit {
    @Input() type: string = 'folder';
    @Input() publishJourneyActive: boolean = false;
    @Input() breadcrumb: string;
    @Input() sharedCourses: boolean = false;
    @Output() newFolder: EventEmitter<any> = new EventEmitter;
    @Output() search: EventEmitter<any> = new EventEmitter;
    @Output() publish: EventEmitter<any> = new EventEmitter;
    @Output() create: EventEmitter<any> = new EventEmitter;
    @ViewChild('modalOwnershipInfo', { static: true }) modalOwnershipInfo: ElementRef;

    private destroy$: Subject<void> = new Subject<void>();
    private repeat$: Subject<void> = new Subject<void>();
    private qualityContents$: BehaviorSubject<any | undefined> = new BehaviorSubject<any>([]);
    private qualityContentsMeta$: BehaviorSubject<any | undefined> = new BehaviorSubject<any>({});
    private availableCompanies$: BehaviorSubject<any | undefined> = new BehaviorSubject<any>([]);
    private loading$: BehaviorSubject<boolean | undefined> = new BehaviorSubject<boolean>(true);
    private modalRef: NgbModalRef;
    public query: string;
    public folderId: number;
    public folder: Folder;
    public me: any;
    public company: any;
    public isQualityMessageVisible: boolean;
    public modalOwnershipInfoStep: number = 1;
    public contentParams: any = {};
    public countsQuality: number;

    constructor(
        private folderProvider: FolderProvider,
        private courseProvider: CourseProvider,
        private dataQualityProvider: DataQualityProvider,
        private route: ActivatedRoute,
        private router: Router,
        private headerService: HeaderService,
        private storageService: StorageService,
        private emitterService: EmitterService,
        private externalAppsService: ExternalAppsService,
        private toastService: ToastService,
        private translateService: TranslationService,
        private modalService: NgbModal,
    ) { }

    ngOnInit() {
        if (this.type !== 'journey' && this.type !== 'instance') {
            this.route.params.subscribe((params) => {
                if (params.slug && params.slug !== 'collaborations' && params.slug !== 'shared') {
                    this.folderProvider.getFolder(this.route.snapshot.params.slug).subscribe(folder => {
                        this.folderId = this.storageService.getAndSet('folderId', folder.folder.id);
                        this.folder = folder;
                    });
                } else {
                    this.folderId = this.storageService.getAndSet('folderId', 0);
                }
            });
        }

        this.query = this.storageService.getFlash('query');
        this.me = this.storageService.get('me');
        this.company = this.storageService.get('company');
        this.isQualityMessageVisible = this.isMessageVisisble();

        if (this.isQualityMessageVisible) {
            this.onQueryQualityCounts();
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
        this.repeat$.complete();
    }

    createFolder() {
        this.folderProvider.createFolder({ folder_id: this.folderId }).subscribe((folder) => {
            this.newFolder.emit(folder);
        });
    }

    createCourse() {
        const params = {
            folder_id: this.folderId,
            company_id: this.company.id
        };
        this.courseProvider.createCourse(params).subscribe(
            (course) => {
                this.headerService.plus('templates');
                this.storageService.set('currentLocation', this.router.url);
                this.router.navigate(['company', this.storageService.get('company').slug, 'course', course.hash]);
            });
    }

    sendCreate(): void {
        this.create.emit(true);
    }

    sendPublish(): void {
        this.publish.emit(true);
    }

    sendSearch(): void {
        this.search.emit(this.query)
    }

    resetSearch(): void {
        this.query = '';
    }

    navigateTo(route: string, params?: any): void {
        if (params) {
            this.router.navigate(['company', this.storageService.get('company').slug, route, params]);
        } else {
            this.router.navigate(['company', this.storageService.get('company').slug, route]);
        }
    }

    openAsideHelp(type) {
        this.emitterService.activeHelp.emit({ type: type, feature: 'exercice' });
    }

    getMapQualityContents(contents: any, companies: any[]) {
        return contents.map((d) => ({
            id: d.contextId,
            isSelected: false,
            icon: ContentTypeIconMapping.get(d.context),
            title: d.title,
            type: this.translateService.instant(`api.content-type.${d.context}`),
            context: d.context,
            description: d.description,
            skills: d.skills.filter(s => s.name).map(s => s.name).join(', '),
            selectOptions: this.getMapAvailableCompanies(companies),
        }));
    }

    getMapQualityContentsMeta(data) {
        const languages = data.languages.map(d => ({
            id: d.id,
            isSelected: false,
            label: d.name,
            value: null
        }));

        const types = data.types.map((d, i) => ({
            id: i,
            isSelected: false,
            label: this.translateService.instant(`api.content-type.${d}`),
            value: d
        }));

        return {languages, types};
    }

    getMapAvailableCompanies(companies: any) {
        return companies.map(d => ({
            id: d.id,
            isSelected: false,
            label: d.name,
            value: null,
        }));
    }

    getQualityContents(companies) {
        return of([])
            .pipe(
                concatMap(()=> this.dataQualityProvider.getContents(this.contentParams)),
                map((resp: any) => this.getMapQualityContents(resp.data, companies)),
                tap(resp => this.qualityContents$.next(resp)),
                finalize(() => this.loading$.next(false)),
                repeatWhen(() => this.repeat$),
                takeUntil(this.destroy$),
            )
            .subscribe();
    }

    getModalOpenDelay(): boolean {
        if (!localStorage.getItem('delay_time')) return;
        const d1 = new Date(localStorage.getItem('delay_time')).getTime();
        const d2 = new Date().getTime();
        const diff = Math.floor((d2 - d1) / _MS_PER_DAY);

        return diff < 7;
    }

    getAssignCompanyParamsList(data) {
        return data.reduce((acc, obj) => {
            const idx = acc.findIndex(d => d.company_id === obj.companyId);

            if (idx > -1) {
                acc[idx].contents.push({
                    context_id: obj.contentId,
                    context: obj.contentType
                });
                return acc;
            }

            let contents = [];
            contents.push({
                context_id: obj.contentId,
                context: obj.contentType
            });

            acc.push({
                company_id: obj.companyId,
                contents
            });
            return acc;

        }, []);
    }

    getCleanParams(params) {
        return Object.keys(params).reduce((acc, key) => {
            if (!params[key] || (Array.isArray(params[key]) && !params[key].length)) {
                return acc;
            }
            acc[key] = params[key];
            return acc;
        }, {});
    }

    isMessageVisisble(): boolean {
        if ((this.type === 'folder' && location.pathname.endsWith('home')) || (this.type === 'journey' && !location.pathname.includes('preview'))) {
            return true;
        }

        return false;
    }

    onQueryQualityContentsMeta() {
        this.dataQualityProvider
            .getContentsMeta()
            .pipe(
                map(resp => this.getMapQualityContentsMeta(resp)),
                tap(resp => this.qualityContentsMeta$.next(resp)),
                takeUntil(this.destroy$),
            )
            .subscribe();
    }

    onQueryAvailableCompanies() {
        this.dataQualityProvider
            .getCompanies({ role: 'author' })
            .pipe(
                tap(resp => this.getQualityContents(resp.companies)),
                map(resp => this.getMapAvailableCompanies(resp.companies)),
                tap(resp => this.availableCompanies$.next(resp)),
                takeUntil(this.destroy$),
            )
            .subscribe();
    }

    onQueryQualityCounts() {
        this.headerService
            .getSubjectCounts()
            .pipe(
                map(resp => resp.quality),
                tap(resp => this.onTapGetQualityCounts(resp)),
                takeUntil(this.destroy$),
            )
            .subscribe();
    }

    onTapGetQualityCounts(counts) {
        if (!counts) return;

        this.countsQuality = counts;

        if (!this.getModalOpenDelay()) {
            this.onModalOpen(this.modalOwnershipInfo, { size: 'l' });
        }

        this.onQueryAvailableCompanies();
        this.onQueryQualityContentsMeta();
    }

    onModalOpen(content: any, options?: NgbModalOptions ) {
        const defaultOptions = { size: 'xl', centered: true, backdropClass: 'backdrop-custom' };

        if (this.modalRef) this.modalRef.close();
        this.modalRef = this.modalService.open(content, Object.assign(defaultOptions, options));
    }

    onModalClose() {
        this.modalOwnershipInfoStep = 1;
        this.modalRef.close();
    }

    onModalDismiss() {
        this.modalOwnershipInfoStep = 1;
        this.modalRef.dismiss();
        localStorage.setItem('delay_time', new Date().toDateString());
    }

    onAssignCompany(contents) {
        const paramsList = this.getAssignCompanyParamsList(contents);
        const source$ = paramsList.map((p: any) => this.dataQualityProvider.editCompanyContent(p));
        forkJoin(source$)
            .pipe(
                finalize(() => this.onAssignCompanyFinalize(contents.length)),
                takeUntil(this.destroy$),
            )
            .subscribe({
                next: () => this.onAssignCompanySuccess(contents),
                error: (err) => this.onAssignCompanyError(err),
            });
    }

    onUpdate(params?) {
        this.contentParams = params ? this.getCleanParams({...this.contentParams, ...params}) : {};
        this.repeat$.next();
    }

    onAssignCompanyFinalize(count) {
        if (this.countsQuality === count) {
            this.modalOwnershipInfoStep = 2;
            this.onModalOpen(this.modalOwnershipInfo, { size: 'l' });
            return;
        }
        this.onModalClose();
    }

    onAssignCompanySuccess(contents) {
        this.countsQuality = this.countsQuality - contents.length;
        this.headerService.minus('quality', contents.length);
        this.toastService.push('data-quality.assign-company-success');
        this.onUpdate();
    }

    onAssignCompanyError(err) {
        this.toastService.push('data-quality.assign-company-error');
    }
}
