import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";

import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { getParams } from "../helpers";
import { environment } from 'src/environments/environment';

@Injectable()
export class DataQualityProvider {

	constructor(private http: HttpClient) {}

	/**
	 * 
	 */
	getContents(params: any = {}): Observable<any> {
		return this.http.get(`${environment.envVar.API_URL}/create/contents/quality`, {
			observe: 'response',
			params: getParams(params),
		}).pipe(
			map(resp => resp.body),
			catchError(error => { throw new Error(error) })
		);
	}

	/**
	 * 
	 */
	getContentsMeta(params = {}): Observable<any> {
		return this.http.get(`${environment.envVar.API_URL}/create/contents/quality/meta`, {
			observe: 'response',
			params: getParams(params),
		}).pipe(
			map(resp => resp.body),
		)
	}

	/**
	 * 
	 */
	getCompanies(params: any = {}): Observable<any> {
		return this.http.get(`${environment.envVar.API_URL}/create/companies`, {
			observe: 'response',
			params: getParams(params)
		}).pipe(
			map(resp => resp.body),
			catchError(error => { throw new Error(error) })
		);
	}

	/**
	 * 
	 */
	editCompanyContent(params: any = {}): Observable<any> {
		return this.http.put(`${environment.envVar.API_URL}/create/contents/company`, params, {
            observe: 'body',
        }).pipe(
			catchError(error => { throw new Error(error) })
        );
	}

	/**
	 * 
	 */
	 editCourseMode(courseId: number, params: any = {}) {
		return this.http.put(`${environment.envVar.API_URL}/create/courses/${courseId}/mode`, params, {
			observe: 'body',
		}).pipe(
			catchError(error => { throw new Error(error) })
		);
	}
}