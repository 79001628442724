import { HttpClient } from "@angular/common/http";
import { Observable } from 'rxjs';
import { environment } from "src/environments/environment";
import { Account } from '../../../account/account';
import { cleanObject } from "../../../helpers";

export class Comment {
    id: number;
    account: Account;
    content: any;
    created_at: any;

    constructor(comment: any, private http: HttpClient) {
        this.extends(comment);
    }

    extends(comment: any) {
        Object.assign(this, comment);
        return this;
    }

    update(): Observable<any> {
        return this.http.put(`${environment.envVar.API_URL}/timeline/comment/${this.id}`, cleanObject(this));
    }
}
