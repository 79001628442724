declare var require: any;

export class LoadFile {
    private fileLoaded: any[] = [];
    private languageFiles: any[] = [];

    constructor() {
        //create module
        this.fileLoaded['section'] = require('../../assets/guidance/section.json').guidance;
        this.fileLoaded['collaborator'] = require('../../assets/guidance/collaborator.json').guidance;
        this.fileLoaded['activity'] = require('../../assets/guidance/activity.json').guidance;

        //create module - activity
        this.fileLoaded['certification'] = require('../../assets/guidance/activity/certification.json').guidance;
        this.fileLoaded['cheatsheet'] = require('../../assets/guidance/activity/cheatsheet.json').guidance;
        this.fileLoaded['exchange'] = require('../../assets/guidance/activity/exchange.json').guidance;
        this.fileLoaded['event'] = require('../../assets/guidance/activity/event.json').guidance;
        this.fileLoaded['media'] = require('../../assets/guidance/activity/media.json').guidance;
        this.fileLoaded['project'] = require('../../assets/guidance/activity/project.json').guidance;
        this.fileLoaded['exercise'] = require('../../assets/guidance/activity/exercise.json').guidance;
        this.fileLoaded['scorm'] = require('../../assets/guidance/activity/scorm.json').guidance;
        this.fileLoaded['selfassessment'] = require('../../assets/guidance/activity/selfassessment.json').guidance;

        //other
        this.fileLoaded['iframe'] = require('../../assets/guidance/iframe.json').guidance;
        this.fileLoaded['webressource'] = require('../../assets/guidance/webressource.json').guidance;
        // this.fileLoaded['journey'] = require('../../assets/guidance/journey.json').guidance;
        // this.fileLoaded['postmodule'] = require('../../assets/guidance/postmodule.json').guidance;

        // Languages
        this.languageFiles['en'] = require('../../assets/i18n/en.json');
        this.languageFiles['de'] = require('../../assets/i18n/de.json');
        this.languageFiles['fr'] = require('../../assets/i18n/fr.json');
        this.languageFiles['it'] = require('../../assets/i18n/it.json');
        this.languageFiles['nl'] = require('../../assets/i18n/nl.json');
        this.languageFiles['es'] = require('../../assets/i18n/es.json');
    }

    get(job: string): any {
        return this.fileLoaded[job];
    }

    getAll(): any {
        return this.fileLoaded;
    }

    getLanguageFile(languageName: string): any {
        return this.languageFiles[languageName];
    }

    getAllLanguages(): any { 
        return this.languageFiles;
    }
}
