import { Component, Input } from '@angular/core';
import { Event, Section, SectionContent } from '@stuplay'

@Component({
    selector: 'app-event',
    templateUrl: './event.component.html'
})
export class EventComponent {
    @Input() event: Event;
    @Input() section: Section;
    @Input() sectionContent: SectionContent;

    constructor() {}

    getSessionLength(): number {
        return (this.event.eventSessions.length >= 2 ) ? 2 : this.event.eventSessions.length;
    }
}
