import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CampProvider, CourseProvider } from '@stuplay';
import { StorageService } from '../../../../utils/services/storage.service';

@Component({
    selector: 'content-ondemand',
    templateUrl: './content-ondemand.component.html'
})
export class ContentOnDemandComponent implements OnInit {
    @Input() template: any;
    @Output() onClose: EventEmitter<any> = new EventEmitter;
    @Output() onPublish: EventEmitter<any> = new EventEmitter;
    public currentCompany: any;
    public camps: any[] = [];
    public active: boolean = false;
    public companies: any[] = [];
    public loading: boolean = false;
    public query: string = '';
    public displays: boolean[] = [];

    constructor(
        private campProvider: CampProvider,
        private courseProvider: CourseProvider,
        private storageService: StorageService,
    ) {}

    ngOnInit() {
        this.currentCompany = this.storageService.get('company');
        this.activeEffect();
        this.search();
    }

     activeEffect(): void {
        setTimeout(() => {
            this.active = true;
        }, 100);
    }

    displayed(index: number): void {
        this.displays[index] = !this.displays[index];
    }

    closeSidebarEmit(): void {
        this.active = false;
        setTimeout(() => {
            this.onClose.emit(true);
        }, 300);
    }

    search() {
        this.loading = true;
        this.displays = [];

        this.campProvider.getAllCamps('template', this.template.id, this.query).subscribe((data) => {
            this.loading = false;
            this.companies = data;

            for (let i = 0; i < this.companies.length; i++) {
                if (this.currentCompany.slug === this.companies[i].slug) {
                    const company = this.companies[i];
                    this.companies.splice(i, 1);
                    this.companies.unshift(company);
                    this.displays[0] = true;
                } else if (this.query) {
                    this.displays[i] = true;
                }
            }

            for (const company of this.companies) {
                for (const camp of company.camps) {
                    if (camp.published) {
                        this.chooseCamp(camp);
                    }
                }
            }
        });
    }

    chooseCamp(camp: any): void {
        const index = this.camps.findIndex((c) => {
            return c.id === camp.id;
        })

        if (index === -1) {
            this.camps.push(camp);
        } else {
            this.camps.splice(index, 1);
        }
    }

    isChoosed(camp: any): boolean {
        return this.camps.findIndex((c) => {
            return c.id === camp.id;
        }) > -1;
    }

    publish(): void {
        const campIds = this.camps.map(c => c.id);
        this.courseProvider.publishInstance([this.template.id], campIds, []).subscribe(() => {
            this.onPublish.emit(this.camps);
        });
    }
}
