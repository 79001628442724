import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';


@Injectable()
export class SessionProvider {

    constructor(private http: HttpClient) {}

    archiveSession(instance_id: string, params?: any): Observable<any> {
        return this.http.post(`${environment.envVar.API_URL}/create/instances/${instance_id}/archive`, params).pipe(map((data) => data));
    }

    restoreSession(instance_id: string, params?: any): Observable<any> {
        return this.http.post(`${environment.envVar.API_URL}/create/instances/${instance_id}/restore-archived`, params).pipe(map((data) => data));
    }
}
