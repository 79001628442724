import { Media } from '../media';
import { HttpClient } from '@angular/common/http';

export class Journey {
    autoReminders: number;
    company?: any;
    contents?: any[];
    contentsCount: number;
    description: string;
    duration: number;
    eventsCount: number;
    externalContentsCount: number;
    id: number;
    isAttachedToACollection: boolean;
    isUnpublished?: boolean;
    language: any;
    media?: Media;
    name: string;
    published: boolean;
    skills: string;
    slug: string;
    templatesCount: number;
    title: string;
    type: string;
    usersLimit: number;
    isCertificable: boolean;

    constructor(journey: any, private http: HttpClient) {
        this.extends(journey);
    }

    extends(journey: any): Journey {
        Object.assign(this, journey);
        if (this.media) {
            this.media = new Media(this.media, this.http);
        }
        return this;
    }
}
