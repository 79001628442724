import { Component, OnInit, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { TranslationService } from '../../services/translation.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
    selector: 'library-toolbox',
    templateUrl: './library-toolbox.component.html'
})
export class LibraryToolboxComponent implements OnInit, AfterViewInit {
    private currentLang: string;

    constructor(
        private translationService: TranslationService,
        private sanitizer: DomSanitizer,
        private ref: ChangeDetectorRef,
    ) { }

    ngOnInit(): void {
        this.currentLang = this.translationService.getCurrentLang();
    }

    ngAfterViewInit() {
        this.ref.detach()
    }

    getGeniallyUrl(): SafeResourceUrl {
        if (this.currentLang === 'fr') {
            return this.sanitizer.bypassSecurityTrustResourceUrl('https://view.genial.ly/5c18f56a363ee256b5899904');
        } else if (this.currentLang === 'nl') {
            return this.sanitizer.bypassSecurityTrustResourceUrl('https://view.genial.ly/5c18f56a363ee256b5899904');
        } else {
            return this.sanitizer.bypassSecurityTrustResourceUrl('https://view.genial.ly/609538622e2ba00d84d492f9');
        }
    }
}
