<ng-container *ngIf="!controlName; else formTemplate">
    <input type="checkbox" [id]="id" [checked]="value" [disabled]="isDisabled" (change)="onChange($event)">
</ng-container>

<ng-template #formTemplate>
    <input type="checkbox" [id]="id" [checked]="value" [disabled]="isDisabled" (change)="onChange($event)" [formControlName]="controlName">
</ng-template>

<label class="{{ type }}" [for]="id"></label>
<ng-container *ngIf="label">
    <label [for]="id" class="description">{{ label }}</label>
</ng-container>
