import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DataQualityProvider, Journey, JourneyProvider } from '@stuplay';
import { ToastService } from '../../../../utils/components/toast/toast.service';
import PerfectScrollbar from 'perfect-scrollbar';
import { LanguageProvider } from 'src/app/providers/language';
import { StorageService } from 'src/app/utils/services/storage.service';
import { ExternalAppsService } from 'src/app/utils/services/external-apps.service';
import { tap } from 'rxjs';

@Component({
    selector: 'journey-sidebar-create',
    templateUrl: './journey-sidebar-create.component.html',
    styleUrls: ['./journey-sidebar-create.component.scss']
})
export class JourneySidebarCreateComponent implements OnInit {
    @Input() journey: Journey;
    @Output() close: EventEmitter<any> = new EventEmitter;
    @Output() create: EventEmitter<any> = new EventEmitter;
    public me: any;
    public company: any;
    public limitActive: boolean = false;
    public data: any;
    public active: boolean = false;
    public duration: number;
    public type: string = 'd';
    public languages: any;
    public companiesAuthor: any;
    public selectedLanguage: any;
    public selectedCompany: any;
    public filteredLanguages: any[];
    public displayDropdown: any = {
        languages: false,
        companies: false
    };
    public filterInput;

    constructor(
        private journeyProvider: JourneyProvider,
        private toastService: ToastService,
        private languageProvider: LanguageProvider,
        private dataQualityProvider: DataQualityProvider,
        private externalAppsService: ExternalAppsService,
        private storageService: StorageService
    ) { }

    ngOnInit() {
        this.me = this.storageService.get('me');
        this.company = this.storageService.get('company');
        this.activeEffect();

        this.selectedLanguage = this.journey && this.journey.language ? this.journey.language : this.me.language;
        this.selectedCompany = this.journey ? this.journey.company : undefined;

        this.data = {
            name: '',
            title: '',
            media: '',
            duration: '1',
            language: this.selectedLanguage,
            skills: '',
            type: 'digital-learning',
            usersLimit: '',
            autoReminders: 0,
            isCertificable: false
        };
        if (this.journey) {
            this.transform(this.journey);
        }

        const ps = new PerfectScrollbar('.sidebar__container');

        this.languageProvider.get('content').subscribe((resp) => {
            this.languages = resp;
            this.filteredLanguages = this.languages;
        });

        this.onQueryCompaniesAuthor();
    }

    activeEffect(): void {
        const timeOut = setTimeout(() => {
            this.active = true;
            clearTimeout(timeOut);
        }, 100);
    }

    transform(journey: Journey): void {
        this.data = {...this.data, ...journey};
        this.reverseDuration();
        this.limitActive = (this.data.usersLimit > 0);
    }

    displayed(key: string): void {
        this.displayDropdown[key] = !this.displayDropdown[key];
    }

    getEditCompanyContentParams(id: number) {
        return {
            company_id: this.selectedCompany.id,
            contents: [{
                context: 'traject',
                context_id: id
            }]
        }
    }

    selectCompany(company): void {
        this.selectedCompany = company;
    }

    selectLanguage(language: any): void {
        this.selectedLanguage = language;
        this.data.language = language;
    }

    filterLanguages(event: string) {
        if (typeof event === 'string') {
            this.filteredLanguages = this.languages.filter(lang => lang.name ? lang.name.toLowerCase().startsWith(event.toLowerCase()) : false);
        }
    }

    setCompanyOwnership(data) {
        this.companiesAuthor = data;

        if (!this.selectedCompany) {
            const company = data.find(c => c.id === this.company.id);
            this.selectCompany(company);
        }
    }

    onQueryCompaniesAuthor() {
        this.dataQualityProvider
            .getCompanies({ role: 'author' })
            .pipe(
                tap(resp => this.setCompanyOwnership(resp.companies))
            )
            .subscribe()
    }

    onUpdateMedia(media: any): void {
        this.data.media_id = (media) ? media.id : '';
    }

    closeSidebar(): void {
        this.active = false;
        const timeOut = setTimeout(() => {
            this.close.emit(true);
            clearTimeout(timeOut);
        }, 300);
    }

    isCompanyOwnershipChanged(): boolean {
        if (this.journey.company) {
            return this.journey.company.id !== this.selectedCompany.id;
        }

        return true;
    }

    isFormValid(): boolean {
        if (this.data.title === '') {
            this.toastService.push('journey-name-required', 'warning');
            return false;
        }

        if (!this.selectedCompany) {
            this.toastService.push('company-ownership-required', 'warning');
            return false;
        } 

        return true;
    }

    createJourney(): void {
        if (this.isFormValid()) {
            this.data.name = this.data.title;
            this.data.usersLimit = (this.limitActive) ? this.data.usersLimit : 0;
            this.data.duration = this.processDuration(this.duration);

            this.journeyProvider.createJourney(this.data).subscribe((journey) => {
                this.active = false;
                this.dataQualityProvider.editCompanyContent(this.getEditCompanyContentParams(journey.id));
                
                const timeOut = setTimeout(() => {
                    this.create.emit(journey);
                    clearTimeout(timeOut);
                }, 300);
            });
        }
    }

    updateJourney(): void {
        if (this.isFormValid()) {
            this.data.name = this.data.title;
            this.data.usersLimit = (this.limitActive) ? this.data.usersLimit : 0;
            this.data.duration = this.processDuration(this.duration);

            this.journeyProvider.updateJourney(this.data).subscribe((journey) => {
                this.active = false;

                if (this.isCompanyOwnershipChanged()) {
                    this.dataQualityProvider.editCompanyContent(this.getEditCompanyContentParams(journey.id));
                }

                const timeOut = setTimeout(() => {
                    this.create.emit(journey);
                    clearTimeout(timeOut);
                }, 300);
            });
        }
    }

    reverseDuration(): void {
        this.duration = this.data.duration;

        if (this.duration % 60 === 0) {
            this.duration /= 60;
            this.type = 'm'
        }

        if (this.duration % 60 === 0) {
            this.duration /= 60;
            this.type = 'h';
        }

        if (this.duration % 24 === 0) {
            this.duration /= 24;
            this.type = 'd';
        }
    }

    processDuration(time: number): number {
        let duration = time;

        switch (this.type) {
            case 'd':
                duration *= 3600 * 24;
                break;
            case 'h':
                duration *= 3600;
                break;
            case 'm':
                duration *= 60;
                break;
        }

        return duration;
    }
}
