import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'truncate'
})
export class TruncatePipe implements PipeTransform {

    transform(value: string, arg: string, end: string = '...'): string {
        if (value) {
            const limit = arg ? parseInt(arg, 10) : 10;

            return value.length > limit ? value.substring(0, limit) + end : value;
        }
        return null;
    }
}
