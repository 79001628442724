import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CampProvider } from '@stuplay';
import { StorageService } from '../../../../utils/services/storage.service';
import { NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import PerfectScrollbar from 'perfect-scrollbar';

@Component({
    selector: 'instance-sidebar-camp',
    templateUrl: './instance-sidebar-camp.component.html',
    styleUrls: ['./instance-sidebar-camp.component.less']
})
export class InstanceSidebarCampComponent implements OnInit {
    @Input() instances: any[];
    @Output() settingsConfiguration: EventEmitter<any> = new EventEmitter;
    @Output() close: EventEmitter<any> = new EventEmitter;
    public currentCompany: any;
    public unpublishedCamps: any[] = [];
    public trainersList: number[] = [];
    public missingsList: any[] = [];
    public unpublished: boolean = false;
    public campsSelected: any[] = [];
    public warning: boolean = false;
    public active: boolean = false;
    public companies: any[] = [];
    public query: string;
    public delete: any;
    public displays: boolean[] = [];
    public loading: boolean = false;

    constructor(
        private campProvider: CampProvider,
        private storageService: StorageService,
        private modalService: NgbModal,
    ) { }

    ngOnInit() {
        this.activeEffect();
        this.hydrateTrainers();

        this.getCamps();

        this.currentCompany = this.storageService.get('company');

        this.delete = {
            savedContents: false,
            notifySavedContents: false
        };

        const ps = new PerfectScrollbar('.sidebar__container');
    }

    displayed(index: number): void {
        this.displays[index] = !this.displays[index];
    }

    getCamps(): void {
        this.loading = true;

        this.campProvider.getAllCamps('instance', this.instances[0].id, '').subscribe((data) => {
            this.loading = false;
            this.companies = data;

            for (let i = 0; i < this.companies.length; i++) {
                if (this.companies[i].slug === this.currentCompany.slug) {
                    const company = this.companies[i];
                    this.companies.splice(i, 1);
                    this.companies.unshift(company);
                    this.displays[0] = true;
                }
            }

            this.campsSelected = [];
            const selectedCamps = this.storageService.getFlash('selectedCamps');
            if (selectedCamps) {
                for (const camp of selectedCamps) {
                    this.chooseCamp(camp);
                }
            } else {
                for (const company of this.companies) {
                    for (const camp of company.camps) {
                        if (camp.published) {
                            this.unpublished = true;
                            this.chooseCamp(camp);
                        }
                    }
                }
            }
        });
    }

    activeEffect(): void {
        setTimeout(() => {
            this.active = true;
        }, 100);
    }

    hydrateTrainers(): void {
        this.instances.forEach((instance) => {
            if (instance.trainers) {
                instance.trainers.forEach((trainer: any) => {
                    if (this.trainersList.indexOf(trainer.id) === -1) {
                        this.trainersList.push(trainer.id);
                    }
                });
            }
        });
    }

    checkIfCampsSelected(): void {
        for (let i = 0; i < this.companies.length; i++) {
            for (const camp of this.companies[i].camps) {
                const index = this.campsSelected.findIndex((data) => {
                    return data.id === camp.id;
                });

                if (index !== -1) {
                    this.displays[i] = true;
                }
            }
        }
    }

    chooseCamp(camp: any): void {
        const index = this.campsSelected.findIndex((data) => {
            return data.id === camp.id;
        });

        if (index === -1) {
            this.campsSelected.push(camp);
            this.checkIfMissing(camp);
            this.setUnpublishedCamp(camp);
        } else {
            this.campsSelected.splice(index, 1);
            this.setUnpublishedCamp(camp, true);

            const missingFound = this.missingsList.indexOf(camp.id);
            if (missingFound > -1) {
                this.warning = false;
                this.missingsList.splice(missingFound, 1);
            }
        }
    }

    setUnpublishedCamp(camp: any, add?: boolean): void {
        if (camp.published) {
            if (add) {
                this.unpublishedCamps.push(camp);
            } else {
                const index = this.unpublishedCamps.findIndex((data) => {
                    return data.id === camp.id;
                });

                if (index !== -1) {
                    this.unpublishedCamps.splice(index, 1);
                }
            }
        }
    }

    checkIfMissing(camp: any): void {
        let missing = false;

        this.trainersList.forEach((trainer) => {
            if (camp.trainers.indexOf(trainer) === -1) {
                missing = true;
            }
        });

        if (missing) {
            this.warning = true;
            this.missingsList.push(camp.id);
        }
    }

    isChoosed(camp: any): boolean {
        return this.campsSelected.findIndex((data) => {
            return data.id === camp.id;
        }) > -1;
    }

    publish(content: any): void {
        if (this.campsSelected.length > 0) {
            if (this.unpublished && this.unpublishedCamps.length > 0) {
                this.openModal(content);
            } else {
                this.settingsConfiguration.emit(this.campsSelected);
            }
        }
    }

    openModal(content: any) {
        const options: NgbModalOptions = { size: 'lg', backdropClass: 'backdrop-custom' };
        this.modalService.open(content, options);
    }

    closeSidebarEmit(): void {
        this.active = false;
        setTimeout(() => {
            this.close.emit(true);
        }, 300);
    }

    reset(): void {
        this.query = '';
        this.search();
    }

    search() {
        this.companies = [];
        this.displays = [];
        this.loading = true;

        this.campProvider.getAllCamps('instance', this.instances[0].id, this.query).subscribe((data) => {
            this.loading = false;
            this.companies = data;

            for (let i = 0; i < this.companies.length; i++) {
                if (this.currentCompany.slug === this.companies[i].slug) {
                    const company = this.companies[i];
                    this.companies.splice(i, 1);
                    this.companies.unshift(company);
                    this.displays[0] = true;
                } else if (this.query) {
                    this.displays[i] = true;
                }
            }

            this.checkIfCampsSelected();
        });
    }

    closeWarning(): void {
        this.warning = false;
    }

    next(): void {
        this.modalService.dismissAll();
        this.storageService.set('savedContentsSettings', this.delete);
        this.settingsConfiguration.emit(this.campsSelected);
    }

    closeUnpublished(): void {
        this.delete = {
            savedContents: false,
            notifySavedContents: false
        };

        this.modalService.dismissAll();
        this.getCamps();
    }
}
