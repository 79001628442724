<div class="templab-summary__button templab-summary__button--transition">
    <button class="templab-btn templab-btn-transition" (click)="openTransition()" *ngIf="!conditionDate && !conditionScore && content.context !== 'event'">
        {{ 'studio.journey.add-condition' | translate }}
    </button>

    <div class="templab-summary__button__transition-enable templab-summary__button__transition-enable--event" *ngIf="content.context === 'event'">
        <div class="templab-summary__button__transition-enable__text">{{ 'studio.journey.condition.before-event' | translate }}</div>
    </div>

    <div class="templab-summary__button__transition-enable" *ngIf="conditionDate || conditionScore">
        <div class="templab-summary__button__transition-enable__text" *ngIf="first">
            <div *ngIf="conditionDate && conditionDate.type === 'date'">
                {{ 'studio.journey.condition.start-journey' | translate }} {{ 'words.the' | translate }} <b>{{ conditionDate.value | date: 'dd/MM/yyyy' }}</b>
            </div>

            <div *ngIf="conditionDate && conditionDate.type === 'subscription_date'">
                {{ 'studio.journey.condition.start-journey' | translate }} <b>{{ conditionDate.value }} {{ 'studio.journey.condition.after-subscription' | translate }}</b>
            </div>

            <div *ngIf="conditionScore">
                {{ 'studio.journey.score' | translate }} : <b>{{ conditionScore.value }} {{ '%' }}</b>
            </div>
        </div>

        <div class="templab-summary__button__transition-enable__text" *ngIf="!first">
            <div *ngIf="conditionDate && conditionDate.type === 'date'">
                {{ 'studio.journey.condition.active' | translate }} {{ 'words.the' | translate }} <b>{{ conditionDate.value | date: 'dd/MM/yyyy' }}</b>
            </div>

            <div *ngIf="conditionDate && conditionDate.type !== 'date'">
                {{ 'studio.journey.condition.active' | translate }}
                <b>{{ conditionDate.value }} {{ ((conditionDate.type === 'subscription_date') ? 'studio.journey.condition.after-subscription' : 'studio.journey.condition.after-activity') | translate }}</b>
            </div>

            <div *ngIf="conditionScore">
                {{ 'studio.journey.score' | translate }} : <b>{{ conditionScore.value }} {{ '%' }}</b>
            </div>
        </div>

        <div class="templab-summary__button__transition-enable__actions" (click)="openTransition()">
            <i class="templab-icon templab-icon-more-horizontal"></i>
        </div>
    </div>
</div>

<aside class="sidebar sidebar--modal" *ngIf="active" [ngClass]="activeAside ? 'active' : ''" (appSidebar)="closeSidebar()">
    <div class="sidebar__container">
        <div class="sidebar__head">
            <div class="sidebar__description">
                <h1 class="sidebar__title">
                    {{ ((first) ? 'studio.journey.condition-title.first' : 'studio.journey.condition-title') | translate }}
                </h1>

                <p class="sidebar__subtitle">
                    {{ ((first) ? 'studio.journey.condition.enable-journey' : 'studio.journey.condition.description') | translate }}
                </p>
            </div>

            <a class="sidebar__close" (click)="closeSidebar()">
                <i class="icon icon--close"></i>
            </a>
        </div>

        <div class="grid" style="margin: 1rem 0 0 0;">
            <label class="input">
                <input type="checkbox" class="input-checkbox" [ngModel]="data.checkedDate" (ngModelChange)="checkedDate()">
                {{ 'studio.journey.condition.fix-date' | translate }}
            </label>

            <label class="input">
                <input type="text" [(ngModel)]="data.valueDate" [disabled]="!data.checkedDate" style="margin: 0 0 0 5rem; width: 15rem; display: inline-block;" (click)="activeDatepicker()">
                <msc-datepicker *ngIf="datePickerActive" [date]="data.valueDate" (onSelectDate)="selectedDate($event)" (close)="activeDatepicker(true)" style="position: absolute; z-index: 10000;"></msc-datepicker>
            </label>
        </div>

        <div class="grid" style="margin: 1rem 0 0 0;">
            <label class="input">
                <input type="checkbox" class="input-checkbox" [ngModel]="data.checkedDay" (ngModelChange)="checkedDay()">
                {{ 'studio.journey.condition.number-day' | translate }}
            </label>

            <label class="input">
                <input type="number" class="" [(ngModel)]="data.valueDay" (ngModelChange)="data.valueDay = data.valueDay > 0 ? data.valueDay : 0" min="0" [disabled]="!data.checkedDay" style="margin: 0 0.5rem 0 3.5rem; width: 5rem; display: inline-block;">
                {{ ((first) ? 'studio.journey.condition.day-after' : 'words.days') | translate | lowercase }}
            </label>
        </div>

        <div class="" style="margin: 1rem 0 0 13rem;" *ngIf="!first && data.checkedDay">
            <label class="input">
                <input type="checkbox" class="input-checkbox" [ngModel]="data.checkedSignin" (ngModelChange)="checkedOptions(false)">
                {{ 'studio.journey.condition.signin' | translate }}
            </label>

            <label class="input">
                <input type="checkbox" class="input-checkbox" [ngModel]="data.checkedLast" (ngModelChange)="checkedOptions(true)">
                {{ 'studio.journey.condition.last-activity' | translate }}
            </label>
        </div>

        <div class="container-score">
            <label class="input" style="width: 25%">
                <input type="checkbox" class="input-checkbox" [ngModel]="activeScore" (click)="enableScore()">
                {{ 'studio.journey.score' | translate }}
            </label>

            <label class="input" style="width: 75%" *ngIf="activeScore">
                <input class="m-slider" type="range" min="1" max="100" value="50" [(ngModel)]="score">
                {{ score + '%'}}
            </label>
        </div>
    </div>

    <footer class="sidebar__footer">
        <div class="sidebar__btn">
            <div class="sidebar__btn-right grid">
                <a class="button button--small button--border" (click)="closeSidebar()" *ngIf="!conditionDate && !conditionScore">
                    {{ 'studio.common.button.cancel' | translate }}
                </a>

                <a class="button button--small button--color" (click)="addTransition()" *ngIf="!conditionDate && !conditionScore">
                    {{ 'studio.journey.add-condition' | translate }}
                </a>

                <a class="button button--small button--border" (click)="deleteTransition()" *ngIf="conditionDate || conditionScore">
                    {{ 'studio.common.button.delete' | translate }}
                </a>

                <a class="button button--small button--color" (click)="updateTransition()" *ngIf="conditionDate || conditionScore">
                    {{ 'words.modify' | translate }}
                </a>
            </div>
        </div>
    </footer>
</aside>

<style>
    .container-score {
        padding: 1rem 0 0 0;
        display: flex;
    }

    .m-slider {
        -webkit-appearance: none;
        width: 70%;
        height: 0.3rem;
        border-radius: 5px;
        background: #cad8ed;
        outline: none;
        margin: 0 2rem;
    }

    .m-slider::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 0.7rem;
        height: 0.7rem;
        border-radius: 50%;
        background: #5B70FF;
        cursor: pointer;
    }

    .m-slider::-moz-range-thumb {
        width: 0.7rem;
        height: 0.7rem;
        border-radius: 50%;
        background: #5B70FF;
        cursor: pointer;
    }
</style>
