import { HttpClient } from '@angular/common/http';
import { Observable } from "rxjs";
import { map } from 'rxjs/operators';
import { Media } from '../../../../media';
import { cleanObject, getParams } from "../../../../helpers";
import { environment } from 'src/environments/environment';

export class Certificate {
    id: number;
    type: string;
    title: string;
    duration: number;
    description: string;
    validityDuration: number;
    gender: string;
    firstName: string;
    lastName: string;
    companyName: string;
    jobTitle: string;
    email: string;
    phoneNumber: number;
    address: string;
    media: Media;
    hash: string;
    minimumRatio: number;
    token: string;
    sendEmail: boolean;
    global: boolean;

    constructor(certificate: any, protected http: HttpClient) {
        this.extends(certificate);
    }

    extends(certificate: any): Certificate {
        Object.assign(this, certificate);
        return this;
    }

    update(): Observable<Certificate> {
        return this.http.put(`${environment.envVar.API_URL}/studio/certificate/${this.id}`, cleanObject(this))
            .pipe(map(res => this.extends(res)));
    }

    download(): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/certificate/${this.hash}/download`, {responseType: 'blob'});
    }

    sendMail(courseId: number, companyId?: number): Observable<any> {
        const data = {
            courseId: courseId,
            companyId: companyId ? companyId : null
        };

        return this.http.post(`${environment.envVar.API_URL}/studio/certificate/${this.hash}/email`, data);
    }

    checkAchieved(courseId: number, sectionContentSessionId: number, companyId?: number): Observable<any> {
        const params = {
            course_id: courseId,
            section_content_session_id: sectionContentSessionId,
            companyId: companyId ? companyId: null
        };

        return this.http.get(`${environment.envVar.API_URL}/studio/certificate/${this.hash}/achieved`, {params: getParams(params)});
    }
}
