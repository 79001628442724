<div *ngIf="currentUser" id="dropdown" class="dropdown account" [ngClass]="{'dropdown_active': isOpen}">
    <div (click)="toggleDropdown()" class="dropdown__context grid middle">
        <img class="avatar" [src]="currentUser.picture +'?size=64'" style="height: 2.75rem; width: auto;">
    </div>

    <div class="dropdown__content dropdown__content-left">
        <div class="dropdown-member dropdown-member-left">
            <div class="title">
                <span>
                    {{'topbar.account.my-account' | translate }}
                </span>
            </div>

            <div class="dropdown-member-list">
                <a (click)="navigateProfile()" class="dropdown-member-list__item">
                    <img src="https://static.myskillcamp.com/images/icon/icon-camp/icon_profil.svg" alt="">

                    <div>
                        <div class="head">{{'topbar.account.profile' | translate }}</div>
                        <p>{{ currentUser.name }}</p>
                    </div>
                </a>

                <a [href]="'https://support.myskillcamp.com/' + currentUser.lang" class="dropdown-member-list__item" target="_blank">
                    <img src="https://static.myskillcamp.com/images/icon/icon-camp/icon_help.svg" alt="">

                    <div>
                        <div class="head">{{'topbar.account.faq' | translate }}</div>
                        <p>{{'topbar.account.faq.description' | translate }}</p>
                    </div>
                </a>

                <!--<button (click)="logout()" class="dropdown-member-list__item logout">-->
                    <!--<img src="https://static.myskillcamp.com/images/icon/icon-camp/icon_exit.svg" alt="">-->
                    <!--<div>-->
                        <!--<div class="head">{{'topbar.account.log-out' | translate }}</div>-->
                        <!--<p>{{'topbar.account.log-out.description' | translate }}</p>-->
                    <!--</div>-->
                <!--</button>-->
            </div>
        </div>
    </div>
</div>
