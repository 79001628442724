<ng-container *ngIf="type === 'folder'">
    <header class="box__folder-header grid middle end">
        <a class="box__btn-edit dropdown__click" (click)="changeOpenDropdown(); stopPropagation($event);" *ngIf="item.editable">
            <i class="icon icon--more"></i>
        </a>

        <ul class="dropdown__sub" [ngClass]="{'active' : openDropdown}" [(autoClose)]="openDropdown">
            <li class="dropdown__item" *ngIf="item.editable">
                <a class="dropdown__link" (click)="renameFolder(); stopPropagation($event);">
                    <i class="new-icon icon-edit-outline"></i> {{ 'words.rename' | translate }}
                </a>
            </li>

            <li class="dropdown__item">
                <a class="dropdown__link" (click)="openMoveTo(item); stopPropagation($event);">
                    <img src="https://static.myskillcamp.com/images/icon-deplace.svg" alt="icon-deplace"> {{ 'words.move' | translate }}
                </a>
            </li>

            <li class="dropdown__item">
                <a class="dropdown__link" (click)="openDeleteFolder(item, modalDeleteFolder); stopPropagation($event);">
                    <img src="https://static.myskillcamp.com/images/icon-delete.svg" alt="icon-delete"> {{ 'words.delete' | translate }}
                </a>
            </li>
        </ul>
    </header>

    <div class="box__group-folder" [ngClass]="{'editable': !item.editable}">
        <h1 class="box__title" *ngIf="renameFolderActive">
            <live-editor (click)="stopPropagation($event)" class="info__name" [ngClass]="{'info__name_empty': item.title === ''}" [placeholder]="'studio.folder.placeholder-folder' | translate" [mode]="'folder'" [(inputData)]="item.title"
                (onUpdate)="updateFolder(item)">
            </live-editor>
        </h1>

        <h1 class="box__title" *ngIf="!renameFolderActive && item.type === 'default'">
            {{ item.title || ('words.folder-without-title' | translate) }}
        </h1>

        <h1 class="box__title" *ngIf="!renameFolderActive && item.type !== 'default'">
            {{ item.type === 'company' ? ('studio.company-content.folder-title' | translate) + item.title : ('words.folder-title.' + item.title | translate) }}
        </h1>

        <div class="box__description-group grid middle center">
            <p class="box__description grid center">
                <span class="box__number">{{ item.templatesCount }}</span>
                <i class="icon icon--modul"></i>
            </p>

            <p class="box__description grid center" *ngIf="item.type === 'default'">
                <span class="box__number">{{ item.foldersCount }}</span>
                <i class="icon icon--folder"></i>
            </p>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="type === 'template'">
    <div class="card studio-card m-cursor" (click)="editTemplate(item)">
        <div class="card__header">
            <div class="card__action card__action--single">
                <div class="folder__name" *ngIf="item.folderName">
                    <i class="icon icon--folder"></i>
                    <span>{{ item.folderName}}</span>
                </div>

                <div class="more__actions">
                    <a class="link__more" (click)="changeOpenDropdown(); stopPropagation($event);">
                        <i class="new-icon icon-more-horizontal"></i>
                    </a>

                    <div class="dropdown" [ngClass]="{'active' : openDropdown}" [(autoClose)]="openDropdown">
                        <ul class="list__action" style="cursor:auto">
                            <li class="item__action" (click)="previewTemplate(); stopPropagation($event);">
                                <img src="https://static.myskillcamp.com/images/icon-apercu.svg" alt="icon-preview">
                                <a style="text-align: left">{{ 'studio.course.preview' | translate }}</a>
                            </li>

                            <li class="item__action" (click)="openMoveTo(item); stopPropagation($event);" *ngIf="configurable && !sharedCourses">
                                <img src="https://static.myskillcamp.com/images/icon-deplace.svg" alt="icon-move">
                                <a>{{ 'words.move' | translate }}</a>
                            </li>

                            <li class="item__action" (click)="cloneTemplate(item); stopPropagation($event);" *ngIf="configurable">
                                <img src="https://static.myskillcamp.com/images/icon-duplicate.svg" alt="icon-duplicate">
                                <a>{{ 'studio.common.button.clone' | translate }}</a>
                            </li>

                            <li 
                            [mscTooltip]="item.hasOnboardings ? ('words.has-onboardings.cannot-delete' | translate) : null"
                            [ngClass]="{ 'generic-disabled': item.hasOnboardings }" class="item__action" (click)="!item.hasOnboardings ? openDeleteTemplate(item, modalDeleteTemplate) : null; stopPropagation($event);" *ngIf="configurable">
                                <img src="https://static.myskillcamp.com/images/icon-delete.svg" alt="icon-delete">
                                <a>{{ 'studio.common.button.delete' | translate }}</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="card__image">
            <img *ngIf="!item.picture" src="https://static.myskillcamp.com/images/images_random/empty-state-7.jpg" alt="media">
            <img *ngIf="item.picture" [src]="item.picture + '?size=512'" alt="media">
        </div>

        <div class="card__content card__content--height">
            <a class="card__title">
                {{ (item.title | truncate: 35) || ('words.template-without-title' | translate) }}
                <span class="card__title-tooltip" *ngIf="item.title && item.title.length > 35">{{item.title}}</span>
            </a>

            <div class="card__infos">
                <p class="card__owner" *ngIf="item.account">{{ 'words.created-by' | translate }} {{ item.account.name }}</p>

                <div class="card__event">
                    <span>{{ item.instancesCount }}</span>
                    <i class="new-icon icon-calendar-outline"></i>
                </div>
            </div>
        </div>

        <div class="card__footer">
            <button class="card__btn" (click)="(item.instancesCount > 0) ? navigateToInstance(item) : addInstance(item); stopPropagation($event);">
                <i class="icon icon--calendar" *ngIf="item.instancesCount > 0"></i>
                <i class="new-icon icon-plus-circle-outline" *ngIf="item.instancesCount === 0"></i> {{ (item.instancesCount > 0 ? 'studio.instance.manage' : 'studio.template.add') | translate }}
            </button>

            <a class="link__edit" (click)="editTemplate(item); stopPropagation($event);">
                <i class="new-icon icon-edit-outline"></i>
            </a>
        </div>
    </div>
</ng-container>

<ng-template #modalDeleteFolder let-modal>
    <div class="modal-header">
        <span></span>
        <button type="button" class="close" aria-label="Close" (click)="modal.close()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div style="background-color: #FFFFFF; padding: 5rem; text-align: center;">
            <span *ngIf="currentFolder && currentFolder.templatesCount > 1">
                {{ 'studio.folder.delete-hint-1' | translate }}{{ currentFolder.templatesCount }}{{ 'studio.folder.delete-hint-2' | translate }}
            </span>
            <span *ngIf="currentFolder && currentFolder.templatesCount === 1">{{ 'studio.folder.delete-hint-single' | translate }}</span>
            <span *ngIf="currentFolder && currentFolder.templatesCount < 1">{{ 'studio.folder.delete-hint-empty' | translate }}</span>

            <div class="actions" style="padding-top: 2rem;">
                <button class="btn btn_color_red" (click)="deleteFolder(currentFolder); modal.close()">
                    {{ 'studio.common.button.delete' | translate }}
                </button>

                <button class="btn btn_color_grey" (click)="modal.close()">
                    {{ 'studio.common.button.cancel' | translate }}
                </button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #modalDeleteTemplate let-modal>
    <div class="modal-header">
        <span></span>
        <button type="button" class="close" aria-label="Close" (click)="modal.close()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div style="background-color: #FFFFFF; padding: 1.2rem 0 4rem 0; text-align: center;">
            <div class="m-title" [innerHTML]="'studio.delete-course.confirmation' | translate"></div>

            <div class="m-content-modal m-grey" *ngIf="currentTemplate">
                <div class="box__grid grid middle" style="width: 50%;">
                    <a>
                        <div class="box__session-name">
                            <h1 class="box__title">{{ currentTemplate.title }}</h1>
                        </div>
                    </a>
                </div>

                <div class="box__list grid middle" style="width: 10%; justify-content: center;">
                    <span class="box__strong">{{ currentTemplate.campsCount }}</span>
                    <i class="icon icon--camp"></i>
                    <div class="tooltip__info" *ngIf="currentTemplate.campsCount > 0">
                        {{ currentTemplate.campsNames }}
                    </div>
                </div>

                <div class="box__list grid middle" style="width: 10%;">
                    <p style="margin: 0;">
                        <span class="box__strong" style="margin: 0;">{{ currentTemplate.usersCount }}</span>
                    </p>
                    <i class="icon icon--users"></i>
                </div>
            </div>

            <div class="m-notify">
                <label>
                    <input type="checkbox" [(ngModel)]="notifyUsers"> {{ 'instance.invite-notify' | translate }}
                </label>
            </div>

            <div class="actions" style="padding-top: 2rem;">
                <button class="btn btn_color_red" (click)="deleteTemplate(currentTemplate); modal.close()">
                    {{ 'studio.common.button.delete' | translate }}
                </button>

                <button class="btn btn_color_grey" (click)="modal.close()">
                    {{ 'studio.common.button.cancel' | translate }}
                </button>
            </div>
        </div>
    </div>
</ng-template>

<style>
    .editable {
        margin: 1.75rem 0 0 0;
    }

    .header--template.box__folder-header:after {
        background-color: transparent !important;
    }

    .header--template.box__folder-header .box__editor {
        font-size: 20px;
        margin: 0.5rem;
    }

    .m-cursor {
        cursor: pointer;
    }

    .m-title {
        font-size: 1.2rem;
        font-weight: bold;
    }

    .m-content-modal {
        margin: 2rem;
        padding: 1rem 0;
        display: flex;
        justify-content: center;
    }

    .m-grey {
        background-color: #F4F7FB;
    }

    .m-notify label {
        font-size: 1rem;
        font-weight: 600;
    }

    .item__action a {
        font-weight: 600;
    }

    .item__action img {
        margin-left: 10px;
    }

    .icon-edit-outline {
        color: #dfe0e4;
    }
</style>
