import { Component, Input, Output, EventEmitter } from '@angular/core';
import { TimelineProvider } from '@stuplay';
import { StorageService } from 'src/app/utils/services/storage.service';

@Component({
    selector: 'msc-post',
    templateUrl: './post.component.html'
})
export class PostComponent {
    @Input() post: any;
    @Input() timelineId: any;
    @Output() onDelete: EventEmitter<any> = new EventEmitter();
    isFormer: boolean = false;

    constructor(
        private timelineProvider: TimelineProvider,
        private storageService: StorageService,
    ) { }

    addComment(comment: any, postId: number, postForm: any): void {
        if (comment !== '') {
            const params = {
                content: comment,
                postId: this.post.id,
                companyId: this.storageService.get('company')?.id
            }

            this.timelineProvider.addComment(this.post.id, params).subscribe((data) => {
                this.post.comments.push(data);
                postForm.reset();
            });
        }
    }

    onDeletePost(): void {
        this.timelineProvider.deletePost(this.timelineId, this.post.id).subscribe(() => {
            this.onDelete.emit(this.post.id);
        });
    }

    deleteComment(id: any): void {
        this.timelineProvider.deleteComment(id).subscribe(() => {
            const index = this.post.comments.findIndex((comment) => {
                return comment.id === id;
            });

            if (index > -1) {
                this.post.comments.splice(index, 1);
            }
        });
    }
}
