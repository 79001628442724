<div class="upload" style="margin-bottom: 2rem" *ngIf="selfAssessment.type === 'media' || selfAssessment.type === 'both'">
    <label class="upload__placeholder" style="cursor: not-allowed;">
        <div class="title">
            {{ 'studio.project.media.upload.title' | translate }}
        </div>

        <div class="content">
            <span>{{ 'studio.media.upload.max-weight' | translate }}</span>
            <input style="text-align: center; width: auto; margin-top: 1rem;" type="file" disabled>
        </div>
    </label>
</div>

<msc-froala *ngIf="selfAssessment.type === 'text' || selfAssessment.type === 'both'" [editable]="false" [height]="200"></msc-froala>

<p *ngIf="selfAssessment.type === 'none'">{{ 'studio.selfassessment.no-requirements' | translate }}</p>
