import { Component, Input } from '@angular/core';
import { DatePipe } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
//
import { Event, SectionContent, EventSession, EventSessionMedia, EventTrainer, Course } from '@stuplay';
//
import { ErrorCatcherService } from '../../../../../../utils/services/error-catcher.service'
import { StorageService } from '../../../../../../utils/services/storage.service';
import { ToastService } from '../../../../../../utils/components/toast/toast.service';
import { EmitterService } from '../../../../../../utils/services/emitter.service';
import { ExternalAppsService } from '../../../../../../utils/services/external-apps.service';
import { environment } from 'src/environments/environment';

const DATE_DEFAULT_FORMAT: string = 'YYYY/MM/dd HH:mm:ss';

@Component({
    selector: 'app-event-single-session',
    templateUrl: './event-single-session.component.html',
    styleUrls: ['./event-single-session.component.less'],
    providers: [DatePipe],
})

export class EventSingleSessionComponent {
    @Input() event: Event;
    @Input() eventSession: EventSession;
    @Input() sectionContent: SectionContent;
    @Input() trajectId: any;

    private company: any;
    private me: any;
    public course: Course;
    public account: any;
    public tagsArray: string[];
    public startDate: Date | string;
    public endDate: Date | string;
    public replaceStartDate: Date | string;
    public replaceEndDate: Date | string;
    public startHour: any;
    public endHour: any;
    public error: any;
    public range: number[] = [];
    public date: any;
    public options: any;
    public queryTrainer: string = '';
    public availableTrainers: any[];
    public focused: boolean = false;

    constructor(
        public http: HttpClient,
        private errorCatcher: ErrorCatcherService,
        private storageService: StorageService,
        private toastService: ToastService,
        private emitterService: EmitterService,
        private externalAppsService: ExternalAppsService,
        private datePipe: DatePipe,
    ) { }

    ngOnInit(): void {
        this.account = this.storageService.get('me');
        this.company = this.storageService.get('company');
        this.course = this.storageService.get('course');
        this.me = this.storageService.get('me');
        this.tagsArray = this.event.tags;
        this.setDates();
        this.reloadButtonEventMedia();
        this.setCalendar();
    }

    /**
     *
     */
    setDates() {
        if (!this.eventSession.startAt || !this.eventSession.endAt) {
            const startDate = new Date();
            startDate.setHours(9);
            const endDate = new Date();
            endDate.setHours(17);
            endDate.setMinutes(30);

            this.startDate = this.datePipe.transform(startDate, DATE_DEFAULT_FORMAT);
            this.endDate = this.datePipe.transform(endDate, DATE_DEFAULT_FORMAT);
            this.eventSession.startAt = this.startDate;
            this.eventSession.endAt = this.endDate;
            this.setUpdateEvent();
        } else {
            this.startDate = this.eventSession.startAt;
            this.endDate = this.eventSession.endAt;
        }
    }

    /**
     *
     */
    setUpdateEvent() {
        this.eventSession.startAt = this.datePipe.transform(new Date(this.eventSession.startAt), DATE_DEFAULT_FORMAT);
        this.eventSession.endAt = this.datePipe.transform(new Date(this.eventSession.endAt), DATE_DEFAULT_FORMAT);
        this.eventSession.companyId = this.company.id;
        this.event.companyId = this.company.id;
        this.setEventSubs();
    }

    /**
     *
     */
    setEventSubs() {
        this.eventSession
            .update()
            .pipe()
            .subscribe({
                next: () => this.error = null,
                error: (error) => this.error = error
            });

        this.event
            .update()
            .pipe()
            .subscribe({
                error: (error) => this.error = error
            });
    }

    /**
     *
     */
    setCalendar() {
        this.date = {
            from: {
                year: new Date(this.eventSession.startAt).getFullYear(),
                month: new Date(this.eventSession.startAt).getMonth(),
                day: new Date(this.eventSession.startAt).getDate(),
            },
            to: {
                year: new Date(this.eventSession.endAt).getFullYear(),
                month: new Date(this.eventSession.endAt).getMonth(),
                day: new Date(this.eventSession.endAt).getDate(),
            }
        };

        this.options = {
            theme: 'default',
            menu: [
                { alias: 'td', text: 'Today', operation: '0d' },
                { alias: 'tm', text: 'This Month', operation: '0m' },
                { alias: 'lm', text: 'Last Month', operation: '-1m' },
                { alias: 'tw', text: 'This Week', operation: '0w' },
                { alias: 'lw', text: 'Last Week', operation: '-1w' },
            ],
            dateFormat: 'yMd',
            outputFormat: 'yyyy/MM/dd',
            outputType: 'object',
            startOfWeek: 0,
            date: this.date,
            locale: this.account.language.code,
            labels: []
        };

        this.startHour = this.datePipe.transform(new Date(this.eventSession.startAt), 'HH:mm');
        this.endHour = this.datePipe.transform(new Date(this.eventSession.endAt), 'HH:mm');

        this.replaceStartDate = this.startHour;
        this.replaceEndDate = this.endHour;
    }

    /**
     *
     */
    reloadButtonEventMedia(): void {
        this.range = [0];
        for (let i = 0; i < this.event.eventSessions[0].eventSessionMedia.length; i++) {
            this.range.push(i + 1);
        }
    }

    /**
     *
     */
    updateSectionContent(): void {
        this.event.title = this.sectionContent.title;
        this.sectionContent
            .update(this.trajectId ? { traject_id: this.trajectId } : undefined)
            .pipe()
            .subscribe({
                next: (sectionContent) => {
                    this.errorCatcher.setErrors(sectionContent);
                    this.setUpdateEvent();
                },
                error: (error) => this.error = error
            });
    }

    /**
     *
     */
    onUpdateMedia(media: any): void {
        this.event.media = media;
        this.setUpdateEvent();
    }

    /**
     *
     */
    updateDate(): void {
        const startDate = new Date(this.date.from);
        startDate.setHours(parseInt(this.startHour));

        const endDate = new Date(this.date.to);
        endDate.setHours(parseInt(this.endHour));

        this.eventSession.startAt = this.datePipe.transform(startDate, DATE_DEFAULT_FORMAT);
        this.eventSession.endAt = this.datePipe.transform(endDate, DATE_DEFAULT_FORMAT);
        this.startDate = this.eventSession.startAt;
        this.endDate = this.eventSession.endAt;
        this.formatDate();
        this.setUpdateEvent();
    }

    /**
     *
     */
    formatDate(): void {
        if (this.eventSession.startAt) {
            this.date = {
                from: {
                    year: new Date(this.eventSession.startAt).getFullYear(),
                    month: new Date(this.eventSession.startAt).getMonth(),
                    day: new Date(this.eventSession.startAt).getDate(),
                },
                to: {
                    year: new Date(this.eventSession.endAt).getFullYear(),
                    month: new Date(this.eventSession.endAt).getMonth(),
                    day: new Date(this.eventSession.endAt).getDate(),
                }
            }
        }

        if (this.eventSession.startAt && this.eventSession.endAt) {
            this.startHour = this.datePipe.transform(new Date(this.eventSession.startAt), 'HH:mm');
            this.endHour = this.datePipe.transform(new Date(this.eventSession.endAt), 'HH:mm');

            this.replaceStartDate = this.startHour;
            this.replaceEndDate = this.endHour;
        }

        this.eventSession.startAt = this.datePipe.transform(new Date(this.eventSession.startAt), DATE_DEFAULT_FORMAT);
        this.eventSession.endAt = this.datePipe.transform(new Date(this.eventSession.endAt), DATE_DEFAULT_FORMAT);
    }

    /**
     *
     */
    updateHour(): void {
        const regex = '^([01]?[0-9]|2[0-3]):[0-5][0-9]$';

        if (this.startHour.match(regex) && this.endHour.match(regex)) {
            const startDate = new Date(this.startDate);
            startDate.setHours(parseInt(this.startHour.split(':')[0]));
            startDate.setMinutes(parseInt(this.startHour.split(':')[1]));

            const endDate = new Date(this.endDate);
            endDate.setHours(parseInt(this.endHour.split(':')[0]));
            endDate.setMinutes(parseInt(this.endHour.split(':')[1]));

            this.eventSession.startAt = this.datePipe.transform(new Date(startDate), DATE_DEFAULT_FORMAT);
            this.eventSession.endAt = this.datePipe.transform(new Date(endDate), DATE_DEFAULT_FORMAT);

            this.formatDate();
            this.setUpdateEvent();
        }

        if (!(this.startHour.match(regex)) && this.endHour.match(regex)) {
            this.startHour = this.replaceStartDate;
        } else if (!(this.endHour.match(regex)) && this.startHour.match(regex)) {
            this.endHour = this.replaceEndDate;
        }
    }

    /**
     *
     */
    onUpdateEventMedia(media: any): void {
        this.event.eventSessions[0].addEventSessionMedia(media.id).subscribe(() => {
            this.reloadButtonEventMedia();
        });
    }

    /**
     *
     */
    onDeleteEventMedia(eventSessionMedia: EventSessionMedia): void {
        this.event.eventSessions[0].deleteEventSessionMedia(eventSessionMedia).subscribe(() => {
            this.reloadButtonEventMedia();
        });
    }

    /**
     *
     */
    searchName(): void {
        if (this.queryTrainer.trim().length > 1) {
            const params = {
                type: 'trainers',
                q: this.queryTrainer,
                event_id: this.event.id
            };

            this.http.get(`${environment.envVar.API_URL}/search/accounts`, { params: this.getParams(params) }).subscribe((data: any) => {
                this.availableTrainers = data.accounts;
            });
        } else {
            this.availableTrainers = [];
        }
    }

    /**
     *
     */
    getParams(params: any): HttpParams {
        let httpParams = new HttpParams();
        for (const p in params) {
            httpParams = httpParams.append(p, params[p]);
        }
        return httpParams;
    }

    /**
     *
     */
    addTrainer(value: string, id?: number): void {
        if (value.trim().length > 1) {
            this.availableTrainers = [];
            this.queryTrainer = '';

            this.event.addEventTrainer(id, value).subscribe(() => {
                this.errorCatcher.setErrors(this.sectionContent);
            });
        }
    }

    /**
     *
     */
    inputTrainer(): void {
        this.addTrainer(this.queryTrainer);
    }

    /**
     *
     */
    deleteTrainer(trainer: EventTrainer): void {
        this.event.deleteEventTrainer(trainer).subscribe(() => {
            this.errorCatcher.setErrors(this.sectionContent);
        });
    }

    /**
     *
     */
    onFocus(isFocus: boolean): void {
        this.focused = isFocus;
    }

    /**
     *
     */
    createVirtualClass(): void {
        const params = {
            event_id: this.event.id,
            company_id: this.company.id
        };

        this.event.createOrUpdateVirtualClass(params).subscribe((data: any) => {
            this.event.url = data.url;
            this.toastService.push('virtual-class.created');
        });
    }

    /**
     *
     */
    openAsideHelp(type: any): any {
        this.emitterService.activeHelp.emit({ type: type, feature: 'exercice' });
    }
}
