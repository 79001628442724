import { Component, Input, Output, EventEmitter, OnInit, HostListener, AfterViewInit } from '@angular/core';
import { Exercise, Media, Question } from '@stuplay';
import { TrueFalseQuestion, CategoryQuestion, SectionContent } from '@stuplay';
import { ErrorCatcherService } from '../../../../../../utils/services/error-catcher.service';
import { EmitterService } from '../../../../../../utils/services/emitter.service';

@Component({
    selector: 'app-question',
    templateUrl: './question.component.html'
})
export class QuestionComponent implements OnInit, AfterViewInit {
    @Input() question: Question | CategoryQuestion | TrueFalseQuestion;
    @Input() exercise: Exercise;
    @Input() sectionContent: SectionContent;
    @Input() indexQuestion: number;
    @Input() displayDeleteAction: boolean = true;
    @Input() settingsClass: string = '';
    @Input() questionClass: string = '';
    @Output() delete: EventEmitter<any> = new EventEmitter;
    public deleteAction: boolean = false;
    public editAction: boolean = false;
    public object: any;
    public position: string = 'right';
    public categories: string[];
    public mediaModalOpen: boolean = false;
    public displayAside: boolean = false;
    public ready: boolean = false;

    constructor(
        private errorCatcher: ErrorCatcherService,
        private emitterService: EmitterService,
    ) { }

    ngOnInit() {
        if (this.question instanceof TrueFalseQuestion) {
            if (!this.question.param.choice) {
                this.question.param.choice = false;
                this.question.update(this.question.type).subscribe();
            }
        }

        if (this.question instanceof CategoryQuestion) {
            if (this.question.content) {
                this.categories = [].concat(this.question.content.categories);
            }
        }
    }

    ngAfterViewInit() {
        const timeOut = setTimeout(() => {
            this.ready = true;
            clearTimeout(timeOut);
        }, 200)
    }

    updateCategoryChoosen(category: string, categoryIndex: number): void {
        if (this.question instanceof CategoryQuestion) {
            this.question.content.choices.forEach((choice) => {
                if (choice.category === this.categories[categoryIndex]) {
                    choice.category = category;
                }
            });
            this.question.content.categories[categoryIndex] = category;
            this.categories[categoryIndex] = category;
        }
    }

    addCategory(): void {
        if (this.question instanceof CategoryQuestion) {
            this.question.content.addCategory(null);
            this.question.update(this.question.type)
                .subscribe(() => {
                    if (this.question instanceof CategoryQuestion) {
                        this.categories = [].concat(this.question.content.categories)
                    }
                });
        }
    }

    modal(): void {
        this.mediaModalOpen = !this.mediaModalOpen
    }

    onUpdateMedia(media: Media): void {
        this.question.media = media;
        this.updateQuestion();
    }

    onUpdateMediaCategory(media: Media, choice: any): void {
        if (media) {
            choice.media = media;
            choice.type = 'media';
            choice.value = media.id;
        } else {
            choice.type = 'text';
            choice.value = null;
        }
        this.updateQuestion();
    }

    deleteQuestion(): void {
        this.exercise.deleteQuestion(this.question.id).subscribe(() => {
            this.delete.emit(true);
            this.errorCatcher.removeFromErrors(this.question)
        });
    }

    changeDeleteAction($event: any): void {
        this.position = ($event.clientX + 260 > window.innerWidth) ? 'left' : 'right';
        this.deleteAction = !this.deleteAction;
    }

    updateQuestion(): void {
        this.question.update(this.question.type).subscribe((question) => {
            Object.assign(this.question, question);
            this.errorCatcher.setErrors(question, this.sectionContent);
            this.emitterService.refreshCourse.emit(true);
        });
    }

    changeEditAction($event: any): void {
        if (this.settingsClass) {
            this.position = ($event.clientX + 260 > window.innerWidth) ? 'left' : 'right';
            this.editAction = !this.editAction;
        } else {
            this.displayAside = true;
        }
    }

    closeAside(): void {
        this.displayAside = false;
    }

    settings(): void {
        this.object = this.question;
    }

    updatePos(choice: any, index: number): void {
        index = index + 1;
        choice.pos = index;
        this.updateQuestion();
    }

    trackByFn(index: number): number {
        return index;
    }

    getHeight(index: number): any {
        return this.ready ? { 'height': document.getElementById('linker-' + index).offsetHeight + 'px' } : null;
    }

    @HostListener('document:click', ['$event'])
    clickout(event: any): void {
        if (!event.target.closest('.settings-delete')) {
            this.deleteAction = false;
        }
        if (!event.target.closest('.settings-edit') && !event.target.closest('.modal')) {
            this.editAction = false;
        }
    }
}
