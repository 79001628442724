import { Injectable } from "@angular/core";
import { SectionContentSession } from "../course-camp-session/section-content-session";
import { HttpClient } from '@angular/common/http';
import { Section } from "../course/section/section";
import { Observable, of } from "rxjs";
import { map } from 'rxjs/operators';
import { cleanObject } from "../helpers";
import { environment } from "src/environments/environment";

@Injectable()
export class CourseSubscriptionSession {
    id: number;
    courseSubscriptionId: number;
    progress: number;
    score: number;
    duration: number = 0;
    doneSurvey: boolean;
    sectionContentSessions: SectionContentSession[] = [];

    constructor(model: any, private http: HttpClient) {
        this.extends(model);
    }

    extends(model: any) {
        Object.assign(this, model);
        if (this.sectionContentSessions.length > 0) {
            this.sectionContentSessions = this.sectionContentSessions.map((sectionContentSession) => new SectionContentSession(sectionContentSession, this.http));
        }
        return this;
    }

    getCampProgress(): number {
        return this.progress;
    }

    getCampScore(): number {
        return this.score;
    }

    getDuration(): number {
        return this.duration;
    }

    getSectionScore(section: Section): number {
        let total = 0;
        let count = 0;

        section.sectionContents.forEach((sectionContent) => {
            if (sectionContent.isScorable) {
                let index = this.sectionContentSessions.findIndex((session) => {
                    return session.sectionContentId === sectionContent.id;
                });
                if (index !== -1 && this.sectionContentSessions[index].score !== null) {
                    count++;
                    total += this.sectionContentSessions[index].score;
                }
            }
        });

        return count > 0 ? Math.round(total / count) : null;
    }

    getSectionProgress(section: Section): number {
        let total = 0;
        let count = 0;

        if (section.sectionContents.length > 0) {
            section.sectionContents.forEach((sectionContent) => {
                count++;
                let index = this.sectionContentSessions.findIndex((session) => {
                    return session.sectionContentId === sectionContent.id;
                });

                if (index > -1) {
                    if (this.sectionContentSessions[index].doneAt) {
                        total += 1;
                    }
                }
            });
        }

        return count > 0 ? (total / count) : 0;
    }

    getOrCreateSectionContentSession(sectionContentId: number): Observable<SectionContentSession> {
        const sectionContentSession = this.sectionContentSessions.find((sectionContentSession) => sectionContentSession.sectionContentId === sectionContentId);
        return sectionContentSession ? of(sectionContentSession) : this.addSectionContentSession(sectionContentId);
    }

    addSectionContentSession(sectionContentId: number): Observable<SectionContentSession> {
        return this.http.post(`${environment.envVar.API_URL}/session/section-content-sessions`, { sectionContentId, courseSubscriptionSessionId: this.id })
            .pipe(map(res => {
                const sectionContentSession = new SectionContentSession(res, this.http);
                this.sectionContentSessions.push(sectionContentSession);
                return sectionContentSession;
            }));
    }

    update(): Observable<CourseSubscriptionSession> {
        return this.http.put(`${environment.envVar.API_URL}/session/course-camp-sessions/${this.id}`, cleanObject(this))
            .pipe(map(res => this.extends(res)));
    }
}
