import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Course, FolderProvider, Folder, CourseProvider } from '@stuplay';
import { HttpClient } from '@angular/common/http';
import { StorageService } from '../../utils/services/storage.service';
import { EmitterService } from '../../utils/services/emitter.service';
import { Subscription } from 'rxjs';
import { HttpHelper } from '../../utils/helpers';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html'
})
export class HomeComponent implements OnInit, OnDestroy {
    public courses: Course[];
    public coursesPagination: any;
    public folders: Folder[];
    public slug: string;
    public companySlug: string;
    public filter: any = {
        q: '',
        recursive: 1
    };
    public processing: boolean = false;
    public foldersLoaded: boolean = false;
    public activeMoveTo: boolean = false;
    public moveToItem: any;
    public subscription: Subscription;
    public foldersTree: Folder[];
    public sharedCourses: boolean = false;
    public configurable: boolean = true;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private folderProvider: FolderProvider,
        private courseProvider: CourseProvider,
        public http: HttpClient,
        private storageService: StorageService,
        private emitterService: EmitterService,
    ) {
    }

    ngOnInit() {
        this.subscription = this.emitterService.moveTo.subscribe((data) => {
            this.loadFoldersTree();
            this.moveToItem = data;
            this.activeMoveTo = true;
        });

        this.route.params.subscribe((params) => {
            this.filter.q = '';
            this.courses = this.route.snapshot.data.course.courses;
            this.coursesPagination = this.route.snapshot.data.course.pagination;

            if (params.slug) {
                this.slug = params.slug;
                if (params.slug === 'collaborations' || params.slug === 'shared') {
                    this.sharedCourses = true;
                    this.companySlug = params.companyId;
                    this.configurable = params.slug !== 'shared';
                }
            }

            if (!this.sharedCourses) {
                this.folderProvider.getHomeFolders(this.slug, { includes: 'permissions' }).subscribe((data) => {
                    this.foldersLoaded = true;
                    this.folders = data.folders;

                    if (!this.slug) {
                        this.folderProvider.getSharedFolders(this.storageService.get('company').slug).subscribe((folders) => {
                            folders.shared.map((folder) => {
                                this.folders.unshift(folder);
                            });
                        });
                    }
                });
            }
        });

        this.filter.q = this.storageService.get('query');
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    loadFoldersTree(): void {
        this.folderProvider.getFoldersTree().subscribe((folders) => {
            this.foldersTree = folders;
        });
    }

    addFolder(folder: any): void {
        folder.context = 'folder';
        folder.new = true;
        this.folders.unshift(folder);
    }

    setQuery(query: string): void {
        this.filter.q = query; // this.storageService.getAndSet('query', query);
        this.filter.recursive = (query) ? 1 : 0;
        this.filter.includes = 'account,permissions';
        this.search();
    }

    search(): void {
        if (this.sharedCourses) {
            if (this.slug === 'company' || this.slug === 'shared') {
                this.courseProvider.getSharedCourses(this.companySlug, this.filter).subscribe((data) => {
                    this.courses = data.courses;
                    this.coursesPagination = data.pagination;
                });
            } else {
                this.courseProvider.getSharedCollaborations(this.filter).subscribe((data) => {
                    this.courses = data.courses;
                    this.coursesPagination = data.pagination;
                });
            }
        } else {
            this.courseProvider.getHomeCourses(this.slug, this.filter).subscribe((data) => {
                this.courses = data.courses;
                this.coursesPagination = data.pagination;
            });

            this.folderProvider.getHomeFolders(this.slug, this.filter).subscribe((data) => {
                this.folders = data.folders;

                if (!this.slug) {
                    this.folderProvider.getSharedFolders(this.storageService.get('company').slug, this.filter).subscribe((folders) => {
                        folders.shared.map((folder) => {
                            this.folders.unshift(folder);
                        });
                    });
                }
            });
        }
    }

    loadMore(): void {
        this.processing = true;

        const params = HttpHelper.cleanParams({
            q: this.filter.q,
            recursive: this.filter.q ? 1 : 0,
            page: this.coursesPagination.currentPage + 1,
            includes: 'account,permissions'
        });

        if (!this.sharedCourses) {
            this.courseProvider.getHomeCourses(this.slug, params).subscribe((data) => {
                this.coursesPagination = data.pagination;
                data.courses.map((res) => {
                    this.courses.push(new Course(res, this.http));
                });
                this.processing = false;
            });
        } else {
            if (this.slug === 'company' || this.slug === 'shared') {
                this.courseProvider.getSharedCourses(this.companySlug, params).subscribe((data) => {
                    this.courses = this.courses.concat(data.courses);
                    this.coursesPagination = data.pagination;
                });
            } else {
                this.courseProvider.getSharedCollaborations(params).subscribe((data) => {
                    this.courses = this.courses.concat(data.courses);
                    this.coursesPagination = data.pagination;
                });
            }
            this.processing = false;
        }
    }

    addInstance(template: any): void {
        this.storageService.set('template', template);
        this.router.navigate(['company', this.storageService.get('company').slug, 'instance', template.slug]);
    }

    closeMoveTo(): void {
        this.activeMoveTo = false;
        this.foldersTree = null;
    }

    refresh(): void {
        this.courseProvider.getHomeCourses(this.slug, { includes: 'account,permissions' }).subscribe((data) => {
            this.courses = data.courses;
            this.coursesPagination = data.pagination;
        });

        this.folderProvider.getHomeFolders(this.slug, { includes: 'permissions' }).subscribe((data) => {
            this.folders = data.folders;

            if (!this.slug) {
                this.folderProvider.getSharedFolders(this.storageService.get('company').slug).subscribe((folders) => {
                    folders.shared.map((folder) => {
                        this.folders.unshift(folder);
                    });
                });
            }
        });
    }
}
