import { Component, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'instance-unpublish-confirm-modal',
    templateUrl: './instance-unpublish-confirm.component.html',
    styleUrls: ['./instance-unpublish-confirm.component.less']
})

export class InstanceUnpublishConfirmModal {
    @Output() closeModal = new EventEmitter<boolean>();
    @Output() onConfirm = new EventEmitter<boolean>();

    close() {
        this.closeModal.emit();
    }

    confirm(){
        this.onConfirm.emit();
    }
}
