<app-topbar [currentUser]="me" [course]="course" [size]="size" (updatedSize)="onUpdateSize($event)" (onPlayerModeChange)="playerMode = !playerMode"></app-topbar>{{ size }}
<app-sidebar [course]="course"></app-sidebar>

<div id="view" [ngClass]="'responsive-'+size">
	<div id="content" [ngClass]="'theme-'+course.theme">
		<div class="studio-content">
			<router-outlet></router-outlet>
		</div>
	</div>
</div>
