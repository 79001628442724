import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { HeaderService } from '../../utils/services/header.service';
import { CookieService } from 'ngx-cookie-service';
import { StorageService } from '../../utils/services/storage.service';
import { ExternalAppsService } from '../../utils/services/external-apps.service';
import { AuthService } from '../../utils/services/auth.service';
import { NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { RedirectService } from '../../utils/services/redirect.service';
import { UrlHelper } from '../../helpers/url.helper';
import { environment } from 'src/environments/environment';

const SUPPORT_WEBFORMS = {
    EN: 'https://support.myskillcamp.com/hc/en-gb/requests/new',
    FR: 'https://support.myskillcamp.com/hc/fr/requests/new',
    NL: 'https://support.myskillcamp.com/hc/nl/requests/new'
}

@Component({
    selector: 'msc-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.less'],
})
export class HeaderComponent implements OnInit {
    @Input() access: any;
    public company: any;
    public counts: any;
    public displayDropdowns: any;
    public displays: any;
    private docs: any;
    public me: any;

    constructor(
        private router: Router,
        private headerService: HeaderService,
        private cookieService: CookieService,
        private storageService: StorageService,
        private authService: AuthService,
        private externalAppsService: ExternalAppsService,
        private redirectService: RedirectService,
        private modalService: NgbModal
    ) {}

    ngOnInit() {
        this.me = this.storageService.get('me');
        this.displays = {
            help: false,
            submenu: false,
        };

        this.docs = {
            fr: 'https://myskillcamp.zendesk.com/hc/fr/categories/4859911244829-Je-cr%C3%A9e',
            en: 'https://myskillcamp.zendesk.com/hc/en-gb/categories/4859911244829-I-create',
            nl: 'https://myskillcamp.zendesk.com/hc/nl/categories/4859911244829-Ik-cre%C3%ABer',
        };
        this.me = this.storageService.get('me');
        this.company = this.storageService.get('company');
        this.setCookie();

        this.displayDropdowns = {
            roles: false
        };

        this.headerService.getCounts().subscribe((data: any) => {
            this.counts = data;
        });
    }

    booted(app: string): boolean {
        return this.externalAppsService.isBooted(app);
    }

    documentation(): void {
        UrlHelper.navigate(this.docs[this.me.language.code]);
    }

    support(): void {
        if (this.externalAppsService.isBooted('freshchat')) {
            this.externalAppsService.freshchat('open');
        }
        else if (this.externalAppsService.isBooted('zendesk')) {
            let url;
            switch (this.me.language.code) {
                case 'en':
                case 'de':
                case 'es':
                case 'it':
                    url = SUPPORT_WEBFORMS.EN;
                    break;
                case 'fr':
                    url = SUPPORT_WEBFORMS.FR;
                    break;
                case 'nl':
                    url = SUPPORT_WEBFORMS.NL;
                    break;
                default:
                    break;
            }
    
            window.open(url, '_blank');
        }
    }

    openModal(content: any) {
        const options: NgbModalOptions = {
            size: 'xl',
            centered: true,
            backdropClass: 'backdrop-custom',
        };
        this.modalService.open(content, options);
    }

    navigateTo(route: string): void {
        this.router.navigate([
            'company',
            this.storageService.get('company').slug,
            route,
        ]);
    }

    isActive(route: string): boolean {
        return this.router.url.split('/')[3] === route;
    }

    redirectTo(): void {
        this.redirectService.navigate('app', ['login', 'company']);
    }

    setCookie(): void {
        const now = new Date();
        now.setTime(now.getTime() + 10 * 24 * 60 * 60 * 1000);

        const company: any = {
            id: this.company.id,
            slug: this.company.slug,
            name: this.company.name,
            type: this.company.type,
            experience: this.company.experience.slug,
        };

        this.cookieService.set('current_company', company.slug, 10, '/', UrlHelper.getDomain(), false, 'Lax');
    }

    redirect(type: string): void {
        this.event(type);
        
        this.redirectService.navigate('app', [type, this.company.slug]);
    }

    event(type: string): void {
        let role = '';
        switch (type) {
            case 'learn':
                role = 'learner';
                break;
            case 'create':
                role = 'author';
                break;
            case 'manage':
                role = 'manager';
                break;
            case 'organize':
                role = 'administrator';
                break;
        }
    }

    displayed(key: string): void {
        this.displayDropdowns[key] = !this.displayDropdowns[key];
    }

    isRole(type: string, company?: any): boolean {
        const companyChecking = company ? company : this.company;

        return (
            companyChecking.roles.findIndex((role: any) => {
                return role === type;
            }) !== -1
        );
    }

    logout(): void {
        this.authService.logout();
    }

    beamer(): void {
        this.externalAppsService.beamer().show();
    }

    chat(): void {
        this.externalAppsService.freshchat('open');
    }
}
