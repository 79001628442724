import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { SectionContent } from '@stuplay';
import { StorageService } from '../../../../../utils/services/storage.service';

@Component({
    selector: 'app-edit-settings',
    templateUrl: './edit-settings.component.html'
})
export class EditSettingsComponent implements OnInit, OnChanges {
    @Input() object: any;
    @Input() sectionContent: SectionContent;
    @Output() closeSettings = new EventEmitter();
    public selfAssessmentSettings: any = {
        media: null,
        text: null
    };
    public type: string = '';
    public error: any;
    public enable: any;

    constructor(private storageService: StorageService) { }

    ngOnInit() {
        if (this.object) {
            this.type = this.object.constructor.name;
            if (this.object.type === 'both') {
                this.selfAssessmentSettings.media = true;
                this.selfAssessmentSettings.text = true;
            } else if (this.object.type === 'text') {
                this.selfAssessmentSettings.media = false;
                this.selfAssessmentSettings.text = true;
            } else if (this.object.type === 'media') {
                this.selfAssessmentSettings.media = true;
                this.selfAssessmentSettings.text = false;
            } else {
                this.selfAssessmentSettings.media = false;
                this.selfAssessmentSettings.text = false;
            }
        }

        this.enable = {
            virtualClass: this.storageService.get('applications') && this.storageService.get('applications').learncube
        };
    }

    ngOnChanges() {
        if (this.object) {
            this.type = this.object.constructor.name;
        }
    }

    onDurationUpdate(duration: number): void {
        this.object.duration = duration;
        this.updateSettings();
    }

    close(): void {
        this.closeSettings.emit();
    }

    updateCourse(): void {
        this.object.update()
            .subscribe(
                () => { },
                (error) => {
                    this.error = error
                }
            );
    }

    updateSelfAssessment(): void {
        if (this.selfAssessmentSettings.text && this.selfAssessmentSettings.media) {
            this.object.type = 'both';
        } else if (this.selfAssessmentSettings.text && !this.selfAssessmentSettings.media) {
            this.object.type = 'text'
        } else if (!this.selfAssessmentSettings.text && this.selfAssessmentSettings.media) {
            this.object.type = 'media'
        } else {
            this.object.type = 'none';
        }
        this.object.update()
            .subscribe(
                () => { },
                (error) => {
                    this.error = error
                }
            );
    }

    updateSettings(): void {
        this.object.isDownloadable = this.sectionContent.isDownloadable;
        this.sectionContent.update()
            .subscribe(
                () => { },
                (error) => {
                    this.error = error
                }
            );
    }
}
