import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Exercise } from '@stuplay';
import { TranslationService } from 'src/app/utils/services/translation.service';

@Component({
    selector: 'msc-aside-question-settings',
    templateUrl: './aside-question-settings.component.html',
    styleUrls: ['./aside-question-settings.component.scss']
})
export class AsideQuestionSettingsComponent implements OnInit {
    @Input() question: any;
    @Input() exercise: Exercise;
    @Output() onClose: EventEmitter<any> = new EventEmitter;
    public active: boolean = false;
    public type: string;
    public displayHint: boolean = false;
    public displayFeedbacks: boolean = false;
    public displayMinLength: boolean = false;
    public correction: any;

    constructor(private translate: TranslationService) { }

    ngOnInit() {
        this.activeEffect();
        this.correction = {
            wrong: '<div style="display: flex; align-items: center">' +
                '<img src="https://static.myskillcamp.com/images/icon/icon-studio/icon_close_circle.svg" alt="wrong" style="max-width: initial!important; width: 10%!important;">' +
                '<span style="font-size: 36px; width: 100%; margin-left: 1rem;">' +
                this.translate.instant('studio.sidebar-settings.common.correction.wrong') +
                '</span> </div> <hr>',
            right: '<div style="display: flex; align-items: center">' +
                '<img src="https://static.myskillcamp.com/images/icon/icon-studio/icon_checkmark_circle.svg" alt="right" style="max-width: initial!important; width: 10%!important;">' +
                '<span style="font-size: 36px;width: 100%; margin-left: 1rem;">' +
                this.translate.instant('studio.sidebar-settings.common.correction.right') +
                '</span> </div> <hr>',
        };

        if (this.question) {
            this.type = this.question.constructor.name;
            this.displayHint = this.question.hint || this.question.hintMedia;
            this.displayFeedbacks = this.question.feedbackDefault || this.question.feedbackWrongAnswer;
            this.displayMinLength = this.question.param.minLength;
            this.question.param.mode = this.question.param.mode || 'progressive';
        }
    }

    setCorrections(): void {
        this.question.feedbackDefault = this.question.feedbackDefault ? this.question.feedbackDefault : this.correction.right;
        this.question.feedbackWrongAnswer = this.question.feedbackWrongAnswer ? this.question.feedbackWrongAnswer : this.correction.wrong;
    }

    activeEffect(): void {
        const timeOut = setTimeout(() => {
            this.active = true;
            clearTimeout(timeOut);
        }, 100);
    }

    closeSidebar(): void {
        this.active = false;
        const timeOut = setTimeout(() => {
            this.onClose.emit();
            clearTimeout(timeOut);
        }, 300);
    }

    updateSettings(): void {
        if (this.question.tryMax === 0) {
            this.question.tryMax = 1;
        }
        this.question.update().subscribe();
    }

    displayedHint(): void {
        if (!this.displayHint) {
            this.question.hint = null;
            this.question.hintMedia = null;
        }
        this.updateSettings();
    }

    displayedFeedBack(): void {
        this.setCorrections();
        if (!this.displayFeedbacks) {
            this.question.feedbackDefault = null;
            this.question.feedbackWrongAnswer = null;
        }
        this.updateSettings();
    }

    displayedMinLength(): void {
        if (!this.displayMinLength) {
            this.question.param.minLength = null;
            this.updateSettings();
        }
    }
}
