import { Component, EventEmitter, Output, Input, OnChanges, ViewChild, ElementRef } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { Media } from '@stuplay';

@Component({
    selector: 'library-item-preview',
    templateUrl: './item-preview.component.html',
})
export class ItemPreviewComponent implements OnChanges {
    @ViewChild('mediaPreview', { static: false }) mediaPreview: ElementRef;
    @ViewChild('downloadUrl', { static: false }) downloadUrl: ElementRef;
    @Input() media: Media;
    @Input() currentUser;
    @Input() toUpload: boolean;
    @Input() library: boolean = false;
    @Input() availableArchive: boolean = true;
    @Output() close = new EventEmitter();
    @Output() updateMedia = new EventEmitter();
    @Output() update = new EventEmitter();
    @Output() archivedMedia = new EventEmitter();
    public fixPreview: boolean = false;
    public blockPreview: boolean = false;
    public stockageUrl: string = environment.envVar.STORAGE_URL;
    public url: any;
    public uploadDate: any;
    public docUrl: any;
    public urlForDownload: any;

    constructor(
        private sanitizer: DomSanitizer,
        private modalService: NgbModal,
    ) { }

    openModal(content: any) {
        const options: NgbModalOptions = { size: 'lg', backdropClass: 'backdrop-custom' };
        this.modalService.open(content, options);
    }

    closePreview() {
        this.close.emit();
    }

    updateMediaDetails() {
        this.media.update().subscribe((media) => {
            this.media = media;
        });
    }

    onUpdateMedia() {
        this.updateMedia.emit(this.media);
    }

    ngOnChanges() {
        if (this.media.url) {
            this.uploadDate = moment(this.media.createdAt).locale(this.currentUser.language.code);
            this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.media.url.replace('www.vimeo.com/', 'player.vimeo.com/video/'));

            if (this.media.type === 'DOC' || this.media.type === 'PDF') {
                this.docUrl = this.sanitizer.bypassSecurityTrustResourceUrl((this.media.originalUrl.indexOf('.txt') > -1) ? environment.envVar.STORAGE_URL + this.media.originalUrl : this.media.pictureUrl);
            }

            if (this.media.type === 'IMG') {
                this.urlForDownload = (this.media.storage === 'ext') ? this.media.url : this.stockageUrl + this.media.url + '?size=raw';
            } else if (this.media.type === 'EMBED') {
                this.urlForDownload = this.stockageUrl + this.media.url;
            } else {
                this.urlForDownload = this.sanitizer.bypassSecurityTrustResourceUrl(this.stockageUrl + this.media.originalUrl);
            }
        }
    }

    getType(): string {
        switch (this.media.type) {
            case 'IMG': {
                return 'studio.media.images';
            }
            case 'VID': {
                return 'studio.re-use.video-context';
            }
            case 'DOC': {
                return 'studio.media.docs';
            }
            case 'EMBED': {
                return this.media.source;
            }
            case 'SND': {
                return 'studio.media.sounds';
            }
        }
    }

    archived(modal: NgbModalRef): void {
        modal.close();
        this.media.isArchived = true;
        this.updateMediaDetails();
        this.closePreview();
        this.archivedMedia.emit(this.media);
    }

    downloadMedia(): void {
        const event = new MouseEvent('click', { bubbles: true });
        // this.renderer.invokeElementMethod(this.downloadUrl.nativeElement, 'dispatchEvent', [event]);
        (this.downloadUrl.nativeElement as any)['dispatchEvent'].apply(this.downloadUrl.nativeElement, [event]);
    }
}
