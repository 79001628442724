import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { TranslationService } from '../services/translation.service';

export class DateHelper {

    public static getYear(date: any, format: string = 'YYYY'): string {
        return moment(date).format(format);
    }

    public static getMonth(date: any, lang: string = 'en', format: string = 'MMM'): string {
        return moment(date).locale(lang).format(format);
    }

    public static getDay(date: any, format: string = 'DD'): string {
        return moment(date).format(format);
    }

    public static getHour(date: any, format: string = 'HH:mm'): string {
        return moment(moment.utc(date, 'YYYY-MM-DD H:mm:ss').format()).format(format);
    }
}

/**
 * Format Date pipe
 */
 @Pipe({
     name: 'formatDate'
 })
 export class FormatDatePipe implements PipeTransform {

     /**
      * Import TranslateService
      * @constructor
      * @param translateService
      */
     constructor(private translateService: TranslationService) {}

     transform(value: string, type: string, format?: any): any {
         if (value) {
             return this.formatting(value, type, format);
         }
         return value;
     }

     /**
      * Formatting logic
      * @param {string} value - Value
      * @param {string} type - Type of date
      * @param {string} format - Formatting
      * @return {string} Formatted date
      */
     private formatting(value: string, type: string, format?: string): string {
         let data = '';
         switch (type) {
             case 'year':
                 data = DateHelper.getYear(value, format);
                 break;
             case 'month':
                 data = DateHelper.getMonth(value, this.translateService.getCurrentLang(), format);
                 break;
             case 'day':
                 data = DateHelper.getDay(value, format);
                 break;
             case 'hour':
                 data = DateHelper.getHour(value, format);
                 break;
         }
         return data;
     }
 }
