<header class="header-old">
    <div class="header__item">
        <a class="leave-player" (click)="goBack()">
            <span class="sr-only">Leave</span>
        </a>
    </div>

    <div class="header__item header__item--view">
        <a class="btn btn_color_green" (click)="preview()">
            {{ 'studio.course.preview' | translate }}
        </a>
    </div>

    <div class="header__item header__item--media" (click)="openModalMedia(modalMedia)">
        <button class="btn">
            {{ 'studio.topbar.media-library' | translate }}
        </button>
    </div>

    <div class="header__item course">
        <span class="course__name">
            {{ course?.title || (course.schema === 'template' ? 'studio.title.template' : 'studio.title.instance') | translate }}
        </span>

        <span class="course__save">{{ 'studio.topbar.auto-save' | translate}}</span>
    </div>

    <div class="right">
        <div class="reset" *ngIf="course.schema === 'instance' && !company.permissions.companyLockSession && permissions.realignmentFeature">
            <div class="tooltip-custom">
                <a class="button button--reset" (click)="openModalReset(modalReset)">
                    <i class="icon icon--reset"></i>
                </a>

                <div class="tooltip__info">
                    <p>
                        {{ 'studio.topbar.reset-instance' | translate }}
                    </p>
                </div>
            </div>
        </div>

        <div class="header__item__instruction">
            <img (click)="openAsideHelp('all')" src="https://static.myskillcamp.com/images/guidance/question-mark-circle.svg" />
        </div>

        <div class="dropdown errors" *ngIf="errors.length > 0" (click)="toggleDropdown()" [class.dropdown_active]="errorOpen">
            <div class="dropdown__context">
                <div #errorsContainer [@itemAnim] class="errors-container">
                    <div class="number">{{ errors.length }}</div>
                </div>

                <div class="dropdown__content">
                    <div class="dropdown-errors">
                        <svg version="1.1" x="0px" y="0px" width="145.7px" height="65.2px" viewBox="0 0 145.7 65.2" enable-background="new 0 0 145.7 65.2">
                            <path fill="#FFF" d="M0,65.2L59.8,5.4c7.2-7.2,18.8-7.2,26,0l59.8,59.8H0z" />
                        </svg>

                        <div class="title">
                            {{ 'studio.topbar.error-list' | translate }}
                        </div>

                        <div class="error-list">
                            <div class="error-list__item" *ngFor="let error of errors" [routerLink]="['section', error.sectionId]" fragment="{{getFragment(error)}}" [ngClass]="getErrorClass(error)">
                                <div class="error-type red"></div>
                                <div>
                                    <div class="element-type">{{ getTranslation(error.element) | translate }}</div>
                                    <p *ngIf="error.data !== 'notNull'">{{ 'studio.topbar.error.description.for-' + error.key | translate }} </p>
                                    <p *ngIf="error.data === 'notNull'">{{ 'studio.topbar.error.description.for-' + error.key + '.' + error.data | translate }} </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <msc-account [currentUser]="currentUser"></msc-account>
    </div>

    <ng-template #modalMedia let-modal class="media-library" id="media-library">
        <library-modal (closeModal)="modal.close()" [toUpload]="false"></library-modal>
    </ng-template>

    <ng-template #modalReset let-modal>
        <div class="modal-header">
            <span></span>
            <button type="button" class="close" aria-label="Close" (click)="modal.close()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div style="background-color: #FFFFFF; padding: 5rem; text-align: center;">
                {{ 'studio.topbar.reset-instance.hint' | translate }}<br><br>
                1. {{ 'studio.topbar.reset-instance.one' | translate }}<br>
                2. {{ 'studio.topbar.reset-instance.two' | translate }}<br>
                3. {{ 'studio.topbar.reset-instance.three' | translate }}<br><br>
                {{ 'studio.topbar.reset-instance.hint-progress' | translate }}<br><br>

                <div class="reset-event">
                    <label for="reset-event">
                        <input id="reset-event" type="checkbox" [checked]="data.resetEvents" [(ngModel)]="data.resetEvents">
                        {{ 'studio.instance.reset-events' | translate }}
                    </label>
                </div>

                <div>
                    <strong>{{ 'studio.topbar.reset-instance.end-hint' | translate }}</strong>
                </div>

                <div style="color: tomato; margin: 1rem 0 0" *ngIf="course.realignedAt">
                    <strong>
                        {{ 'studio.topbar.reset-instance.last-date' | translate }} :
                        {{ course.realignedAt | date: 'dd/MM/yyyy HH:mm' }}
                    </strong>
                </div>

                <div class="actions" style="padding-top: 2rem;">
                    <button class="btn btn_color_red" (click)="resetInstance()" [disabled]="loading">
                        <div class="lds-ring" *ngIf="loading">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>

                        <span>{{ 'studio.topbar.reset-instance' | translate }}</span>
                    </button>

                    <button class="btn btn_color_grey" (click)="modal.close()">
                        {{ 'studio.common.button.cancel' | translate }}
                    </button>
                </div>
            </div>
        </div>
    </ng-template>
</header>
