<div class="templab-summary__content" [ngClass]="{'templab-summary__content--activity': contentType === 'content',
     'templab-summary__content--external': contentType === 'external_content' && content.provider, 'templab-summary__content--link': contentType === 'external_content' && !content.provider}">
    <div class="templab-journey__provider" *ngIf="content.provider">
        <div [style.backgroundImage]="'url(' + getLogo(content) + ')'"></div>
    </div>

    <h4 class="templab-journey__type">
        {{ (contentType === 'external_content' && !content.provider ? 'filter.content-type.external_resource' : 'studio.journey.' + contentType) | translate }}
        {{ ((contentType === 'content') ? 'words.' + content.context : '') | translate }}
    </h4>

    <div class="templab-journey__content">
        <div class="templab-journey__picture" *ngIf="content.media || content[content.context]?.media || content.picture">
            <div class="templab-journey__picture__background" *ngIf="content.picture" [style.backgroundImage]="content.picture ? ('url(' + content.picture + ((content.provider?.key !== 'linkedin') ? '?size=256)' : ')')) : ('url(https://static.myskillcamp.com/lxp/images/logo-empty-state-' + content.id % 7 + '.webp)')"></div>
            <media-holder [media]="content.media" [size]="256" *ngIf="(contentType === 'course' || content.context === 'media') && content.media"></media-holder>
            <media-holder [media]="content[content.context].media" *ngIf="contentType === 'content' && content.context !== 'media' && content.context !== 'exercise' && content.context !== 'external_link' && content[content.context].media">
            </media-holder>
        </div>

        <div class="templab-journey__desc">
            <h4 class="templab-journey__title">
                {{ content.title }}
            </h4>

            <p class="templab-journey__text" *ngIf="contentType === 'course'">
                <span>{{ content.instancesCount }}</span> {{ ((content.instancesCount > 1) ? 'studio.instance.plural' : 'studio.instance.single') | translate }}
            </p>

            <div class="templab-journey__description" *ngIf="content.description" [innerHTML]="content.description | safe: 'html'"></div>

            <div class="templab-journey__questions" *ngIf="content.exercise">
                <div class="templab-journey__questions__item" *ngFor="let question of content.exercise?.questions; let index=index">
                    <div class="item-type">
                        {{ 'studio.modal-question.' + question.type + '-title' | translate }}
                    </div>

                    <div class="item-title" [innerHTML]="question.title | safe:'html'"></div>

                    <div class="item-dropdown">
                        <div class="templab-journey__dropdown">
                            <div class="templab-dropdown">
                                <div class="templab-dropdown__selected">
                                    <i class="templab-icon templab-icon-more-horizontal"></i>
                                </div>

                                <div class="templab-dropdown__content">
                                    <ul class="templab-dropdown__list">
                                        <li class="templab-dropdown__item" (click)="editQuestion(index)">
                                            <a>
                                                <i class="templab-icon templab-icon-edit"></i> {{ 'words.modify' | translate }}
                                            </a>
                                        </li>

                                        <li class="templab-dropdown__item" (click)="deleteQuestion(index)" *ngIf="content.exercise.questions.length > 1">
                                            <a>
                                                <i class="templab-icon templab-icon-trash"></i> {{ 'words.delete' | translate }}
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="templab-journey__dates" *ngIf="content.context === 'event'">
                <p class="date-ctn">
                    <span class="date">{{ content.event.eventSessions[0].startAt | date : 'dd MMM yy' }}</span> {{ convertHour(content.event.eventSessions[0].startAt) }}
                    <span *ngIf="sameDay()"> - {{ convertHour(content.event.eventSessions[0].endAt) }}</span>
                </p>

                <p *ngIf="!sameDay()" class="date-ctn">
                    <span class="date">{{ content.event.eventSessions[0].endAt | date : 'dd MMM yy' }}</span> {{ convertHour(content.event.eventSessions[0].endAt) }}
                </p>
            </div>

            <div class="templab-journey__address" *ngIf="content.context === 'event' && content.event.eventSessions[0].address && content.event.type === 'place'">
                <i class="templab-icon templab-icon-pin"></i>

                <span>{{ content.event.eventSessions[0].address }}</span>
            </div>

            <a class="templab-journey__address" *ngIf="content.context === 'event' && content.event.link && content.event.type === 'visio'" [attr.href]="content.event.link" target="_blank">
                <span *ngIf="content.event.type === 'visio'">{{ content.event.link }}</span>
            </a>

            <div class="templab-journey__trainers" *ngIf="content.context === 'event' && content.event.eventTrainers.length > 0">
                <div class="">
                    {{ 'studio.instance.trainers' | translate }}

                    <ul class="templab-journey__trainers__list">
                        <li *ngFor="let trainer of content.event.eventTrainers | slice: 0: 3">
                            <a class="tooltip-v2" [attr.data-tooltip]="trainer.name">
                                <img *ngIf="trainer.account" [src]="trainer.account.picture + '?size=64'">
                                <img *ngIf="!trainer.account" [src]="'https://ui-avatars.com/api/?background=cb96ff&color=a35ff0&font-size=0.6&length=1&name='+trainer.name+'?size=64'">
                            </a>
                        </li>

                        <li class="templab-journey__trainers__more" *ngIf="content.event.eventTrainers.length > 3">
                            ...
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="templab-journey__order" *ngIf="!movingContent">
        <a (click)="moveContent.emit(true)" *ngIf="!first">
            <i class="templab-icon templab-icon-arrow-ios-up"></i>
        </a>

        <a (click)="moveContent.emit(false)" *ngIf="!last">
            <i class="templab-icon templab-icon-arrow-ios-down"></i>
        </a>
    </div>

    <div class="templab-journey__dropdown">
        <div class="templab-dropdown">
            <div class="templab-dropdown__selected">
                <i class="templab-icon templab-icon-more-horizontal"></i>
            </div>

            <div class="templab-dropdown__content">
                <ul class="templab-dropdown__list">
                    <li class="templab-dropdown__item" *ngIf="contentType !== 'course' && !content.provider" (click)="edit()">
                        <a>
                            <i class="templab-icon templab-icon-edit"></i> {{ 'words.modify' | translate }}
                        </a>
                    </li>

                    <li class="templab-dropdown__item" (click)="openModal(modalConfirmation)">
                        <a>
                            <i class="templab-icon templab-icon-trash"></i> {{ 'words.delete' | translate }}
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>

<ng-template #modalConfirmation let-modal>
    <div class="modal-header">
        <span></span>
        <button type="button" class="close" aria-label="Close" (click)="modal.close()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div style="background-color: #FFFFFF; padding: 2rem;">
            {{ 'studio.lti.modal.confirm' | translate }}

            <div class="actions" style="padding-top: 2rem;">
                <button class="btn btn_color_red" (click)="delete()">
                    {{ 'words.yes' | translate }}
                </button>

                <button class="btn btn_color_grey" (click)="modal.close()">
                    {{ 'words.no' | translate }}
                </button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #modalEmbed let-modal>
    <div class="modal-header">
        <span></span>
        <button type="button" class="close" aria-label="Close" (click)="modal.close()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div style="background-color: #FFFFFF; padding: 5rem; margin: 3rem 0 0; max-height:80%; overflow: auto;">
            <div class="embed">
                <iframe width="100%" height="100%" name="embed-iframe"></iframe>
                <div id="embed-code"></div>
            </div>
        </div>
    </div>
</ng-template>

<style>
    .container-content--event {
        display: block;
    }

    .container-content--event__infos {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-top: 1rem;
        border-top: 1px solid #f4f8fe;
        padding: 1rem 0 0 0;
    }

    .container-content--event__infos__dates {
        display: flex;
    }

    .container-content--event__trainers {
        margin-top: 2rem;
    }

    .trainer-list {
        display: flex;
        list-style: none;
        margin: 0;
    }

    .trainer-list li {
        position: relative;
        width: 32px;
        height: 32px;
    }

    .trainer-list li img {
        border-radius: 50%;
        border: 3px solid white;
    }

    .trainers--more {
        color: black;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #f4f8fe;
        border-radius: 50%;
    }

    .container-content--event__infos__dates .date-ctn {
        background: #f4f8fe;
        margin: 0;
        padding: 0.5rem 1rem;
        border-radius: 6px;
        font-weight: bold;
        font-size: 0.8rem;
    }

    .container-content--event__infos__dates .date-ctn:not(:first-child) {
        margin-left: 1rem;
    }

    .container-content--event__infos__dates .date {
        border-right: 1px solid #e4e8ee;
        padding-right: 0.5rem;
        margin-right: 0.5rem;
        color: #F88A56;
    }

    .container-content--event__address {
        font-size: 0.8rem;
        max-width: 250px;
        word-break: break-word;
    }

    .container-content--event__address img {
        margin-right: 0.5rem;
    }

    .m-border {
        background-color: white;
        position: relative;
        border-radius: 4px;
        display: flex;
        flex-direction: row;
    }

    .m-border:before {
        content: '';
        width: 2px;
        height: 102%;
        position: absolute;
        left: -4rem;
        top: 0;
        border-left: 2px dashed rgb(214, 221, 233);
    }

    .m-border:after {
        content: '';
        width: 15px;
        height: 15px;
        border-radius: 100%;
        background-color: #ecf1f9;
        border: 4px solid #5B70FF;
        position: absolute;
        top: 50%;
        left: -4.35rem;
        transform: translateY(-50%);
    }

    .m-actions {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-content: center;
        background-color: #d9e3f2;
        padding: 1rem 1rem 1rem 1rem;
        margin-right: 1%;
    }

    .m-actions button:hover {
        color: #8c8ce4;
    }

    .content {
        width: 100%;
        padding: 1rem;
    }

    .dropdown__click {
        position: absolute;
        top: 3%;
        right: 4%;
    }

    .m-module {
        max-width: 40rem;
        border-left: 4px solid #5B70FF;
        margin: 1% 0 1% 3rem;
    }

    .m-activity {
        max-width: 40rem;
        border-left: 4px solid #F88A56;
        margin: 1% 0 1% 3rem;
    }

    .m-activity:after {
        border-color: #F88A56;
    }

    .m-last:before {
        height: 50%;
    }

    .m-last:after {
        top: 50%;
    }

    .m-blue {
        color: #5B70FF;
    }

    .m-orange {
        color: #F88A56;
    }

    .m-slider {
        -webkit-appearance: none;
        width: 70%;
        height: 0.3rem;
        border-radius: 5px;
        background: #cad8ed;
        outline: none;
        margin: 0 2rem;
    }

    .m-slider::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 0.7rem;
        height: 0.7rem;
        border-radius: 50%;
        background: #5B70FF;
        cursor: pointer;
    }

    .m-slider::-moz-range-thumb {
        width: 0.7rem;
        height: 0.7rem;
        border-radius: 50%;
        background: #5B70FF;
        cursor: pointer;
    }

    .content-media img {
        border-radius: 4px;
    }

    .container-question {
        width: 100%;
    }

    .content-question {
        border: 2px solid #cad8ed;
        border-left: 3px solid #F88A56;
        border-radius: 0 4px 4px 0;
        padding: 1rem;
        margin: 0 0 1rem 0;
    }

    .content-question:last-child {
        margin: 0;
    }

    .dropdown__question {
        position: relative;
    }

    .m-sub {
        visibility: hidden;
        position: absolute;
        right: 1rem;
        background-color: #fff;
        min-width: 180px;
        border-radius: 4px;
        opacity: 0;
        transform: scale(.9);
        transition: all .2s cubic-bezier(.175, .885, .32, 2);
        z-index: 9998;
        border: 1px solid #ebf0f8;
        box-shadow: 0 8px 20px 0 rgba(66, 70, 103, .3);
        list-style: none;
        text-align: left;
    }

    .m-sub.active {
        visibility: visible;
        opacity: 1;
        transform: scale(1);
        transition: all .2s cubic-bezier(.175, .885, .32, 2);
    }

    .flex-column {
        flex-direction: column;
    }
</style>
