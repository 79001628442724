<div class="alert alert_error" *ngIf="error && (!company.permissions.companyLockSession || (company.permissions.companyLockSession && course.state != 'ongoing'))">
    <div class="alert__title"></div>
    <div class="alert__content">
        {{'error.message' | translate}}
    </div>
    <button class="alert__close"><span class="sr-only">Delete</span></button>
</div>

<div class="slide" id="s-{{section.id}}">
    <div class="settings" *ngIf="!company.permissions.companyLockSession || (company.permissions.companyLockSession && course.state != 'ongoing')">
        <div class="settings-delete" [ngClass]="{'open-settings': deleteAction == true}">
            <button class="settings-delete__trigger btn_action_delete" (click)="changeDeleteAction($event)"><span class="sr-only">{{'words.delete' | translate}}</span></button>
            <div class="settings-delete__content {{'settings_position_'+position}}">
                <div class="title">{{ 'studio.course.section.delete-title' | translate }}</div>
                <div class="settings-block">
                    <div class="settings-block__title has_tooltip">
                        <h5>{{ 'studio.course.section.delete-hint' | translate }}</h5>
                    </div>

                    <div class="settings-block_details">
                        <div class="row">
                            <div class="columns small-6">
                                <button class="delete" (click)="deleteSection()">
                                    <span>{{ 'studio.common.button.delete' | translate }}</span>
                                </button>
                            </div>

                            <div class="columns small-6">
                                <button class="cancel" (click)="changeDeleteAction($event)">
                                    <span>{{ 'studio.common.button.cancel' | translate }}</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="section-presentation">
        <h2 class="heading">
            <div class="heading__block-title">
                <span>{{ 'studio.course.section.title' | translate }}</span>

                <a (click)="openAsideHelp('section')">
                    <img src="https://static.myskillcamp.com/images/guidance/question-mark-circle.svg"/>
                </a>
            </div>

            <live-editor [disabled]="company.permissions.companyLockSession && course.state == 'ongoing'" [(inputData)]="section.title" (onUpdate)="updateSection()" [placeholder]="('studio.course.section.title-hint' | translate)" ></live-editor>
        </h2>

        <p class="section-presentation__description">
            <msc-aside-editor [disable]="company.permissions.companyLockSession && course.state == 'ongoing'" [placeholder]="'studio.course.section.description-hint' | translate" [(content)]="section.description" (onUpdate)="updateSection()"></msc-aside-editor>
        </p>

        <div class="section-presentation__media" style="text-align: center;">
            <media-manager *ngIf="!company.permissions.companyLockSession || (company.permissions.companyLockSession && course.state != 'ongoing')" [media]="section?.media" [onlyPictures]="true" (updateMedia)="onUpdatedMedia($event)"></media-manager>
        </div>
    </div>
</div>

<add-section-content *ngIf="!company.permissions.companyLockSession || (company.permissions.companyLockSession && course.state != 'ongoing')" [open]="true" [position]="0" [course]="course" [section]="section"></add-section-content>
