<label>
    <input type="text" [placeholder]="placeholder" [(ngModel)]="message" debounce="1000" required>
    <button (click)='onAddContent(message)' type="submit" class="btn btn_action_post">
        {{'studio.timeline.button-send-message' | translate }}
    </button>
</label>

<button *ngIf="!media" class="add-media" (click)="openModal(modalMedia)">
    <!--<span>{{'studio.timeline.add-media' | translate}}</span>-->
</button>

<div *ngIf="media" class="media-manager media-manager_mini" style="height: 80px; width: 200px;">
    <media-holder [media]="media" [mini]="true"></media-holder>
    <div class="media-manager__actions">
        <button class="btn btn_color_red delete" (click)="deleteMediaPreview()">
            <span class="sr-only">{{ 'words.delete' | translate }}</span>
        </button>
    </div>
</div>

<ng-template #modalMedia let-modal class="media-library">
    <library-modal (closeModal)="modal.close($event)" (updateMedia)="onUpdatedMedia($event)" [toUpload]="true"></library-modal>
</ng-template>

<style>
    .add-media {
        opacity: 1 !important;
    }

    .add-media:before {
        content: '';
        background: url('https://static.myskillcamp.com/images/icon/icon-studio/icon_addimage_white.svg') no-repeat center;
        height: 36px;
        display: block;
    }
</style>
