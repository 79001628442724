import { Component, Input } from '@angular/core';
import { Section, SectionContent, Exchange } from '@stuplay'
import { ErrorCatcherService } from '../../../../../utils/services/error-catcher.service'

@Component({
    selector: 'app-exchange',
    templateUrl: './exchange.component.html'
})
export class ExchangeComponent {
    @Input() exchange: Exchange;
    @Input() section: Section;
    @Input() sectionContent: SectionContent;

    constructor(private errorCatcher: ErrorCatcherService) { }

    onUpdatedMedia(media) {
        this.exchange.media = media;
        this.updateExchange();
    }

    updateExchange() {
        if (this.exchange) {
            this.sectionContent.update().subscribe((sectionContent) => {
                this.errorCatcher.setErrors(sectionContent);
                this.sectionContent = sectionContent;
            });
        }
    }
}
