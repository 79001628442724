<div class="templab-summary__button templab-summary__button--content">
    <button class="templab-btn templab-btn-content" (click)="displayed('actions')">
        <i class="templab-icon templab-icon-plus-circle"></i>
        {{ 'studio.product-tour.sidebar.add-content.title' | translate }}
    </button>

    <ul class="templab-summary__button-content" [ngClass]="{'active': displays.actions}">
        <li>
            <button class="templab-btn templab-btn-activity" (click)="select('content')">
                <i class="templab-icon templab-icon-content"></i>

                <p>{{ 'words.existing-content' | translate }}</p>
            </button>
        </li>

        <li>
            <button class="templab-btn templab-btn-activity" (click)="select('activity')">
                <i class="templab-icon templab-icon-grid"></i>

                <p>{{ 'words.create-activity' | translate }}</p>
            </button>
        </li>

        <li>
            <button class="templab-btn templab-btn-activity" (click)="createEvent()">
                <msc-loader [loading]="loadingEvent"></msc-loader>

                <i *ngIf="!loadingEvent" class="templab-icon templab-icon-calendar"></i>
                <p *ngIf="!loadingEvent">{{ 'words.plan-event' | translate }}</p>
            </button>
        </li>

        <li>
            <button class="templab-btn templab-btn-activity" (click)="select('link')">
                <i class="templab-icon templab-icon-pin-2"></i>

                <p>{{ 'words.pin-link' | translate }}</p>
            </button>
        </li>
    </ul>
</div>

