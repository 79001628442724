import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Media } from '@stuplay';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { TranslationService } from '../../services/translation.service';
import { EmitterService } from "../../services/emitter.service";
import { ExternalAppsService } from "../../services/external-apps.service";
import { StorageService } from "../../services/storage.service";
import { environment } from 'src/environments/environment';

@Component({
    selector: 'library-embed',
    templateUrl: './library-embed.component.html',
    styleUrls: ['./library-embed.component.less']
})
export class LibraryEmbedComponent implements OnInit {
    @Input() media: Media;
    @Input() toUpload: boolean;
    @Input() importType: string = 'url';
    @Output() updateMedia = new EventEmitter();
    @Output() redirect = new EventEmitter();
    public me: any;
    public company: any;
    public currentLang: string;
    public link: SafeResourceUrl;
    public url: string = '';
    public embed: string = '';
    public trustedUrl: any;
    public cleanUrl: string = '';
    public sites: any;
    public links: any = {};

    constructor(
        public http: HttpClient,
        private translationService: TranslationService,
        private sanitizer: DomSanitizer,
        private emitterService: EmitterService,
        private externalAppsService: ExternalAppsService,
        private storageService: StorageService,
    ) { }

    ngOnInit() {
        this.sites = {
            'youtube': {
                'url': '//www.youtube.com/embed/{id}?rel=0&showinfo=0',
                'reg': /^watch\?v=([\w-_]*)/gi
            },
            'youtu': {
                'url': '//www.youtube.com/embed/{id}?rel=0&showinfo=0',
                'reg': /^([\w-_]*)/gi
            },
            'vimeo'  : {
                'url'    : '//player.vimeo.com/video/{id}?dnt=1',
                'reg'    : /^([\w_-]*)/gi
            },
            'dailymotion': {
                'url': '//www.dailymotion.com/embed/video/{id}',
                'reg': /^video\/([\w_-]*)/gi
            },
            'scratch': {
                'url': 'http://scratch.mit.edu/projects/{id}/#editor',
                'reg': /^projects\/(\d*)/gi
            }
        };
        this.links = {
            iframe: {
                en: 'https://player.vimeo.com/video/194838088?color=45B7D9&dnt=1',
                fr: 'https://player.vimeo.com/video/194838088?color=45B7D9&dnt=1',
                nl: 'https://player.vimeo.com/video/194838088?color=45B7D9&dnt=1'
            },
            url: {
                en: 'https://player.vimeo.com/video/194838088?color=45B7D9&dnt=1',
                fr: 'https://player.vimeo.com/video/194838088?color=45B7D9&dnt=1',
                nl: 'https://player.vimeo.com/video/194838088?color=45B7D9&dnt=1'
            }
        };
        this.currentLang = this.translationService.getCurrentLang();
        this.me = this.storageService.get('me');
        this.company = this.storageService.get('company');
    }

    onUpdatedMedia(media: any) {
        this.updateMedia.emit(media);
    }

    preview(type: string): void {
        if (type === 'embed') {
            this.embed = this.embed.replace('src= ', 'src=')
            const re = /src=[\"\''](?:https:|http:)?((?:\S[^\"\']*){1})/i;
            const result = re.exec(this.embed);

            try {
                this.cleanUrl = 'https:' + result[1];
                this.trustedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.cleanUrl);
            } catch (e) {
                // nothing
            }
        } else {
            this.processUrl();
        }
    }

    createEmbed(): void {
        if (this.cleanUrl) {
            this.http.post(`${environment.envVar.API_URL}/media/embed`, { url: this.cleanUrl })
                .subscribe(data => {
                    this.url = '';
                    this.embed = '';
                    this.toUpload ? this.onUpdatedMedia(data) : this.redirect.emit('embed');
                });
        }
    }

    getVideoUrl(type: string): SafeResourceUrl {
        return this.link ? this.link : this.link = this.sanitizer.bypassSecurityTrustResourceUrl(this.links[type][this.currentLang]);
    }

    processUrl(): void {
        if (this.url.indexOf('zoom.us') > -1 || this.url.indexOf('docs.google.com') > -1) {
            this.cleanUrl = this.url.replace(/(https:|http:)/g, '').split('?')[0];
            this.trustedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.cleanUrl);
        } else {
            const urlReplace = this.url.replace('player.', '');
            const re = /^(?:https:|http:)?\/\/(?:www\.)?([\w-]*)\.(?:[^\s\/]*)\/(.*)/i;
            const result = re.exec(urlReplace);

            try {
                if (this.sites[result[1]]) {
                    const url = this.sites[result[1]].url;
                    const id = this.sites[result[1]].reg.exec(result[2]);
                    this.cleanUrl = url.replace('{id}', id[1]);
                    this.trustedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.cleanUrl);
                } else {
                    console.error('Not valid website');
                }
            } catch (e) { }
        }
    }

    openAsideHelp(type: string): any {
        this.emitterService.activeHelp.emit({ type: type, feature: 'media' });
    }
}
