import { Component, Input } from '@angular/core';
import { WorkerService } from '../../services/worker.service';
import { Media, UtilityProvider } from '@stuplay';
import Player from '@vimeo/player';

@Component({
    selector: 'media-vimeo-player',
    templateUrl: './vimeo.component.html',
    styleUrls: ['./vimeo.component.less']
})
export class VimeoComponent {
    @Input() media: Media;
    @Input() size: number = 400;
    public ready: boolean = false;

    constructor(
        private utilityProvider: UtilityProvider,
        private workerService: WorkerService,
    ) { }

    ngOnChanges() {
        if (this.media.url) {
            this.process();
        }
    }

    process(): void {
        const id: number = parseInt(this.media.url.replace('//www.vimeo.com/', ''), 10);

        this.utilityProvider.getVimeoReady(id, new Date().getTime()).subscribe(() => {
            this.initPlayer(id);
        }, (error) => {
            this.worker(id);
        });
    }

    worker(id: number): void {
        const timeOut = setTimeout(() => {
            const func = function (event) {
                const req = new XMLHttpRequest();
                req.responseType = 'json';
                const self = this;
                req.onreadystatechange = function (e) {
                    if (this.readyState === XMLHttpRequest.DONE) {
                        if (this.status === 200) {
                            self.postMessage(this.response);
                        } else {
                            self.postMessage('error');
                        }
                    }
                };

                req.open('GET', 'https://vimeo.com/api/oembed.json?url=https://vimeo.com/' + event.data[0] + '&version=' + event.data[1], true);
                req.send();
            };

            this.workerService.run('vimeo', func, true);
            this.workerService.post('vimeo', [id, new Date().getTime()]);
            this.workerService.listen('vimeo').subscribe((data) => {
                this.workerService.stop('vimeo');
                if (data !== 'error') {
                    this.initPlayer(id);
                } else {
                    this.worker(id);
                }
            });
            clearTimeout(timeOut);
        }, 10000);
    }

    initPlayer(id: number): void {
        this.ready = true;
        const options = {
            id: id,
            height: this.size
        };
        const elementId = 'vimeo-player-' + this.media.id;
        const time = setInterval(() => {
            if (document.getElementById(elementId)) {
                const player = new Player(elementId, options);
                clearInterval(time);
            }
        }, 500);
    }
}
