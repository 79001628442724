<aside class="sidebar sidebar--modal sidebar-subscription" [ngClass]="active ? 'active' : ''" (appSidebar)="closeSidebarEmit()">
    <div class="sidebar__alert grid between middle" [ngClass]="warning ? 'active': ''" style="z-index: 9999;">
        <i class="icon icon--warning"></i>
        <p *ngIf="campsSelected.length === 1 && instances.length === 1">
            {{ 'studio.instance.warning.camp.instance' | translate }}
        </p>

        <p *ngIf="campsSelected.length === 1 && instances.length > 1">
            {{ 'studio.instance.warning.camp.instances' | translate }}
        </p>

        <p *ngIf="campsSelected.length > 1 && instances.length === 1">
            {{ 'studio.instance.warning.camps.instance' | translate }}
        </p>

        <p *ngIf="campsSelected.length > 1 && instances.length > 1">
            {{ 'studio.instance.warning.camps.instances' | translate }}
        </p>

        <a (click)="closeWarning()">
            <i class="icon icon--close"></i>
        </a>
    </div>

    <div class="sidebar__container">
        <div class="sidebar__head sidebar__head--center">
            <div class="sidebar__description">
                <h1 class="sidebar__title">
                    {{ 'words.choose-camps' | translate }}
                </h1>
            </div>

            <a class="sidebar__close" (click)="closeSidebarEmit()">
                <i class="icon icon--close"></i>
            </a>
        </div>

        <div class="sidebar__body">
            <div class="sidebar__form">
                <ul class="sidebar__progress">
                    <li class="active"></li>

                    <li>
                        <a class="tooltip-v2" [attr.data-tooltip]="'words.subscribe-method' | translate"></a>
                    </li>

                    <li>
                        <a class="tooltip-v2" [attr.data-tooltip]="'words.settings-publication' | translate"></a>
                    </li>

                    <li>
                        <a class="tooltip-v2" [attr.data-tooltip]="'words.summary-publication' | translate"></a>
                    </li>
                </ul>

                <div class="sidebar__search">
                    <div class="search">
                        <label class="search__label">
                            <i class="search__icon icon icon--search"></i>
                        </label>

                        <input class="search__input" type="search" [placeholder]="'words.search' | translate" [(ngModel)]="query" [debounce]="500" (onDebounce)="search()">

                        <i class="icon icon--close" *ngIf="query" (click)="reset()"></i>
                    </div>
                </div>

                <div class="sidebar__fieldset" *ngFor="let company of companies; let index=index">
                    <div class="sidebar__toggle" [ngClass]="{'active': displays[index]}" (click)="displayed(index)">
                        <p>{{ company.name }}</p>
                        <i class="icon icon--arrow-bottom"></i>
                    </div>

                    <div class="sidebar__camps">
                        <div class="" *ngIf="company.camps.length === 0" style="display: flex; justify-content: center; width: 100%;">
                            {{ 'words.camps-not-found' | translate }}
                        </div>
                        <div class="box-camp" [mscTooltip]="(camp.published && currentCompany.permissions.companyLockSession) ? ('instance.unpublishing.tooltip' | translate) : null" *ngFor="let camp of company.camps" [ngClass]="{'active' : isChoosed(camp), 'camp-disabled': camp.published && currentCompany.permissions.companyLockSession }" (click)="(camp.published && currentCompany.permissions.companyLockSession) ? '' : chooseCamp(camp)">
                            <div class="box-camp__content">
                                <p class="box-camp__title">{{ camp.name }}</p>
                                <div class="box-camp__stats">
                                    <div>
                                        <i class="icon icon--users"></i> {{ camp.usersCount }}
                                    </div>

                                    <div>
                                        <i class="icon icon--calendar"></i> {{ camp.instancesCount }}
                                    </div>
                                </div>

                                <div class="box-camp__checkbox">
                                    <input type="checkbox" class="input-checkbox input-checkbox--rounded" [checked]="isChoosed(camp)">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="" *ngIf="companies.length === 0 && !loading" style="display: flex; justify-content: center; align-items: center; height: 200px; font-weight: 600;">
                    {{ 'words.camps-not-found' | translate }}
                </div>

                <div class="load-more animate" *ngIf="loading">
                    <div class="dot"></div>
                </div>
            </div>
        </div>

        <div class="sidebar__footer sidebar__footer--notif">
            <p *ngIf="campsSelected.length === 0"></p>
            <p *ngIf="campsSelected.length > 0">
                <span>{{ campsSelected.length }}</span> {{ (campsSelected.length > 1 ? 'studio.publish.choose-camp.selected-camps' : 'studio.publish.choose-camp.selected-camp') | translate | lowercase }}
            </p>

            <button class="btn btn--primary" (click)="publish(modalUnpublished)">
                {{ 'words.next' | translate }}
            </button>
        </div>
    </div>
</aside>

<ng-template #modalUnpublished let-modal>
    <div class="modal-header">
        <span></span>
        <button type="button" class="close" aria-label="Close" (click)="modal.close()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div style="background-color: #FFFFFF; padding: 1.2rem 0 4rem 0;">
            <div class="m-title">
                {{ 'instance.unpublishing' | translate }}
            </div>

            <div class="m-content">
                <label>
                    <input type="checkbox" [(ngModel)]="delete.savedContents"> {{ 'instance.delete.saved-contents' | translate }}
                </label>
            </div>

            <div class="m-content">
                <label>
                    <input type="checkbox" [(ngModel)]="delete.notifySavedContents"> {{ 'instance.notify-saved-contents' | translate }}
                </label>
            </div>

            <div class="actions" style="padding-top: 2rem;">
                <button class="btn btn_color_red" (click)="next()">
                    {{ 'studio.correction.button-submit' | translate }}
                </button>

                <button class="btn btn_color_grey" (click)="closeUnpublished()">
                    {{ 'studio.common.button.cancel' | translate }}
                </button>
            </div>
        </div>
    </div>
</ng-template>

<style>
    .m-title {
        font-size: 1.5rem;
        font-weight: 800;
    }

    .m-content {
        margin: 2rem;
    }
</style>
