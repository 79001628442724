import { Question } from '../question';
import { HttpClient } from '@angular/common/http';

export class TrueFalseQuestion extends Question {
  param: TrueFalseQuestionParameters; //(stringify);
  markMode: string; //TODO;

  constructor(question: any, http: HttpClient) {
    super(question, http);
    this.extends(question);
  }

  extends(question: any): TrueFalseQuestion {
    Object.assign(this, question);
    if (question.param) {
      if (this.param instanceof TrueFalseQuestionParameters) {
        this.param.extends(question.paremeters)
      } else {
        this.param = new TrueFalseQuestionParameters(question.param);
      }
    }
    // this.param = JSON.parse(question.param);
    return this;
  }
}

export class TrueFalseQuestionParameters {
  choice: boolean = true;
  type: string; // (True or Yes);
  allowNone: boolean; //TO DO;

  constructor(data: any = {}) {
    this.extends(data);
  }

  extends(data: any = {}): TrueFalseQuestionParameters {
    Object.assign(this, data);

    return this;
  }
}
