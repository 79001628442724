import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CourseCamp } from './course-camp';
import { environment } from 'src/environments/environment';

@Injectable()
export class CourseCampProvider {
    defaultLang: string;

    constructor(public http: HttpClient) {}

    getCourseCamp(id: number, preview: string): Observable<CourseCamp> {
        return this.http.get(`${environment.envVar.API_URL}/course-camp/${id}?preview=${preview}`)
            .pipe(map((courseCamp) => {
                return new CourseCamp(courseCamp, this.http);
            }));
    }

    createCourseCamp(courseCamp: any): Observable<CourseCamp> {
        return this.http.post(`${environment.envVar.API_URL}/course-camp`, courseCamp)
            .pipe(map((data) => new CourseCamp(data, this.http)));
    }
}
