import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'instance-manager',
    templateUrl: './instance-manager.component.html'
})
export class InstanceManagerComponent {
    @Input() step: string = '';
    @Input() template: any = '';
    @Input() instances: any[];
    @Input() camps: any[];
    @Output() eventDispatcher: EventEmitter<any> = new EventEmitter;

    eventManager(event: any, type: string): void {
        this.eventDispatcher.emit({object: event, type: type});
    }
}
