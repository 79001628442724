import { Component, Input, OnInit } from '@angular/core';
import { Media, Section, SectionContent } from '@stuplay';

@Component({
    selector: 'app-media',
    templateUrl: './app-media.component.html'
})
export class AppMediaComponent implements OnInit {
    @Input() media: Media;
    @Input() section: Section;
    @Input() sectionContent: SectionContent;

    constructor() {}

    ngOnInit() {}

    updateMedia() {
        this.sectionContent.update().subscribe((res) => this.sectionContent = res);
    }
}
