<div class="add-section-content" [attr.id]="'as-' + position" [ngClass]="{'open-modal': modalAction}">
    <div class="add-section-content__trigger" (click)="changeModalAction()">
        <button class="icon">+</button>
        <button class="btn btn_color_white btn_width_1 text">{{ 'studio.section-content.add-section-content' | translate }}</button>
        <button class="icon">+</button>
    </div>

    <div class="add-section-content__content">
        <div class="add-section-content__block-title" id="selectActivitySection">
            <h3 class="section__content">{{ 'studio.section-content.select-section-content' | translate }}</h3>

            <img (click)="openAsideHelp('activity')" src="https://static.myskillcamp.com/images/guidance/question-mark-circle.svg" />
        </div>

        <div class="row">
            <div class="columns medium-3">
                <div class="item" (click)="openModal(modalMedia, { windowClass: 'force-fullscreen-fixed' })">
                    <div class="image-container">
                        <img src="https://static.myskillcamp.com/images/guidance/star.svg" />
                    </div>

                    <div class="item__body">
                        <img src="https://static.myskillcamp.com/images/illustration/section-content/media.svg" />
                        <h5 class="item__title">{{ 'studio.modal-activity.media' | translate }}</h5>
                        <span class="item__subtitle">{{ 'studio.modal-activity.media-description' | translate }}</span>
                    </div>
                </div>
            </div>
            <ng-template #modalMedia let-modal>
                <library-modal (closeModal)="modal.close()" (updateMedia)="addMedia($event)" [toUpload]="true"></library-modal>
            </ng-template>

            <div class="columns medium-3">
                <div class="item" (click)="addCheatSheet()">
                    <div class="image-container">
                        <img src="https://static.myskillcamp.com/images/guidance/star.svg" />
                    </div>

                    <div class="item__body">
                        <img src="https://static.myskillcamp.com/images/illustration/section-content/cheatsheet.svg" />
                        <h5 class="item__title">{{ 'studio.modal-activity.cheatsheet' | translate }}</h5>
                        <span class="item__subtitle">{{ 'studio.modal-activity.cheatsheet-description' | translate }}</span>
                    </div>
                </div>
            </div>

            <div class="columns medium-3">
                <div class="item" (click)="addExercise()">
                    <div class="image-container">
                        <img src="https://static.myskillcamp.com/images/guidance/star.svg" />
                    </div>

                    <div class="item__body">
                        <img src="https://static.myskillcamp.com/images/illustration/section-content/exercise.svg">
                        <h5 class="item__title">{{ 'studio.modal-activity.exercise' | translate }}</h5>
                        <span class="item__subtitle">{{ 'studio.modal-activity.exercise-description' | translate }}</span>
                    </div>
                </div>
            </div>

            <div class="columns medium-3">
                <div class="item" (click)="addExchange()">
                    <img src="https://static.myskillcamp.com/images/illustration/section-content/discussion.svg" />
                    <h5 class="item__title">{{ 'studio.modal-activity.discussion' | translate }}</h5>
                    <span class="item__subtitle">{{ 'studio.modal-activity.discussion-description' | translate }}</span>
                </div>
            </div>

            <div class="columns medium-3">
                <div class="item" (click)="addEvent()">
                    <img src="https://static.myskillcamp.com/images/illustration/section-content/event.svg" />
                    <h5 class="item__title">{{ 'studio.modal-activity.event' | translate }}</h5>
                    <span class="item__subtitle">{{ 'studio.modal-activity.event-description' | translate }}</span>
                </div>
            </div>

            <div class="columns medium-3">
                <div class="item" (click)="addProject()">
                    <img src="https://static.myskillcamp.com/images/illustration/section-content/project.svg">
                    <h5 class="item__title">{{ 'studio.modal-activity.project' | translate }}</h5>
                    <span class="item__subtitle">{{ 'studio.modal-activity.project-description' | translate }}</span>
                </div>
            </div>

            <div class="columns medium-3">
                <div class="item item_existing" (click)="addSelfAssessment()">
                    <img src="https://static.myskillcamp.com/images/illustration/section-content/project.svg">
                    <h5 class="item__title">
                        {{ 'studio.modal-activity.selfassessment' | translate}}
                    </h5>
                    <span class="item__subtitle">
                        {{ 'studio.modal-activity.selfassessment-description' | translate}}
                    </span>
                </div>
            </div>

            <div class="columns medium-3">
                <div class="item" (click)="openAside()">
                    <img src="https://static.myskillcamp.com/images/illustration/section-content/scorm.svg">
                    <h5 class="item__title">{{'studio.modal-activity.lti' | translate}}</h5>
                    <span class="item__subtitle">
                        {{'studio.modal-activity.lti-description' | translate}}
                    </span>
                </div>
            </div>
            <msc-aside-lti *ngIf="display" [action]="'create'" (onSave)="addLti($event)" (onClose)="closeAside()"></msc-aside-lti>

            <div class="columns medium-3">
                <div class="item" (click)="openModal(modalScormCloud)">
                    <img src="https://static.myskillcamp.com/images/illustration/section-content/scorm.svg" />
                    <h5 class="item__title">{{ 'studio.modal-activity.scorm' | translate }}</h5>
                    <span class="item__subtitle">{{ 'studio.modal-activity.scorm-description' | translate }}</span>
                </div>
            </div>
            <ng-template #modalScormCloud let-modal>
                <scorm-modal [cloud]="true" (closeModal)="modal.close()" (updateScorm)="addScorm($event)"></scorm-modal>
            </ng-template>

            <div class="columns medium-3">
                <div class="item" (click)="addCertificate()">
                    <img src="https://static.myskillcamp.com/images/illustration/section-content/certif.svg">
                    <h5 class="item__title">{{'studio.modal-activity.certificate' | translate}}</h5>
                    <span class="item__subtitle">
                        {{'studio.modal-activity.certificate-description' | translate}}
                    </span>
                </div>
            </div>
            <div class="columns medium-3">
                <div class="item item_existing" (click)="openModal(modalReUse, { windowClass: 'force-fullscreen-height' })">
                    <span class="item__icon">+</span>
                    <h5 class="item__title">
                        {{ 'studio.modal-activity.re-use-activity.title' | translate}}
                    </h5>
                    <span class="item__subtitle">
                        {{ 'studio.modal-activity.re-use-activity.description' | translate}}
                    </span>
                </div>
            </div>

            <ng-template class="modal-library" #modalReUse let-modal>
                <modal-re-use-section-content [position]="position" [course]="course" [section]='section' (closeModal)="modal.close()"></modal-re-use-section-content>
            </ng-template>
        </div>

        <div class="add-section-content__text">
            <img src="https://static.myskillcamp.com/images/guidance/star-outline.svg" />
            <div>{{ 'studio.activity.recommended' | translate }}</div>
        </div>
    </div>
</div>
