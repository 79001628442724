<div class="alert-toast" style="z-index: 999999;">
    <div *ngFor="let toast of toasts" [ngStyle]="{'animation': 'fadein 0.5s, fadeout 0.5s '+ (toast.duration - 500) / 1000 +'s'}" [class]="'alert-toast__message alert-toast__message--'+ toast.type" (click)="dismiss(toast)">
        <span class="alert-toast__content" *ngIf="!toast.link">
            {{ 'studio.toast.' + toast.message | translate }}
        </span>

        <span class="alert-toast__content" *ngIf="toast.link">
            <a [routerLink]="toast.link.length > 1 ? [toast.link[0], toast.link[1]] : [toast.link[0]]">
                {{ 'studio.toast.' + toast.message | translate }}
            </a>
        </span>

        <a class="alert-toast__close">
            <i class="icon icon--close"></i>
        </a>
    </div>
</div>
