<div class="alert alert_error" *ngIf="error && !closeAlert">
    <div class="alert__title"></div>

    <div class="alert__content">
        {{'error.message' | translate}}
    </div>

    <button class="alert__close" (click)="changeCloseAlert()">
        <span class="sr-only">Delete</span>
    </button>
</div>

<div class="slide slide_{{sectionContent.context}} {{sectionContent.context}}"
    [ngClass]="{'event_mode_single': sectionContent.context === 'event' && sectionContent.event.eventSessions.length === 1, 'event_mode_multiple': sectionContent.context === 'event' && sectionContent.event.eventSessions.length > 1}">
    <div class="settings">
        <div *ngIf="sectionContent.context == 'scormcloud'" [ngClass]="{'tooltip-v2 tooltip-v2--top tooltip-v2--large': sectionContent.scormcloud.hasOutdatedScormVersions}"
            [attr.data-tooltip]="'tooltip.scorm-version.warning' | translate: {version: ('words.scorm.version.' + sectionContent.scormcloud.version | translate)}">
            <a href="https://scorm.com/scorm-explained/business-of-scorm/scorm-versions/" target="_blank"
                class="settings-edit"><b>{{'words.scorm.version.' + sectionContent.scormcloud.version | translate}}</b></a>
        </div>
        <div *ngIf="sectionContent.context == 'scorm' || sectionContent.context == 'scormcloud' || sectionContent.context == 'project'"
            class="no-mobile">
            <div class="no-mobile__info">
                <p>{{'studio.course.mobile-not-supported' | translate}}</p>
            </div>
        </div>

        <div class="settings-edit" [ngClass]="{'open-settings': editAction == true }">
            <button class="settings-edit__trigger" (click)="changeEditAction($event)"
                *ngIf="['cheatsheet', 'exchange', 'certificate', 'scorm'].indexOf(sectionContent.context) == -1">
                <span class="sr-only">{{'words.edit' | translate}}</span>
            </button>

            <div class="settings-edit__content {{'settings_position_'+position}}" *ngIf="editAction">
                <div class="title">
                    {{ 'words.edit' | translate }}
                </div>

                <app-edit-settings [object]="sectionContent[sectionContent.context]" [sectionContent]="sectionContent">
                </app-edit-settings>
            </div>
        </div>

        <div class="settings-delete" [ngClass]="{'open-settings': deleteAction == true }">
            <button class="settings-delete__trigger btn_action_delete" (click)="changeDeleteAction($event)">
                <span class="sr-only">{{ 'words.delete' | translate }}</span>
            </button>

            <div class="settings-delete__content {{ 'settings_position_' + position }}" *ngIf="deleteAction">
                <div class="title">
                    {{ 'words.delete' | translate }}
                </div>

                <div class="settings-block" style="padding: 1em;">
                    <div class="settings-block__title has_tooltip">
                        <h5>{{ 'studio.course.section-content.'+sectionContent.context+'-delete-hint' | translate }}
                        </h5>
                    </div>

                    <div class="settings-block_details">
                        <div class="row">
                            <div class="columns small-6">
                                <button class="delete" (click)="deleteSectionContent()">
                                    <span>{{ 'studio.common.button.delete' | translate }}</span>
                                </button>
                            </div>

                            <div class="columns small-6">
                                <button class="cancel" (click)="changeDeleteAction($event)">
                                    <span>{{ 'studio.common.button.cancel' | translate }}</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <h2 class="heading heading_border_none" *ngIf="sectionContent.context == 'exercise'">
        <div class="heading__block-title">
            <span class="instruction">
                {{ 'studio.course.section-content.exercise-title' | translate }}

                <img (click)="openAsideHelp(sectionContent.context)"
                    src="https://static.myskillcamp.com/images/guidance/question-mark-circle.svg" />
            </span>
        </div>

        <live-editor (onUpdate)="updateSectionContent()" [(inputData)]="sectionContent.title"
            [placeholder]="('studio.course.section-content.' + sectionContent.context + '-hint' | translate)">
        </live-editor>
    </h2>

    <h2 class="heading heading_border_none"
        *ngIf="['exercise', 'certificate', 'event'].indexOf(sectionContent.context) === -1">
        <span class="instruction">
            {{ 'studio.course.section-content.'+sectionContent.context+'-title' | translate }}

            <img (click)="openAsideHelp(sectionContent.context)"
                src="https://static.myskillcamp.com/images/guidance/question-mark-circle.svg"
                *ngIf="sectionContent.context !== 'external_link'">
        </span>

        <live-editor (onUpdate)="updateSectionContent(); updateSubContext()" [(inputData)]="sectionContent.title"
            [placeholder]="('studio.course.section-content.'+sectionContent.context+'-hint' | translate)"></live-editor>
    </h2>

    <p class="event-presentation__description" *ngIf="containsDescription(sectionContent.context)">
        <msc-aside-editor
            [placeholder]="'studio.course.section-content.'+sectionContent.context+'-hint-desc' | translate"
            [(content)]="sectionContent.description" (onUpdate)="updateSectionContent()"></msc-aside-editor>
    </p>

    <div *ngIf="sectionContent.context == 'project'" id="#sc-{{sectionContent.id}}">
        <app-project [project]="sectionContent.project"></app-project>
    </div>

    <div *ngIf="sectionContent.context == 'selfassessment'" id="#sc-{{sectionContent.id}}">
        <app-self-assessment [selfAssessment]="sectionContent.selfassessment"></app-self-assessment>
    </div>

    <div *ngIf="sectionContent.context == 'event'" class="event-presentation" id="#sc-{{sectionContent.id}}">
        <app-event [event]="sectionContent.event" [section]="section" [sectionContent]="sectionContent"></app-event>
    </div>

    <div *ngIf="sectionContent.context == 'media'" id="#sc-{{sectionContent.id}}">
        <app-media [media]="sectionContent.media" [section]="section" [sectionContent]="sectionContent"></app-media>
    </div>

    <div *ngIf="sectionContent.context == 'exercise'" id="#sc-{{sectionContent.id}}">
        <app-exercise [exercise]="sectionContent.exercise" [section]="section" [sectionContent]="sectionContent">
        </app-exercise>
    </div>

    <div *ngIf="sectionContent.context == 'cheatsheet'" id="#sc-{{sectionContent.id}}">
        <app-cheatsheet [cheat]="sectionContent.cheatsheet" [section]="section" [sectionContent]="sectionContent">
        </app-cheatsheet>
    </div>

    <div *ngIf="sectionContent.context == 'exchange'" id="#sc-{{sectionContent.id}}">
        <app-exchange [exchange]="sectionContent.exchange" [section]="section" [sectionContent]="sectionContent">
        </app-exchange>
    </div>

    <div *ngIf="sectionContent.context == 'certificate'" id="#sc-{{sectionContent.id}}">
        <app-certificate [certificate]="sectionContent.certificate" [section]="section"
            [sectionContent]='sectionContent'></app-certificate>
    </div>

    <div *ngIf="sectionContent.context == 'scorm'" id="#sc-{{sectionContent.id}}">
        <app-scorm [scorm]="sectionContent.scorm" [sectionContent]="sectionContent"></app-scorm>
    </div>

    <div *ngIf="sectionContent.context == 'scormcloud'" id="#sc-{{sectionContent.id}}">
        <app-scorm [cloud]="true" [scorm]="sectionContent.scormcloud" [sectionContent]="sectionContent"></app-scorm>
    </div>

    <div *ngIf="sectionContent.context == 'external_link'" id="#sc-{{sectionContent.id}}">
        <app-external-link [externalLink]="sectionContent.externalLink" [section]="section"
            [sectionContent]='sectionContent'></app-external-link>
    </div>
    <msc-aside-lti *ngIf="display" [lti]="sectionContent.externalLink" [action]="'edit'" (onSave)="updateLti($event)"
        (onClose)="closeAside()"></msc-aside-lti>

    <div *ngIf="sectionContent.context == 'video'" id="#sc-{{sectionContent.id}}">
        <app-video [video]="sectionContent.video" [section]="section" [sectionContent]='sectionContent'></app-video>
    </div>
</div>