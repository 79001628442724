import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable()
export class HeaderService {
    private counts: any;
    private queue: any = {};
    private countsSbj: BehaviorSubject<any> = new BehaviorSubject<any>({});

    constructor(private http: HttpClient) { }

    set(counts: any): void {
        this.counts = counts;
    }

    getCounts(): Observable<any> {
        return (this.counts) ? of(this.counts) : this.http.get(`${environment.envVar.API_URL}/studio/counts`)
            .pipe(
                map(data => {
                    Object.entries(data).forEach(([key, value]) => {
                        if (this.queue[key]) {
                            data[key] = value + this.queue[key];
                            delete this.queue[key];
                        }
                    })
                    return this.counts = data
                }),
                tap(data => this.setSubjectCounts(data))
            );

    }

    get(item: string): any {
        return this.counts ? this.counts[item] : null;
    }

    getSubjectCounts() {
        return this.countsSbj.asObservable();
    }

    setSubjectCounts(data: any) {
        this.countsSbj.next(data);
    }

    setQueue(sign: number, item, count) {
        if (!this.queue[item]) {
            this.queue[item] = count * sign;
        } else {
            this.queue[item] += count * sign;
        };
    }

    plus(item: string, count: number = 1): void {
        if (!this.counts) {
            this.setQueue(1, item, count);
            return;
        }
        this.counts[item] += count;
    }

    minus(item: string, count: number = 1): void {
        if (!this.counts) {
            this.setQueue(-1, item, count);
            return;
        }
        this.counts[item] -= count;
    }

    delete(): void {
        this.counts = null;
    }
}
