<div class="overlay" [ngClass]="{'active' : sidebarActive}"></div>

<msc-topbar [type]="'journey'" [breadcrumb]="journey.title" [publishJourneyActive]="true" (publish)="openSidebar('publish')"></msc-topbar>

<div *ngIf="sidebarActive === 'external-link'">
    <msc-aside-lti [lti]="selectedContentForEdit.value.externalLink"
                   [action]="'edit'"
                   [sectionContent]="selectedContentForEdit"
                   (onClose)="closeSidebar()"
                   (onSave)="updateLti($event)">
    </msc-aside-lti>
</div>

<div *ngIf="sidebarActive === 'publish'">
    <journey-sidebar-publish [journey]="journey"
                             (close)="closeSidebar()"
                             (publish)="publishJourney($event)">
    </journey-sidebar-publish>
</div>

<div *ngIf="sidebarActive === 'update'">
    <journey-sidebar-create [journey]="journey"
                            (close)="closeSidebar()"
                            (create)="updateJourney($event)">
    </journey-sidebar-create>
</div>

<div class="container">
    <div class="grid">
        <aside class="sidebar sidebar--filter">
            <a class="sidebar__edit" (click)="openSidebar('update')">
                <i class="icon icon--edit-text"></i>
            </a>

            <h1 class="sidebar__title sidebar__title--alert">
                <div>{{ journey.title | truncate: '50' }}</div>

<!--                <div class="sidebar__title__alert" *ngIf="journey.isUnpublished">-->
<!--                    <div class="tooltip-v2 tooltip-v2&#45;&#45;large" [attr.data-tooltip]="'tooltip.learning-path.unpublished' | translate">-->
<!--                        <i class="templab-icon templab-icon-alert-triangle templab-icon-alert templab-color&#45;&#45;red"></i>-->
<!--                    </div>-->
<!--                </div>-->
            </h1>

            <div class="" style="text-align: center">
                <img *ngIf="!journey.media" class="sidebar__img" [src]="'https://static.myskillcamp.com/lxp/images/logo-empty-state-' + journey.id % 7 + '.jpg'" alt="">
                <img *ngIf="journey.media" class="sidebar__img" [src]="getPicture(journey.media)" alt="media">
            </div>

            <p style="padding: 1rem 0 0 0; max-height: 300px; overflow: auto;" [innerHTML]="journey.description | safe:'html'"></p>

            <div class="sidebar__listing">
                <ul class="sidebar__list">
                    <li class="sidebar__list-item">
                        <i class="icon icon--time"></i>
                        <strong>{{ 'words.duration' | translate }}</strong>
                    </li>

                    <li class="sidebar__list-item">
                        <i class="icon icon--language"></i>
                        <strong>{{ 'studio.course.language' | translate }}</strong>
                    </li>

                    <li class="sidebar__list-item">
                        <i class="icon icon--tag"></i>
                        <strong>{{ 'studio.journey.type' | translate }}</strong>
                    </li>

                    <li class="sidebar__list-item">
                        <i class="icon icon--journey"></i>
                        <strong>{{ 'words.is-certificable' | translate }}</strong>
                    </li>

                    <li class="sidebar__list-item">
                        <i class="icon icon--skill"></i>
                        <strong>{{ 'words.skills' | translate }}</strong>
                    </li>
                </ul>

                <ul class="sidebar__list" style="color: #475C67;">
                    <li class="sidebar__list-item">{{ transformDuration(journey.duration) }} {{ 'words.prefix-' + prefixDuration | translate }}</li>
                    <li class="sidebar__list-item">{{ journey.language?.name }}</li>
                    <li class="sidebar__list-item">{{ 'type.' + journey.type | translate }}</li>
                    <li class="sidebar__list-item">{{ journey.skills }}</li>
                    <li class="sidebar__list-item">                         
                        <div class="form__item grid__half">
                            <label class="input">
                                <input type="checkbox" (click)="externalUpdateJourney()" class="input-checkbox" [(ngModel)]="isCertificable">
                            </label>
                        </div>
                    </li>
                </ul>
            </div>

            <div class="sidebar__skills">
                <div class="dropdown dropdown--skill" [ngClass]="{'active': displayDropdown}" [(autoClose)]="displayDropdown" (click)="displayedDropdown()">
                    <div class="dropdown__label">
                        {{ selectedCompany?.name }}
                        <i class="new-icon icon-arrow-ios-downward-outline"></i>
                    </div>
                    <div class="dropdown__list">
                        <div class="dropdown__item" *ngFor="let company of companies" (click)="selectCompany(company)">
                            {{ company.name }}
                        </div>
                    </div>
                </div>

                <span class="sidebar__skills-title" *ngIf="filterSkills(true).length > 0">
                    {{ 'words.thematics' | translate }}
                </span>

                <div class="skills__thematic-item">
                    <div class="skill skill--rate" *ngFor="let skill of filterSkills(true); let index=index" (mouseout)="displayLevel(index, null, true)">
                        <div class="skill__content" (click)="updateLevel(skill, 0)">
                            <i class="new-icon icon-grid-outline" style="padding: 0 0.5rem 0 0;"></i>
                            <div class="skill__text">{{ skill.name }}</div>

                            <div class="skill__rating">
                                <span [ngClass]="{'active' : isLevel(index, skill, 0, true)}" (mouseover)="displayLevel(index, 1, true)" (click)="updateLevel(skill, 1); $event.stopPropagation()"></span>
                                <span [ngClass]="{'active' : isLevel(index, skill, 1, true)}" (mouseover)="displayLevel(index, 2, true)" (click)="updateLevel(skill, 2); $event.stopPropagation()"></span>
                                <span [ngClass]="{'active' : isLevel(index, skill, 2, true)}" (mouseover)="displayLevel(index, 3, true)" (click)="updateLevel(skill, 3); $event.stopPropagation()"></span>
                            </div>

                            <div class="skill__close" (click)="detachSkill(skill)">
                                <i class="new-icon icon-close-outline"></i>
                            </div>
                        </div>
                    </div>
                </div>

                <span class="sidebar__skills-title" *ngIf="filterSkills().length > 0">
                    {{ 'words.other-skills' | translate }}
                </span>

                <div>
                    <div class="skill skill--rate" *ngFor="let skill of filterSkills(); let index=index" (mouseout)="displayLevel(index)">
                        <div class="skill__content" (click)="updateLevel(skill, 0)">
                            <div class="skill__text">{{ skill.name }}</div>

                            <div class="skill__rating">
                                <span [ngClass]="{'active' : isLevel(index, skill, 0)}" (mouseover)="displayLevel(index, 1)" (click)="updateLevel(skill, 1); $event.stopPropagation()"></span>
                                <span [ngClass]="{'active' : isLevel(index, skill, 1)}" (mouseover)="displayLevel(index, 2)" (click)="updateLevel(skill, 2); $event.stopPropagation()"></span>
                                <span [ngClass]="{'active' : isLevel(index, skill, 2)}" (mouseover)="displayLevel(index, 3)" (click)="updateLevel(skill, 3); $event.stopPropagation()"></span>
                            </div>

                            <div class="skill__close" (click)="detachSkill(skill)">
                                <i class="new-icon icon-close-outline"></i>
                            </div>
                        </div>
                    </div>
                </div>

                <msc-loader [loading]="loading"></msc-loader>

                <button class="add-item" (click)="openAside()">
                    {{ 'words.add-skill' | translate }}
                </button>
            </div>

            <div *ngIf="journey.autoReminders != 0 || journey.usersLimit > 0" style="margin: 1rem 0 0 0;">
                <p style="font-weight: 800;">
                    {{ 'studio.journey.conditions' | translate }}
                </p>

                <ul class="box">
                    <li class="grid" *ngIf="journey.usersLimit > 0">
                        <i class="icon icon--check" style="color: #F6904C; font-weight: bold; margin: 0 0.3rem 0 0;"></i>
                        <p>{{ 'studio.journey.users-limit' | translate }} {{ 'words.at' | translate }} {{ journey.usersLimit }}</p>
                    </li>

                    <li class="grid" *ngIf="journey.autoReminders !== 0">
                        <i class="icon icon--check" style="color: #F6904C; font-weight: bold; margin: 0 0.3rem 0 0;"></i>
                        <p> {{ 'studio.journey.auto-reminders' | translate }}</p>
                    </li>
                </ul>
            </div>
        </aside>

        <section class="section section--sidebar session">
            <div class="templab-reset">
                <div class="templab-summary" *ngIf="mode === 'summary'">
                    <div class="templab-summary__empty-box" *ngIf="!journey.contents">
                        {{ 'studio.learning-path.empty' | translate }}
                    </div>

                    <div class="templab-summary__timeline">
                        <div *ngFor="let content of journey.contents; let index = index">
                            <ng-container *ngIf="content.data">
                                <journey-condition
                                               [content]="content.data"
                                               [conditions]="content.conditions"
                                               [first]="index === 0"
                                               [last]="(journey.contents.length - 1) === index"
                                               (activeSidebar)="manageConditionSidebar($event)"
                                               (addCondition)="manageAddCondition($event, content)"
                                               (updateCondition)="manageUpdateCondition($event, content)"
                                               (deleteCondition)="manageDeleteCondition($event, content)">
                                </journey-condition>
                            </ng-container>
                            
                            <ng-container *ngIf="content.data">
                                <journey-content 
                                             [contentType]="content.context"
                                             [content]="content.data"
                                             [journey]="journey"
                                             [conditions]="content.conditions"
                                             [first]="index === 0"
                                             [last]="(journey.contents.length - 1) === index"
                                             [index]="index"
                                             [published]="journey.published"
                                             [movingContent]="movingContent"
                                             (editContent)="editContent(content.context, $event)"
                                             (deleteContent)="deleteContent(content)"
                                             (moveContent)="moveContent($event, index)">
                                </journey-content>
                            </ng-container>
                        </div>

                        <journey-button [company]="company"
                                        [loadingEvent]="loaders.createEvent"
                                        (onSelect)="flushContentForEdit(); openLibrary($event)"
                                        (onCreateEvent)="addEvent()">
                        </journey-button>
                    </div>
                </div>

                <journey-library *ngIf="mode === 'library'"
                                 [company]="company"
                                 [journey]="journey"
                                 [(action)]="libraryAction"
                                 [content]="selectedContentForEdit?.value"
                                 [edit]="edit"
                                 [event]="createdEvent"
                                 [sectionContent]="sectionContent"
                                 [loadingCreateEvent]="loaders.createEvent"
                                 (onAddCourse)="addContent('course', $event)"
                                 (onAddPtmc)="addContent('external_content', [$event])"
                                 (onAddContent)="addContent('content', [$event])"
                                 (onSelectedEvent)="addEvent()"
                                 (onEventCreate)="addContent('content', [$event])"
                                 (onUpdate)="manageUpdate($event)"
                                 (onRefresh)="refreshContent()"
                                 (onClose)="changeMode('summary')">
                </journey-library>
            </div>
        </section>
    </div>
</div>

<msc-aside-skill *ngIf="displayAside"
                 [company]="selectedCompany"
                 [content]="journey"
                 [skills]="skills"
                 [full]="true"
                 (onEvent)="eventDispatcher($event)"
                 (onClose)="closeAside()">
</msc-aside-skill>

<style>
    .sidebar--filter {
        width: 400px !important;
        padding: 0.5rem 2rem;
        margin: 2rem 0 1rem 0;
    }

    .sidebar__img {
        max-height: 20rem;
    }

    .section--sidebar {
        width: calc(100% - 400px - 2rem) !important;
    }
</style>
