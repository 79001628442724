<div class="event__modes row">
    <div class="columns medium-6">
        <a (click)="addEventSession()" class="event_mode_single">
            <img src="https://static.myskillcamp.com/images/illustration/event/session_single.svg">

            <h2 class="heading heading_border_none">
                <span> {{ 'studio.event.event-presentation.event' | translate }}</span>
                {{ 'studio.event.event-presentation.single-session' | translate }}
            </h2>

            <p> {{ 'studio.event.event-presentation.create-single-session' | translate }} </p>
        </a>
    </div>

    <div class="columns medium-6">
        <a class="event_mode_multiple">
            <img src="https://static.myskillcamp.com/images/illustration/event/session_multiple.svg">

            <h2 class="heading heading_border_none">
                <span> {{ 'studio.event.event-presentation.event' | translate }}</span>
                {{ 'studio.event.event-presentation.multiple-sessions' | translate }}
            </h2>

            <p> {{ 'studio.event.event-presentation.create-multiple-sessions' | translate }}</p>
        </a>
    </div>
</div>
