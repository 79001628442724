import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Journey, JourneyProvider, SectionContent } from '@stuplay';
import { EmitterService } from '../../../../../../utils/services/emitter.service';
import { StorageService } from '../../../../../../utils/services/storage.service';
import { ExternalAppsService } from '../../../../../../utils/services/external-apps.service';

@Component({
    selector: 'journey-library-event',
    templateUrl: './library-event.component.html'
})
export class JourneyLibraryEventComponent implements OnInit {
    @Input() journey: Journey;
    @Input() content: any;
    @Input() event: Event;
    @Input() sectionContent: SectionContent;
    @Output() add: EventEmitter<any> = new EventEmitter;
    @Output() edit: EventEmitter<any> = new EventEmitter;
    public company: any;
    public me: any;
    public enable: any;
    public notifyUsers: boolean = false;

    constructor(
        private journeyProvider: JourneyProvider,
        private emitterService: EmitterService,
        private externalAppsService: ExternalAppsService,
        private storageService: StorageService,
    ) { }

    ngOnInit() {
        this.company = this.storageService.get('company');
        this.me = this.storageService.get('me');

        this.enable = {
            virtualClass: this.storageService.get('applications') && this.storageService.get('applications').learncube
        };

        if (this.content) {
            this.sectionContent = this.content;

            this.journeyProvider.getSectionContent(this.content.id).subscribe((data) => {
                this.sectionContent = data;
            });
        }

        const element: any = document.getElementById('main-content');
        const scroll: any = {
            block: 'start',
            inline: 'nearest',
            behavior: 'smooth'
        };
        element.scrollIntoView(scroll);
    }

    addActivity(): void {
        this.add.emit(this.sectionContent);
    }

    editActivity(): void {
        this.edit.emit(this.sectionContent);
    }

    updateSettings(): void {
        this.sectionContent.isScorable = !this.sectionContent.isScorable;
        this.sectionContent.update({
            traject_id: this.journey.id
        }).subscribe();
    }
}
