import { Component, HostListener, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
//
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
//
import { CourseProvider, EventsProvider } from '@stuplay';
//
import { StorageService } from '../../utils/services/storage.service';
import { HeaderService } from '../../utils/services/header.service';
import { ToastService } from '../../utils/components/toast/toast.service';
import { RedirectService } from 'src/app/utils/services/redirect.service';
import { SessionProvider } from 'src/app/providers';
import { ExternalAppsService } from 'src/app/utils/services/external-apps.service';
//
import tippy from 'tippy.js'
import PerfectScrollbar from 'perfect-scrollbar';
import { Subject } from 'rxjs';
import { EmitterService } from 'src/app/utils/services/emitter.service';
import { environment } from 'src/environments/environment';

const DATE_DEFAULT_FORMAT: string = 'YYYY/MM/dd HH:mm:ss';

@Component({
    selector: 'app-instance',
    templateUrl: './instance.component.html',
    styleUrls: ['./instance.component.less'],
    providers: [DatePipe],
})
export class InstanceComponent {
    @ViewChild('modalUnpublish') modalUnpublish;
    @ViewChild('modalUnpublishConfirm') modalUnpublishConfirm;
    public company: any;
    public step: string;
    public template: any;
    public templates: any[] = [];
    public pagination: any;
    private paginationTrainers: any;
    public selectedInstances: any[];
    private search: any = {};
    private trainersList: any[] = [];
    private templatesSelectList: any[] = [];
    private instancesSelectList: any[] = [];
    private selectedLanguageIds: number[] = [];
    private trainersSelect: any[] = [];
    private discussions: any[] = [];
    public campsSelected: any[];
    private dropdownOpen: boolean[] = [];
    private templateOpen: boolean[] = [];
    private dropdownTrainersActive: boolean = false;
    private dropdownLanguagesActive: boolean = false;
    public dropdownTemplateFilter: boolean = false;
    public displayMode: string = 'list';
    private eventsCalendar: any[] = [];
    private currentLang: any = 'fr';
    public templatesFilter: any;
    private templateFilterChosen: any = 'reset';
    private langsFilter: string[] = [];
    private currentTemplate: any;
    private currentInstance: any;
    public datePickerActive: boolean = false;
    public breadcrumb: boolean = false;
    public processing: boolean = false;
    private canActive: boolean = true;
    private displayMenu: boolean[] = [];
    public aside: any;
    private selectedTemplate: any;
    private fetchingData: any = {
        courses: [],
        pagination: {
            currentPage: 0
        }
    };
    public dataRealign: any;
    public calendarOptions: any;
    public delete: any;
    public dropdownContentOd: boolean[] = [];
    public metaLanguages: any;
    public loadingTrainers: boolean = false;
    public selected = {};
    public showArchived: boolean = false;
    public loading: boolean = false;
    public hasInitialData: boolean = false;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private courseProvider: CourseProvider,
        private http: HttpClient,
        private storageService: StorageService,
        private headerService: HeaderService,
        private eventsProvider: EventsProvider,
        private toastService: ToastService,
        private redirectService: RedirectService,
        private sessionProvider: SessionProvider,
        private modalService: NgbModal,
        private externalAppsService: ExternalAppsService,
        private datePipe: DatePipe,
        private emitterService: EmitterService
    ) {
        const name = Calendar.name;
    }

    ngOnInit() {
        this.company = this.storageService.get('company');

        this.route.params.subscribe((params) => {
            if (params.slug) {
                this.templates = [this.route.snapshot.data.instances];
                this.breadcrumb = true;
            } else {
                this.templates = this.route.snapshot.data.instances.templates;
                this.pagination = this.route.snapshot.data.instances.pagination;
                this.hasInitialData = this.templates.length ? true : false;
            }
            this.templatesFilter = this.templates;
        });

        this.currentLang = this.storageService.get('me').language.code;
        this.getTrainers()
        this.getMetaLanguages();
        this.initCalendar();
        this.checkButtonTextMonth();
        this.checkButtonTextListWeek();

        if ((this.template = this.storageService.getFlash('template'))) {
            this.step = 'created';
        }

        window.document.getElementsByClassName('header')[0].scroll(0, 0);

        this.aside = {
            onDemand: false,
            onDemandLearners: false
        };

        this.dataRealign = {
            resetEvents: false
        };


        this.setDeleteOptions();
    }

    getTrainers() {
        this.http.get(`${environment.envVar.API_URL}/search/trainers`).subscribe((data: any) => {
            this.trainersList = data.accounts;
            this.paginationTrainers = data.pagination;
        });
    }

    getMetaLanguages() {
        this.http.get(`${environment.envVar.API_URL}/create/templates/meta?folder_id=0&company_id=${this.company.id}`).subscribe((meta: any) => {
            this.metaLanguages = meta.meta.languageIds;
        });
    }

    setDeleteOptions(): void {
        this.delete = {
            notify: false,
            notifySavedContents: false,
            savedContents: false
        };
    }

    openModal(content: any, options: NgbModalOptions = { size: 'lg' }) {
        this.modalService.open(content, options);
        const ps = new PerfectScrollbar('.scrollable');
    }

    initCalendar(): void {
        this.calendarOptions = {
            plugins: [dayGridPlugin, listPlugin],
            initialView: 'dayGridMonth',
            headerToolbar: {
                left: 'dayGridMonth,listWeek',
                center: 'title',
                right: 'prev,next'
            },
            views: {
                dayGridMonth: {

                },
                listWeek: {
                    titleFormat: { day: '2-digit', month: 'long', year: 'numeric' },
                }
            },
            buttonText: {
                month: '',
                listWeek: '',
            },
            eventTimeFormat: {
                hour: '2-digit',
                minute: '2-digit',
                hour12: false
            },
            height: 955,
            firstDay: 1,
            allDayText: '',

            dayMaxEventRows: true,
            events: [],
            eventDisplay: 'block',
            locale: this.currentLang,
            noEventsDidMount: (el: any) => {
                this.checkNoEventsMessage(el);
            },
            eventClick: (info: any) => {
                this.onEventClick(info.event.extendedProps);
            },
            eventMouseEnter: (info: any) => {
                if (info.view.type === 'dayGridMonth') {
                    this.onEventMouseover(info.event, info.el);
                }
            }
        };
    }

    addLanguageFilter(lang: string) {
        const index: number = this.langsFilter.indexOf(lang);
        if (index !== -1) {
            this.langsFilter.splice(index, 1);
        } else {
            this.langsFilter.push(lang);
        }
        this.doFilterTemplate();
    }

    doFilterTemplate() {
        const arrayFilter: string = this.formatArrayLangsFilter();
        this.eventsProvider.getEventsByDate((this.templateFilterChosen && this.templateFilterChosen !== 'reset')
            ? this.templateFilterChosen : '', arrayFilter, this.search.q ? this.search.q : '')
            .subscribe((res: any) => {
                res.forEach((event: any) => {
                    event.start = this.datePipe.transform(new Date(event.start), DATE_DEFAULT_FORMAT);
                    event.end = this.datePipe.transform(new Date(event.end), DATE_DEFAULT_FORMAT);
                });
                this.eventsCalendar = res;
                this.calendarOptions.events = res;
            });
    }

    formatArrayLangsFilter() {
        return this.langsFilter.join(',');
    }

    checkNoEventsMessage(el: any) {
        if (this.currentLang === 'fr') {
            el.text = "Pas d'événements à afficher";
        } else if (this.currentLang === 'nl') {
            el.text = 'Geen evenementen om weer te geven';
        } else {
            el.text = 'No events to display';
        }
    }

    checkButtonTextMonth() {
        if (this.currentLang === 'fr') {
            this.calendarOptions.buttonText.month = 'Mois';
        } else if (this.currentLang === 'nl') {
            this.calendarOptions.buttonText.month = 'Maand';
        } else {
            this.calendarOptions.buttonText.month = 'Month';
        }
    }

    checkButtonTextListWeek() {
        if (this.currentLang === 'fr') {
            this.calendarOptions.buttonText.listWeek = 'Agenda Semaine';
        } else if (this.currentLang === 'nl') {
            this.calendarOptions.buttonText.listWeek = 'Wekelijkse agenda';
        } else {
            this.calendarOptions.buttonText.listWeek = 'Week agenda';
        }
    }

    checkEventLimitText() {
        if (this.currentLang === 'fr') {
            this.calendarOptions.eventLimitText = 'de plus';
        } else if (this.currentLang === 'nl') {
            this.calendarOptions.eventLimitText = 'meer';
        } else {
            this.calendarOptions.eventLimitText = 'more';
        }
    }

    onCalendarInit(intialized: boolean) { }

    getEvents(): void {
        const templateId = (this.breadcrumb) ? this.templates[0].id : '';
        this.eventsProvider.getEventsByDate(templateId, '', '').subscribe((res: any) => {
            res.forEach((event: any) => {
                event.start = this.datePipe.transform(new Date(event.start), DATE_DEFAULT_FORMAT);
                if (event.allDay) {
                    const endDate = new Date(event.end);
                    endDate.setDate(endDate.getDate() + 1);
                    event.end = this.datePipe.transform(endDate, DATE_DEFAULT_FORMAT);
                } else {
                    event.end = this.datePipe.transform(new Date(event.end), DATE_DEFAULT_FORMAT);
                }
            });
            this.eventsCalendar = res;
            this.calendarOptions.events = res;
        });
    }

    changeDisplayMode(type: string): void {
        if (this.displayMode !== 'calendar' && type === 'calendar') {
            this.getEvents();
        }

        this.displayMode = type;
    }

    filterSearch(query?: string): void {
        if (query) {
            this.search.q = query;
        } else {
            this.search.q = '';
        }

        if (this.displayMode === 'list') {
            if (this.trainersSelect.length > 0) {
                this.search.trainers = this.trainersSelect.join(',');
            } else {
                this.search.trainers = '';
            }

            if (this.breadcrumb) {
                this.courseProvider.getSpecificTemplate(this.templates[0].slug, this.transformSearch()).subscribe((data) => {
                    this.templates = [data];
                });
            } else {
                this.http.get(`${environment.envVar.API_URL}/courses/folders/0?includes=instances,camps&q=${this.search.q}&all=1${this.showArchived ? '&archived=1' : ''}`,
                    { params: this.getParams(this.transformSearch()) })
                    .subscribe((data: any) => {
                        this.templates = data.courses;
                        this.pagination = data.pagination;
                    });
            }
        } else if (this.displayMode === 'calendar') {
            const arrayFilter: string = this.formatArrayLangsFilter();
            this.eventsProvider.getEventsByDate((this.templateFilterChosen && this.templateFilterChosen !== 'reset')
                ? this.templateFilterChosen : '', arrayFilter, this.search.q)
                .subscribe((res: any) => {
                    res.forEach((event: any) => {
                        event.start = this.datePipe.transform(new Date(event.start), DATE_DEFAULT_FORMAT);
                        event.end = this.datePipe.transform(new Date(event.end), DATE_DEFAULT_FORMAT);
                    });
                    this.eventsCalendar = res;
                    this.calendarOptions.events = res;
                });
        }
    }

    transformSearch(): any {
        const search = Object.assign({}, this.search);
        search.start_at = (this.search.start_at) ? this.datePipe.transform(new Date(search.start_at), 'YYYY-MM-dd') : '';
        return search;
    }

    loadMore(): void {
        this.processing = true;

        this.http.get(`${environment.envVar.API_URL}/courses/folders/0?includes=instances,camps&instances_per_page=3&per_page=10&all=1&page=${this.pagination.currentPage + 1}${this.showArchived ? '&archived=1' : ''}`,
            { params: this.getParams(this.transformSearch()) })
            .subscribe((data: any) => {
                data.courses.map((template: any) => {
                    this.templates.push(template);
                });
                this.pagination = data.pagination;
                this.processing = false;
            });
    }

    loadMoreTrainers(ev: any) {
        ev.stopPropagation();
        this.loadingTrainers = true;

        this.http.get(`${environment.envVar.API_URL}/search/trainers?page=${this.paginationTrainers.currentPage + 1}`).subscribe((data: any) => {
            this.trainersList.push(...data.accounts);
            this.paginationTrainers = data.pagination;

            this.loadingTrainers = false;
        });
    }

    refresh(): void {
        this.loading = true;
        this.route.params.subscribe((params) => {
            if (params.slug) {
                this.courseProvider
                    .getSpecificTemplate(params.slug, { includes: 'camps', archived: this.showArchived ? 1 : 0 })
                    .subscribe((data: any) => {
                        this.templates = [data];
                        this.breadcrumb = true;
                        this.templatesFilter = this.templates;
                        this.loading = false;
                    });
            } else {
                this.http.get(`${environment.envVar.API_URL}/courses/folders/0?includes=instances,camps&instances_per_page=3&per_page=10&all=1&page=${this.fetchingData.pagination.currentPage}${this.showArchived ? '&archived=1' : ''}`,
                    { params: this.getParams(this.transformSearch()) })
                    .subscribe((data: any) => {
                        this.fetchingData.courses = data.courses;
                        this.fetchingData.pagination = data.pagination;

                        this.templates = this.fetchingData.courses;
                        this.pagination = this.fetchingData.pagination;
                        this.loading = false;
                    });
            }
        });
    }

    private fetchLoad(): void {
        this.http.get(`${environment.envVar.API_URL}/courses/folders/0?includes=instances,camps&instances_per_page=3&per_page=10&all=1&page=${this.fetchingData.pagination.currentPage + 1}${this.showArchived ? '&archived=1' : ''}`,
            { params: this.getParams(this.transformSearch()) })
            .subscribe((data: any) => {
                this.fetchingData.courses = this.fetchingData.courses.concat(data.courses);
                this.fetchingData.pagination = data.pagination;

                if (this.pagination.currentPage > data.pagination.currentPage) {
                    this.fetchLoad();
                } else {
                    this.templates = this.fetchingData.courses;
                    this.pagination = this.fetchingData.pagination;
                }
            });
    }

    getParams(params: any): HttpParams {
        let httpParams = new HttpParams();
        for (const p in params) {
            httpParams = httpParams.append(p, params[p]);
        }

        return httpParams;
    }

    activeDatepicker(closeForce?: boolean): void {
        if (closeForce) {
            this.datePickerActive = false;
            const timeOut = setTimeout(() => {
                this.canActive = true;
                clearTimeout(timeOut);
            }, 100);
        } else {
            if (this.canActive) {
                this.datePickerActive = true;
                this.canActive = false;
            }
        }
    }

    selectedDate(date?: any): void {
        this.search.start_at = (date) ? date.format('DD/MM/YYYY') : '';
        this.filterSearch();
    }

    setLanguage(lang: any): void {
        const index = this.selectedLanguageIds.indexOf(lang.id);

        if (index === -1) {
            this.selectedLanguageIds.push(lang.id);
        } else {
            this.selectedLanguageIds.splice(index, 1);
        }

        this.search.languageIds = this.selectedLanguageIds.join(',');
        this.filterSearch();
    }

    setDiscussion(mode: string): void {
        const index = this.discussions.indexOf(mode);

        if (index === -1) {
            this.discussions.push(mode);
        } else {
            this.discussions.splice(index, 1);
        }

        this.search.discussion_mode = this.discussions.join(',');
        this.filterSearch();
    }

    selectTemplate(template: any): void {
        const templateFound = this.templatesSelectList.indexOf(template.id);

        if (templateFound === -1) {
            this.templatesSelectList.push(template.id);

            template.instances.forEach((instance: any) => {
                if (this.instancesSelectList.indexOf(instance) === -1) {
                    this.instancesSelectList.push(instance);
                }
            });
        } else {
            this.templatesSelectList.splice(templateFound, 1);
            template.instances.forEach((instance: any) => {
                const index = this.instancesSelectList.indexOf(instance);
                if (index !== -1) {
                    this.instancesSelectList.splice(index, 1);
                }
            });
        }

        if (this.templatesSelectList.length > 0) {
            this.step = this.storageService.getAndSet('step', 'sidemenu');
        } else {
            this.step = this.storageService.delete('step');
        }

        setTimeout(() => {
            this.emitterService.deleteDisabledEmitter.emit(this.instancesSelectList.some((instance)=> instance.hasOnboardings));
        }, 200);
    }

    isTemplateSelected(id: number): boolean {
        return this.templatesSelectList.indexOf(id) >= 0;
    }

    selectInstance(instanceEmit: any, template: any): void {
        const instanceFound = this.instancesSelectList.findIndex((instance) => {
            return instance.id === instanceEmit.id;
        });

        if (instanceFound === -1) {
            this.instancesSelectList.push(instanceEmit);
        } else {
            this.instancesSelectList.splice(instanceFound, 1);
        }

        if (this.instancesSelectList.length > 0) {
            this.step = this.storageService.getAndSet('step', 'sidemenu');
        } else {
            this.step = this.storageService.delete('step');
        }

        this.storageService.set('templateSelected', template);

        setTimeout(() => {
            this.emitterService.deleteDisabledEmitter.emit(this.instancesSelectList.some((instance)=> instance.hasOnboardings));
        }, 200);
    }

    isInstanceSelected(id: number): boolean {
        return this.instancesSelectList.findIndex((instance) => {
            return instance.id === id;
        }) !== -1;
    }

    eventDispatcher(event: any, modalDeleteInstance: any, modalRealign: any): void {
        switch (event.type) {
            case 'createInstance':
                this.createInstance(event.object);
                break;
            case 'publishInstance':
                this.publishInstance();
                break;
            case 'unpublishInstance':
                if (this.instancesSelectList.some(instance => ['archived', 'ongoing', 'draft'].includes(instance.state))) {
                    this.openModal(this.modalUnpublish, { size: 'md', centered: true, backdropClass: 'backdrop-custom' })
                } else if (this.instancesSelectList.length >= 1) {
                    this.openModal(this.modalUnpublishConfirm, { size: 'md', centered: true, backdropClass: 'backdrop-custom' })
                } else {
                    this.unpublishInstance();
                }
                break;
            case 'realignInstance':
                this.dataRealign.resetEvents = false;
                this.openModal(modalRealign, { size: 'xl', centered: true, backdropClass: 'backdrop-custom' });
                break;
            case 'cloneInstance':
                this.cloneInstance();
                break;
            case 'deleteInstance':
                this.openDeleteInstance(null, modalDeleteInstance);
                break;
            case 'addInstance':
                this.addInstance(event.object);
                break;
            case 'updateInstance':
                this.updateInstance(event.object);
                break;
            case 'selectCamps':
                this.selectCamps(event.object);
                break;
            case 'settings':
                this.settings(event.object);
                break;
            case 'reload':
                this.toastService.push(this.storageService.getFlash('instances').length > 1 ? 'instances-published' : 'instance-published');
                this.loadTemplates();
                break;
            case 'close':
                this.step = this.storageService.getFlash('step');
                if (event.object?.refresh) {
                    this.loadTemplates();
                }
                break;
            case 'closeSidemenu':
                this.reset();
                break;
            case 'finish':
                this.step = '';
                break;
            default:
                this.step = '';
        }
    }

    realignInstance(): void {
        for (const instance of this.instancesSelectList) {
            const params = {
                reset_events: this.dataRealign.resetEvents,
                reset: [instance.id]
            };

            this.courseProvider.realign(instance.templateId, params).subscribe();
        }
        this.modalService.dismissAll();
        if (this.instancesSelectList.length > 1) {
            this.toastService.push('instances.current.align', 'info');
        } else {
            this.toastService.push('instance.current.align', 'info');
        }
    }

    newInstance(): void {
        if (this.breadcrumb) {
            this.createInstance(this.templates[0]);
        } else {
            this.step = 'selecttemplate';
        }
    }

    createInstance(template: any): void {
        this.step = 'created';
        this.template = template;
        this.selectedInstances = [];
        this.instancesSelectList = [];
    }

    addInstance(instanceEmit: any): void {
        const templateFound = this.templates.find((template: any) => {
            return template.id === instanceEmit.templateId;
        });

        if (templateFound) {
            if (!templateFound.instances) {
                templateFound.instances = [];
            }

            if (this.breadcrumb) {
                templateFound.instances.push(instanceEmit);
            } else {
                this.loadTemplates();
            }

            this.headerService.plus('instances');
            this.toastService.push('instance-created');
        }
    }

    updateInstance(instanceEmit: any): void {
        this.templates.forEach((template: any) => {
            if (template.instances) {
                const instanceIndex = template.instances.findIndex((instance: any) => {
                    return instance.id === instanceEmit.id;
                });

                if (instanceIndex !== -1) {
                    template.instances[instanceIndex] = instanceEmit;
                    this.toastService.push('instance-updated');
                }
            }
        });
    }

    publishInstance(instance?: any, template?: any): void {
        if (!instance) {
            if (this.instancesSelectList.length > 0) {
                this.selectCamps(this.instancesSelectList);
            } else {
                this.toastService.push('no-session-selected', 'warning');
            }
        } else {
            this.storageService.set('templateSelected', template);
            this.instancesSelectList = [];
            this.instancesSelectList.push(instance);
            this.selectCamps(this.instancesSelectList);
            this.changeDropdownInstance(instance.id);
        }
        this.selected = {};
    }

    unpublishInstance(instance?: any, template?: any): void {
        if (!instance) {
            if (this.instancesSelectList.length > 0) {
                this.unpublishFromCamps(this.instancesSelectList);
            } else {
                this.toastService.push('no-instance-selected', 'warning');
            }
        } else {
            this.storageService.set('templateSelected', template);
            this.instancesSelectList = [];
            this.instancesSelectList.push(instance);
            this.unpublishFromCamps(this.instancesSelectList);
            this.changeDropdownInstance(instance.id);
        }
        this.selected = {};
    }

    editInstance(instanceEmit: any): void {
        this.step = 'created';
        this.selectedInstances = [];
        this.selectedInstances.push(instanceEmit);
        this.changeDropdownInstance(instanceEmit.id);
    }

    cloneInstance(instanceEmit?: any): void {
        const instancesId = [];
        if (instanceEmit) {
            instancesId.push(instanceEmit.id);
            this.changeDropdownInstance(instanceEmit.id);
        } else {
            this.instancesSelectList.forEach((instance) => {
                instancesId.push(instance.id);
            });
        }

        this.courseProvider.cloneCourse(instancesId, 'instance').subscribe((data) => {
            data.courses.forEach((instance: any) => {
                this.addInstance(instance);
            });
        });
    }

    deleteInstance(instanceEmit?: any): void {
        let instancesId = '';
        const instancesToDelete = [];

        if (instanceEmit) {
            instancesId = instanceEmit.id;
        } else {
            this.instancesSelectList.forEach((instance) => {
                instancesToDelete.push(instance.id);
            });
            instancesId = instancesToDelete.join(',');
        }

        const params = {
            company_id: this.company.id,
            courses: instancesId,
            notify: this.delete.notify ? 1 : 0,
            notify_saved_contents: this.delete.notifySavedContents ? 1 : 0,
            saved_contents: this.delete.savedContents ? 1 : 0
        };

        this.courseProvider.deleteInstance(params).subscribe(() => {
            this.loadTemplates();

            if (instanceEmit) {
                this.headerService.minus('instances');
            } else {
                this.headerService.minus('instances', instancesToDelete.length);
            }
        });
    }

    archiveInstance(instance?: any): void {
        const params = {
            company_id: this.company.id,
        };

        this.sessionProvider.archiveSession(instance.id, params).subscribe(() => {
            this.loadTemplates();
            this.headerService.minus('instances');
            this.toastService.push('instance-archived');
        });
    }

    restoreInstance(instance?: any): void {
        const params = {
            company_id: this.company.id,
        };

        this.sessionProvider.restoreSession(instance.id, params).subscribe(() => {
            this.loadTemplates();
            this.headerService.plus('instances');
            this.toastService.push('instance-restored');
        });
    }

    unpublishFromAllCamps() {
        this.loading = true;
        this.courseProvider.publishInstance(this.instancesSelectList.map(i => i.id), [], {}).subscribe((data) => {
            this.loadTemplates();
        });
    }

    unpublishFromCamps(instances: any): void {
        this.step = 'unpublish';
        this.selectedInstances = [];
        instances.forEach((instance: any) => {
            this.selectedInstances.push(instance);
        });
    }

    selectCamps(instances: any): void {
        this.step = 'selectcamp';
        this.selectedInstances = [];
        instances.forEach((instance: any) => {
            this.selectedInstances.push(instance);
        });
    }

    settings(camps: any[]): void {
        this.step = 'settings';
        this.campsSelected = camps;
    }

    loadTemplates(): void {
        this.instancesSelectList = [];
        this.templatesSelectList = [];

        if (this.breadcrumb) {
            this.courseProvider.getSpecificTemplate(this.templates[0].slug, { includes: 'camps', archived: this.showArchived ? 1 : 0 }).subscribe((data) => {
                this.templates = [data];
            });
        } else {
            this.fetchingData = {
                courses: [],
                pagination: {
                    currentPage: 0
                }
            };

            this.fetchLoad();
        }
    }

    changeDropdownInstance(id: number): void {
        const dropdown = !this.dropdownOpen[id];
        this.dropdownOpen = [];
        this.dropdownOpen[id] = dropdown;
    }

    changeTemplateOpen(index: number): void {
        this.templateOpen[index] = !this.templateOpen[index];
    }

    dropdownTrainers(): void {
        this.dropdownTrainersActive = !this.dropdownTrainersActive;
    }

    dropdownLanguages(): void {
        this.dropdownLanguagesActive = !this.dropdownLanguagesActive;
    }

    addTrainers(id: number): void {
        const index = this.trainersSelect.indexOf(id);

        if (index === -1) {
            this.trainersSelect.push(id);
        } else {
            this.trainersSelect.splice(index, 1);
        }
        this.filterSearch();
    }

    openDeleteInstance(instance?: any, content?: any): void {
        this.setDeleteOptions();
        this.openModal(content, { size: 'xl', centered: true, backdropClass: 'backdrop-custom' });
        if (instance) {
            this.currentInstance = instance;
            this.changeDropdownInstance(instance.id);
        } else {
            if (this.instancesSelectList.length > 1) {
                this.currentInstance = null;
            } else {
                this.currentInstance = this.instancesSelectList[0];
            }
        }
    }

    openArchiveInstance(instance, content): void {
        this.openModal(content, { size: 'xl', centered: true, backdropClass: 'backdrop-custom' });
        if (instance) {
            this.currentInstance = instance;
            this.changeDropdownInstance(instance.id);
        } else {
            if (this.instancesSelectList.length > 1) {
                this.currentInstance = null;
            } else {
                this.currentInstance = this.instancesSelectList[0];
            }
        }
    }

    onEventClick(event: any): void {
        if (this.breadcrumb) {
            this.displayMode = 'list';
            const timeOut = setTimeout(() => {
                const instanceFound = this.templates[0].instances.find((instance: any) => {
                    return instance.id === event.instanceId;
                });

                if (instanceFound) {
                    this.instancesSelectList.push(instanceFound);
                    this.step = 'sidemenu';
                }
                clearTimeout(timeOut);
            }, 100);
        } else {
            this.router.navigate(['company', this.storageService.get('company').slug, 'instance', event.templateSlug]);
        }
    }

    onEventMouseover(event: any, element: any): void {
        const eventDate = this.getEventCalendar(event.extendedProps.eventId);
        let content = (event.extendedProps.eventTitle)
            ? event.title + ' - ' + event.extendedProps.eventTitle + '<br>'
            : event.title + '<br>';

        content += `${this.datePipe.transform(new Date(eventDate.start), 'HH:mm')}-${this.datePipe.transform(new Date(eventDate.end), 'HH:mm')}` + '<br>' + event.extendedProps.address;

        tippy(element, {
            content: content,
            delay: 50,
            arrow: true,
            animation: 'fade'
        });
    }

    reset(): void {
        this.step = this.storageService.delete('step');
        this.templatesSelectList = [];
        this.instancesSelectList = [];
    }

    getEventCalendar(id: number): any {
        const index = this.eventsCalendar.findIndex((event) => {
            return event.eventId === id;
        });

        return this.eventsCalendar[index];
    }

    getDay(date: Date): string {
        return this.datePipe.transform(new Date(date), 'dd');
    }

    getMonth(date: Date): string {
        return this.datePipe.transform(new Date(date), 'MMMM');
    }

    navigateTo(route: string, params: any): void {
        this.router.navigate(['company', this.storageService.get('company').slug, route, params], { queryParams: { archived: this.showArchived ? 1 : 0 } });
    }

    preview(id: any): void {
        this.redirectService.navigate('player', ['course', id], { preview: true });
    }

    displayedMenu(index: number): void {
        this.displayMenu[index] = !this.displayMenu[index];
    }

    enableOnDemand(template: any, disable?: boolean, type: string = 'content'): void {
        if ((!template.onDemand && disable) || !disable) {
            if (type === 'learners') {
                this.aside.onDemandLearners = true;
            } else if (type === 'content') {
                this.aside.onDemand = true;
            }
            this.selectedTemplate = template;
        }
    }

    closeAside(type: string): void {
        this.aside[type] = !this.aside[type];
        this.selectedTemplate = null;
        for (let i = 0; i < this.displayMenu.length; i++) {
            this.displayMenu[i] = false;
        }
    }

    publishOnDemand(camps: any): void {
        this.selectedTemplate.onDemand = true;
        this.selectedTemplate.campsCount = camps.length;
        this.selectedTemplate.campsNames = camps.map(c => c.name).join(', ');
        this.closeAside('onDemand');
        this.toastService.push('enable-onDemand');
    }

    openContentOd(index: number): void {
        this.dropdownContentOd[index] = !this.dropdownContentOd[index];
    }

    disableContentOd(): void {
        const params = {
            on_demand: false
        };
        this.courseProvider.updateOnDemand(this.currentTemplate.id, params).subscribe(() => {
            this.currentTemplate.onDemand = false;
            this.toastService.push('disable-onDemand');
            this.modalService.dismissAll();
        });
    }

    openModalDisableContentOd(template: any, content: any): void {
        this.currentTemplate = template;
        this.openModal(content, { size: 'xl', centered: true, backdropClass: 'backdrop-custom' });
    }

    containsUsers(): boolean {
        if (this.currentInstance && this.currentInstance.usersCount > 0) {
            return true;
        } else if (!this.currentInstance) {
            return this.instancesSelectList.filter((instance) => {
                return instance.usersCount > 0;
            }).length > 0;
        }
        return false
    }

    @HostListener('document:click', ['$event'])
    clickout(event: any) {
        if (!event.target.closest('.show-more') && !event.target.closest('.session__item') && !event.target.closest('.sidebar') && this.step === 'sidemenu') {
            this.reset();
        }
    }
}
