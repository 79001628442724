import { Component, Input, OnInit } from '@angular/core';
import { CheatSheet, Section, SectionContent } from '@stuplay';
import { ErrorCatcherService } from '../../../../../utils/services/error-catcher.service';

@Component({
    selector: 'app-cheatsheet',
    templateUrl: './cheatsheet.component.html',
    styleUrls: ['./cheatsheet.component.less']
})
export class CheatSheetComponent implements OnInit {
    @Input() cheat: CheatSheet;
    @Input() section: Section;
    @Input() sectionContent: SectionContent;
    public content: any;

    constructor(private errorCatcher: ErrorCatcherService) {}

    ngOnInit() {
        this.content = this.cheat.content;
    }

    update(): void {
        if (this.cheat) {
            this.cheat.content = this.content;
            this.cheat.update().subscribe(() => {
                this.errorCatcher.setErrors(this.sectionContent);
            });
        }
    }
}
