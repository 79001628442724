import { Component, Input, Output, EventEmitter } from '@angular/core';
import { TranslationService } from '../../services/translation.service';
import { environment } from '../../../../environments/environment';

@Component({
    selector: 'msc-froala',
    templateUrl: './froala.component.html',
    styleUrls: ['./froala.component.scss']
})

export class FroalaComponent {
    @Input() autofocus: boolean = false;
    @Input() content: string | any;
    @Input() editable: boolean = true;
    @Input() height: number = 400;
    @Input() plugins: any;
    @Input() buttons: any;
    @Input() isCourse: boolean;
    @Input() maxLength: number = undefined;
    @Output() contentChange: EventEmitter<string> = new EventEmitter;
    @Output() onUpdate: EventEmitter<any> = new EventEmitter;

    public options: any;

    private readonly froalaKey: string = 're1H1qC4C2C6E6C5C4hBi1a2d2Za1IXBh1f1THTBPLIIWBORpF1F1E1G4F1C11A8C2C5F5==';

    constructor(
        private translationService: TranslationService,
    ) { }

    ngOnInit() {
        const onChange = function (content: any) {
            this.onChange(content);
        }.bind(this);

        const isEditable = function () {
            return this.editable;
        }.bind(this);

        this.options = {
            key: this.froalaKey,
            theme: 'custom',
            attribution: false,
            pluginsEnabled: this.getPlugins(),
            toolbarSticky: false,
            fontFamily: this.isCourse ? 'Assistant,arial,sans-serif' : {},
            toolbarVisibleWithoutSelection: true,
            charCounterCount: (this.editable && this.maxLength) ? true : false,
            toolbarButtons: this.getToolbarButtons(),
            videoInsertButtons: ['videoBack', '|', 'videoByURL', 'videoEmbed', 'videoUpload'],
            videoAllowedTypes: ['mp4', 'avi', 'ogg', 'mov', 'flv', 'webm', '3gp', 'wmv'],
            videoMaxSize: 1024 * 1024 * 1024,
            videoUploadURL: `${environment.envVar.API_URL}/media?froala=1`,
            imageAllowedTypes: ['jpeg', 'jpg', 'png'],
            imageMaxSize: 100 * 1024 * 1024,
            imageUploadURL: `${environment.envVar.API_URL}/media?froala=1`,
            requestHeaders: {
                'Authorization': 'Bearer ' + sessionStorage.getItem(environment.tokenSessionName)
            },
            linkAlwaysBlank: true,
            heightMin: this.height,
            language: this.translationService.getCurrentLang(),
            autofocus: this.autofocus,
            pasteAllowedStyleProps: ['font-family', 'font-size', 'color'],
            pasteDeniedAttrs: [],
            placeholderText: this.isCourse ? null : undefined,
            charCounterMax: this.maxLength,
            listAdvancedTypes: false,
            events: {
                'initialized': function () {
                    if (!isEditable()) {
                        this.edit.off();
                    }
                },
                'contentChanged': function () {
                    onChange(this.html.get(true));
                },
                'video.inserted': function ($video: any) {
                    const source = $video.contents().get(0).src;
                    $video.html('<iframe src="' + source + '" style="border: none; width: 100%"></iframe>');
                    onChange(this.html.get());
                },
                'html.set': function () {
                    this.events.trigger('charCounter.update');
                }
            }
        };
    }

    private getToolbarButtons() {
        if (this.buttons) {
            if (this.buttons === 'xmini') {
                return {
                    moreText: {
                        buttons: ["bold", "italic", "formatUL"]
                    },
                    moreRich: {
                        buttons: ['insertLink', 'insertImage', 'insertVideo', 'embedly'],
                        buttonsVisible: 4
                    },
                    moreMisc: {
                        buttons: ["undo", "redo"],
                        align: "right"
                    }
                }
            }
        }
        return [
            "bold", "italic", "underline", "strikeThrough", "paragraphStyle", "subscript", "superscript", "|",
            "fontSize", "textColor", "|",
            "paragraphFormat", "align", "formatOL", "formatUL", "outdent", "indent", "quote", "|",
            "insertLink", "insertImage", "insertTable", "insertVideo", "|",
            "insertHR", "selectAll", "clearFormatting", "|",
            "insertFile", "fullscreen", "html", "save", "undo", "redo"
        ]
    }

    private getPlugins(): any {
        if (this.plugins) {
            if (typeof this.plugins === 'object') {
                return this.plugins;
            }

            if (this.plugins === 'normal') {
                return [
                    'colors', 'align', 'fontSize',
                    'fontFamily', 'link', 'paragraphFormat',
                    'table', 'quote', 'lists',
                    'image', 'video', 'codeView'
                ];
            }

            if (this.plugins === 'mini') {
                return [
                    'colors', 'fontSize', 'fontFamily',
                    'link', 'paragraphFormat', 'lists'
                ]
            }

            if (this.plugins === 'xmini') {
                return [
                    'link', 'paragraphFormat', 'lists', 'charCounter', 'embedly', 'image', 'video', 'draggable'
                ];
            }
        }

        return [
            'align', 'charCounter', 'codeBeautifier', 'codeView',
            'colors', 'draggable', 'embedly', 'entities',
            'fontFamily', 'fontSize', 'image',
            'lineBreaker', 'link', 'lists', 'paragraphFormat',
            'quickInsert', 'quote', 'table', 'url',
            'video', 'wordPaste'
        ];
    }

    onChange(content: string): void {
        this.contentChange.emit(content);
        this.onUpdate.emit();
    }
}
