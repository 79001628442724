<div class="input-duration">
    <input class="input-duration__input" type="text" [(ngModel)]="duration" (blur)="updateDuration()">

    <div class="input-duration__unit">
        <div class="unit" (click)="openUnitsList()">
            {{ unit }}
            <span class="unit__trigger" *ngIf="!disable">
				<span class="sr-only">Change unit</span>
			</span>
        </div>

        <div class="units" [ngClass]="{'units_open': open}">
			<span class="units__item" [ngClass]="{'units__item_active': isUnit(unit)}" *ngFor="let unit of filterUnits()" (click)="setUnit(unit)">
				{{ unit }}
			</span>
        </div>
    </div>
</div>

