export class Account {
    id: number;
    name: string;
    email: string;
    headline: string;
    lastname: string;
    firstname: string;
    picture: string;

    constructor(account: any) {
        this.extends(account);
    }

    extends(account: any): Account {
        Object.assign(this, account);

        return this;
    }
}
