import { HttpClient } from '@angular/common/http';
import { Observable } from "rxjs";
import { Course } from "../course";
import { Camp } from "../camp";
import { map } from 'rxjs/operators';
import { CourseCampSession } from '../course-camp-session';
import { Event } from '../course/section/section-content/event';
import { cleanObject } from "../helpers";
import { environment } from 'src/environments/environment';

export class CourseCamp {
    id: number;
    slug: string;
    createdAt: number;
    origin: string;
    availability: string;
    belongsToTraject: boolean;
    isOrdered: boolean;
    isLocked: boolean;
    isTrajectContent: boolean;
    isFavorite: boolean;
    surveyId: any;
    camp: Camp;
    course: Course;
    courseCampSessions?: CourseCampSession[];

    constructor(courseCamp: any, private http: HttpClient) {
        this.extends(courseCamp);
    }

    extends(courseCamp: any): CourseCamp {
        Object.assign(this, courseCamp);
        this.course = new Course(this.course, this.http);
        this.camp = new Camp(this.camp, this.http);
        if (this.courseCampSessions) {
            this.courseCampSessions = this.courseCampSessions.map((courseCampSession) => new CourseCampSession(courseCampSession, this.http));
        }

        return this;
    }

    update(): Observable<CourseCamp> {
        return this.http.put(`${environment.envVar.API_URL}/course-camp/${this.id}`, cleanObject(this)).pipe(map(res => this.extends(res)));
    }

    delete(): Observable<any> {
        return this.http.delete(`${environment.envVar.API_URL}/course-camp/${this.id}`);
    }

    getEvents(): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/events?course_camp_id=${this.id}&includes=event_sessions`)
            .pipe(map((data: any) => {
                return data.data.map((event) => {
                    return new Event(event, this.http);
                })
            }));
    }
}
