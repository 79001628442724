import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Media } from '../../../media';
import { cleanObject } from "../../../helpers";
import { environment } from 'src/environments/environment';

export class SelfAssessmentAnswer {
    id: number;
    accountId: number;
    sectionContentId: number;
    content?: string;
    info: string;
    isCorrected: boolean;
    ratio: number;
    feedback: string;
    createdAt: number;
    selfassessmentId: number;
    updatedAt: number;
    deletedAt: number;
    medias?: Media[];
    type: string;

    constructor(projectAnswer: any, protected http: HttpClient) {
        this.extends(projectAnswer);
    }

    extends(selfAssessmentAnswer: any): SelfAssessmentAnswer {
        Object.assign(this, selfAssessmentAnswer);
        if (this.medias && this.medias.length > 0) {
            this.medias = this.medias.map((selfAssessmentAnswer) => new Media(selfAssessmentAnswer, this.http));
        }
        return this;
    }

    update(): Observable<SelfAssessmentAnswer> {
        let selfAssessment: any = Object.assign({}, this);
        selfAssessment.medias = selfAssessment.medias.map((media) => {
            return media.id;
        });
        return this.http.put(`${environment.envVar.API_URL}/selfassessments/${this.selfassessmentId}/answers/${this.id}`, cleanObject(selfAssessment))
            .pipe(map(res => this.extends(res)));
    }
}