import { TrueFalseQuestion } from './true-false/true-false-question';
import { MultipleQuestion } from './multiple/multiple-question';
import { OpenWriteQuestion } from './open-write/open-write-question';
import { OrderedQuestion } from './ordered/ordered-question';
import { ProcedureQuestion } from './procedure/procedure-question';
import { FillInQuestion } from './fill-in/fill-in-question';
import { LinkerQuestion } from './linker/linker-question';
import { CategoryQuestion } from './category/category-question';
import { OpenAudioQuestion } from './open-audio/open-audio-question';
import { UniqueQuestion } from "./unique/unique-question";
import { HttpClient } from '@angular/common/http';

export function factoryQuestion(type: string, question: any, http: HttpClient) {
    switch (type) {
        case 'true-false' : {
            return new TrueFalseQuestion(question, http);
        }
        case 'multiple' : {
            return new MultipleQuestion(question, http);
        }
        case 'open-write' : {
            return new OpenWriteQuestion(question, http);
        }
        case 'ordered' : {
            return new OrderedQuestion(question, http);
        }
        case 'procedure' : {
            return new ProcedureQuestion(question, http);
        }
        case 'fill-in' : {
            return new FillInQuestion(question, http);
        }
        case 'linker' : {
            return new LinkerQuestion(question, http);
        }
        case 'category' : {
            return new CategoryQuestion(question, http);
        }
        case 'open-audio' : {
            return new OpenAudioQuestion(question, http);
        }
        case 'ucq': {
            return new UniqueQuestion(question, http);
        }
    }
}
