import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Media } from "./media";
import { environment } from 'src/environments/environment';

@Injectable()
export class MediaProvider {

    constructor(public http: HttpClient) {}

    getMedias(type: string): Observable<any> {
        let data = {
            medias: [],
            pagination: {}
        };
        return this.http.get(`${environment.envVar.API_URL}/folder/0?type=${type}`)
            .pipe(map((res: any) => {
                res.folder.medias.map((media) => {
                    data.medias.push(new Media(media, this.http))
                });
                data.pagination = res.pagination;
                return data;
            }));
    }

    getStatus(id: number): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/media/${id}/status`)
            .pipe(map((data) => new Media(data, this.http)));

    }

    getMedia(id: number): Observable<Media> {
        return this.http.get(`${environment.envVar.API_URL}/media/${id}/show`)
            .pipe(map((data: any) => data.media));
    }

    loadMore(type: string, currentPage: number) {
        let data = {
            medias: [],
            pagination: {}
        };
        return this.http.get(`${environment.envVar.API_URL}/folder/0?type=${type}&page=${currentPage}`)
            .pipe(map((res: any) => {
                res.folder.medias.map((media) => {
                    data.medias.push(new Media(media, this.http));
                });
                data.pagination = res.pagination;
                return data;
            }));
    }
}
