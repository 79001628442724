import { Pipe } from '@angular/core';
import { PipeTransform } from '@angular/core';
import { TranslationService } from '../services/translation.service';

@Pipe({
    name: 'translate',
    pure: false
})
export class TranslatePipe implements PipeTransform {

    constructor(private translateService: TranslationService) { }

    transform(key: string, params?: any): string {
        return this.translateService.getTranslate(key, params) || key;
    }
}
