<dl>
    <ng-container *ngFor="let datum of data; let i=index; trackBy: onTrackByIndex">
        <dd>
            <input type="checkbox" id="leaf-{{datum.id}}-{{i}}">
            <div>
                <ng-container *ngTemplateOutlet="leafTemplate || template || defaultLeafTemplate; context: { datum: datum }"></ng-container>
                <ng-container *ngIf="datum?.children && datum.children.length">
                    <label for="leaf-{{datum.id}}-{{i}}">▼</label>
                </ng-container>
            </div>
            <ng-container *ngIf="datum?.children && datum.children.length">
                <msc-common2-tree [data]="datum.children" [template]="leafTemplate || template || defaultLeafTemplate"></msc-common2-tree>
            </ng-container>
        </dd>
    </ng-container>
</dl>

<ng-template #defaultLeafTemplate let-datum="datum">
    <span>{{ datum?.label }}</span>
</ng-template>
