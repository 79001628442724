export class Condition {
    id: number;
    type: string;
    contentId: number;
    value: string;

    constructor(condition: any) {
        this.extends(condition);
    }

    extends(condition: any): Condition {
        Object.assign(this, condition);
        return this;
    }
}