import { HttpClient } from '@angular/common/http';
import { Observable } from "rxjs";
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Account } from '../account/account';

export class Media {
  id: number;
  hash: string;
  uid: string;
  url: string; // Pointer to display
  originalUrl?: string;
  pictureUrl?: string;
  title: string;
  type: string; // video, image, embed, sound, pdf, doc
  upload?: Upload;
  folderId: number; // save folder
  storage: string; // extern, vimeo, azure
  isDownloadable: boolean;
  size: number;
  param: MediaParameter;
  status?: number;
  createdAt:any;
  source:string;
  isArchived: boolean;

  constructor(media: any, private http: HttpClient) {
    this.extends(media);
  }

  extends(media: any): Media {
    Object.assign(this, media);
    return this;
  }

  update(): Observable<Media> {
    return this.http.put(`${environment.envVar.API_URL}/media/${this.id}`, {name: this.title, isArchived: this.isArchived})
    .pipe(map(res => this.extends(res)));
  }

}

export interface Upload {
  type: string;
  size?: number;
  originBaseName: string;
  originExtension: string;
  storageBaseName: string;
  storageExtension: string;
  state: string;
  createdAt: number;
  updatedAt: number;
  deletedAt: number;
  account: Account;
  status?: number | 'failed' | 'pending' | 'done';
}

export interface MediaParameter {
  video: MediaVideoParameter;
}

export interface MediaVideoParameter {
  type: string
  version: string;
  providerName: string;
  providerUrl: string;
  title: string;
  authorName: string;
  authorUrl: string;
  isPlus: boolean;
  html: string;
  width: number;
  height: number;
  duration: number;
  description: string;
  thumbnailUrl: string;
  thumbnailWidth: number;
  thumbnailHeight: number;
  thumbnailUrlWithPlayButton: string;
  uploadDate: Date;
  videoId: number;
  uri: string;
}
