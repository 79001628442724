import { Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';

@Component({
    selector: 'image-bank-list',
    templateUrl: './image-bank-list.component.html'
})
export class ImageBankListComponent implements OnChanges {
    @Input() images: any[];
    @Output() uploadMedia = new EventEmitter();
    public unsplash;
    public imagesTab: any[] = [];
    public page: number;
    public value: string = 'marketing';

    ngOnChanges() {
        const myImages = Object.assign([], this.images);
        for (let i = 0; i < 3; i++) {
            const quarter = Math.ceil(myImages.length / (3 - i));
            this.imagesTab[i] = [];
            this.imagesTab[i] = myImages.splice(0, quarter);
        }
    }

    createMedia(url: string): void {
        this.uploadMedia.emit(url);
    }
}
