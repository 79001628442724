import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class SectionContentProvider {

    constructor(public http: HttpClient) { }

    deleteQuestion(questionId: number, params?): Observable<any> {
        return this.http.delete(`${environment.envVar.API_URL}/studio/question/${questionId}`, { params });
    }

    createSectionContent(id: number, sectionId: number, params: any): Observable<any> {
        return this.http.post(`${environment.envVar.API_URL}/create/courses/${id}/sections/${sectionId}/contents`, params);
    }

    updatePosition(id: number, sectionId: number, params: any): Observable<any> {
        return this.http.put(`${environment.envVar.API_URL}/create/courses/${id}/sections/${sectionId}/contents/position`, params);
    }
}
