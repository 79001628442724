import { HttpClient } from "@angular/common/http";
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from "src/environments/environment";
import { cleanObject } from "../helpers";

export class Note {
	id: number;
	name: string;
	content: string;

	constructor(note:any, private http: HttpClient) {
		this.extends(note);
	}

	extends(note:any) {
		Object.assign(this, note);
		return this;
	}

	update(): Observable<Note> {
		return this.http.put(`${environment.envVar.API_URL}/note/${this.id}`, cleanObject(this))
		.pipe(map((note) => this.extends(note)));
	}

	delete(id: number): Observable<void> {
		return this.http.delete(`${environment.envVar.API_URL}/note/${this.id}`)
		.pipe(map((res) => null));
	}
}