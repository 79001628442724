<aside class="sidebar sidebar--filter">
	<div class="sidebar-library__element">
		<div class="sidebar-library__element-title">{{'words.add' | translate}}</div>
		<div class="sidebar-library__element-filters">
			<div class="filter" [ngClass]="{'active': isSelected('import')}" (click)="setTab('import')">
				<img src="https://static.myskillcamp.com/images/icon/icon-camp/icon_library_upload.svg">{{'studio.media.upload.title' | translate}}
			</div>

			<div class="filter" [ngClass]="{'active': isSelected('integration')}" (click)="setTab('integration')">
				<img src="https://static.myskillcamp.com/images/icon/icon-camp/icon_library_embed.svg">{{'studio.media.embed' | translate}}
			</div>

            <div class="filter" [ngClass]="{'active': isSelected('iframe')}" (click)="setTab('iframe')">
                <img src="https://static.myskillcamp.com/images/icon/icon-camp/icon_code.svg"> {{ 'studio.media.iframe' | translate }}
            </div>

			<div class="filter" [ngClass]="{'active': isSelected('databank')}" (click)="setTab('databank')">
				<img src="https://static.myskillcamp.com/images/icon/icon-camp/icon_library_photostock.svg">{{'studio.library.image-bank' | translate}}
			</div>
		</div>
	</div>

	<div class="sidebar-library__element">
		<div class="sidebar-library__element-title">{{'studio.re-use.media-context' | translate}}</div>
		<div class="sidebar-library__element-filters">
			<div class="filter" [ngClass]="{'active': isSelected('video')}" (click)="setTab('video'); filterMedias('video')">
				<img src="https://static.myskillcamp.com/images/icon/icon-camp/icon_library_video.svg">{{'studio.re-use.video-context' | translate}}
			</div>

			<div class="filter" [ngClass]="{'active': isSelected('image')}" (click)="setTab('image'); filterMedias('image')">
				<img src="https://static.myskillcamp.com/images/icon/icon-camp/icon_library_bibliotheque.svg">{{'studio.media.images' | translate}}
			</div>

            <div class="filter" [ngClass]="{'active': isSelected('sound')}" (click)="setTab('sound'); filterMedias('sound')">
                <img src="https://static.myskillcamp.com/images/icon/icon-camp/icon_library_sound.svg">{{'studio.media.sounds' | translate}}
            </div>

			<div class="filter" [ngClass]="{'active': isSelected('document')}" (click)="setTab('document'); filterMedias('document')">
				<img src="https://static.myskillcamp.com/images/icon/icon-camp/icon_library_fichiers.svg">{{'studio.media.docs' | translate}}
			</div>

			<!--<div class="filter" [ngClass]="{'active': isSelected('sound')}" (click)="setTab('sound'); filterMedias('sound')">
				<img src="https://static.myskillcamp.com/images/icon/icon-camp/icon_library_voice.svg">{{'studio.media.sounds' | translate}}
			</div>-->

			<div class="filter" [ngClass]="{'active': isSelected('embed')}" (click)="setTab('embed'); filterMedias('embed')">
				<img src="https://static.myskillcamp.com/images/icon/icon-camp/icon_library_interactivecontent.svg">{{'studio.media.embed' | translate}}
			</div>

            <div class="filter" [ngClass]="{'active': isSelected('toolbox')}" (click)="setTab('toolbox')">
                <img src="https://static.myskillcamp.com/images/guidance/toolbox.svg">{{'studio.media.toolbox' | translate}}
            </div>
		</div>
	</div>
</aside>
