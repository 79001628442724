import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TimelineProvider } from '@stuplay';
import { StorageService } from '../../../utils/services/storage.service';

@Component({
    selector: 'app-timeline',
    templateUrl: './timeline.component.html',
})
export class TimelineComponent implements OnInit {
    @Input() context: string;
    @Input() contextId: number;
    @Output() updateMedia = new EventEmitter();
    private company: any;
    public timelineId: number;
    public items: any[] = [];
    public pagination: any;

    constructor(
        private timelineProvider: TimelineProvider,
        private storageService: StorageService,
    ) { }

    ngOnInit() {
        this.company = this.storageService.get('company');
        const params = {
            context: this.context,
            context_id: this.contextId
        };

        this.timelineProvider.getTimelineId(params).subscribe((data) => {
            this.timelineId = data.id;

            this.timelineProvider.getTimeline(this.timelineId).subscribe((timeline) => {
                this.items = timeline.items;
                this.pagination = timeline.pagination;
            });
        });
    }

    addPost(post: any): void {
        const params = {
            timeline_id: this.timelineId,
            content: {
                message: post.message,
                media_id: post.media ? post.media.id : null
            },
            type: 'mixed',
            company_id: this.company.id
        };

        this.timelineProvider.send(params).subscribe((data) => {
            this.items.push(data);
        });
    }

    nextPosts(): void {
        const params = {
            page: this.pagination.currentPage + 1
        };

        this.timelineProvider.getTimeline(this.timelineId, params).subscribe((timeline) => {
            this.items = this.items.concat(timeline.items);
            this.pagination = timeline.pagination;
        });
    }

    deletePost(post: any): void {
        const index = this.items.findIndex((item) => {
            return item.id === post;
        });

        if (index > -1) {
            this.items.splice(index, 1);
        }
    }
}
