<div class="aside-help">
    <div [ngClass]="{'active': active.aside}"></div>

    <aside class="help-sidebar sidebar sidebar--modal" [ngClass]="{'active': active.aside}" (appSidebar)="closeSidebar()">
        <div class="sidebar__container">
            <div class="sidebar__head">
                <div class="sidebar__description">
                    <div class="sidebar__title">
                        <img src="https://static.myskillcamp.com/images/guidance/question-mark-circle.svg"/>
                        {{ 'studio.aside-help.helper' | translate }}
                    </div>
                </div>

                <a class="sidebar__close" (click)="closeSidebar()">
                    <i class="icon icon--close"></i>
                </a>
            </div>

            <div class="help-content">
                <ul *ngIf="guides">
                    <li class="help-block" *ngFor="let guide of guides | keyValue; let i = index">
                        <div *ngIf="isAll">
                            <div *ngFor="let key of guide">
                                <div class="block-title" (click)="openDropdown(i)" [ngClass]="{'active': active.dropdown[i]}">
                                    <div class="block-title__title">{{ key.title[currentLang] ? key.title[currentLang] : key.title['en']  }}</div>
                                    <img class="block-title__arrow-down" src="https://static.myskillcamp.com/images/guidance/arrow-ios-up.svg">
                                </div>

                                <div class="block-content block-dropdown" [ngClass]="{'active': active.dropdown[i]}" [(autoClose)]="displayed">
                                    <p class="block-description" *ngIf="key.description">{{ key.description[currentLang] ? key.description[currentLang] : key.description['en'] }}</p>

                                    <div class="block-tag" *ngFor="let type of key.type">
                                        <div class="block-tag__group" *ngIf="type.type === 'faq'">
                                            <a [attr.href]="type[currentLang] ? type[currentLang].link : type['en'].link"
                                               target="_blank"> {{ type[currentLang] ? type[currentLang].title : type['en'].title }} / FAQ
                                                <img src="https://static.myskillcamp.com/images/guidance/external-link.svg">
                                            </a>
                                        </div>

                                        <div class="block-tag__group" *ngIf="type.type === 'genially'">
                                            <a [attr.href]="type[currentLang] ? type[currentLang].link : type['en'].link"
                                               target="_blank">  {{ type[currentLang] ? type[currentLang].title : type['en'].title }} /
                                                Genially
                                                <img src="https://static.myskillcamp.com/images/guidance/external-link.svg">
                                            </a>
                                        </div>

<!--                                        <div class="block-tag__group" *ngIf="type.type === 'appcues'">-->
<!--                                            <a (click)="startGuide(type)"> {{ type[currentLang].name }} </a>-->
<!--                                            <img src="https://static.myskillcamp.com/images/guidance/book-open.svg">-->
<!--                                        </div>-->

                                        <div class="block-tag__group" *ngIf="type.type === 'blog'">
                                            <a [attr.href]="type[currentLang] ? type[currentLang].link : type['en'].link"
                                               target="_blank"> {{ type[currentLang] ? type[currentLang].title : type['en'].title }} / Blog
                                                <img src="https://static.myskillcamp.com/images/guidance/external-link.svg">
                                            </a>
                                        </div>

                                        <div class="block-tag__group" *ngIf="type.type === 'lxp'">
                                            <a [attr.href]="type[currentLang] ? type[currentLang].link : type['en'].link"
                                               target="_blank"> {{ type[currentLang] ? type[currentLang].title : type['en'].title }} / Lxp
                                                <img src="https://static.myskillcamp.com/images/guidance/external-link.svg">
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="!isAll">
                            <div class="block-title">
                                <div class="block-title__title">{{ guide.title[currentLang] ? guide.title[currentLang] : guide.title['en']}}</div>
                                <div class="block-title__arrow-down"></div>
                            </div>

                            <div class="block-content" [(autoClose)]="displayed">
                                <p class="block-description" *ngIf="guide.description">{{ guide.description[currentLang] ? guide.description[currentLang] : guide.description['en']}}</p>

                                <div class="block-tag" *ngFor="let type of guide.type">
                                    <div class="block-tag__group" *ngIf="type.type === 'faq'">
                                        <a [attr.href]="type[currentLang] ? type[currentLang].link : type['en'].link"
                                           target="_blank"> {{ type[currentLang] ? type[currentLang].title : type['en'].title }} / FAQ
                                            <img src="https://static.myskillcamp.com/images/guidance/external-link.svg">
                                        </a>
                                    </div>

                                    <div class="block-tag__group" *ngIf="type.type === 'genially'">
                                        <a [attr.href]="type[currentLang] ? type[currentLang].link : type['en'].link"
                                           target="_blank">  {{ type[currentLang] ? type[currentLang].title : type['en'].title }} /
                                            Genially
                                            <img src="https://static.myskillcamp.com/images/guidance/external-link.svg">
                                        </a>
                                    </div>

<!--                                    <div class="block-tag__group" *ngIf="type.type === 'appcues'">-->
<!--                                        <a (click)="startGuide(type)"> {{ type[currentLang].name }} </a>-->
<!--                                        <img src="https://static.myskillcamp.com/images/guidance/book-open.svg">-->
<!--                                    </div>-->

                                    <div class="block-tag__group" *ngIf="type.type === 'blog'">
                                        <a [attr.href]="type[currentLang] ? type[currentLang].link : type['en'].link"
                                           target="_blank"> {{ type[currentLang] ? type[currentLang].title : type['en'].title }} / Blog
                                            <img src="https://static.myskillcamp.com/images/guidance/external-link.svg">
                                        </a>
                                    </div>

                                    <div class="block-tag__group" *ngIf="type.type === 'lxp'">
                                        <a [attr.href]="type[currentLang] ? type[currentLang].link : type['en'].link"
                                           target="_blank"> {{ type[currentLang] ? type[currentLang].title : type['en'].title }} / Lxp
                                            <img src="https://static.myskillcamp.com/images/guidance/external-link.svg">
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </aside>
</div>

