import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators'; 
import { Media } from "../../../../../media/index";
import { cleanObject } from "../../../../../helpers";
import { environment } from 'src/environments/environment';

export class EventSessionMedia {
    id: number;
    eventId: number;
    eventSessionId: number;
    media: Media;

    constructor(eventSessionMedia: any, private http: HttpClient) {
        this.extends(eventSessionMedia);
    }

    extends(eventSessionMedia: any): EventSessionMedia {
        Object.assign(this, eventSessionMedia);
        return this;
    }

    delete(): Observable<void> {
        return this.http.delete(`${environment.envVar.API_URL}/events/${this.eventId}/session/${this.eventSessionId}/media/${this.id}`)
            .pipe(map((res) => null));
    }

    update(): Observable<EventSessionMedia> {
        return this.http.put(`${environment.envVar.API_URL}/events/${this.eventId}/session/${this.eventSessionId}/media/${this.id}`, cleanObject(this))
            .pipe(map(res => this.extends(res)));
    }
}