import { Question } from '../question';
import { HttpClient } from '@angular/common/http';

export class FillInQuestion extends Question {
    content: FillInQuestionContent;
    param: FillInQuestionParameters;

    constructor(question: any, http: HttpClient) {
        super(question, http);
        this.extends(question);
    }

    extends(question: any): FillInQuestion {
        Object.assign(this, question);

        if (!this.param) {
            this.param = new FillInQuestionParameters();
        }
        if (question.param) {
            if (this.param instanceof FillInQuestionParameters) {
                this.param.extends(question.param);
            } else {
                this.param = new FillInQuestionParameters(question.param);
            }
        }

        if (!this.content) {
            this.content = new FillInQuestionContent();
            this.content.addChoice();
        }
        if (question.content) {
            if (this.content instanceof FillInQuestionContent) {
                this.content.extends(question.content);
            } else {
                this.content = new FillInQuestionContent(question.content);
            }
        }

        return this;
    }

    getChoices(index: number): string[] {
        return this.content.choices[index].choice.match(/\[(.*?)\]/gm).map((match: string) => match.substring(1, match.length - 1));
    }
}

export class FillInQuestionContent {
    choices: FillInQuestionChoice[] = [];

    constructor(data: any = {}) {
        this.extends(data);
    }

    extends(data: any = {}): FillInQuestionContent {
        Object.assign(this, data);
        return this;
    }

    addChoice(): FillInQuestionChoice {
        const newChoice = new FillInQuestionChoice();
        newChoice.choice = '';
        // newChoice.pos = (this.choices.length + 1)
        this.choices.push(newChoice);
        return newChoice;
    }

    removeChoice(index: number) {
        this.choices.splice(index, 1);
    }
}

export class FillInQuestionParameters {
    type: string;
    displayFirstLetter: boolean;
    displayWordsLength: boolean;

    constructor(data: any = {}) {
        this.extends(data);
    }

    extends(data: any = {}): FillInQuestionParameters {
        Object.assign(this, data);

        return this;
    }
}

export class FillInQuestionChoice {
    choice: string;

    constructor(data: any = {}) {
        this.extends(data);
    }

    extends(data: any = {}): FillInQuestionChoice {
        Object.assign(this, data);

        return this;
    }
}
