import { Account } from "../../";

export class Instance {
    id: number;
    templateId: number;
    language: string;
    campsNames: string;
    campsCount: number;
    eventsCount: number;
    usersCount: number;
    settings: any;
    trainers?: Account[];

    constructor(instance: any) {
        this.extends(instance);
    }

    extends(instance: any): Instance {
        Object.assign(this, instance);
        if (this.trainers && this.trainers.length > 0) {
            this.trainers = this.trainers.map((trainer) => new Account(trainer));
        }
        return this;
    }
}