import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Section } from './section';
import { environment } from 'src/environments/environment';

@Injectable()
export class SectionProvider {
    defaultLang: string;

    constructor(public http: HttpClient) {}

    getSectionsContentList():Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/studio/section-content`)
    }

    getSections():Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/studio/section`);
    }

    getSection(id: number): Observable<Section> {
        return this.http.get(`${environment.envVar.API_URL}/studio/course/${id}/section`)
            .pipe(map((data) => new Section(data, this.http)))
    }

    createSection(section: any): Observable<Section> {
        return this.http.post(`${environment.envVar.API_URL}/studio/course/${section.courseId}/section`, section)
            .pipe(map((res) => new Section(res, this.http)))
    }

    updatePosition(id: number, params: any): Observable<any> {
        return this.http.put(`${environment.envVar.API_URL}/create/courses/${id}/sections/position`, params);
    }
}
