<div>
    <div class="certificate__content">
        <div class="row">
            <div class="column medium-8">
                <h2 class="heading">
                    <label class="input select__label">
                        {{ 'words.type' | translate }}

                        <div class="select">
                            <select class="select__field" [ngModel]="getType()" (ngModelChange)="changeType($event)">
                                <option value="certificate">{{ 'words.certificate' | translate }}</option>
                                <option value="degree">{{ 'words.degree' | translate }}</option>
                                <option value="attestation">{{ 'words.attestation' | translate }}</option>
                                <option value="achievement">{{ 'words.achievement' | translate }}</option>
                                <option value="badge">{{ 'words.badge' | translate }}</option>
                            </select>
                        </div>
                    </label>

                    <span class="instruction">
                        {{ 'words.'+certificate.type | translate }}
                        <img (click)="openAsideHelp('certification')" src="https://static.myskillcamp.com/images/guidance/question-mark-circle.svg"/>
                    </span>
                    <live-editor (onUpdate)="updateCertificate()" [(inputData)]="sectionContent.title" [placeholder]="('words.document-title' | translate)"></live-editor>
                </h2>

                <p>
                    <live-editor [line]="'multi'" [rows]="8" (onUpdate)="updateCertificate()" [(inputData)]="certificate.description" [placeholder]="('words.document-description' | translate)"></live-editor>
                </p>
                <!--<label class="duration">{{ 'studio.course.section-content.certificate.duration' | translate }}
                    <input-duration (dataOutput)="onDurationUpdate($event)" [dataInput]="certificate.duration" ></input-duration>
                </label>-->

                <label class="minimal-grade">
                    <span>{{ 'studio.course.section-content.certificate.minimal-grade' | translate }}</span>
                    <input [(ngModel)]="certificate.minimumRatio" (blur)="updateCertificate()" type="number" min="0" max="100" step="10">%
                </label>

                <label class="certificate__toggle" *ngIf="!disabledGlobal">
                    <div class="switch switch_height_small" [ngClass]="{'switch_active': certificate.global}">
                        <input type="checkbox" [(ngModel)]="certificate.global" (ngModelChange)="updateCertificate()">
                    </div>
                    <p>{{ 'studio.certificate.global' | translate }}</p>
                </label>

                <label class="certificate__toggle">
                    <div class="switch switch_height_small" [ngClass]="{'switch_active': certificate.sendEmail}">
                        <input type="checkbox" [(ngModel)]="certificate.sendEmail" (ngModelChange)="updateCertificate()">
                    </div>
                    <p>{{ 'studio.certificate.sendmail' | translate }}</p>
                </label>
            </div>

            <div class="column medium-4">
                <media-manager [media]="certificate?.media" (updateMedia)="onUpdatedMedia($event)"></media-manager>
            </div>
        </div>

        <div class="row">
            <div class="column medium-8">
                <div class="receiver">
                    <span>{{ 'studio.course.section-content.document.delivered-to' | translate }}</span>
                    <span class="name">{{ 'studio.course.section-content.certificate.learner-name' | translate }}</span>
                    <span class="email">{{ 'studio.course.section-content.certificate.learner-email' | translate }}</span>
                    <span class="phone">{{ 'studio.course.section-content.certificate.learner-phone-number' | translate }}</span>
                </div>
            </div>

            <div class="column medium-4">
                <div class="deliver">
                    <span>{{ 'studio.course.section-content.document.delivered-by' | translate }}</span>
                    <div class="row">
                        <div class="columns small-6">
                            <input type="text" placeholder="Firstname" (blur)="updateCertificate()" [(ngModel)]="certificate.firstName" [placeholder]="('studio.course.section-content.certificate.first-name' | translate)">
                        </div>
                        <div class="columns small-6">
                            <input type="text" placeholder="Lastname" (blur)="updateCertificate()" [(ngModel)]="certificate.lastName" [placeholder]="('studio.course.section-content.certificate.last-name' | translate)">
                        </div>
                    </div>
                    <input type="text" placeholder="Email" (blur)="updateCertificate()" [(ngModel)]="certificate.email" [placeholder]="('studio.course.section-content.certificate.email' | translate)">
                    <input type="text" placeholder="Position" (blur)="updateCertificate()" [(ngModel)]="certificate.jobTitle" [placeholder]="('studio.course.section-content.certificate.job-title' | translate)"> {{'studio.course.section-content.certificate.from' | translate}}
                    <input type="text" placeholder="Company name" (blur)="updateCertificate()" [(ngModel)]="certificate.companyName" [placeholder]="('studio.course.section-content.certificate.company-name' | translate)">
                    <input type="text" placeholder="Company phone" (blur)="updateCertificate()" [(ngModel)]="certificate.phoneNumber" [placeholder]="('studio.course.section-content.certificate.company-phone' | translate)">
                    <textarea placeholder="Company address" (blur)="updateCertificate()" [(ngModel)]="certificate.address" [placeholder]="('studio.course.section-content.certificate.company-address' | translate)"></textarea>
                </div>
            </div>
        </div>
    </div>
</div>
