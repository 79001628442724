import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'msc-aside-course',
    templateUrl: './aside-course.component.html',
    styleUrls: ['./aside-course.component.scss']
})
export class AsideCourseComponent implements OnInit {
    @Input() content: string;
    @Input() title: string;
    @Input() plugins: any;
    @Output() contentChange: EventEmitter<string> = new EventEmitter;
    @Output() onUpdate: EventEmitter<any> = new EventEmitter;
    @Output() onClose: EventEmitter<any> = new EventEmitter;
    public active: boolean = false;

    ngOnInit() {
        this.activeEffect();
    }

    activeEffect(): void {
        const timeOut = setTimeout(() => {
            this.active = true;
            clearTimeout(timeOut);
        }, 100);
    }

    closeSidebar(): void {
        this.active = false;

        const timeOut = setTimeout(() => {
            this.onClose.emit();
            clearTimeout(timeOut);
        }, 300);
    }

    save(): void {
        this.contentChange.emit(this.content);
        this.onUpdate.emit();
        this.closeSidebar();
    }
}
