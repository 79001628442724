import { HttpClient } from "@angular/common/http";
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Note } from './note';
import { Injectable } from '@angular/core';
import { getParams } from "../helpers";
import { environment } from "src/environments/environment";

@Injectable()
export class NoteProvider {

	constructor(private http: HttpClient) {}

	getNotes(templateId: number): Observable<Note[]> {
		const params = {
			courseId: templateId
		};

		return this.http.get(`${environment.envVar.API_URL}/notes`, {params: getParams(params)})
		.pipe(map((notes:any[]) => notes.map((note) => new Note(note, this.http))));
	}

	getNote(id: number): Observable<Note> {
		return this.http.get(`${environment.envVar.API_URL}/note/${id}`)
		.pipe(map((note) => new Note(note, this.http)));
	}

	createNote(templateId: number): Observable<Note> {
		const params = {
			courseId: templateId,
			name: '',
			content: ''
		};

		return this.http.post(`${environment.envVar.API_URL}/note`, params)
		.pipe(map((res:any) => new Note(res, this.http)));
	}
}