import { Component, Input } from '@angular/core';
import { Certificate, Section, SectionContent } from '@stuplay';
import { EmitterService } from "../../../../../utils/services/emitter.service";
import { ExternalAppsService } from "../../../../../utils/services/external-apps.service";
import { StorageService } from "../../../../../utils/services/storage.service";

@Component({
    selector: 'app-certificate',
    templateUrl: './certificate.component.html',
    styleUrls: ['./certificate.component.less']
})
export class CertificateComponent {
    @Input() certificate: Certificate;
    @Input() section: Section;
    @Input() sectionContent: SectionContent;
    @Input() disabledGlobal: boolean = false;
    private me: any;
    private company: any;


    constructor(
        private emitterService: EmitterService,
        private externalAppsService: ExternalAppsService,
        private storageService: StorageService,
    ) { }

    ngOnInit() {
        this.me = this.storageService.get('me');
        this.company = this.storageService.get('company');
    }

    updateCertificate() {
        this.sectionContent.update().subscribe((sectionContent) => this.certificate = sectionContent.certificate);
    }

    onUpdatedMedia(media) {
        this.certificate.media = media;
        this.updateCertificate();
    }

    getType(): string {
        return this.certificate.type;
    }

    changeType(type): void {
        this.certificate.type = type;
        this.updateCertificate();
    }

    openAsideHelp(type: string): any {
        this.emitterService.activeHelp.emit({ type: type, feature: 'exercice' });
    }
}
