import { Component, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'instance-unpublish-modal',
    templateUrl: './instance-unpublish-modal.component.html',
    styleUrls: ['./instance-unpublish-modal.component.less']
})

export class InstanceUnpublishModal {
    @Output() closeModal = new EventEmitter<boolean>();

    close() {
        this.closeModal.emit();
    }
}
