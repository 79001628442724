import { Component, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'timeline-delete-content',
    templateUrl: './timeline-delete-content.component.html'
})
export class TimelineDeleteContentComponent {
    isOpen: boolean = false;
    @Output() deleteContent = new EventEmitter();

    onDeleteContent() {
        this.deleteContent.emit();
        this.isOpen = false;
    }

    toggleSettings() {
        this.isOpen = !this.isOpen
    }
}
