<div class="alert alert_error" *ngIf="error">
    <div class="alert__title"></div>
    <div class="alert__content">
        {{ 'error.message' | translate }}
    </div>

    <button class="alert__close">
        <span class="sr-only">{{ 'words.delete' | translate }}</span>
    </button>
</div>

<ng-container *ngIf="isCourseCreator">
    <ng-container *ngIf="course.isAttachedToACollection">
        <div class="course-draft-info">
            <ng-container *ngIf="isInfoMessageDisplayed && !isLive">
                <div class="draft-text">
                    <i class="templab-icon templab-icon-info-1"></i>
                    <p>This course is in <span> Draft.</span> Changes won’t be reflected to customers.
                        <a (click)="onStatusLive()">Set status to Live</a>
                    </p>
                    <i class="new-icon icon-close-square-outline" (click)="onDismissInfoMessage()"></i>
                </div>
            </ng-container>
    
            <div class="draft-switch">
                <label>
                    <span class="label-text">Marketplace status: <span [ngClass]="{'live': isLive}">{{ isLive ? 'Live' : 'Draft'}}</span></span>
                    <span class="switch switch_height_small" [ngClass]="{'switch_active': isLive}">
                        <input type="checkbox" (change)="onStatusLive()">
                    </span>
                </label>
            </div>
        </div>
    </ng-container>
</ng-container>

<div class="page">
    <div class="course-fieldset course-fieldset--half">
        <label for="title">{{ 'studio.create.course.title' | translate }}</label>
        <input type="text" name="title" maxlength="120" [disabled]="course.schema !== 'template'"
            [(ngModel)]="course.title" (ngModelChange)="courseUpdate.next($event)">
    </div>
    <div class="course-fieldset course-fieldset--half">
        <ng-container *ngIf="isCourseCreator">
            <label for="">{{ 'studio.section-content.label.company-ownership' | translate }}</label>
            <ng-container *ngIf="course.isAttachedToACollection">
                <div class="tooltip-custom">
                    <input type="text" [value]="selectedCompany?.name" disabled>
                    <div class="tooltip__info">{{ 'tooltip.campany-ownership-update' | translate }}</div>
                </div>
            </ng-container>

            <ng-container *ngIf="!course.isAttachedToACollection">
                <div class="dropdown dropdown--skill dropdown--overflow"
                    [ngClass]="{'active': displayDropdown.company}"
                    [(autoClose)]="displayDropdown.company" (click)="displayed('company')">
                    <div class="dropdown__label">
                        <span><i *ngIf="!selectedCompany" class="templab-icon templab-icon-alert-circle-1"></i> 
                            {{ selectedCompany ? selectedCompany.name : 'studio.section-content.select-company' | translate }}</span>
                        <i class="new-icon icon-arrow-ios-downward-outline"></i>
                    </div>
    
                    <div class="dropdown__list">
                        <div class="dropdown__items">
                            <div class="dropdown__item" *ngFor="let company of companiesAuthor" (click)="updateCompanyOwnership(company)">
                                {{ company.name }}
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </ng-container>
    </div>
    <div class="course-fieldset course-fieldset--half" *ngIf="course.schema === 'template'">
        <label for="reference">{{ 'studio.instance.reference' | translate }}</label>
        <input type="text" name="reference" *ngIf="course.settings" [disabled]="course.schema !== 'template'"
            [(ngModel)]="course.settings.reference" (ngModelChange)="courseUpdate.next($event)">
    </div>
    <div class="course-fieldset">
        <label for="description">{{ 'studio.create.course.description' | translate }}</label>
        <msc-froala [editable]="course.schema === 'template'" [height]="100" [maxLength]="2000" [isCourse]="true"
            [plugins]="'xmini'" [buttons]="'xmini'" [(content)]="course.description"
            (contentChange)="courseUpdate.next($event)"></msc-froala>
    </div>
    <div class="course-fieldset course-fieldset--half">
        <label for="description">{{ 'studio.create.course.image' | translate }}</label>
        <span class="helper">{{ 'studio.create.course.image.helper' | translate }}</span>
        <div class="media-container" *ngIf="course.schema === 'template'" ngFileDrop [uploadInput]="uploadInput"
            (uploadOutput)="onUploadOutput($event)" accept="image/*"
            [ngClass]="{'media-container--dragging': dragging, 'media-container--has-file': course.media}">
            <div class="upload upload_processing" *ngIf="coverFile">
                <h2 class="heading">
                    {{ coverFile.name }}
                </h2>
                <div class="progress">
                    <div class="progress__text">{{ ((coverFile?.progress.data.percentage >= 100 ? 100 :
                        coverFile?.progress.data.percentage| number:'1.1-1' )||
                        0)+'%' }}</div>
                    <div class="progress-bar">
                        <div class="progress-bar__progress"
                            [ngStyle]="{'width': (coverFile?.progress.data.percentage || 0)+'%'}"></div>
                    </div>
                </div>
            </div>
            <div class="media-label" *ngIf="!coverFile">
                <ng-container *ngIf="!dragging">
                    <i class="icon icon--publish"></i>
                    <span>{{ 'studio.create.course.image.drag' | translate }} <a (click)="openModal(modalMedia, { windowClass: 'force-fullscreen-fixed' })">{{
                            'studio.create.course.image.or-choose' | translate }}</a></span>
                </ng-container>
                <span *ngIf="dragging">{{ 'studio.create.course.image.dragging' | translate }}</span>
            </div>
        </div>
        <div class="media-container media-container--disabled" *ngIf="!course.media && course.schema !== 'template'">
            <div class="media-label">
                <i class="icon icon--publish"></i>
                <span>{{ 'studio.create.course.image.not-available' | translate }}</span>
            </div>
        </div>
        <div class="media-upload" *ngIf="course.media">
            <button *ngIf="course.schema === 'template'" class="btn btn_color_red delete" (click)="onUpdateMedia(null)">
                <span class="sr-only">{{ 'words.delete' | translate }}</span>
            </button>
            <media-image [media]="course.media"></media-image>
        </div>
    </div>
    <div class="course-fieldset">
        <label for="prerequisites">{{'studio.create.course.requirement' | translate}}</label>
        <msc-froala [editable]="course.schema === 'template'" [height]="100" [maxLength]="1000" [isCourse]="true"
            [plugins]="'xmini'" [buttons]="'xmini'" [(content)]="course.requirement"
            (contentChange)="courseUpdate.next($event)"></msc-froala>
    </div>
    <div class="course-fieldset">
        <label for="audience">{{'studio.create.course.audience' | translate}}</label>
        <msc-froala [editable]="course.schema === 'template'" [height]="100" [maxLength]="1000" [isCourse]="true"
            [plugins]="'xmini'" [buttons]="'xmini'" [(content)]="course.target"
            (contentChange)="courseUpdate.next($event)"></msc-froala>
    </div>
    <div class="course-fieldset">
        <label for="purpose">{{'studio.create.course.purpose' | translate}}</label>
        <msc-froala [editable]="course.schema === 'template'" [height]="100" [maxLength]="1000" [isCourse]="true"
            [plugins]="'xmini'" [buttons]="'xmini'" [(content)]="course.purpose"
            (contentChange)="courseUpdate.next($event)"></msc-froala>
    </div>
    <div class="course-fieldset course-fieldset--quarter">
        <label for="duration">{{ 'studio.create.course.duration' | translate }}</label>
        <div class="suffix" [attr.data-unit]="'words.hours' | translate | lowercase">
            <input type="number" min="0" name="duration" [(ngModel)]="duration"
                (ngModelChange)="courseUpdate.next($event)" [disabled]="course.schema === 'instance'">
        </div>
    </div>
    <div class="course-fieldset course-fieldset--quarter">
        <label for="language">{{ 'studio.create.course.language' | translate }}</label>
        <div>
            <span class="" *ngIf="course.schema === 'instance'">
                {{ course.language?.name }}
            </span>

            <div class="dropdown dropdown--skill dropdown--overflow" style="z-index: 2;"
                *ngIf="course.schema === 'template'" [ngClass]="{'active': displayDropdown.languages}"
                [(autoClose)]="displayDropdown.languages" (click)="displayed('languages')">
                <div class="dropdown__label">
                    {{ selectedLanguage?.name }}
                    <i class="new-icon icon-arrow-ios-downward-outline"></i>
                </div>

                <div class="dropdown__list">
                    <div class="dropdown__filter">
                        <input class="" type="text" [placeholder]="'placeholder.search' | translate"
                            [(ngModel)]="filterInput" [debounce]="500" (onDebounce)="filterLanguages($event)"
                            (click)="$event.stopPropagation()" />
                    </div>
                    <div class="dropdown__items">
                        <div class="dropdown__item" *ngFor="let language of filteredLanguages"
                            (click)="selectLanguage(language)">
                            {{ language.name }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="course-fieldset course-fieldset--quarter">
        <label for="type">{{ 'studio.create.course.type' | translate }}</label>
        <div>
            <span class="" *ngIf="course.schema === 'instance'">
                {{ 'type.' + course.type | translate }}
            </span>

            <div class="dropdown dropdown--skill dropdown--overflow" style="z-index: 1;"
                *ngIf="course.schema === 'template'" [ngClass]="{'active': displayDropdown.type}"
                [(autoClose)]="displayDropdown.type" (click)="displayed('type')">
                <div class="dropdown__label">
                    {{ 'type.' + course.type | translate }}
                    <i class="new-icon icon-arrow-ios-downward-outline"></i>
                </div>

                <div class="dropdown__list">
                    <div class="dropdown__items">
                        <div class="dropdown__item" (click)="course.type = 'digital-learning';updateCourse()">
                            {{'type.digital-learning' | translate}}
                        </div>
                        <div class="dropdown__item" (click)="course.type = 'blended-learning';updateCourse()">
                            {{'type.blended-learning' | translate}}
                        </div>
                        <div class="dropdown__item" (click)="course.type = 'presentiel';updateCourse()">
                            {{'type.presentiel' | translate}}
                        </div>
                        <div class="dropdown__item" (click)="course.type = 'distanciel';updateCourse()">
                            {{'type.distanciel' | translate}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="course-fieldset">
        <label for="skills">{{ 'words.skills' | translate }}</label>
        <div class="course-fieldset__container">
            <p class="helper" *ngIf="filterSkills(true).length > 0">
                {{ 'words.thematics' | translate }}
            </p>

            <div class="skill-container">
                <div class="skill skill--rate" *ngFor="let skill of filterSkills(true); let index=index"
                    (mouseout)="displayLevel(index, null, true)">
                    <div class="skill__content" (click)="updateLevel(skill, 0)">
                        <i class="new-icon icon-grid-outline" style="padding: 0 0.5rem 0 0;"></i>
                        <div class="skill__text">{{ skill.name }}</div>

                        <div class="skill__rating">
                            <span [ngClass]="{'active' : isLevel(index, skill, 0, true)}"
                                (mouseover)="displayLevel(index, 1, true)"
                                (click)="updateLevel(skill, 1); $event.stopPropagation()"></span>
                            <span [ngClass]="{'active' : isLevel(index, skill, 1, true)}"
                                (mouseover)="displayLevel(index, 2, true)"
                                (click)="updateLevel(skill, 2); $event.stopPropagation()"></span>
                            <span [ngClass]="{'active' : isLevel(index, skill, 2, true)}"
                                (mouseover)="displayLevel(index, 3, true)"
                                (click)="updateLevel(skill, 3); $event.stopPropagation()"></span>
                        </div>

                        <div class="skill__close" (click)="detachSkill(skill)" *ngIf="course.schema === 'template'">
                            <i class="new-icon icon-close-outline"></i>
                        </div>
                    </div>
                </div>
            </div>

            <p class="helper" *ngIf="filterSkills().length > 0">
                {{ 'words.other-skills' | translate }}
            </p>

            <div class="skill-container">
                <div class="skill skill--rate" *ngFor="let skill of filterSkills(); let index=index"
                    (mouseout)="displayLevel(index)">
                    <div class="skill__content" (click)="updateLevel(skill, 0)">
                        <div class="skill__text">{{ skill.name }}</div>

                        <div class="skill__rating">
                            <span [ngClass]="{'active' : isLevel(index, skill, 0)}" (mouseover)="displayLevel(index, 1)"
                                (click)="updateLevel(skill, 1); $event.stopPropagation()"></span>
                            <span [ngClass]="{'active' : isLevel(index, skill, 1)}" (mouseover)="displayLevel(index, 2)"
                                (click)="updateLevel(skill, 2); $event.stopPropagation()"></span>
                            <span [ngClass]="{'active' : isLevel(index, skill, 2)}" (mouseover)="displayLevel(index, 3)"
                                (click)="updateLevel(skill, 3); $event.stopPropagation()"></span>
                        </div>

                        <div class="skill__close" (click)="detachSkill(skill)" *ngIf="course.schema === 'template'">
                            <i class="new-icon icon-close-outline"></i>
                        </div>
                    </div>
                </div>
            </div>

            <msc-loader [loading]="loading"></msc-loader>
        </div>

        <div class="course-fieldset__actions">
            <button class="add-item" (click)="openAside()" *ngIf="course.schema === 'template'">
                {{ 'words.add-skill' | translate }}
            </button>
        </div>
    </div>
</div>

<msc-aside-skill *ngIf="displayAside" [company]="company" [content]="course" [skills]="skills"
    (onEvent)="eventDispatcher($event)" (onClose)="closeAside()">
</msc-aside-skill>

<ng-template #modalMedia let-modal>
    <library-modal (closeModal)="modal.close()" (updateMedia)="onUpdateMedia($event)"
        [toUpload]="true" [onlyPictures]="true"></library-modal>
</ng-template>

<ng-template #modalDraft let-modal>
    <div class="modal-header modal-header-draft">
        
    </div>
    <div class="modal-body modal-body-draft">
        <h3>This content is now <span>Live</span></h3>
        <p>If you want to make changes, the <span>marketplace status</span> will be set to <span class="status status-draft">Draft.</span> 
            When you’re <span>done</span> make sure to set it back to <span class="status status-live">Live.</span></p>
        <p class="preview-text">Just browsing? Use <a (click)="goToPreview()">learner preview</a> instead</p>
    
        <div class="modal-actions">
            <button class="btn" (click)="goToCatalogue()">Back to catalogue</button>
            <button class="btn btn--secondary" (click)="onContinueEditiong()">Continue editing</button>
        </div>
    </div>
</ng-template>
