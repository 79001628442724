<div *ngIf="project.type === 'document'" >
    <div class="upload">
        <label class="upload__placeholder" style="cursor: not-allowed;">
            <div class="title">
                {{ 'studio.project.media.upload.title' | translate }}
            </div>

            <div class="content">
                <span>{{ 'studio.media.upload.max-weight' | translate }}</span>
                <input style="text-align: center; width: auto; margin-top: 1rem;" type="file" disabled>
            </div>
        </label>
    </div>
</div>

<div *ngIf="project.type === 'cheatsheet'">
    <msc-froala [editable]="false" [height]="200"></msc-froala>
</div>
