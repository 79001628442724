import { Component, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'instance-publish-modal',
    templateUrl: './instance-publish-modal.component.html',
    styleUrls: ['./instance-publish-modal.component.less']
})

export class InstancePublishModal {
    @Output() publishSession = new EventEmitter();
    @Output() closeModal = new EventEmitter<boolean>();
    hide = false;

    confirm() {
        if (this.hide) {
            // store decision
            sessionStorage.setItem('hidePublishWarn', this.hide ? '1' : null);
        }

        this.publishSession.emit();
        this.closeModal.emit();
    }

    close() {
        this.closeModal.emit();
    }
}
