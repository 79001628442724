<div [ngStyle]="{'width': width+'px', 'height': height+'px'}" [ngClass]="{'media-holder_preview': mini}" class="media-holder">
	<div [ngStyle]="{'height': height+'px'}" *ngIf="(status === 1 && init) || media.type === 'EMBED' || media.storage ==='ext'">
		<media-embed *ngIf="media.type === 'EMBED'" [media]="media"></media-embed>
		<media-image *ngIf="media.type === 'IMG'" [media]="media" [size]="size"></media-image>
        <media-sound *ngIf="media.type === 'SND'" [media]="media"></media-sound>
        <media-none *ngIf="media.type === 'NONE'" [media]="media"></media-none>
		<media-vimeo-player *ngIf="media.type === 'VID'" [media]="media" [size]="size"></media-vimeo-player>
		<media-doc *ngIf="(media.type === 'DOC' || media.type === 'PDF')" [media]="media"></media-doc>
	</div>

	<div *ngIf="status === 0 && init && media.type !== 'EMBED'">
		<div class="load-more animate">
			<div class="dot"></div>
		</div>
		<span>{{ 'media.upload.waiting-process' | translate }}</span>
	</div>

	<div *ngIf="status === -1 && init && media.type !== 'EMBED'">
		<span>Un problème est survenu.</span>
	</div>
</div>
