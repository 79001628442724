import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, ContentChildren, QueryList, TemplateRef, ElementRef, HostListener } from '@angular/core';
//
import { CommonSearchOutput } from '../search/search.component';
import { CommonTreeDatum } from '../tree/tree.component';
//
export type CommonSelectType = 'single' | 'multi';
export interface CommonSelectDatum<T> extends CommonTreeDatum<T> { isSelected: boolean; }
//

const COMMON_SELECT_CONTENT_HEIGHT: number = 400; // the max-height of the option container or content

@Component({
    selector: 'msc-common-select',
    templateUrl: './select.component.html',
    styleUrls: ['./select.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class CommonSelectComponent<T> {
    @Input() data: Array<CommonSelectDatum<T>> = []; // data of select options
    @Input() hasSearch: boolean = false; // indicates if it has search capability
    @Input() type: CommonSelectType = 'single';
    @Output() selectEvent: EventEmitter<CommonSelectDatum<T>> = new EventEmitter<CommonSelectDatum<T>>();
    @ContentChildren('buttonTemplate') buttonTemplate: QueryList<TemplateRef<{ datum: CommonSelectDatum<T> }>>;
    @ContentChildren('optionTemplate') optionTemplate: QueryList<TemplateRef<{ datum: CommonSelectDatum<T> }>>;

    public isOpen: boolean = false;
    public dataRender: Array<CommonSelectDatum<T>> = []; // data for option template
    public dataSelect: Array<CommonSelectDatum<T>>; // data for button template

    constructor(
        private readonly elementRef: ElementRef,
    ) { }

    get isUp(): boolean { return this.elementRef.nativeElement.getBoundingClientRect().y + COMMON_SELECT_CONTENT_HEIGHT > document.documentElement.clientHeight; }
    get templateButton(): TemplateRef<{ datum: CommonSelectDatum<T> }> { return (this.buttonTemplate?.toArray() || [])[0]; }
    get templateOption(): TemplateRef<{ datum: CommonSelectDatum<T> }> { return (this.optionTemplate?.toArray() || [])[0]; }

    ngOnInit(): void {
        this.setDataRender();
    }

    ngOnChanges(): void {
        this.setDataSelect();
    }

    /**
     * Set the data for render
     */
    setDataRender(): void {
        this.dataRender = [...this.data];
    }

    /**
     * Set the data for select
     */
    setDataSelect(): void {
        this.dataSelect = (this.data || []).filter((datum) => datum.isSelected);
        if (this.type !== 'single') { return; }
        this.isOpen = false;
    }

    /**
     * Event handler for open
     */
    onOpen(): void {
        this.isOpen = !this.isOpen;
    }

    /**
     * Event handler for search
     */
    onSearch({ data }: CommonSearchOutput<CommonSelectDatum<T>>): void {
        if (!Array.isArray(data)) { return; } // sanity check
        this.dataRender = data as Array<CommonSelectDatum<T>>;
    }

    /**
     * Event handler for select
     */
    onSelect(datum: CommonSelectDatum<T>): void {
        if (!datum) { return; } // sanity check
        this.selectEvent.emit(datum);
    }

    /**
     * Event handler for clicking outside
     */
    @HostListener('document:mousedown', ['$event'])
    onOutside(event: Event): void {
        if (this.elementRef.nativeElement.contains(event.target)) { return; }
        this.isOpen = false;
    }
}
