export function find(element: string): any {
    return document.querySelector(element);
}

export function create(element: string, attributes?: any, content?: any): HTMLElement {
    const newElement = document.createElement(element);
    if (attributes) {
        if (attributes instanceof Array) {
            for (const attribute of attributes) {
                const attr = document.createAttribute(attribute.type);
                if (attribute.value !== null && attribute.value !== undefined) {
                    attr.value = attribute.value;
                }
                newElement.setAttributeNode(attr);
            }
        } else {
            const attr = document.createAttribute(attributes.type);
            if (attributes.value !== null && attributes.value !== undefined) {
                attr.value = attributes.value;
            }
            newElement.setAttributeNode(attr);
        }
    }

    if (content) {
        newElement[content.type] = content.value;
    }
    return newElement;
}

export function add(parent: string, element: HTMLElement | string, attributes?: any, content?: any): void {
    const parentElement = find(parent);
    if (parentElement) {
        if (typeof element === 'string') {
            element = create(element, attributes, content);
        }
        parentElement.appendChild(element);
    }
}

export function before(parent: string, element: HTMLElement | string, attributes?: any, content?: any): void {
    const parentElement = find(parent);
    if (parentElement) {
        if (typeof element === 'string') {
            element = create(element, attributes, content);
        }
        insert(parentElement, 'beforebegin', element);
    }
}

export function after(parent: string, element: HTMLElement | string, attributes?: any, content?: any): void {
    const parentElement = find(parent);
    if (parentElement) {
        if (typeof element === 'string') {
            element = create(element, attributes, content);
        }
        insert(parentElement, 'afterend', element);
    }
}

export function append(parent: string, element: HTMLElement | string, attributes?: any, content?: any): void {
    const parentElement = find(parent);
    if (parentElement) {
        if (typeof element === 'string') {
            element = create(element, attributes, content);
        }
        insert(parentElement, 'beforeend', element);
    }
}

export function prepend(parent: string, element: HTMLElement | string, attributes?: any, content?: any): void {
    const parentElement = find(parent);
    if (parentElement) {
        if (typeof element === 'string') {
            element = create(element, attributes, content);
        }
        insert(parentElement, 'afterbegin', element);
    }
}

export function move(parent: string, element: string, position: string): void {
    const parentElement = find(parent);
    const elm = find(element);
    if (parentElement && elm) {
        if (position === 'before') {
            parentElement.appendChild(elm);
        } else {
            parentElement.insertBefore(elm);
        }
    }
}

export function remove(element: string): void {
    const elm = find(element);
    if (elm) {
        elm.parentNode.removeChild(elm);
    }
}

export function addClass(element: string, className: string): void {
    const elm = find(element);
    if (elm) {
        elm.classList.add(className);
    }
}

export function removeClass(element: string, className: string): void {
    const elm = find(element);
    if (elm) {
        elm.classList.remove(className);
    }
}

function insert(parent: HTMLElement, position: any, element: HTMLElement): void {
    parent.insertAdjacentElement(position, element);
}
