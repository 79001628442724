import { Component, Input } from '@angular/core';
import { Video, Section, SectionContent } from '@stuplay'

@Component({
    selector: 'app-video',
    templateUrl: './video.component.html'
})

export class VideoComponent {
    @Input() video: Video;
    @Input() section: Section;
    @Input() sectionContent: SectionContent;
}
