import { environment } from '../../../environments/environment';

export class UrlHelper {
    private static sites: any = {
        'youtube': {
            'url': '//www.youtube.com/embed/{id}?rel=0&showinfo=0',
            'reg': /^watch\?v=([\w-_]*)/gi
        },
        'youtu': {
            'url': '//www.youtube.com/embed/{id}?rel=0&showinfo=0',
            'reg': /^([\w-_]*)/gi
        },
        'vimeo'  : {
            'url'    : '//player.vimeo.com/video/{id}?dnt=1',
            'reg'    : /^(.*)/
        },
        'dailymotion': {
            'url': '//www.dailymotion.com/embed/video/{id}',
            'reg': /^video\/([\w_-]*)/gi
        }
    };
    private static regex: any = /((https?|ftp)\:\/\/)([a-z0-9+!*(),;?&=\$_.-]+(\:[a-z0-9+!*(),;?&=\$_.-]+)?@)?([a-z0-9-.]*)\.([a-z]{2,3})(\:[0-9]{2,5})?(\/([a-z0-9+\$_\-~@\(\)\%]\.?)+)*\/?(\?[a-z+&\$_.-][a-z0-9;:@&#%=+\/\$_.-]*)?(#[a-z_.-][a-z0-9+\$_.-]*)?/i;

    constructor() { }

    public static parse(url: string, reference?: any, key?: string): string {
        const re = /^(?:https:|http:)?\/\/(?:www\.)?([\w-]*)\.(?:[^\s\/]*)\/(.*)/i;
        const result = re.exec(url);
        let parsedUrl: string;

        try {
            if (this.sites[result[1]]) {
                url = this.sites[result[1]].url;
                const id = this.sites[result[1]].reg.exec(result[2]);
                parsedUrl = url.replace('{id}', id[1]);
                reference[key] = parsedUrl;
            }
        } catch (e) { }

        return parsedUrl;
    }

    public static getHttpUrl(url: string): string {
        return (url.indexOf('http') !== -1) ? url : location.protocol + '//' + url;
    }

    public static navigate(url: any, target: string = '_blank'): void {
        let link = url;
        if (url && typeof url === 'object') {
            link = url.parts.join('/');

            let params = null;
            if (url.params) {
                params = link.indexOf('&') > -1 ? '&' : '?';
                let i = 0;
                for (const param in url.params) {
                    if (url.params.hasOwnProperty(param)) {
                        if (i > 0) {
                            params += '&';
                        }
                        params += param + '=' + url.params[param];
                        i++;
                    }
                }
                link += params;
            }
        }
        window.open(this.getHttpUrl(link), target);
    }

    public static test(url: string): boolean {
        return this.regex.test(url);
    }

    public static getHost(): string {
        return location.host;
    }

    public static getHostname(): string {
        return location.hostname;
    }

    public static getDomain(): string {
        return location.hostname.split('.').slice(-2).join('.');
    }

    public static getUrl(applicationName: string = 'app'): string {
        if (`${UrlHelper.getHostname()}/` === environment.envVar.STUDIO_URL.replace('https://', '')) {
            return environment.envVar[applicationName.toUpperCase() + '_URL']
        } else {
            switch (applicationName) {
                case 'studio':
                case 'player':
                    return location.protocol + '//' + UrlHelper.getHostname() + '/' + applicationName;
                case 'app':
                    return location.protocol + '//' + UrlHelper.getHostname();
            }
        }
    }
}
