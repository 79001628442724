import { Component, Input } from '@angular/core';
import { Exercise } from '@stuplay';
import { QuestionTypeMap } from '@stuplay';
import { ErrorCatcherService } from '../../../../../../utils/services/error-catcher.service';
import { switchMap } from 'rxjs/operators';

@Component({
    selector: 'add-question',
    templateUrl: './add-question.component.html'
})
export class AddQuestionComponent {
    @Input() exercise: Exercise;
    @Input() position: number;
    @Input() sectionContentId: number;
    error: any;
    modalAction: boolean = false;

    constructor(private errorCatcher: ErrorCatcherService) { }

    changeModalAction(): void {
        this.modalAction = !this.modalAction;
        if (this.modalAction) {
            this.scroll('aq-' + this.position + '-' + this.sectionContentId, 300);
        }
    }

    scroll(id: string, time: number = 150): void {
        const timeOut = setTimeout(function() {
            const element: any = document.getElementById(id);
            const params: any = {
                block: 'center',
                inline: 'nearest',
                behavior: 'smooth'
            };
            element.scrollIntoView(params);
            clearTimeout(timeOut);
        }, time);
    }

    addQuestion(type: string): void {
        this.exercise
            .addQuestion({
                type_id: QuestionTypeMap.getIdByName(type),
                exerciseId: this.exercise.id,
                position: this.position
            })
            .pipe(switchMap(() => this.exercise.update()))
            .subscribe(() => {
                this.errorCatcher.setErrors(this.findQuestion());
                this.scroll('q-' + this.findQuestion().id);
            });
        this.modalAction = !this.modalAction;
    }

    findQuestion(): any {
        const realPosition = this.position + 1;
        const question = this.exercise.questions.find((question) => question.position === realPosition);
        return question || [...this.exercise.questions].pop();
    }
}
