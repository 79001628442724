<div class='timeline'>
	<div class="timeline__new-post">
		<form #commentForm class="new-post">
			<span>
                {{ 'studio.timeline.discussion.subtitle' | translate }}
            </span>

			<timeline-add-content (addContent)="addPost($event)" [placeholder]="('studio.timeline.placeholder.ask' | translate)"></timeline-add-content>
		</form>
	</div>

	<div *ngIf="items && items.length > 0">
		<div class="post" *ngFor="let item of items">
			<msc-post [post]='item' [timelineId]='timelineId' (onDelete)="deletePost($event)"></msc-post>
		</div>

		<button *ngIf="pagination?.currentPage < pagination?.lastPage" class="load_more text-center btn" (click)="nextPosts()">
            {{'studio.timeline.button-load-more' | translate}}
        </button>
	</div>
</div>
