<aside class="sidebar sidebar--modal sidebar-subscription" [ngClass]="active ? 'active' : ''" (appSidebar)="closeSidebarEmit()">
    <div class="sidebar__container">
        <div class="sidebar__head sidebar__head--center">
            <div class="sidebar__description">
                <h1 class="sidebar__title">
                    {{ 'words.unpublish-sessions' | translate }}
                </h1>
            </div>

            <a class="sidebar__close" (click)="closeSidebarEmit()">
                <i class="icon icon--close"></i>
            </a>
        </div>

        <div class="sidebar__body">
            <div class="sidebar__form">

                <div class="sidebar__search">
                    <div class="search">
                        <label class="search__label">
                            <i class="search__icon icon icon--search"></i>
                        </label>

                        <input class="search__input" type="search" [placeholder]="'words.search' | translate" [(ngModel)]="query" [debounce]="500" (onDebounce)="search()">

                        <i class="icon icon--close" *ngIf="query" (click)="reset()"></i>
                    </div>
                </div>

                <div class="sidebar__fieldset" *ngFor="let company of companies; let index=index">
                    <div class="sidebar__toggle" [ngClass]="{'active': displays[index]}" (click)="displayed(index)">
                        <p>{{ company.name }}</p>
                        <i class="icon icon--arrow-bottom"></i>
                    </div>

                    <div class="sidebar__camps">
                        <div class="" *ngIf="company.camps.length === 0" style="display: flex; justify-content: center; width: 100%;">
                            {{ 'words.camps-not-found' | translate }}
                        </div>

                        <div class="box-camp" *ngFor="let camp of company.camps" [ngClass]="{'active' : isChoosed(camp)}" (click)="chooseCamp(camp)">
                            <div class="box-camp__content">
                                <p class="box-camp__title">{{ camp.name }}</p>
                                <div class="box-camp__stats">
                                    <div>
                                        <i class="icon icon--users"></i> {{ camp.usersCount }}
                                    </div>

                                    <div>
                                        <i class="icon icon--calendar"></i> {{ camp.instancesCount }}
                                    </div>
                                </div>

                                <div class="box-camp__checkbox">
                                    <input type="checkbox" class="input-checkbox input-checkbox--rounded" [checked]="isChoosed(camp)">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="" *ngIf="companies.length === 0 && !loading" style="display: flex; justify-content: center; align-items: center; height: 200px; font-weight: 600;">
                    {{ 'words.camps-not-found' | translate }}
                </div>

                <div class="load-more animate" *ngIf="loading">
                    <div class="dot"></div>
                </div>
            </div>
        </div>

        <div class="sidebar__footer sidebar__footer--notif">
            <p *ngIf="campsSelected.length === 0"></p>
            <p *ngIf="campsSelected.length > 0">
                <span>{{ campsSelected.length }}</span> {{ (campsSelected.length > 1 ? 'studio.publish.choose-camp.selected-camps' : 'studio.publish.choose-camp.selected-camp') | translate | lowercase }}
            </p>

            <button class="btn btn--primary" (click)="unpublish(modalUnpublished)" [disabled]="saveLoading || !unpublishedCamps.length">
                <div class="lds-ring" *ngIf="saveLoading">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                {{ 'words.confirm' | translate }}
            </button>
        </div>
    </div>
</aside>

<ng-template #modalUnpublished let-modal>
    <instance-unpublish-confirm-modal (closeModal)="modal.close()" (onConfirm)="confirm(); modal.close()">
    </instance-unpublish-confirm-modal>
</ng-template>

<style>
    .m-title {
        font-size: 1.5rem;
        font-weight: 800;
    }

    .m-content {
        margin: 2rem;
    }
</style>
