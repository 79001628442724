import { Component, Input } from '@angular/core';
import { Media } from '@stuplay';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser'

@Component({
    selector: 'media-embed',
    templateUrl: './embed.component.html',
    styleUrls: ['./embed.component.less']
})

export class MediaEmbedComponent {
    @Input() media: Media;
    public url: SafeResourceUrl;

    constructor(private sanitizer: DomSanitizer) { }

    ngOnChanges() {
        this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.media.url);
    }
}
