import { Component, OnInit, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { Media, MediaProvider } from '@stuplay';
import { StorageService } from '../../services/storage.service';
import { ExternalAppsService } from '../../services/external-apps.service';
import { EmitterService } from "../../services/emitter.service";

@Component({
    selector: 'library-modal',
    templateUrl: './library-modal.component.html',
    styleUrls: ['./library-modal.component.less']
})
export class LibraryModalComponent implements OnInit {
    @Input() toUpload: boolean;
    @Input() onlyPictures: boolean = false;
    @Output() updateMedia = new EventEmitter();
    @Output() closeModal = new EventEmitter<boolean>();
    public me: any;
    public company: any;
    public media: Media;
    public dropdownOrderVisible: boolean = false;
    public query: string;
    public orderBy: string;
    public medias: Media[];
    public showItemPreview: boolean = false;
    public itemToPreview: Media;
    public tab: string;
    public currentUser: any;
    public pagination: any;

    constructor(
        private mediaProvider: MediaProvider,
        private storageService: StorageService,
        private externalAppsService: ExternalAppsService,
        private emitterService: EmitterService,
    ) { }

    ngOnInit() {
        this.me = this.storageService.get('me');
        this.company = this.storageService.get('company');
        this.currentUser = this.storageService.get('me');
        this.tab = (this.onlyPictures) ? 'image' : 'video';

        this.mediaProvider.getMedias(this.tab).subscribe((data) => {
            this.medias = data.medias;
            this.pagination = data.pagination;
        });
    }

    @HostListener('document:click', ['$event'])
    clickout(event) {
        if (!event.target.closest('.dropdown__context')) {
            this.dropdownOrderVisible = false;
        }
        if (!event.target.closest('.library-page__preview') && !event.target.closest('.folder')) {
            this.showItemPreview = false;
        }
    }

    filterMedias(type: string): void {
        this.mediaProvider.getMedias(type).subscribe((data) => {
            this.medias = data.medias;
            this.pagination = data.pagination;
        })
    }

    toggleDropdown(): void {
        this.dropdownOrderVisible = !this.dropdownOrderVisible;
    }

    setOrder(orderBy: string): void {
        this.orderBy = orderBy;
    }

    setTab(type: string): void {
        this.showItemPreview = false;
        this.tab = type;
    }

    isSelected(type: string): boolean {
        return this.tab === type;
    }

    onUpdatedMedia(media: any): void {
        if (this.toUpload) {
            this.updateMedia.emit(media);
            this.close();
        }
    }

    close(): void {
        this.closeModal.emit();
    }

    closePreview(): void {
        this.showItemPreview = false;
    }

    showPreview(item: any): void {
        this.showItemPreview = true;
        this.itemToPreview = item;
    }

    redirect(destination: string): void {
        switch (destination) {
            case 'video': {
                this.setTab('video');
                this.filterMedias('video');
                break;
            }
            case 'image': {
                this.setTab('image');
                this.filterMedias('image');
                break;
            }
            case 'document': {
                this.setTab('document');
                this.filterMedias('document');
                break;
            }
            case 'sound': {
                this.setTab('sound');
                this.filterMedias('sound');
                break;
            }
            case 'embed': {
                this.setTab('embed');
                this.filterMedias('embed');
                break;
            }
            case 'iframe': {
                this.setTab('embed');
                this.filterMedias('embed');
                break;
            }
        }
    }

    resetSearch(): void {
        this.query = '';
    }

    openAsideHelp(type: string): any {
        this.emitterService.activeHelp.emit({ type: type, feature: 'exercice' });
    }
}
