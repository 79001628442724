export class Place {
  id: number;
  city: City;
  raw: string;
  title: string;
  addressNumber: number;
  street: string;
  lat: number; // float
  lon: number; // float
}

export class City {
  id: number;
  country: Country;
  name: string;
  lat: number; // float
  lon: number; // float
}

export class Country {
  id: number;
  name: string;
  lat: number; // float
  lon: number; // float
}
