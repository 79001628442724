<div [class]="'settings ' + settingsClass">
    <div class="settings-edit" [ngClass]="{'open-settings': editAction === true}">
        <button class="settings-edit__trigger" (click)="changeEditAction($event)">
            <span class="sr-only">{{'words.edit' | translate}}</span>
        </button>

        <div class="settings-edit__content {{ 'settings_position_' + position }}" *ngIf="editAction" [ngClass]="{'journey-settings-overflow': settingsClass}">
            <div class="title">
                {{ 'words.edit' | translate }}
            </div>

            <app-edit-question-settings (modalOpen)="modal()" [exercise]="exercise" [question]="question"></app-edit-question-settings>
        </div>
    </div>

    <div class="settings-delete" *ngIf="displayDeleteAction" [ngClass]="{'open-settings': deleteAction === true}">
        <button class="settings-delete__trigger btn_action_delete" (click)="changeDeleteAction($event)">
            <span class="sr-only">{{ 'studio.common.button.delete' | translate }}</span>
        </button>

        <div class="settings-delete__content {{'settings_position_' + position}}" *ngIf="deleteAction">
            <div class="title">
                {{ 'words.delete' | translate }}
            </div>

            <div class="settings-block">
                <div class="settings-block__title has_tooltip">
                    <h5>{{ 'studio.course.section-content.exercise.question.delete-hint' | translate }}</h5>
                </div>

                <div class="settings-block_details">
                    <div class="row">
                        <div class="columns small-6">
                            <button class="delete" (click)="deleteQuestion()">
                                <span>{{ 'studio.common.button.delete' | translate }}</span>
                            </button>
                        </div>

                        <div class="columns small-6">
                            <button class="cancel">
                                <span>{{ 'studio.common.button.cancel' | translate }}</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div [class]="'question__content ' + questionClass">
    <h3 class="heading heading_border_none" style="margin: 0 0 1rem;">
        <span>
            {{ 'studio.course.section-content.exercise.' + question.type + '_type-explanation' | translate }}
        </span>

        <live-editor [(inputData)]="question.title"
                     [placeholder]="('words.title' | translate)"
                     (onUpdate)="updateQuestion()" style="font-size: 29px; font-weight: 600;">
        </live-editor>
    </h3>

    <div class="" style="margin: 0 0 1rem;">
        <msc-aside-editor [placeholder]="'studio.course.section-content.exercise.' + question.type + '-title' | translate"
                          [(content)]="question.description"
                          (onUpdate)="updateQuestion()">
        </msc-aside-editor>
    </div>

    <!-- FILL IN -->
    <div *ngIf="question?.type == 'fill-in'">
        <label class="choice" *ngFor="let choice of question.content.choices; let index = index; trackBy: trackByFn">
           <span class="choice__text">
                <live-editor [(inputData)]="choice.choice" [placeholder]="('studio.course.section-content.exercise.question.fill-in.placeholder-choice' | translate)" (onUpdate)="updateQuestion()"></live-editor>
            </span>

        <button class="delete" tabindex="-1" (click)="question.content.removeChoice(index); updateQuestion()"><span class="sr-only">{{'words.delete' | translate}}</span></button>
    </label>

    <div class="add-choice" (click)="question.content.addChoice(); updateQuestion()">
        {{ 'studio.course.section-content.exercise.question.fill-in.add-phrase' | translate }}
    </div>
</div>
<!-- OPEN WRITE -->
<div *ngIf="question?.type == 'open-write'">
    <textarea rows="8" [placeholder]="'studio.course.section-content.exercise.question.open-write.textarea-placeholder' | translate " disabled></textarea>
</div>
<!-- OPEN AUDIO -->
<div *ngIf="question?.type == 'open-audio'">
    <div class="audio audio__player">
        <div class="audio__state">
            <span class="audio__btn" style="background-color: #989898"></span>
            <span class="audio__spectre-container">
                <span class="audio__spectre" style="background-color: #989898"></span>
            </span>
        </div>

        <span class="audio__label">{{ 'studio.course.section-content.exercise.question.open-audio.record' | translate }}</span>
        <span class="audio__time">
            <span class="audio__puce" style="background-color: #989898"></span>
            00:00
        </span>
    </div>
</div>
<!-- LINKER -->
<div *ngIf="question?.type == 'linker'">
    <div class="linker-group" style="display: flex;">
        <div class="columns medium-6" style="display: flex; flex-direction: column;">
            <div [attr.id]="'linker-'  + linkIndex" class="choice" *ngFor="let link of question.content.links; let linkIndex = index; trackBy: trackByFn">
                <live-editor [(inputData)]="question.content.links[linkIndex]" (onUpdate)="updateQuestion()" [placeholder]="('studio.course.section-content.exercise.question.linker.label' | translate)"></live-editor>
            </div>
        </div>

        <div class="columns medium-6" style="display: flex; flex-direction: column;">
            <div [attr.id]="'linker-answer-' + choiceIndex" class="choice m-choice-answer" *ngFor="let choice of question.content.choices; let choiceIndex = index; trackBy: trackByFn" [ngStyle]="getHeight(choiceIndex)">
                <live-editor [(inputData)]="question.content.choices[choiceIndex]" (onUpdate)="updateQuestion()" [placeholder]="('studio.course.section-content.exercise.question.linker.choice' | translate)" style="width: 100%"></live-editor>

                <button class="delete" tabindex="-1" (click)="question.content.removeLink(choiceIndex); updateQuestion()">
                    <span class="sr-only">
                        {{ 'words.delete' | translate }}
                    </span>
                </button>
            </div>
        </div>
    </div>

    <div class="add-choice" (click)="question.content.addLink(); updateQuestion()">
        {{ 'studio.course.section-content.exercise.question.linker.add-link' | translate }}
    </div>
</div>
<!-- TRUE-FALSE -->
<div *ngIf="question?.type == 'true-false'">
    <div>
        <label class="choice" [ngClass]="{'choice_selected': question.param.choice}">
            <input type="checkbox" (click)="question.param.choice = true; updateQuestion()">
            <span class="choice__text">{{ question.param.type == 'yes' ? ('studio.course.section-content.exercise.question.true-false.yes' | translate ) : ('studio.course.section-content.exercise.question.true-false.true' | translate )}}</span>
        </label>

        <label class="choice" [ngClass]="{'choice_selected': !question.param.choice}">
            <input type="checkbox" (click)="question.param.choice = false; updateQuestion()">
            <span class="choice__text">{{ question.param.type == 'yes' ? ('studio.course.section-content.exercise.question.true-false.no' | translate ) : ('studio.course.section-content.exercise.question.true-false.false' | translate )}}</span>
        </label>

        <label class="choice" [ngClass]="{'checked': !question.param.choice}" *ngIf="question.param.allowNone">
            <input type="checkbox" (click)="updateQuestion()">
            <span class="choice__text">{{ 'studio.course.section-content.exercise.question.true-false.none' | translate }}</span>
        </label>
    </div>
</div>
<!-- QCM -->
<div *ngIf="question?.type == 'multiple'" [ngClass]="{'question_list-style_number': question.param.listStyle === 'number', 'question_list-style_letter': question.param.listStyle === 'letter'}">
    <label class="choice"  *ngFor="let choice of question.content.choices; let index = index; trackBy: trackByFn" [ngClass]="{'choice_selected': choice.state}">
        <input type="checkbox"  (click)="choice.state = !choice.state; updateQuestion()">
        <live-editor [(inputData)]="choice.choice" [placeholder]="('studio.course.section-content.exercise.question.multiple.placeholder-choice' | translate)" (onUpdate)="updateQuestion()" ></live-editor>
        <button class="delete" tabindex="-1" (click)="question.content.removeChoice(index); updateQuestion()"><span class="sr-only">{{'words.delete' | translate}}</span></button>
    </label>

    <div class="add-choice" (click)="question.content.addChoice(false); updateQuestion()">
        {{ 'studio.course.section-content.exercise.question.multiple.add-choice' | translate }}
    </div>
</div>
<!-- ORDERED -->
<div *ngIf="question?.type == 'ordered'">
    <label class="choice" *ngFor="let choice of question.content?.choices; let index = index; trackBy: trackByFn">
        <input type="checkbox">
        <live-editor [(inputData)]="choice.choice" (onUpdate)="updateQuestion()" [placeholder]="('studio.course.section-content.exercise.question.ordered.phrase-placeholder' | translate)" ></live-editor>
        <button class="delete" tabindex="-1" (click)="question.content.removeChoice(index); updateQuestion()"><span class="sr-only">{{ 'words.delete' | translate }}</span></button>
    </label>

    <div class="add-choice" (click)="question.content.addChoice(); updateQuestion()">
        {{ 'studio.course.section-content.exercise.question.ordered.add-phrase' | translate }}
    </div>
</div>
<!-- CATEGORY -->
<div *ngIf="question?.type == 'category'">
    <div class="category" *ngFor="let choice of question.content?.choices; let choiceIndex = index; trackBy: trackByFn">
        <div class="category__counter">
            <button class="btn btn_color_red btn_action_delete" (click)="question.content?.removeChoice(choiceIndex); updateQuestion()"><span class="sr-only">Delete</span></button>
        </div>

        <div class="category__title">
            <live-editor *ngIf="choice.type === 'text'" [(inputData)]="choice.value" (onUpdate)="updateQuestion()" [placeholder]="('studio.course.section-content.exercise.question.category.choice-placeholder' | translate)" ></live-editor>
            <media-manager [media]="choice?.media" (updateMedia)="onUpdateMediaCategory($event, choice)"></media-manager>
        </div>

        <div class="category__content">
            <label for="cat-{{indexQuestion}}-{{choiceIndex}}-{{categoryIndex}}" class="choice" *ngFor="let category of question.content?.categories; let categoryIndex = index; trackBy: trackByFn"  [class.choice_selected]="choice.category === category">
                <input id="cat-{{indexQuestion}}-{{choiceIndex}}-{{categoryIndex}}" type="radio"  [(ngModel)]="choice.category" (change)="updateQuestion()" [value]="question.content.categories[categoryIndex]">
                <live-editor [inputData]="question.content.categories[categoryIndex]" (inputDataChange)="updateCategoryChoosen($event, categoryIndex)" (onUpdate)="updateQuestion()" [placeholder]="('studio.course.section-content.exercise.question.category.category-title-placeholder' | translate)"></live-editor>
                <button class="delete" tabindex="-1" (click)="question.content?.removeCategory(categoryIndex); updateQuestion()" *ngIf="choiceIndex === 0"><span class="sr-only">{{'words.delete' | translate}}</span></button>
            </label>

            <div class="add-choice" (click)="addCategory()">
                {{ 'studio.course.section-content.exercise.question.category.add-category' | translate }}
            </div>
        </div>
    </div>

    <div class="add-category add-choice" (click)="question.content.addChoice(); updateQuestion()">
        {{ 'studio.course.section-content.exercise.question.category.add-choice' | translate }}
    </div>
</div>
<!-- PROCEDURE -->
<div *ngIf="question?.type == 'procedure'">
    <div class="procedure">
        <div class="procedure__choices" dnd-sortable-container [sortableData]="question.content.choices">
            <label class="choice" *ngFor="let choice of question.content.choices; let index = index; trackBy: trackByFn" dnd-sortable [sortableIndex]="index" (onDropSuccess)="updatePos(choice, index)" for="questionProcedureChoice{{question.id}}-{{index}}">
                <input type="checkbox">
                <live-editor [(inputData)]="choice.choice" (onUpdate)="updateQuestion()" [placeholder]="('studio.course.section-content.exercise.question.procedure.placeholder-choice' | translate)"></live-editor>

                <button class="delete" tabindex="-1" (click)="question.content.removeChoice(index); updateQuestion()">
                    <span class="sr-only">{{ 'words.delete' | translate }}</span>
                </button>
            </label>

            <div class="add-choice" (click)="question.content.addChoice(); updateQuestion()">
                {{ 'studio.course.section-content.exercise.question.procedure.add-choice' | translate }}
            </div>
        </div>
    </div>
</div>
</div>

<!--<div *ngIf="question?.type !== 'category'">-->
<!--    <div class="question__medias">-->
<!--        <div class="medias-list" style="height: 210px">-->
<!--            <div class="medias-list__item">-->
<!--                <media-manager [mini]="true" [width]="200" [height]="150" [media]="question?.media" [leftTrash]="true" (updateMedia)="onUpdateMedia($event)"></media-manager>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
<!--</div>-->

<msc-aside-question-settings *ngIf="displayAside" [question]="question" [exercise]="exercise" (onClose)="closeAside()"></msc-aside-question-settings>

<style>
    .m-choice-answer {
        display: flex;
        align-items: center;
        width: 100%;
    }

    .journey-settings {
        top: 7rem;
        right: 4rem;
    }

    .journey-settings-overflow {
        overflow: auto;
        max-height: 700px;
    }
</style>
