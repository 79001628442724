<div #mediaPreview class="library-page__preview" id="itemPreview" [class.fixed]="fixPreview" [class.blocked]="blockPreview">
    <div class="preview-library">
        <button class="leave-white" (click)="closePreview()"></button>

        <div class="preview-library__title">
            <div class="supertitle">{{ getType() === 'embed' ? getType() : (getType() | translate)}}</div>

            <h2>
				<live-editor [(inputData)]="media.title" (onUpdate)="updateMediaDetails()"></live-editor>
			</h2>
        </div>

        <div class="preview-library__media" *ngIf="media.url">
            <img *ngIf="media.type === 'IMG' && media.storage !== 'ext'" [src]="stockageUrl + media.url + '?size=1024'">
            <img *ngIf="media.type === 'IMG' && media.storage === 'ext'" [src]="media.url + '?size=1024'">

            <div *ngIf="media.type === 'VID'" class="embed">
                <iframe [src]="url" frameborder="0" title="" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
            </div>

            <div *ngIf="media.type === 'EMBED'" class="embed">
                <iframe [src]="url" width="100%" height="100%"></iframe>
            </div>

            <div *ngIf="media.type === 'DOC' || media.type === 'PDF'" class="embed">
                <iframe [src]="docUrl" width="100%" height="100%"></iframe>
            </div>
        </div>

        <button *ngIf="toUpload" class="btn" (click)="onUpdateMedia()">
            {{'studio.library.use-media' | translate}}
        </button>

        <div *ngIf="library && (media.type !== 'VID' && media.type !== 'EMBED')" style="margin: 1rem 0 0 0;">
            <button class="button btn_color_grey" (click)="downloadMedia()">
                {{ 'words.download' | translate }}
            </button>

            <a #downloadUrl [href]="urlForDownload" target="_blank"></a>
        </div>

        <div class="publish-info">
            <div *ngIf="uploadDate" class="publish-info__element picto-created">
                {{'studio.library.added-at' | translate }} {{ uploadDate | date:'mediumDate'}}
            </div>
        </div>

        <button *ngIf="availableArchive" class="settings-delete__trigger btn_action_delete" (click)="openModal(modalArchive)"> </button>
    </div>

    <ng-template #modalArchive let-modal>
        <div class="modal-header">
            <span></span>
            <button type="button" class="close" aria-label="Close" (click)="modal.close()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div style="background-color: #FFFFFF; padding: 5rem; text-align: center;">
                {{ 'words.archive' | translate }}

                <div class="actions" style="padding-top: 2rem; display: flex;">
                    <button class="btn btn_color_red" (click)="archived(modal)">
                        {{ 'studio.common.button.delete' | translate }}
                    </button>

                    <button class="btn btn_color_grey" (click)="modal.close()">
                        {{ 'studio.common.button.cancel' | translate }}
                    </button>
                </div>
            </div>
        </div>
    </ng-template>
</div>
