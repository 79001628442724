import { Component, Input } from '@angular/core';
import { Exercise, SectionContent, Section, } from '@stuplay'

@Component({
    selector: 'app-exercise',
    templateUrl: './exercise.component.html'
})
export class ExerciseComponent {
    @Input() exercise: Exercise;
    @Input() section: Section;
    @Input() sectionContent: SectionContent;
}
