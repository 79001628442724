import { Component, OnInit, EventEmitter } from '@angular/core';
import { MediaProvider, Media } from '@stuplay';
import { StorageService } from '../../utils/services/storage.service';

@Component({
    selector: 'media-library',
    templateUrl: './media-library.component.html'
})
export class MediaLibraryComponent implements OnInit {
    public tab: string = 'import';
    public toUpload: boolean = false;
    public showItemPreview: boolean = false;
    public itemToPreview: Media;
    public medias: any;
    public pagination: any;
    public me: any;
    public refresh: EventEmitter<any> = new EventEmitter();

    constructor(
        private mediaProvider: MediaProvider,
        private storageService: StorageService,
    ) { }

    ngOnInit() {
        this.me = this.storageService.get('me');
    }

    setTab(tab: string): void {
        this.showItemPreview = false;
        this.tab = tab;
    }

    redirect(destination: string): void {
        switch (destination) {
            case 'video': {
                this.setTab('video');
                this.filterMedias('video');
                break;
            }
            case 'image': {
                this.setTab('image');
                this.filterMedias('image');
                break;
            }
            case 'document': {
                this.setTab('document');
                this.filterMedias('document');
                break;
            }
            case 'sound': {
                this.setTab('sound');
                this.filterMedias('sound');
                break;
            }
            case 'embed': {
                this.setTab('embed');
                this.filterMedias('embed');
                break;
            }
        }
    }

    filterMedias(type: string): void {
        this.mediaProvider.getMedias(type).subscribe((data) => {
            this.medias = data.medias;
            this.pagination = data.pagination;
        })
    }

    archivedMedia(media: any): void {
        const archivedMedia = media;

        const index = this.medias.findIndex((data) => {
            return data.id === archivedMedia.id;
        });

        if (index !== -1) {
            this.medias.splice(index, 1);
            this.refresh.emit();
        }
    }

    showPreview(item: any): void {
        this.showItemPreview = true;
        this.itemToPreview = item;
    }

    closePreview(): void {
        this.showItemPreview = false;
    }
}
