import { Directive, ElementRef, Input, Output, EventEmitter, OnChanges, Renderer2 } from '@angular/core';

@Directive({
    selector: '[autoClose]'
})
export class AutoCloseDirective implements OnChanges {
    @Input('activeClass') activeClass: string = 'active';
    @Input('autoClose') isActive: boolean;
    @Output('autoCloseChange') isActiveChange: EventEmitter<boolean> = new EventEmitter;
    public listener: any;

    constructor(
        private element: ElementRef,
        private renderer: Renderer2,
    ) { }

    ngOnChanges() {
        if (this.detectActivation()) {
            const timeOut = setTimeout(() => {
                this.listener = this.renderer.listen('document', 'click', (event) => {
                    this.onClickOut(event);
                });
                clearTimeout(timeOut);
            }, 10);
        } else {
            if (typeof this.listener === 'function') {
                const timeOut = setTimeout(() => {
                    this.listener();
                    clearTimeout(timeOut);
                }, 10);
            }
        }
    }

    detectActivation(): boolean {
        const selector = this.element.nativeElement.className.split(' ');
        const index = selector.findIndex((select) => {
            return select === this.activeClass;
        });

        return index !== -1;
    }

    onClickOut(event: any): void {
        const selector = '.' + this.element.nativeElement.className.split(' ')[0];
        if (!event.target.closest(selector)) {
            this.isActiveChange.emit(false);
        }
    }
}
