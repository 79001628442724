import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { getParams } from '../helpers';
import { CompanyPermission } from './company-permission';
import { CompanyRole } from './company-role';
import { environment } from '../../../environments/environment';

@Injectable()
export class CompanyProvider {

    constructor(public http: HttpClient) { }

    getCompany(slug: string, params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/company/${slug}`, { params: getParams(params) });
    }

    getRoles(slug: string | number): Observable<any> {
        const response = {
            roles: {},
            permissions: {}
        };

        return this.http.get(`${environment.envVar.API_URL}/company/${slug}/roles`)
            .pipe(map((data: any) => {
                response.roles = new CompanyRole(data.roles);
                response.permissions = new CompanyPermission(data.permissions);

                return response;
            }));
    }
}
