<aside class="sidebar sidebar--modal" [ngClass]="active ? 'active' : ''" (appSidebar)="closeSidebar()">
    <div class="sidebar__container">
        <div class="sidebar__head">
            <div class="sidebar__description">
                <h1 class="sidebar__title">
                    {{ ((!journey) ? 'studio.journey.create-title' : 'studio.journey.update-title') | translate }}
                </h1>
            </div>

            <a class="sidebar__close" (click)="closeSidebar()">
                <i class="icon icon--close"></i>
            </a>
        </div>

        <form action="" class="form">
            <div class="grid">
                <div class="form__item grid__half" style="width: 100%;">
                    <label class="input input__label">
                        {{ 'words.title' | translate }}
                    </label>
                    <input class="input-label" type="text" placeholder="{{ 'words.title' | translate }}" [(ngModel)]="data.title" [ngModelOptions]="{standalone: true}">
                </div>
            </div>

            <div class="" style="overflow: hidden; text-align: center;">
                <media-manager [media]="data.media" [cover]="data.id ? ('https://static.myskillcamp.com/lxp/images/logo-empty-state-' + data.id % 7 + '.jpg') : null" [height]="230" [margin]="true" (updateMedia)="onUpdateMedia($event)"></media-manager>
            </div>

            <div class="grid between">
                <div class="form__item grid__half">
                    <label class="input select__label">{{ 'studio.course.language' | translate }}
                        <div class="dropdown-simple dropdown-simple--create-path dropdown-simple--overflow" [ngClass]="{'active': displayDropdown.languages}" [(autoClose)]="displayDropdown.languages" (click)="displayed('languages')">
                            <div class="dropdown-simple__title">
                                {{ selectedLanguage?.name }}
                                <i class="templab-icon templab-icon-arrow-ios-down"></i>
                            </div>

                            <div class="dropdown-simple__content">
                                <input class="dropdown-simple__filter" type="text" name="search" [placeholder]="'placeholder.search' | translate"
                                    [(ngModel)]="filterInput" [debounce]="500" (onDebounce)="filterLanguages($event)" (click)="$event.stopPropagation()" />
                                <div class="content-list">
                                    <div class="content-item" *ngFor="let language of filteredLanguages" (click)="selectLanguage(language)">
                                        {{ language.name }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </label>
                </div>

                <div class="form__item grid__half">
                    <label class="input select__label">
                        {{ 'words.duration' | translate }}

                        <div class="" style="display: flex;">
                            <input class="input-label" type="text" [(ngModel)]="duration" [ngModelOptions]="{standalone: true}" style="width: 40%;">

                            <div class="select" style="width: 25%; margin: 0.25rem 0 0 1.5rem;">
                                <select class="select__field" [(ngModel)]="type" [ngModelOptions]="{standalone: true}">
                                    <option value="d">{{ 'words.prefix-day' | translate }}</option>
                                    <option value="h">{{ 'words.prefix-hour' | translate }}</option>
                                    <option value="m">{{ 'words.prefix-min' | translate }}</option>
                                </select>
                            </div>
                        </div>
                    </label>
                </div>
            </div>

            <div class="grid between">
                <div class="form__item grid__half">
                    <ng-container *ngIf="journey?.isAttachedToACollection">
                        <label class="input select__label">
                            {{ 'studio.section-content.label.company-ownership' | translate }}
                            <div class="tooltip-custom">
                                <input class="input-label" type="text" [value]="selectedCompany?.name" disabled>
                                <div class="tooltip__info">{{ 'tooltip.campany-ownership-update' | translate }}</div>
                            </div>
                        </label>
                    </ng-container>

                    <ng-container *ngIf="!journey?.isAttachedToACollection">
                        <label class="input select__label">{{ 'studio.section-content.label.company-ownership' | translate }}
                            <div class="dropdown-simple dropdown-simple--create-path dropdown-simple--overflow" [ngClass]="{'active': displayDropdown.companies}" [(autoClose)]="displayDropdown.companies" (click)="displayed('companies')">
                                <div class="dropdown-simple__title">
                                    {{ selectedCompany ? selectedCompany.name : 'studio.section-content.select-company' | translate }}
                                    <i class="templab-icon templab-icon-arrow-ios-down"></i>
                                </div>
    
                                <div class="dropdown-simple__content">
                                    <div class="content-list">
                                        <div class="content-item" *ngFor="let company of companiesAuthor" (click)="selectCompany(company)">
                                            {{ company.name }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </label>
                    </ng-container>
                </div>

                <div class="form__item grid__half">
                    <label class="input select__label">{{ 'studio.journey.type' | translate }}
                        <div class="select">
                            <select class="select__field" [(ngModel)]="data.type" [ngModelOptions]="{standalone: true}">
                                <option value="digital-learning">{{'type.digital-learning' | translate}}</option>
                                <option value="blended-learning">{{'type.blended-learning' | translate}}</option>
                                <option value="presentiel">{{'type.presentiel' | translate}}</option>
                                <option value="distanciel">{{'type.distanciel' | translate}}</option>
                            </select>
                        </div>
                    </label>
                </div>
            </div>

            <div class="grid">
                <div class="form__item grid__full">
                    <label class="input select__label">
                        {{ 'words.description' | translate }}
                    </label>

                    <msc-froala [plugins]="'xmini'" [buttons]="'xmini'" [height]="200" [(content)]="data.description"></msc-froala>
                </div>
            </div>

            <div class="">
                <p>
                    <span style="font-weight: 600;">{{ 'studio.journey.conditions' | translate }}</span>
                    <span style="font-size: 0.85rem;">({{ 'words.optional' | translate }})</span>
                </p>

                <div class="form__item grid__half">
                    <label class="input">
                        <input type="checkbox" class="input-checkbox" [(ngModel)]="limitActive" [ngModelOptions]="{standalone: true}">
                        {{ 'studio.journey.users-limit' | translate }}
                    </label>
                </div>

                <div class="form__item grid__half" *ngIf="limitActive">
                    <label class="input select__label">
                        {{ 'studio.journey.users-limit.title' | translate }}
                    </label>
                    <input class="input-label" type="text" placeholder="{{ 'studio.journey.users-limit.title' | translate }}" [(ngModel)]="data.usersLimit" [ngModelOptions]="{standalone: true}">
                </div>

                <div class="form__item grid__half">
                    <label class="input">
                        <input type="checkbox" class="input-checkbox" [(ngModel)]="data.autoReminders" [ngModelOptions]="{standalone: true}">
                        {{ 'studio.journey.auto-reminders' | translate }}
                    </label>
                </div>

                <div class="form__item grid__half">
                    <label class="input">
                        <input type="checkbox" class="input-checkbox" [(ngModel)]="data.isCertificable" [ngModelOptions]="{standalone: true}">
                        {{ 'words.is-certificable' | translate }}
                    </label>
                </div>
            </div>
        </form>
    </div>

    <footer class="sidebar__footer">
        <div class="sidebar__btn">
            <div class="sidebar__btn-right grid">
                <a *ngIf="!journey" (click)="createJourney()" class="button button--small button--color">
                    {{ 'studio.instance.save' | translate }}
                </a>

                <a *ngIf="journey" (click)="updateJourney()" class="button button--small button--color">
                    {{ 'studio.instance.modify' | translate }}
                </a>
            </div>
        </div>
    </footer>
</aside>

<style>
    .grid__full {
        width: 100%;
    }

    .sidebar .form {
        margin: 1rem 0 0 0 !important;
    }
</style>
