import { Component, EventEmitter, Input, Output, OnInit, ElementRef } from '@angular/core';

@Component({
	selector: 'live-editor',
	templateUrl: './live-editor.component.html'
})
export class LiveEditorComponent implements OnInit {
	@Output() onUpdate = new EventEmitter();
	@Input() placeholder;
	@Input() inputData;
	@Input() rows: number = 1;
	@Input() line: string = 'simple';
	@Input() disabled: boolean = false;
	@Input() mode: string = 'normal';
	@Output('inputDataChange') update = new EventEmitter<string>();
	enterKeydown: boolean = false;
	focused: boolean = false;

	constructor(private element: ElementRef) {}

	ngOnInit() {
		if (this.line === 'multi') {
			this.enterKeydown = true;
		}

		if (this.mode !== 'normal') {
			this.element.nativeElement.children[0].children[0].focus();
		}
	}

	onFocus(): void {
		this.focused = true;
	}

	onBlur(): void {
		this.update.emit(this.inputData);
		this.focused = false;
		this.onUpdate.emit();
	}

	isEmpty(): any {
		return !this.inputData;
	}

	getData(): string {
		return this.isEmpty() ? this.placeholder : this.inputData;
	}

	getClass(): string {
		return (this.mode !== 'normal') ? 'align-center' : '';
	}
}
