<img class="author__image avatar" [src]="post.account.picture+'?size=48'">

<span class="author__name">
	{{ post.account.name }}

	<span *ngIf= "isFormer">
        {{ 'studio.timeline.post.former' | translate }}
    </span>
</span>

<span class="date">
    {{ post.created_at | timeAgo }}
</span>

<div class="post__content">
	<timeline-show-content [content]="post.content"></timeline-show-content>

	<msc-link-manager [content]="post.content.message"></msc-link-manager>
</div>

<div class="post__comments">
	<div  class="comment" *ngFor="let comment of post?.comments">
		<msc-comment [comment]='comment' (onDelete)="deleteComment($event)"></msc-comment>
	</div>

	<form #postForm class="new-post">
		<timeline-add-content (addContent)="addComment($event, post.id, postForm)" [placeholder]="('studio.timeline.placeholder.answer' | translate)"></timeline-add-content>
	</form>
</div>

<timeline-delete-content (deleteContent)="onDeletePost()"></timeline-delete-content>
