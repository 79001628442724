import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { getParams } from '../helpers';
import { environment } from 'src/environments/environment';

@Injectable()
export class UtilityProvider {

    constructor(public http: HttpClient) {}

    getLinkPreview(url: string): Observable<any> {
        const params = {
            url: url
        };

        return this.http.get(`${environment.envVar.API_URL}/link-preview`, {params: getParams(params)});
    }

    getVimeoReady(id: number, version: any): Observable<any> {
        const params = {
            url: 'https://vimeo.com/' + id,
            version: version
        };

        return this.http.get(`https://vimeo.com/api/oembed.json`, {params: getParams(params)});
            // .retryWhen((errors) => errors.delay(10000));
    }
}
