import { Component, Input } from '@angular/core';
import { SelfAssessment } from '@stuplay'

@Component({
    selector: 'app-self-assessment',
    templateUrl: './self-assessment.component.html'
})
export class SelfAssessmentComponent {
    @Input() selfAssessment: SelfAssessment;
}
