<header class="content__head">
    <div class="container grid between middle">
        <div class="content__group-search">
            <div class="search">
                <label class="search__label">
                    <i class="search__icon icon icon--search"></i>
                </label>
                <input class="search__input" type="text" [placeholder]="'words.search' | translate" [(ngModel)]="query" [debounce]="500" (onDebounce)="sendSearch()">
                <i class="icon icon--close" (click)="resetSearch()"></i>
            </div>
        </div>

        <div class="content__group-buttons">
            <ng-container *ngIf="isQualityMessageVisible && countsQuality">
                <div class="missing-ownership-banner">
                    <i class="templab-icon templab-icon-alert-circle-1"></i>
                    <span>{{ 'studio.data-quality.info-message' | translate: {value: countsQuality} }} <a (click)="onModalOpen(modalOwnershipTable)">{{ 'studio.data-quality.info-message.link' | translate }}</a></span>
                </div>
            </ng-container>
    
            <div class="content__button" *ngIf="type === 'folder' && !sharedCourses">
                <a class="button button--border" (click)="createFolder()">
                    <i class="icon icon--folder"></i>
                    {{ 'studio.folder.create-folder' | translate }}
                </a>
    
                <a class="button button--color" (click)="createCourse()">
                    <i class="icon icon--modul"></i>
                    {{ 'studio.template.create' | translate }}
                </a>
            </div>
        </div>
        
        <div class="content__instruction" *ngIf="type === 'instance'">
<!--            <img (click)="openAsideHelp('postmodule')" src="https://static.myskillcamp.com/images/guidance/question-mark-circle.svg"/>-->

            <div class="content__button">
                <a class="button button--border" (click)="sendPublish()">
                    <i class="icon icon--publish"></i>
                    {{ 'studio.instance.publish' | translate }}
                </a>
                <a class="button button--color" (click)="sendCreate()">
                    {{ 'studio.instance.create' | translate }}
                </a>
            </div>
        </div>

        <div class="content__instruction" *ngIf="type === 'journey'">
<!--            <img (click)="openAsideHelp('journey')" src="https://static.myskillcamp.com/images/guidance/question-mark-circle.svg"/>-->

            <div class="content__button">
                <a class="button button--color" (click)="sendCreate()" *ngIf="!publishJourneyActive">
                    {{ 'studio.journey.create' | translate }}
                </a>

                <a class="button button--border" (click)="sendPublish()" *ngIf="publishJourneyActive">
                    <i class="icon icon--publish"></i>
                    {{ 'studio.journey.publish' | translate }}
                </a>
            </div>
        </div>
    </div>
</header>

<div class="breadcrumb" *ngIf="type === 'folder' && folderId !== 0">
    <div class="container">
        <a class="breadcrumb__link" (click)="navigateTo('home')">
            {{ 'studio.template.title' | translate }}
        </a>

        <span *ngIf="folder">
            <a class="breadcrumb__link" (click)="navigateTo('home', parent.data.id)" *ngFor="let parent of folder.parents.reverse()">
                {{ (parent.title !== '') ? parent.title : ('words.folder-without-title' | translate) }}
            </a>

            <span class="breadcrumb__link">
                {{ (folder.folder.title !== '') ? folder.folder.title : ('words.folder-without-title' | translate) }}
            </span>
        </span>
    </div>
</div>

<div class="breadcrumb" *ngIf="sharedCourses">
    <div class="container">
        <a class="breadcrumb__link" (click)="navigateTo('home')">
            {{ 'studio.template.title' | translate }}
        </a>

        <span>
            <span class="breadcrumb__link">
                {{ 'studio.company-content.folder-title' | translate }}
            </span>
        </span>
    </div>
</div>

<div class="breadcrumb" *ngIf="type === 'instance' && breadcrumb">
    <div class="container">
        <a class="breadcrumb__link" (click)="navigateTo('instance')">{{ 'studio.instance.title' | translate }}</a>
        <a class="breadcrumb__link">{{ breadcrumb }}</a>
    </div>
</div>

<div class="breadcrumb" *ngIf="type === 'journey' && breadcrumb">
    <div class="container">
        <a class="breadcrumb__link" (click)="navigateTo('journey')">{{ 'studio.journey.my-journey' | translate }}</a>
        <span class="breadcrumb__link">{{ breadcrumb }}</span>
    </div>
</div>

<ng-template #modalOwnershipInfo let-modal>
    <ng-container [ngSwitch]="modalOwnershipInfoStep">
        <div class="modal-ownership-info">
            <ng-container *ngSwitchCase="1">
                <div class="modal-header">
                    <img src="https://static.myskillcamp.com/images/studio/ownership-alert.png"/>
                </div>
                <div class="modal-body">
                    <h3>{{ 'studio.modal.data-quality.first-step.title' | translate }}</h3>
                    <p [innerHTML]="'studio.modal.data-quality.first-step.text' | translate"></p>
                </div>
                <div class="modal-footer">
                    <a (click)="onModalDismiss()">{{ 'studio.modal.data-quality.button.ask-again' | translate }}</a>
                    <button class="btn" (click)="onModalOpen(modalOwnershipTable)">{{ 'words.next' | translate }}</button>
                </div>
            </ng-container>

            <ng-container *ngSwitchCase="2">
                <div class="modal-header">
                    <img src="https://static.myskillcamp.com/images/studio/ownership-success.png"/>
                </div>
                <div class="modal-body">
                    <h3>{{ 'studio.modal.data-quality.final-step.title' | translate }}</h3>
                    <p>{{ 'studio.modal.data-quality.final-step.text' | translate }}</p>
                </div>
                <div class="modal-footer">
                    <button class="btn" (click)="onModalClose()">{{ 'words.close' | translate }}</button>
                </div>
            </ng-container>
        </div>
    </ng-container>
</ng-template>

<ng-template #modalOwnershipTable let-modal>
    <msc-table-company-ownership-component
        [rows]="qualityContents$ | async"
        [meta]="qualityContentsMeta$ | async"
        [availableCompanies]="availableCompanies$ | async"
        [loading]="loading$ | async"
        (assignEvent)="onAssignCompany($event)" 
        (dismissEvent)="onModalDismiss()"
        (updateEvent)="onUpdate($event)">
    </msc-table-company-ownership-component>
</ng-template>

<style>
    .button--color .icon {
        font-size: 18px;
        margin-right: .5rem;
    }
</style>
