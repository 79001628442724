import { Injectable, EventEmitter } from '@angular/core';

@Injectable()
export class EmitterService {
    dropdownActive: EventEmitter<any> = new EventEmitter();
    moveTo: EventEmitter<any> = new EventEmitter();
    activeHelp: EventEmitter<any> = new EventEmitter();
    loadingExternalResource: EventEmitter<any> = new EventEmitter();
    refreshCondition: EventEmitter<any> = new EventEmitter();
    refreshCourse: EventEmitter<any> = new EventEmitter();
    unableToEdit: EventEmitter<any> = new EventEmitter();
    deleteDisabledEmitter: EventEmitter<any> = new EventEmitter();
}
