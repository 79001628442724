import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { EmitterService } from 'src/app/utils/services/emitter.service';
import { StorageService } from 'src/app/utils/services/storage.service';

@Component({
    selector: 'instance-sidebar-menu',
    templateUrl: './instance-sidebar-menu.component.html'
})
export class InstanceSidebarMenuComponent implements OnInit, OnDestroy {
    @Output() close: EventEmitter<any> = new EventEmitter;
    @Output() publish: EventEmitter<any> = new EventEmitter;
    @Output() unpublish: EventEmitter<any> = new EventEmitter;
    @Output() clone: EventEmitter<any> = new EventEmitter;
    @Output() realign: EventEmitter<any> = new EventEmitter();
    @Output() delete: EventEmitter<any> = new EventEmitter;
    public active: boolean = false;
    public company;
    public permissions;
    public disableDeleteButton;
    public disableDeleteButtonSubscription;

    constructor(private storageService: StorageService, private emitterService: EmitterService) {
        this.disableDeleteButtonSubscription = this.emitterService.deleteDisabledEmitter.subscribe((disabled) => {
            this.disableDeleteButton = disabled;
        });
    }

    ngOnInit() {
        this.company = this.storageService.get('company');
        this.permissions = this.storageService.get('permissions');

        const timeOut = setTimeout(() => {
            this.active = true;
            clearTimeout(timeOut);
        }, 100);
    }

    closeSidebar(): void {
        this.close.emit(true);
    }

    publishInstance(): void {
        this.publish.emit(true);
    }

    unpublishInstance(): void {
        this.unpublish.emit(true);
    }

    cloneInstance(): void {
        this.clone.emit(true);
    }

    realignInstance(): void {
        this.realign.emit(true);
    }

    deleteInstance(): void {
        this.delete.emit(true);
    }

    ngOnDestroy() {
        this.disableDeleteButtonSubscription.unsubscribe()
    }
}
