<div class="settings settings-delete" [ngClass]="{'settings-open': isOpen }">
    <button class="settings__trigger" (click)="toggleSettings()">
        <span class="sr-only">{{'words.delete' | translate}}</span>
    </button>
    <div class="settings__content">
        <div class="settings-block">
            <div class="settings-block__title">
                <h5>{{'words.delete' | translate}}</h5>
            </div>
            <div class="settings-block_details">
                <button class="delete" (click)="onDeleteContent()">
                    <span>{{'words.yes' | translate}}</span>
                </button>
                <button class="cancel" (click)="toggleSettings()">
                    <span>{{'words.no' | translate}}</span>
                </button>
            </div>
        </div>
    </div>
</div>