import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Course, Section } from '@stuplay';
import { ErrorCatcherService } from '../../../utils/services/error-catcher.service'
import { StorageService } from '../../../utils/services/storage.service';

@Component({
    selector: 'app-section',
    templateUrl: './section.component.html'
})
export class SectionComponent implements OnInit {
    course: Course;
    section: Section;

    constructor(
        private route: ActivatedRoute,
        private errorCatcher: ErrorCatcherService,
        private storageService: StorageService,
    ) { }

    ngOnInit() {
        this.course = this.storageService.getAndSet('course', this.route.parent.snapshot.data['course']);
        this.errorCatcher.setErrorsOnInit(this.course);

        this.route.params.subscribe(() => {
            this.section = this.route.snapshot.data['course'].sections.find(({ id }) => id === parseInt(this.route.snapshot.params['sectionId'], 10));
        });
    }

    reloadErrors(course: Course) {
        this.errorCatcher.setErrorsOnInit(course);
    }
}
