import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { getParams } from "../helpers";
import { environment } from 'src/environments/environment';

@Injectable()
export class EventsProvider {
    defaultLang: string;

    constructor(public http: HttpClient) {}

    getEventsByDate(templateIdChosen?: number, langs?: string, q?: string) {
        return this.http.get(`${environment.envVar.API_URL}/studio/instances/events`, {params: getParams({templateId: templateIdChosen, language: langs, q: q})});
    }
}
