import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators'; 
import { Auth } from './auth';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthProvider {

    constructor(private http: HttpClient) {}

    login(credentials: any): Observable<Auth> {
        return this.http.post(`${environment.envVar.AUTH_URL}/api/idp/login`, credentials).pipe(
            map(data => new Auth(data))
        );
    }

    logout(): Observable<any> {
        return this.http.post(`${environment.envVar.AUTH_URL}/api/idp/logout`, {});
    }

    refresh(refreshToken: string): Observable<Auth> {
        const params = {
            refresh_token: refreshToken
        };

        return this.http.post(`${environment.envVar.AUTH_URL}/api/idp/token`, params).pipe(
            map(data => new Auth(data))
        );
    }

    authenticateMethod(params: any): Observable<any> {
        return this.http.post(`${environment.envVar.CLOUD_URL}/auth/method`, params);
    }
}
