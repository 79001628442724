<div class="templab-library">
    <h3 class="templab-library__back" (click)="onClose.emit()">
        <i class="templab-icon templab-icon-arrow-ios-left"></i>
        {{ 'words.table-contents' | translate }}
    </h3>

    <ul class="templab-library__list">
        <li *ngIf="!edit || action === 'content'">
            <button class="templab-btn templab-btn-activity" [ngClass]="{'templab-btn-activity--active': action === 'content'}" (click)="select('content')">
                <i class="templab-icon templab-icon-content"></i>

                <p>{{ 'words.existing-content' | translate }}</p>
            </button>
        </li>

        <li *ngIf="!edit || action === 'activity'">
            <button class="templab-btn templab-btn-activity" [ngClass]="{'templab-btn-activity--active': action === 'activity'}" (click)="select('activity')">
                <i class="templab-icon templab-icon-grid"></i>

                <p>{{ 'words.create-activity' | translate }}</p>
            </button>
        </li>

        <li *ngIf="!edit || action === 'event'">
            <button class="templab-btn templab-btn-activity" [ngClass]="{'templab-btn-activity--active': action === 'event'}" (click)="selectEvent()">
                <msc-loader [loading]="loadingCreateEvent"></msc-loader>
                <i *ngIf="!loadingCreateEvent" class="templab-icon templab-icon-calendar"></i>

                <p *ngIf="!loadingCreateEvent">{{ 'words.plan-event' | translate }}</p>
            </button>
        </li>

        <li *ngIf="!edit || action === 'link'">
            <button class="templab-btn templab-btn-activity" [ngClass]="{'templab-btn-activity--active': action === 'link'}" (click)="select('link')">
                <i class="templab-icon templab-icon-pin-2"></i>

                <p>{{ 'words.pin-link' | translate }}</p>
            </button>
        </li>
    </ul>

    <div class="templab-library__main">
        <journey-library-content *ngIf="action === 'content'"
                                 [journey]="journey"
                                 [content]="content"
                                 (onSave)="addCourse($event)"
                                 (onEdit)="updateCourse($event)">
        </journey-library-content>

        <journey-library-activity *ngIf="action === 'activity'"
                                  [journey]="journey"
                                  [content]="content"
                                  (add)="addContent($event)"
                                  (edit)="refresh()">
        </journey-library-activity>

        <journey-library-event *ngIf="action === 'event'"
                               [journey]="journey"
                               [content]="content"
                               [event]="event"
                               [sectionContent]="sectionContent"
                               (add)="createEvent($event)"
                               (edit)="updateEvent($event)">
        </journey-library-event>

        <journey-library-link *ngIf="action === 'link'"
                              [journey]="journey"
                              [content]="content"
                              (onSave)="addPtmc($event)"
                              (onRefresh)="refresh()">
        </journey-library-link>
    </div>
</div>
