import { Component, Input } from '@angular/core';
import { Media } from '@stuplay';
import { environment } from '../../../../environments/environment';

@Component({
    selector: 'media-none',
    templateUrl: './none.component.html',
    styleUrls: ['./none.component.less']
})
export class MediaNoneComponent {
    @Input() media: Media;
    @Input() size: number;
    public stockageUrl: string = environment.envVar.STORAGE_URL;
}
