import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'msc-aside-editor',
    templateUrl: './aside-editor.component.html',
    styleUrls: ['./aside-editor.component.scss']
})
export class AsideEditorComponent implements OnInit {
    @Input() plugins: any = 'normal';
    @Input() placeholder: string;
    @Input() content: string;
    @Input() title: string;
    @Input() disable: boolean = false;
    @Output() contentChange: EventEmitter<string> = new EventEmitter;
    @Output() onUpdate: EventEmitter<any> = new EventEmitter;
    public display: boolean = false;

    constructor() {}

    ngOnInit() {}

    edit(): void {
        if (!this.disable) {
            this.display = true;
        }
    }

    update(): void {
        this.contentChange.emit(this.content);
        this.onUpdate.emit();
    }

    close(): void {
        this.display = false;
    }
}
