import { Component, Input, Output, EventEmitter, OnInit, OnChanges } from '@angular/core';
import { Media, MediaProvider } from '@stuplay';
import * as moment from 'moment';
import { TranslationService } from '../../services/translation.service';
import { SafeResourceUrl } from "@angular/platform-browser";
import { EmitterService } from "../../services/emitter.service";
import { ExternalAppsService } from "../../services/external-apps.service";
import { StorageService } from "../../services/storage.service";

@Component({
    selector: 'library-folders',
    templateUrl: './library-folders.component.html',
    styleUrls: ['./library-folders.component.less']
})
export class LibraryFoldersComponent implements OnInit, OnChanges {
    @Input() item;
    @Input() medias: Media[];
    @Input() query: string;
    @Input() orderBy: string;
    @Input() show: string;
    @Input() currentUser: any;
    @Input() pagination: any;
    @Input() toUpload: boolean;
    @Input() refresh: EventEmitter<any> = new EventEmitter();
    @Output() updateMedia = new EventEmitter();
    @Output() showItemPreview = new EventEmitter();
    public me: any;
    public company: any;
    public splittedMedias: any[] = [];
    public dates: any[] = [];
    public type: string;
    public processing: boolean = false;

    constructor(
        private mediaProvider: MediaProvider,
        private translationService: TranslationService,
        private emitterService: EmitterService,
        private externalAppsService: ExternalAppsService,
        private storageService: StorageService,
    ) { }

    ngOnInit() {
        this.refresh.subscribe(() => {
            this.splittedMedias = [];
            this.splitByDate(this.medias);
        });
        this.me = this.storageService.get('me');
        this.company = this.storageService.get('company');
    }

    ngOnChanges() {
        this.splittedMedias = [];
        this.dates = [];
        if (this.medias && this.currentUser) {
            this.type = this.setType();
            this.splitByDate(this.medias);
        }
    }

    showPreview(item: any): void {
        this.showItemPreview.emit(item);
    }

    onUpdatedMedia(media: any): void {
        this.updateMedia.emit(media);
    }

    setDates(): void {
        this.dates = [];
        this.splittedMedias.forEach(item => {
            this.dates.push(moment(item.medias[0].createdAt).locale(this.currentUser.language.code));
        });
    }

    setType(): string {
        if (this.medias && this.medias.length > 0) {
            switch (this.medias[0].type) {
                case 'IMG': {
                    return 'image';
                }
                case 'VID': {
                    return 'video';
                }
                case 'EMBED': {
                    return 'embed';
                }
                case 'SND': {
                    return 'sound';
                }
                default: {
                    return 'document';
                }
            }
        }
    }

    splitByDate(medias: any): void {
        medias.forEach((media, index) => {
            if ((index === 0 && this.splittedMedias.length === 0) || index !== 0 && moment(media.createdAt).month() !== moment(medias[index - 1].createdAt).month()) {
                this.splittedMedias.push({
                    date: {
                        month: moment(media.createdAt).month() + 1,
                        year: moment(media.createdAt).year()
                    },
                    medias: [media]
                })
            } else {
                const object = this.splittedMedias.find((item) => item.date.month === moment(media.createdAt).month() + 1 && item.date.year === moment(media.createdAt).year());
                if (object) {
                    object.medias.push(media);
                }
            }
        });
        this.setDates();
    }

    showMore(): void {
        this.processing = true;
        this.mediaProvider.loadMore(this.type, this.pagination.currentPage + 1).subscribe((data) => {
            this.splitByDate(data.medias);
            this.pagination = data.pagination;
            this.processing = false;
        })
    }

    openAsideHelp(type: string): any {
        this.emitterService.activeHelp.emit({ type: type, feature: 'media' });
    }
}
