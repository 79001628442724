import { Observable } from "rxjs";
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { cleanObject } from "../../../../helpers";
import { environment } from "src/environments/environment";

export class CheatSheet {
    cheatsheetId: number;
    content: any; // To translate
    title: string;
    id: number;

    constructor(cheatsheet: any, private http: HttpClient) {
        this.extends(cheatsheet);
    }

    extends(cheatsheet: any): CheatSheet {
        Object.assign(this, cheatsheet);
        return this;
    }

    update(): Observable<CheatSheet> {
        return this.http.put(`${environment.envVar.API_URL}/studio/cheatsheet/${this.id}`, cleanObject(this))
            .pipe(map(res => this.extends(res)));
    }
}
