<div class="embed" *ngIf="!cloud">
    <iframe #iframeScorm [src]="url" width="100%" height="100%"></iframe>
</div>

<div *ngIf="cloud">
    <button *ngIf="cloudStatus === 'done'" class="btn" (click)="launchScormCloud()">
        <div class="lds-ring" *ngIf="processing">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
        <span>{{ 'scorm.launch-scorm' | translate }}</span>
    </button>

    <div *ngIf="cloudStatus === 'pending'">
        <div style="text-align: center;">{{ message + '...' }}</div>
        <div class="scorm__progress-bar">
            <span [ngStyle]="{'width': progress+'%'}"></span>
        </div>
    </div>
</div>
