import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Media } from '../../../media';
import { ProjectAnswerMedia } from "./project-answer-media";
import { cleanObject } from "../../../helpers";
import { environment } from 'src/environments/environment';

export class ProjectAnswer {
    id: number;
    accountId: number;
    sectionContentId: number;
    media?: Media;
    content?: string;
    info: string;
    isCorrected: boolean;
    ratio: number;
    feedback: string;
    createdAt: number;
    updatedAt: number;
    deletedAt: number;
    projectAnswerMedia: ProjectAnswerMedia[];

    constructor(projectAnswer: any, protected http: HttpClient) {
        this.extends(projectAnswer);
    }

    extends(projectAnswer: any): ProjectAnswer {
        Object.assign(this, projectAnswer);
        if (this.projectAnswerMedia && this.projectAnswerMedia.length > 0) {
            this.projectAnswerMedia = this.projectAnswerMedia.map((projectAnswerMedia) => new ProjectAnswerMedia(projectAnswerMedia, this.http));
        }
        return this;
    }

    update(): Observable<ProjectAnswer> {
        return this.http.put(`${environment.envVar.API_URL}/session/project-answer/${this.id}`, cleanObject(this))
            .pipe(map(res => this.extends(res)));
    }
}