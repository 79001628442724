import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Section, Course, SectionContent, ExternalLinkProvider } from '@stuplay';
import { ErrorCatcherService } from '../../../../utils/services/error-catcher.service';
import { TranslationService } from '../../../../utils/services/translation.service';
import { NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { ExternalAppsService } from '../../../../utils/services/external-apps.service';
import { StorageService } from '../../../../utils/services/storage.service';
import { EmitterService } from '../../../../utils/services/emitter.service';

@Component({
    selector: 'add-section-content',
    templateUrl: './add-section-content.component.html',
    styleUrls: ['./add-section-content.component.less']
})
export class AddSectionContentComponent implements OnInit {
    @Input() course: Course;
    @Input() section: Section;
    @Input() position: number = 0;
    @Input() open: boolean = false;
    private company: any;
    public display: boolean = false;
    me: any;
    error: any;
    modalAction: boolean = false;
    currentLang: string;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private errorCatcher: ErrorCatcherService,
        private translationService: TranslationService,
        private modalService: NgbModal,
        private externalAppsService: ExternalAppsService,
        private storageService: StorageService,
        private emitterService: EmitterService,
        private externalLinkProvider: ExternalLinkProvider,
    ) { }

    ngOnInit() {
        this.modalAction = (!this.section.sectionContents || this.section.sectionContents.length === 0 || this.open);
        this.me = this.storageService.get('me');
        this.company = this.storageService.get('company');
    }

    openModal(content: any, options: NgbModalOptions = { size: 'xl', centered: true, backdropClass: 'backdrop-custom' }) {
        this.modalService.open(content, options);
    }

    changeModalAction(): void {
        this.modalAction = !this.modalAction;

        if (this.modalAction) {
            this.scroll('as-' + this.position, 300);
        }
    }

    blinkTo(id: any): void {
        this.router.navigate(['..', this.section.id], { relativeTo: this.route, fragment: 'sc-' + id })
        this.scroll('sc-' + id);
    }

    scroll(id: string, time: number = 150): void {
        const timeOut = setTimeout(function() {
            const element: any = document.getElementById(id);
            const params: any = {
                block: 'start',
                inline: 'start',
                behavior: 'smooth'
            };
            element.scrollIntoView(params);
            clearTimeout(timeOut);
        }, time);
    }

    findSectionContentId(): number {
        return this.section.sectionContents.find(finalSectionContent => finalSectionContent.position === this.position).id;
    }

    findSectionContent(): SectionContent {
        return this.section.sectionContents.find(finalSectionContent => finalSectionContent.position === this.position);
    }

    addProject(): void {
        this.section.addContent(this.course.id, 'project', {
            type: 'document',
            position: this.position
        }).subscribe(() => {
            this.errorCatcher.setErrors(this.findSectionContent());
            this.blinkTo(this.findSectionContentId());
            this.processPosition();
            this.updateSectionContent();
        }, (error) => {
            this.error = error
        });
        this.modalAction = false;
    }

    addSelfAssessment(): void {
        this.section.addContent(this.course.id, 'selfassessment', {
            type: 'document',
            position: this.position
        }).subscribe(() => {
            this.errorCatcher.setErrors(this.findSectionContent());
            this.blinkTo(this.findSectionContentId());
            this.processPosition();
        }, (error) => {
            this.error = error
        });
        this.modalAction = false;
    }

    addExercise(): void {
        this.section.addContent(this.course.id, 'exercise', {
            position: this.position
        }).subscribe(() => {
            this.errorCatcher.setErrors(this.findSectionContent());
            this.blinkTo(this.findSectionContentId());
            this.processPosition();
        }, (error) => {
            this.error = error
        });
        this.modalAction = false;
    }

    addCheatSheet(): void {
        this.section.addContent(this.course.id, 'cheatsheet', {
            position: this.position
        }).subscribe(() => {
            this.errorCatcher.setErrors(this.findSectionContent());
            this.blinkTo(this.findSectionContentId());
            this.processPosition();
        }, (error) => {
            this.error = error
        });
        this.modalAction = false;
    }

    addEvent(): void {
        const dst = moment().isDST();

        this.section.addContent(this.course.id, 'event', {
            type: 'place',
            position: this.position,
            languageId: this.course.languageId,
            dst: dst
        }).subscribe(() => {
            this.errorCatcher.setErrors(this.findSectionContent());
            this.blinkTo(this.findSectionContentId());
            this.processPosition();
            this.updateSectionContent();
        }, (error) => {
            this.error = error;
        });

        this.modalAction = false;
    }

    addMedia(media: any): void {
        this.section.addContent(this.course.id, 'media', {
            contextId: media.id,
            position: this.position
        }).subscribe(() => {
            this.errorCatcher.setErrors(this.findSectionContent());
            this.blinkTo(this.findSectionContentId());
            this.processPosition();
            this.updateSectionContent();
        }, (error) => {
            this.error = error;
        });

        this.modalAction = false;
    }

    addExchange(): void {
        this.section.addContent(this.course.id, 'exchange', {
            position: this.position
        }).subscribe(() => {
            this.errorCatcher.setErrors(this.findSectionContent());
            this.blinkTo(this.findSectionContentId());
            this.processPosition();
        }, (error) => {
            this.error = error;
        });
        this.modalAction = false;
    }

    addScorm(scorm: any): void {
        this.section.addContent(this.course.id, 'scormcloud', {
            contextId: scorm,
            position: this.position
        }).subscribe(() => {
            this.processPosition();
        }, (error) => {
            this.error = error;
        });
        this.modalAction = false;
    }

    addCertificate(): void {
        this.section.addContent(this.course.id, 'certificate', {
            position: this.position,
            type: 'certificate',
            sendEmail: true
        }).subscribe(() => {
            this.errorCatcher.setErrors(this.findSectionContent());
            this.blinkTo(this.findSectionContentId());
            this.processPosition();
            this.updateSectionContent();
        }, (error) => {
            this.error = error;
        });
    }

    addLti(lti: any): void {
        const params = {
            company_id: this.company.id,
            type: 'lti',
            name: lti.name,
            description: lti.description,
            url: lti.url,
            redirect_url: lti.redirectUrl,
            key: lti.key,
            secret: lti.secret,
            names: lti.names,
            anonymize: lti.anonymize,
            mode: lti.mode
        };

        this.externalLinkProvider.addExternalLink(params).subscribe((data) => {
            this.section.addContent(this.course.id, 'external_link', {
                position: this.position,
                contextId: data.id
            }).subscribe(() => {
                this.processPosition();
                const sectionContent: SectionContent = this.findSectionContent();
                this.errorCatcher.setErrors(sectionContent);
                this.blinkTo(this.findSectionContentId());

                sectionContent.title = lti.name;
                sectionContent.update();
            }, (error) => {
                this.error = error;
            });
        });

        this.display = false;
    }

    updateSectionContent(): void {
        const sectionContent = this.findSectionContent();
        sectionContent.update().subscribe((sectionContent) => {
            this.emitterService.refreshCourse.emit(true);
            this.errorCatcher.setErrors(sectionContent);
        },
            (error) => {
                this.error = error
            }
        );
    }

    openAsideHelp(type: string): void {
        this.emitterService.activeHelp.emit({ type: type, feature: 'exercice' });
    }

    openAside(): void {
        this.display = true;
    }

    closeAside(): void {
        this.display = false;
    }

    processPosition(): void {
        for (let i = 0; i < this.section.sectionContents.length; i++) {
            this.section.sectionContents[i].position = i;
        }
    }
}
