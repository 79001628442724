import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { CourseProvider } from '@stuplay';

@Component({
    selector: 'instance-sidebar-template',
    templateUrl: './instance-sidebar-template.component.html'
})
export class InstanceSidebarTemplateComponent implements OnInit {
    @Output() create: EventEmitter<any> = new EventEmitter;
    @Output() close: EventEmitter<any> = new EventEmitter;
    public templates: any;
    public choosenTemplate: any;
    public active: boolean = false;

    constructor(private courseProvider: CourseProvider) { }

    ngOnInit() {
        this.activeEffect();

        this.courseProvider.getTemplates(null, null, '').subscribe((data) => {
            this.templates = data.templates;
        });
    }

    activeEffect(): void {
        setTimeout(() => {
            this.active = true;
        }, 100);
    }

    chooseTemplate(template: any): void {
        this.choosenTemplate = template;
    }

    isChoosed(template: any): boolean {
        return this.choosenTemplate === template;
    }

    createInstance(): void {
        if (this.choosenTemplate) {
            this.create.emit(this.choosenTemplate);
        }
    }

    closeSidebarEmit(): void {
        this.active = false;
        setTimeout(() => {
            this.close.emit(true);
        }, 300);
    }

    searchTemplate(search: string): void {
        this.courseProvider.getTemplates(null, null, search).subscribe((data) => {
            this.templates = data.templates;
        });
    }
}
