import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SkillProvider } from '@stuplay';
import { StorageService } from 'src/app/utils/services/storage.service';

@Component({
    selector: 'msc-aside-skill',
    templateUrl: './aside-skill.component.html',
    styleUrls: ['./aside-skill.component.scss']
})
export class AsideSkillComponent implements OnInit {
    @Input() company: any;
    @Input() content: any;
    @Input() skills: any = [];
    @Input() full: boolean = false;
    @Output() onClose: EventEmitter<any> = new EventEmitter;
    @Output() onEvent: EventEmitter<any> = new EventEmitter;
    public displayCreate: boolean = false;
    public skillsLevel: number[][] = [];
    public active: boolean = false;
    public skillCollection: any;
    public suggestions: any;
    public query: string;
    public loading: boolean = false;
    public level: number = 0;
    public me;

    constructor(
        private skillProvider: SkillProvider,
        private storageService: StorageService,
    ) { }

    ngOnInit() {
        this.me = this.storageService.get('me');
        this.skillsLevel[0] = [];
        this.skillsLevel[1] = [];
        this.activeEffect();
        this.search();
    }

    activeEffect(): void {
        const timeOut = setTimeout(() => {
            this.active = true;
            clearTimeout(timeOut);
        }, 100);
    }

    search(displayCreate?: boolean): void {
        this.loading = true;
        this.displayCreate = false;
        this.suggestions = null;
        const params = {
            company_id: this.company.slug,
        };

        if (this.query) {
            params['q'] = this.query;
        } else {
            displayCreate = false;
        }

        this.skillProvider.getSkills(params).subscribe((data) => {
            this.skillCollection = this.filtering(data.skills);
            this.loading = false;
            if (displayCreate) {
                this.displayCreate = this.isExist(data.skills);
                this.suggestions = this.filteringSuggestions(data.skills);
            }
        });
    }

    closeSidebar(): void {
        this.active = false;
        const timeOut = setTimeout(() => {
            this.onClose.emit();
            clearTimeout(timeOut);
        }, 300);
    }

    attachSkill(skill: any): void {
        this.onEvent.emit({ type: 'attach', skill, level: this.level });

        const timeOut = setTimeout(() => {
            this.removeSearch();
            clearTimeout(timeOut);
        }, 700);
    }

    updateLevel(skill: any, level: number): void {
        this.onEvent.emit({ type: 'level', skill, level });
    }

    detachSkill(skill: any): void {
        this.onEvent.emit({ type: 'detach', skill });

        const timeOut = setTimeout(() => {
            this.removeSearch();
            clearTimeout(timeOut);
        }, 700);
    }

    isLevel(index: number, skill: any, levelExpected: number, thematic?: boolean): boolean {
        if (this.skillsLevel[thematic ? 0 : 1][index]) {
            return this.skillsLevel[thematic ? 0 : 1][index] > levelExpected;
        }
        return skill.level > levelExpected;
    }

    displayLevel(index: number, level?: number, thematic?: boolean): void {
        this.skillsLevel[thematic ? 0 : 1][index] = level;
    }

    setLevel(level: number): void {
        this.level = level;
    }

    filtering(skills: any): any {
        return skills.filter((skill) => {
            return this.skills.findIndex((data) => {
                return data.id === skill.id;
            }) === -1 && skill.visible;
        });
    }

    filteringSuggestions(skills: any): any {
        return skills.filter((skill) => {
            if (!skill.visible && skill.suggestion && skill.suggestion.visible) {
                const index = this.skills.findIndex((data) => {
                    return data.id === skill.suggestion.id;
                });

                if (index === -1) {
                    return skill;
                }
            }
        });
    }

    createSkill(): void {
        const level = this.level;
        const params = {
            company_id: this.company.slug,
            translations:{
                   [this.me.language.code]: this.query
            }
        };

        this.skillProvider.create(params).subscribe((skill) => {
            this.removeSearch();
            this.onEvent.emit({ type: 'attach', skill, level: level });
        });
    }

    canCreate(): boolean {
        return !!this.query && this.displayCreate;
    }

    removeSearch(): void {
        this.query = null;
        this.suggestions = null;
        this.search();
    }

    isExist(skills: any): boolean {
        if (!this.query) {
            return false;
        }

        return skills.findIndex((skill) => {
            return skill.name.toLowerCase() === this.query.toLowerCase();
        }) === -1;
    }

    filterSkills(thematic?: boolean): any {
        return this.skills.filter((skill) => {
            if (thematic && skill.thematic) {
                return skill;
            }

            if (!thematic && !skill.thematic) {
                return skill;
            }
        });
    }
}
