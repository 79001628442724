import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Journey, JourneyProvider } from '@stuplay';
import { HeaderService } from '../../../utils/services/header.service';
import { ToastService } from '../../../utils/components/toast/toast.service';
import { StorageService } from '../../../utils/services/storage.service';
import { NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-journey-list',
    templateUrl: './journey-list.component.html',
    styleUrls: ['./journey-list.component.less']
})
export class JourneyListComponent implements OnInit {
    public journeys: Journey[];
    public pagination: any;
    public sidebarActive = '';
    public openDropdown: boolean[] = [];
    public currentJourney: Journey;
    public loading = false;
    public journeyLoading = false;
    public hideArchiveWarn;
    public onlyArchived = 0;
    public isEmptyState = false;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private journeyProvider: JourneyProvider,
        private headerService: HeaderService,
        private toastService: ToastService,
        private storageService: StorageService,
        private modalService: NgbModal,
    ) { }

    ngOnInit() {
        this.hideArchiveWarn = sessionStorage.getItem('hideArchiveWarn') ? true : false;
        this.route.params.subscribe(() => {
            this.journeys = this.route.snapshot.data.journeys.journeys;
            this.pagination = this.route.snapshot.data.journeys.pagination;

            if (!this.journeys.length) {
                this.isEmptyState = true;
            }
        });
    }

    openModal(content: any, options: NgbModalOptions = {size: 'lg', backdropClass: 'backdrop-custom'}) {
        this.modalService.open(content, options);
    }

    changeOpenDropdown(index: number): void {
        const dropdown = !this.openDropdown[index];
        this.openDropdown = [];
        this.openDropdown[index] = dropdown;
    }

    stopPropagation(event: any): void {
        event.stopPropagation();
    }

    search(query: string): void {
        this.journeyLoading = true;
        this.journeys = [];
        const params = {
            company_id: this.storageService.get('company').slug,
            archived: this.onlyArchived
        };
        this.journeyProvider.getJourneys(query, params).subscribe((data) => {
            this.journeys = data.journeys;
            this.pagination = data.pagination;
            this.journeyLoading = false;
        });
    }

    closeSidebar(): void {
        this.sidebarActive = '';
    }

    openSidebar(name, journey?): void {
        this.currentJourney = journey;
        this.sidebarActive = name;
    }

    publishJourney(event: any): void {
        this.journeyProvider.publish(this.currentJourney.id, event.settings).subscribe(() => {
            this.toastService.push('journey-published');
            this.currentJourney.published = true;
            this.closeSidebar();
            this.updateDataJourney(this.currentJourney);
            this.currentJourney = null;
        });
    }

    updateDataJourney(newJourney){
        this.journeys.map((journey: Journey)=>{
            if(journey.id === newJourney.id){
                journey.extends(newJourney);
            }
        })
    }

    updateJourney(journey: any): void {
        this.closeSidebar();
        this.updateDataJourney(journey);
        this.currentJourney = null;
        this.toastService.push('journey-updated');
    }

    addJourney(journey: Journey): void {
        this.closeSidebar();
        this.journeys.push(journey);
        this.headerService.plus('trajects');
        this.toastService.push('journey-created');
        this.openDropdown = [];
        this.router.navigate(['company', this.storageService.get('company').slug, 'journey', 'preview', journey.slug]);
    }

    loadMore(): void {
        this.loading = true;
        const params = {
            page: this.pagination.currentPage + 1,
            company_id: this.storageService.get('company').slug,
            onlyArchived: this.onlyArchived
        };

        this.journeyProvider.getJourneys('', params).subscribe((data) => {
            data.journeys.map((journey: any) => {
                this.journeys.push(journey);
            });
            this.pagination = data.pagination;
            this.loading = false;
        });
    }

    cloneJourney(journey: Journey): void {
        this.openDropdown = [];
        this.journeyProvider.cloneJourney(journey.id).subscribe((data) => {
            this.navigateToJourney(data.slug);
        });
    }

    archiveJourney(journey: Journey): void {
        const params = {
            company_id: this.storageService.get('company').slug,
        };

        if (this.hideArchiveWarn) {
            // store decision
            sessionStorage.setItem('hideArchiveWarn', this.hideArchiveWarn ? '1' : null);
        }
        this.openDropdown = [];
        this.journeyProvider.archiveJourney(journey.id, params).subscribe((data) => {
            this.toastService.push('learning-path.archived');
            this.search('');
        });
    }

    restoreJourney(journey: Journey): void {
        const params = {
            company_id: this.storageService.get('company').slug,
        };

        this.openDropdown = [];
        this.journeyProvider.restoreJourney(journey.id, params).subscribe((data) => {
            this.toastService.push('learning-path.restored');
            this.search('');
        });
    }

    openJourneyModal(journey: Journey, content: any, small?): void {
        this.openDropdown = [];
        this.currentJourney = journey;
        this.openModal(content, { size: small ? 'md' : 'xl', centered: true });
    }

    deleteJourney(journey: Journey): void {
        this.journeyProvider.deleteJourney(journey.id).subscribe(() => {
            const index = this.journeys.findIndex((data) => {
                return data.id === journey.id;
            });

            if (index !== -1) {
                this.headerService.minus('trajects');
                this.journeys.splice(index, 1);
                this.toastService.push('journey-deleted');
                this.openDropdown = [];
            }
        });
    }

    getPicture(media: any): string {
        if (media.storage === 'ext') {
            return media.url;
        }

        return media.pictureUrl + '?size=1024';
    }

    navigateToJourney(slug: string): void {
        this.router.navigate(['company', this.storageService.get('company').slug, 'journey', 'preview', slug]);
    }
}
