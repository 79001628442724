import { Component, Input, Output, EventEmitter, OnInit, HostListener } from '@angular/core';
import { Course, Folder, CourseProvider } from '@stuplay';
import { Router } from '@angular/router';
import { NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { HeaderService } from '../../../../../utils/services/header.service';
import { EmitterService } from '../../../../../utils/services/emitter.service';
import { StorageService } from '../../../../../utils/services/storage.service';
import { ToastService } from '../../../../../utils/components/toast/toast.service';
import { RedirectService } from '../../../../../utils/services/redirect.service';

@Component({
    selector: 'folder-miniature',
    templateUrl: './folder-miniature.component.html'
})
export class FolderMiniatureComponent implements OnInit {
    @Input() folders: Folder[];
    @Input() courses: Course[];
    @Input() item: any;
    @Input() type: string;
    @Input() index: number;
    @Input() contents: any[];
    @Input() sharedCourses: boolean = false;
    @Input() configurable: boolean = true;
    @Output() slice: EventEmitter<any> = new EventEmitter;
    @Output() createInstance: EventEmitter<boolean> = new EventEmitter;
    public openDropdown: boolean = false;
    public currentFolder: Folder;
    public currentTemplate: any;
    public renameFolderActive: boolean = false;
    public notifyUsers: boolean = false;

    constructor(
        private router: Router,
        private courseProvider: CourseProvider,
        private headerService: HeaderService,
        private emitterService: EmitterService,
        private storageService: StorageService,
        private toastService: ToastService,
        private redirectService: RedirectService,
        private modalService: NgbModal,
    ) {
        this.emitterService.dropdownActive.subscribe((data) => {
            if (data.type !== this.item.constructor.name || data.index !== this.index) {
                this.openDropdown = false;
            }
        });
    }

    ngOnInit() {
        if (this.item instanceof Folder && this.item.new) {
            this.renameFolderActive = true;
        }
    }

    openModal(content: any) {
        const options: NgbModalOptions = { size: 'xl', centered: true, backdropClass: 'backdrop-custom' };
        this.modalService.open(content, options);
    }

    updateFolder(folder: Folder): void {
        if (folder.title !== '') {
            folder.update().subscribe();
        }
    }

    changeOpenDropdown(): void {
        this.openDropdown = !this.openDropdown;
        if (this.openDropdown) {
            this.emitterService.dropdownActive.emit({ index: this.index, type: this.item.constructor.name });
        }
    }

    openDeleteFolder(folder: Folder, content: any): void {
        this.openModal(content);
        this.currentFolder = folder;
        this.changeOpenDropdown();
    }

    deleteFolder(folder: Folder): void {
        folder.delete().subscribe(() => {
            this.slice.emit({ index: this.index, type: 'folder' });
        });
    }

    stopPropagation(event: any): void {
        event.stopPropagation();
    }

    editTemplate(item: any): void {
        this.storageService.set('currentLocation', this.router.url);
        this.router.navigate(['company', this.storageService.get('company').slug, 'course', item.slug]);
    }

    cloneTemplate(item: any): void {
        this.courseProvider.cloneCourse([item.id]).subscribe((data) => {
            this.headerService.plus('templates');
            this.router.navigate(['company', this.storageService.get('company').slug, 'course', data.courses[0].slug]);
        });
    }

    renameFolder(): void {
        this.changeOpenDropdown();
        this.renameFolderActive = !this.renameFolderActive;
    }

    openDeleteTemplate(course: Course, content: any): void {
        this.notifyUsers = false;
        this.currentTemplate = course;
        this.courseProvider.getTemplateMeta(this.currentTemplate.id).subscribe((data) => {
            this.currentTemplate.campNames = data.campNames;
            this.currentTemplate.campsCount = data.campsCount;
            this.currentTemplate.usersCount = data.usersCount;
            this.openModal(content);
        });
    }

    deleteTemplate(course: Course): void {
        const params = {
            notify: this.notifyUsers ? 1 : 0
        };
        course.delete(params).subscribe(() => {
            this.headerService.minus('templates');
            this.headerService.minus('instances', course.instancesCount);
            this.slice.emit({ index: this.index, type: 'template' });
        });
    }

    addInstance(item: any): void {
        this.createInstance.emit(item);
    }

    navigateToInstance(item: any): void {
        this.router.navigate(['company', this.storageService.get('company').slug, 'instance', item.slug]);
    }

    openMoveTo(item: any): void {
        if (item.permissions.move) {
            this.emitterService.moveTo.emit({ item: item, type: this.type });
            this.changeOpenDropdown();
        } else {
            this.toastService.push('not-owner-' + this.type, 'warning');
        }
    }

    previewTemplate(): void {
        this.redirectService.navigate('player', ['course', this.item.id], { preview: true }, '_blank');
    }

    @HostListener('document:click', ['$event'])
    clickout(): void {
        this.renameFolderActive = false;
    }
}
