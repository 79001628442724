import { Component, Input, OnInit } from '@angular/core';
import { SectionContent, Section, Course, ExternalLinkProvider } from '@stuplay';
import { ErrorCatcherService } from '../../../../utils/services/error-catcher.service';
import { EmitterService } from '../../../../utils/services/emitter.service';
import { ExternalAppsService } from '../../../../utils/services/external-apps.service';
import { StorageService } from '../../../../utils/services/storage.service';

@Component({
    selector: 'app-section-content',
    templateUrl: './section-content.component.html',
    styleUrls: ['./section-content.component.less']
})
export class SectionContentComponent implements OnInit {
    @Input() course: Course;
    @Input() section: Section;
    @Input() sectionContent: SectionContent;
    private me: any;
    private company: any;
    public display: boolean = false;
    public deleteAction: boolean = false;
    public editAction: boolean = false;
    public error: any;
    public deleteError: any;
    public position: string = 'right';
    public closeAlert: boolean = false;

    constructor(
        private errorCatcher: ErrorCatcherService,
        private emitterService: EmitterService,
        private externalAppsService: ExternalAppsService,
        private storageService: StorageService,
        private externalLinkProvider: ExternalLinkProvider,
    ) {
        document.addEventListener('click', this.offClickHandler.bind(this));
    }

    ngOnInit() {
        this.me = this.storageService.get('me');
        this.company = this.storageService.get('company');
    }

    offClickHandler(event: any): void {
        if (!event.target.closest('.settings-delete')) {
            this.deleteAction = false;
        }

        if (!event.target.closest('.settings-edit')) {
            this.editAction = false;
        }
    }

    changeDeleteAction(event: any): void {
        this.position = (event.clientX + 260 > window.innerWidth) ? 'left' : 'right';
        this.deleteAction = !this.deleteAction;
    }

    changeEditAction(event: any): void {
        if (this.sectionContent.context === 'external_link') {
            this.display = true;
        } else {
            this.position = (event.clientX + 260 > window.innerWidth) ? 'left' : 'right';
            this.editAction = !this.editAction;
        }
    }

    updateSectionContent(): void {
        this.sectionContent.update().subscribe((sectionContent) => {
            this.emitterService.refreshCourse.emit(true);
            this.errorCatcher.setErrors(sectionContent);
        },
            (error) => {
                this.error = error
            }
        );
    }

    updateLti(lti: any, updateSectionContent: boolean = true): void {
        const params = {
            company_id: this.company.id,
            type: 'lti',
            name: lti.name,
            description: lti.description,
            url: lti.url,
            redirect_url: lti.redirectUrl,
            key: lti.key,
            secret: lti.secret,
            names: lti.names,
            anonymize: lti.anonymize,
            mode: lti.mode,
            mediaId: lti.mediaId
        };

        this.externalLinkProvider.updateExternalLink(lti.id, params).subscribe((externalLink) => {
            this.sectionContent.externalLink = externalLink;
            if (updateSectionContent) {
                this.sectionContent.title = externalLink.name;
                this.updateSectionContent();
            }
            this.display = false;
        });
    }

    updateSubContext(): void {
        if (this.sectionContent.context === 'external_link' && this.sectionContent.externalLink.name !== this.sectionContent.title) {
            this.sectionContent.externalLink.name = this.sectionContent.title;
            this.updateLti(this.sectionContent.externalLink, false);
        }
    }

    changeCloseAlert(): void {
        this.closeAlert = !this.closeAlert;
    }

    deleteSectionContent(): void {
        const params = {
            companyId: this.company.id
        };

        this.section.deleteSectionContent(this.sectionContent.id, params).subscribe(
            () => {
                this.errorCatcher.removeFromErrors(this.sectionContent);
                this.emitterService.refreshCourse.emit(true);
            },
            (error) => {
                this.deleteError = error
            }
        );
    }

    containsDescription(context: string): boolean {
        switch (context) {
            case 'media':
            case 'exchange':
            case 'scorm':
            case 'project':
            case 'selfassessment':
            case 'external_link':
                return true;
            default:
                return false
        }
    }

    openAsideHelp(type: string): any {
        this.emitterService.activeHelp.emit({ type: type, feature: 'exercise' });
    }

    openAside(): void {
        this.display = true;
    }

    closeAside(): void {
        this.display = false;
    }
}
