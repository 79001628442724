import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import * as moment from 'moment';
import * as _ from 'lodash';
import { TranslationService } from '../../services/translation.service';

export interface CalendarDate {
    mDate: moment.Moment;
    selected?: boolean;
    today?: boolean;
}

@Component({
    selector: 'msc-datepicker',
    templateUrl: './datepicker.component.html',
})
export class DatepickerComponent implements OnInit, OnChanges {
    @Input() class: string;
    @Input() date;
    @Input() reset: boolean = true;
    @Output() onSelectDate: EventEmitter<any> = new EventEmitter();
    @Output() close: EventEmitter<any> = new EventEmitter();
    public currentDate;
    public dayNames = {
        'en': ['M', 'T', 'W', 'T', 'F', 'S', 'S'],
        'fr': ['L', 'M', 'Me', 'J', 'V', 'S', 'D'],
        'nl': ['M', 'D', 'W', 'D', 'V', 'Z', 'Z']
    };
    public weeks: CalendarDate[][] = [];
    public currentLang: string;

    constructor(private translationService: TranslationService) { }

    ngOnInit(): void {
        this.currentLang = this.translationService.getCurrentLang();
        this.currentDate = (this.date) ? moment(moment(this.date, 'DD/MM/YYYY')) : moment();
        this.generateCalendar();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.date &&
            changes.date.currentValue &&
            changes.date.currentValue.length > 1) {
            this.currentDate = moment(moment(changes.date.currentValue, 'DD/MM/YYYY'));
            this.generateCalendar();
        }
    }

    isToday(date: moment.Moment): boolean {
        return moment().isSame(moment(date), 'day');
    }

    isSelected(date: moment.Moment): boolean {
        if (this.date) {
            return moment(moment(this.date, 'DD/MM/YYYY')).isSame(date, 'day');
        }
        return false;
    }

    isSelectedMonth(date: moment.Moment): boolean {
        return moment(date).isSame(this.currentDate, 'month');
    }

    selectDate(date: CalendarDate, enable: boolean): void {
        if (enable) {
            this.onSelectDate.emit(date.mDate);
            this.close.emit(true);
        }
    }

    prevMonth(): void {
        this.currentDate = moment(this.currentDate).subtract(1, 'months');
        this.generateCalendar();
    }

    nextMonth(): void {
        this.currentDate = moment(this.currentDate).add(1, 'months');
        this.generateCalendar();
    }

    prevYear(): void {
        this.currentDate = moment(this.currentDate).subtract(1, 'year');
        this.generateCalendar();
    }

    nextYear(): void {
        this.currentDate = moment(this.currentDate).add(1, 'year');
        this.generateCalendar();
    }

    resetDate(): void {
        if (this.reset) {
            this.onSelectDate.emit();
            this.close.emit(true);
        }
    }

    closeDatepicker(): void {
        this.close.emit(true);
    }

    generateCalendar(): void {
        const dates = this.fillDates(this.currentDate);
        const weeks: CalendarDate[][] = [];
        while (dates.length > 0) {
            weeks.push(dates.splice(0, 7));
        }
        this.weeks = weeks;
    }

    fillDates(currentMoment: moment.Moment): CalendarDate[] {
        let firstOfMonth = moment(currentMoment).startOf('month').day();
        if (firstOfMonth === 0) {
            firstOfMonth = 7;
        }
        const firstDayOfGrid = moment(currentMoment).startOf('month').subtract(firstOfMonth - 1, 'days');
        const start = firstDayOfGrid.date();
        return _.range(start, start + 42)
            .map((date: number): CalendarDate => {
                const d = moment(firstDayOfGrid).date(date);
                return {
                    today: this.isToday(d),
                    selected: this.isSelected(d),
                    mDate: d,
                };
            });
    }
}
