<msc-header></msc-header>

<div class="overlay" [ngClass]="{'active' : activeMoveTo}"></div>

<msc-topbar (search)="setQuery($event)" (newFolder)="addFolder($event)" [sharedCourses]="sharedCourses"></msc-topbar>

<moveto *ngIf="activeMoveTo" [object]="moveToItem" [folders]="foldersTree" (close)="closeMoveTo()" (move)="refresh()"></moveto>

<div class="container">
    <section class="section" *ngIf="!sharedCourses">
        <h1 class="section__title" *ngIf="foldersLoaded && folders.length > 0">
            {{ 'words.my-folder' | translate }}
        </h1>

        <msc-loader [loading]="!foldersLoaded"></msc-loader>

        <folder-display *ngIf="foldersLoaded" [type]="'folder'" [contents]="folders" (newFolder)="addFolder($event)"></folder-display>
    </section>

    <section class="section">
        <h1 class="section__title">
            {{ 'studio.template.title' | translate }}
        </h1>

        <folder-display [type]="'template'" [contents]="courses" (createInstance)="addInstance($event)" [sharedCourses]="sharedCourses" [configurable]="configurable"></folder-display>

        <button class="show-more" *ngIf="coursesPagination.currentPage < coursesPagination.lastPage" (click)="loadMore()">
            <div class="lds-ring" *ngIf="processing">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>

            <span>{{ 'studio.instance.load-more' | translate }}</span>
        </button>
    </section>
</div>
