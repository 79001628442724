import { NgModule, LOCALE_ID, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { Router, RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule, APP_BASE_HREF, registerLocaleData } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { OverlayModule } from '@angular/cdk/overlay';
import { ScrollingModule } from '@angular/cdk/scrolling';
import * as Sentry from '@sentry/angular';

import { StuplayModule } from '@stuplay';

import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { FullCalendarModule } from '@fullcalendar/angular';

import { Angular2PromiseButtonModule } from 'angular2-promise-buttons';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import { TagInputModule } from 'ngx-chips';
import { NgxUploaderModule } from 'ngx-uploader';
import { DndModule } from 'ng2-dnd';
import { NgProgressModule } from 'ngx-progressbar';
import { Autosize } from 'angular2-autosize';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routes';

import { ProvidersModule } from './providers/providers.module';

// Guards
import { AuthGuard } from './utils/guards/auth.guard';
import { CompanyGuard } from './utils/guards/company.guard';

// Components
import { AccountComponent } from './utils/components/account/account.component';
import { AsideCourseComponent } from './utils/components/aside/aside-course/aside-course.component';
import { AsideEditorComponent } from './utils/components/aside/aside-editor/aside-editor.component';
import { AsideHelpComponent } from './utils/components/aside/aside-help/aside-help.component';
import { AsideLtiComponent } from './utils/components/aside/aside-lti/aside-lti.component';
import { AsideSkillComponent } from './utils/components/aside/aside-skill/aside-skill.component';
import { DatepickerComponent } from './utils/components/datepicker/datepicker.component';
import { DaterangePickerComponent } from './utils/components/daterangepicker/daterangepicker.component';
import { DebounceDirective } from './utils/components/input/debounce/debounce.directive';
import { FroalaComponent } from './utils/components/froala/froala.component';
import { InputDurationComponent } from './utils/components/input/duration/input-duration.component';
import { LinkManagerComponent } from './utils/components/link-preview/link-manager/link-manager.component';
import { LinkPreviewComponent } from './utils/components/link-preview/link-preview.component';
import { LoaderComponent } from './utils/components/loader/loader.component';
import { ManagerAsideComponent } from './utils/components/aside/manager-aside/manager-aside.component';
import { ResponsiveToolComponent } from './utils/components/topbar/responsive/responsive.component';
import { SidebarComponent } from './utils/components/sidebar/sidebar.component';
import { SubfolderComponent } from './utils/components/subfolder/subfolder.component';
import { ToastComponent } from './utils/components/toast/toast.component';
import { TooltipComponent } from './utils/components/tooltip/tooltip.component';
import { TopBarComponent } from './utils/components/topbar/topbar.component';
import { CommonTableComponent } from './utils/components/table/table.component';
import { CommonThCheckboxComponent } from './utils/components/table-th/th-checkbox/th-checkbox.component';
import { CommonThSortComponent } from './utils/components/table-th/th-sort/th-sort.component';
import { CommonSelectComponent } from './utils/components/select/select.component';
import { CommonTreeComponent } from './utils/components/tree/tree.component';
import { CommonCheckboxComponent } from './utils/components/checkbox/checkbox.component';
import { CommonSearchComponent } from './utils/components/search/search.component';

// Directives
import { AutoCloseDirective } from './utils/directives/autoclose.directive';
import { SidebarDirective } from './utils/directives/sidebar.directive';

// Services
import { AuthService } from './utils/services/auth.service';
import { EmitterService } from './utils/services/emitter.service';
import { ErrorCatcherService } from './utils/services/error-catcher.service';
import { ExternalAppsService } from './utils/services/external-apps.service';
import { FullstoryService } from './utils/services/fullstory.service';
import { HeaderService } from './utils/services/header.service';
import { RedirectService } from './utils/services/redirect.service';
import { RefreshAuthService } from './utils/services/refresh-auth.service';
import { ScriptInjectorService } from './utils/services/script-injector.service';
import { StorageService } from './utils/services/storage.service';
import { TranslationService } from './utils/services/translation.service';
import { WorkerService } from './utils/services/worker.service';
import { ToastService } from './utils/components/toast/toast.service';
import { SubfolderService } from './utils/components/subfolder/subfolder.service';

// Interceptors
import { TokenInterceptorService } from './utils/interceptors/token.interceptor.service';
import { ErrorInterceptorService } from './utils/interceptors/error.interceptor.service';

// Media
import { MediaButtonUploadComponent } from './utils/media/media-button-upload.component';
import { MediaComponent } from './utils/media/media.component';
import { MediaDocComponent } from './utils/media/doc/doc.component';
import { MediaEmbedComponent } from './utils/media/embed/embed.component';
import { MediaImageComponent } from './utils/media/image/image.component';
import { MediaManagerComponent } from './utils/media/media-manager.component';
import { MediaNoneComponent } from './utils/media/none/none.component';
import { MediaSoundComponent } from './utils/media/sound/sound.component';
import { MediaVideoComponent } from './utils/media/video/video.component';
import { SingleUploadComponent } from './utils/media/single-upload.component';
import { VimeoComponent } from './utils/media/vimeo/vimeo.component';

// Pipes
import { CleanPipe } from './utils/pipes/clean.pipe';
import { DaterangePickerPipe } from './utils/pipes/daterangepicker.pipe';
import { DurationPipe } from './utils/pipes/duration.pipe';
import { FileSizePipe } from './utils/pipes/filesize.pipe';
import { FormatDatePipe } from './utils/pipes/format-date.pipe';
import { KeyPipe } from './utils/pipes/key.pipe';
import { SafePipe } from './utils/pipes/safe.pipe';
import { TimeAgoPipe } from './utils/pipes/time-ago.pipe';
import { ToArrayPipe } from './utils/pipes/to-array.pipe';
import { TruncatePipe } from './utils/pipes/truncate.pipe';
import { UrlPipe } from './utils/pipes/url.pipe';
import { UuidPipe } from './utils/pipes/uuid.pipe';

// Pages
import { AddQuestionComponent } from './pages/course/section/section-content/exercise/add-question/add-question.component';
import { AddSectionContentComponent } from './pages/course/section/add-section-content/add-section-content.component';
import { AppcuesComponent } from './pages/header/appcues/appcues.component';
import { AppMediaComponent } from './pages/course/section/section-content/media/app-media.component';
import { AsideQuestionSettingsComponent } from './pages/course/section/settings/aside-question-settings/aside-question-settings.component';
import { CertificateComponent } from './pages/course/section/section-content/certificate/certificate.component';
import { CheatSheetComponent } from './pages/course/section/section-content/cheatsheet/cheatsheet.component';
import { CommentComponent } from './pages/course/timeline/comment/comment.component';
import { CompanyComponent } from './pages/company/company.component';
import { ContentOnDemandComponent } from './pages/instance/aside/content/content-ondemand.component';
import { CourseComponent } from './pages/course/course.component';
import { CoursePresentationComponent } from './pages/course/course-presentation/course-presentation.component';
import { EditQuestionSettingsComponent } from './pages/course/section/settings/question-edit/edit-question-settings.component';
import { EditSettingsComponent } from './pages/course/section/settings/section-content-edit/edit-settings.component';
import { EventComponent } from './pages/course/section/section-content/event/event.component';
import { EventPresentationComponent } from './pages/course/section/section-content/event/event-presentation/event-presentation.component';
import { EventSingleSessionComponent } from './pages/course/section/section-content/event/event-single-session/event-single-sessions.component';
import { ExchangeComponent } from './pages/course/section/section-content/exchange/exchange.component';
import { ExerciseComponent } from './pages/course/section/section-content/exercise/exercise.component';
import { ExternalLinkComponent } from './pages/course/section/section-content/external-link/external-link.component';
import { FolderDisplayComponent } from './pages/home/folder/folder-display/folder-display.component';
import { FolderMiniatureComponent } from './pages/home/folder/folder-display/folder-miniature/folder-miniature.component';
import { HeaderComponent } from './pages/header/header.component';
import { HomeComponent } from './pages/home/home.component';
import { InstanceComponent } from './pages/instance/instance.component';
import { InstanceManagerComponent } from './pages/instance/instance-manager/instance-manager.component';
import { InstancePublishModal } from './pages/instance/instance-sidebar/instance-sidebar-create/instance-publish-modal/instance-publish-modal.component';
import { InstanceSidebarCampComponent } from './pages/instance/instance-sidebar/instance-sidebar-camp/instance-sidebar-camp.component';
import { InstanceSidebarCreateComponent } from './pages/instance/instance-sidebar/instance-sidebar-create/instance-sidebar-create.component';
import { InstanceSidebarMenuComponent } from './pages/instance/instance-sidebar/instance-sidebar-menu/instance-sidebar-menu.component';
import { InstanceSidebarSettingsComponent } from './pages/instance/instance-sidebar/instance-sidebar-settings/instance-sidebar-settings.component';
import { InstanceSidebarTemplateComponent } from './pages/instance/instance-sidebar/instance-sidebar-template/instance-sidebar-template.component';
import { JourneyButtonComponent } from './pages/journey/journey-components/journey-button/journey-button.component';
import { JourneyComponent } from './pages/journey/journey.component';
import { JourneyConditionComponent } from './pages/journey/journey-components/journey-condition/journey-condition.component';
import { JourneyContentComponent } from './pages/journey/journey-components/journey-content/journey-content.component';
import { JourneyLibraryActivityComponent } from './pages/journey/journey-components/journey-library/library/library-activity/library-activity.component';
import { JourneyLibraryComponent } from './pages/journey/journey-components/journey-library/journey-library.component';
import { JourneyLibraryContentComponent } from './pages/journey/journey-components/journey-library/library/library-content/library-content.component';
import { JourneyLibraryEventComponent } from './pages/journey/journey-components/journey-library/library/library-event/library-event.component';
import { JourneyLibraryLinkComponent } from './pages/journey/journey-components/journey-library/library/library-link/library-link.component';
import { JourneyLibraryQuestionComponent } from './pages/journey/journey-components/journey-library/library/library-question/library-question.component';
import { JourneyListComponent } from './pages/journey/journey-list/journey-list.component';
import { JourneyPreviewComponent } from './pages/journey/journey-preview/journey-preview.component';
import { JourneySidebarCreateComponent } from './pages/journey/journey-sidebar/journey-sidebar-create/journey-sidebar-create.component';
import { JourneySidebarPublishComponent } from './pages/journey/journey-sidebar/journey-sidebar-publish/journey-sidebar-publish.component';
import { LearnersOnDemandComponent } from './pages/instance/aside/learners/learners-ondemand.component';
import { MediaLibraryComponent } from './pages/media-library/media-library.component';
import { MediaLibrarySidebarComponent } from './pages/media-library/media-libary-sidebar/media-library-sidebar.component';
import { MoveToComponent } from './pages/home/moveto/moveto.component';
import { PostComponent } from './pages/course/timeline/post/post.component';
import { ProjectComponent } from './pages/course/section/section-content/project/project.component';
import { QuestionComponent } from './pages/course/section/section-content/exercise/question/question.component';
import { ScormComponent } from './pages/course/section/section-content/scorm/scorm.component';
import { ScormModalComponent } from './pages/course/section/section-content/scorm/scorm-modal.component';
import { ReUseSectionContentComponent } from './pages/course/section/re-use-section-content/re-use-section-content.component';
import { SectionComponent } from './pages/course/section/section.component';
import { SectionContentComponent } from './pages/course/section/section-content/section-content.component';
import { SectionPresentationComponent } from './pages/course/section/section-presentation/section-presentation.component';
import { SelfAssessmentComponent } from './pages/course/section/section-content/self-assessment/self-assessment.component';
import { TimelineAddContentComponent } from './pages/course/timeline/timeline-add-content.component';
import { TimelineComponent } from './pages/course/timeline/timeline.component';
import { TimelineDeleteContentComponent } from './pages/course/timeline/timeline-delete-content.component';
import { TimelineShowContentComponent } from './pages/course/timeline/timeline-show-content.component';
import { TopbarComponent } from './pages/topbar/topbar.component';
import { TableCompanyOwnershipComponent } from './pages/topbar/table-company-ownership/table-company-ownership.component';
import { VideoComponent } from './pages/course/section/section-content/video/video.component';

// Utils
import { LibraryModule } from './utils/library';
import { UrlHelper } from './utils/helpers';

import { environment } from '../environments/environment';

import localeEn from '@angular/common/locales/en';
import localeFr from '@angular/common/locales/fr';
import localeNl from '@angular/common/locales/nl';
import localeDe from '@angular/common/locales/de';
import localeEs from '@angular/common/locales/es';
import localeIt from '@angular/common/locales/it';
import { InstanceSidebarUnpublishComponent } from './pages/instance/instance-sidebar/instance-sidebar-unpublish/instance-sidebar-unpublish.component';
import { InstanceUnpublishModal } from './pages/instance/instance-sidebar/instance-unpublish-modal/instance-unpublish-modal.component';
import { MscTooltipComponent } from './utils/components/tooltip-v2/tooltip.component';
import { MscTooltipDirective } from './utils/components/tooltip-v2/tooltip.directive';
import { InstanceUnpublishConfirmModal } from './pages/instance/instance-sidebar/instance-unpublish-confirm/instance-unpublish-confirm.component';

registerLocaleData(localeEn);
registerLocaleData(localeFr);
registerLocaleData(localeNl);
registerLocaleData(localeDe);
registerLocaleData(localeEs);
registerLocaleData(localeIt);

import 'froala-editor/js/froala_editor.pkgd.min.js';
import 'froala-editor/js/plugins.pkgd.min.js';
import 'froala-editor/js/third_party/embedly.min.js';
import { OrderByPipe } from './utils/pipes/orderby.pipe';

export function BaseHrefFactory() {
    if (UrlHelper.getHostname() === environment.envVar.STUDIO_URL.replace('https://', '') || UrlHelper.getDomain().includes('deltabluecdn.com')) {
        return '/';
    }
    return '/studio/';
}

@NgModule({
    imports: [
        Angular2PromiseButtonModule.forRoot(),
        AppRoutingModule,
        BrowserAnimationsModule,
        BrowserModule,
        CommonModule,
        DndModule.forRoot(),
        FormsModule,
        FroalaEditorModule.forRoot(),
        FroalaViewModule.forRoot(),
        FullCalendarModule,
        HttpClientModule,
        LibraryModule,
        NgbModule,
        NgProgressModule,
        NgxUploaderModule,
        ProvidersModule.forRoot(),
        RouterModule,
        StuplayModule.forRoot(),
        TagInputModule,
        DragDropModule,
        OverlayModule,
        ScrollingModule
    ],
    declarations: [
        AccountComponent,
        AddQuestionComponent,
        AddSectionContentComponent,
        AppComponent,
        AppcuesComponent,
        AppMediaComponent,
        AsideCourseComponent,
        AsideEditorComponent,
        AsideHelpComponent,
        AsideLtiComponent,
        AsideQuestionSettingsComponent,
        AsideSkillComponent,
        AutoCloseDirective,
        Autosize,
        CertificateComponent,
        CheatSheetComponent,
        CleanPipe,
        CommentComponent,
        CompanyComponent,
        ContentOnDemandComponent,
        CourseComponent,
        CoursePresentationComponent,
        DatepickerComponent,
        DaterangePickerComponent,
        DaterangePickerPipe,
        DebounceDirective,
        DurationPipe,
        EditQuestionSettingsComponent,
        EditSettingsComponent,
        EventComponent,
        EventPresentationComponent,
        EventSingleSessionComponent,
        ExchangeComponent,
        ExerciseComponent,
        ExternalLinkComponent,
        FileSizePipe,
        FolderDisplayComponent,
        FolderMiniatureComponent,
        FormatDatePipe,
        FroalaComponent,
        HeaderComponent,
        HomeComponent,
        InputDurationComponent,
        InstanceComponent,
        InstanceManagerComponent,
        InstancePublishModal,
        InstanceUnpublishModal,
        InstanceUnpublishConfirmModal,
        InstanceSidebarCampComponent,
        InstanceSidebarUnpublishComponent,
        InstanceSidebarCreateComponent,
        InstanceSidebarMenuComponent,
        InstanceSidebarSettingsComponent,
        InstanceSidebarTemplateComponent,
        JourneyButtonComponent,
        JourneyComponent,
        JourneyConditionComponent,
        JourneyContentComponent,
        JourneyLibraryActivityComponent,
        JourneyLibraryComponent,
        JourneyLibraryContentComponent,
        JourneyLibraryEventComponent,
        JourneyLibraryLinkComponent,
        JourneyLibraryQuestionComponent,
        JourneyListComponent,
        JourneyPreviewComponent,
        JourneySidebarCreateComponent,
        JourneySidebarPublishComponent,
        KeyPipe,
        OrderByPipe,
        LearnersOnDemandComponent,
        LinkManagerComponent,
        LinkPreviewComponent,
        LoaderComponent,
        ManagerAsideComponent,
        MediaButtonUploadComponent,
        MediaComponent,
        MediaDocComponent,
        MediaEmbedComponent,
        MediaImageComponent,
        MediaLibraryComponent,
        MediaLibrarySidebarComponent,
        MediaManagerComponent,
        MediaNoneComponent,
        MediaSoundComponent,
        MediaVideoComponent,
        MoveToComponent,
        MscTooltipComponent,
        MscTooltipDirective,
        PostComponent,
        ProjectComponent,
        QuestionComponent,
        ResponsiveToolComponent,
        SafePipe,
        ScormComponent,
        ScormModalComponent,
        ReUseSectionContentComponent,
        SectionComponent,
        SectionContentComponent,
        SectionPresentationComponent,
        SelfAssessmentComponent,
        SidebarComponent,
        SidebarDirective,
        SingleUploadComponent,
        SubfolderComponent,
        CommonTableComponent,
        CommonThCheckboxComponent,
        CommonThSortComponent,
        CommonSelectComponent,
        CommonTreeComponent,
        CommonCheckboxComponent,
        CommonSearchComponent,
        TimeAgoPipe,
        TimelineAddContentComponent,
        TimelineComponent,
        TimelineDeleteContentComponent,
        TimelineShowContentComponent,
        ToArrayPipe,
        ToastComponent,
        TooltipComponent,
        TopbarComponent,
        TableCompanyOwnershipComponent,
        TopBarComponent,
        TruncatePipe,
        UrlPipe,
        UuidPipe,
        VideoComponent,
        VimeoComponent,
    ],
    providers: [
        {
            provide: APP_BASE_HREF,
            useFactory: BaseHrefFactory
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptorService,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptorService,
            multi: true
        },
        {
            provide: LOCALE_ID,
            useValue: 'en'
        },
        {
          provide: ErrorHandler,
          useValue: Sentry.createErrorHandler({
            showDialog: false,
          }),
        },
        {
          provide: Sentry.TraceService,
          deps: [Router],
        },
        {
          provide: APP_INITIALIZER,
          useFactory: () => () => {},
          deps: [Sentry.TraceService],
          multi: true,
        },
        AuthGuard,
        AuthService,
        CompanyGuard,
        EmitterService,
        ErrorCatcherService,
        ExternalAppsService,
        FullstoryService,
        HeaderService,
        NgbActiveModal,
        RedirectService,
        RefreshAuthService,
        ScriptInjectorService,
        StorageService,
        SubfolderService,
        ToastService,
        TranslationService,
        WorkerService,
    ],
    bootstrap: [AppComponent]
})

export class AppModule { }
