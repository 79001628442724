import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { StuplayModule } from '@stuplay';

import { NgxUploaderModule } from 'ngx-uploader';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { LibraryModalComponent } from './library-modal.component';
import { LibraryItemComponent } from './library-item.component';
import { LibraryFoldersComponent } from './library-folders.component';
import { LibraryUploadComponent } from './library-upload.component';
import { LibraryEmbedComponent } from './library-embed.component';
import { LibraryToolboxComponent } from './library-toolbox.component';
import { Angular2PromiseButtonModule } from 'angular2-promise-buttons';
import { SearchPipe } from '../../pipes/search.pipe';
import { OrderPipe } from '../../pipes/order.pipe';
import { FilterFoldersPipe } from '../../pipes/filter-folders.pipe';
import { SearchingPipe } from '../../pipes/searching.pipe';
import { KeyValuePipe } from '../../pipes/keyvalue.pipe';
import { ItemPreviewComponent } from './item-preview/item-preview.component';
import { LiveEditorComponent } from '../../components/live-editor/live-editor.component';
import { LibraryBankComponent } from './library-bank/library-bank.component';
import { ImageBankListComponent } from './library-bank/image-bank-list/image-bank-list.component';
import { TranslatePipe } from '../../pipes/translate.pipe';
import { TranslationService } from '../../services/translation.service';

@NgModule({
    imports: [
        BrowserModule,
        HttpClientModule,
        FormsModule,
        StuplayModule.forRoot(),
        NgxUploaderModule,
        NgbModule,
        Angular2PromiseButtonModule.forRoot(),
    ],
    declarations: [
        LibraryModalComponent,
        LibraryItemComponent,
        LibraryFoldersComponent,
        LibraryUploadComponent,
        LibraryEmbedComponent,
        LibraryToolboxComponent,
        LiveEditorComponent,
        SearchPipe,
        OrderPipe,
        SearchingPipe,
        KeyValuePipe,
        FilterFoldersPipe,
        TranslatePipe,
        ItemPreviewComponent,
        LibraryBankComponent,
        ImageBankListComponent
    ],
    exports: [
        SearchPipe,
        OrderPipe,
        SearchingPipe,
        KeyValuePipe,
        FilterFoldersPipe,
        TranslatePipe,
        LibraryModalComponent,
        LibraryItemComponent,
        LibraryFoldersComponent,
        LibraryUploadComponent,
        LibraryEmbedComponent,
        LiveEditorComponent,
        LibraryToolboxComponent,
        LibraryBankComponent,
        ImageBankListComponent,
        ItemPreviewComponent,
    ],
    providers: [
        TranslationService,
    ]
})
export class LibraryModule { }
