export class CourseRole {
    id: number;
    title: string;
    canManageStaff: boolean;
    canManageUser: boolean;
    canManageGroup: boolean;
    canManageProject: boolean;
    canManageEvent: boolean;
    canManageCorrection: boolean;
    canManageStatistic: boolean;


    constructor(courseRole: any) {
        this.extends(courseRole);
    }

    extends(courseRole: any): CourseRole {
        Object.assign(this, courseRole);

        return this;
    }
}
