<div class="modal-header">
    <span></span>
    <button type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <div class="modal-content" style="border: none;">
        <h3 [innerHtml]="('instance.unpublishing' | translate) + '?'"></h3>
        <p>{{ 'studio.session.unpublish.confirm' | translate }}</p>
    </div>

    <div class="modal-actions" style="display: flex; justify-content: flex-end; margin-top: 10px;">
        <button class="button button--border" (click)="close()">
            {{ 'words.cancel' | translate }}
        </button>
        <button class="button button--border button--primary" (click)="confirm()">
            {{ 'words.confirm' | translate }}
        </button>
    </div>
</div>