<ul class="box" [ngClass]="{'box--sub-level': subFolder}">
    <li class="box__item box__item--sub" *ngFor="let folder of folders; let index=index">
        <div class="grid between middle">
            <div class="folder folder--list grid" [ngClass]="{'active': isSelected(folder)}" [ngStyle]="{'opacity': (isDisabled(folder) ? '0.5' : '1'), 'cursor': (isDisabled(folder) ? 'not-allowed': 'pointer')}" (click)="selectFolder(folder)">
                <div class="folder__icon">
                    <i class="icon icon--folder"></i>
                </div>

                <div class="folder__content box">
                    <h1 class="box__title">
                        {{ (folder.title !== '') ? folder.title : ('words.folder-without-title' | translate) }}
                    </h1>

                    <div class="box__description-group grid middle">
                        <p class="box__description grid center">
                            <span class="box__number">{{ folder.templatesCount}}</span>
                            <i class="icon icon--modul"></i>
                        </p>

                        <p class="box__description grid center">
                            <span class="box__number">{{ folder.foldersCount }}</span>
                            <i class="icon icon--folder"></i>
                        </p>
                    </div>
                </div>
            </div>

            <a class="box__icon-sub" *ngIf="folder.foldersCount > 0" (click)="displaySubfolder(index)">
                <i class="icon" [ngClass]="{'icon--less-circle': displays[index], 'icon--add-circle': !displays[index]}"></i>
            </a>
        </div>

        <msc-subfolder *ngIf="displays[index]" [folders]="folder.subfolders" [object]="object" [subFolder]="true" [disabled]="isDisabled(folder)"></msc-subfolder>
    </li>
</ul>
