import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable()
export class LanguageProvider {
    cached = {};
    constructor(private http: HttpClient) { }
    get(type: 'interface' | 'content'): Observable<any> {
        if (this.cached[type]) {
            return of(this.cached[type]);
        }
        return this.http.get(`${environment.envVar.API_URL}/common/languages?type=${type}`).pipe(map((data: any) => this.cached[type] = data.languages));
    }
}
