<div class="overlay" [ngClass]="{'active': active}"></div>

<aside class="m-sidebar sidebar sidebar--modal" [ngClass]="{'active': active}" (appSidebar)="closeSidebar()">
    <div class="sidebar__container">
        <div class="sidebar__head">
            <div class="sidebar__description">
                <h1 class="sidebar__title">
                    {{ 'words.edit' | translate }}
                </h1>
            </div>

            <a class="sidebar__close" (click)="closeSidebar()">
                <i class="icon icon--close"></i>
            </a>
        </div>

        <div class="m-content">
            <div class="settings-block">
                <div class="settings-block__title">
                    <label>{{ 'studio.sidebar-settings.common.score-max' | translate }}</label>
                </div>

                <div class="settings-block__details">
                    <div class="row">
                        <div class="columns small-12">
                            <input type="text" [(ngModel)]="question.scoreMax" (blur)="updateSettings()">
                        </div>
                    </div>
                </div>
            </div>

            <div class="settings-block">
                <div class="settings-block__title" [ngClass]="{'active': displayHint}">
                    <label>{{ 'studio.sidebar-settings.common.hint' | translate }}
                        <span class="switch switch_height_small" [ngClass]="{'switch_active': displayHint}">
                            <input [(ngModel)]="displayHint" (ngModelChange)="displayedHint()" type="checkbox">
                        </span>
                    </label>
                </div>

                <div class="settings-block__details" *ngIf="displayHint">
                    <div class="row">
                        <div class="columns small-12">
                            <msc-froala [plugins]="'normal'" [height]="200" [(content)]="question.hint" (onUpdate)="updateSettings()"></msc-froala>
                        </div>
                    </div>
                </div>
            </div>

            <div class="settings-block" *ngIf="type !== 'OpenWriteQuestion' && type !== 'OpenAudioQuestion'">
                <div class="settings-block__title" [ngClass]="{'active': displayFeedbacks}">
                    <label>{{ 'studio.sidebar-settings.common.correction' | translate }}
                        <span class="switch switch_height_small" [ngClass]="{'switch_active': displayFeedbacks}">
                            <input [(ngModel)]="displayFeedbacks" (ngModelChange)="displayedFeedBack()" type="checkbox">
                        </span>
                    </label>
                </div>

                <div class="settings-block__details" *ngIf="displayFeedbacks">
                    <div class="row">
                        <div class="columns small-12">
                            <span class="label">{{ 'studio.sidebar-settings.common.correction.right' | translate }}</span>
                            <msc-froala [plugins]="'normal'" [height]="200" [(content)]="question.feedbackDefault" (onUpdate)="updateSettings()"></msc-froala>

                            <span class="label">{{ 'studio.sidebar-settings.common.correction.wrong' | translate }}</span>
                            <msc-froala [plugins]="'normal'" [height]="200" [(content)]="question.feedbackWrongAnswer" (onUpdate)="updateSettings()"></msc-froala>
                        </div>
                    </div>
                </div>
            </div>

            <div class="settings-block" *ngIf="type !== 'OpenWriteQuestion' && type !== 'OpenAudioQuestion'">
<!--                <div class="settings-block__title" [ngClass]="{'active': question.tryMax}">-->
<!--                    <label>-->
<!--                        <span [innerHTML]="'studio.sidebar-settings.common.wrong-answers-limit' | translate"></span>-->

<!--                        <span class="switch switch_height_small switch_active">-->
<!--                            <input type="checkbox">-->
<!--                        </span>-->
<!--                    </label>-->
<!--                </div>-->

                <div class="settings-block__title">
                    <label>{{ 'studio.sidebar-settings.common.wrong-answers-limit' | translate }}</label>
                </div>

                <div class="settings-block__details">
                    <div class="row">
                        <div class="columns small-12">
                            <input type="number" min="1" max="100" [(ngModel)]="question.tryMax" (blur)="updateSettings()">
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="type === 'OpenWriteQuestion'">
                <div class="settings-block">
                    <div class="settings-block__title" [ngClass]="{'active': displayMinLength}">
                        <label>{{ 'studio.sidebar-settings.event.min-length' | translate }}
                            <span class="switch switch_height_small" [ngClass]="{'switch_active': displayMinLength}">
                                <input type="checkbox" [(ngModel)]="displayMinLength" (ngModelChange)="displayedMinLength()">
                            </span>
                        </label>
                    </div>

                    <div class="settings-block__details" *ngIf="displayMinLength">
                        <div class="row">
                            <div class="columns small-12">
                                <input type="number" [(ngModel)]="question.param.minLength" (blur)="updateSettings()">
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="type === 'TrueFalseQuestion'">
                <div class="settings-block">
                    <div class="settings-block__title active">
                        <label>{{ 'studio.sidebar-settings.true-false.response' | translate }}</label>
                    </div>

                    <div class="settings-block__details">
                        <div class="row">
                            <div class="columns small-6">
                                <label class="settings-btn" [ngClass]="{'active': question.param.type == 'yes'}">
                                    <input type="radio" (click)="question.param.type = 'yes'; question.param.choice = true; updateSettings()" [checked]="question.param.choice && question.param.type == 'yes'">
                                    <span>{{ 'studio.sidebar-settings.true-false.response.yes-no' | translate }}</span>
                                </label>
                            </div>

                            <div class="columns small-6">
                                <label class="settings-btn" [ngClass]="{'active': question.param.type === 'true'}" >
                                    <input type="radio" (click)="question.param.type = 'true'; question.param.choice = false; updateSettings()" [checked]="question.param.type === 'true' && !question.param.choice">
                                    <span>{{ 'studio.sidebar-settings.true-false.response.true-false' | translate }}</span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="type === 'OrderedQuestion'">
                <div class="settings-block">
                    <div class="settings-block__title">
                        <label>{{ 'studio.sidebar-settings.ordered.type' | translate }}</label>
                    </div>

                    <div class="settings-block__details">
                        <div class="row">
                            <div class="columns small-10">
                                <select [(ngModel)]="question.param.type" (ngModelChange)="updateSettings()" (select)="question.param.type">
                                    <option value="none">{{ 'studio.sidebar-settings.ordered.type.none' | translate }}</option>
                                    <option value="dragdrop">{{ 'studio.sidebar-settings.ordered.type.dragdrop' | translate }}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="type === 'FillInQuestion'">
                <div class="settings-block">
                    <div class="settings-block__title">
                        <label>{{ 'studio.sidebar-settings.fillin.mode' | translate }}</label>
                    </div>

                    <div class="settings-block__details">
                        <div class="row">
                            <div class="columns small-12">
                                <select [(ngModel)]="question.param.type" (ngModelChange)="updateSettings()" (select)="question.param.type">
                                    <option value="select">{{ 'studio.sidebar-settings.fillin.mode.select' | translate }}</option>
                                    <option value="input" selected>{{ 'studio.sidebar-settings.fillin.mode.input' | translate }}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="type === 'MultipleQuestion'">
                <div class="settings-block">
                    <div class="settings-block__title">
                        <label>
                            {{'studio.sidebar-settings.multiple.list' | translate}}
                        </label>
                    </div>

                    <div class="settings-block__details">
                        <div class="row">
                            <div class="columns small-10">
                                <select [(ngModel)]="question.param.listStyle" (ngModelChange)="updateSettings()" (select)="question.param.listStyle">
                                    <option value="none">{{'studio.sidebar-settings.multiple.none' | translate}}</option>
                                    <option value="number">{{'studio.sidebar-settings.multiple.number' | translate}}</option>
                                    <option value="letter">{{'studio.sidebar-settings.multiple.letter' | translate}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="settings-block">
                    <div class="settings-block__title">
                        <label>{{ 'studio.sidebar-settings.multiple.count-type' | translate }}</label>
                    </div>

                    <div class="settings-block__details">
                        <div class="row">
                            <div class="columns small-10">
                                <select [(ngModel)]="question.param.mode" (ngModelChange)="updateSettings()" (select)="question.param.mode">
                                    <option value="progressive">{{'studio.sidebar-settings.multiple.mode.progressive' | translate}}</option>
                                    <option value="strict">{{ 'studio.sidebar-settings.multiple.mode.strict' | translate }}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</aside>
