import { HttpClient } from '@angular/common/http';
import { Observable } from "rxjs";
import { map } from 'rxjs/operators';
import { Media } from '../../../../media';
import { cleanObject } from "../../../../helpers";
import { environment } from 'src/environments/environment';

export class Exchange {
    id: number;
    media?: Media;

    constructor(exchange: any, protected http: HttpClient) {
        this.extends(exchange);
    }

    extends(exchange: any): Exchange {
        Object.assign(this, exchange);
        return this;
    }

    update(): Observable<Exchange> {
        return this.http.put(`${environment.envVar.API_URL}/studio/exchange/${this.id}`, cleanObject(this))
            .pipe(map(res => this.extends(res)));
    }

    save(): Observable<Exchange> {
        let baseObs: Observable<any>;
        if (this.id) {
            baseObs = this.http.put(`${environment.envVar.API_URL}/studio/project/${this.id}`, cleanObject(this));
        } else {
            baseObs = this.http.post(`${environment.envVar.API_URL}/studio/project`, cleanObject(this));
        }

        return baseObs.pipe(map((object: object) => this.extends(object)));
    }
}
