import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { Condition } from '@stuplay';
import * as moment from 'moment';
import { EmitterService } from '../../../../utils/services/emitter.service';

@Component({
    selector: 'journey-condition',
    templateUrl: './journey-condition.component.html'
})
export class JourneyConditionComponent implements OnInit {
    @Input() content: any;
    @Input() conditions: any;
    @Input() first: boolean = false;
    @Input() last: boolean = false;
    @Output() activeSidebar: EventEmitter<any> = new EventEmitter;
    @Output() addCondition: EventEmitter<any> = new EventEmitter;
    @Output() updateCondition: EventEmitter<any> = new EventEmitter;
    @Output() deleteCondition: EventEmitter<any> = new EventEmitter;
    public canActive: boolean = true;
    public conditionDate: Condition;
    public conditionScore: Condition;
    public activeScore: boolean = false;
    public score: number = 50;
    public active: boolean = false;
    public activeAside: boolean = false;
    public data: any;
    public datePickerActive: boolean = false;

    constructor(private emitterService: EmitterService) { }

    ngOnInit() {
        this.checkConditions();

        this.emitterService.refreshCondition.subscribe(() => {
            this.checkConditions();
        });
    }

    checkConditions(): void {
        const find: any = {
            date: false,
            score: false
        };
        if (this.conditions) {
            for (const condition of this.conditions) {
                if (condition.type !== 'score') {
                    find.date = true;
                    this.conditionDate = condition;
                }

                if (condition.type === 'score') {
                    find.score = true;
                    this.conditionScore = condition;
                    this.activeScore = true;
                    this.score = parseInt(this.conditionScore.value, 10);
                }
            }
        }

        if (!find.date) {
            this.conditionDate = null;
        }

        if (!find.score) {
            this.conditionScore = null;
            this.activeScore = false;
            this.score = 50;
        }
    }

    initialize(): void {
        this.data = {
            checkedDate: true,
            checkedDay: false,
            checkedSignin: !this.first,
            checkedLast: false,
            valueDate: '',
            valueDay: ''
        };

        if (this.conditionDate) {
            if (this.conditionDate.type === 'date') {
                this.data.valueDate = moment(this.conditionDate.value).format('DD/MM/YYYY');
            } else if (this.conditionDate.type === 'subscription_date') {
                this.data.checkedDate = false;
                this.data.checkedDay = true;
                this.data.valueDay = this.conditionDate.value;
            } else {
                this.data.checkedDate = false;
                this.data.checkedDay = true;
                this.data.checkedSignin = false;
                this.data.checkedLast = true;
                this.data.valueDay = this.conditionDate.value;
            }
        }
    }

    openTransition(): void {
        this.initialize();
        this.activeSidebar.emit(true);
        this.active = true;

        const timeOut = setTimeout(() => {
            this.activeAside = true;
            clearTimeout(timeOut);
        }, 100);
    }

    activeDatepicker(closeForce?: boolean): void {
        if (closeForce) {
            this.datePickerActive = false;

            const timeOut = setTimeout(() => {
                this.canActive = true;
                clearTimeout(timeOut);
            }, 100);
        } else {
            if (this.canActive) {
                this.datePickerActive = true;
                this.canActive = false;
            }
        }
    }

    selectedDate(date?: any): void {
        this.data.valueDate = (date) ? date.format('DD/MM/YYYY') : '';
    }

    closeSidebar(): void {
        this.activeAside = false;

        const timeOut = setTimeout(() => {
            this.activeSidebar.emit(false);
            this.active = false;
            clearTimeout(timeOut);
        }, 300);
    }

    checkedDate(): void {
        this.data.checkedDate = true;
        this.data.checkedDay = false;
    }

    checkedDay(): void {
        this.data.checkedDay = true;
        this.data.checkedDate = false;
    }

    checkedOptions(checked: boolean): void {
        this.data.checkedSignin = !checked;
        this.data.checkedLast = checked;
    }

    addTransition(): void {
        this.addCondition.emit(this.emitters());
        this.closeSidebar();
    }

    updateTransition(): void {
        this.dispatchUpdate();
        this.closeSidebar();
    }

    deleteTransition(): void {
        this.deleteCondition.emit(this.emitters());
        this.closeSidebar();
    }

    emitters(): any {
        const emitters: any = [];

        if (this.emitterDate()) {
            emitters.push(this.emitterDate());
        }

        if (this.emitterScore()) {
            emitters.push(this.emitterScore());
        }

        return emitters;
    }

    emitterDate(): any {
        if (this.data.valueDate || this.data.valueDay === 0 || this.data.valueDay) {
            const date = moment(moment(this.data.valueDate, 'DD/MM/YYYY')).format('YYYY-MM-DD');
            const emit = { id: 0, type: 'date', value: date };

            if (this.data.checkedDay) {
                emit.type = 'subscription_date';
                emit.value = this.data.valueDay;

                if (this.data.checkedLast) {
                    emit.type = 'achievement_date';
                }
            }

            if (this.conditionDate) {
                emit.id = this.conditionDate.id;
            }

            return emit;
        }
        return;
    }

    emitterScore(): any {
        if (this.activeScore) {
            return { id: this.conditionScore ? this.conditionScore.id : 0, type: 'score', value: this.score };
        }
        return;
    }

    dispatchUpdate(): void {
        const currentConditions = this.emitters().filter((condition) => {
            return condition.id !== 0;
        });
        const newConditions = this.emitters().filter((condition) => {
            return condition.id === 0;
        });
        const deleteConditions = this.conditions.filter((condition) => {
            return currentConditions.findIndex((cond) => {
                return cond.id === condition.id;
            }) === -1;
        });

        this.addCondition.emit(newConditions);
        this.updateCondition.emit(currentConditions);
        this.deleteCondition.emit(deleteConditions);
    }

    enableScore(): void {
        this.activeScore = !this.activeScore;
        this.score = 50;
    }
}
