import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { CanActivate } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { RedirectService } from '../services/redirect.service';
import { StorageService } from '../services/storage.service';

@Injectable()
export class CompanyGuard implements CanActivate {

    constructor(
        private auth: AuthService,
        private router: Router,
        private storageService: StorageService,
        private redirectService: RedirectService,
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const company: any = this.storageService.get('company');

        if (company) {
            const slug = company.slug;
            this.router.navigate(['company', slug]);
            return true;
        }

        this.redirect();
        return false;
    }

    redirect(): void {
        this.redirectService.navigate('app', ['login']);
    }
}
