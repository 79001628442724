import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'toArray'
})
export class ToArrayPipe implements PipeTransform {

    transform(value: number): any {
        const array = [];

        for (let i = 0; i < value; i++) {
            array.push(i);
        }

        return array;
    }
}
