<div class="templab-library-event">
    <div class="templab-library-event__body">
        <div class="sidebar__container">
            <div class="settings-event">
                <div class="settings-block">
                    <div class="settings-block__title">
                        <label>{{ 'studio.sidebar-settings.event.type' | translate }}</label>
                    </div>

                    <div class="settings-block_details">
                        <div class="row">
                            <div class="columns small-12">
                                <select [(ngModel)]="sectionContent.event.type" (ngModelChange)="updateSettings()" (select)="sectionContent.event.type">
                                    <option value="place">{{ 'studio.sidebar-settings.event.type.place' | translate }}</option>
                                    <option value="visio">{{ 'studio.sidebar-settings.event.type.visio' | translate }}</option>
                                    <option value="virtual-class" *ngIf="enable.virtualClass">{{ 'studio.sidebar-settings.event.type.virtual-class' | translate }}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <app-event-single-session [sectionContent]="sectionContent" [event]="sectionContent.event"
                                      [eventSession]="sectionContent.event.eventSessions[0]" [trajectId]="journey.id">
            </app-event-single-session>
        </div>
    </div>

    <footer class="templab-library-event__footer">
        <div class="sidebar__btn">
            <label class="input">
                <input type="checkbox" class="input-checkbox" [ngModel]="notifyUsers">
                {{ 'instance.invite-notify' | translate }}
            </label>

            <div class="sidebar__btn-right grid">
                <a class="templab-btn templab-btn--secondary" (click)="addActivity()" *ngIf="!content">
                    <i class="templab-icon templab-icon-checkmark"></i>
                    {{ 'studio.journey.add-event' | translate }}
                </a>

                <a class="templab-btn templab-btn--secondary" (click)="editActivity()" *ngIf="content">
                    <i class="templab-icon templab-icon-checkmark"></i>
                    {{ 'words.modify' | translate }}
                </a>
            </div>
        </div>
    </footer>
</div>
