import { Component, Input } from '@angular/core';
import { Folder } from '@stuplay';
import { SubfolderService } from './subfolder.service';

@Component({
    selector: 'msc-subfolder',
    templateUrl: './subfolder.component.html',
})
export class SubfolderComponent {
    @Input() folders: Folder[];
    @Input() object: any;
    @Input() subFolder: boolean = false;
    @Input() disabled: boolean = false;
    public displays: boolean[] = [];

    constructor(private subfolderService: SubfolderService) { }

    selectFolder(folder: Folder): void {
        if (!this.isDisabled(folder)) {
            this.subfolderService.select(folder);
        }
    }

    isSelected(folder: Folder): boolean {
        return this.subfolderService.selected() === folder;
    }

    displaySubfolder(index: number): void {
        this.displays[index] = !this.displays[index];
    }

    isDisabled(folder: Folder): boolean {
        return (this.object.type === 'Folder' && (this.object.item.id === folder.id || this.object.item.parentId === folder.id)) || this.disabled;
    }
}
