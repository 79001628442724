export interface TrueFalseAnswer {
    choice: boolean;
    isCorrect?: boolean;
}

export interface OpenWriteAnswer {
    text: string;
}

export interface MultipleAnswer {
    choices: MultipleAnswerChoice[];
}

export interface MultipleAnswerChoice {
    choice: string;
    state: boolean;
    isCorrect?: boolean;
}

export interface LinkerAnswer {
    links: string[];
    choices: LinkerAnswerChoice[];
}

export interface LinkerAnswerChoice {
    name: string;
    isCorrect?: boolean;
}

export interface CategoryAnswer {
    choices: CategoryAnswerChoice[];
}

export interface CategoryAnswerChoice {
    type: string;
    value: string|number;
    category: string;
    isCorrect?: boolean;
}

export interface FillInAnswer {
    choices: FillInAnswerChoice[];
}

export interface FillInAnswerChoice {
    choice: string;
    isCorrect?: boolean;
    id: number;
}

export interface OrderedAnswer {
    choices: OrderedAnswerChoice[];
}

export interface OrderedAnswerChoice {
    choice: string[];
    statement?: string[];
    isCorrect: boolean;
}

export interface ProcedureAnswer {
    choices: ProcedureAnswerChoice[];
}

export interface ProcedureAnswerChoice {
    choice: string;
    isCorrect: boolean;
}

export interface UniqueAnswer {
    choices: UniqueAnswerChoice[];
}

export interface UniqueAnswerChoice {
    choice: string;
    state: boolean;
    isCorrect?: boolean;
}
