<msc-header></msc-header>

<div class="container grid">
	<media-library-sidebar [tab]="tab"
                           (filterMedia)="filterMedias($event)"
                           (sendTab)="setTab($event)">
	</media-library-sidebar>

	<div *ngIf="tab === 'video' || tab === 'image' || tab === 'document'  || tab === 'sound' || tab === 'embed'" class="margin-module" style="margin: 2rem 0 0 32px;">
		<library-folders [medias]="medias"
						 [currentUser]="me"
						 [pagination]="pagination"
						 [toUpload]="toUpload"
						 [refresh]="refresh"
						 (showItemPreview)="showPreview($event)">
		</library-folders>

		<library-item-preview *ngIf="showItemPreview"
							  [media]="itemToPreview"
							  [currentUser]="me"
							  [toUpload]="toUpload"
							  [library]="true"
							  (close)="closePreview()"
							  (archivedMedia)="archivedMedia($event)">
		</library-item-preview>
	</div>

	<library-upload *ngIf="tab === 'import'" [toUpload]="toUpload" (redirect)="redirect($event)" class="margin-module"></library-upload>

	<library-bank *ngIf="tab === 'databank'" [toUpload]="toUpload" (redirect)="redirect($event)" class="margin-module"></library-bank>

	<library-embed *ngIf="tab === 'integration' || tab === 'iframe'" [toUpload]="toUpload" (redirect)="redirect($event)" [importType]="tab === 'integration' ? 'url' : 'iframe'" class="margin-module"></library-embed>

    <library-toolbox *ngIf="tab === 'toolbox'" class="margin-module"></library-toolbox>
</div>

<style>
	.margin-module {
		margin: 2rem 0 0 0;
		flex-grow: 1;
	}
</style>
