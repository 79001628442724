import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Event as NavigationEvent, NavigationEnd } from '@angular/router';
import { StorageService } from '../../utils/services/storage.service';
import { TranslationService } from '../../utils/services/translation.service';
import { ExternalAppsService } from '../../utils/services/external-apps.service';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'company',
    templateUrl: './company.component.html',
    styleUrls: ['./company.component.less']
})
export class CompanyComponent implements OnInit {
    private company: any;
    private me: any;
    public applications: any;
    public favicon: any;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private storageService: StorageService,
        private translationService: TranslationService,
        private externalAppsService: ExternalAppsService,
    ) { }

    ngOnInit() {
        this.company = this.storageService.get('company');
        this.me = this.storageService.getAndSet('me', this.route.snapshot.data.me);
        this.applications = this.storageService.getAndSet('applications', this.route.snapshot.data.applications);
        this.favicon = document.querySelector('#favicon');

        this.translationService.setLang(this.me.language.code);

        this.externalAppsService.bootSentry(this.me);

        this.storageService.set('applications', this.applications);
        this.externalAppsService.boot(this.me, this.company, this.company.roles, this.applications);

        this.router.events.forEach((event: NavigationEvent) => {
            if (event instanceof NavigationEnd) {
                if (environment.name.short !== 'dev' && this.externalAppsService.appcues()) {
                    this.externalAppsService.appcues().page();
                }
            }
        });

        this.processFavicon();
    }

    processFavicon(): void {
        if (this.company.experience.url && this.company.experience.url.media) {
            this.favicon.href = this.company.experience.url.media.pictureUrl + '?size=64';
        }
    }
}
