import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';

const Unsplash = require('unsplash-js').default;

@Component({
    selector: 'library-bank',
    templateUrl: './library-bank.component.html'
})
export class LibraryBankComponent implements OnInit {
    @Input() toUpload: boolean;
    @Output() updateMedia = new EventEmitter();
    @Output() redirect = new EventEmitter();
    public unsplash: any;
    public images: any[];
    public collections: any[];
    public showList: boolean = false;
    public query: string;
    public page: number = 1;
    public collectionPage: number = 1;
    public collectionId: number;
    public processing: boolean = false;
    public loadAvailable: boolean = true;

    constructor(
        public http: HttpClient,
    ) {
        this.unsplash = new Unsplash({
            applicationId: environment.unsplashAccess,
            secret: environment.unsplashSecret,
            callbackUrl: '#'
        });
    }

    ngOnInit() {
        this.unsplash.collections.listCollections(1, 10, 'popular').then((res) => res.json())
            .then(collections => {
                this.collections = collections;
            });
    }

    getPhotos(): void {
        if (this.query) {
            this.images = [];
            this.page = 1;
            this.unsplash.search.photos(this.query, this.page, 28).then((res) => res.json())
                .then(res => {
                    this.images = res.results;
                    this.showList = true;
                });
        }
    }

    getCollectionPhotos(id: number): void {
        this.images = [];
        this.unsplash.collections.getCollectionPhotos(id, 1, 28, 'popular').then((res) => res.json())
            .then(images => {
                this.images = images;
                this.showList = true;
                this.loadAvailable = true;
            });
    }

    loadMore(): void {
        if (this.showList && !this.collectionId) {
            this.loadMorePhotos();
        } else if (!this.showList) {
            this.loadMoreCollections();
        } else {
            this.loadMoreCollectionPhotos();
        }
    }

    loadMorePhotos(): void {
        this.processing = true;
        this.unsplash.search.photos(this.query, this.page + 1, 28).then((res) => res.json())
            .then(res => {
                res.results.forEach((image) => {
                    this.images.push(image);
                });
                this.images = this.images.slice();
                this.page += 1;
                this.processing = false;
            });
    }

    loadMoreCollections(): void {
        this.processing = true;
        this.collectionPage += 1;
        this.unsplash.collections.listCollections(this.collectionPage, 10, 'popular').then((res) => res.json())
            .then(collections => {
                collections.forEach((collection) => {
                    this.collections.push(collection);
                    this.processing = false;
                });

                if (collections.length < 10) {
                    this.loadAvailable = false;
                }
            });
    }

    loadMoreCollectionPhotos(): void {
        this.processing = true;
        this.unsplash.collections.getCollectionPhotos(this.collectionId, this.page + 1, 28, 'popular').then((res) => res.json())
            .then(images => {
                images.forEach((image) => {
                    this.images.push(image);
                });
                this.images = this.images.slice();
                this.processing = false;
                if (images.length < 28) {
                    this.loadAvailable = false;
                }
            });
        this.page += 1;
    }

    uploadMedia(url: string): void {
        this.http.post(`${environment.envVar.API_URL}/media`, { url: url, title: 'IMG', type: 'IMG', size: 0, status: 1 }).subscribe(data => {
            this.updateMedia.emit(data);

            if (!this.toUpload) {
                this.redirect.emit('image');
            }
        });
    }
}
