import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'responsive-tool',
    templateUrl: './responsive.component.html',
    styleUrls: ['./responsive.component.less']

})

export class ResponsiveToolComponent implements OnInit {
    @Input() size: string;
    @Output() updatedSize: EventEmitter<string> = new EventEmitter();


    ngOnInit() {
        this.size = 'desktop';
    }

    setResponsiveSize(size) {
        this.size = size;
        this.updatedSize.emit(size);
    }

    isResponsiveSize(size) {
        return this.size === size;
    }
}
