import { Component, OnInit, Output, EventEmitter, Input, ElementRef } from '@angular/core';

@Component({
    selector: 'journey-button',
    templateUrl: './journey-button.component.html'
})
export class JourneyButtonComponent implements OnInit {
    @Input() company: any;
    @Input() loadingEvent: boolean = false;
    @Output() onSelect: EventEmitter<any> = new EventEmitter();
    @Output() onCreateEvent: EventEmitter<any> = new EventEmitter();
    public displays: any;

    constructor() { }

    ngOnInit() {
        this.displays = {
            actions: false
        }
    }

    displayed(type: string): void {
        this.displays[type] = !this.displays[type];

        setTimeout(() => {
            window.scroll(0, document.documentElement.scrollTop + 75);
        }, 100);
    }

    createEvent(): void {
        this.onCreateEvent.emit();
    }

    select(action: string): void {
        this.onSelect.emit(action);
    }
}
