import { Component, Input, HostListener, ViewChild, ElementRef, NgZone } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Course, SectionContent, Question, Collaborator, CourseProvider, UserProvider, SectionProvider, SectionContentProvider } from '@stuplay';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { StorageService } from '../../services/storage.service';
import { EmitterService } from '../../services/emitter.service';
import { ExternalAppsService } from '../../services/external-apps.service';
import { DataHelper } from '../../helpers';
import { RedirectService } from '../../services/redirect.service';
import { map, mergeScan, repeat, switchMap, takeUntil, tap } from 'rxjs/operators';
import { moveItemInArray, CdkDragDrop } from '@angular/cdk/drag-drop';
import { BehaviorSubject, of, Subject } from 'rxjs';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.less'],
})
export class SidebarComponent {
    @ViewChild('modalUnableToEdit') modalUnableToEdit: ElementRef;
    @Input() course: Course;

    public currentCompany: any;
    public company: any;
    public me: any;
    public activeSiteSection: string;
    public canMove: boolean = true;
    public processing: boolean = false;
    public companyContent: boolean = false;
    public sharedCompanies: any[] = [];
    public selectedSharedCompany: any;
    public pendingCollaboratorToDelete: Collaborator;
    public sectionOpen: Array<boolean> = [];
    public settings: any;
    public sections = {};
    public activePanel: string = 'course';
    public error: any;
    public locks: boolean = false;
    public addSectionError: any;
    public isSectionContentDraggable = false;
    public isExerciseContentDraggable = false;
    public isSectionDraggable = false;
    public intermediateSectionId: string;
    public query: string = '';
    public sectionUpdating: boolean = false;
    public currentLang: string;
    public selectedCompany: string;
    public isPreview: boolean = false;
    //
    private destroy$: Subject<void> = new Subject<void>();
    //
    public collaborators$: BehaviorSubject<any[]> = new BehaviorSubject<Collaborator[]>([]);
    public collaboratorsPage$: BehaviorSubject<number> = new BehaviorSubject<number>(1);
    public collaboratorsRepeat$: Subject<void> = new Subject<void>();
    public collaboratorsQuery$: BehaviorSubject<any> = new BehaviorSubject<any>({});
    public collaboratorsStatus$: BehaviorSubject<string> = new BehaviorSubject<string>('loading');
    public collaboratorsHasLoadMore: boolean = false;
    //
    private collaboratorDebounce$: Subject<boolean> = new Subject();
    private isCollaboratorDebounceSet = false;
    public collaboratorsAvailable$: BehaviorSubject<any[]> = new BehaviorSubject<Collaborator[]>([]);
    public collaboratorsAvailablePage$: BehaviorSubject<number> = new BehaviorSubject<number>(1);
    public collaboratorsAvailableRepeat$: Subject<void> = new Subject<void>();
    public collaboratorsAvailableStatus$: BehaviorSubject<string> = new BehaviorSubject<string>('loading');
    public collaboratorsAvailableHasLoadMore: boolean = false;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private storageService: StorageService,
        private courseProvider: CourseProvider,
        private sectionProvider: SectionProvider,
        private userProvider: UserProvider,
        private emitterService: EmitterService,
        private externalAppsService: ExternalAppsService,
        private sectionContentProvider: SectionContentProvider,
        private redirectService: RedirectService,
        public zone: NgZone,
        private modalService: NgbModal,
    ) { }

    ngOnInit() {
        this.me = this.storageService.get('me');
        this.company = this.storageService.get('company');
        this.currentCompany = this.storageService.get('company');
        this.companyContent = this.course.shared;
        this.isPreview = this.route.snapshot.queryParams.isPreview;
        this.getAvailableCompanies();

        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                const eventFragment = this.router.parseUrl(event.url);
                this.siteURLActiveCheck(event, eventFragment.fragment);
                if (eventFragment.fragment) {
                    this.scroller(eventFragment.fragment);
                }
            }
        });

        this.emitterService.refreshCourse.subscribe((data: any) => {
            this.refreshCourse();
        });

        this.emitterService.unableToEdit.subscribe(() => {
            // this.modalUnableToEdit.open();
            this.openModal(this.modalUnableToEdit);
        })
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
        this.collaboratorsRepeat$.complete();
        this.collaboratorsAvailableRepeat$.complete();
    }

    openModal(content: any) {
        const options: NgbModalOptions = { size: 'xl', centered: true, backdropClass: 'backdrop-custom' };
        this.modalService.open(content, options);
    }

    scroller(fragment: string): void {
        const timeOut = setTimeout(() => {
            const element: any = document.getElementById(fragment);
            if (element) {
                const params: any = {
                    block: 'start',
                    inline: 'start',
                    behavior: 'smooth'
                };
                element.scrollIntoView(params);
            }
            clearTimeout(timeOut);
        }, 100);
    }

    private refreshCourse(): void {
        this.courseProvider.getCourse(this.course.slug, { company_id: this.company.id, 'includes': 'sections,sections.sectionContents' }).subscribe((data) => {
            this.course = data;
        });
    }

    defineIntermediate(id: number): void {
        this.intermediateSectionId = String(id);
    }

    getAvailableCompanies(): void {
        const params = {
            permission: 'access_company_courses',
            role: 'company_author'
        };

        this.userProvider.getCompanies(params).subscribe((data) => {
            const index = data.findIndex(company => company.id === this.company.id);

            this.sharedCompanies = data;
            if (this.sharedCompanies.length > 0) {
                this.selectedSharedCompany = this.sharedCompanies[index > -1 ? index : 0];
                this.selectedCompany = this.selectedSharedCompany.id;
            }
        });
    }

    siteURLActiveCheck(event: NavigationEnd, string: string): void {
        if (event.url.indexOf('#' + string) !== -1) {
            this.activeSiteSection = string;
        } else {
            this.activeSiteSection = '';
        }
    }

    isSectionActive(section: string): boolean {
        return section === this.activeSiteSection;
    }

    @HostListener('document:keypress', ['$event', 'type'])
    setUpDragAndDrop(event: MouseEvent, type: string): void {
        if (type === 'section') {
            this.isSectionContentDraggable = this.isExerciseContentDraggable = false;
            this.isSectionDraggable = true;
        }
        if (type === 'sectionContent') {
            this.isSectionContentDraggable = true;
            this.isExerciseContentDraggable = this.isSectionDraggable = false;
        }
        if (type === 'exercise') {
            this.isSectionContentDraggable = this.isSectionDraggable = false;
            this.isExerciseContentDraggable = true;
        }
    }

    openSection(id: number): void {
        this.sections[id] = true;
    }

    toggleSection(id: number): void {
        this.sections[id] = !this.sections[id];
    }

    isOpenedSection(id: number): boolean {
        if (this.sections[id]) {
            return this.sections[id];
        } else {
            return false;
        }
    }

    onCloseSettings(): void {
        this.settings = null;
    }

    switchPanel(panel: string): void {
        this.activePanel = panel;
        if (panel === 'collaborators' && !this.collaborators$.getValue().length && this.course.schema === 'template') {
            this.onLoadCollaborators();
            this.onLoadCollaboratorsAvailable();
        }
    }

    /**
     *
     */
    getParamsOnLoadCollaborators() {
        return Object.assign({
            page: this.collaboratorsPage$.getValue(),
            ...this.collaboratorsQuery$.getValue()
        });
    }

    /**
     *
     */
    getParamsOnLoadCollaboratorsAvailable() {
        return Object.assign({
            page: this.collaboratorsAvailablePage$.getValue(),
            ...this.collaboratorsQuery$.getValue()
        });
    }

    /**
     * 
     */
    onSearchCollaborator() {
        this.collaboratorsStatus$.next('loading');
        this.collaboratorsAvailableStatus$.next('loading');
        this.collaboratorsPage$.next(1);
        this.collaboratorsAvailablePage$.next(1);
        this.collaboratorsQuery$.next({ q: this.query });
        this.onLoadCollaborators(false);
        this.onLoadCollaboratorsAvailable(false);
    }

    /**
     * 
     */
    onLoadCollaborators(isAppend: boolean = true): void {
        if (!this.isCollaboratorDebounceSet) {
            // we are cancelling existing request if a new one is made
            this.collaboratorDebounce$.pipe(
                switchMap((append) =>
                    this.course.getCollaborators(this.getParamsOnLoadCollaborators())
                        .pipe(
                            tap((response: any) => this.onTapGetPaginationLoadCollaborators(response.pagination)),
                            map((response: any) => this.onMapLoadCollaborators(response.data)),
                            mergeScan((acc, items) => of([...acc, ...items]), append ? this.collaborators$.getValue() : []),
                            tap((data: any) => this.onTapGetDataOnLoadCollaborators(data)),
                            repeat({ delay: () => this.collaboratorsRepeat$ }),
                            takeUntil(this.destroy$),
                        )
                )
            ).subscribe();
            this.isCollaboratorDebounceSet = true;
        }
        this.collaboratorDebounce$.next(isAppend);
    }

    /**
     * 
     */
    onLoadCollaboratorsAvailable(isAppend: boolean = true): void {
        this.course
            .getCollaboratorsAvailable(this.getParamsOnLoadCollaboratorsAvailable())
            .pipe(
                tap((response: any) => this.onTapGetPaginationLoadCollaboratorsAvailable(response.pagination)),
                map((response: any) => this.onMapLoadCollaboratorsAvailable(response.data)),
                mergeScan((acc, items) => of([...acc, ...items]), isAppend ? this.collaboratorsAvailable$.getValue() : []),
                tap((data: any) => this.onTapGetDataOnLoadCollaboratorsAvailable(data)),
                repeat({ delay: () => this.collaboratorsAvailableRepeat$ }),
                takeUntil(this.destroy$),
            )
            .subscribe();
    }

    /**
     * 
     */
    onTapGetPaginationLoadCollaborators(pagination: any) {
        this.collaboratorsHasLoadMore = pagination.currentPage !== pagination.lastPage;
    }

    /**
     * 
     */
    onTapGetPaginationLoadCollaboratorsAvailable(pagination: any) {
        this.collaboratorsAvailableHasLoadMore = pagination.currentPage !== pagination.lastPage;
    }

    /**
     * 
     */
    onMapLoadCollaborators(data: Array<any>) {
        if (!Array.isArray(data)) { return []; }

        return data.map((datum: any) => new Collaborator(datum));
    }

    /**
     * 
     */
    onMapLoadCollaboratorsAvailable(data: Array<any>) {
        if (!Array.isArray(data)) { return []; }

        return data.map((datum: any) => new Collaborator(datum));
    }

    /**
     * 
     */
    onTapGetDataOnLoadCollaborators(data) {
        this.collaborators$.next(data);
        this.collaboratorsStatus$.next('success');
    }

    /**
     * 
     */
    onTapGetDataOnLoadCollaboratorsAvailable(data) {
        this.collaboratorsAvailable$.next(data);
        this.collaboratorsAvailableStatus$.next('success');
    }

    /**
     *
     */
    onLoadMoreCollaborators(): void {
        this.collaboratorsPage$.next(this.collaboratorsPage$.getValue() + 1);
        this.onLoadCollaborators();
    }

    /**
     * 
     */
    onLoadMoreCollaboratorsAvailable(): void {
        this.collaboratorsAvailablePage$.next(this.collaboratorsAvailablePage$.getValue() + 1);
        this.onLoadCollaboratorsAvailable();
    }

    addCollaborator(collaborator: Collaborator): void {
        this.collaboratorsAvailable$.next(this.collaboratorsAvailable$.getValue().filter(existingCollaborator => existingCollaborator.id !== collaborator.id));
        this.collaborators$.next([...this.collaborators$.getValue(), { ...collaborator, notUpdated: true }]);

        this.course.addCollaborator(collaborator.id, this.currentCompany.id).subscribe(response => {
            //success
            this.onLoadCollaborators(false);
        }, error => {
            // in case of error, revert
            this.collaboratorsAvailable$.next([...this.collaboratorsAvailable$.getValue(), collaborator]);
            this.collaborators$.next(this.collaborators$.getValue().filter(availableCollaborator => availableCollaborator.id !== collaborator.id));
        })
    }

    deleteCollaborator(collaborator: Collaborator, force: boolean = false, content: any): void {
        if (collaborator.id === this.me.id && !force) {
            this.openModal(content);
            this.pendingCollaboratorToDelete = collaborator;
            return;
        }

        this.collaborators$.next(this.collaborators$.getValue().filter(existingCollaborator => existingCollaborator.id !== collaborator.id));
        this.collaboratorsAvailable$.next([...this.collaboratorsAvailable$.getValue(), collaborator]);

        this.course.updateCollaborator(collaborator.id, collaborator.courseUser.role.id, false, this.company.id).subscribe(response => {
            //success
        }, error => {
            // in case of error, revert
            this.collaborators$.next([...this.collaborators$.getValue(), collaborator]);
            this.collaboratorsAvailable$.next(this.collaboratorsAvailable$.getValue().filter(availableCollaborator => availableCollaborator.id !== collaborator.id));
        });
    }

    updateSectionContent(sectionContent: SectionContent, index: number): void {
        index = index + 1;
        sectionContent.position = index;
        sectionContent.update().pipe(switchMap(() => this.course.update(this.company.id))).subscribe();
    }

    updatePosition(): void {
        this.sectionUpdating = true;

        const params = {
            section_ids: this.course.sections.map((section) => section.id)
        };

        this.sectionProvider.updatePosition(this.course.id, params).subscribe(() => {
            this.sectionUpdating = false;
        });
    }

    updateQuestion(question: Question, index: number): void {
        index = index + 1;
        question.position = index;
        question.update(question.type).subscribe(
            () => {
            },
            (error) => {
                this.error = error
            }
        );
    }

    modalClose(event: any): void {
        //this.modalPublish.close();
    }

    addSection(): void {
        this.locks = false;
        if (this.locks === false) {
            this.course.addSection().subscribe((course) => {
                const section = course.sections[course.sections.length - 1];
                this.router.navigate(['section', section.id], { relativeTo: this.route, fragment: 's-' + section.id });
            }, (error) => {
                this.addSectionError = error
            });
        }
        this.locks = true;
    }

    checkPosition(section: any, sectionContent: any, direction: string): boolean {
        if (direction === 'up') {
            return sectionContent.position + 1 < section.sectionContents.length;
        } else if (direction === 'down') {
            return sectionContent.position - 1 >= 0;
        }
    }

    stopPropagation(event: any): void {
        event.stopPropagation();
    }

    changePosition(section: any, sectionContent: any, direction: string): void {
        if (this.canMove && this.checkPosition(section, sectionContent, direction)) {
            this.canMove = false;
            this.processing = true;

            this.reposition(section);
            DataHelper.move(section.sectionContents, sectionContent.position, direction === 'up' ? sectionContent.position + 1 : sectionContent.position - 1);
            this.reposition(section);

            const params = {
                section_content_ids: section.sectionContents.map((content) => content.id)
            };
            this.sectionContentProvider.updatePosition(this.course.id, section.id, params).subscribe(() => {
                this.canMove = true;
                this.processing = false;
            }, () => {
                this.canMove = true;
                this.processing = false;
            });
        }
    }

    reposition(section: any): void {
        for (let i = 0; i < section.sectionContents.length; i++) {
            section.sectionContents[i].position = i;
        }
    }

    publishInstance(template: any): void {
        this.storageService.set('template', template);
        this.router.navigate(['company', this.storageService.get('company').slug, 'instance', template.slug]);
    }

    openModalCompanyContent(content: any): void {
        if (!this.companyContent) {
            this.openModal(content);
        }
    }

    validateCompanyContent(): void {
        const params = {
            company_id: this.selectedSharedCompany.id
        };

        this.courseProvider.companyContent(this.course.id, params).subscribe(() => {
            this.companyContent = true;
            this.modalService.dismissAll();
        });
    }

    openAsideHelp(type: string): any {
        this.emitterService.activeHelp.emit({ type: type, feature: 'exercice' });
    }

    selectCompany(): void {
        const index = this.sharedCompanies.findIndex((company) => {
            return company.id === parseInt(this.selectedCompany, 10);
        });

        if (index > -1) {
            this.selectedSharedCompany = this.sharedCompanies[index];
        }
    }

    goToPreview(): void {
        this.zone.run(() => this.redirectService.navigate('player', ['course', this.course.id], { preview: true }));
    }

    dropped(event: CdkDragDrop<string[]>) {
        moveItemInArray(
            this.course.sections,
            event.previousIndex,
            event.currentIndex
        );
        moveItemInArray(
            this.sectionOpen,
            event.previousIndex,
            event.currentIndex
        )
        this.updatePosition();
    }
}
