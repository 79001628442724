import { Component, Input, Output, OnInit, EventEmitter, HostListener, ElementRef } from '@angular/core';
import { TranslationService } from '../../../services/translation.service';

@Component({
    selector: 'input-duration',
    templateUrl: './input-duration.component.html',
    styleUrls: ['./input-duration.component.less']
})
export class InputDurationComponent implements OnInit {
    @Input() dataInput: any;
    @Input() disable: boolean = false;
    @Output() dataOutput: EventEmitter<any> = new EventEmitter;
    public units: any = { d: 'jr', h: 'hr', m: 'min' };
    public unit: string = 'min';
    public duration: number = 0;
    public open = false;
    public lang: string = 'fr';

    constructor(
        private elementRef: ElementRef,
        private translationService: TranslationService,
    ) { }

    ngOnInit() {
        this.lang = this.translationService.getCurrentLang();
        this.setUnits();

        this.duration = this.dataInput;
        if (this.duration % 60 === 0) {
            this.duration /= 60;
            this.unit = this.getUnit('m');
        }

        if (this.duration % 60 === 0) {
            this.duration /= 60;
            this.unit = this.getUnit('h');
        }

        if (this.duration % 24 === 0) {
            this.duration /= 24;
            this.unit = this.getUnit('d');
        }
    }

    setUnit(unit: string): void {
        this.unit = unit;
        this.updateDuration();
        this.open = false;
    }

    isUnit(unit: string): boolean {
        return this.unit === unit;
    }

    setUnits(): void {
        if (this.lang === 'en') {
            this.units = { d: 'day', h: 'hr', m: 'min' };
        } else if (this.lang === 'nl') {
            this.units = { d: 'dag', h: 'uur', m: 'min' };
        }
    }

    filterUnits(): string[] {
        const units = [];
        for (const key in this.units) {
            units.push(this.units[key]);
        }

        return units;
    }

    getUnit(key: string): string {
        return this.units[key];
    }

    updateDuration(): void {
        let value;

        if (this.unit === this.getUnit('m')) {
            value = this.duration * 60;
        } else if (this.unit === this.getUnit('h')) {
            value = this.duration * 3600;
        } else {
            value = (this.duration * 3600) * 24;
        }

        this.dataOutput.emit(value);
    }

    openUnitsList(): void {
        if (!this.disable) {
            this.open = true;
        }
    }

    @HostListener('document:click', ['$event'])
    handleBlurClick(e: MouseEvent) {
        const target = e.target;
        if (!this.elementRef.nativeElement.contains(e.target) && !(<Element>target).classList.contains('unit')) {
            this.open = false;
        }
    }
}
