import { Pipe, PipeTransform } from '@angular/core';
import { UrlHelper } from '../helpers';

@Pipe({
    name: 'url'
})
export class UrlPipe implements PipeTransform {

    transform(value: string): string {
        if (value) {
            const splitter = value.split(/(\s|<br>)/gm);
            const newValue = [];
            for (const item of splitter) {
                if (UrlHelper.test(item)) {
                    const data = '<a href="' + item + '" target="_blank">' + item + '<a>';
                    newValue.push(data);
                } else {
                    newValue.push(item);
                }
            }

            return newValue.join(' ');
        }

        return value;
    }
}
