import { Question } from '../question';
import { HttpClient } from '@angular/common/http';

export class LinkerQuestion extends Question {
    content: LinkerQuestionContent;

    constructor(question: any, protected http: HttpClient) {
        super(question, http);
        this.extends(question);
    }

    extends(data: any = {}): LinkerQuestion {
        Object.assign(this, data);

        if (!this.content) {
            this.content = new LinkerQuestionContent();
            this.content.addLink(null, null);
            this.content.addLink(null, null);
            this.content.addLink(null, null);
            this.content.addLink(null, null);
        }
        if (data.content) {
            if (this.content instanceof LinkerQuestionContent) {
                this.content.extends(data.content);
            } else {
                this.content = new LinkerQuestionContent(data.content);
            }
        }
        return this;
    }
}

export class LinkerQuestionContent {
    links: string[] = [];
    choices: string[] = [];

    constructor(data: any = {}) {
        this.extends(data);
    }

    extends(data: any = {}): LinkerQuestionContent {
        Object.assign(this, data);
        return this;
    }

    addLink(link:string, choice: string) {
        this.links.push(link);
        this.choices.push(choice);
    }

    removeLink(index: number) {
        this.links.splice(index, 1);
        this.choices.splice(index, 1);
    }
}
