import { Component, ChangeDetectionStrategy, SkipSelf, Input, Output, EventEmitter } from '@angular/core';
import { ControlContainer } from '@angular/forms';
//
import { UuidPipe } from 'src/app/utils/pipes/uuid.pipe';
//
export type CommonCheckboxType = 'ballot' | 'half' | 'square' | 'tick';

@Component({
    selector: 'msc-common-checkbox',
    templateUrl: './checkbox.component.html',
    styleUrls: ['./checkbox.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    viewProviders: [
        {
            provide: ControlContainer,
            useFactory: (container: ControlContainer) => container,
            deps: [[new SkipSelf(), ControlContainer]]
        }
    ],
    providers: [UuidPipe],
})

export class CommonCheckboxComponent {
    @Input() controlName?: string; // form control name
    @Input() isDisabled: boolean = false;
    @Input() label?: string;
    @Input() type: CommonCheckboxType = 'square';
    @Input() value: boolean; // value of checked property
    @Output() changeEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

    public id: string = this.uuidPipe.transform();

    constructor(
        private readonly uuidPipe: UuidPipe,
    ) { }

    /**
     * Event handler for change
     */
    onChange(event: Event): void {
        event.stopPropagation();
        this.value = !this.value;
        this.changeEvent.emit(this.value);
    }
}