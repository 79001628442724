<img class="author__image avatar" [src]="comment.account.picture+'?size=48'">

<span class="author__name">
    {{ comment.account.name }}
</span>

<span class="date">{{ comment.created_at | timeAgo }}</span>

<div>
    <timeline-show-content [content]="comment.content"></timeline-show-content>

    <msc-link-manager [content]="comment.content.message" [class]="'m-link--blue'"></msc-link-manager>
</div>

<timeline-delete-content (deleteContent)="onDeleteComment()"></timeline-delete-content>
