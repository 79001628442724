import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'order'
})
export class OrderPipe implements PipeTransform {

    transform(values: any[], args?: any): any {
        if (args === 'updatedAt') {
            return values.sort((a, b) => {
                if (a.updatedAt < b.updatedAt) {
                    return 1;
                } else if (a.updatedAt > b.updatedAt) {
                    return -1;
                } else {
                    return 0;
                }
            })
        } else if (args === 'title') {
            return values.sort((a, b) => {
                if (a.title.toLowerCase() > b.title.toLowerCase()) {
                    return 1;
                } else if (a.title.toLowerCase() < b.title.toLowerCase()) {
                    return -1;
                } else {
                    return 0;
                }
            })
        } else if (args === 'reverseUpdated') {
            return values.sort((a, b) => {
                if (a.updatedAt > b.updatedAt) {
                    return 1;
                } else if (a.updatedAt < b.updatedAt) {
                    return -1;
                } else {
                    return 0;
                }
            })
        } else {
            return values
        }
    }
}
