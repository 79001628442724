<aside class="sidebar sidebar--modal sidebar-subscription" [ngClass]="active ? 'active' : ''" (appSidebar)="closeSidebarEmit()">
    <div class="sidebar__container">
        <div class="sidebar__head sidebar__head--center">
            <div class="sidebar__description">
                <h1 class="sidebar__title">
                    <i class="icon icon--arrow-left" (click)="back()" *ngIf="currentTab !== 'published'"></i>

                    <span *ngIf="currentTab === 'invitation'">{{ 'words.subscribe-method' | translate }}</span>
                    <span *ngIf="currentTab === 'settings'">{{ 'words.settings-publication' | translate }}</span>
                    <span *ngIf="currentTab === 'summary'">{{ 'words.summary-publication' | translate }}</span>
                    <span *ngIf="currentTab === 'published'">{{ 'words.publication' | translate }}</span>
                </h1>
            </div>

            <a class="sidebar__close" (click)="closeSidebarEmit()">
                <i class="icon icon--close"></i>
            </a>
        </div>

        <div class="sidebar__body">
            <div class="sidebar__form">
                <ul class="sidebar__progress">
                    <li class="success" (click)="changeTab('camps', 0)">
                        <a class="tooltip-v2" [attr.data-tooltip]="'words.choose-camps' | translate"></a>
                    </li>

                    <li [ngClass]="{'active': currentTab === 'invitation', 'success': currentTab === 'settings' || currentTab === 'summary' || currentTab === 'published'}" (click)="changeTab('invitation', 1)">
                        <a class="tooltip-v2" [attr.data-tooltip]="'words.subscribe-method' | translate" *ngIf="currentTab !== 'invitation'"></a>
                    </li>

                    <li [ngClass]="{'active': currentTab === 'settings', 'success': currentTab === 'summary' || currentTab === 'published'}" (click)="changeTab('settings', 2)">
                        <a class="tooltip-v2" [attr.data-tooltip]="'words.settings-publication' | translate" *ngIf="currentTab !== 'settings'"></a>
                    </li>

                    <li [ngClass]="{'active': currentTab === 'summary', 'success': currentTab === 'published'}" (click)="changeTab('summary', 3)">
                        <a class="tooltip-v2" [attr.data-tooltip]="'words.summary-publication' | translate" *ngIf="currentTab !== 'summary'"></a>
                    </li>
                </ul>

                <h2 class="sidebar__subtitle" *ngIf="currentTab === 'invitation'">
                    {{ (instances.length > 1 ? 'instance.publication.settings.plural' : 'instance.publication.settings') | translate }}
                </h2>

                <h2 class="sidebar__subtitle sidebar__subtitle--highlight" *ngIf="currentTab === 'summary'">
                    {{ (instances.length > 1 ? 'words.instances-publication' : 'words.instance-publication') | translate }}
                </h2>

                <h2 class="sidebar__subtitle sidebar__subtitle--highlight" *ngIf="currentTab === 'published'">
                    {{ (settings.is_catalogue ? 'instance.published.catalogue' : 'instance.published.without-catalogue') | translate }}
                </h2>

                <div class="sidebar__content" *ngIf="currentTab === 'invitation'">
                    <div class="box-publication" [ngClass]="{'active': settings.is_catalogue}" (click)="selectCatalogue()">
                        <div class="box-publication__header">
                            <i class="new-icon icon-catalogue"></i> {{ 'instance.options.catalogue' | translate }}
                        </div>

                        <div class="box-publication__body">
                            <p><i class="icon icon--check"></i>{{ 'instance.catalogue.subscribe' | translate }}</p>
                            <p><i class="icon icon--check"></i>{{ 'instance.admin.subscribe' | translate }}</p>
                        </div>
                    </div>

                    <div class="box-publication" [ngClass]="{'active': !settings.is_catalogue}" (click)="selectCatalogue(false)">
                        <div class="box-publication__header">
                            <i class="new-icon icon-email-outline"></i> {{ 'instance.options.invitation' | translate }}
                        </div>

                        <div class="box-publication__body">
                            <p><i class="icon icon--close"></i>{{ 'instance.catalogue.subscribe' | translate }}</p>
                            <p><i class="icon icon--check"></i>{{ 'instance.admin.subscribe' | translate }}</p>
                        </div>
                    </div>
                </div>

                <div class="params" *ngIf="currentTab === 'settings'">
                    <div class="params__item params__item--success">
                        <div class="sidebar__toggle" [ngClass]="{'active': displays.access}" (click)="displayed('access')">
                            <p>
                                <i class="new-icon icon-options-outline"></i> {{ 'studio.publish.options.access-title' | translate }}
                            </p>

                            <i class="icon icon--arrow-bottom"></i>
                        </div>

                        <div class="params__form">
                            <div class="params__fieldset">
                                <div class="params__group">
                                    <div class="switch-v2">
                                        <div class="switch__content">
                                            <input type="checkbox" name="params-3" class="switch-checkbox" id="params-3" [(ngModel)]="settings.notify_manager">

                                            <label class="switch-label" for="params-3">
                                                <span class="switch-inner"></span>
                                                <span class="switch-switch">
                                                    <i class="icon icon--close"></i>
                                                </span>
                                            </label>
                                        </div>

                                        <div class="switch__label">
                                            {{ 'studio.publish.options.notify-manager' | translate }}
                                            <span>{{ 'studio.publish.options.enable-notify-manager' | translate }}</span>
                                        </div>
                                    </div>

                                    <div class="switch-v2">
                                        <div class="switch__content">
                                            <input type="checkbox" name="params-1" class="switch-checkbox" id="params-1" [(ngModel)]="settings.need_validation">

                                            <label class="switch-label" for="params-1">
                                                <span class="switch-inner"></span>
                                                <span class="switch-switch">
                                                    <i class="icon icon--close"></i>
                                                </span>
                                            </label>
                                        </div>

                                        <div class="switch__label">
                                            {{ 'studio.publish.options.open-course' | translate }}
                                            <span>{{ 'studio.publish.options.need-validation' | translate }}</span>
                                        </div>
                                    </div>
                                </div>

                                <div class="switch-v2">
                                    <div class="switch__content">
                                        <input type="checkbox" name="params-2" class="switch-checkbox" checked="checked" id="params-2" [(ngModel)]="settings.is_locked">

                                        <label class="switch-label" for="params-2">
                                            <span class="switch-inner"></span>
                                            <span class="switch-switch">
                                                <i class="icon icon--close"></i>
                                            </span>
                                        </label>
                                    </div>

                                    <div class="switch__label">
                                        {{ 'studio.publish.options.course-locked' | translate }}
                                        <span>{{ 'studio.publish.options.course-lock' | translate }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="params__item">
                        <div class="sidebar__toggle" [ngClass]="{'active': displays.advanced}" (click)="displayed('advanced')">
                            <p>
                                <i class="new-icon icon-settings-outline"></i> {{ 'studio.publish.options.course-follow-title' | translate }}
                            </p>

                            <i class="icon icon--arrow-bottom"></i>
                        </div>

                        <div class="params__form">
                            <div class="params__fieldset">
                                <div class="switch-v2">
                                    <div class="switch__content">
                                        <input type="checkbox" name="params-4" class="switch-checkbox" id="params-4" [(ngModel)]="settings.is_ordered">

                                        <label class="switch-label" for="params-4">
                                            <span class="switch-inner"></span>
                                            <span class="switch-switch">
                                                <i class="icon icon--close"></i>
                                            </span>
                                        </label>
                                    </div>

                                    <div class="switch__label">
                                        {{ 'studio.publish.options.course-follow' | translate }}
                                        <span>{{ 'studio.publish.options.ordered' | translate }}</span>
                                    </div>
                                </div>

                                <div class="switch-v2">
                                    <div class="switch__content">
                                        <input type="checkbox" name="params-5" class="switch-checkbox" checked="checked" id="params-5" [(ngModel)]="settings.survey">

                                        <label class="switch-label" for="params-5">
                                            <span class="switch-inner"></span>
                                            <span class="switch-switch">
                                                <i class="icon icon--close"></i>
                                            </span>
                                        </label>
                                    </div>

                                    <div class="switch__label">
                                        {{ 'studio.publish.options.survey-allow' | translate }}
                                        <span>{{ 'studio.publish.options.enable-survey' | translate }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="" *ngIf="currentTab === 'summary'" style="margin: 25px 60px 0;">
                    <div class="box-module" *ngFor="let instance of instances" style="margin: 10px 0 0;">
                        <div class="box-module__icon">
                            <i class="icon icon--modul"></i>
                        </div>

                        <div class="box-module__text">
                            <p>
                                {{ instance.title }}
                                <span>{{ instance.internalName }}</span>
                            </p>
                        </div>

                        <div class="box-module__notif">
                            <p>
                                <i class="new-icon icon-camp"></i> {{ camps.length }}
                            </p>
                        </div>
                    </div>
                </div>

                <div class="sidebar-summary" *ngIf="currentTab === 'summary'" style="margin: 0 100px;">
                    <h2 class="sidebar-summary__title">
                        {{ 'words.custom-settings' | translate }}
                    </h2>

                    <h3 class="sidebar-summary__subtitle">
                        <i class="new-icon icon-email-outline"></i>
                        {{ 'words.subscribe-method' | translate }}
                    </h3>

                    <p>
                        <i class="icon icon--check"></i> {{ (settings.is_catalogue ? 'instance.options.catalogue' : 'instance.options.invitation') | translate }}
                    </p>

                    <h3 class="sidebar-summary__subtitle" *ngIf="settings.need_validation || settings.is_locked || settings.notify_manager !== defaultParams.notify_manager">
                        <i class="new-icon icon-options-outline"></i>
                        {{ 'studio.publish.options.access-title' | translate }}
                    </h3>

                    <p *ngIf="settings.need_validation">
                        <i class="icon icon--check"></i> {{ 'studio.publish.options.open-course' | translate }}
                        <span>{{ 'studio.publish.options.need-validation' | translate }}</span>
                    </p>

                    <p *ngIf="settings.is_locked">
                        <i class="icon icon--check"></i> {{ 'studio.publish.options.course-locked' | translate }}
                        <span>{{ 'studio.publish.options.course-lock' | translate }}</span>
                    </p>

                    <p *ngIf="settings.notify_manager !== defaultParams.notify_manager">
                        <i class="icon icon--check" *ngIf="settings.notify_manager && !defaultParams.notify_manager"></i>
                        <i class="icon icon--close" *ngIf="!settings.notify_manager && defaultParams.notify_manager"></i> {{ 'studio.publish.options.notify-manager' | translate }}
                        <span>{{ 'studio.publish.options.enable-notify-manager' | translate }}</span>
                    </p>

                    <h3 class="sidebar-summary__subtitle" *ngIf="settings.is_ordered || settings.survey">
                        <i class="new-icon icon-settings-outline"></i>
                        {{ 'words.advanced-settings' | translate }}
                    </h3>

                    <p *ngIf="settings.is_ordered">
                        <i class="icon icon--check"></i> {{ 'studio.publish.options.course-follow' | translate }}
                        <span>{{ 'studio.publish.options.ordered' | translate }}</span>
                    </p>

                    <p *ngIf="settings.survey">
                        <i class="icon icon--check"></i> {{ 'studio.publish.options.survey-allow' | translate }}
                        <span>{{ 'studio.publish.options.enable-survey' | translate }}</span>
                    </p>
                </div>
            </div>
        </div>

        <div class="" *ngIf="currentTab === 'published'" style="margin: 0 100px;">
            <p class="sidebar__text sidebar__text--center">
                {{ (settings.is_catalogue ? 'words.catalogue.publication-description' : 'words.invitation.publication-description') | translate }}
            </p>

            <div class="sidebar__action" *ngIf="settings.is_catalogue">
                <button class="btn btn--primary" (click)="finish()">
                    <i class="icon icon--check"></i> {{ 'words.finish' | translate }}
                </button>

                <p>{{ 'words.or' | translate | uppercase }}</p> 
            </div>

            <div class="sidebar__publishing-option">
                <div class="box-module" (click)="openModal(modalInvite)">
                    <div class="box-module__icon">
                        <i class="new-icon icon-camp"></i>
                    </div>

                    <div class="box-module__text">
                        <p>{{ (camps.length > 1 ? 'instance.invite-auto-camp' : 'instance.invite-auto-camps') | translate }}</p>
                    </div>
                </div>

                <div class="box-module" (click)="navigate('members')">
                    <div class="box-module__icon">
                        <i class="new-icon icon-person-add-outline"></i>
                    </div>

                    <div class="box-module__text">
                        <p>{{ 'instance.invite-members' | translate }}</p>
                    </div>
                </div>

                <div class="box-module" (click)="navigate('groups')">
                    <div class="box-module__icon">
                        <i class="new-icon icon-people-outline"></i>
                    </div>

                    <div class="box-module__text">
                        <p>{{ 'instance.invite-groups' | translate }}</p>
                    </div>
                </div>
            </div>

            <div class="sidebar__action" *ngIf="!settings.is_catalogue">
                <button class="btn btn--primary" (click)="finish()">
                    <i class="icon icon--check"></i> {{ 'words.finish' | translate }}
                </button>
            </div>
        </div>

        <div class="sidebar__footer" *ngIf="currentTab !== 'published'">
            <button class="btn btn--primary" (click)="next(modalPublish)" [disabled]="loading">
                <div class="lds-ring" *ngIf="loading">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>

                <span>{{ (currentTab === 'summary' ? 'studio.instance.create-publish' : 'words.next') | translate }}</span>
            </button>
        </div>
    </div>
</aside>

<ng-template #modalInvite let-modal>
    <div class="modal-header">
        <span></span>
        <button type="button" class="close" aria-label="Close" (click)="modal.close()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div style="padding: 0 0 3rem; text-align: center;">
            <div class="" *ngIf="camps.length > 1">
                {{ 'instance.invite-description.all' | translate }}
            </div>

            <div class="" *ngIf="camps.length === 1">
                {{ camps[0].usersCount > 1 ? ('instance.invite-people' | translate:{value: camps[0].usersCount}) : ('instance.invite-person' | translate)}}
            </div>

            <div class="options">
                <label style="display: flex; justify-content: center; align-items: center; margin: 20px 0;">
                    <div class="switch switch_height_small" [ngClass]="{'switch_active': parameters.notify}">
                        <input type="checkbox" [(ngModel)]="parameters.notify">
                    </div>

                    <span style="font-size: 16px; padding: 0 0 0 20px;">{{ 'instance.invite-notify' | translate }}</span>
                </label>
            </div>

            <div class="actions" style="padding-top: 2rem;">
                <button class="btn btn_color_blue" (click)="invite(); modal.close()">
                    {{ 'studio.correction.button-submit' | translate }}
                </button>

                <button class="btn btn_color_grey" (click)="modal.close()">
                    {{ 'studio.common.button.cancel' | translate }}
                </button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #modalPublish let-modal>
    <instance-publish-modal (closeModal)="modal.close()" (publishSession)="publish()">
    </instance-publish-modal>
</ng-template>

<style>
    .sidebar__progress {
        cursor: pointer;
    }
</style>
