<div class="media-button-upload" style="margin: 20px 0 0 0;">
    <button *ngIf="!media" class="add-document add" (click)="modalOpen(modalMedia)">
        {{ 'studio.timeline.add-document' | translate }}
    </button>

    <button style="float: left;" *ngIf="media" class="add-document check" (click)="displayMedia()">
        <div class="interior">
            {{ 'studio.timeline.see-document' | translate }}
            <div class="document-name">
                {{ media.title | slice:0:20 }}
            </div>
        </div>
    </button>

    <span style="display: inline-block; margin: 0 0 0 10px;" *ngIf="media" class="btn_action_delete" (click)="deletedMedia(media)"></span>
    <a *ngIf="media" #downloadableMedia [href]="dynamicUrl(media)" target="_blank"></a>
</div>

<ng-template #modalMedia let-modal>
    <single-upload (closeModal)="modal.close()" (updateMedia)="onUpdateMedia($event)"></single-upload>
</ng-template>
