import { Place } from '../../../../../place/place';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Account } from '../../../../../account/account';
import { EventSessionMedia } from "./event-session-media";
import { EventSessionIntegration } from "./event-session-integration";
import { cleanObject } from "../../../../../helpers";
import { environment } from 'src/environments/environment';

export class EventSession {
    id: number;
    eventId: number;
    place: Place;
    startAt: any;
    endAt: any;
    registrationStartAt: number | string;
    registrationEndAt: number | string;
    isAllDay: boolean;
    seats: number;
    lang: string;
    creator: Account;
    address: string;
    icsCalendar: string;
    eventSessionMedia: EventSessionMedia[];
    eventSessionIntegrations: EventSessionIntegration[];
    companyId?: number;
    notify?: boolean;

    constructor(eventSession: any, private http: HttpClient) {
        this.extends(eventSession);
    }

    extends(eventSession: any): EventSession {
        Object.assign(this, eventSession);
        if (this.eventSessionMedia && this.eventSessionMedia.length > 0) {
            this.eventSessionMedia = this.eventSessionMedia.map((eventSessionMedia) => new EventSessionMedia(eventSessionMedia, this.http));
        }
        if (this.eventSessionIntegrations && this.eventSessionIntegrations.length > 0) {
            this.eventSessionIntegrations = this.eventSessionIntegrations.map((eventSessionIntegration) => new EventSessionIntegration(eventSessionIntegration, this.http));
        }
        return this;
    }

    delete(): Observable<void> {
        return this.http.delete(`${environment.envVar.API_URL}/events/${this.eventId}/session/${this.id}`)
            .pipe(map((res) => null));
    }

    update(): Observable<EventSession> {
        return this.http.put(`${environment.envVar.API_URL}/events/${this.eventId}/session/${this.id}`, cleanObject(this))
            .pipe(map(res => this.extends(res)));
    }

    addEventSessionMedia(mediaId: number):Observable<EventSession> {
        return this.http.post(`${environment.envVar.API_URL}/events/${this.eventId}/session/${this.id}/media/${mediaId}`, cleanObject(this))
            .pipe(map((eventSessionMedia: any) => {
                this.eventSessionMedia.push(new EventSessionMedia(eventSessionMedia, this.http));
                return this
            }));
    }

    deleteEventSessionMedia(eventSessionMedia: EventSessionMedia):Observable<EventSession> {
        const index = this.eventSessionMedia.findIndex(({ id }) => id === eventSessionMedia.id);
        return eventSessionMedia.delete()
            .pipe(map(() => {
                this.eventSessionMedia.splice(index, 1);
                return this;
            }));
    }

    createIntegration(resourceId: string, provider: string): void {
        const object = {
            id: null,
            eventSessionId: this.id,
            providerName: provider,
            resourceId: resourceId
        };

        this.eventSessionIntegrations.push(new EventSessionIntegration(object, this.http));
    }
}
