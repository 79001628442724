import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ExternalLinkProvider, Journey, JourneyProvider, SectionContent } from '@stuplay';
import { StorageService } from '../../../../../../utils/services/storage.service';
import * as moment from 'moment';
import { NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'journey-library-activity',
    templateUrl: './library-activity.component.html'
})
export class JourneyLibraryActivityComponent implements OnInit {
    @Input() journey: Journey;
    @Input() content: any;
    @Output() add: EventEmitter<any> = new EventEmitter;
    @Output() edit: EventEmitter<any> = new EventEmitter;
    public activityActive: string = '';
    public generatedSectionContent: SectionContent;
    public questionIndex: number;
    public company: any;
    public displayAside: boolean = false;

    constructor(
        private journeyProvider: JourneyProvider,
        private storageService: StorageService,
        private externalLinkProvider: ExternalLinkProvider,
        private modalService: NgbModal,
    ) { }

    ngOnInit() {
        this.company = this.storageService.get('company');

        if (this.content) {
            this.journeyProvider.getSectionContent(this.content.id).subscribe((data) => {
                this.activityActive = data.context;
                this.generatedSectionContent = data;

                if (data.context === 'exercise') {
                    this.questionIndex = this.content.questionIndex;
                }
            });
        }

        const element: any = document.getElementById('main-content');
        const scroll: any = {
            block: 'start',
            inline: 'nearest',
            behavior: 'smooth'
        };
        element.scrollIntoView(scroll);
    }

    openModal(content: any, options: NgbModalOptions = { windowClass: 'force-fullscreen-fixed', backdropClass: 'backdrop-custom' }) {
        this.modalService.open(content, options);
    }

    selectActivity(type: string, data?: any): void {
        if (type) {
            this.generateSectionContent(type, data);
        } else {
            this.activityActive = '';
            this.generatedSectionContent = null;
        }
    }

    addLti(lti: any): void {
        const params = {
            company_id: this.company.id,
            type: 'lti',
            name: lti.name,
            description: lti.description,
            url: lti.url,
            redirect_url: lti.redirectUrl,
            key: lti.key,
            secret: lti.secret,
            names: lti.names,
            anonymize: lti.anonymize,
            mode: lti.mode
        };

        this.externalLinkProvider.addExternalLink(params).subscribe((data) => {
            const parameters = {
                type: 'externalLink',
                position: 0,
                language: 'fr',
                dst: moment().isDST(),
                contextId: data.id
            };

            this.journeyProvider.generateSectionContent('external_link', parameters).subscribe((sectionContent) => {
                this.generatedSectionContent = sectionContent;
                this.generatedSectionContent.title = lti.name;
                this.activityActive = 'external_link';
                this.displayAside = false;
            });
        });
    }

    generateSectionContent(context: string, contextId?: any): void {
        const params = {
            type: this.getType(context),
            position: 0,
            language: 'fr',
            contextId: null,
            dst: moment().isDST()
        };

        if (contextId) {
            params.contextId = contextId;
        }

        this.journeyProvider.generateSectionContent(context, params).subscribe((data) => {
            this.generatedSectionContent = data;
            this.activityActive = context;
        });
    }

    getType(context: string): string {
        let type = '';

        switch (context) {
            case 'event':
                type = 'place';
                break;
            case 'project':
                type = 'document';
                break;
            case 'certificate':
                type = 'certificate';
                break;
            default:
                type = '';
        }

        return type;
    }

    updateSectionContent(): void {
        this.generatedSectionContent.update({
            traject_id: this.journey.id
        }).subscribe();
    }

    addActivity(): void {
        const timeOut = setTimeout(() => {
            this.add.emit(this.generatedSectionContent);
            clearTimeout(timeOut);
        }, 300);
    }

    editActivity(): void {
        const timeOut = setTimeout(() => {
            this.edit.emit(this.generatedSectionContent);
            clearTimeout(timeOut);
        }, 300);
    }

    openAside(): void {
        this.displayAside = true;
    }

    closeAside(): void {
        this.displayAside = false;
    }
}
